import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "./NoticeBoard.css";
import axios from "axios";
import { baseURL } from "../../../../constants";
axios.defaults.baseURL = baseURL;

function NoticeBoard() {
  const [notices, setNotices] = useState(null);
  useEffect(() => {
    const getAllNotices = async () => {
      let { data } = await axios.get(
        `/noticeboards/getAllNoticeBoards/${localStorage.getItem(
          "classroomId"
        )}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setNotices(data?.noticeboards);
    };
    getAllNotices();
  }, []);

  return (
    <div className="notis">
      {notices?.map((notice, index) => {
        return (
          <Accordion defaultActiveKey="0" key={index}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Announcement</Accordion.Header>
              <Accordion.Body>
                <span>“ Notice:</span>
                <p>
                  Sub: {notice?.title} <br />
                  {notice?.description}
                  <span>”</span>
                  <span className="span-2">{notice?.date?.split("T")[0]}</span>
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        );
      })}
    </div>
  );
}

export default NoticeBoard;
