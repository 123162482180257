import React, { useState, useEffect } from "react";
import { ImRadioChecked } from "react-icons/im";
import "./MyAssingmentSub.css";
// import { assingmentsDataSub } from "../../data/MyscheduleData";
// import Addimage from "../../AddimageModals/Addimage/Addimage";
import "../../AddimageModals/AddimageModal/AddimageModal.css";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import Modal from "@mui/material/Modal";
import axios from "axios";
import { baseURL } from "../../../../constants";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const colors = {
  "new Assignment": {
    bordercolor: "#00AFEF",
    backgroundcolor: "#00AFEF",
    descriptionColor: "#00AFEF",
    buttonBgColor: "#00AFEF",
    buttonTextColor: "#00AFEF",
    button: "Already submitted",
    spandotColor: "#00AFEF",
    bctextColor: "#4E5566",
  },
  "Due soon": {
    bordercolor: "#FF7E3E",
    backgroundcolor: "#FF7E3E",
    descriptionColor: "#FF7E3E",
    buttonTextColor: "#FF7E3E",
    button: "Already submitted",
    spandotColor: "#FF7E3E",
    bctextColorr: "#4E5566",
  },
  "OverDue Today": {
    bordercolor: "#F93333",
    backgroundcolor: "#F93333",
    descriptionColor: "#F93333",
    buttonTextColor: "#F93333",
    button: "Submit assignment",
    spandotColor: "#F93333",
    bctextColor: "#4E5566",
  },
  submitted: {
    bordercolor: "#16D03B",
    background: "#16D03B",
    descriptionColor: "#16D03B",
    buttonTextColor: "#16D03B",
    button: "Already submitted",
    spandotColor: "#16D03B",
    bctextColor: "#4E5566",
  },
};

function MyAssingmentSub() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const myStyles = [
    {
      bordercolor: "#F93333",
      backgroundcolor: "rgba(249,51,51,0.11)",
      buttonbackgroundcolor: "rgba(249,51,51,0.11)",
      descriptionColor: "#F93333",
      buttonTextColor: "#F93333",
      button: "Submit assignment",
      spandotColor: "#F93333",
      bctextColor: "#F93333",
    },
    {
      bordercolor: "#FF7E3E",
      backgroundcolor: "rgba(22,208,59,0.14)",
      buttonbackgroundcolor: "rgba(22,208,59,1)",
      descriptionColor: "#FF7E3E",
      buttonTextColor: "rgba(249, 51, 51, 1)",
      button: "Submit assingment",
      spandotColor: "#FF7E3E",
      bctextColor: "#4E5566",
    },
    {
      bordercolor: "#16D03B",
      backgroundcolor: "rgba(240,255,243,0.14)",
      buttonbackgroundcolor: "rgba(240,255,243,1)",
      descriptionColor: "#16D03B",
      buttonTextColor: "#16D03B",
      spandotColor: "#16D03B",
      button: "Already submitted",
      bctextColor: "#16D03B",
    },
    {
      bordercolor: "#00AFEF",
      backgroundcolor: "rgba(0,175,239,0.15)",
      buttonbackgroundcolor: "rgba(0,175,239,0.15)",
      descriptionColor: "#00AFEF",
      buttonBgColor: "#00AFEF",
      buttonTextColor: "#00AFEF",
      spandotColor: "#00AFEF",
      bctextColor: "#00AFEF",
    },
  ];
  useEffect(() => {
    const getAllAssignments = async () => {
      const generateMappedData = (inputData) => {
        const styles = {
          "OverDue Today": {
            bordercolor: "#F93333",
            backgroundcolor: "rgba(249,51,51,0.11)",
            buttonbackgroundcolor: "rgba(249,51,51,0.11)",
            descriptionColor: "#F93333",
            buttonTextColor: "#F93333",
            button: "Submit assignment",
            spandotColor: "#F93333",
            bctextColor: "#F93333",
          },
          "Due soon": {
            bordercolor: "#FF7E3E",
            backgroundcolor: "rgba(22,208,59,1)",
            buttonbackgroundcolor: "rgba(22,208,59,1)",
            descriptionColor: "#FF7E3E",
            buttonTextColor: "rgba(249, 51, 51, 1)",
            button: "Submit assingment",
            spandotColor: "#FF7E3E",
            bctextColor: "#4E5566",
          },
          submitted: {
            bordercolor: "#16D03B",
            backgroundcolor: "rgba(240,255,243,1)",
            buttonbackgroundcolor: "rgba(240,255,243,1)",
            descriptionColor: "#16D03B",
            buttonTextColor: "#16D03B",
            spandotColor: "#16D03B",
            button: "Already submitted",
            bctextColor: "#16D03B",
          },
          "new Assignment": {
            bordercolor: "#00AFEF",
            backgroundcolor: "rgba(0,175,239,0.15)",
            buttonbackgroundcolor: "rgba(0,175,239,0.15)",
            descriptionColor: "#00AFEF",
            buttonBgColor: "#00AFEF",
            buttonTextColor: "#00AFEF",
            spandotColor: "#00AFEF",
            bctextColor: "#00AFEF",
          },
        };

        return inputData.map((item) => {
          const style = styles[item.type] || {};
          return { ...item, ...style };
        });
      };
    };
  }, []);

  const [assignments, setAssignments] = useState(null);

  const params = useParams();

  const dispatch = useDispatch();

  const { user, loading, error } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const getAssignments = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/assignments/getSubmittedAssignments/${user.digitalValues.id}/${params.subjectId}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        setAssignments(data.assignments);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAssignments();
  }, [user]);

  return (
    <div className="myassingment-main">
      <div className="myassingment-child-1">
        <div className="myassingment-radio-btn">
          <span></span>
          <p>OverDue</p>
          <ImRadioChecked
            style={{ color: "#f93333", width: "19px", height: "19px" }}
          />
        </div>
        <div className="myassingment-radio-btn">
          <span></span>
          <p>Due Soon</p>
          <ImRadioChecked
            style={{ color: "#ff7e3e", width: "19px", height: "19px" }}
          />
        </div>
        <div className="myassingment-radio-btn">
          <span></span>
          <p>Submitted</p>
          <ImRadioChecked
            style={{ color: "#16d03b", width: "19px", height: "19px" }}
          />
        </div>
        <div className="myassingment-radio-btn">
          <span></span>
          <p>New</p>
          <ImRadioChecked
            style={{ color: "#00afef", width: "19px", height: "19px" }}
          />
        </div>
      </div>
      <div>
        {assignments &&
          assignments.map((assignment, index) => {
            const newIndex = index % myStyles.length;
            return (
              <div
                className="myassingment-child-2"
                key={index}
                style={{ marginTop: "10px" }}
              >
                <div
                  className="myassingment-card"
                  style={{
                    backgroundColor: "rgba(240,255,243,1)",
                    borderColor: "#16D03B",
                  }}
                >
                  <div className="myassingment-card-child-1">
                    <div className="myassingment-child">
                      <div className="myassingment-child-data">
                        <div className="myassingment-child-data">
                          <p
                            className="date"
                            style={{
                              colors: "#16D03B",
                              backgroundColor: "#16D03B",
                            }}
                          >
                            {assignment?.id}
                          </p>
                        </div>
                        <div className="mychild-myassingment">
                          <h6
                            style={{
                              color: "#FF7E3E",
                            }}
                          >
                            {assignment?.title}
                          </h6>
                          <div className="myassingment-time">
                            <div className="myassingment-mother">
                              <p>{assignment?.dueOn}</p>
                              <span
                                style={{
                                  color: "#16D03B",
                                }}
                              >
                                ●
                              </span>
                              <p>{assignment?.createdAt}</p>
                            </div>
                            <p style={{ fontSize: "8px" }}></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="myassingment-card-child-2">
                    <div className="description">
                      <h5 style={{ color: "#16D03B" }}>
                        {assignment?.description}
                      </h5>
                    </div>
                    <div className="dov">
                      <h4 style={{ color: "#16D03B" }}>{assignment?.type}</h4>

                      <div>
                        <button
                          style={{
                            borderColor: "#16D03B",
                            color: "#16D03B",
                          }}
                          onClick={handleOpen}
                        >
                          Already Submitted
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default MyAssingmentSub;
