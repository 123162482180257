import React, { useEffect, useState } from "react";
import "./Addefficiency.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import LineChart from "../Adminstrator/AdminstratorDashbord/LineChart/LineChart";
import Badge from "react-bootstrap/Badge";
import { TbGridDots } from "react-icons/tb";
import { VscThreeBars } from "react-icons/vsc";
import { CiSettings } from "react-icons/ci";
import { IoIosArrowRoundForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { GrShare } from "react-icons/gr";
import axios from "axios";
import { baseURL } from "../../../constants";
axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;

const EfficiencyScore = () => {
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [efficiencyScore, setEfficiencyScore] = useState(null);
  const [integrations, setIntegrations] = useState(null);
  const [vendorScore, setVendorScore] = useState(null);

  const getVendorScore = async () => {
    try {
      const { data } = await axios.get(
        "/VendercentralityScore/getVenderontralityScore",
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setVendorScore(data);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };



  const [centralityScore, setCentralityScore] = useState(null);

  const getCentralityScore = async () => {
    try {
      const { data } = await axios.get(
        "/AppCentralityScore/getAppCentralityScore",
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setCentralityScore(data);
    } catch (error) {
      console.log(error);
    }
  };

  const integrationsHandler = async () => {
    try {
      setLoading2(true);
      const { data } = await axios.get("/integrations/getintegrations", {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });

      setLoading2(false);
      setIntegrations(data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    integrationsHandler();
    getCentralityScore();
    getVendorScore();
  }, []);

  return (
    <div className="EfficiencyScore-main">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col sm={12}>
            <Nav variant="pills" className="flex-row">
              <Nav.Item className="navitems">
                <Nav.Link eventKey="first" className="tabs-button">
                  Teacher app Efficiency Score
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second" className="tabs-button">
                  Student app Efficiency Score
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third" className="tabs-button">
                  Parents app Efficiency Score
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="first-tab-main">
                  <div className="addchart">
                    {/* {vendorScore && centralityScore && (
                      <LineChart data1={centralityScore} data2={vendorScore} />
                    )} */}
                    <LineChart />
                  </div>
                  <div className="addtitle">
                    <h1>Integrations</h1>
                    <p>Teacher use Apps use to integrate with MyCentrality</p>
                  </div>
                  <div className="addcards">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="one"
                    >
                      <Row>
                        <div className="ADDEFFIENCYTABS">
                          <Col sm={9}>
                            <Nav variant="pills" className="flex-row">
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="one"
                                  className="addtab-button"
                                >
                                  All Integrations
                                  <Badge
                                    bg="white"
                                    text="dark"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    9
                                  </Badge>
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="two"
                                  className="addtab-button"
                                >
                                  Finance
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="three"
                                  className="addtab-button"
                                >
                                  Communications
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="four"
                                  className="addtab-button"
                                >
                                  Storage
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Col>
                          <Col>
                            <div className="toggle-card">
                              <div className="TbGridDots-icon">
                                {" "}
                                <TbGridDots />
                              </div>
                              <div className="VscThreeBars-icon">
                                {" "}
                                <VscThreeBars />
                              </div>
                            </div>
                          </Col>
                        </div>
                        <Row>
                          <Col sm={12}>
                            <Tab.Content>
                              <Tab.Pane eventKey="one">
                                <div className="all-intragraction">
                                  {["juuu"]?.map((integration) => (
                                    <div
                                      key={integration?.title || "title"}
                                      className="all-intragraction-card"
                                    >
                                      <img
                                        src={integration?.logo || ""}
                                        alt="#"
                                      />
                                      <h2>{integration?.title || "title"}</h2>
                                      <p>
                                        {integration?.description ||
                                          "description"}
                                      </p>
                                      <div className="buttons-div">
                                        <button>
                                          {integration?.status || "status"}{" "}
                                          <CiSettings />
                                        </button>
                                        <Link to="/AddAppIntegration">
                                          Integrations details
                                          <IoIosArrowRoundForward className="arrow" />
                                        </Link>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="two">
                                Second tab content
                              </Tab.Pane>
                              <Tab.Pane eventKey="three">
                                Third tab content
                              </Tab.Pane>
                              <Tab.Pane eventKey="four">
                                Fourth tab content
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Row>
                    </Tab.Container>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">Second tab content</Tab.Pane>
              <Tab.Pane eventKey="third">Third tab content</Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      <div className="app-integration-term-and-condation">
        <p>
          Read our
          <ins>
            <span>terms and Conditions</span>
          </ins>
        </p>
        <GrShare className="app-share-icon" />
      </div>
    </div>
  );
};

export default EfficiencyScore;
