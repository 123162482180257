import React, { useEffect, useState } from "react";
import "./FirstPage.css";
import white from "../../images/whiteLogo.png";
import mainLogo from "../../images/Logo.png";
import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import { baseURL } from "../../../../constants";
axios.defaults.baseURL = baseURL;

function FirstPage() {
  const [school, setSchool] = useState([]);
  const permanentPlaceholder = "School.Mycentrality.org";

  const getSchool = async () => {
    try {
      const { data } = await axios.get(`/schools/all`, {
        headers: {
          accessToken: localStorage.getItem("token"),
        },
      });
      setSchool(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchool();
  }, []);

  return (
    <div className="FirstPage-main">
      <div className="FirstPage-Alpha">
        <div className="FirstPage-Logo">
          <img src={white} alt="White Logo" />
          <h3>MyCentrality</h3>
        </div>
      </div>
      <div className="CenterCard">
        <div className="CardH3">
          <img src={mainLogo} alt="Main Logo" />
          <h3>MyCentrality</h3>
        </div>
        <h4>
          Welcome to <span>Mycentrality</span>
        </h4>
        gzbdvgtxdgxzfvzf
        <div className="CenterInput">
          <label className="CenterLabel">
            Enter your school or district URL
          </label>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={school.map((s) => s.name)}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={permanentPlaceholder}
                InputProps={{
                  ...params.InputProps,
                  //   startAdornment: (
                  //     <>
                  //       {params.InputProps.startAdornment}
                  //       <span className="permanent-placeholder">@gmail.com</span>
                  //     </>
                  //   ),
                }}
              />
            )}
          />
        </div>
        <button className="CenterButton">Continue</button>
      </div>
    </div>
  );
}

export default FirstPage;
