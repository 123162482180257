import React, { useEffect, useState } from "react";
import "./AllStudents.css";
import { IoCaretForward, IoChevronForward } from "react-icons/io5";
import { IoSearch } from "react-icons/io5";
import { RiEqualizerFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Dummy from "../../AdminComponents/images/dummy-user.png";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../../constants";
import { IoIosArrowBack } from "react-icons/io";
axios.defaults.baseURL = baseURL;

function AllStudents() {
  const [students, setStudents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [studentsPerPage] = useState(12);
  const { user } = useSelector((state) => state.getUserProfile);

  const getSchool = async () => {
    try {
      const { data } = await axios.get(
        `/ParentBySchoolId/getSchoolByTeacherId/${user?.dataValues?.id}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      const res = await axios.get(`/students/all/${data?.school?.id}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setStudents(res.data?.students);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (user) {
      getSchool();
    }
  }, [user]);

  const indexOfLastStudent = currentPage * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  const currentStudents = students.slice(
    indexOfFirstStudent,
    indexOfLastStudent
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const nextPage = () => {
    if (currentPage < Math.ceil(students.length / studentsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderPageNumbers = () => {
    const totalPages = Math.ceil(students.length / studentsPerPage);
    const pageNumbers = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => paginate(i)}
            className={i === currentPage ? "active" : ""}
          >
            {i}
          </button>
        );
      }
    } else {
      if (currentPage > 3) {
        pageNumbers.push(
          <button key={1} onClick={() => paginate(1)}>
            1
          </button>
        );
        pageNumbers.push(<span key="dots1">...</span>);
      }

      const startPage = Math.max(currentPage - 2, 1);
      const endPage = Math.min(currentPage + 2, totalPages);

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => paginate(i)}
            className={i === currentPage ? "active" : ""}
          >
            {i}
          </button>
        );
      }

      if (currentPage < totalPages - 2) {
        pageNumbers.push(<span key="dots2">...</span>);
        pageNumbers.push(
          <button key={totalPages} onClick={() => paginate(totalPages)}>
            {totalPages}
          </button>
        );
      }
    }

    return pageNumbers;
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="ALLstudents-Top">
        <h3>Dashboard</h3>
        <IoCaretForward />
        <h4>Students List</h4>
      </div>
      <div className="ALLstudents-Two">
        <h2>Student List</h2>
        <div className="ALLstudents-Two-main">
          <div className="ALLstudents-Two-search">
            <IoSearch />
            <input placeholder="Search ." />
          </div>
          <div className="ALLstudents-Two-date">
            <input type="date" />
          </div>
          <button className="ALLstudents-Two-filter">
            <RiEqualizerFill />
            Filters
          </button>
        </div>
      </div>
      <div className="table-containerr">
        <table className="student-table">
          <thead>
            <tr>
              <th>Profile Picture</th>
              <th>Student Name</th>
              <th>Guardian Name</th>
              <th>Enroll Date</th>
              <th>Grade</th>
            </tr>
          </thead>
          <tbody>
            {currentStudents &&
              currentStudents.map((student) => (
                <tr
                  onClick={() => {
                    localStorage.setItem("teacherStudent", student.id);
                    navigate(`/StuProfile/${student.id}`);
                  }}
                  key={student.id}
                  style={{ textDecoration: "none", color: "#000000" }}
                >
                  <td>
                    <img
                      src={`${baseURL}/images/${student.profilePicture}`}
                      alt={student.studentName}
                      onError={({ currentTarget }) => {
                        currentTarget.src = Dummy;
                      }}
                      className="student-img"
                    />
                  </td>
                  <td>
                    {student.firstName} {student.lastName}
                  </td>
                  <td>{student.fatherName}</td>
                  <td>
                    {student.enrollDate
                      ? student.enrollDate.split("T")[0]
                      : "N/A"}
                  </td>
                  <td>{student.gradeLevel}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="paginationnn">
          <button onClick={prevPage} disabled={currentPage === 1}>
            <IoIosArrowBack />
          </button>
          {renderPageNumbers()}
          <button
            onClick={nextPage}
            disabled={
              currentPage === Math.ceil(students.length / studentsPerPage)
            }
          >
            <IoChevronForward />
          </button>
        </div>
      </div>
    </>
  );
}

export default AllStudents;
