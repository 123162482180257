import React, { useEffect, useState } from "react";
import AppImg from "./1195025-education-apps 1.png";
import Appimg from "./1195025-education-apps 3.png";
import appIMG from "./1195025-education-apps 5.png";
import APPimg from "./1195025-education-apps 1.png";
import "./RecentlyApp.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import AllowModal from "../Allowmodal/AllowModal";
import axios from "axios";
import { baseURL } from "../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

function RecentlyApp() {
  const appData = [
    { Img: AppImg, label: "Camera", timeSpent: 5 * 60 * 60 },
    { Img: Appimg, label: "Calendar", timeSpent: 5 * 60 * 60 },
    { Img: appIMG, label: "Gallery", timeSpent: 10 * 60 * 60 },
    { Img: APPimg, label: "Message", timeSpent: 7 * 60 * 60 },
  ];

  const { studentId } = useSelector((state) => state.studentId);

  const [appUsage, setAppUsage] = useState(null);

  const getAppAccess = async () => {
    try {
      const { data } = await axios.get(`/apps/getAppUsages/${studentId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setAppUsage(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAppAccess();
  }, [studentId]);

  return (
    <div className="ParentRecentlyApp-main">
      <div className="RecentlyApp-ProgressBars">
        {appUsage &&
          appUsage.map((app, index) => (
            <div className="ProgressFlex" key={index}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <img
                  style={{ objectFit: "contain" }}
                  src={`${baseURL}/images/${app?.App?.logo}`}
                  alt=""
                />
                <label htmlFor={`file${index}`}>{app?.App?.appName}</label>
              </div>
              <div className="ProgressBarWrapper">
                <ProgressBar
                  now={(app?.screenTime / (24 * 60 * 60)) * 100}
                  className="ProgressBar"
                />
                <span className="ProgressBarTime">{app?.appUsageTime}</span>
              </div>

              <span className="ProgressFlex-Btn">
                <AllowModal />
              </span>
            </div>
          ))}
      </div>
    </div>
  );
}

export default RecentlyApp;
