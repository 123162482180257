import React from "react";
import "./GraStatus.css";
import print from "../Medical/flat-color-icons_print.png";

function GraStatus() {
  function handlePrint() {
    window.print();
  }

  return (
    <div className="Graduation-main">
      <div className="Graduation-Top">
        <h3>Graduation Status</h3>
        <button onClick={handlePrint}>
          <img src={print} alt="#" />
          Print Graduation Status Report{" "}
        </button>
      </div>
      <div className="Graduation-Body">
        <div className="Graduation-Child">
          <div className="Graduation-Child-input">
            <input type="checkbox" />
            <label>Show Credit Details</label>
          </div>
          <div className="Graduation-Child-input">
            <input type="checkbox" />
            <label>Hide Planned Credits</label>
          </div>
        </div>
        <p>
          Please note: Status reflected is contingent upon successful completion
          of current course enrollment.
        </p>
        <div className="Graduation-Top">
          <h3>Graduation Status</h3>
        </div>
        <div className="Graduation-Table">
          <table className="overflowtable">
            <thead>
              <tr>
                <th>Subject Area</th>
                <th>Credit Required</th>
                <th>Credit Completed</th>
                <th>Credit Enrolled</th>
                <th>Credit Planned</th>
                <th>Credit Needed</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>A - English</td>
                <td>40.00</td>
                <td>35.00</td>
                <td>5.00</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>B - Physical Education</td>
                <td>30.00</td>
                <td>30.00</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="Graduation-Btns">
          <div className="Graduation-Btns-one">
            <h4>Credit Completed</h4>
            <h5>272.50</h5>
          </div>
          <div className="Graduation-Btns-two">
            <h4>Credit Enrolled</h4>
            <h5>272.50</h5>
          </div>
          <div className="Graduation-Btns-three">
            <h4>Credit Needed</h4>
            <h5>272.50</h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GraStatus;
