import React, { useState, useEffect } from "react";
import "./TuitionInfo.css";
import axios from "axios";
import Dummy from "../../../../AdminComponents/images/dummy-user.png";
import { useParams } from "react-router-dom";
import { baseURL } from "../../../../../constants";
axios.defaults.baseURL = baseURL;

const TuitionInfo = () => {
  const { studentId } = useParams();
  const [student, setStudent] = useState("");

  const getStudent = async () => {
    try {
      const { data } = await axios.get(
        `/students/profile/${
          studentId === ":studentId"
            ? localStorage.getItem("teacherStudent")
            : studentId
        }`,

        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setStudent(data?.student);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStudent();
  }, []);
  console.log(student, "This is me sheryar");
  return (
    <div className="TuitionInfo1">
      <div className="TeacherStudentProfile">
        <div className="studentInfo1">
          <img
            src={`${baseURL}/images/${student?.profilePicture}`}
            alt="profilePicture"
            onError={({ currentTarget }) => {
              currentTarget.src = Dummy;
            }}
          />

          <div>
            <h1>{student?.firstName}</h1>
            <h2>
              Student ID :<span>{student?.id}</span>
            </h2>
            {/* <h2>
              Department:
              <span> Department of Computer Science</span>
            </h2> */}
          </div>
        </div>
        <div className="studentInfo">
          <h2>
            Father Name : <span>{student?.fatherName}</span>
          </h2>
        </div>
      </div>
      {/* {tuition &&
          Object.keys(tuition).map((info) => {
            return (
              <div className="Semester">
                <h1>{info}</h1>
                <table>
                  <thead>
                    <tr>
                      <th>Invoice</th>
                      <th>Date</th>
                      <th>Description</th>
                      <th>Initial Amount</th>
                      <th>Paid</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tuition?.[info]?.tuitionInfo?.map((dt) => {
                      return (
                        <tr>
                          <td>{dt?.invoice}</td>
                          <td>{dt?.date?.split("T")[0]}</td>
                          <td> {dt?.description}</td>
                          <td> {dt?.initialAmount}</td>
                          <td> {dt?.paid}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            );
          })} */}
    </div>
  );
};

export default TuitionInfo;
