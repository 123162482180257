import React, { useEffect, useState } from "react";
import "./TuitionInfo.css";
import Dummy from "../../AdminComponents/images/dummy-user.png";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../../constants";
axios.defaults.baseURL = baseURL;

const TuitionInfo = () => {
  const { studentId } = useSelector((state) => state.studentId);

  const [tuitionInfo, setTuitionInfo] = useState(null);

  const getTuitionInfo = async () => {
    try {
      const { data } = await axios.get(
        `/tuitionInfos/getTuitionInfo/${studentId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setTuitionInfo(data?.tuitionInfo);
    } catch (error) {
      console.log(error);
    }
  };

  const [studentProfile, setStudentProfile] = useState(null);

  const GetParentData = async () => {
    try {
      const { data } = await axios.get(`/students/profile/${studentId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setStudentProfile(data?.student);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTuitionInfo();

    GetParentData();
  }, [studentId]);
  return (
    <div className="TuitionInfo">
      <div className="TeacherStudentProfile">
        <div className="studentInfo1">
          <img
            src={`${baseURL}/images/${studentProfile?.profilePicture}`}
            alt="profilePicture"
            onError={({ currentTarget }) => {
              currentTarget.src = Dummy;
            }}
          />
          <div>
            <h1>
              {/* {parentProfile?.parent?.firstName}{" "}
                {parentProfile?.parent?.middleName}{" "}
                {parentProfile?.parent?.lastName} */}
            </h1>
            <h2>
              Student ID:
              <span>{studentProfile?.studentId}</span>
            </h2>
          </div>
        </div>
        <div className="studentInfo">
          <h2>
            Father Name : <span>{studentProfile?.fatherName}</span>
          </h2>
          {/* <h2>
            Session:
            <span>{studentProfile?.session}</span>
          </h2>
          <h2>
            Degree :<span>{studentProfile?.program}</span>
   
          </h2> */}
        </div>
      </div>
      {tuitionInfo &&
        Object.keys(tuitionInfo).map((semister) => {
          return (
            <div className="Semester">
              <h1>{semister}</h1>
              <table>
                <thead>
                  <tr>
                    <th>Invoice</th>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Initial Amount</th>
                    <th>Paid</th>
                  </tr>
                </thead>
                <tbody>
                  {tuitionInfo[semister]?.tuitionInfo?.map((semi) => {
                    return (
                      <tr>
                        <td>{semi?.invoice}</td>
                        <td>{semi?.date?.split("T")[0]}</td>
                        <td>{semi?.description}</td>
                        <td>{semi?.initialAmount}</td>
                        <td>{semi?.paid}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          );
        })}
    </div>
  );
};

export default TuitionInfo;
