import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Summary.css";
import { baseURL } from "../../../../../../constants";
import { FaRegEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;

const Summary = () => {
  const [subject, setSubject] = useState(null);
  const params = useParams();

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);
  const [schedules, setSchedules] = useState(null);

  const { subjectId } = useParams();
  const getSchedules = async () => {
    try {
      const { data } = await axios.get(
        `/schedules/getScheduleBySubject/${subjectId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setSchedules(data.schedules);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchedules();
  }, []);
  const GetData = async () => {
    try {
      const { data } = await axios.get(
        `/subjects/getSubjectById/${params?.subjectId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setSubject(data?.subject);
    } catch (error) {
      console.log(error, "Error fetching subject");
    }
  };

  useEffect(() => {
    GetData();
  }, []);

  const [teacher, setTeacher] = useState(null);

  const getTeacher = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/teachers/getProfile/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setTeacher(data?.profile);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTeacher();
  }, [user]);

  const [summery, setSummery] = useState(null);

  const getSummery = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/schedules/getScheduleByTeacher/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setSummery(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSummery();
  }, [user]);
  return (
    <div className="Summary">
      <React.Fragment>
        <div className="Chemistry">
          <div className="btnInfo">
            <h4>Summary</h4>
            <p>
              Subject: <span>{subject?.subjectName}</span>
            </p>
          </div>
          <button className="liveBtn">Go Live</button>
        </div>
        <div className="ClassTiming">
          <div className="timingAttendancebtn">
            <h6>Class timing</h6>
            <Link to="/Teacherattendance">
              <button className="liveBtn Attendancebtn">Attendance</button>
            </Link>
          </div>
          <div className="Teacher">
            <table>
              <thead>
                <tr>
                  <th>Day</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Total Time</th>
                  <th>Venue</th>
                  <th>
                    <FaRegEdit />
                  </th>
                </tr>
              </thead>
              <tbody>
                {summery &&
                  summery.schedules?.map((schedule) => {
                    return (
                      <tr>
                        <td>{schedule?.day?.join(", ")}</td>
                        <td>{schedule?.startTime}</td>
                        <td>{schedule?.endTime}</td>
                        <td>{schedule?.totalTime}</td>
                        <td>{schedule?.gradeLevel}</td>
                        <td></td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="AboutTeacher">
            <h5>My Name</h5>
            <p>
              Name:{" "}
              <span>
                {teacher?.firstName} {teacher?.middleName} {teacher?.z}
              </span>
            </p>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export default Summary;
