import React from "react";
import "./IeProgram.css";
import "../NestedTabs/NestedTabs.css";

function IeProgram() {
  return (
    <div className="IeProgram-Main123">
      <div>
        <div className="IeProgram-Top">
          <h3>Individualized Education Program</h3>
        </div>
        <table>
          <thead>
            <tr>
              <th>Program</th>
              <th>Entered</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>English Learner</td>
              <td>09/05/2017</td>
            </tr>
            <tr>
              <td>Foster Program</td>
              <td>09/05/2017</td>
            </tr>
            <tr>
              <td>Gate</td>
              <td>09/05/2017</td>
            </tr>
            <tr>
              <td>Gifted and Talented Education (GATE)</td>
              <td>09/05/2017</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default IeProgram;
