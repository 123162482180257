import React, { useEffect, useState } from "react";
import "./StaffAddress.css";
import { useParams } from "react-router-dom";
import EditStafAddress from "./EditStafAddress/EditStafAddress";
import axios from "axios";
import { Country, State } from "country-state-city";
import { baseURL } from "../../../../../constants";
axios.defaults.baseURL = baseURL;

function StaffAddress() {
  const [showEdit, setShowEdit] = useState(false);

  const [emergencyContact, setEmergencyContact] = useState(null);
  const [teacherContact, setTeacherContact] = useState(null);
  const [teacherAddress, setTeacherAddress] = useState(null);

  const params = useParams();
  const getStaffAddressInfo = async () => {
    try {
      const { data } = await axios.get(
        `/teachers/getAddressInfo/${params.staffId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setTeacherAddress(data.teacherAddress);
      setTeacherContact(data.teacherContact);
      setEmergencyContact(data.emergencyContact);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStaffAddressInfo();
  }, []);
  return !showEdit ? (
    <div className="StaffAddress-Main">
      <div className="StaffAddress-Row-1">
        <h3>Address & Contact</h3>

        <button
          onClick={() => {
            setShowEdit(true);
          }}
        >
          Edit Information
        </button>
      </div>
      <h4>Personal Contact Information</h4>

      {
        <div className="StaffAddress-Main-row-1">
          <div>
            <label>Home phone</label> <br />
            <input
              value={teacherContact?.homePhone}
              readOnly
              name="homePhone"
              placeholder="-"
            />
          </div>
          <div>
            <label>Mobile phone</label> <br />
            <input
              value={teacherContact?.mobilePhone}
              readOnly
              name="mobilePhone"
            />
          </div>
          <div>
            <label>Personal Email</label> <br />
            <input
              value={teacherContact?.personalEmail}
              readOnly
              name="personalEmail"
            />
          </div>
        </div>
      }
      {
        <div className="StaffAddress-Main-row-1">
          <div>
            <label>School Email</label> <br />
            <input
              value={teacherContact?.schoolEmail}
              readOnly
              name="schoolEmail"
            />
          </div>
          <div>
            <label>Office Phone</label> <br />
            <input
              value={teacherContact?.officePhone}
              readOnly
              name="officePhone"
            />
          </div>
        </div>
      }
      <div className="StaffAddress-Main-2">
        <h3>Home Address</h3>
      </div>
      {
        <div className="StaffAddress-Main-row-1">
          <div>
            <label>Street Address 1</label> <br />
            <input readOnly value={teacherAddress?.address1} name="address1" />
          </div>
          <div>
            <label>Street Address 2</label> <br />
            <input readOnly value={teacherAddress?.address2} name="address2" />
          </div>
          <div>
            <label>Country</label> <br />
            <input
              value={Country?.getCountryByCode(teacherAddress?.country)?.name}
              readOnly
              name="country"
            />
          </div>
          <div>
            <label>State/province/Locality</label> <br />
            <input
              value={
                State?.getStateByCode(teacherAddress?.stateProvinceOrLocality)
                  ?.name
              }
              readOnly
              name="stateOrProvinceOrLocality"
            />
          </div>
        </div>
      }
      <div className="StaffAddress-Main-row-1">
        <div>
          <label>City</label> <br />
          <input value={teacherAddress?.city} readOnly name="city" />
        </div>
        <div>
          <label>Zip/postal Code</label> <br />
          <input
            value={teacherAddress?.zipOrPostalCode}
            readOnly
            name="zipOrPostalCode"
          />
        </div>
      </div>
      <h3>Emergency Contact Info</h3>
      {
        <div className="StaffAddress-Main-row-1">
          <div>
            <label>Emergency Contact Name</label> <br />
            <input
              value={emergencyContact?.emergencyContactName}
              readOnly
              name="emergencyContactName"
            />
          </div>
          <div>
            <label>Relationship to Staff</label> <br />
            <input
              value={emergencyContact?.relationshipToStaff}
              readOnly
              name="emergencyContactChanges"
            />
          </div>
          <div>
            <label>Home phone</label> <br />
            <input
              value={emergencyContact?.homePhone}
              readOnly
              name="homePhone"
            />
          </div>
        </div>
      }

      {
        <div className="StaffAddress-Main-row-1">
          <div>
            <label>Mobile Phone</label> <br />
            <input
              value={emergencyContact?.mobilePhone}
              readOnly
              name="mobilePhone"
            />
          </div>
          <div>
            <label>Email</label> <br />
            <input value={emergencyContact?.email} readOnly name="email" />
          </div>
        </div>
      }
    </div>
  ) : (
    <EditStafAddress
      setShowEdit={setShowEdit}
      emergencyContact={emergencyContact}
      teacherContact={teacherContact}
      teacherAddress={teacherAddress}
      getStaffAddressInfo={getStaffAddressInfo}
    />
  );
}

export default StaffAddress;
