import React, { useEffect, useState } from "react";
import "./CourseTab.css";
import CourseAssignModal from "./CourseAssignModal";
import { FaUserEdit } from "react-icons/fa";
import { ImBin2 } from "react-icons/im";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import axios from "axios";
import { baseURL } from "../../../../../constants";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
axios.defaults.baseURL = baseURL;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CourseTab() {
  const [deleteId, setDeleteId] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const params = useParams();

  const handleClose = () => setOpen(false);

  const [schedules, setSchedules] = useState(null);
  const getCourses = async () => {
    try {
      const { data } = await axios.get(
        `/studentCourses/getstudentCourse/${params?.studentId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setSchedules(data?.studentCourses);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteSchedule = async () => {
    try {
      const { data } = await axios.delete(
        `/studentCourses/removeStudentCourse/${deleteId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      getCourses();
      handleClose();
      toast.success("Deleted Successfully");
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.detail
        );
      } else if (error?.response?.data?.error) {
        toast.error(
          error?.response?.data?.error[0]?.detail ||
            error?.response?.data?.error[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <>
      <Modal
        className="Allowmodalmain"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="CommentModal-Mmain-child1">
              <h4>Delete course</h4>
              <p>
                Are you sure you want to delete this course? This will remove
                the course and can't be undone?
              </p>
              <div className="CommentModal-Mmain-child2">
                <button
                  className="
                CommentModal-Mmain-child2-allow "
                  onClick={handleClose}
                >
                  CANCEL
                </button>
                <button
                  onClick={handleDeleteSchedule}
                  className="CommentModal-Mmain-child2-Dont-allow"
                >
                  DELETE
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <div className="CourseTab-main">
        <div className="CourseTab-row-1">
          <h2>Schedule Courses</h2>
          <button>
            <CourseAssignModal getCourses={getCourses} />
          </button>
        </div>
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Course Section</th>
              <th>Teacher</th>
              <th>Classroom</th>
              <th>Program</th>
              <th>Subject</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {schedules?.map((schedule) => {
              return (
                <tr className="TableFirstrow">
                  <td>{schedule?.id}</td>
                  <td>{schedule?.Course?.courseName}</td>
                  <td>
                    {schedule?.Teacher?.firstName} {schedule?.Teacher?.lastName}
                  </td>
                  <td>{schedule?.Classroom?.classroomName}</td>
                  <td>{schedule?.Program?.programName}</td>
                  <td>{schedule?.Subject?.subjectName}</td>
                  <td>
                    <FaUserEdit />
                    <ImBin2
                      onClick={() => {
                        handleOpen();
                        setDeleteId(schedule?.id);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
export default CourseTab;
