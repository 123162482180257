import React, { useState, useEffect } from "react";
import "./Order.css";

const Ordercom = ({ apiData = [] }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (apiData) {
      setData(apiData);
    }
  }, [apiData]);

  return (
    data && (
      <div className="main-order">
        <div className="firstdiv">
          <table id="table1">
            <thead>
              <tr className="tablerow">
                <th className="order">S.No</th>
                <th className="product">Teacher</th>
                <th>Class Time</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((record, index) => {
                  return (
                    <tr key={index + 1}>
                      <td className="order">{index + 1}</td>
                      <td className="product">
                        <div className="img">
                          <img src={record.teacher} alt="profileImage" />
                          <div className="proinfo">
                            <h3>{record.name}</h3>
                          </div>
                        </div>
                      </td>
                      <td>{record.class}</td>
                      <td>{record.date}</td>

                      <td className="processing">{record.status}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    )
  );
};

export default Ordercom;
