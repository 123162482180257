import imgplan1 from "./Group 1000006263.png";
import imgplan2 from "./vscode-icons_file-type-excel2.png";
import imgplan3 from "./Group 1000006266.png";
import imgplan4 from "./Group 1000006264.png";
import imgplan5 from "./Group 1000006265.png";
import imgplan6 from "./Group 1000006269.png";

const ADDAcademic = [
  { id: 1, title: "School Data", image: imgplan1, exportImage: imgplan2 },
  {
    id: 2,
    title: "Another School Data",
    image: imgplan3,
    exportImage: imgplan2,
  },
  {
    id: 2,
    title: "Another School Data",
    image: imgplan4,
    exportImage: imgplan2,
  },
  {
    id: 2,
    title: "Another School Data",
    image: imgplan5,
    exportImage: imgplan2,
  },
  {
    id: 2,
    title: "Another School Data",
    image: imgplan1,
    exportImage: imgplan2,
  },
  {
    id: 2,
    title: "Another School Data",
    image: imgplan6,
    exportImage: imgplan2,
  },
];

export default ADDAcademic;
