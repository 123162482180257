import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import { baseURL } from "../../../constants";
import { addSchoolId, getAllSchools } from "../../../redux/actions/userAction";
import { ToggleFilterButton } from "../Togglebtn/ToggleFilterButton";
import Dummy from "../../AdminComponents/images/images.png";
import people_img from "../images/24px.png";
import people_imgs from "../images/12.png";
import people_imgg from "../images/434.png";
import "./SchoolList.css";

axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SchoolList = () => {
  const dispatch = useDispatch();
  const { schools } = useSelector((state) => state.getAllSchools);
  const { user } = useSelector((state) => state.getUserProfile);

  console.log(schools, "Nice schools");
  useEffect(() => {
    if (user) {
      dispatch(getAllSchools(user));
    }
  }, [dispatch, user]);

  const [schoolId, setSchoolId] = useState("");
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [schoolsPerPage] = useState(6);
  const [mySwitch, setMySwitch] = useState(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSchoolId("");
  };

  const navigate = useNavigate();

  const handleViewSchool = (schoolId) => {
    localStorage.removeItem("schoolId");
    localStorage.setItem("schoolId", schoolId);
    navigate("/NestedDashbord");
    dispatch(addSchoolId(schoolId));
  };

  const deleteSchoolHandler = async () => {
    try {
      await axios.delete(`/schools/removeSchool/${schoolId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      dispatch(getAllSchools(user));
      handleClose();
      toast.success("School Deleted Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const indexOfLastSchool = currentPage * schoolsPerPage;
  const indexOfFirstSchool = indexOfLastSchool - schoolsPerPage;
  const currentSchools = schools?.slice(indexOfFirstSchool, indexOfLastSchool);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (schools && currentPage < Math.ceil(schools.length / schoolsPerPage))
      setCurrentPage(currentPage + 1);
  };

  const renderPageNumbers = () => {
    if (!schools || schools.length === 0) return null;

    const totalPageCount = Math.ceil(schools.length / schoolsPerPage);
    const pageNumbers = [];

    if (totalPageCount <= 5) {
      for (let i = 1; i <= totalPageCount; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pageNumbers.push(1, 2, 3, 4, "...", totalPageCount);
      } else if (currentPage >= totalPageCount - 2) {
        pageNumbers.push(
          1,
          "...",
          totalPageCount - 3,
          totalPageCount - 2,
          totalPageCount - 1,
          totalPageCount
        );
      } else {
        pageNumbers.push(
          1,
          "...",
          currentPage - 1,
          currentPage,
          currentPage + 1,
          "...",
          totalPageCount
        );
      }
    }

    return pageNumbers.map((number, index) => (
      <button
        key={index}
        onClick={() => number !== "..." && paginate(number)}
        className={currentPage === number ? "active" : ""}
        disabled={number === "..."}
      >
        {number}
      </button>
    ));
  };

  return (
    <>
      <div className="CommentModal-Mmain">
        <Modal
          className="Allowmodalmain"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div className="CommentModal-Mmain-child1">
                <h4>Delete School</h4>
                <p>
                  Are you sure you want to delete this School? This will remove
                  the School and can’t be undone.
                </p>
                <div className="CommentModal-Mmain-child2">
                  <button
                    className="CommentModal-Mmain-child2-allow"
                    onClick={handleClose}
                  >
                    CANCEL
                  </button>
                  <button
                    className="CommentModal-Mmain-child2-Dont-allow"
                    onClick={deleteSchoolHandler}
                  >
                    DELETE
                  </button>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
      <ToggleFilterButton mySwitch={mySwitch} setMySwitch={setMySwitch} />
      {mySwitch ? (
        <div className="SchoolList-main-Card-main">
          {currentSchools &&
            currentSchools.map((school) => (
              <div key={school?.id} className="SchoolList-main-Card">
                <span>
                  <BsThreeDotsVertical
                    onClick={() => {
                      handleOpen();
                      setSchoolId(school?.id);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </span>
                <img
                  style={{ objectFit: "cover", borderRadius: 10 }}
                  src={`${baseURL}/images/${school?.logo}`}
                  alt={`School ${school.logo}`}
                  onError={({ currentTarget }) => {
                    currentTarget.src = Dummy;
                  }}
                />
                <div className="SchoolList-main-Card-child-1">
                  <h2>{school?.name}</h2>
                  <textarea readOnly>
                    {school?.description || "No Description"}
                  </textarea>
                  <div className="SchoolList-main-Card-child-2">
                    <div className="SchoolList-main-Card-child-3">
                      <img src={people_imgs} alt="Person 1" />
                      <img src={people_imgg} alt="Person 2" />
                      <img src={people_img} alt="Person 3" />
                      {/* <p>{school?.population}+</p> */}
                    </div>
                    <div>Population</div>
                  </div>
                  <div
                    className="getSchoolButton"
                    onClick={() => handleViewSchool(school?.id)}
                  >
                    View School
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div className="alternative-schoollist-container">
          {currentSchools &&
            currentSchools.map((school) => (
              <div key={school?.id} className="alternative-main-card">
                <span>
                  <BsThreeDotsVertical
                    onClick={() => {
                      handleOpen();
                      setSchoolId(school?.id);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </span>
                <img
                  style={{ objectFit: "cover", borderRadius: 10 }}
                  src={`${baseURL}/images/${school?.logo}`}
                  alt={`School ${school.logo}`}
                  onError={({ currentTarget }) => {
                    currentTarget.src = Dummy;
                  }}
                />
                <div className="SchoolList-main-Card-child-1">
                  <h2>{school?.name}</h2>
                  <textarea readOnly>
                    {school?.description || "No Description"}
                  </textarea>
                  <div className="SchoolList-main-Card-child-2">
                    <div className="SchoolList-main-Card-child-3">
                      <img src={people_imgs} alt="Person 1" />
                      <img src={people_imgg} alt="Person 2" />
                      <img src={people_img} alt="Person 3" />
                      <p>
                        {Object.entries(school?.population).reduce(
                          (acc, currentValue) => {
                            return acc + currentValue?.[1];
                          },
                          0
                        )}
                        +
                      </p>
                    </div>
                    <div>Population</div>
                  </div>
                  <div
                    className="getSchoolButton"
                    onClick={() => handleViewSchool(school?.id)}
                  >
                    View School
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
      <div className="paginationnn">
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          &laquo;
        </button>
        {renderPageNumbers()}
        <button
          onClick={handleNextPage}
          disabled={currentPage === Math.ceil(schools?.length / schoolsPerPage)}
        >
          &raquo;
        </button>
      </div>
    </>
  );
};

export default SchoolList;
