import React, { useEffect, useMemo, useCallback } from "react";
import "./App.css";
import StuRouting from "./Components/StudentComponents/StudentRouting/StuRouting";
import ADDRouting from "./Components/AdminComponents/ADDRouting/ADDrouting";
import { Toaster } from "react-hot-toast";
import TeacherRouting from "./Components/TeacherComponent/TeacherRouting/TeacherRouting.jsx";
import ParentPoralRouting from "./Components/ParentPortalComponents/ParentPortalRouting/ParentPortalRouting.jsx";
import Authentication from "./Components/Authentication/Authentication.jsx";
import { useDispatch, useSelector } from "react-redux";
import { useSocket } from "./SocketProvider.jsx";

import { setOnlineMembers } from "./redux/reducers/miscReducer.js";
import { SocketProvider } from "./SocketProvider.jsx";
import { getUserProfile } from "./redux/actions/userAction.js";
function App() {
  // eslint-disable-next-line
  const { user, isAuthenticated, loading, error } = useSelector(
    (state) => state.getUserProfile
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile());
    // eslint-disable-next-line
  }, []);

  const socket = useSocket();

  const getOnlineMembers = useCallback((data) => {
    if (data?.length > 0) {
      dispatch(setOnlineMembers(data?.map((d) => d?.[0])));
    } else {
      dispatch(setOnlineMembers([]));
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("ONLINE_MEMBERS", getOnlineMembers);
      return () => {
        socket.off("ONLINE_MEMBERS", getOnlineMembers);
      };
    }
  }, [socket]);

  return (
    <div className="App">
      <Authentication />
      <div>
        <ADDRouting />
        <StuRouting />
        <TeacherRouting />
        <ParentPoralRouting />
      </div>
      <Toaster />
    </div>
  );
}

export default App;
