import React, { useEffect, useState } from "react";
import "./MyDetailModal.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../redux/actions/userAction";

const DetailModal = ({ func = () => {} }) => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);



  const updateAdminProfile = async () => {
    try {
      if (user) {
        await axios.put(
          `/admins/updateaccount/${user.dataValues.id}`,
          {
            firstName,
            lastName,
            email,
            phoneNumber: contactNumber,
          },
          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Profile Updated Successfully");
        func();
      }
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <div>
      <button onClick={onOpenModal}>Edit Information</button>
      <Modal open={open} onClose={onCloseModal} center>
        <div className="detail-modal-main">
          <h2>Edit Account</h2>
          <div className="deatil-modal-first-Name">
            <label htmlFor="First Name*">First Name*</label>
            <div className="detail-modal-input-first-Name">
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                id="text"
                placeholder="First Name"
              />
            </div>
          </div>
          <div className="deatil-modal-last-Name">
            <label htmlFor="last Name*">Last Name*</label>
            <div className="detail-modal-input-last-Name">
              <input
                type="text"
                id="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
              />
            </div>
          </div>
          <div className="deatil-modal-Emails">
            <label htmlFor="Email*">Email*</label>
            <div className="deatil-modal-Emails-your-Email">
              <input
                type="email"
                id="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="example@gmail.com"
              />
            </div>
          </div>
          <div className="deatil-modal-Numbers">
            <label htmlFor="Contact Number*">Contact Number*</label>
            <div>
              <input
                type="number"
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
                id="number"
                placeholder="+123456789"
              />
            </div>
          </div>
          <div className="details-models-btn">
            <button className="details-model-ntn-Cancel" onClick={onCloseModal}>
              Cancel
            </button>
            <button
              className="details-model-ntn-Update"
              onClick={updateAdminProfile}
            >
              Update
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DetailModal;
