import React, { useState, useEffect } from "react";
import "../Medical/Medical.css";
import print from "../../StudentComponents/Medical/flat-color-icons_print.png";
import { IoAddCircle } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import { IoRefreshCircle } from "react-icons/io5";
import axios from "axios";
import { IoArrowBack } from "react-icons/io5";
import { baseURL } from "../../../constants";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
axios.defaults.baseURL = baseURL;

const Medical = () => {
  const [show, setShow] = useState(false);
  function handlePrint() {
    window.print()
  }
  
  const [formData, setFormData] = useState({
    date: "",
    code: "",
    startTime: "",
    endTime: "",
    comment: "",
    HealthProblems: "",
  });

  const { date, code, startTime, endTime, comment, HealthProblems } = formData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const { studentId } = useParams();

  const [plan, setPlan] = useState(null);

  const getPlan = async () => {
    try {
      const { data } = await axios.get(
        `/medicalRecords/medicalRecordsBystudentId/${studentId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );

      setPlan(data?.medicalRecords);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getPlan();
  }, []);

  const [editId, setEditId] = useState("");

  useEffect(() => {
    if (editId && plan) {
      const getCurrentPlan = plan?.find((pln) => {
        return editId === pln.id;
      });

      setFormData({
        date: getCurrentPlan?.date?.split("T")[0],
        code: getCurrentPlan?.code,
        startTime: getCurrentPlan?.startTime,
        endTime: getCurrentPlan?.endTime,
        comment: getCurrentPlan?.comment,
        HealthProblems: getCurrentPlan?.HealthProblems,
      });
      setShow(true);
    }
  }, [editId, plan]);

  const Medical = async () => {
    try {
      if (editId) {
        await axios.put(
          `/medicalRecords/updatemedicalRecord/${editId}`,
          {
            date,
            code,
            startTime,
            endTime,
            comment,
            HealthProblems,
            studentId,
          },

          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Plan Edited Successfully");
        setShow(false);
        setEditId("");
      } else {
        await axios.post(
          `/medicalRecords/addMedicalRecords`,
          {
            date,
            code,
            startTime,
            endTime,
            comment,
            HealthProblems,
            studentId,
          },

          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Plan Added Successfully");
        setShow(false);
        setEditId("");
      }

      getPlan();
      setFormData({
        date: "",
        code: "",
        startTime: "",
        endTime: "",
        comment: "",
        HealthProblems: "",
      });
    } catch (error) {
      console.log(error, "Main containers");
      if (error.response?.data?.details) {
        toast.error(
          error.response?.data?.details[0]?.error ||
            error.response?.data?.details[0]?.message ||
            error.response?.data?.details[0]?.detail
        );
      } else {
        toast.error(error?.response?.data?.error);
      }
    }
  };

  const handleClearData = () => {
    setFormData({
      date: "",
      code: "",
      startTime: "",
      endTime: "",
      comment: "",
      HealthProblems: "",
    });
  };

  const deleteMedical = async () => {
    try {
      const { data } = await axios.delete(
        `/medicalRecords/removemedicalRecord/${editId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Successfully deleted medical!");
      getPlan();
      setEditId("");
      setShow(false)
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else if (error?.response?.data?.error) {
        toast.error(
          error?.response?.data?.error[0]?.detail ||
            error?.response?.data?.error[0]?.message
        );
      } else {
        toast.error(
          error?.response?.data?.message || error?.response?.data?.msg
        );
      }
      console.log(error);
    }
  };


  return (
    <div className="Medical-main">
      {!show ? (
        <div>
          <div className="Medical-child-1">
            <h2>Medical Logarithms Options</h2>
            <button onClick={handlePrint}>
              <img src={print} alt="#" />
              Print the plan
            </button>
          </div>
          <div className="Medical-child-2">
            <h3>
              Health Problems:{" "}
              {plan &&
                plan.map((pln) => {
                  return <span>{`${pln?.HealthProblems}, `}</span>;
                })}
            </h3>
            <button onClick={() => setShow(true)}>
              <IoAddCircle />
              Add new Record
            </button>
          </div>
          <div className="Medical-child-3">
            <div className="Medical-child-3-table">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Code</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                  </tr>
                </thead>
                <tbody>
                  {plan &&
                    plan.map((pln) => {
                      return (
                        <tr>
                          <td>
                            {pln?.date?.split("T")[0]} <br />
                            <span>{pln?.comment}</span>
                          </td>
                          <td>{pln?.code}</td>
                          <td>{pln?.startTime}</td>
                          <td className="MedicalTD">
                            <span>{pln?.endTime}</span>
                            <div className="plan504">
                              <FiEdit
                                onClick={() => setEditId(pln.id)}
                                size={19}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="Medical-child-1">
            <IoArrowBack
              onClick={() => {
                setEditId("");
                setShow(false);
              }}
            />
            <h2>Medical Logarithms Options</h2>
          </div>
          <div className="Medical-child-2">
            <h3>
              Health Problems:{" "}
              <input
                name="HealthProblems"
                value={formData.HealthProblems}
                onChange={handleChange}
                placeholder="Allergy: Peanut "
                type="text"
              />
            </h3>
          </div>
          <div className="Medical-child-3ADD">
            <div className="Medical-child-3ADD-table">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Code</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        name="date"
                        onChange={handleChange}
                        value={formData.date}
                        type="date"
                      />
                    </td>
                    <td>
                      <select
                        value={formData.code}
                        name="code"
                        onChange={handleChange}
                      >
                        <option disabled></option>
                        <option value="Asthma">Asthma</option>
                        <option value="Abrasion">Abrasion</option>
                        <option value="Back/ Neck Injury">
                          Back/ Neck Injury
                        </option>
                        <option value="Chicken Pox">Chicken Pox</option>
                      </select>
                    </td>
                    <td>
                      {" "}
                      <input
                        onChange={handleChange}
                        name="startTime"
                        value={formData.startTime}
                        type="time"
                      />
                    </td>
                    <td>
                      <input
                        onChange={handleChange}
                        name="endTime"
                        value={formData.endTime}
                        type="time"
                      />
                    </td>
                  </tr>
                  <textarea
                    name="comment"
                    onChange={handleChange}
                    value={formData.comment}
                    placeholder="Comment"
                  />
                </tbody>
              </table>
              </div>

            <div className="MedicalTWoButton">
              <div className="MedicalTWoChild">
                {editId && (
                  <button
                    onClick={() => {
                      deleteMedical();
                    }}
                  >
                    Delete
                  </button>
                )}
                <button onClick={handleClearData}>
                    <IoRefreshCircle />
                  Reset
                </button>
                <button
                  onClick={() => {
                    Medical();
                  }}
                >
                  Save new Record
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Medical;
