import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Sidebar from "../TeacherSideBar/Sidebar";
import TeacherTopSearchBar from "../TeacherTopSearchBar/TeacherTopSearchBar";
import TeacherHome from "../TeacherAllComponents/Home/TeacherHome";
import TeacherClassRoomTopSearch from "../TeacherAllComponents/TeacherClassRoom/TeacherClassRoomTopSearch";
import Request from "../TeacherAllComponents/TeacherMockupRequests/Request";
import NestedTabs from "../TeacherAllComponents/TeacherClassRoom/TeacherNestedTabs/NestedTabs";
import ClassRoomTabsSidebar from "../TeacherSideBar/ClassRoomTabsSidebar";
import Teacherattendance from "../TeacherAllComponents/TeacherClassRoom/Teacher-attendance/Teacherattendance";
import Setting from "../setting/Setting";
import AllStudents from "../TeacherAllStudents/AllStudents";
import TuitionInfo from "../StudentProfile/StuSixTabs/TuitionInfo/TuitionInfo";
import StuProfile from "../StudentProfile/StuProfile";
import Calender from "../TeacherAllComponents/CalenderPage/Calender";
import ProfieViews from "../profileViews/ProfieViews";
import TeacherGrades from "../TeacherAllComponents/TeacherClassRoom/TeacherNestedTabs/TeacherStuGrades/TeacherGrades";
import ADDChatBox from "../ADDChatBox/ADDChatBox";
import Submited from "../TeacherAllComponents/TeacherClassRoom/TeacherNestedTabs/createNewTest/StuSubmitedAss/Submited";
import GradeBook from "../GradeBook/GradeBook";
// import TabBook from "../TabGradeBook/TabBook";
import AddPlan from "../Add504Plan/AddPlan";
import Discipline from "../Discipline/Discipline";
import IeProgram from "../IEProgram/IeProgram";
import Medical from "../Medical/Medical";
import MediHistory from "../MedicalHistory/MediHistory";
import { getUserProfile } from "../../../redux/actions/userAction";
import Special from "../TeacherAllComponents/SpecialEducation/Special";
import Extra from "../TeacherAllComponents/ExtraActivity/Extra";
import Notice from "../GetNoticeFromAdmin/Notice";
import Notes from "../TeacherNotes/Notes";
import AddEvent from "../TeacherAllComponents/CalenderPage/AddEvent";
import { useDispatch } from "react-redux";

function TeacherRouting() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);
  return (
    <div>
      <Routes>
        <Route
          path="/teacherhome"
          element={<PrivateRoute component={<TeacherHomeWithSidebar />} />}
        />
        <Route
          path="/TeacherClassRoomTopSearch"
          element={
            <PrivateRoute
              component={<TeacherClassRoomTopSearchWithSidebar />}
            />
          }
        />
        <Route
          path="/MockupRequests"
          element={<PrivateRoute component={<RequestWithSidebar />} />}
        />
        <Route
          path={`/TeacherNestedTabs/:subjectId`}
          element={<PrivateRoute component={<NestedTabsWithSidebar />} />}
        />
        <Route
          path="/Teacherattendance"
          element={
            <PrivateRoute component={<TeacherattendanceWithSidebar />} />
          }
        />
        <Route
          path="/ProfieViews"
          element={<PrivateRoute component={<ProfieViewsWithSidebar />} />}
        />
        <Route
          path="/AllStudents"
          element={<PrivateRoute component={<AllStudentsWithSidebar />} />}
        />
        <Route
          path="/StuProfile/:studentId"
          element={<PrivateRoute component={<StuProfileWithSidebar />} />}
        />
        <Route
          path="/TuitionInfo"
          element={<PrivateRoute component={<TuitionInfoWithSidebar />} />}
        />
        <Route
          path="/TeacherCalender"
          element={<PrivateRoute component={<CalenderWithSidebar />} />}
        />
        <Route
          path="/TeacherAccountSetting"
          element={<PrivateRoute component={<SettingWithSidebar />} />}
        />
        <Route
          path="/GiveGrades"
          element={<PrivateRoute component={<TeacherGradesWithSidebar />} />}
        />
        <Route
          path="/TeacherChat"
          element={<PrivateRoute component={<ADDChatBoxWithSidebar />} />}
        />
        <Route
          path={`/StudentAssingment/:assignmentId`}
          element={<PrivateRoute component={<SubmitedWithSidebar />} />}
        />
        {/* <Route
          path="/TabBook"
          element={<PrivateRoute component={<TabBookWithSidebar />} />}
        /> */}
        <Route
          path="/Gradebook"
          element={<PrivateRoute component={<GradeBookWithSidebar />} />}
        />
        <Route
          path="/Teacher504Plan"
          element={<PrivateRoute component={<AddPlanWithSidebar />} />}
        />
        <Route
          path="/TeacherDiscipline"
          element={<PrivateRoute component={<DisciplineWithSidebar />} />}
        />
        <Route
          path="/TeacherIEProgram"
          element={<PrivateRoute component={<IeProgramWithSidebar />} />}
        />
        <Route
          path="/TeacherMedical"
          element={<PrivateRoute component={<MedicalWithSidebar />} />}
        />
        <Route
          path="/TeacherMedicalHistory"
          element={<PrivateRoute component={<MediHistoryWithSidebar />} />}
        />
        <Route
          path="/Extracurricular"
          element={<PrivateRoute component={<ExtraWithSidebar />} />}
        />
        <Route
          path="/TeacherSpecialEducation"
          element={<PrivateRoute component={<SpecialWithSidebar />} />}
        />
        <Route
          path="/SchoolNoticeboard"
          element={<PrivateRoute component={<NoticeWithSidebar />} />}
        />
        <Route
          path="/TeacherNotes"
          element={<PrivateRoute component={<NotesWithSidebar />} />}
        />
        <Route
          path="/TeacherAddEvents"
          element={<PrivateRoute component={<AddEventWithSidebar />} />}
        />
      </Routes>
    </div>
  );
}
function PrivateRoute({ component }) {
  const isAuthenticated = true;
  if (isAuthenticated) {
    return component;
  } else {
    return <Navigate to="/Login" replace />;
  }
}

function TeacherHomeWithSidebar() {
  return (
    <Sidebar>
      <TeacherTopSearchBar />
      <TeacherHome />
    </Sidebar>
  );
}
// function TabBookWithSidebar() {
//   return (
//     <Sidebar>
//       <TeacherTopSearchBar />
//       <TabBook />
//     </Sidebar>
//   );
// }
function GradeBookWithSidebar() {
  return (
    <Sidebar>
      <TeacherTopSearchBar />
      <GradeBook />
    </Sidebar>
  );
}
function TeacherClassRoomTopSearchWithSidebar() {
  return (
    <Sidebar>
      <TeacherTopSearchBar />
      <TeacherClassRoomTopSearch />
    </Sidebar>
  );
}
function RequestWithSidebar() {
  return (
    <Sidebar>
      <TeacherTopSearchBar />
      <Request />
    </Sidebar>
  );
}
function NestedTabsWithSidebar() {
  return (
    <Sidebar>
      <TeacherTopSearchBar />
      <NestedTabs />
    </Sidebar>
  );
}

function TeacherGradesWithSidebar() {
  return (
    <ClassRoomTabsSidebar>
      <TeacherTopSearchBar />
      <TeacherGrades />
    </ClassRoomTabsSidebar>
  );
}
function TeacherattendanceWithSidebar() {
  return (
    <Sidebar>
      <TeacherTopSearchBar />
      <Teacherattendance />
    </Sidebar>
  );
}
function ProfieViewsWithSidebar() {
  return (
    <Sidebar>
      <TeacherTopSearchBar />
      <ProfieViews />
    </Sidebar>
  );
}
function AllStudentsWithSidebar() {
  return (
    <Sidebar>
      <TeacherTopSearchBar />
      <AllStudents />
    </Sidebar>
  );
}
function StuProfileWithSidebar() {
  return (
    <ClassRoomTabsSidebar>
      <TeacherTopSearchBar />
      <StuProfile />
    </ClassRoomTabsSidebar>
  );
}
// ////////////////////////////////

function SpecialWithSidebar() {
  return (
    <ClassRoomTabsSidebar>
      <TeacherTopSearchBar />
      <Special />
    </ClassRoomTabsSidebar>
  );
}

// /////////////////////
function TuitionInfoWithSidebar() {
  return (
    <ClassRoomTabsSidebar>
      <TeacherTopSearchBar />
      <TuitionInfo />
    </ClassRoomTabsSidebar>
  );
}
function CalenderWithSidebar() {
  return (
    <Sidebar>
      <TeacherTopSearchBar />
      <Calender />
    </Sidebar>
  );
}
function AddEventWithSidebar() {
  return (
    <Sidebar>
      <TeacherTopSearchBar />
      <AddEvent />
    </Sidebar>
  );
}

function SettingWithSidebar() {
  return (
    <Sidebar>
      <TeacherTopSearchBar />
      <Setting />
    </Sidebar>
  );
}
function ADDChatBoxWithSidebar() {
  return (
    <Sidebar>
      <TeacherTopSearchBar />
      <ADDChatBox />
    </Sidebar>
  );
}
function NoticeWithSidebar() {
  return (
    <Sidebar>
      <TeacherTopSearchBar />
      <Notice />
    </Sidebar>
  );
}

function SubmitedWithSidebar() {
  return (
    <ClassRoomTabsSidebar>
      <TeacherTopSearchBar />
      <Submited />
    </ClassRoomTabsSidebar>
  );
}
function NotesWithSidebar() {
  return (
    <ClassRoomTabsSidebar>
      <TeacherTopSearchBar />
      <Notes />
    </ClassRoomTabsSidebar>
  );
}
function AddPlanWithSidebar() {
  return (
    <ClassRoomTabsSidebar>
      <TeacherTopSearchBar />
      <AddPlan />
    </ClassRoomTabsSidebar>
  );
}
function DisciplineWithSidebar() {
  return (
    <ClassRoomTabsSidebar>
      <TeacherTopSearchBar />
      <Discipline />
    </ClassRoomTabsSidebar>
  );
}
function IeProgramWithSidebar() {
  return (
    <ClassRoomTabsSidebar>
      <TeacherTopSearchBar />
      <IeProgram />
    </ClassRoomTabsSidebar>
  );
}
function MedicalWithSidebar() {
  return (
    <ClassRoomTabsSidebar>
      <TeacherTopSearchBar />
      <Medical />
    </ClassRoomTabsSidebar>
  );
}
function MediHistoryWithSidebar() {
  return (
    <ClassRoomTabsSidebar>
      <TeacherTopSearchBar />
      <MediHistory />
    </ClassRoomTabsSidebar>
  );
}

function ExtraWithSidebar() {
  return (
    <ClassRoomTabsSidebar>
      <TeacherTopSearchBar />
      <Extra />
    </ClassRoomTabsSidebar>
  );
}

export default TeacherRouting;
