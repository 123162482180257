import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Summary.css";
import { baseURL } from "../../../../constants";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;

const Summary = () => {
  const [subject, setSubject] = useState(null);

  const params = useParams();
  const [classroomTeacher, setClassroomTeacher] = useState("");
  const getTeacherByClassroom = async () => {
    try {
      const { data } = await axios.get(
        `/classrooms/getTeacherByClassroomId/${localStorage.getItem(
          "classroomId"
        )}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setClassroomTeacher(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTeacherByClassroom();
  }, []);
  const getAllSubjects = async () => {
    try {
      const { data } = await axios.get(
        `/subjects/getSubjectById/${params?.subjectId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setSubject(data?.subject);
    } catch (error) {
      console.log(error);
    }
  };

  const [teacher, setTeacher] = useState(null);

  const dispatch = useDispatch();

  const { user, loading, error } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const [school, setSchool] = useState(null);

  const getSchoolByStudentId = async () => {
    try {
      const { data } = await axios.get(
        `/ParentBySchoolId/getschoolBystudentId/${user?.dataValues?.id}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );

      setSchool(data?.school);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchoolByStudentId();
  }, [user]);

  const getTeacher = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/students/profile/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setTeacher(data?.student);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllSubjects();
    getTeacher();
  }, [user]);

  const { subjectId } = useParams();

  const [schedules, setSchedules] = useState(null);

  const getSchedules = async () => {
    try {
      const { data } = await axios.get(
        `/schedules/getScheduleBySubject/${subjectId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setSchedules(data.schedules);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchedules();
  }, []);

  const [summery, setSummery] = useState(null);

  const getSummery = async () => {
    try {
      if (school) {
        const { data } = await axios.get(
          `/schedules/getScheduleBySchool/${school?.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setSummery(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSummery();
  }, [school]);
  console.log(summery, "these are schedules");
  return (
    <div className="Summary">
      <React.Fragment>
        <div className="Chemistry">
          <div className="btnInfo">
            <h4>Summary</h4>
            <p>
              Subject: <span>{subject?.subjectName}</span>
            </p>
          </div>
          <button className="liveBtn">Join live Class</button>
        </div>
        <div className="ClassTiming">
          <h6>Class timing</h6>
          <div className="Teacher">
            <table>
              <thead>
                <tr>
                  <th>Day</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Total Time</th>
                  <th>Venue</th>
                </tr>
              </thead>
              <tbody>
                {summery &&
                  summery?.schedules?.map((schedule) => {
                    return (
                      <tr>
                        <td>{schedule?.day?.join(", ")}</td>
                        <td>{schedule?.startTime}</td>
                        <td>{schedule?.endTime}</td>
                        <td>{schedule?.totalTime}</td>
                        <td>{schedule?.gradeLevel}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="AboutTeacher">
            <h5>About Teacher</h5>
            <p>
              Teacher Name:
              {classroomTeacher && (
                <span>
                  {classroomTeacher?.firstName} {classroomTeacher?.middleName}
                  {classroomTeacher?.lastName}
                </span>
              )}
            </p>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export default Summary;

function addTime(time1, time2) {
  let [hours1, minutes1, seconds1] = time1.split(":").map(Number);
  let [hours2, minutes2, seconds2] = time2.split(":").map(Number);

  let totalSeconds = seconds1 + seconds2;
  let carryMinutes = Math.floor(totalSeconds / 60);
  seconds1 = totalSeconds % 60;

  let totalMinutes = minutes1 + minutes2 + carryMinutes;
  let carryHours = Math.floor(totalMinutes / 60);
  minutes1 = totalMinutes % 60;

  let totalHours = hours1 + hours2 + carryHours;
  hours1 = totalHours % 24;

  let result = `${String(hours1).padStart(2, "0")}:${String(minutes1).padStart(
    2,
    "0"
  )}:${String(seconds1).padStart(2, "0")}`;

  return result;
}

let time1 = "08:00:00";
let time2 = "10:00:00";
console.log(addTime(time1, time2));
