import React, { useState } from "react";
import "./Grades.css";
import { IoArrowBack } from "react-icons/io5";
import print from "../../../../StudentComponents/Medical/flat-color-icons_print.png";
import { CiSaveDown2 } from "react-icons/ci";
import { MdOutlineRefresh } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoMdAddCircle, IoMdArrowDropdown } from "react-icons/io";
import { FiEdit } from "react-icons/fi";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ChildModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <button onClick={handleOpen} className="DropDownModalGrade">
        Pick a Section <IoMdArrowDropdown />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="AddPIckModall"
      >
        <Box sx={{ ...style, width: 400 }}>
          <div className="TableAddPIck">
            <div className="AddPIckSec">
              <p>Pick a Section</p>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Section No</th>
                  <th>Course</th>
                  <th>Teacher</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>43</td>
                  <td>0304-English 12 CP</td>
                  <td>Turner (816)</td>
                </tr>
                <tr>
                  <td>44</td>
                  <td>0662-Lab Asst Sci</td>
                  <td>Manning (672)</td>
                </tr>
                <tr>
                  <td>45</td>
                  <td>0010-PE 9</td>
                  <td>Sanders (702)</td>
                </tr>
                <tr>
                  <td>46</td>
                  <td>1500-Tchr Aide</td>
                  <td>Sanders (702)</td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <button onClick={handleClose}>Close Child Modal</button> */}
        </Box>
      </Modal>
    </React.Fragment>
  );
}

function Grades() {
  function handlePrint() {
    window.print();
  }
  const [open, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const [openn, setOpenModall] = React.useState(false);
  const handleOpenn = () => setOpenModall(true);
  const handleClosee = () => setOpenModall(false);

  const [show, setShow] = useState(false);
  return (
    <>
      {!show ? (
        <div className="Grades-MainONe">
          <div className="Grades-Top">
            <h3>Grades</h3>
            <div className="GradesTopTwoButtons">
              <button>
                <img src={print} />
                Print
              </button>
              <button onClick={handleOpenn} className="GradesAddNew">
                <IoMdAddCircle />
                Add New Records
              </button>
            </div>
          </div>
          <Modal
            open={openn}
            onClose={handleClosee}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="Modallllllll"
          >
            <Box sx={style}>
              <Typography id="modal-modal-description">
                <div className="AddNewGradeModal">
                  <h5>Section Search</h5>
                  <hr />
                  <div className="SectionSearchCir">
                    <div className="Circleee"></div>
                    <p>
                      Select s section number for the record or click continue
                      to manually add the data
                    </p>
                  </div>
                  <div className="chilDmOdalGrade">
                    <ChildModal />
                    <button
                      onClick={() => setShow(true)}
                      className="ContinueBtnTwo"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </Typography>
            </Box>
          </Modal>
          <div className="GRADESTAbLLEE">
            <table>
              <thead>
                <tr>
                  <th>Per</th>
                  <th>Crs-ID</th>
                  <th>Course</th>
                  <th>Teacher(s)</th>
                  <th>1st Qtr</th>
                  <th>1st Sem</th>
                  <th>3rd Qtr</th>
                  <th>2nd Sem</th>
                  <th>CRD</th>
                  <th>CIT</th>
                  <th>WH</th>
                  <th>ABS</th>
                  <th>TDY</th>
                  <th>Comments</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>P1</td>
                  <td>0317</td>
                  <td>IB Eng HL2</td>
                  <td>May, Ava</td>
                  <td>A</td>
                  <td>A</td>
                  <td>A</td>
                  <td className="GradesTD">A</td>
                  <td>5.00</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>29</td>
                  <td>Barrera</td>
                  <td>
                    <FiEdit onClick={() => setShow(true)} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="Grades-MainONe">
          <div className="Grades-Top">
            <h3>
              <IoArrowBack onClick={() => setShow(false)} />
              Grades
            </h3>
            <div className="GradeThreeBts">
              <button onClick={() => setShow(false)}>
                <CiSaveDown2 />
                Save the Record
              </button>
              <button>
                <MdOutlineRefresh />
                Reset Records
              </button>
              <button onClick={handleOpen}>
                <RiDeleteBin6Line />
                Delete Records
              </button>
            </div>
          </div>
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-description">
                  <div className="AddGradesOverModal">
                    <h4>Comfirm</h4>
                    <p>Are you sure you want to delete this records?</p>
                    <div className="SaveAndCancel">
                      <button className="CancelBtnone" onClick={handleClose}>
                        Cancel
                      </button>
                      <button className="CancelBtnTwo">Yes</button>
                    </div>
                  </div>
                </Typography>
              </Box>
            </Modal>
          </div>
          <div className="GRADESTAbLLEE">
            <table>
              <thead>
                <tr>
                  <th>Per</th>
                  <th>Crs-ID</th>
                  <th>Course</th>
                  <th>Teacher(s)</th>
                  <th>1st Qtr</th>
                  <th>1st Sem</th>
                  <th>3rd Qtr</th>
                  <th>2nd Sem</th>
                  <th>CRD</th>
                  <th>CIT</th>
                  <th>WH</th>
                  <th>ABS</th>
                  <th>TDY</th>
                  <th>Comments</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>P1</td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <select>
                      <option>A</option>
                      <option>A-</option>
                      <option>B+</option>
                      <option>B</option>
                      <option>B-</option>
                      <option>C+</option>
                      <option>C-</option>
                      <option>C</option>
                      <option>D-</option>
                      <option>D</option>
                      <option>F-</option>
                      <option>F</option>
                    </select>
                  </td>
                  <td>
                    <select>
                      <option>A</option>
                      <option>A-</option>
                      <option>B+</option>
                      <option>B</option>
                      <option>B-</option>
                      <option>C+</option>
                      <option>C-</option>
                      <option>C</option>
                      <option>D-</option>
                      <option>D</option>
                      <option>F-</option>
                      <option>F</option>
                    </select>
                  </td>
                  <td>
                    <select>
                      <option>A</option>
                      <option>A-</option>
                      <option>B+</option>
                      <option>B</option>
                      <option>B-</option>
                      <option>C+</option>
                      <option>C-</option>
                      <option>C</option>
                      <option>D-</option>
                      <option>D</option>
                      <option>F-</option>
                      <option>F</option>
                    </select>
                  </td>
                  <td className="GradesTD">
                    <select>
                      <option>A</option>
                      <option>A-</option>
                      <option>B+</option>
                      <option>B</option>
                      <option>B-</option>
                      <option>C+</option>
                      <option>C-</option>
                      <option>C</option>
                      <option>D-</option>
                      <option>D</option>
                      <option>F-</option>
                      <option>F</option>
                    </select>
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <select>
                      <option></option>
                      <option>N: Needs Imporvement</option>
                      <option>O: Outstanding</option>
                      <option>S: Satisfactory</option>
                      <option>U: Unsatisfactory</option>
                    </select>
                  </td>
                  <td>
                    <select>
                      <option></option>
                      <option>N: Needs Imporvement</option>
                      <option>O: Outstanding</option>
                      <option>S: Satisfactory</option>
                      <option>U: Unsatisfactory</option>
                    </select>
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}
export default Grades;
