import React, { useEffect, useState } from "react";
import "./ParentSetting.css";
import PhoneInput from "react-phone-number-input";
import { LuUpload } from "react-icons/lu";
import uploadImage from "../Images/Profile.png";
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";
import { FaPhoneAlt } from "react-icons/fa";
import { baseURL } from "../../../constants";
import toast from "react-hot-toast";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;

const Setting = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [homePhone, setHomePhone] = useState("");
  const [middleName, setMiddleName] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const handleOnChange = (value) => {
    setPhoneNumber(value);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 1024 * 1024) {
        alert("Image size should be less than 1MB.");
        return;
      }
      setSelectedFile(file);
      const objectUrl = URL.createObjectURL(file);
      setImageUrl(objectUrl);
    }
  };

  const updateProfile = async () => {
    try {
      const formData = new FormData();
      formData.append("profilePicture", selectedFile);
      firstName && formData.append("firstName", firstName);
      lastName && formData.append("lastName", lastName);
      middleName && formData.append("middleName", middleName);
      formData.append("homePhone", homePhone);
      formData.append("phoneNumber", phoneNumber);

      if (user) {
        await axios.put(
          `/parents/updateParent/${user.dataValues.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        toast.success("Profile Updated Successfully");
      }
    } catch (err) {
      toast.error(err?.response?.data?.error);
    }
  };

  const [inputText, setInputText] = useState("");

  const countWords = (text) => {
    const words = text.trim().split(/\s+/);
    const wordCount = words.length;

    if (wordCount <= 50) {
      return `${wordCount}/50`;
    } else {
      const truncatedText = words.slice(0, 50).join(" ");
      setInputText(truncatedText);
      return "50/50 (Max reached)";
    }
  };

  const handleInputChange = (event) => {
    const text = event.target.value;
    const countDisplay = countWords(text);
    setInputText(text);
    setWordCountDisplay(countDisplay);
  };

  const [wordCountDisplay, setWordCountDisplay] = useState("0/50");

  const handleSaveChangess = () => {
    if (!firstName || !lastName) {
      alert("Please fill all input fields.");
      return;
    }

    alert("Changes saved successfully!");
  };

  const updatePassword = async () => {
    try {
      if (newPassword !== confirmPassword)
        return toast.error("New Password and confirm password is not matching");

      if (user) {
        let { data } = await axios.put(
          `/students/updatePassword/${user.dataValues.id}`,
          {
            currentPassword,
            newPassword,
            confirmPassword,
          },
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setPasswordChanged(true);
        toast.success("Password Updated Successfully");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  const togglePasswordVisibility = (field) => {
    switch (field) {
      case "current":
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  const handleSaveChanges = () => {
    if (!currentPassword || !newPassword) {
      alert(
        "Please fill in all required fields: Password, Current Password, and New Password."
      );
      return;
    }

    setPasswordsMatch(true);

    if (newPassword !== confirmPassword) {
      setPasswordsMatch(false);
      return;
    }

    setPasswordChanged(true);
    setTimeout(() => {
      setPasswordChanged(false);
    }, 5000);
  };

  const [eventUpdate, setEventUpdate] = useState(false);
  const [gradeUpdate, setGradeUpdate] = useState(false);
  const [assignmentUpdate, setAssignmentUpdate] = useState(false);
  const [messageUpdate, setMessageUpdate] = useState(false);
  const [testUpdate, setTestUpdate] = useState(false);

  const { user, loading, error } = useSelector((state) => state.getUserProfile);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const handleUpdateNotificationsChanges = async () => {
    try {
      if (user) {
        await axios.post(
          `/settings/addOrUpdateParentNotificationSettings`,
          {
            parentId: user.dataValues.id,
            eventUpdate,
            gradeUpdate,
            assignmentUpdate,
            messageUpdate,
            testUpdate,
          },
          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        toast.success("Changes Updated");
      }
    } catch (error) {
      toast.error(error?.response?.data?.details[0]?.message);
    }
  };

  return (
    <div className="Setting-main">
      <div className="setting-child-11">
        <div className="setting-account-setting">
          <h6>Account Settings</h6>
          <div className="account-setting-input-name">
            <label htmlFor="Full name">Full name</label>
            <div className="first-name">
              <input
                type="text"
                id="text"
                placeholder="First name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <input
                type="text"
                id="text"
                placeholder="Middle name"
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
              />
              <input
                type="text"
                id="text"
                placeholder="Last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          <div className="account-setting-input-username">
            <label htmlFor="Full name">Home Number</label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "7px",
              }}
            >
              <FaPhoneAlt />
              <PhoneInput
                placeholder="Your Home number..."
                value={homePhone}
                onChange={(value) => setHomePhone(value)}
              />
            </div>
          </div>
          <div className="account-setting-input-username">
            <label htmlFor="Full name">Phone Number</label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "7px",
              }}
            >
              <FaPhoneAlt />
              <PhoneInput
                placeholder="Your Phone number..."
                value={phoneNumber}
                onChange={(value) => setPhoneNumber(value)}
              />
            </div>
          </div>
        </div>
        <div className="setting-account-upload">
          <div className="upload-profile">
            {!imageUrl ? (
              <img
                src={uploadImage}
                alt="Default"
                style={{ width: "200px", height: "200px" }}
              />
            ) : (
              <img
                src={imageUrl}
                alt="Uploaded"
                style={{ width: "200px", height: "200px" }}
              />
            )}
            <div className="chose-file-btn">
              <LuUpload className="Aiout-icon" />
              <div className="mno">
                <input
                  type="file"
                  id="source-file"
                  onChange={handleFileInputChange}
                />
                <label className="source-file" htmlFor="source-file">
                  Upload Photo
                </label>
              </div>
            </div>
            <p className="image-size">
              Image size should be under 1MB and image ratio needs to be 1:1
            </p>
          </div>
        </div>
      </div>
      <div className="account-setting-btn">
        <button
          onClick={(e) => {
            e.preventDefault();
            updateProfile();
          }}
        >
          Save Changes
        </button>
      </div>

      <div className="setting-child-3">
        <div className="Account-setting-notification">
          <h5>Notifications</h5>
          <div className="Notifications-checkboxes">
            <li>
              <input
                checked={eventUpdate}
                onChange={() => setEventUpdate(!eventUpdate)}
                type="checkbox"
                id="checkbox"
              />
            </li>
            <p>I want to know about student event updates</p>
          </div>
          <div className="Notifications-checkboxes">
            <li>
              <input
                checked={gradeUpdate}
                onChange={() => setGradeUpdate(!gradeUpdate)}
                type="checkbox"
                id="checkbox"
              />
            </li>
            <p>I want to know about student grade updates</p>
          </div>
          <div className="Notifications-checkboxes">
            <li>
              <input
                checked={assignmentUpdate}
                onChange={() => setAssignmentUpdate(!assignmentUpdate)}
                type="checkbox"
                id="checkbox"
              />
            </li>
            <p>I want to know about student assignment updates</p>
          </div>
          <div className="Notifications-checkboxes">
            <li>
              <input
                checked={messageUpdate}
                onChange={() => setMessageUpdate(!messageUpdate)}
                type="checkbox"
                id="checkbox"
              />
            </li>
            <p>I want to know about student message updates</p>
          </div>
          <div className="Notifications-checkboxes">
            <li>
              <input
                checked={testUpdate}
                onChange={() => setTestUpdate(!testUpdate)}
                type="checkbox"
                id="checkbox"
              />
            </li>
            <p>I want to know about student test updates</p>
          </div>

          <div
            onClick={handleUpdateNotificationsChanges}
            className="setting-notification-btn"
          >
            <button>Save changes</button>
          </div>
        </div>

        <div className="Account-setting-Change-password">
          <h5>Change password</h5>

          <div className="Account-setting-Current-Password">
            <label htmlFor="Current Password">Current Password</label>
            <div className="Change-password-input">
              <input
                type={showCurrentPassword ? "text" : "password"}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder="Current Password"
              />
              {showCurrentPassword ? (
                <FiEyeOff onClick={() => togglePasswordVisibility("current")} />
              ) : (
                <FiEye onClick={() => togglePasswordVisibility("current")} />
              )}
            </div>
          </div>

          <div className="Account-setting-New-Password">
            <label htmlFor="New Password">New Password</label>
            <div className="New-Password-input">
              <input
                type={showNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="New Password"
              />
              {showNewPassword ? (
                <FiEyeOff onClick={() => togglePasswordVisibility("new")} />
              ) : (
                <FiEye onClick={() => togglePasswordVisibility("new")} />
              )}
            </div>
          </div>

          <div className="Account-setting-Confirm-Password">
            <label htmlFor="Confirm-Password">Confirm Password</label>
            <div className="Confirm-password-input">
              <input
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm Password"
              />
              {showConfirmPassword ? (
                <FiEyeOff onClick={() => togglePasswordVisibility("confirm")} />
              ) : (
                <FiEye onClick={() => togglePasswordVisibility("confirm")} />
              )}
            </div>
          </div>
          {!passwordsMatch && (
            <div style={{ color: "red", marginTop: "10px" }}>
              Passwords don't match!
            </div>
          )}

          {passwordChanged && (
            <div style={{ color: "green", marginTop: "10px" }}>
              Password changed successfully!
            </div>
          )}

          <div className="setting-password-btn">
            <button
              onClick={() => {
                updatePassword();
              }}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
