import React, { useEffect, useState } from "react";
import "./AdminstratorDashbord.css";
import AddUsersActive from "../AdminstratorDashbord/AddUsersActive/AddUsersActive";
import LineChart from "./LineChart/LineChart";
import CircleChart from "./CircleChart/CircleChart";
import ADDBarChart from "./BarChart/ADDBarChart";
import PartnerLocatorMap from "./ADDMap/PartnerLocatorMap";
import axios from "axios";
import { baseURL } from "../../../../constants";
axios.defaults.baseURL = baseURL;

const AdminstratorDashbord = () => {
  // const gaugeValue = 75;
  // const gaugeMax = 100;

  const [financialOverview, setFinancialOverview] = useState(null);
  const getFinancialOverview = async () => {
    try {
      const { data } = await axios.get(
        "/financials/getFinancialOverviewAnalytics",
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setFinancialOverview(data);
    } catch (error) {
      console.log(error);
    }
  };

  const [vendorScore, setVendorScore] = useState(null);

  const getVendorScore = async () => {
    try {
      const { data } = await axios.get(
        "/VendercentralityScore/getVenderontralityScore",
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setVendorScore(data);
    } catch (error) {
      console.log(error);
    }
  };


  const [centralityScore, setCentralityScore] = useState(null);

  const getCentralityScore = async () => {
    try {
      const { data } = await axios.get(
        "/AppCentralityScore/getAppCentralityScore",
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setCentralityScore(data);
    } catch (error) {
      console.log(error);
    }
  };

  const [scores, setScores] = useState(null);

  const getScores = async () => {
    try {
      const { data } = await axios.get("/centralitys/getCentralityScore", {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setScores(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFinancialOverview();
    getVendorScore();
    getCentralityScore();
    getScores();
  }, []);

  return (
    <div>
      <AddUsersActive />
      <div className="twoCharts">
        <div className="AddChart">
          {/* {vendorScore && centralityScore && (
            <LineChart data1={centralityScore} data2={vendorScore} />
          )} */}
          <LineChart />
        </div>
        <div className="circleChart">
          {/* {scores && (
            <CircleChart
              data1={scores.appCentrality}
              data2={scores.vendorCentrality}
            />
          )} */}
          <CircleChart />
        </div>
      </div>
      <div className="barChartMap">
        <div className="ADDBarChart">
          {/* {financialOverview && <ADDBarChart data={financialOverview} />} */}
          <ADDBarChart />
        </div>
        <div className="ADDMAP">
          <PartnerLocatorMap />
        </div>
      </div>
      {/* <ADDMeterChart /> */}
      {/* value={gaugeValue} max={gaugeMax} label="Gauge Example" */}
    </div>
  );
};

export default AdminstratorDashbord;
