import React, { useEffect, useState } from "react";
import "./Login.css";
import LoginCrtn from "../../../StudentComponents/images/Saly-10.png";
import Logo from "../../../StudentComponents/images/Logo.png";
import { MdOutlineMailOutline } from "react-icons/md";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { FcGoogle } from "react-icons/fc";
import Loginmodal from "../RoleSelection/LoginModal/Loginmodal";
import axios from "axios";
import { baseURL } from "../../../../constants";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader from "../../../AdminComponents/Loader/Loader";
axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  const login = async (username, password, role) => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        "/auth/signin",
        {
          email: username,
          password,
          role,
        },
        {
          headers: {
            "Content-Type": "application/json",
            withCredentials: true,
          },
        }
      );

      setLoading(false);
      setUser(data.user);
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");

      console.log(data?.accessToken, "this is real token");
      localStorage.setItem("token", data?.accessToken);

      localStorage.setItem(
        "user",
        JSON.stringify({
          ...data.user,
          role,
        })
      );
      setIsLoggedIn(true);
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else if (error?.response.data?.error) {
        toast.error(
          error?.response?.data?.error[0]?.detail ||
            error?.response?.data?.error[0]?.message
        );
      } else {
        toast.error(
          error?.response?.data?.message || error?.response?.data?.msg
        );
      }
    }
  };

  useEffect(() => {
    setRole(window.location.search.split("=")[1]);
  }, [window.location.search]);
  const handleLogin = async (e) => {
    e.preventDefault();
    login(username, password, role);
  };

  const navigate = useNavigate();
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const refreshToken = localStorage.getItem("refreshToken");
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    if (refreshToken && accessToken) {
      if (loggedInUser?.role === "teacher") {
        navigate("/teacherhome");
        window.location.reload();
      } else if (loggedInUser?.role === "student") {
        navigate("/Home");
        window.location.reload();
      } else if (loggedInUser?.role === "admin") {
        navigate("/AdminstratorDashbord");
        window.location.reload();
      } else if (loggedInUser?.role === "parent") {
        navigate("/Parentportal");
        window.location.reload();
      }
    }
  }, [loggedInUser, refreshToken, accessToken]);

  const [isVerified, setIsVerified] = useState(false);

  const handleRecaptchaChange = (value) => {
    setIsVerified(true);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return loading && !user ? (
    <Loader />
  ) : (
    <>
      <form onSubmit={handleLogin}>
        <div className="LoginLOGO">
          <img src={Logo} alt="#" />
          <h2>MyCentrality</h2>
        </div>
        <div className="LOgin-MAIN">
          <div className="CRTN-IMG">
            <img src={LoginCrtn} alt="#" />
          </div>
          <div className="LOgin_page">
            <div className="Login-Head-LOgo">
              <img src={Logo} alt="#" />
              <h2>MyCentrality</h2>
            </div>
            <h4>Sign In with MyCentrality</h4>
            <p>Welcome back! please enter your detail</p>
            <div className="LoginUsername">
              <MdOutlineMailOutline />
              <input
                placeholder="Email"
                type="email"
                required
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
            </div>
            <div className="password-container">
              <LockOutlinedIcon />
              <div className="password-input">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required
                />
                <span
                  className="toggle-password"
                  onClick={handleTogglePassword}
                >
                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                </span>
              </div>
            </div>
            <div className="CheckBox-input">
              <span>
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                />
                <label for="vehicle1">Remember me</label>
                <br></br>
              </span>
              <div className="CSSForSignUpButton">
                <Link to={`/ForgotPassword?role=${role}`}>
                  Forgot Password?
                </Link>
              </div>
            </div>
            <div className="TermsAndPolicy">
              <h3>
                I hereby agree to the Website <ins>Terms of Use</ins>
                and the <ins>Privacy Policy</ins>
              </h3>
              <input type="checkbox" />
            </div>
            <button className="LoginButton">
              {isLoggedIn ? (
                <Loginmodal
                  role={role}
                  setIsLoggedIn={setIsLoggedIn}
                  error={error}
                  isLoggedIn={isLoggedIn}
                  user={user}
                  loading={loading}
                />
              ) : null}
              Continue
            </button>
            <button className="GoogleButn">
              <FcGoogle />
              Sign In with Google
            </button>
            <button className="SS0Btn">Sign In with SSO</button>
            {role === "admin" && (
              <Link to="/SignUp" className="SS0Btn">
                Sign Up
              </Link>
            )}
          </div>
        </div>
      </form>
    </>
  );
}

export default Login;
