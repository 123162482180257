import React, { useEffect, useState } from "react";
import "./Setting.css";
import "react-phone-input-2/lib/style.css";
import { LuUpload } from "react-icons/lu";
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";
import axios from "axios";
import { baseURL } from "../../../constants";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../redux/actions/userAction";
import PhoneInput from "react-phone-number-input";
import { FaPhoneAlt } from "react-icons/fa";
import Dummy from "../../AdminComponents/images/dummy-user.png";

const Setting = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.getUserProfile);
  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 1024 * 1024) {
        alert("Image size should be less than 1MB.");
        return;
      }
      setSelectedFile(file);
      const objectUrl = URL.createObjectURL(file);
      setImageUrl(objectUrl);
    }
  };

  const [teacher, setTeacher] = useState(null);
  const getTeacher = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/teachers/getProfile/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setTeacher(data?.profile);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTeacher();
  }, [user]);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [staffName, setStaffName] = useState("");
  const [staffId, setStaffId] = useState("");
  const [alternateId, setAlternateId] = useState("");
  const [stateId, setStateId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [govIssuedNumber, setGovIssuedNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [countryOfBirth, setCountryOfBirth] = useState("");
  const [nationality, setNationality] = useState("");
  const [nativeLanguage, setNativeLanguage] = useState("");
  const [languagesSpoken, setLanguagesSpoken] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [profile, setProfile] = useState("");

  useEffect(() => {
    if (teacher) {
      setFirstName(teacher?.firstName);
      setMiddleName(teacher?.middleName);
      setLastName(teacher?.lastName);
      setStaffName(teacher?.staffName);
      setStaffId(teacher?.staffId);
      setAlternateId(teacher?.alternateId);
      setStateId(teacher?.stateId);
      setDistrictId(teacher?.districtId);
      setGovIssuedNumber(teacher?.govIssuedNumber);
      setDateOfBirth(
        new Date(teacher?.dateOfBirth)?.toISOString()?.split("T")[0]
      );
      setGender(teacher?.gender);
      setCountryOfBirth(teacher?.countryOfBirth);
      setNationality(teacher?.nationality);
      setNativeLanguage(teacher?.nativeLanguage);
      console.log(teacher?.languagesSpoken, "Juuuu container");
      setLanguagesSpoken(
        Array.isArray(teacher?.languagesSpoken)
          ? teacher?.languagesSpoken?.join(", ")
          : ""
      );
      setPhoneNumber(teacher?.phoneNumber);
      setProfile(teacher?.profile);
    }
  }, [teacher]);

  const updateProfile = async () => {
    try {
      const formData = new FormData();
      selectedFile && formData.append("profilePicture", selectedFile);
      firstName && formData.append("firstName", firstName);
      lastName && formData.append("lastName", lastName);
      title && formData.append("title", title);
      biography && formData.append("biography", biography);
      phoneNumber && formData.append("phoneNumber", phoneNumber);
      let response = axios.put(
        `${baseURL}/students/updateProfile/2`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const [inputText, setInputText] = useState("");

  const countWords = (text) => {
    const words = text.trim().split(/\s+/);
    const wordCount = words.length;

    if (wordCount <= 50) {
      return `${wordCount}/50`;
    } else {
      const truncatedText = words.slice(0, 50).join(" ");
      setInputText(truncatedText);
      return "50/50 (Max reached)";
    }
  };

  const handleInputChange = (event) => {
    const text = event.target.value;
    const countDisplay = countWords(text);
    setInputText(text);
    setWordCountDisplay(countDisplay);
  };

  const [wordCountDisplay, setWordCountDisplay] = useState("0/50");

  const handleSaveChangess = () => {
    alert("Changes saved successfully!");
  };

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [title, setTitle] = useState("");
  const [biography, setBioGraphy] = useState("");

  const togglePasswordVisibility = (field) => {
    switch (field) {
      case "current":
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  const handleSaveChanges = () => {
    if (!currentPassword || !newPassword) {
      alert(
        "Please fill in all required fields: Password, Current Password, and New Password."
      );
      return;
    }

    setPasswordsMatch(true);

    if (newPassword !== confirmPassword) {
      setPasswordsMatch(false);
      return;
    }

    setPasswordChanged(true);
    setTimeout(() => {
      setPasswordChanged(false);
    }, 5000);
  };

  const handleUpdateProfile = async () => {
    try {
      const newForm = new FormData();

      firstName && newForm.append("firstName", firstName);
      middleName && newForm.append("middleName", middleName);
      lastName && newForm.append("lastName", lastName);
      staffName && newForm.append("staffName", staffName);
      staffId && newForm.append("staffId", staffId);
      alternateId && newForm.append("alternateId", alternateId);
      stateId && newForm.append("stateId", stateId);
      districtId && newForm.append("districtId", districtId);
      govIssuedNumber && newForm.append("govIssuedNumber", govIssuedNumber);
      dateOfBirth && newForm.append("dateOfBirth", dateOfBirth);
      gender && newForm.append("gender", gender);
      countryOfBirth && newForm.append("countryOfBirth", countryOfBirth);
      nationality && newForm.append("nationality", nationality);
      nativeLanguage && newForm.append("nativeLanguage", nativeLanguage);
      languagesSpoken.split(",").forEach((element) => {
        element && newForm.append("languagesSpoken[]", element);
      });
      phoneNumber && newForm.append("phoneNumber", phoneNumber);
      selectedFile && newForm.append("profilePicture", selectedFile);
      profile && newForm.append("profile", profile);

      if (user) {
        await axios.put(
          `/teachers/updateTeacher/${user.dataValues.id}`,
          newForm,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        toast.success("Profile Updated Successfully");
        getTeacher();
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.message ||
            error?.response?.data?.details[0]?.detail
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const [makeUpRequest, setMakeUpRequest] = useState(false);
  const [assignmentSubmit, setAssignmentSubmit] = useState(false);
  const [testSubmit, setTestSubmit] = useState(false);
  const [eventUpdate, setEventUpdate] = useState(false);
  const [messageUpdate, setMessageUpdate] = useState(false);
  const [noticeUpdate, setNoticeUpdate] = useState(false);
  const [profileVisit, setProfileVisit] = useState(false);
  const [meetingRequest, setMeetingRequest] = useState(false);
  const [chatRequest, setChatRequest] = useState(false);
  const handleUpdateNotifications = async () => {
    try {
      if (user) {
        await axios.post(
          `/settings/addOrUpdateTeacherNotificationSettings`,
          {
            teacherId: user.dataValues.id,
            makeUpRequest,
            assignmentSubmit,
            testSubmit,
            eventUpdate,
            messageUpdate,
            noticeUpdate,
            profileVisit,
            meetingRequest,
            chatRequest,
          },
          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Changes Updated Successfully");
      }
    } catch (error) {
      toast.error(error?.response?.data?.details[0]?.message);
    }
  };
  const updatePassword = async () => {
    try {
      if (newPassword !== confirmPassword)
        return toast.error("New password and confirm password is not matching");

      if (user) {
        await axios.put(
          `/teachers/updatePassword/${user.dataValues.id}`,
          {
            currentPassword,
            newPassword,
          },
          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Password Updated Successfully");
      }
    } catch (err) {
      if (err?.response?.data?.details) {
        toast.error(
          err?.response?.data?.details[0]?.detail ||
            err?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
  };

  return (
    <div className="ParentSetting-main">
      <div className="setting-child-1">
        <div className="setting-account-setting">
          <h6>Account Settings</h6>
          <div className="account-setting-input-name">
            <div className="first-name1">
              <input
                type="text"
                id="text"
                placeholder="First name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <input
                type="text"
                id="text"
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
                placeholder="Middle Name"
              />
              <input
                type="text"
                id="text"
                placeholder="Last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div className="account-setting-input-name">
            <div className="first-name1">
              <input
                type="text"
                id="text"
                value={staffName}
                onChange={(e) => setStaffName(e.target.value)}
                placeholder="Staff Name"
              />
              <input
                type="text"
                value={staffId}
                onChange={(e) => setStaffId(e.target.value)}
                id="text"
                placeholder="Staff Id"
              />
              <input
                type="text"
                value={alternateId}
                onChange={(e) => setAlternateId(e.target.value)}
                id="text"
                placeholder="Alternate Id"
              />
            </div>
          </div>
          <div className="account-setting-input-name">
            <div className="first-name1">
              <input
                type="text"
                value={stateId}
                onChange={(e) => setStateId(e.target.value)}
                id="text"
                placeholder="State Id"
              />
              <input
                type="text"
                id="text"
                value={districtId}
                onChange={(e) => setDistrictId(e.target.value)}
                placeholder="District Id"
              />
              <input
                type="text"
                id="text"
                value={govIssuedNumber}
                onChange={(e) => setGovIssuedNumber(e.target.value)}
                placeholder="GovIssued Number"
              />
            </div>
          </div>
          <div className="account-setting-input-name">
            <div className="first-name1">
              <input
                type="date"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
                id="text"
                placeholder="Date Of Birth"
              />
              <input
                type="date"
                value={countryOfBirth}
                onChange={(e) => setCountryOfBirth(e.target.value)}
                id="text"
                placeholder="Country Of Birth"
              />

              <div className="gander">
                <label>Male</label>
                <input
                  value={"male"}
                  onChange={(e) => setGender(e.target.value)}
                  name="radio"
                  type="radio"
                  checked={gender === "male"}
                />
                <label>Female</label>
                <input
                  value={"female"}
                  onChange={(e) => setGender(e.target.value)}
                  name="radio"
                  type="radio"
                  checked={gender === "female"}
                />
                <label>Non-Binary</label>
                <input
                  value={"other"}
                  onChange={(e) => setGender(e.target.value)}
                  name="radio"
                  type="radio"
                  checked={gender === "other"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="setting-account-upload">
          <div className="upload-profile">
            {!imageUrl ? (
              <img
                src={`${baseURL}/images/${teacher?.profilePicture}`}
                alt="Default"
                style={{ width: "200px", height: "200px" }}
                onError={({ currentTarget }) => {
                  currentTarget.src = Dummy;
                }}
              />
            ) : (
              <img
                src={imageUrl}
                alt="Uploaded"
                style={{ width: "200px", height: "200px" }}
              />
            )}
            <div className="chose-file-btn">
              <LuUpload className="Aiout-icon" />
              <div className="mno">
                <input
                  type="file"
                  id="source-file"
                  onChange={handleFileInputChange}
                />
                <label className="source-file" htmlFor="source-file">
                  Upload Photo
                </label>
              </div>
            </div>
            <p className="image-size">
              Image size should be under 1MB and image ratio needs to be 1:1
            </p>
          </div>
        </div>
      </div>
      <div className="account-setting-input-name">
        <div className="first-name1">
          <input
            type="text"
            id="text"
            value={nationality}
            onChange={(e) => setNationality(e.target.value)}
            placeholder="Nationality"
          />
          <input
            type="text"
            value={nativeLanguage}
            onChange={(e) => setNativeLanguage(e.target.value)}
            id="text"
            placeholder="Native Language"
          />
        </div>
      </div>
      <div className="account-setting-input-name">
        <div className="first-name13">
          <input
            type="text"
            value={languagesSpoken}
            onChange={(e) => setLanguagesSpoken(e.target.value)}
            id="text"
            placeholder="Language1, Language2"
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "7px",
              width: "100%",
            }}
          >
            <FaPhoneAlt />
            <PhoneInput
              placeholder="Enter contact number"
              value={phoneNumber}
              onChange={(value) => setPhoneNumber(value)}
            />
          </div>
          <input
            type="text"
            id="text"
            value={profile}
            onChange={(e) => setProfile(e.target.value)}
            placeholder="Profile"
          />
        </div>
      </div>
      <div className="setting-notification-btn" style={{ marginTop: "15px" }}>
        <button onClick={handleUpdateProfile}>Save changes</button>
      </div>
      <div className="setting-child-3">
        <div className="Account-setting-notification">
          <h5>Notifications</h5>
          <div className="Notifications-checkboxes">
            <input
              checked={makeUpRequest}
              onChange={() => setMakeUpRequest(!makeUpRequest)}
              type="checkbox"
            />
            <p>I want to know about makeup requests</p>
          </div>
          <div className="Notifications-checkboxes">
            <li>
              <input
                checked={assignmentSubmit}
                onChange={() => setAssignmentSubmit(!assignmentSubmit)}
                type="checkbox"
                id="checkbox"
              />
            </li>
            <p>I want to know about submitt assignments</p>
          </div>
          <div className="Notifications-checkboxes">
            <li>
              <input
                checked={testSubmit}
                onChange={() => setTestSubmit(!testSubmit)}
                type="checkbox"
                id="checkbox"
              />
            </li>
            <p>I want to know about submit tests</p>
          </div>
          <div className="Notifications-checkboxes">
            <li>
              <input
                type="checkbox"
                checked={eventUpdate}
                onChange={() => setEventUpdate(!eventUpdate)}
                id="checkbox"
              />
            </li>
            <p>I want to know about event updates</p>
          </div>
          <div className="Notifications-checkboxes">
            <li>
              <input
                type="checkbox"
                checked={messageUpdate}
                onChange={() => setMessageUpdate(!messageUpdate)}
                id="checkbox"
              />
            </li>
            <p>I want to know about message updates</p>
          </div>
          <div className="Notifications-checkboxes">
            <li>
              <input
                type="checkbox"
                checked={noticeUpdate}
                onChange={() => setNoticeUpdate(!noticeUpdate)}
                id="checkbox"
              />
            </li>
            <p>I want to know about notice updates</p>
          </div>
          <div className="Notifications-checkboxes">
            <li>
              <input
                type="checkbox"
                checked={profileVisit}
                onChange={() => setProfileVisit(!profileVisit)}
                id="checkbox"
              />
            </li>
            <p>I want to know about profile visits</p>
          </div>
          <div className="Notifications-checkboxes">
            <li>
              <input
                type="checkbox"
                checked={meetingRequest}
                onChange={() => setMeetingRequest(!meetingRequest)}
                id="checkbox"
              />
            </li>
            <p>I want to know about meeting requests</p>
          </div>
          <div className="Notifications-checkboxes">
            <li>
              <input
                type="checkbox"
                checked={chatRequest}
                onChange={() => setChatRequest(!chatRequest)}
                id="checkbox"
              />
            </li>
            <p>I want to know about chat requests</p>
          </div>
          <div
            onClick={handleUpdateNotifications}
            className="setting-notification-btn"
          >
            <button>Save changes</button>
          </div>
        </div>

        <div className="Account-setting-Change-password">
          <h5>Change password</h5>

          <div className="Account-setting-Current-Password">
            <label htmlFor="Current Password">Current Password</label>
            <div className="Change-password-input">
              <input
                type={showCurrentPassword ? "text" : "password"}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder="Current Password"
              />
              {showCurrentPassword ? (
                <FiEyeOff onClick={() => togglePasswordVisibility("current")} />
              ) : (
                <FiEye onClick={() => togglePasswordVisibility("current")} />
              )}
            </div>
          </div>

          <div className="Account-setting-New-Password">
            <label htmlFor="New Password">New Password</label>
            <div className="New-Password-input">
              <input
                type={showNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="New Password"
              />
              {showNewPassword ? (
                <FiEyeOff onClick={() => togglePasswordVisibility("new")} />
              ) : (
                <FiEye onClick={() => togglePasswordVisibility("new")} />
              )}
            </div>
          </div>

          <div className="Account-setting-Confirm-Password">
            <label htmlFor="Confirm-Password">Confirm Password</label>
            <div className="Confirm-password-input">
              <input
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm Password"
              />
              {showConfirmPassword ? (
                <FiEyeOff onClick={() => togglePasswordVisibility("confirm")} />
              ) : (
                <FiEye onClick={() => togglePasswordVisibility("confirm")} />
              )}
            </div>
          </div>
          {!passwordsMatch && (
            <div style={{ color: "red", marginTop: "10px" }}>
              Passwords don't match!
            </div>
          )}

          {passwordChanged && (
            <div style={{ color: "green", marginTop: "10px" }}>
              Password changed successfully!
            </div>
          )}

          <div className="setting-password-btn">
            <button
              onClick={() => {
                updatePassword();
              }}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
