import React, { useState, useEffect } from "react";
import "./ExtraActivities.css";
import Print from "../../StudentComponents/Medical/flat-color-icons_print.png";
import axios from "axios";
import { baseURL } from "../../../constants";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
axios.defaults.baseURL = baseURL;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function ExtraActivities() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 3000);
    return () => {
      clearTimeout();
    };
  }, []);
  const [acitivities, setActivities] = useState([]);
  function handlePrint() {
    window.print();
  }
  const { studentId } = useSelector((state) => state.studentId);

  const getActivity = async () => {
    try {
      if (studentId) {
        const { data } = await axios.get(
          `/extracurricularActivitys/getExtracurricularActivitiesByStudentId/${studentId}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setActivities(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getActivity();
  }, [studentId]);

  return (
    <div className="ExtraMain">
      <div className="ExtraMainTop">
        <h3>Extracurricular Activities</h3>
        <button onClick={handlePrint}>
          <img src={Print} />
          Print
        </button>
      </div>
      <div className="ExtraTable">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Extracurricular Activity</th>
              <th>Hours</th>
              <th>Start Time</th>
              <th>End Time</th>
            </tr>
          </thead>
          <tbody>
            {acitivities.map((activity, index) => (
              <tr key={index}>
                <td>{activity?.date?.split("T")[0]}</td>
                <td>{activity?.type}</td>
                <td>{activity?.code}</td>
                <td>{activity?.hours}</td>
                <td>{activity?.startTime}</td>
                <td>{activity?.endTime}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="TestSubModal">
                <h4>Karate Reminder</h4>
                <p>
                  Did you attend Karate at <span>4:00</span> PM today?
                </p>
                <div className="TestMLastBtns">
                  <button onClick={handleClose} className="TestMLastBtns1">
                    Yes, I attended
                  </button>
                  <button onClick={handleClose} className="TestMLastBtns2">
                    No, I did not attend
                  </button>
                </div>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
export default ExtraActivities;
