import React, { useEffect, useState } from "react";
import "./MySchedule.css";
import { GrFormNext } from "react-icons/gr";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getStudentEvents } from "../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;

const MySchedule = () => {
  const [events, setEvents] = useState([]);
  const [school, setSchool] = useState(null);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.getUserProfile);

  const getSchool = async () => {
    try {
      const { data } = await axios.get(
        `/ParentBySchoolId/getschoolBystudentId/${user?.dataValues?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            accessToken: localStorage.getItem("token"),
          },
        }
      );
      setSchool(data.school);
    } catch (error) {
      console.log(error);
    }
  };
  const {
    loading,
    events: dispatchEvents,
    error: dispatchError,
  } = useSelector((state) => state.events);

  const getEvents = async () => {
    try {
      if (!school) return;
      const { data } = await axios.get(`/events/getSchoolEvents/${school.id}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setEvents(data.events);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user) {
      getSchool();
    }
  }, [user]);

  useEffect(() => {
    if (school) {
      dispatch(getStudentEvents(school?.id));
    }
  }, [school]);

  console.log(events, "this is my event");

  return (
    <div className="myschedule-main">
      <div className="myschedule-heading">
        <h1>My Schedule</h1>
        <Link to="/Calender">
          <span>See All</span>
        </Link>
      </div>
      <div className="CalenderEvents StudentSchedules ">
        {dispatchEvents?.slice(0, 3)?.map((event, index) => {
          return (
            <div
              key={event.id}
              className="myschedule-child"
              style={{
                marginTop: "19px",
                backgroundColor: event?.color || "#FFF0F7",
              }}
            >
              <div className="myschedule-child-data">
                <div
                  className="myschedule-child-date"
                  style={{
                    background: "#FF1D86",
                  }}
                >
                  <p>{index + 1}</p>
                </div>
                <div className="mychild-myschedule">
                  <div className="myschedule-time">
                    <h6>{event?.eventName}</h6>
                    <div className="myschedule-mother">
                      <p>
                        {moment(event?.startdate).format("YYYY Do MM:hh:mm:ss")}
                      </p>
                      <span
                        style={{
                          color: "#FF1D86",
                          fontSize: "14px",
                        }}
                      >
                        ●
                      </span>
                      <p>
                        {moment(event?.enddate).format("YYYY Do MM:hh:mm:ss")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="myschedule-icon">
                  <Link to="/MyAssingmentSub">
                    <GrFormNext style={{ width: "18px", height: "18px" }} />
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MySchedule;
