import React, { useState } from "react";
import { IoCallOutline } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";
import "./CardView.css";
import Dummy from "../../images/dummy-user.png";
import Pagination from "react-js-pagination";
import { Link, useParams } from "react-router-dom";
import { baseURL } from "../../../../constants";

const CardView = ({ cardData }) => {
  const params = useParams();

  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 8;

  const pageCount = Math.ceil(cardData.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, cardData.length);
  const displayedData = cardData.slice(startIndex, endIndex);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <div className="parent-card-view">
      <div className="grid-container">
        {cardData &&
          displayedData &&
          displayedData.map((cdData) => {
            return (
              <Link
                to={`/StaffProfile/${cdData?.id}`}
                style={{ textDecoration: "none" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "8px",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: "15px",
                    paddingBlock: "14px",
                  }}
                >
                  <img
                    src={`${baseURL}/images/${cdData?.image}`}
                    style={{
                      width: "80px",
                      height: "80px",
                      objectFit: "cover",
                      borderRadius: "100%",
                    }}
                    onError={({ currentTarget }) => {
                      currentTarget.src = Dummy;
                    }}
                  />
                  <p
                    style={{
                      color: "#303972",
                      fontWeight: 700,
                      fontSize: "18px",
                    }}
                  >
                    {cdData?.staffName}
                  </p>
                  <p
                    style={{
                      color: "#a098ae",
                      fontWeight: 400,
                      fontSize: "14px",
                    }}
                  >
                    {cdData?.staffId}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: "#00afef",
                        borderRadius: "100%",
                        padding: ".2rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <IoCallOutline color="white" />
                    </div>
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: "#00afef",
                        borderRadius: "100%",
                        padding: ".2rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <MdOutlineMail color="white" />
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
      </div>
      <div className="footerr">
        <p className="footer-text">
          Showing from {currentPage} - {pageCount} from {cardData.length}
        </p>
        <Pagination
          itemClass="pagination-button"
          activePage={currentPage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={cardData.length}
          pageRangeDisplayed={5}
          activeClass="active-page"
          linkClass="button-link"
          activeLinkClass="button-active-link"
          onChange={(e) => setCurrentPage(e)}
          hideFirstLastPages
          hideDisabled
        />
      </div>
      <p className="footerr-text">© 2023 MyCentrality. All Rights Reserved.</p>
    </div>
  );
};

export default CardView;
