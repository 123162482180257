import React, { useEffect } from "react";

const PartnerLocatorMap = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&callback=initMap`;
    script.async = true;
    script.defer = true;

    script.onerror = () => {
      console.error("Error loading Google Maps API script.");
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);
  window.initMap = () => {
    const map = new window.google.maps.Map(
      document.getElementById("partnerLocatorMap"),
      {
        center: { lat: 37.7749, lng: -122.4194 },
        zoom: 8,
      }
    );

    const partnerMarkers = [
      { position: { lat: 37.7749, lng: -122.4194 }, title: "Partner 1" },
    ];

    partnerMarkers.forEach((markerInfo) => {
      new window.google.maps.Marker({
        position: markerInfo.position,
        map: map,
        title: markerInfo.title,
      });
    });
  };

  return (
    <div id="partnerLocatorMap" style={{ height: "500px", width: "100%" }} />
  );
};

export default PartnerLocatorMap;
