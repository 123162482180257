import React, { useEffect } from "react";
import "./calender.css";
import { MdPhotoCamera } from "react-icons/md";
import InputColor from "react-input-color";
import { useState } from "react";
import { useRef } from "react";
// import moment from "moment";
import PhoneInput from "react-phone-number-input";
import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { isValidAmericanPhoneNumber } from "../../../../../utils/regex";
import { addEvent, updateEvent } from "../../../../../redux/actions/userAction";
import toast from "react-hot-toast";

const AddEvent = ({ setMyEvents }) => {
  const [eventImage, setEventImage] = useState(null);

  /*
  /events/addEvent

   "eventName": "Annual School Festival",
        "address": "1234 Elm St, Springfield",
        "time": "14:30",
        "date": "2024-09-30",
        "contactNumber": "+1234567890",
        "meetLink": "hasfkjsdzkjzkjz;f",
        "description": "A fun-filled day with various events.",
        "email": "organizer@example.com",
        "visibilitforSchool": true,
        "visibilitforStudent": true,
        "visibilitforParent": false,
        "color": "#FF5733",
        "coverPhoto": "coverPhoto-1723912225158-Screenshot from 2024-08-17 20-55-23.png",
        "schoolId": 2,
  */

  const editId = useSearchParams()[0].get("editId");

  const [eventName, setEventName] = useState("");
  const [address, setAddress] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [meetLink, setMeetLink] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [visibilitforSchool, setVisibilitforSchool] = useState(false);
  const [visibilitforStudent, setVisibilitforStudent] = useState(false);
  const [visibilitforParent, setVisibilitforParent] = useState(false);
  const [color, setColor] = useState("");

  useEffect(() => {
    console.log(color, "I'm color");
  }, [color]);
  // color
  // coverPhoto
  // schoolId
  // coverPhoto

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const imageRef = useRef(null);

  useEffect(() => {
    if (!contactNumber?.startsWith("+1")) {
      setContactNumber("+1");
    } else {
      setContactNumber(contactNumber);
    }
  }, [contactNumber]);

  const { schoolId } = useSelector((state) => state.schoolId);

  const { loading, message, error } = useSelector((state) => state.events);

  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch({ type: "clearMessage" });
      setEventImage(null);
      setEventName("");
      setAddress("");
      setStartDate("");
      setEndDate("");
      setContactNumber("");
      setMeetLink("");
      setDescription("");
      setEmail("");
      setVisibilitforSchool(false);
      setVisibilitforStudent(false);
      setVisibilitforParent(false);
      setColor("");
      navigate("/DashboardCalender");
    }
    if (error) {
      toast.error(error);
      dispatch({ type: "clearError" });
    }
  }, [message, error, dispatch]);

  const handleEventAdd = () => {
    if (!validator.isEmail(email)) return toast.error("Email is not valid");

    if (!isValidAmericanPhoneNumber(contactNumber, "Contact number")) return;
    const newForm = new FormData();
    newForm.append("eventName", eventName);
    newForm.append("address", address);
    newForm.append("startdate", startDate);
    newForm.append("enddate", endDate);
    newForm.append("contactNumber", contactNumber);
    newForm.append("description", description);
    newForm.append("email", email);
    newForm.append("visibilitforSchool", visibilitforSchool);
    newForm.append("visibilitforStudent", visibilitforStudent);
    newForm.append("visibilitforParent", visibilitforParent);
    newForm.append("color", color?.rgba);
    newForm.append("coverPhoto", eventImage);
    newForm.append("meetLink", meetLink);
    newForm.append("schoolId", schoolId);

    if (editId) {
      dispatch(updateEvent(editId, newForm));
    } else {
      dispatch(addEvent(newForm));
    }
  };

  return (
    <div className="AddEventMAin">
      <h3>Add New Event</h3>
      <div className="AddEventBody">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => imageRef?.current?.click()}
        >
          <div className="AddEventImage">
            {!eventImage ? (
              <MdPhotoCamera />
            ) : (
              <img
                style={{
                  width: "80px",
                  height: "80px",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
                src={URL.createObjectURL(eventImage)}
                alt="#"
              />
            )}
          </div>
          <h4>Upload Cover Photo</h4>
          <input
            ref={imageRef}
            type="file"
            accept="image/*"
            onChange={(e) => setEventImage(e.target.files[0])}
            style={{ display: "none" }}
          />
        </div>
        <div className="AddEventInputs">
          <div className="AddEventRowOne">
            <div className="AddEnentLabel">
              <label>Event Name</label>
              <input
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
                placeholder="Enter event name"
              />
            </div>
            <div className="AddEnentLabel">
              <label>Address</label>
              <input
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Address"
              />
            </div>
          </div>
          <div className="AddEventRowOne">
            <div className="AddEnentLabel">
              <label>Start Date</label>
              <input
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                type="datetime-local"
              />
            </div>
            <div className="AddEnentLabel">
              <label>End Date</label>
              <input
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                type="datetime-local"
              />
            </div>
          </div>
          <div className="AddEventRowOne">
            <div className="AddEnentLabel">
              <label>Contact Number</label>
              <PhoneInput
                placeholder="Enter your Contact Number"
                value={contactNumber}
                onChange={setContactNumber}
              />
            </div>
            <div className="AddEnentLabel">
              <label>Insert Meeting Link</label>
              <input
                onChange={(e) => setMeetLink(e.target.value)}
                value={meetLink}
                placeholder="Meeting Link"
              />
            </div>
          </div>
          <div className="AddEventRowOne1">
            <div className="AddEnentLabel">
              <label>Description</label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <p className="characterCount">{description.length}/5000 </p>
            </div>
            <div className="AddEnentLabel">
              <label>Attendee Email</label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Search"
              />
              <p>Visible To</p>
              <div className="AddEventCheckBox">
                <input
                  checked={visibilitforSchool}
                  onChange={() => setVisibilitforSchool(!visibilitforSchool)}
                  type="checkbox"
                />
                <label>School</label>
                <input
                  checked={visibilitforStudent}
                  onChange={() => setVisibilitforStudent(!visibilitforStudent)}
                  type="checkbox"
                />
                <label>Student</label>
                <input
                  checked={visibilitforParent}
                  onChange={() => setVisibilitforParent(!visibilitforParent)}
                  type="checkbox"
                />
                <label>Parent</label>
              </div>
              <p>Color</p>
              <InputColor
                initialValue="#00afef"
                onChange={setColor}
                placement="right"
              />
            </div>
          </div>
        </div>
        <button onClick={handleEventAdd}>
          {editId ? "Updated Now" : "Add Now"}
        </button>
      </div>
    </div>
  );
};

export default AddEvent;
