import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import "./Charttt.css";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Accordion, Dropdown, DropdownButton } from "react-bootstrap";
import { CiFilter } from "react-icons/ci";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../../../../constants";
axios.defaults.baseURL = baseURL;

function valuetext(value) {
  return `${value}°C`;
}

const BarChart = ({ text = "", currentTab = "" }) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [dataType, setDataType] = useState("weekly");

  // const handleDataTypeChange = (event) => {
  //   setDataType(event.target.value);
  // };

  const [data, setData] = useState(null);

  const getSeriesData = () => {
    return data;
  };

  const chartData = {
    series: [{ name: "Quantity", data: getSeriesData() }],
    options: {
      chart: { type: "bar", height: 380 },
      xaxis: { type: "category" },
      yaxis: {
        min: 0,
        max: 100,
        labels: { formatter: (val) => `${val}` },
      },
      title: {
        text,
      },
      tooltip: { y: { formatter: (val) => `${val}` } },
    },
  };
  const [value, setValue] = React.useState([0, 100]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log(value, "this is my value");
  const [gradeLevelOptions, setGradeLevelOptions] = useState(null);

  const { schoolId } = useSelector((state) => state.schoolId);
  const [myAllOptions, setMyAllOptions] = useState([]);

  const getGradeLevelOptions = async () => {
    try {
      const { data } = await axios.get(
        `/students/getAllGradeLevels/${schoolId}`,
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );
      setGradeLevelOptions(data);

      setMyAllOptions(data);
      setSelectedFilters(data);
      handleFilter(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGradeLevelOptions();
  }, []);
  const handleChecker = (gd) => {
    setSelectedFilters((prev) => {
      return prev.includes(gd)
        ? prev.filter((id) => {
            return id !== gd;
          })
        : [...prev, gd];
    });
  };
  const [total, setTotal] = useState(0);
  const [filteredData, setFilteredData] = useState({});
  const handleFilter = async (dt = null) => {
    try {
      const passingData = dt ? dt : selectedFilters;
      const { data } = await axios.post(
        `/students/getGradeLevelfilter/${schoolId}`,
        {
          gradeLevels: passingData,
        },
        {
          headers: {
            accessToken: localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );

      const newK = [];

      Object.entries(data).forEach((i) => {
        newK.push({ x: i[0], y: i[1] });
      });

      if (currentTab === "gradeLevel") {
        setData(newK);
      }

      let t = 0;

      Object?.keys(data).forEach((elem) => {
        t += data[elem];
      });

      setTotal(t);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAgeFilter = async () => {
    try {
      const { data } = await axios.get(
        `/students/getStudentAgesAndCounts/${schoolId}?minAge=${value[0]}&maxAge=${value[1]}`,
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );
      const ak = [];

      data?.ageCounts?.forEach((elm) => {
        ak.push({ x: elm?.age, y: elm?.count });
      });

      if (currentTab !== "gradeLevel") {
        setData(ak);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleFilter();
    handleAgeFilter();
    return () => {
      setData(null);
    };
  }, []);

  const [isSelectAll, setIsSelectAll] = useState(false);

  useEffect(() => {
    console.log(isSelectAll, "main");
    if (isSelectAll) {
      setSelectedFilters(myAllOptions);
    } else {
      setSelectedFilters([]);
    }
  }, [isSelectAll]);

  return (
    <div className="BarChartMain">
      <div className="BarChartFilter">
        <DropdownButton id="dropdown-basic-button" title="Filters">
          <Dropdown>
            {currentTab === "gradeLevel" ? (
              <div className="DonutCHILDS">
                <div className="DONUTTOP">
                  <h2>Filter</h2>
                  <CiFilter />
                </div>
                <h3>Grade Level</h3>
                <div className="DonutFlex">
                  <input
                    checked={isSelectAll}
                    onChange={() => setIsSelectAll(!isSelectAll)}
                    type="checkbox"
                  />
                  <label>Select All</label>
                </div>
                {gradeLevelOptions &&
                  gradeLevelOptions.map((gradeLevel) => {
                    return (
                      <div
                        onClick={() => handleChecker(gradeLevel)}
                        className="DonutFlex"
                      >
                        <input
                          checked={selectedFilters.includes(gradeLevel)}
                          type="checkbox"
                        />
                        <label>{gradeLevel}</label>
                      </div>
                    );
                  })}

                <div className="DonutBtns">
                  <button onClick={() => handleFilter(null)}>Done</button>
                  <button onClick={() => setSelectedFilters([])}>Reset</button>
                </div>
              </div>
            ) : (
              <div className="BarCHArtCilds">
                <h2>
                  Age Range <CiFilter />
                </h2>
                <Box sx={{ width: 300 }}>
                  <Slider
                    getAriaLabel={() => "Temperature range"}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                  />
                </Box>
                <button onClick={handleAgeFilter}>Done</button>
              </div>
            )}
          </Dropdown>
        </DropdownButton>
      </div>
      <div id="chart">
        {data && (
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={400}
          />
        )}
      </div>
    </div>
  );
};

export default BarChart;
