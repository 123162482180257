import React from "react";
import "./StudentHeader.css";
import { CiSearch } from "react-icons/ci";
import { RiEqualizerFill } from "react-icons/ri";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { TfiLayoutGrid4 } from "react-icons/tfi";
import { FaPlus } from "react-icons/fa6";
import ExcelIcon from "../../../images/vscode-icons_file-type-excel2.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import FilterModal from "../../../Filter/FilterModal";
import {exportToCSV} from '../../../../../utils/features'
import ByDate from "../../../ByDate/ByDate";

const StudentHeader = ({
  showTable,
  setShowTable,
  searchValue,
  setSearchValue,
  allStudents,
  filterFunction = () => {},
}) => {
  const params = useParams()
  return (
    <div className="student-header">
      <h2 className="student-list-title">Student List</h2>
      <div className="student-content">
        <div className="left-content">
          <div id="searching">
            <CiSearch onClick={filterFunction} className="search-icon" />
            <input
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              placeholder="Search"
            />
          </div>
          {/* <div className="select-date">
            <input
              style={{ color: "#858d9d" }}
              className="search-input date-input"
              type="date"
              placeholder="Select Date"
            />
          </div> */}
          <ByDate />
          {/* <div className="filterrr">
            <RiEqualizerFill color="gray" className="filter-icon" />
            <p>Filters</p>
          </div> */}
          <FilterModal />
        </div>
        {params?.role !== "parent" ? (
          <div className="switchh">
            <div className="switch-div" onClick={() => setShowTable(true)}>
              <RxHamburgerMenu
                className="hamburger-icon"
                style={{
                  backgroundColor: showTable ? "#00afef" : "#f5f4f6",
                }}
                size={30}
                color={showTable ? "white" : "gray"}
              />
            </div>
            <div
              onClick={() => setShowTable(false)}
              className="layout-grid-icon-div"
            >
              <TfiLayoutGrid4
                className="layout-grid-icon"
                style={{
                  backgroundColor: !showTable ? "#00afef" : "#f5f4f6",
                }}
                size={30}
                color={!showTable ? "white" : "gray"}
              />
            </div>
          </div>
        ) : null}
        <div className="exlimation-icon-div">
          <AiOutlineExclamationCircle size={25} color="#00afef" />
          <div className="right-buttons">
            <FaPlus size={20} style={{ color: "white" }} />
            {/* <p className="add-new-p">Add new</p> */}
            <Link className="add-new-p" to="/AddNewStudent">
              Add New
            </Link>
          </div>

          <div className="export-data-div">
            <img
              src={ExcelIcon}
              style={{ width: "20px", height: "20px", objectFit: "contain" }}
            />
            <div
              onClick={() => exportToCSV(allStudents)}
              style={{ textDecoration: "none" }}
            >
              <p style={{ color: "#148348" }}>EXPORT DATA</p>
            </div>
          </div>
          <Link
            to="/ImportData?role=student"
            style={{ textDecoration: "none" }}
            className="import-data-div"
          >
            <FaPlus color="white" size={20} />
            <p style={{ color: "white" }}>Import Data</p>
          </Link>
          <BsThreeDotsVertical size={20} color="#00afef" />
        </div>
      </div>
    </div>
  );
};

export default StudentHeader;
