import LoaderGif from "../../../loader.gif";
const Loader = () => {
  return (
    <div
      style={{
        minWidth: "100vw",
        maxWidth: "100vw",
        minHeight: "100vh",
        maxHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="gif-loader"
    >
      <img
        style={{ width: "150px", height: "150px" }}
        src={LoaderGif}
        alt="loader"
      />
    </div>
  );
};

export default Loader;
