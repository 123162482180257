import React, { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import "./Myassingmentsubject.css";
import { IoCaretForwardOutline } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import SubjectModal from "./SubjectModal/SubjectModal";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import axios from "axios";
import { baseURL } from "../../../../constants";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Myassingmentsubject = () => {
  const assignmentsData = [
    {
      descriptionColor: "#F93333",
      bgColor: "#F93333",
      buttonBgColor: "#f93333",
      buttonTextColor: "#F93333",
      button: "Active",
      date: "1",
      programCodeColor: "#F93333",
      programBorderColor: "#F93333",
      programDurationColor: "#F93333",
      drageIconColor: "#F93333",
      programBorderColor: "#F93333",
    },
    {
      descriptionColor: "#16D03B",
      bgColor: "#16D03B",
      buttonBgColor: "#16D03B",
      buttonTextColor: "#16D03B",
      button: "Active",
      bcColor: "#F0FFF3",
      mainbordercolor: "#16D03B",
      date: "2",
      programCodeColor: "#16D03B",
      programDurationColor: "#F93333",
      drageIconColor: "#16D03B",
      programBorderColor: "#16D03B",
    },
  ];

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [subjects, setSubjects] = useState(null);

  const { schoolId } = useSelector((state) => state.schoolId);
  const getSubjects = async () => {
    try {
      const { data } = await axios.get(`/subjects/getSubjects/${schoolId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setSubjects(data?.subjects);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSubjects();
  }, [schoolId]);

  const [isEdit, setIsEdit] = useState("");

  const [subject, setSubject] = useState({});
  const handleEdit = async (subjectId) => {
    setIsEdit(subjectId);
    try {
      const { data } = await axios.get(
        `/subjects/getSubjectById/${subjectId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );

      setSubject(data.subject);
    } catch (error) {
      console.log(error);
    }
  };

  const [deleteId, setDeleteId] = useState("");
  const handleDelete = async () => {
    try {
      await axios.delete(`/subjects/removeSubject/${deleteId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setDeleteId("");
      getSubjects();
      toast.success("Deleted Successfully!");
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="myassingment-main2">
      <div className="myassingment-XOp">
        <h3>School </h3>
        <IoCaretForwardOutline />
        <h4 style={{ color: "#00afef" }}>Manage Academics</h4>
        <IoCaretForwardOutline />
        <h4> Subject</h4>
      </div>
      <div className="myassingment-SeC">
        <div className="myassingment-SeC-input">
          <IoIosSearch />
          <input placeholder="Search ." type="text" />
        </div>
        <div className="myassingment-SeC-button">
          <SubjectModal
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            func={getSubjects}
            subject={subject}
            setSubject={setSubject}
          />
        </div>
      </div>
      <div className="myassingment-SeC-Table">
        <table>
          <thead>
            <tr>
              <th>
                <input type="checkbox" />
                Subject Name
              </th>
              <th>Subject Code</th>
              <th>Program Description</th>
              <th>Credit Hours </th>
              <th>Quality Points</th>
              <th>Start Date </th>
              <th>End Date</th>
              <th>Action</th>
              <th>Action</th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="CommentModal-Mmain">
        <Modal
          className="Allowmodalmain"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div className="CommentModal-Mmain-child1">
                <h4>Delete Subject</h4>
                <p>
                  Are you sure you want to delete this Subject? This will remove
                  the Subject and can’t be undone.
                </p>
                <div className="CommentModal-Mmain-child2">
                  <button
                    className="CommentModal-Mmain-child2-Dont-allow"
                    onClick={handleClose}
                  >
                    NO, CANCEL
                  </button>
                  <button
                    onClick={handleDelete}
                    className="CommentModal-Mmain-child2-allow"
                  >
                    YES, DELETE
                  </button>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
      {/* map */}
      <div style={{ marginTop: "10px" }}>
        {subjects?.map((assignment, index) => (
          <div
            className="myassingments-child-2m"
            key={index}
            style={{ marginTop: "10px" }}
          >
            <div
              className="myassingments-cardd"
              style={{
                backgroundColor:
                  assignmentsData[!assignment?.isActive ? 0 : 1]?.bcColor,
                borderColor:
                  assignmentsData[!assignment?.isActive ? 0 : 1]
                    ?.mainbordercolor,
              }}
            >

              <div className="myassingmentss-childss">
                <div className="myassingments-childss-Data">
                  <div
                    className="myassingments-childs-dateE"
                    style={{
                      backgroundColor:
                        assignmentsData[!assignment?.isActive ? 0 : 1]?.bgColor,
                    }}
                  >
                    <p>{assignment?.id}</p>
                  </div>
                  <div className="Mychildss-myassingmentss">
                    <h6>{assignment?.subjectName}</h6>
                  </div>
                </div>
                <div>
                  <h6
                    style={{
                      color:
                        assignmentsData[!assignment?.isActive ? 0 : 1]
                          ?.programCodeColor,
                    }}
                  >
                    {assignment?.subjectCode}
                  </h6>
                </div>
              </div>
              {/* ///////////////////////////////// */}
              <div className="myassingments-card-childs-2m">
                <div className="descriptions-s">
                  <p
                    style={{
                      fontSize: "14px",
                      color:
                        assignmentsData[!assignment?.isActive ? 0 : 1]
                          ?.descriptionColor,
                    }}
                  >
                    {assignment?.description}
                  </p>
                </div>
                <div>
                  <h6
                    style={{
                      color:
                        assignmentsData[!assignment?.isActive ? 0 : 1]
                          ?.programCodeColor,
                    }}
                  >
                    {assignment?.creditHours}
                  </h6>
                </div>

                <div>
                  <h6
                    style={{
                      color:
                        assignmentsData[!assignment?.isActive ? 0 : 1]
                          ?.programCodeColor,
                    }}
                  >
                    {assignment?.qualityPoints}
                  </h6>
                </div>

                <div>
                  <h6
                    style={{
                      color:
                        assignmentsData[!assignment?.isActive ? 0 : 1]
                          ?.programCodeColor,
                    }}
                  >
                    {assignment?.startDate?.split("T")[0]}
                  </h6>
                </div>
                <div>
                  <h6
                    style={{
                      color:
                        assignmentsData[!assignment?.isActive ? 0 : 1]
                          ?.programCodeColor,
                    }}
                  >
                    {assignment?.endDate?.split("T")[0]}
                  </h6>
                </div>
                <div>
                  <button
                    style={{
                      color:
                        assignmentsData[!assignment?.isActive ? 0 : 1]
                          ?.programCodeColor,
                      borderColor:
                        assignmentsData[!assignment?.isActive ? 0 : 1]
                          ?.programBorderColor,
                      backgroundColor:
                        assignmentsData[!assignment?.isActive ? 0 : 1]?.bcColor,
                    }}
                    className="MySUbBtn"
                  >
                    {assignment?.isActive ? "Active" : "Not Active"}
                  </button>
                </div>

                <div className="mychilds-myassingments-dov">
                  <div className="mychilds-myassingments-icoNs">
                    <MdEdit
                      onClick={() => handleEdit(assignment?.id)}
                      style={{
                        color:
                          assignmentsData[!assignment?.isActive ? 0 : 1]
                            ?.drageIconColor,
                      }}
                    />
                    <RiDeleteBin6Line
                      onClick={() => {
                        handleOpen();
                        setDeleteId(assignment?.id);
                      }}
                      style={{
                        color:
                          assignmentsData[!assignment?.isActive ? 0 : 1]
                            ?.drageIconColor,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Myassingmentsubject;
