import React from "react";
// import axios from "axios";
import "./AddReport.css";
// import { baseURL } from "../../../../constants";
import { IoIosArrowForward } from "react-icons/io";

const AddReport = () => {
  return (
    <div className="AddReport-main">
      <div className="AddReport-main-child">
        <div className="AddReport-main-child-main-div">
          <div className="AddReport-main-child-main-div-child-1">
            <img alt="img" />
            <h2>log</h2>
          </div>
          <div className="AddReport-main-child-main-div-child-2">
            <h3>
              <span>
                <IoIosArrowForward />
              </span>
              report 1
            </h3>

            <h3>
              <span>
                <IoIosArrowForward />
              </span>
              report 2
            </h3>
            <h3>
              <span>
                <IoIosArrowForward />
              </span>
              report 3
            </h3>
            <h3>
              <span>
                <IoIosArrowForward />
              </span>
              report 4
            </h3>
            <button>
              <div className="spanomg">
                <h3>Generate Report Ai</h3>
                <small>Coming soon</small>
              </div>
              <img src={""} alt="img" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddReport;
