import React, { useEffect, useState } from "react";
import "./MyAccountSubscription.css";
import { FiEye, FiEyeOff } from "react-icons/fi";
import subscribtion from "../images/subscrib.svg";
import master_card from "../images/ion_card-sharp.png";
import profileDefaultImg from "../images/Rectangle 25 (2).png";
import MyDetailModal from "./MyDetailModal";
import toast from "react-hot-toast";
import Dummy from "../images/dummy-user.png";
import axios from "axios";
import { baseURL } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";

const MyAccountSubscription = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [profileImage, setProfileImage] = useState(profileDefaultImg);
  const [user, setUser] = useState(null);

  const togglePasswordVisibility = (field) => {
    switch (field) {
      case "current":
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  const {
    user: myUser,
    loading,
    error,
  } = useSelector((state) => state.getUserProfile);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);
  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      setProfileImage(reader.result);
    };
    reader.readAsDataURL(file);

    try {
      console.log(file, "this is our file");
      const newForm = new FormData();
      newForm.append("profilePicture", file);

      if (myUser) {
        const { data } = await axios.put(
          `/admins/profilePicture/${myUser.dataValues.id}`,
          newForm,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        toast.success("Profile Updated Successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);

  const [oldPassword, setOldPassword] = useState("");
  const [getNewPassword, setGetNewPassword] = useState("");
  const [getConfirmPassword, setGetConfirmPassword] = useState("");
  const updatePassword = async () => {
    try {
      if (getConfirmPassword !== getNewPassword)
        return toast.error("New password and confirm password is not matching");
      if (myUser) {
        const { data } = await axios.put(
          `/admins/updatePassword/${myUser.dataValues.id}`,
          {
            currentPassword: oldPassword,
            newPassword: getNewPassword,
          },
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );

        toast.success(data.message);
      }
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const getUserProfile = () => async () => {
    try {
      if (myUser) {
        const { data } = await axios.get(
          `/admins/adminProfile/${myUser.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setUser(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUserProfile();
  }, [myUser]);

  const handleUpdatePassword = () => {
    updatePassword();
  };

  const [messageUpdate, setMessageUpdate] = useState(false);
  const [eventUpdate, setEventUpdate] = useState(false);
  const [transactionUpdate, setTransactionUpdate] = useState(false);
  const [passwordUpdate, setPasswordUpdate] = useState(false);
  const [profileUpdate, setProfileUpdate] = useState(false);

  console.log(myUser, "this is my user");
  const handleNotificationChanges = async () => {
    try {
      if (myUser) {
        const { data } = await axios.post(
          `/settings/addOrUpdateAdminNotificationSettings`,
          {
            adminId: myUser.dataValues.id,
            messageUpdate,
            eventUpdate,
            transactionUpdate,
            passwordChangeUpdate: passwordUpdate,
            profileUpdate,
          },
          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        toast.success("Changes Update Successfully");
      }
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(error?.response?.data?.details[0]?.detail);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const [admin, setAdmin] = useState(null);

  const getAdminProfile = async () => {
    try {
      if (myUser) {
        const { data } = await axios.get(
          `/admins/adminProfile/${myUser.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        setAdmin(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAdminProfile();
  }, [myUser]);
  return (
    !!user && (
      <div className="my-account-subscriptiom-main">
        <h6>My Account & Subscription</h6>
        <div className="my-account-subscriptiom-main-1">
          <div className="my-account-upload-profile-image-account">
            <img
              style={{
                borderRadius: "100%",
                objectFit: "cover",
              }}
              onError={({ currentTarget }) => {
                currentTarget.src = Dummy;
              }}
              src={`${baseURL}/images/${admin?.profilePicture}`}
              alt="#"
            />
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
              id="uploadImageInput"
            />
            <label className="change-img-upload" htmlFor="uploadImageInput">
              Change Image
            </label>
          </div>

          <div className="my-account-subscriptiom-information">
            <div className="my-account-subscriptiom-name">
              <h2>
                {user?.firstName} {user?.lastName}
              </h2>
              <div>
                <button onClick={onOpenModal}>
                  <MyDetailModal func={getAdminProfile} />
                </button>
              </div>
            </div>
            <div className="my-account-subscriptiom-input-form">
              <div className="my-account-subscriptiom-input-form-Email">
                <label htmlFor="Email">Email</label>
                <div>{admin?.email}</div>
              </div>
              <div className="my-account-subscriptiom-input-form-phone">
                <label htmlFor="phone">phone</label>
                <div>{admin?.phoneNumber}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-account-subscriptiom-main-2">
          <div className="my-account-subscriptiom-main-2-chils-1">
            <div className="my-account-subscriptiom-main-2-chils-1-data">
              <img src={subscribtion} alt="" />
              <div className="my-account-subscriptiom-main-cards-data">
                <h4>Current Subscription</h4>
                <p>
                  You are on free Trials, which expires on{" "}
                  <span>January 6, 2024</span>
                </p>
                <button>Subscribe Now</button>
              </div>
            </div>
            <div className="my-account-subscriptiom-main-2-chils-1-data2">
              <img src={master_card} alt="" />
              <div className="my-account-subscriptiom-main-cards-data2">
                <h4>Available Payments Methods</h4>
                <ol>
                  <li>Credits Card</li>
                  <li>ACH (bank Account Debit)</li>
                  <li>Offline Wire Transfer</li>
                  <li>Offline Cheque Paymen</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/*  */}

        <div className="setting-child-3">
          <div className="Account-setting-notification">
            <h5>Notifications</h5>
            <div className="Notifications-checkboxes">
              <li>
                <input
                  checked={messageUpdate}
                  onChange={() => setMessageUpdate(!messageUpdate)}
                  type="checkbox"
                  id="checkbox"
                />
              </li>
              <p>I want to know about message updates</p>
            </div>
            <div className="Notifications-checkboxes">
              <li>
                <input
                  checked={eventUpdate}
                  onChange={() => setEventUpdate(!eventUpdate)}
                  type="checkbox"
                  id="checkbox"
                />
              </li>
              <p>I want to know about event updates</p>
            </div>
            <div className="Notifications-checkboxes">
              <li>
                <input
                  checked={transactionUpdate}
                  onChange={() => setTransactionUpdate(!transactionUpdate)}
                  type="checkbox"
                  id="checkbox"
                />
              </li>
              <p>I want to know about transaction updates</p>
            </div>
            <div className="Notifications-checkboxes">
              <li>
                <input
                  checked={passwordUpdate}
                  onChange={() => setPasswordUpdate(!passwordUpdate)}
                  type="checkbox"
                  id="checkbox"
                />
              </li>
              <p>I Want to know about password updates</p>
            </div>
            <div className="Notifications-checkboxes">
              <li>
                <input
                  checked={profileUpdate}
                  onChange={() => setProfileUpdate(!profileUpdate)}
                  type="checkbox"
                  id="checkbox"
                />
              </li>
              <p>I want to know about profile updates</p>
            </div>

            <div className="setting-notification-btn">
              <button onClick={handleNotificationChanges}>Save changes</button>
            </div>
          </div>

          <div className="Account-setting-Change-password">
            <h5>Changed password</h5>

            <div className="Account-setting-Current-Password">
              <label htmlFor="Current Password">Current Password</label>
              <div className="Change-password-input">
                <input
                  type={showCurrentPassword ? "text" : "password"}
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  placeholder="Current Password"
                />
                {showCurrentPassword ? (
                  <FiEyeOff
                    onClick={() => togglePasswordVisibility("current")}
                  />
                ) : (
                  <FiEye onClick={() => togglePasswordVisibility("current")} />
                )}
              </div>
            </div>

            <div className="Account-setting-New-Password">
              <label htmlFor="New Password">New Password</label>
              <div className="New-Password-input">
                <input
                  type={showNewPassword ? "text" : "password"}
                  value={getNewPassword}
                  onChange={(e) => setGetNewPassword(e.target.value)}
                  placeholder="New Password"
                />
                {showNewPassword ? (
                  <FiEyeOff onClick={() => togglePasswordVisibility("new")} />
                ) : (
                  <FiEye onClick={() => togglePasswordVisibility("new")} />
                )}
              </div>
            </div>

            <div className="Account-setting-Confirm-Password">
              <label htmlFor="Confirm-Password">Confirm Password</label>
              <div className="Confirm-password-input">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  value={getConfirmPassword}
                  onChange={(e) => setGetConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                />
                {showConfirmPassword ? (
                  <FiEyeOff
                    onClick={() => togglePasswordVisibility("confirm")}
                  />
                ) : (
                  <FiEye onClick={() => togglePasswordVisibility("confirm")} />
                )}
              </div>
            </div>
            {!passwordsMatch && (
              <div style={{ color: "red", marginTop: "10px" }}>
                Password Dont't match!
              </div>
            )}

            {passwordChanged && (
              <div style={{ color: "green", marginTop: "10px" }}>
                Password Changed Successfuly!
              </div>
            )}

            <div className="setting-password-btn">
              <button onClick={handleUpdatePassword}>Save changes</button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default MyAccountSubscription;
