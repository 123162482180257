import React from "react";
import "./AddUsersActive.css";

const AddUsersActiveCom = ({
  dailyActiveUsers,
  amount,
  lastWeek,
  fullScreenIcon,
  persntageIcon,
  persntageText,
  color,
  backgroundColor,
}) => {
  return (
    <div className="userActive">
      <div className="card">
        <h2>
          {dailyActiveUsers} <span>{fullScreenIcon}</span>
        </h2>
        <h1>
          {amount}{" "}
          <span style={{ color: color, backgroundColor: backgroundColor }}>
            {persntageIcon}
            {persntageText}
          </span>
        </h1>
        <p>Last Week {lastWeek}</p>
      </div>
    </div>
  );
};

export default AddUsersActiveCom;
