import React, { useEffect, useState } from "react";
import "./TopSearchBar.css";
import Notification from "../ADDnotification/Notification";
import LogoutModal from "../ADDLogoutModal/LogoutModal";
import axios from "axios";
import { baseURL } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;

function TopSearchBar() {
  const [notificationClicked, setNotificationClicked] = useState(false);

  const [admin, setAdmin] = useState(null);

  const dispatch = useDispatch();
  const { user, loading, error } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const getAdminProfile = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/admins/adminProfile/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        setAdmin(data);
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getAdminProfile();
  }, [user]);

  return (
    <div className="TopSearchBar-main1">
      <div className="TopSearchBar-two">
        <div className="TopSearchBar-Alpha">
          <div
            className={`icon-container1 ${
              notificationClicked ? "clicked" : ""
            }`}
            onClick={() => setNotificationClicked(true)}
          >
            <Notification />
          </div>
          <LogoutModal />
          <div className="clientNAME">
            <h3>
              {admin?.firstName} {admin?.middleName} {admin?.lastName}
            </h3>
            <p>Super Administrator</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopSearchBar;
