import "./Addparanteditinfo.css";
import React, { useState, useRef, useEffect } from "react";
import { FiUploadCloud } from "react-icons/fi";
import { GrNotes } from "react-icons/gr";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { baseURL } from "../../../../../constants";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";
import Avator from "../../../PickAvator/Avator";
import CompleteModal from "../../../CompleteModal/Complete";
import { FaPhoneAlt } from "react-icons/fa";
// import Select from "react-select";
import {
  isValidAmericanPhoneNumber,
  isValidEmail,
  isValidName,
  isValidPassword,
  requiredValues,
} from "../../../../../utils/regex.js";
import makeAnimated from "react-select/animated";
// import { colourOptions } from "../data";

axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;
const Addparanteditinfo = () => {
  const animatedComponents = makeAnimated();
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // setSelectedFile(file);
  };

  // const handleImageClick = () => {
  //   fileInputRef.current.click();
  // };

  const [showSecondDiv, setShowSecondDiv] = useState(false);

  const handleSwitchChange = () => {
    setShowSecondDiv(!showSecondDiv);
  };

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userProfile, setUserProfile] = useState("");
  const [email, setEmail] = useState("");
  const [personalNumber, setPersonalNumber] = useState("+1");
  const [homePhone, setHomePhone] = useState("+1");
  const [workEmail, setWorkEmail] = useState("");
  const [workNumber, setWorkNumber] = useState("+1");
  const [portalAccess, setPortalAccess] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [isShow, setIsShow] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [handleFirstSwitch, setHandleFirstSwitch] = useState(false);
  const [handleSecondSwitch, setHandleSecondSwitch] = useState(false);
  const [loginEmailAddress, setLoginEmailAddress] = useState("");

  const params = useParams();

  const [parent, setParent] = useState(null);

  const [success, setSuccess] = useState(false);
  const getParent = async () => {
    try {
      const { data } = await axios.get(
        `/parents/getParent/${params.parentId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setParent(data?.parent);
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();
  useState(() => {
    getParent();
  }, []);

  useEffect(() => {
    if (parent) {
      setFirstName(parent?.firstName);
      setMiddleName(parent?.middleName);
      setLastName(parent?.lastName);
      setUserProfile(parent?.userProfile);
      setEmail(parent?.email);
      setPersonalNumber(parent?.personalNumber);
      setHomePhone(parent?.homePhone);
      setWorkEmail(parent?.workEmail);
      setPortalAccess(parent?.portalAccess);
      setDisabled(parent?.disabled);
      setLoginEmailAddress(parent?.loginEmailAddress);
    }
  }, [parent]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const generateRandomPassword = () => {
    const randomPassword = Math.random().toString(36).slice(-8);
    setPassword(randomPassword);
  };
  //SchoolId

  const [profilePicture, setProfilePicture] = useState(null);

  const { schoolId } = useSelector((state) => state.schoolId);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const handleUpdateParent = async () => {
    try {

      if (
        !requiredValues([
          {
            value: firstName,
            name: "First name",
          },
          {
            value: lastName,
            name: "Last name",
          },
        ])
      )
        return;
      if (!isValidName(firstName, "First name")) return;
      if (middleName) {
        if (!isValidName(middleName, "Middle name")) return;
      }
      if (!isValidName(lastName, "Last name")) return;

      if (!isValidEmail(email)) return;

      if (!isValidAmericanPhoneNumber(personalNumber, "Personal number"))
        return;
      if (!isValidAmericanPhoneNumber(homePhone, "Home phone")) return;

      if (workEmail) {
        if (!isValidEmail(workEmail)) return;
      }

      if (!isValidAmericanPhoneNumber(workNumber, "Work number")) return;

      if (!isValidEmail(loginEmailAddress, "Login email address")) return;

    
      
        if (!isValidPassword(password)) return;
    
     
      const newForm = new FormData();
      newForm.append("firstName", firstName);
      newForm.append("middleName", middleName);
      newForm.append("lastName", lastName);
      newForm.append("userProfile", userProfile);
      newForm.append("email", email);
      newForm.append("personalNumber", Number(personalNumber));
      newForm.append("homePhone", Number(homePhone));
      newForm.append("workEmail", workEmail);
      newForm.append("workNumber", workNumber);
      newForm.append("portalAccess", portalAccess);
      newForm.append("disabled", disabled);
      newForm.append("schoolId", Number(schoolId));
      newForm.append("profilePicture", profilePicture);
      newForm.append("loginEmailAddress", loginEmailAddress);

      selectedStudents.forEach((st) => {
        newForm.append("Children[]", st);
      });

      handleSecondSwitch && newForm.append("password", password);

      const { data } = await axios.post(`/parents/addParent`, newForm, {
        headers: {
          "Content-Type": "multipart/form-data",
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setSuccess(true);
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error.response.data.details[0]?.detail ||
            error?.response?.data?.details[0]?.message ||
            error?.response?.data?.details[0]?.error
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const colourOptions = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const [schoolStudents, setSchoolStudents] = useState([]);
  const [allStudents, setAllStudents] = useState([]);

  const getAllStudents = async () => {
    try {
      const { data } = await axios.get(
        `/students/getstudentbyschoolId/${schoolId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );

      const newStudents = data.map((d) => {
        return {
          value: d.id,
          label: `(${d.id}) ${d.firstName} ${d.middleName} ${d.lastName}`,
        };
      });
      setSchoolStudents(newStudents);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(selectedStudents, "these are selected students");
  useEffect(() => {
    getAllStudents();
  }, [schoolId]);

  const handleManipulateValues = (e) => {
    setAllStudents(e);

    const newArr = e.map((e) => {
      return e.value;
    });

    setSelectedStudents(newArr);
  };

  const [lgShow, setLgShow] = useState(false);

  return (
    <div className="Addparanteditinfo-main">
      {success && <CompleteModal link="/ParentTable" />}
      <div className="Addparanteditinfo-main-child-1">
        <div onClick={() => setLgShow(true)} className="upLoaDing">
          {selectedFile ? (
            <img
              src={selectedFile}
              alt="Staff"
              style={{
                width: "170px",
                height: "170px",
                cursor: "pointer",
                borderRadius: "100%",
                padding: "1px",
              }}
              // onClick={handleImageClick}
            />
          ) : (
            <>
              <FiUploadCloud
                style={{ width: "100%", cursor: "pointer" }}
                // onClick={handleImageClick}
              />
              <label
                // htmlFor="upload"
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  marginTop: "3px",
                }}
              >
                Add Guardian Profile Picture
              </label>
            </>
          )}
          <Avator
            profilePicture={profilePicture}
            setProfilePicture={setProfilePicture}
            lgShow={lgShow}
            setSelectedFile={setSelectedFile}
            setLgShow={setLgShow}
          />
          {/* <input
            type="file"
            id="upload"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          /> */}
        </div>
        <h2>
          {firstName} {lastName}
        </h2>
        <button className="Addparanteditinfo-main-child-1-button">
          {userProfile}
        </button>
        <div className="Addparant-main-child-1-tap-button">
          <GrNotes /> <h3>General Info</h3>
        </div>
      </div>
      <div className="Addparanteditinfo-main-child-2">
        <div className="Addparanteditinfo-main-child-2-title">
          <h2>Identification information</h2>
        </div>
        <div className="Addparanteditinfo-main-child-2-Identification-information">
          <div className="Information-form">
            <div className="Addparanteditinfo-main-child-2-input-and-label">
              <label>First Given Name *</label>
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="-"
              />
            </div>
            <div className="Addparanteditinfo-main-child-2-input-and-label">
              <label>Middle Name</label>
              <input
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
                placeholder="-"
              />
            </div>
            <div className="Addparanteditinfo-main-child-2-input-and-label">
              <label>Last Name *</label>
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="-"
              />
            </div>
          </div>
          <div className="Information-form">
            <div className="Addparanteditinfo-main-child-2-input-and-label">
              <label>Relation with student *</label>
              <input
                value={userProfile}
                onChange={(e) => setUserProfile(e.target.value)}
                placeholder="-"
              />
            </div>
            <div className="Addparanteditinfo-main-child-2-input-and-label">
              <label>Email *</label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="-"
              />
            </div>
            <div className="Addparanteditinfo-main-child-2-input-and-label">
              <label>Mobile Number *</label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "7px",
                }}
              >
                <FaPhoneAlt />
                <PhoneInput
                  value={personalNumber}
                  onChange={setPersonalNumber}
                />
              </div>
            </div>
          </div>
          <div className="Information-form">
            <div className="Addparanteditinfo-main-child-2-input-and-label">
              <label>Home Phone *</label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "7px",
                }}
              >
                <FaPhoneAlt />
                <PhoneInput value={homePhone} onChange={setHomePhone} />
              </div>
            </div>
            <div className="Addparanteditinfo-main-child-2-input-and-label">
              <label>Work Email</label>
              <input
                value={workEmail}
                onChange={(e) => setWorkEmail(e.target.value)}
                placeholder="-"
              />
            </div>
            <div className="Addparanteditinfo-main-child-2-input-and-label">
              <label>Work Number</label>
              {/* <PhoneInput value={workNumber} onChange={setWorkNumber} /> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "7px",
                }}
              >
                <FaPhoneAlt />
                <PhoneInput value={workNumber} onChange={setWorkNumber} />
              </div>
            </div>
          </div>
          {/* /////////////////////// */}
          {/* <div className="Information-form">
            <div className="Addparanteditinfo-main-child-2-input-and-label">
              <label>Select Child *</label>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                // defaultValue={[schoolStudents[4], schoolStudents[5]]}
                isMulti
                value={allStudents}
                onChange={handleManipulateValues}
                options={schoolStudents}
              />
            </div>
          </div> */}
          {/* .////////////////////// */}
          <div
            className="ADDEditSchoolInfo-Switch"
            style={{ display: "flex", gap: "20px", alignItems: "center" }}
          >
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id=""
                style={{ marginTop: "6px" }}
                checked={portalAccess}
                onChange={() => {
                  setPortalAccess(!portalAccess);
                  setHandleFirstSwitch(!handleFirstSwitch);
                }}
              />
              <label className="form-check-label">Portal Access</label>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id=""
                style={{ marginTop: "6px" }}
                // checked={handleSecondSwitch}
                // onChange={() => setHandleSecondSwitch(!handleSecondSwitch)}
              />
              <label className="form-check-label">Allow SSO Login</label>
            </div>
          </div>
        </div>
        {/* {portalAccess ? ( */}

        {/* ) : null} */}
        {/* {portalAccess && ( */}
        <div className="ADDSwitchShowInput">
          <div className="ADDSwitchShowInput-child-1">
            <label>Access Information *</label> <br />
            <input
              value={loginEmailAddress}
              onChange={(e) => setLoginEmailAddress(e.target.value)}
              placeholder="Login Email Address*"
            />
          </div>
          <div className="ADDSwitchShowInput-child-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                onChange={() => setHandleSecondSwitch(!handleSecondSwitch)}
                checked={handleSecondSwitch}
                id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Set or Reset Password
              </label>
            </div>
          </div>
        </div>
        {/* )} */}
        {handleSecondSwitch ? (
          <div className="ADDEditShowHide">
            <h4>Password *</h4>
            <h5>Student email to set or reset password</h5>
            <div class="form-check">
              <input
                class="form-check-input "
                type="radio"
                name="exampleRadios"
                id="exampleRadios1"
                value="option1"
              />
              <label class="form-check-label labelss" for="exampleRadios1">
                Student will be able to create password on their own by clicking
                the link on their email
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="exampleRadios1"
                value="option1"
              />
              <label class="form-check-label" for="exampleRadios1">
                Create Password
              </label>
            </div>
            <div className="ADDShowCloseDiv-main">
              <div className="ADDShowCloseDiv">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                />
                <span className="eye-icon" onClick={handleTogglePassword}>
                  {showPassword ? "👁️" : "👁️‍🗨️"}
                </span>
              </div>
              <p onClick={generateRandomPassword}>
                <RefreshIcon />
                Auto Generate Password
              </p>
            </div>
          </div>
        ) : null}
        <div className="Addparanteditinfo-main-child-2-tow-button">
          <button
            onClick={() => navigate(`/ParentTable`)}
            className="Addparanteditinfo-main-child-2-tow-button-1"
          >
            Cancel
          </button>
          <button
            onClick={handleUpdateParent}
            className="Addparanteditinfo-main-child-2-tow-button-2"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default Addparanteditinfo;
