import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./ADDLogoutModal.css";
import { MdBarChart } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../redux/actions/userAction";
import Dummy from "../images/dummy-user.png";
import { baseURL } from "../../../constants";

const LogoutModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const handleLogout = () => {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("refreshToken");
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("token");
    setShow(false);
    dispatch(getUserProfile());
    navigate("/");
    window.location.reload();
  };

  const handleLiClick = () => {
    handleClose();
  };

  const [admin, setAdmin] = useState(null);

  const { user, loading, error } = useSelector((state) => state.getUserProfile);

  const getAdminProfile = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/admins/adminProfile/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        setAdmin(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAdminProfile();
  }, [user]);

  return (
    <>
      <div className="LogoutModall">
        <div className="mainModall">
          <div className="LogoProfilel" onClick={handleShow}>
            {admin && (
              <img
                style={{
                  borderRadius: "100%",
                  objectFit: "cover",
                }}
                src={`${baseURL}/images/${admin?.profilePicture}`}
                onError={({ currentTarget }) => {
                  currentTarget.src = Dummy;
                }}
                alt="#"
              />
            )}
          </div>
          <div className="modalContainerl">
            <Modal
              show={show}
              onHide={handleClose}
              animation={false}
              className="custom-modalll"
            >
              <div className="devSqurl"></div>
              <Modal.Header closeButton>
                <Link className="MyProfile-Linkl">
                  <Modal.Title className="titleel" onClick={handleLiClick}>
                    My Profile
                  </Modal.Title>
                  <span className="LogRUnl">Super Administrator</span>
                </Link>
              </Modal.Header>
              <Modal.Body className="detlasl">
                <ul>
                  <Link
                    to="/MyAccountSub"
                    className="upGradeell"
                    onClick={handleLiClick}
                  >
                    <MdBarChart />
                    My Account & Subscription
                  </Link>

                  <li onClick={handleLiClick}>
                    <Link to="/NewsAndUpdate">News & Update</Link>
                  </li>
                  <li onClick={handleLiClick}>
                    <Link to="/ADDHelpMain">Help and Support</Link>
                  </li>
                  <li onClick={handleLogout}>Log Out</li>
                </ul>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default LogoutModal;
