import { useEffect, useState } from "react";
import StudentHeader from "../StudentHeader/StudentHeader";
import "./StudentsView.css";
import TableView from "../TableView/TableView";
import CardView from "../CardView/CardView";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

const StudentsView = ({ link }) => {
  const params = useParams();
  const [showTable, setShowTable] = useState(true);

  const [allStaffs, setAllStaffs] = useState(null);

  const { schoolId } = useSelector((state) => state.schoolId);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [allTeachers, setAllTeachers] = useState([])
  const getAllStaffs = async () => {
    try {
      const { data } = await axios.get(`/teachers/all/${schoolId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      const newArr = data.teacherList.map((d) => {
        return {
          staffName: d.staffName,
          staffId: d.staffId,
          profile: d.profile,
          mobileNumber: d.phoneNumber,
          image: d.profilePicture,
          id: d.id,
        };
      });

      setAllTeachers(data.teacherList)
      
      setAllStaffs(newArr);
      setFilteredStudents(newArr);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getAllStaffs();
  }, [schoolId]);

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = () => {
    if (searchValue) {
      const newArr = allStaffs.filter((d) => {
        return d.staffName.toLowerCase().includes(searchValue.toLowerCase());
      });
      setFilteredStudents(newArr);
    } else {
      setFilteredStudents(allStaffs);
    }
  };

  useEffect(() => {
    if (allStaffs) {
      handleSearch();
    }
  }, [searchValue, allStaffs]);

  return (
    filteredStudents && (
      <div className="students-view">
        <StudentHeader
          allStaffs={allTeachers}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          showTable={showTable}
          setShowTable={setShowTable}
          filterFunction={handleSearch}
        />
        {showTable ? (
          <TableView getAllStaffs={getAllStaffs} tableData={filteredStudents} />
        ) : (
          <CardView cardData={filteredStudents} link={"/StaffProfile"} />
        )}
      </div>
    )
  );
};

export default StudentsView;
