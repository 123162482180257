import React, { useEffect, useState } from "react";
import "./Transcripts.css";
import { RiFileExcel2Line } from "react-icons/ri";
import axios from "axios";
import { baseURL } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;
function Transcripts() {
  const [transcript, setTranscript] = useState(null);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const [schoolId, setSchoolId] = useState(null);
  const getSchoolByStudentId = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/ParentBySchoolId/getschoolBystudentId/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setSchoolId(data.school.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchoolByStudentId();
  }, [user]);

  const getTranscripts = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/students/transcript/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        setTranscript(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [results, setResults] = useState(null);

  const getStudentResults = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/results/getStudentResults/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setResults(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTranscripts();
    getStudentResults();
  }, [user]);

  const [school, setSchool] = useState("");

  const getSchool = async () => {
    try {
      if (schoolId) {
        const { data } = await axios.get(`/schools/getschoolById/${schoolId}`, {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        });
        setSchool(data?.school);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchool();
  }, [schoolId]);

  return (
    <div className="Transcripts-main1">
      <div className="Transcript-MainTop">
        <div className="Transcript-Top">
          <h3>Transcript</h3>
        </div>
        <div className="transcriptTable">
          <table>
            <thead>
              <tr>
                <th>Graduation Track</th>
                <th>Graduated</th>
                <th>Grad Stat</th>
                <th>Expected Grad</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Default</td>
                <td>Comment</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="transcriptGpa">
        <div className="Transcript-Top">
          <h3>GPAs</h3>
        </div>
        <h4>
          This process will recompute the student’s built-in 9pa and any
          district defined below.
        </h4>
        <div className="transcriptGpa-Body">
          <div>
            <h5>(W)Acad GPA</h5>
            <p>3,9691 3,773</p>
          </div>
          <div>
            <h5>(W)Total GPA</h5>
            <p>3,9691 3,773</p>
          </div>
          <div>
            <h5>(W)10-12 GAP</h5>
            <p>3,9691 3,773</p>
          </div>
          <div>
            <h5>Credit Att/Compl</h5>
            <p>3,9691 3,773</p>
          </div>
          <div>
            <h5>Class Rank Grad</h5>
            <p>3,9691 3,773</p>
          </div>
        </div>
        <div className="TranscriptGpaTable">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>GPA</th>
                <th>Class Rank/Size</th>
                <th>GPA Last Updated</th>
                <th>Class Rank Last Updated</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
      <div className="CourseHistory">
        <div className="Transcript-Top">
          <h3>Course History</h3>
        </div>
        <div className="CourseInput">
          <input type="checkbox" />
          <label>Sort By Subject</label>
          <input type="checkbox" />
          <label>Sort By Date Descending</label>
        </div>
        <div className="Transcript-Top1">
          <h3>2019-2020 Bald Intermediate School </h3>
          <h3>Grade 8 | Fall</h3>
        </div>
        <div className="TranscriptSchool">
          <table>
            <thead>
              <tr>
                <th>Schl</th>
                <th>Year</th>
                <th>Tm</th>
                <th>Grd</th>
                <th>Crs ID</th>
                <th>Std Course Title</th>
                <th>RT</th>
                <th>CP</th>
                <th>N/H</th>
                <th>Mark</th>
                <th>Atmp</th>
                <th>Comp</th>
                <th>SCT</th>
                <th className="ThLast">More</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>993</td>
                <td>19-20</td>
                <td>1</td>
                <td>8</td>
                <td>100386</td>
                <td>Algebra G</td>
                <td></td>
                <td></td>
                <td></td>
                <td>B</td>
                <td>0.00</td>
                <td>0.00</td>
                <td></td>
                <td>Less</td>
              </tr>
            </tbody>
          </table>
          <div className="TranscriptH6">
            <h6>
              Enrolled: <span>89</span>
            </h6>
            <h6>
              Absences: <span>89</span>
            </h6>
            <h6>
              Present: <span>89</span>
            </h6>
          </div>
          <div className="TranscriptH6">
            <h6>
              Excused Abs: <span>89</span>
            </h6>
            <h6>
              Unexcused Abs: <span>89</span>
            </h6>
            <h6>
              Suspended: <span>89</span>
            </h6>
            <h6>
              Tardy: <span>89</span>
            </h6>
          </div>
          <div className="TranscriptH6">
            <h6>
              Citizenship: <span>89</span>
            </h6>
            <h6>
              Section School: <span>89</span>
            </h6>
            <h6>
              Section: <span>89</span>
            </h6>
            <h6>
              Staff: <span>89</span>
            </h6>
            <h6>
              Date of Completion: <span>89</span>
            </h6>
          </div>
          <div className="TranscriptH6">
            <h6>
              Characteristics: <span>89</span>
            </h6>
          </div>
        </div>
        <div className="Transcript-Top1">
          <h3>2019-2020 Bald Intermediate School </h3>
          <h3>Grade 9 | Spring</h3>
        </div>
        <div className="TranscriptSchool">
          <table>
            <thead>
              <tr>
                <th>Schl</th>
                <th>Year</th>
                <th>Tm</th>
                <th>Grd</th>
                <th>Crs ID</th>
                <th>Std Course Title</th>
                <th>RT</th>
                <th>CP</th>
                <th>N/H</th>
                <th>Mark</th>
                <th>Atmp</th>
                <th>Comp</th>
                <th>SCT</th>
                <th className="ThLast">More</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>993</td>
                <td>19-20</td>
                <td>1</td>
                <td>8</td>
                <td>100386</td>
                <td>Algebra G</td>
                <td></td>
                <td></td>
                <td></td>
                <td>B</td>
                <td>0.00</td>
                <td>0.00</td>
                <td></td>
                <td>Less</td>
              </tr>
            </tbody>
          </table>
          <div className="TranscriptH6">
            <h6>
              Enrolled: <span>89</span>
            </h6>
            <h6>
              Absences: <span>89</span>
            </h6>
            <h6>
              Present: <span>89</span>
            </h6>
          </div>
          <div className="TranscriptH6">
            <h6>
              Excused Abs: <span>89</span>
            </h6>
            <h6>
              Unexcused Abs: <span>89</span>
            </h6>
            <h6>
              Suspended: <span>89</span>
            </h6>
            <h6>
              Tardy: <span>89</span>
            </h6>
          </div>
          <div className="TranscriptH6">
            <h6>
              Citizenship: <span>89</span>
            </h6>
            <h6>
              Section School: <span>89</span>
            </h6>
            <h6>
              Section: <span>89</span>
            </h6>
            <h6>
              Staff: <span>89</span>
            </h6>
            <h6>
              Date of Completion: <span>89</span>
            </h6>
          </div>
          <div className="TranscriptH6">
            <h6>
              Characteristics: <span>89</span>
            </h6>
          </div>
        </div>
        <div className="Transcript-Top1">
          <h3>2023-2024 Bald Intermediate School </h3>
          <h3>Grade 10 | Summer</h3>
        </div>
        <div className="TranscriptSchool">
          <table>
            <thead>
              <tr>
                <th>Schl</th>
                <th>Year</th>
                <th>Tm</th>
                <th>Grd</th>
                <th>Crs ID</th>
                <th>Std Course Title</th>
                <th>RT</th>
                <th>CP</th>
                <th>N/H</th>
                <th>Mark</th>
                <th>Atmp</th>
                <th>Comp</th>
                <th>SCT</th>
                <th className="ThLast">More</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>993</td>
                <td>19-20</td>
                <td>1</td>
                <td>8</td>
                <td>100386</td>
                <td>Algebra G</td>
                <td></td>
                <td></td>
                <td></td>
                <td>B</td>
                <td>0.00</td>
                <td>0.00</td>
                <td></td>
                <td>Less</td>
              </tr>
              <tr>
                <td>993</td>
                <td>19-20</td>
                <td>1</td>
                <td>8</td>
                <td>100386</td>
                <td>Algebra G</td>
                <td></td>
                <td></td>
                <td></td>
                <td>B</td>
                <td>0.00</td>
                <td>0.00</td>
                <td></td>
                <td>Less</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="Transcript-Top1">
          <h3>2023-2024 Bald Intermediate School </h3>
          <h3>Grade 10 | Spring</h3>
        </div>
        <div className="TranscriptSchool">
          <table>
            <thead>
              <tr>
                <th>Schl</th>
                <th>Year</th>
                <th>Tm</th>
                <th>Grd</th>
                <th>Crs ID</th>
                <th>Std Course Title</th>
                <th>RT</th>
                <th>CP</th>
                <th>N/H</th>
                <th>Mark</th>
                <th>Atmp</th>
                <th>Comp</th>
                <th>SCT</th>
                <th className="ThLast">More</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>993</td>
                <td>19-20</td>
                <td>1</td>
                <td>8</td>
                <td>100386</td>
                <td>Algebra G</td>
                <td></td>
                <td></td>
                <td></td>
                <td>B</td>
                <td>0.00</td>
                <td>0.00</td>
                <td></td>
                <td>Less</td>
              </tr>
              <tr>
                <td>993</td>
                <td>19-20</td>
                <td>1</td>
                <td>8</td>
                <td>100386</td>
                <td>Algebra G</td>
                <td></td>
                <td></td>
                <td></td>
                <td>B</td>
                <td>0.00</td>
                <td>0.00</td>
                <td></td>
                <td>Less</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="btnNs">
        <button>Share</button>
        <button>
          <span>
            <RiFileExcel2Line />
          </span>
          Export Data
        </button>
        <button className="Print">Print Transcript</button>
      </div>
    </div>
  );
}

export default Transcripts;
