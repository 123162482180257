import React, { useEffect, useState } from "react";
import { GoPeople } from "react-icons/go";
import { PiChartBarHorizontalDuotone } from "react-icons/pi";
import { PiNotebookDuotone } from "react-icons/pi";
import "./Card.css";
import { LuPlayCircle } from "react-icons/lu";
import axios from "axios";
import { baseURL } from "../../../../../constants";
axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;

function Card() {
  const [attendenceOverview, setAttendenceOverview] = useState(null);

  const getAttendenceOverview = async () => {
    try {
      const { data } = await axios.get("/attendances/getAttendanceOverview", {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setAttendenceOverview(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAttendenceOverview();
  }, []);

  return (
    attendenceOverview && (
      <>
        <div className="ADDtopCards1">
          <div className="ADDtopCards-11">
            <div className="ADDtopCards-11-svg1">
              <GoPeople />
            </div>
            <div>
              <h2>Total </h2>
              <p>{attendenceOverview.totalStudents}</p>
            </div>
          </div>
          <div className="ADDtopCards-41">
            <div className="ADDtopCards-41-svg1">
              <LuPlayCircle />
            </div>
            <div>
              <h2>Present</h2>
              <p>{attendenceOverview.totalPresents}</p>
            </div>
          </div>
          <div className="ADDtopCards-21">
            <div className="ADDtopCards-21-svg1">
              <PiChartBarHorizontalDuotone />
            </div>
            <div>
              <h2>Absents</h2>
              <p>{attendenceOverview.unexcused}</p>
            </div>
          </div>
          <div className="ADDtopCards-31">
            <div className="ADDtopCards-31-svg1">
              <PiNotebookDuotone />
            </div>
            <div>
              <h2>Excused</h2>
              <p>{attendenceOverview.excused}</p>
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default Card;
