import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { TbArrowsDiagonal } from "react-icons/tb";
import { IoAlertCircle } from "react-icons/io5";
import { Dropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./LineChart.css";

const LineChart = () => {
  const chartContainer = useRef(null);
  const chartInstance = useRef(null);
  const [vendorCentrality, setVendorCentrality] = useState("centralityScore");

  const chartData = {
    centralityScore: [1, 3, 5, 4, 2, 6, 7, 10],
    VendorScore: [43, 23, 53, 23, 52, 64, 23, 15],
  };

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    if (chartContainer && chartContainer.current) {
      const ctx = chartContainer.current.getContext("2d");

      chartInstance.current = new Chart(ctx, {
        type: "line",
        data: {
          labels: [
            "Dec 24",
            "Dec 25",
            "Dec 26",
            "Dec 27",
            "Dec 28",
            "Dec 29",
            "Dec 30",
            "Dec 31",
          ],
          datasets: [
            {
              label:
                vendorCentrality === "centralityScore"
                  ? "App Centrality Score"
                  : "Vendor Centrality Score",
              data: chartData[vendorCentrality],
              fill: true,
              backgroundColor: "rgba(0, 175, 239, 0.1)",
              borderColor: "#00AFEF",
              opacity: ".2",
              borderWidth: 1,
              tension: 0.4,
            },
          ],
        },
        options: {
          scales: {
            x: {
              title: {
                display: true,
              },
              grid: {
                display: false,
              },
            },
            y: {
              title: {
                display: true,
              },
              beginAtZero: true,
              ticks: {
                callback: (value) => {
                  if (value === 0) return "";
                  if (value >= 1000) {
                    return value / 1000 + "k";
                  }
                  return value;
                },
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    }
  }, [vendorCentrality]);

  const handleScoreChange = (eventKey) => {
    setVendorCentrality(eventKey);
  };

  return (
    <div className="AddminsLineChart">
      <div className="AddDropdown">
        <div className="appCentrality">
          <h2>App Centrality Score </h2>
          <span title="Something about this feature and what it does. ">
            <IoAlertCircle />
          </span>
          <span>
            <TbArrowsDiagonal />
          </span>
        </div>
        <Dropdown onSelect={handleScoreChange}>
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className="dropdown"
          >
            {vendorCentrality === "centralityScore"
              ? "App Centrality Score"
              : "Vendor Centrality Score"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey="centralityScore">
              App Centrality Score
            </Dropdown.Item>
            <Dropdown.Item eventKey="VendorScore">
              Vendor Centrality Score
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <canvas ref={chartContainer} />
    </div>
  );
};

export default LineChart;
