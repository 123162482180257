import { configureStore } from "@reduxjs/toolkit";
import {
  getAllSchoolsReducer,
  getUserProfileReducer,
  schoolIdReducer,
  studentIdReducer,
  userReducer,
  medicalStudentIdReducer,
  medicalHistoryStudentIdReducer,
} from "./reducers/userReducer";

import misc from "./reducers/miscReducer";

import chats from "./chatsApis";

import eventsReducer from "./reducers/eventsReducer";

const store = configureStore({
  reducer: {
    user: userReducer,
    studentId: studentIdReducer,
    schoolId: schoolIdReducer,
    getAllSchools: getAllSchoolsReducer,
    getUserProfile: getUserProfileReducer,
    medicalStudentId: medicalStudentIdReducer,
    medicalHistoryStudentId: medicalHistoryStudentIdReducer,
    events: eventsReducer,
    [misc?.name]: misc.reducer,
    [chats.reducerPath]: chats.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(chats.middleware),
});

export default store;
