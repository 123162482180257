import React, { useEffect, useState } from "react";
import ParentPortalWelcomeBack from "../ParentPortalWelcomeBack/ParentPortalWelcomeBack";
import { Link } from "react-router-dom";
import "./ParentPortalHome.css";
import ParentAttendenceFltr from "../../ParentAttendenceFltr/ParentAttendenceFilter";
import MySchedule from "../../ParentMyschedule/MySchedule";
import ParentsCalander from "../../ParentSmallCalander/ParentsCalander.";
import ParentGpa from "../../ParentCurrentGpa/ParentGpa";
import GradesParent from "../../Grades-main/MyGrade/GradesParent";
import axios from "axios";
import { baseURL } from "../../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

function ParentPortalHome() {
  const [recentGrades, setRecentGrades] = useState(null);

  const { studentId } = useSelector((state) => state.studentId);

  const getRecentGrades = async () => {
    try {
      const { data } = await axios.get(`/grades/recent/${studentId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });

      setRecentGrades(data);
    } catch (error) {
      console.log(error);
    }
  };

  const [attendaces, setAttendaces] = useState(null);

  const getAttendances = async () => {
    try {
      const { data } = await axios.get(
        `/attendances/getStudentAttendanceForToday/${studentId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setAttendaces(data?.attendanceToday);
    } catch (error) {
      console.log(error);
    }
  };

  const [assignments, setAssignments] = useState(null);

  const getAssignments = async () => {
    try {
      const { data } = await axios.get(
        `/attendances/getStudentAttendanceForToday/${studentId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setAssignments(data?.attendanceToday);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRecentGrades();
    getAttendances();
    getAssignments();
  }, [studentId]);

  return (
    <div className="Home-main">
      <div className="Home-70">
        <ParentPortalWelcomeBack />
        <div className="ParentPortalMyGrade">
          <div className="RecentGrades">
            <h2>Recent Grades</h2>
            <Link to="/ParentGrades">view all</Link>
          </div>
          {recentGrades && (
            <GradesParent isShow={false} status={"home"} data={recentGrades} />
          )}
        </div>
        <div className="ParentPortalMyGrade1">
          <div className="RecentGrades1">
            <h2>Today Attendance </h2>
            <Link to="/AttendencePage">view all</Link>
          </div>
          {assignments && <ParentAttendenceFltr assignments={assignments} />}
        </div>
      </div>
      <div className="Home-30">
        <ParentsCalander />
        <MySchedule />
        <ParentGpa />
      </div>
    </div>
  );
}

export default ParentPortalHome;
