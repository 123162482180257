import { FaRegCirclePlay } from "react-icons/fa6";
import { FiStopCircle } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import React, { useState, useEffect } from "react";
import "./Addimage.css";
import expo from "../Group 1000004529.png";
import Drive from "../Vector.png";
import SVG from "./Group 1000004531.svg";
import axios from "axios";
import { baseURL } from "../../../../constants";
import toast from "react-hot-toast";
axios.defaults.baseURL = baseURL;

function Addimage({ subjectId, studentId, assignmentId, func }) {
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [inputImage, setInputImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadBarVisible, setUploadBarVisible] = useState(false);
  const [uploadingFileName, setUploadingFileName] = useState("");
  const [title, setTitle] = useState("");
  const [myFile, setMyFile] = useState(null);

  const handleSubmitAssignment = () => {
    const newForm = new FormData();
    newForm.append("subjectId", subjectId);
    newForm.append("studentId", studentId);
    newForm.append("assignmentId", assignmentId);
    newForm.append("title", title);
    newForm.append("fileUrl", myFile);

    axios
      .post(`/assignmentSubmissions/submitAssignment`, newForm, {
        headers: {
          "Content-Type": "multipart/form-data",
          accessToken: `${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        toast.success("Assignment Submitted Successfully");
        func();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });
  };
  useEffect(() => {
    if (uploading) {
      const interval = setInterval(() => {
        setUploadProgress((prevProgress) =>
          prevProgress < 100 ? prevProgress + 10 : 100
        );
      }, 500);

      return () => clearInterval(interval);
    }
  }, [uploading]);

  const handleFileChange = (selectedFile, section) => {
    if (selectedFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        if (section === "thumbnail") {
          setThumbnailImage({ src: reader.result, name: selectedFile.name });
        } else if (section === "inputs") {
          setInputImage({ src: reader.result, name: selectedFile.name });
        }
      };

      reader.readAsDataURL(selectedFile);
      setUploadingFileName(selectedFile.name);
      setUploadBarVisible(true);
    }
  };

  const handleDropThumbnail = (e) => {
    e.preventDefault();

    const droppedFile = e.dataTransfer.files[0];

    if (droppedFile) {
      handleFileChange(droppedFile, "thumbnail");
    }
  };

  const handleFileInputThumbnail = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      handleFileChange(selectedFile, "thumbnail");
    }
  };

  const handleDropInputs = (e) => {
    e.preventDefault();

    const droppedFile = e.dataTransfer.files[0];

    if (droppedFile) {
      handleFileChange(droppedFile, "inputs");
    }
  };

  const handleFileInputInputs = (e) => {
    const selectedFile = e.target.files[0];
    setMyFile(selectedFile);
    if (selectedFile) {
      handleFileChange(selectedFile);
    }
  };

  const handlePlayPause = () => {
    setUploading(!uploading);
  };

  const handleDeleteUpload = () => {
    setThumbnailImage(null);
    setInputImage(null);
    setUploadBarVisible(false);
    setUploadProgress(0);
  };

  const [fileProcess, setFileProcess] = useState(0);

  const [previewImage, setPreviewImage] = useState("");

  const handleFileSelect = (e) => {
    const file = e.target.files[0];

    setMyFile(file);

    const fileReader = new FileReader();

    fileReader.onload = () => {
      if (fileReader.readyState == 2) {
        setFileProcess(100);
        setPreviewImage(fileReader.result);
      }
    };

    fileReader.readAsDataURL(file);
  };

  return (
    <>
      <div className="Thumbnail-main">
        <div
          className="Thumbnail-div2"
          onDrop={handleDropThumbnail}
          onDragOver={(e) => e.preventDefault()}
        >
          {myFile ? (
            <>
              <img
                src={previewImage}
                alt="Uploaded"
                className="Thumbnail-image"
              />
              <p>{myFile.name}</p>
            </>
          ) : (
            <>
              <img src={expo} alt="#" className="Expo-Image" />
              <div>
                <label
                  htmlFor="fileInputThumbnails"
                  className="Thumbnail-labels"
                >
                  upload a document/
                  <br />
                  Png/jpeg/mp4/csv
                </label>
              </div>
            </>
          )}
          <input
            type="file"
            id="fileInputThumbnails"
            style={{ display: "none" }}
            onChange={handleFileSelect}
          />
        </div>
        <div className="Inputs-Addimage">
          <input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Title"
            className="Inputs-Addimage-btn1"
          />
          <div
            className="Input-Addimage-btn2"
            onDrop={handleDropInputs}
            onDragOver={(e) => e.preventDefault()}
          >
            {inputImage ? (
              <>
                <img
                  src={inputImage.src}
                  alt="Uploaded"
                  className="Thumbnail-image"
                />
                <p>{inputImage.name}</p>
                <label htmlFor="fileInputInputs" className="Thumbnail-labels">
                  Change Documents
                </label>
              </>
            ) : (
              <>
                <div className="AddFile">
                  <label
                    htmlFor="fileInputInputs"
                    className="Thumbnail-labels"
                    style={{ marginTop: "10px" }}
                  >
                    Upload From Dropbox/Google Drive
                  </label>
                  <img
                    src={Drive}
                    alt="#"
                    className="Expo-Image"
                    style={{ marginTop: "10px" }}
                  />
                </div>
              </>
            )}
            <input
              type="file"
              id="fileInputInputs"
              style={{ display: "none" }}
              onChange={handleFileSelect}
            />
          </div>
        </div>
      </div>

      <div className="deleteProgressbar">
        <>
          <img src={SVG} alt="icon" />
          <h6 className="UploadingFileName">{uploadingFileName}</h6>

          <progress value={fileProcess} max="100" className="Upload-progress" />

          {uploading ? (
            <FaRegCirclePlay
              alt="Pause"
              className="PlayPause-icon"
              onClick={handlePlayPause}
            />
          ) : (
            <FiStopCircle
              alt="Play"
              className="PlayPause-icon"
              onClick={handlePlayPause}
            />
          )}
          <MdDeleteForever
            className="Delete-icon"
            onClick={handleDeleteUpload}
          />
        </>
      </div>
      <div className="btnss">
        <button onClick={handleSubmitAssignment}>Submit</button>
      </div>
    </>
  );
}

export default Addimage;
