import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import ADDHelpFaqs from "./ADDHelpFaqs";
import "./ADDHelp.css";
import FaqsSvg from "../SvgIcons/faq.svg";
import { MdSettings } from "react-icons/md";

function ADDHelpMain() {
  return (
    <div className="ADDHELPMAIN">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col sm={3} className="SIdeTabsHELP">
            <Nav variant="pills" className="flex-column ">
              <h3>Help Center</h3>
              <Nav.Item>
                <Nav.Link eventKey="first">
                  <img src={FaqsSvg} alt="#" />
                  FAQ's
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">
                  <MdSettings />
                  Contact Us
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <ADDHelpFaqs />
              </Tab.Pane>
              <Tab.Pane eventKey="second">Contact Us</Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}

export default ADDHelpMain;
