import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Sidebar from "../ParentPortalSideBar/Sidebar";
import ParentPortalTopSearchBar from "../ParentPortalTopSearchBar/ParentPortalTopSearchBar";
import ParentPortalHome from "../ParentPortalAllComponents/Home/ParentPortalHome";
import Transcripts from "../ParentPortalAllComponents/Transcripts/Transcripts";
import MyGrade from "../ParentPortalAllComponents/MyGrade/MyGrade";
import TeacherTopSearchBar from "../../TeacherComponent/TeacherTopSearchBar/TeacherTopSearchBar";
import ParentSetting from "../Parentsetting/ParentSetting";
import TuitionInfo from "../ParentTuitionInfo/TuitionInfo";
import ParentInfoMain from "../ParentStudentInfo/ParentInfoMain";
import ParentAssigments from "../ParentAssigments/ParentAssigments";
import ParentAppsAccess from "../ParentPortalAllComponents/ParentAppsAccess/ParentAppsAccess";
import ParentGrades from "../ParentsGradesPage/ParentGrades";
import Assigment from "../ParentAssigmentResult/Assigment";
import AttendencePage from "../ParentAttendencePage/AttendencePage";
import Calender from "../CalenderPage/Calender";
import ADDChatBox from "../ADDChatBox/ADDChatBox";
import Medical from "../Medical/Medical";
import Grades from "../StudentGrades/Grades";
import History from "../GradeHistory/History";
import GraStatus from "../GraduationStatus/GraStatus";
import Guidance from "../Guidance/Guidance";
import MediHistory from "../MedicalHistory/MediHistory";
import Plan from "../Plan504/Plan";
import Special from "../SpecialEducation/Special";
import ReportCard from "../ReportCard/ReportCard";
import { useDispatch } from "react-redux";
import { getUserProfile } from "../../../redux/actions/userAction";
import ExtraActivities from "../Extracurricular/ExtraActivities";
import AddEvent from "../CalenderPage/AddEvent";
function ParentPortalRouting() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  return (
    <div>
      <Routes>
        <Route
          path="/Parentportal"
          element={<PrivateRoute component={<ParentPortalHomeWithSidebar />} />}
        />
        <Route
          path="/ParentTranscripts"
          element={<PrivateRoute component={<TranscriptsWithSidebar />} />}
        />
        <Route
          path="/ParentMygrade"
          element={<PrivateRoute component={<MyGradeWithSidebar />} />}
        />
        <Route
          path="/AccountSetting"
          element={<PrivateRoute component={<ParentSettingWithSidebar />} />}
        />
        <Route
          path="/ParentTuitionInfo"
          element={<PrivateRoute component={<TuitionInfoWithSidebar />} />}
        />
        <Route
          path="/ParentInfoMain"
          element={<PrivateRoute component={<ParentInfoMainWithSidebar />} />}
        />
        <Route
          path="/ParentAssigments"
          element={<PrivateRoute component={<ParentAssigmentsWithSidebar />} />}
        />
        <Route
          path="/ParentAppsAccess"
          element={<PrivateRoute component={<ParentAppsAccessWithSidebar />} />}
        />
        <Route
          path="/ParentGrades"
          element={<PrivateRoute component={<ParentGradesWithSidebar />} />}
        />
        <Route
          path="/ParentAssigment"
          element={<PrivateRoute component={<AssigmentWithSidebar />} />}
        />
        <Route
          path="/AttendencePage"
          element={<PrivateRoute component={<AttendencePageWithSidebar />} />}
        />
        <Route
          path="/ParentCalender"
          element={<PrivateRoute component={<CalenderWithSidebar />} />}
        />
        <Route
          path="/ParentChat"
          element={<PrivateRoute component={<ADDChatBoxWithSidebar />} />}
        />
        <Route
          path="/ChildMedical"
          element={<PrivateRoute component={<MedicalWithSidebar />} />}
        />
        <Route
          path="/ChildGrades"
          element={<PrivateRoute component={<GradesWithSidebar />} />}
        />
        <Route
          path="/ChildHistory"
          element={<PrivateRoute component={<HistoryWithSidebar />} />}
        />
        <Route
          path="/ChildStatus"
          element={<PrivateRoute component={<GraStatusWithSidebar />} />}
        />
        <Route
          path="/ChildGuidance"
          element={<PrivateRoute component={<GuidanceWithSidebar />} />}
        />
        <Route
          path="/ChildMediHistory"
          element={<PrivateRoute component={<MediHistoryWithSidebar />} />}
        />
        <Route
          path="/ChildPlan"
          element={<PrivateRoute component={<PlanWithSidebar />} />}
        />
        <Route
          path="/ChildSpecial"
          element={<PrivateRoute component={<SpecialWithSidebar />} />}
        />
        <Route
          path="/ChildReportCard"
          element={<PrivateRoute component={<ReportCardWithSidebar />} />}
        />
        <Route
          path="/Extracurricularactivities"
          element={<PrivateRoute component={<ExtraActivitiesWithSidebar />} />}
        />
        <Route
          path="/ParentAddEvent"
          element={<PrivateRoute component={<AddEventWithSidebar />} />}
        />
      </Routes>
    </div>
  );
}

function PrivateRoute({ component }) {
  const isAuthenticated = true;

  if (isAuthenticated) {
    return component;
  } else {
    return <Navigate to="/Login" replace />;
  }
}

function ParentPortalHomeWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <ParentPortalHome />
    </Sidebar>
  );
}
function TranscriptsWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <Transcripts />
    </Sidebar>
  );
}

function MyGradeWithSidebar() {
  return (
    <Sidebar>
      <TeacherTopSearchBar />
      <MyGrade />
    </Sidebar>
  );
}

function TuitionInfoWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <TuitionInfo />
    </Sidebar>
  );
}
function ParentInfoMainWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <ParentInfoMain />
    </Sidebar>
  );
}

function ParentAssigmentsWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <ParentAssigments />
    </Sidebar>
  );
}

function ParentAppsAccessWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <ParentAppsAccess />
    </Sidebar>
  );
}
function ParentGradesWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <ParentGrades />
    </Sidebar>
  );
}

function AssigmentWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <Assigment />
    </Sidebar>
  );
}
function AttendencePageWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <AttendencePage />
    </Sidebar>
  );
}
function CalenderWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <Calender />
    </Sidebar>
  );
}
function AddEventWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <AddEvent />
    </Sidebar>
  );
}

function ParentSettingWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <ParentSetting />
    </Sidebar>
  );
}
function ADDChatBoxWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <ADDChatBox />
    </Sidebar>
  );
}
function MedicalWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <Medical />
    </Sidebar>
  );
}

function GradesWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <Grades />
    </Sidebar>
  );
}
function HistoryWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <History />
    </Sidebar>
  );
}

function GraStatusWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <GraStatus />
    </Sidebar>
  );
}
function GuidanceWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <Guidance />
    </Sidebar>
  );
}
function MediHistoryWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <MediHistory />
    </Sidebar>
  );
}
function PlanWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <Plan />
    </Sidebar>
  );
}
function SpecialWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <Special />
    </Sidebar>
  );
}
function ReportCardWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <ReportCard />
    </Sidebar>
  );
}
function ExtraActivitiesWithSidebar() {
  return (
    <Sidebar>
      <ParentPortalTopSearchBar />
      <ExtraActivities />
    </Sidebar>
  );
}
export default ParentPortalRouting;
