import React, { useEffect, useState } from "react";
import "./ADDEditStudentinfo.css";
import RefreshIcon from "@mui/icons-material/Refresh";
import Select from "react-select";
import axios from "axios";
import { baseURL } from "../../../../constants";
import countryList from "country-list";
import languageList from "language-list";
import { Autocomplete, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  isValidName,
  requiredValues,
  isValidPassword,
} from "../../../../utils/regex";
import toast from "react-hot-toast";
axios.defaults.baseURL = baseURL;

function ADDEditStudentinfo({
  student,
  setShowEdit = () => {},
  getStudent,
  showEditPicture,
  selectedFile,
  setShowEditPicture,
}) {
  const options = [
    { value: "1", label: "Akash" },
    { value: "2", label: "Ey" },
    { value: "3", label: "Charakuz" },
  ];
  const { schoolId } = useSelector((state) => state.schoolId);

  const [parents, setParents] = useState([]);

  const getParents = async () => {
    try {
      const { data } = await axios.get(`/parents/getParents/${schoolId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });

      const newArr = data?.parents.map((data) => {
        return {
          value: data?.id,
          label: `${data?.firstName} ${data?.middleName} ${data?.lastName}`,
        };
      });

      setParents(newArr);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getParents();
  }, [schoolId]);

  const [parentId, setParentId] = useState(student?.parentId);
  const handleChange = (selectedOption) => {
    console.log(selectedOption.value, "I've selected this");
    setParentId(selectedOption ? selectedOption.value : null);
  };

  const languages = languageList();

  console.log(student, "this is my studnet");
  const [allLanguages, setAllLanguages] = useState(null);

  useEffect(() => {
    const allLanguages = languages.getData().map((item) => {
      return item.language;
    });
    setAllLanguages(allLanguages);
  }, []);
  const [isShow, setIsShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const [handleFirstSwitch, setHandleFirstSwitch] = useState(false);
  const [handleSecondSwitch, setHandleSecondSwitch] = useState(false);

  const generateRandomPassword = () => {
    const randomPassword = Math.random().toString(36).slice(-8);
    setPassword(randomPassword);
  };

  const [handleFirstRadio, setHandleFirstRadio] = useState(false);
  const [firstName, setFirstName] = useState(student?.firstName);
  const [middleName, setMiddleName] = useState(student?.middleName);
  const [lastName, setLastName] = useState(student?.lastName);
  const [studentInformation, setStudentInformation] = useState(
    student?.studentInformation
  );

  const [prefferedName, setPrefferedName] = useState(student?.prefferedName);

  const [stateId, setStateId] = useState(student?.stateId);
  const [districtId, setDistrictId] = useState(student?.districtId);
  const [admissionNumber, setAdmissionNumber] = useState(
    student?.admissionNumber
  );

  const [govtIssuedNumber, setGovtIssuedNumber] = useState(
    student?.govtIssuedNumber
  );

  const [dateOfBirth, setDateOfBirth] = useState(
    new Date(student?.dateOfBirth).toISOString().split("T")[0]
  );

  const [gender, setGender] = useState(student?.gender);
  const [countryOfBirth, setCountryOfBirth] = useState(student?.countryOfBirth);
  const [nationality, setNationality] = useState(student?.nationality);
  const [nativeLanguage, setNativeLanguage] = useState(student?.nativeLanguage);
  const [spokenLanguages, setSpokenLanguages] = useState("");
  useEffect(() => {
    if (student) {
      setParentId(Number(student?.parentId));
    }
    if (student?.spokenLanguages) {
      setSpokenLanguages(
        Array.isArray(student?.spokenLanguages)
          ? student?.spokenLanguages?.join(",")
          : ""
      );
    } else {
      setSpokenLanguages("");
    }
  }, [student]);
  const [portalAccess, setPortalAccess] = useState(
    student?.portalAccess || false
  );

  const [ssoLogin, setSSOLogin] = useState(student?.ssoLogin || false);
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(student?.disabled || false);
  const [studentId, setStudentId] = useState(student?.studentId);
  const [loginEmailAddress, setLoginEmailAddress] = useState(
    student?.loginEmailAddress
  );

  const [showCreatePassword, setShowCreatePassword] = useState(false);
  const params = useParams();

  useEffect(() => {
    if (student?.countryOfBirth) {
      setCountryOfBirth(student.countryOfBirth);
    }
  }, [student]);
  const navigate = useNavigate();

  const { studentId: st } = useParams();
  const [relationship, setRelationship] = useState(student?.relationship);
  const [ethinic, setEthinic] = useState(student?.ethnicGroup);

  console.log(student?.travelBybus, "juu naa");
  const [travelByBus, setTravelByBus] = useState(student?.travelBybus);

  console.log(student, "This is student ddd");
  const handleEditStudentGeneralInformation = async (e) => {
    try {
      e.preventDefault();
      const newForm = new FormData();
      selectedFile && newForm.append("profilePicture", selectedFile);

      if (!isValidName(firstName, "First name")) return;
      if (middleName) {
        if (!isValidName(middleName, "Middle name")) return;
      }

      if (!isValidName(lastName, "Last name")) return;
      if (prefferedName) {
        if (!isValidName(prefferedName, "Preffered name")) return;
      }
      if (
        !requiredValues([
          {
            value: firstName,
            name: "First name",
          },
          {
            value: lastName,
            name: "Last name",
          },
          {
            value: studentInformation,
            name: "Student Information",
          },
          {
            value: studentId,
            name: "Student ID",
          },
          {
            value: ethinic,
            name: "Ethinic Group",
          },
          {
            value: travelByBus,
            name: "Travel by bus",
          },
          {
            value: stateId,
            name: "State ID",
          },
          {
            value: districtId,
            name: "District ID",
          },
          {
            value: admissionNumber,
            name: "Admission Number",
          },
          {
            value: govtIssuedNumber,
            name: "Government Issued Number",
          },
          {
            value: dateOfBirth,
            name: "Date of birth",
          },
          {
            value: gender,
            name: "Gender",
          },
          {
            value: countryOfBirth,
            name: "Country of birth",
          },
          {
            value: nationality,
            name: "Nationality",
          },
          {
            value: nativeLanguage,
            name: "Native Language",
          },
          {
            value: ethinic,
            name: "Ethnic Group",
          },
          {
            value: loginEmailAddress,
            name: "Login Email Address",
          },
        ])
      )
        return;

      if (!spokenLanguages || spokenLanguages.length < 1)
        return toast.error("Spoken Languages are required");

      if (prefferedName) {
        if (!isValidName(prefferedName, "Preffered/Common name")) return;
      }
      if (password) {
        if (!isValidPassword(password)) return;
      }

      if (!isValidName(relationship, "Relationship with student")) return;

      newForm.append("firstName", firstName);
      newForm.append("middleName", middleName);
      newForm.append("lastName", lastName);
      newForm.append("studentInformation", studentInformation);
      newForm.append("prefferedName", prefferedName);
      newForm.append("travelBybus", travelByBus);
      newForm.append("stateId", stateId);
      newForm.append("districtId", districtId);
      newForm.append("admissionNumber", admissionNumber);
      newForm.append("disabled", disabled);
      newForm.append("govtIssuedNumber", govtIssuedNumber);
      newForm.append("dateOfBirth", dateOfBirth);
      newForm.append("gender", gender);
      newForm.append("countryOfBirth", countryOfBirth);
      newForm.append("nationality", nationality);
      newForm.append("parentId", parentId);
      newForm.append("relationship", relationship);
      newForm.append("nativeLanguage", nativeLanguage);
      newForm.append("ethnicGroup", ethinic);

      spokenLanguages &&
        spokenLanguages?.split(",")?.forEach((element) => {
          newForm.append("spokenLanguages[]", element);
        });

      newForm.append("portalAccess", portalAccess);
      newForm.append("ssoLogin", ssoLogin);
      password && newForm.append("password", password);
      newForm.append("loginEmailAddress", loginEmailAddress);
      newForm.append("studentId", studentId);
      newForm.append("schoolId", student?.schoolId);
      const { data } = await axios.put(
        `/students/updateProfile/${params?.studentId}`,
        newForm,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      if (data) {
        await axios.post(
          `/relations/add`,
          {
            parentId: Number(parentId),
            studentId: Number(st),
            relationship,
          },
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
      }

      toast.success("Profile Updated Successfully");
      setShowEdit(false);
      getStudent();
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <div className="ADDEditStudentInfo-main">
      <form onSubmit={handleEditStudentGeneralInformation}>
        <div className="ADDEditStudent">
          <h3>Identification Information</h3>

          <div className="ADDEditStudentInfo-inputs">
            <div>
              <label>First/Given Name</label> <br />
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="-"
                required
              />
            </div>
            <div>
              <label>Middle Name</label> <br />
              <input
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
                placeholder="-"
              />
            </div>
            <div>
              <label>Last/Family Name</label> <br />
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="-"
                required
              />
            </div>
          </div>
        </div>

        <div className="ADDEditStudentInfo-inputschild-1">
          <div>
            <label>Student Information</label> <br />
            <input
              value={studentInformation}
              onChange={(e) => setStudentInformation(e.target.value)}
              placeholder="-"
              required
            />
          </div>
          <div>
            <label>Preferred/Common Name</label> <br />
            <input
              value={prefferedName}
              onChange={(e) => setPrefferedName(e.target.value)}
              placeholder="-"
            />
          </div>
          <div>
            <label>Student ID</label> <br />
            <input
              value={studentId}
              onChange={(e) => setStudentId(e.target.value)}
              placeholder="-"
              required
            />
          </div>
          <div className="ADDEditStudentInfo-inpOne">
            <label>Ethnicity Groups *</label>
            <select
              value={ethinic}
              onChange={(e) => setEthinic(e.target.value)}
            >
              <option value="">Select Group</option>
              <option value="Asian">Asian</option>
              <option value="American">American</option>
              <option value="German">German</option>
              <option value="Italian">Italian</option>
              <option value="Irish">Irish</option>
              <option value="South American">South American</option>
            </select>
          </div>
          <div className="ADDEditStudentInfo-inpOne">
            <label>Travel By Bus *</label>
            <select
              value={travelByBus}
              onChange={(e) => setTravelByBus(e.target.value)}
            >
              <option value=""></option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
        </div>
        <div className="ADDEditStudentInfo-inputschild-1">
          <div>
            <label>State ID</label> <br />
            <input
              value={stateId}
              onChange={(e) => setStateId(e.target.value)}
              placeholder="-"
              required
            />
          </div>
          <div>
            <label>District ID</label> <br />
            <input
              value={districtId}
              onChange={(e) => setDistrictId(e.target.value)}
              placeholder="-"
              required
            />
          </div>
          <div>
            <label>Relationship With Student</label> <br />
            <input
              value={relationship}
              onChange={(e) => setRelationship(e.target.value)}
              placeholder="-"
              required
            />
          </div>
          <div>
            <label>Guardian Name *</label> <br />
            <Select
              value={options.find((option) => option.value === parentId)}
              onChange={handleChange}
              options={parents}
            />
          </div>
        </div>
        <div className="ADDEditStudentInfo-inputschild-1">
          <div>
            <label>Out Govt Issued Number</label> <br />
            <input
              value={govtIssuedNumber}
              onChange={(e) => setGovtIssuedNumber(e.target.value)}
              placeholder="-"
              required
            />
          </div>
          <div>
            <label>Admission Number</label> <br />
            <input
              value={admissionNumber}
              onChange={(e) => setAdmissionNumber(e.target.value)}
              placeholder="-"
              required
            />
          </div>
        </div>

        <div className="ADDEditStudentInfo-child-2">
          <h3>Demographic Information</h3>
          <div className="ADDEditStudentInfo-inp">
            <div>
              <label>Date of birth</label> <br />
              <input
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
                required
                type="date"
                placeholder="-"
              />
            </div>
            <div>
              <label>Gender</label> <br />
              <select
                required
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="">Select Gender</option>

                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="Non-Binary">Non-Binary</option>
              </select>
            </div>
            <div>
              <label>Country of birth</label> <br />
              <Autocomplete
                disablePortal
                value={countryOfBirth}
                onChange={(event, newValue) => {
                  setCountryOfBirth(newValue);
                }}
                id="combo-box-demo"
                options={countryList.getNames()}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </div>
          <div className="ADDEditStudentInfo-inp">
            <div>
              <label>Nationality</label> <br />
              <Autocomplete
                disablePortal
                value={nationality}
                onChange={(event, newValue) => {
                  setNationality(newValue);
                }}
                id="combo-box-demo"
                options={countryList.getNames()}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
            <div>
              <label>Native Language</label> <br />
              {allLanguages && (
                <Autocomplete
                  disablePortal
                  value={nativeLanguage}
                  onChange={(event, newValue) => {
                    setNativeLanguage(newValue);
                  }}
                  id="combo-box-demo"
                  options={allLanguages}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            </div>
            <div>
              <label>Languages Spoken</label> <br />
              {allLanguages && (
                <Autocomplete
                  disablePortal
                  value={spokenLanguages}
                  onChange={(event, newValue) => {
                    setSpokenLanguages(newValue);
                  }}
                  id="combo-box-demo"
                  options={allLanguages}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            </div>
          </div>
        </div>
        <div
          className="ADDEditSchoolInfo-Switch"
          style={{ display: "flex", gap: "20px", alignItems: "center" }}
        >
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id=""
              checked={portalAccess}
              onChange={() => setPortalAccess(!portalAccess)}
            />
            <label className="form-check-label">Portal Access</label>
          </div>
          {portalAccess ? (
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id=""
                checked={ssoLogin}
                onChange={() => setSSOLogin(!ssoLogin)}
              />
              <label className="form-check-label">Allow SSO Login</label>
            </div>
          ) : null}
        </div>

        {portalAccess && (
          <div className="ADDSwitchShowInput">
            <div className="ADDSwitchShowInput-child-1">
              <label>Access Information</label> <br />
              <input
                required
                value={loginEmailAddress}
                onChange={(e) => setLoginEmailAddress(e.target.value)}
                placeholder="Login Email Address*"
              />
            </div>
            <div className="ADDSwitchShowInput-child-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  checked={showCreatePassword}
                  onChange={() => setShowCreatePassword(!showCreatePassword)}
                />
                <label
                  class="form-check-label"
                  for="flexCheckDefault"
                  style={{ color: "black" }}
                >
                  Set or Reset Password
                </label>
              </div>
            </div>
          </div>
        )}
        {/* ///////////////////Show Hide////////////////////// */}
        {showCreatePassword ? (
          <div className="ADDEditShowHide">
            <h4>Password</h4>
            <h5>Student email to set or reset password</h5>
            <div class="form-check">
              <input
                class="form-check-input "
                type="radio"
                name="exampleRadios"
                id="exampleRadios1"
                value="option1"
              />
              <label class="form-check-label labelss" for="exampleRadios1">
                Student will be able to create password on their own by clicking
                the link on their email
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="exampleRadios1"
                value="option1"
              />
              <label class="form-check-label" for="exampleRadios1">
                Create Password
              </label>
            </div>
            <div className="ADDShowCloseDiv-main">
              <div className="ADDShowCloseDiv">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                />
                <span className="eye-icon" onClick={handleTogglePassword}>
                  {showPassword ? "👁️" : "👁️‍🗨️"}
                </span>
              </div>
              <p onClick={generateRandomPassword}>
                <RefreshIcon />
                Auto Generate Password
              </p>
            </div>
          </div>
        ) : null}
        {/* ///////////////////////End Show Hide/////////////////////////// */}
        <div className="ADDEditFooter">
          {/* <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              checked={disabled}
              id="flexCheckDisabled"
              onChange={() => setDisabled(!disabled)}
            />
            <label class="form-check-label" for="flexCheckDisabled">
              Disabled checkbox
            </label>
          </div> */}
          <button
            onClick={() => {
              setShowEdit(false);
              setShowEditPicture(false);
            }}
            className="ADDEditFooter-btn1"
          >
            Cancel
          </button>
          <button type="submit" className="ADDEditFooter-btn2">
            Update
          </button>
        </div>
      </form>
    </div>
  );
}

export default ADDEditStudentinfo;
