import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../constants";

const chats = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    headers: {
      accessToken: localStorage.getItem("token"),
    },
  }),

  tagTypes: ["Chats"],
  endpoints: (builder) => ({
    getMyChats: builder.query({
      query: ({ role, userId }) => ({
        url: `/chats/members/${role}/${userId}`,
      }),
      keepUnusedDataFor: 0,
    }),
    getChatMessages: builder.query({
      query: ({ chatId }) => ({
        url: `/messages/getMessagebychatId/${chatId}`,
      }),
    }),
    getChatDetails: builder.query({
      query: ({ chatId, userId, role }) => ({
        url: `/chats/getChatById/${chatId}/${role}/${userId}`,
      }),
    }),
    getAdminChatMembers: builder.query({
      query: ({ adminId }) => ({
        url: `/schools/getSchoolsByAdminIds/${adminId}`,
      }),
    }),
    getAllTeachersOfStudent: builder.query({
      query: ({ studentId }) => ({
        url: `/teachers/getTeacherByStudentId/${studentId}`,
      }),
    }),
    getAllStudentsOfTeacher: builder.query({
      query: ({ teacherId }) => ({
        url: `/teachers/getStudentsByTeacherId/${teacherId}`,
      }),
    }),
    getParentMembers: builder.query({
      query: ({ parentId }) => ({
        url: `/relations/getStudentsByParentId/${parentId}`,
      }),
    }),
  }),
});

export default chats;

export const {
  useGetMyChatsQuery,
  useGetChatMessagesQuery,
  useGetChatDetailsQuery,
  useGetAdminChatMembersQuery,
  useGetAllTeachersOfStudentQuery,
  useGetAllStudentsOfTeacherQuery,
  useGetParentMembersQuery,
} = chats;
