import * as React from "react";
import { IoArrowUpCircleOutline } from "react-icons/io5";
import { MdArrowCircleDown } from "react-icons/md";
import "./TransationTable.css";
import ModalInvoice from "./InvoiceModal/ModalInvoice";
import "./TransationTable.css";
import axios from "axios";
import { baseURL } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;

export default function TransactionTable() {
  const data = [
    {
      type: "down",
      description: "Freepik Sales",
      transactionId: "#12548796",
      transactionType: "Transfer",
      card: "@1234 ****",
      date: "25 Jan, 10.40 PM",
      amount: "+$750",
    },

    {
      type: "up",
      description: "Freepik Sales",
      transactionId: "#12548796",
      transactionType: "Transfer",
      card: "@1234 ****",
      date: "25 Jan, 10.40 PM",
      amount: "-$750",
    },
    {
      type: "down",
      description: "Freepik Sales",
      transactionId: "#12548796",
      transactionType: "Transfer",
      card: "@1234 ****",
      date: "25 Jan, 10.40 PM",
      amount: "+$750",
    },
    {
      type: "up",
      description: "Freepik Sales",
      transactionId: "#12548796",
      transactionType: "Transfer",
      card: "@1234 ****",
      date: "25 Jan, 10.40 PM",
      amount: "-$750",
    },
    {
      type: "up",
      description: "Freepik Sales",
      transactionId: "#12548796",
      transactionType: "Transfer",
      card: "@1234 ****",
      date: "25 Jan, 10.40 PM",
      amount: "-$750",
    },
    {
      type: "up",
      description: "Freepik Sales",
      transactionId: "#12548796",
      transactionType: "Transfer",
      card: "@1234 ****",
      date: "25 Jan, 10.40 PM",
      amount: "-$750",
    },

    {
      type: "up",
      description: "Freepik Sales",
      transactionId: "#12548796",
      transactionType: "Transfer",
      card: "@1234 ****",
      date: "25 Jan, 10.40 PM",
      amount: "-$750",
    },
  ];

  const [recentTransactions, setRecentTransactions] = React.useState(null);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.getUserProfile);
  React.useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const getRecentTransactions = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/transactions/getRecentTransactions/${user.dataValues.id}/admin`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        setRecentTransactions(data?.transactions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getRecentTransactions();
  }, [user]);

  return (
    <>
      <h2 className="transationh2">Recent Transactions</h2>
      <div className="transation-table-main">
        <table className="tablee">
          <thead>
            <tr>
              <th className="transition-table-heads" scope="col">
                Description
              </th>
              <th className="transition-table-heads" scope="col">
                Transaction ID
              </th>
              <th className="transition-table-heads" scope="col">
                Type
              </th>
              <th className="transition-table-heads" scope="col">
                Card
              </th>
              <th className="transition-table-heads" scope="col">
                Date
              </th>
              <th className="transition-table-heads" scope="col">
                Amount
              </th>
              <th className="transition-table-heads" scope="col">
                Receipt
              </th>
            </tr>
          </thead>
          <tbody>
            {recentTransactions?.map((item, index) => (
              <tr key={index}>
                <th scope="row" className={`table-row-Transition-${item.type}`}>
                  {item.type === "up" ? (
                    <IoArrowUpCircleOutline className="IoArrowUpCircleOutline-transition-table" />
                  ) : (
                    <MdArrowCircleDown
                      className={`IoArrowUpCircleOutline-transition-table-${item.type}`}
                    />
                  )}
                  <ins className="ing-table-line">
                    <p>{item?.description}</p>
                  </ins>
                </th>
                <td>{item.transactionId}</td>
                <td>{item.transactionType}</td>
                <td>{item.type}</td>
                <td>{item.card}</td>

                <td>{item.date.split("T")[0]}</td>
                <td style={{ color: "green" }}>${item.amount}</td>
                <td className="transition-table-btn">
                  <ModalInvoice />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
