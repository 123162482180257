import React, { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import "./ADDProfile.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IoDocumentTextOutline } from "react-icons/io5";
import GeneralInformation from "./GeneralInformation";
import StudentEnrolmentInfo from "./studentEnrol/StudentEnrolmentInfo";
import Family from "./Family/Family";
import Transcripts from "../Transcripts/Transcripts";
import ChatChatBox from "../ADDChatBox/ADDChatBox";
import ActivityModal from "../ActivityModal/ActivityModal";
import CourseTab from "./CourseSchedule/CourseTab";
import ComposeMessageModal from "../MixComponents/ComposeMessageModal";
import Charttt from "../../Charttt/Charttt";
import AddressContact from "./Address&Contact/AddressContact";
import AddGreats from "./Addgreats/AddGreats";
import Order from "../ADDTabsPage/order/Order";
import CustomerDetailsFiltr from "../ADDTabsPage/customerdetailsfilters/CustomerDetailsFiltr";
import { IoCaretForwardOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Dummy from "../../images/dummy-user.png";
import axios from "axios";
import { baseURL } from "../../../../constants";
import { useParams } from "react-router-dom";
import AddPlan from "./Add504Plan/AddPlan";
import Medical from "../../Medical/Medical";
import MHistory from "../MedicalHistory/MHistory";
import Avatar from "../../PickAvator/Avator";
import Discipline from "../../Discipline/Discipline";
import IEProgram from "../../IEProgram/IeProgram";
import Graduation from "../../GraduationStatus/Graduation";
import { AiFillMedicineBox } from "react-icons/ai";
import { HiCalculator } from "react-icons/hi";
import Activities from "./ExtraActivities/Activities";
import Grades from "./StudentGrades/Grades";
import History from "./GradeHistory/History";
import ReportCard from "./ReportCard/ReportCard";
axios.defaults.baseURL = baseURL;

function ADDProfile() {
  const [attendanceSummery, setAttendanceSummery] = useState(null);

  const getAttendanceSummery = async () => {
    try {
      const { data } = await axios.get(
        "/AttendanceSummery/getAttendanceSummery",
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setAttendanceSummery(data);
    } catch (error) {
      console.log(error);
    }
  };

  const params = useParams();
  const [student, setStudent] = useState(null);
  const getStudent = async () => {
    try {
      const { data } = await axios.get(
        `/students/profile/${params.studentId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setStudent(data.student);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAttendanceSummery();
    getStudent();
  }, []);

  const [selectedFile, setSelectedFile] = useState(null);

  const [showEditPicture, setShowEditPicture] = useState(false);

  useEffect(() => {
    if (window.location.search?.split("=")[1] == "true") {
      setShowEditPicture(true);
    } else {
      setShowEditPicture(false);
    }
  }, [window.location.search]);

  const [lgShow, setLgShow] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  return (
    <div className="ADD-MAIn">
      <div className="myassingment-TOP" style={{ padding: "0px 0px 10px 0px" }}>
        <Link style={{ color: "#00afef" }} to="/CardViewMain/student">
          Student List
        </Link>

        <IoCaretForwardOutline />
        <Link to={`/StudentProfile/${params.studentId}`}>
          Student Information
        </Link>
      </div>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <div className="studentaddinfo-main">
            <div className="studentaddinfo-main-child-1">
              <Col sm={12} className="Width30">
                <div className="ADDProfile-Main">
                  {!showEditPicture && student ? (
                    <div className="StaffProfile-Child-1">
                      <img
                        src={`${baseURL}/images/${student?.profilePicture}`}
                        onError={({ currentTarget }) => {
                          currentTarget.src = Dummy;
                        }}
                        alt="Staff"
                      />
                      <h2>
                        {student?.firstName} {student?.middleName}{" "}
                        {student?.lastName}
                      </h2>
                      <div className="TWoModals">
                        <div className="TWoModals-one">
                          <ComposeMessageModal />
                        </div>
                        <div className="TWoModals-one">
                          <ActivityModal />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="EditStaff-COl3">
                      <div className="EditStaff-Child-1">
                        <div className="fiupload-img">
                          <div className="upLoaDing">
                            {selectedFile ? (
                              <img
                                src={selectedFile}
                                alt="Staff"
                                style={{
                                  width: "100%",
                                  height: "171px",
                                  cursor: "pointer",
                                  borderRadius: "50%",
                                  objectFit: "contain",
                                }}
                                onError={({ currentTarget }) => {
                                  currentTarget.src = Dummy;
                                }}
                                onClick={() => setLgShow(true)}
                              />
                            ) : (
                              <>
                                <img
                                  src={`${baseURL}/images/${student?.profilePicture}`}
                                  alt="Staff"
                                  style={{
                                    width: "100%",
                                    cursor: "pointer",
                                    height: "100%",
                                    overflow: "hidden",
                                    borderRadius: "100%",
                                    objectFit: "contain",
                                  }}
                                  onError={({ currentTarget }) => {
                                    currentTarget.src = Dummy;
                                  }}
                                  onClick={() => setLgShow(true)}
                                />
                              </>
                            )}
                            <Avatar
                              profilePicture={profilePicture}
                              setProfilePicture={setProfilePicture}
                              setSelectedFile={setSelectedFile}
                              lgShow={lgShow}
                              setLgShow={setLgShow}
                            />
                          </div>
                        </div>
                        {student && <h2>{student?.firstName}</h2>}
                      </div>
                    </div>
                  )}
                </div>
                <Nav variant="pills" className="flex-column">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <IoDocumentTextOutline /> Student Details
                    </AccordionSummary>
                    <AccordionDetails>
                      <Nav.Item>
                        <Nav.Link eventKey="first">General Info</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Enrolment Info </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Address & Contact </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fourth">Family Info </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fifth">504 plan </Nav.Link>
                      </Nav.Item>
                    </AccordionDetails>
                  </Accordion>
                  <div className="ADDMainTabs">
                    <Nav.Item>
                      <Nav.Link eventKey="five">
                        <IoDocumentTextOutline /> Course Schedule{" "}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="six">
                        <IoDocumentTextOutline />
                        Attendance{" "}
                      </Nav.Link>
                    </Nav.Item>
                  </div>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <IoDocumentTextOutline /> Grades
                    </AccordionSummary>
                    <AccordionDetails>
                      <Nav.Item>
                        <Nav.Link eventKey="GradeOverview">
                          Grade Overview
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="GradeHistory">
                          Grade History
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="GraduationStatus">
                          Graduation Status
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="eight">Report Card</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="seven">Transcript</Nav.Link>
                      </Nav.Item>
                    </AccordionDetails>
                  </Accordion>
                  <Nav.Item className="ADDCommunicationTab">
                    <Nav.Link eventKey="nine">
                      <IoDocumentTextOutline />
                      Communication{" "}
                    </Nav.Link>
                  </Nav.Item>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <AiFillMedicineBox /> Medical
                    </AccordionSummary>
                    <AccordionDetails>
                      <Nav.Item>
                        <Nav.Link eventKey="Medical">Medical log</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="MHistory">Medical History</Nav.Link>
                      </Nav.Item>
                    </AccordionDetails>
                  </Accordion>
                  <Nav.Item className="ADDCommunicationTab">
                    <Nav.Link eventKey="Discipline">
                      <IoDocumentTextOutline />
                      Discipline
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="ADDCommunicationTab">
                    <Nav.Link eventKey="IEProgram">
                      <HiCalculator />
                      IE Program
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="ADDCommunicationTab">
                    <Nav.Link eventKey="Extracurricularactivities">
                      <HiCalculator />
                      Extracurricular Activities
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </div>
            <div className="studentaddinfo-main-child-2">
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    {student && (
                      <GeneralInformation
                        getStudent={getStudent}
                        student={student}
                        selectedFile={profilePicture}
                        herthrtjhrtyityuo
                        showEditPicture={showEditPicture}
                        setShowEditPicture={setShowEditPicture}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <StudentEnrolmentInfo />
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <AddressContact />
                  </Tab.Pane>
                  <Tab.Pane eventKey="fourth">
                    <Family />
                  </Tab.Pane>
                  <Tab.Pane eventKey="fifth">
                    <AddPlan />
                  </Tab.Pane>
                  <Tab.Pane eventKey="five">
                    <CourseTab />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="six"
                    style={{ background: "transparent" }}
                  >
                    <Charttt />
                    <CustomerDetailsFiltr />
                    {attendanceSummery && <Order apiData={attendanceSummery} />}
                  </Tab.Pane>
                  <Tab.Pane eventKey="seven">
                    <Transcripts />
                  </Tab.Pane>
                  <Tab.Pane eventKey="eight">
                    <ReportCard />
                  </Tab.Pane>
                  <Tab.Pane eventKey="nine">
                    <ChatChatBox />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Medical">
                    <Medical />
                  </Tab.Pane>
                  <Tab.Pane eventKey="MHistory">
                    <MHistory />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Discipline">
                    <Discipline />
                  </Tab.Pane>
                  <Tab.Pane eventKey="IEProgram">
                    <IEProgram />
                  </Tab.Pane>
                  <Tab.Pane eventKey="GraduationStatus">
                    <Graduation />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Extracurricularactivities">
                    <Activities />
                  </Tab.Pane>
                  <Tab.Pane eventKey="GradeOverview">
                    <Grades />
                  </Tab.Pane>
                  <Tab.Pane eventKey="GradeHistory">
                    <History />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </div>
          </div>
        </Row>
      </Tab.Container>
    </div>
  );
}

export default ADDProfile;
