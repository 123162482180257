import { useState, React } from "react";
import "./ActivityModal.css";
import Modal from "react-bootstrap/Modal";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import LocalPoliceOutlinedIcon from "@mui/icons-material/LocalPoliceOutlined";
import { IoIosPeople } from "react-icons/io";
import toast from "react-hot-toast";
import axios from "axios";

const ActivityModal = () => {
  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [tagValue, setTagValue] = useState("");
  const [tags, setTags] = useState([]);

  const deleteTag = (id) => {
    const newArr = tags.filter((item, index) => {
      return index !== id;
    });
    setTags(newArr);
  };

  const [activityType, setActivityType] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [notes, setNotes] = useState("");

  const handleAddActivity = async () => {
    try {
      const { data } = await axios.post(
        "/activities/add",
        {
          activityType,
          date,
          time,
          notes,
          participants: tags,
        },
        {
          headers: {
            "Content-Type": "application/json",
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Activity added successfully!");
      setActivityType("");
      setDate("");
      setTime("");
      setNotes("");
      setTags([]);
      setSmShow(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  window.addEventListener("keydown", (e) => {
    if (e.key === "Enter") {
      setTags([...tags, tagValue]);
      setTagValue("");
    }
  });

  return (
    <div className="Activity_main">
      <button
        onClick={() => setLgShow(true)}
        style={{ border: "none", backgroundColor: "transparent" }}
      >
        <LocalPoliceOutlinedIcon />
      </button>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton className="ADD-ACTIVITY_cOntain">
          <p>Add Activity</p>
        </Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">
          <div className="Activity-modal-main">
            <div className="Activity-modal-main-child-1">
              <div
                style={{
                  backgroundColor:
                    activityType === "announcement" ? "lightblue" : "white",
                  cursor: "pointer",
                  borderRadius: "15px",
                }}
                onClick={() => setActivityType("announcement")}
                className="Activity-modal-main-child-2"
              >
                <IoDocumentTextOutline />
                <p> Announcement</p>
              </div>
              <div
                style={{
                  backgroundColor:
                    activityType === "phoneCall" ? "lightblue" : "white",
                  borderRadius: "15px",
                  cursor: "pointer",
                }}
                onClick={() => setActivityType("phoneCall")}
                className="Activity-modal-main-child-2"
              >
                <FaPhoneAlt className="Activity-FaPhoneAlt" />
                <p>Phone Call</p>
              </div>
              <div
                style={{
                  backgroundColor:
                    activityType === "meeting" ? "lightblue" : "white",
                  borderRadius: "15px",
                  cursor: "pointer",
                }}
                onClick={() => setActivityType("meeting")}
                className="Activity-modal-main-child-2"
              >
                <IoIosPeople />
                <p>Meeting</p>
              </div>
            </div>
            <hr />
            <div className="Activity-child-3">
              <div style={{ width: "50%" }}>
                <label htmlFor="Date">Date</label>
                <input
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  type="date"
                  id="date"
                />
              </div>
              <div style={{ width: "50%" }}>
                <label htmlFor="Date">Time</label>
                <input
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  type="time"
                  id="text"
                />
              </div>
            </div>
            <div className="Activity-child-4">
              <textarea
                name="text"
                id="text"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Notes*"
              ></textarea>
            </div>
            <div className="Activity-child-5">
              <h5>Participants*</h5>
              <div className="Activity-child-6">
                {tags.map((name, index) => {
                  return (
                    <div className="ALIP">
                      <p>{name}</p>
                      <IoMdClose onClick={() => deleteTag(index)} />
                    </div>
                  );
                })}
                <div>
                  <input
                    type="text"
                    value={tagValue}
                    onChange={(e) => setTagValue(e.target.value)}
                    id="text"
                    placeholder="Search Participants"
                  />
                </div>
              </div>
            </div>
            <div className="Activity-btnn">
              <button onClick={() => setLgShow(false)}>Cancel</button>
              <button onClick={handleAddActivity}>Submit</button>
            </div>
          </div>
        </Modal.Title>
      </Modal>
    </div>
  );
};

export default ActivityModal;
