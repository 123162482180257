import React, { useEffect, useState } from "react";
import "./ADDMessage.css";
import axios from "axios";
import { baseURL } from "../../../../constants";
axios.defaults.baseURL = baseURL;

const ADDMessages = () => {
  const [messages, setMessages] = useState(null);

  const getAllMessages = async () => {
    try {
      const { data } = await axios.get("/teacherMessage/getTeacherMessage", {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setMessages(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllMessages();
  }, [])

  return (
    <div className="ADDMessages-main">
      <div className="ADDMessages-main-title">
        <h2>New Messages Request</h2>
      </div>
      <div>
        {messages?.map((msg, index) => {
          return (
            <div
              key={index}
              style={{ display: "flex", justifyContent: "space-between" }}
              className="ADDMessages-main-child"
            > 
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <div className="Reqist-card-child-1">
                  <img src={msg.image} alt="user pic" />
                  <div className="ADDMessages-main-dot"></div>
                </div>
                <div>
                  <div className="Reqist-card">
                    <div className="Reqist-card-child-2">
                      <h4>{msg.name}</h4>
                      <p>{msg.message}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Reqist-card-child-3">
                <p>{msg.time}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="ADDMessages-main-button">
        <button>See All</button>
      </div>
    </div>
  );
};
export default ADDMessages;
