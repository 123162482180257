import React from "react";

const TuitionInfoCom = ({ data }) => {
  return (
    <>
      <div className="TranscriptCom">
        <table>
          <thead>
            <tr>
              <th>Invoice</th>
              <th>Date</th>
              <th>Description</th>
              <th>Initial Amount</th>
              <th>Paid</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((dt) => {
                return (
                  <tr>
                    <td>{dt?.invoice}</td>
                    <td>{dt?.date?.split("T")[0]}</td>
                    <td>{dt?.description}</td>
                    <td>{dt?.initialAmount}</td>
                    <td>{dt?.paid}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <div className="Printbtns">
        <button>Print</button>
      </div>
    </>
  );
};

export default TuitionInfoCom;
