import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./Avator.css";
import one from "../images/Layer_1-212.png";
import two from "../images/Layer_1-2.png";
import three from "../images/Layer_1-2 (1).png";
import four from "../images/Layer_1-2 (2).png";
import five from "../images/Layer_1-2 (3).png";
import six from "../images/Layer_1-2 (4).png";
import { LuUploadCloud } from "react-icons/lu";
import seven from "../images/Layer_1-2 (5).png";
import eight from "../images/Layer_1-2 (6).png";
import nine from "../images/Layer_1-2 (7).png";
import ten from "../images/Layer_1-2 (8).png";
import eleven from "../images/Layer_1-2 (9).png";
import twelve from "../images/Layer_1-2 (10).png";
import thirteen from "../images/Layer_1-2 (11).png";
import fourteen from "../images/Layer_1-2 (12).png";
import fifteen from "../images/Layer_1-2 (13).png";
import sixteen from "../images/Layer_1-2 (14).png";
import seventeen from "../images/Layer_1-2 (15).png";
import eighteen from "../images/Layer_1-2 (16).png";
import nineteen from "../images/Layer_1-2 (17).png";
import twenty from "../images/Layer_1-2 (18).png";
import twentyone from "../images/Layer_1-2 (19).png";
import twentytwo from "../images/Layer_1-2 (20).png";
import twentythree from "../images/Layer_1-2 (21).png";
import twentyfour from "../images/Layer_1-2 (22).png";
import twentyfive from "../images/Layer_1-2 (23).png";
import twentysix from "../images/Layer_1-2 (24).png";
import twentyseven from "../images/Layer_1-2 (25).png";
import twentyeight from "../images/Layer_1-2 (26).png";
import twentynine from "../images/Layer_1-2 (27).png";
import thirty from "../images/Layer_1-2 (28).png";
import thirtyone from "../images/Layer_1-2 (29).png";
import thirtytwo from "../images/Layer_1-2 (30).png";
import thirtythree from "../images/Layer_1-2 (31).png";
import thirtyfour from "../images/Layer_1-2 (32).png";
import toast from "react-hot-toast";
import axios from "axios";
import { baseURL } from "../../../constants";
axios.defaults.baseURL = baseURL;
// import fiupload from "../../../images/fi_upload-cloud.png";

function Avator({
  lgShow,
  setLgShow,
  profilePicture,
  setProfilePicture,
  setSelectedFile: setSlFile,
}) {
  const [isActive, setIsActive] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    setIsActive(true);
    setSelectedFile(e.target.files[0]);
    setSelectedAvatar(e.target.files[0]);
  };

  console.log(selectedAvatar, "selected images");

  const handleImageClick = (avatar) => {
    setSelectedAvatar(avatar);
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const [avatars, setAvatars] = useState([
    one,
    two,
    three,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    ten,
    eleven,
    twelve,
    thirteen,
    fourteen,
    fifteen,
    sixteen,
    seventeen,
    eighteen,
    nineteen,
    twenty,
    twentyone,
    twentytwo,
    twentythree,
    twentyfour,
    twentyfive,
    twentysix,
    twentyseven,
    twentyeight,
    twentynine,
    thirty,
    thirtyone,
    thirtytwo,
    thirtythree,
    thirtyfour,
  ]);

  const handleUploadImage = async () => {
    try {
      const form = new FormData();

      if (selectedAvatar && !isActive) {
        const res = await fetch(selectedAvatar);
        const data = await res.blob();
        // form.append("profilePicture", data);
        setProfilePicture(data);
        setSlFile(selectedAvatar);
        // await axios.put(
        //   `/students/updateStudentsProfile/${localStorage.getItem("token")}`,
        //   form,
        //   {
        //     headers: {
        //       "Content-Type": "multipart/form-data",
        //     },
        //   }
        // );
        // toast.success("Profile Picture Added Successfully");

        setLgShow(false);
        setIsActive(false);
      } else if (isActive) {
        // form.append("profilePicture", selectedAvatar);

        const reader = new FileReader();

        reader.onloadend = () => {
          setSlFile(reader.result);
        };

        reader.readAsDataURL(selectedAvatar);

        setProfilePicture(selectedAvatar);

        // await axios.put(
        //   `/students/updateStudentsProfile/${localStorage.getItem("token")}`,
        //   form,
        //   {
        //     headers: {
        //       "Content-Type": "multipart/form-data",
        //     },
        //   }
        // );
        // toast.success("Profile Picture Added Successfully");
        setLgShow(false);

        setIsActive(false);
      }
    } catch (error) {
      console.log(error);
      setIsActive(false);
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details?.[0]?.detail ||
            error?.response?.data?.details?.[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (selectedAvatar) {
      handleUploadImage();
    }
  }, [selectedAvatar]);


  return (
    <div className="Avator-Main">
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="AvatorModal"
      >
        <h2 id="example-modal-sizes-title-lg">Pick an Avator</h2>
        <hr />

        <Modal.Body>
          <div className="ChildMain">
            <div className="ChildAvator">
              <div className="ChildONEE">
                {selectedFile ? (
                  <img
                    src={URL.createObjectURL(selectedFile)}
                    alt="Staff"
                    style={{
                      width: "100%",
                      height: "171px",
                      cursor: "pointer",
                      borderRadius: "50%",
                    }}
                    onClick={handleUploadClick}
                  />
                ) : (
                  <>
                    <LuUploadCloud size={20} onClick={handleUploadClick} />
                    <label
                      htmlFor="upload"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={handleUploadClick}
                    >
                      Upload
                    </label>
                  </>
                )}

                <input
                  type="file"
                  id="upload"
                  name="profilePicture"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>
              {avatars.slice(0, 6).map((src, index) => (
                <div
                  key={index}
                  className="avatar-image-container"
                  onClick={() => handleImageClick(src)}
                  style={{ position: "relative" }}
                >
                  <img src={src} alt={`Avatar ${index + 1}`} />
                  {selectedAvatar === src && (
                    <div className="green-tick">✔</div>
                  )}
                </div>
              ))}
            </div>
            <div className="ChildAvator">
              {avatars.slice(6, 13).map((src, index) => (
                <div
                  key={index}
                  className="avatar-image-container"
                  onClick={() => handleImageClick(src)}
                  style={{ position: "relative" }}
                >
                  <img src={src} alt={`Avatar ${index + 1}`} />
                  {selectedAvatar === src && (
                    <div className="green-tick">✔</div>
                  )}
                </div>
              ))}
            </div>
            <div className="ChildAvator">
              {avatars.slice(13, 20).map((src, index) => (
                <div
                  key={index}
                  className="avatar-image-container"
                  onClick={() => handleImageClick(src)}
                  style={{ position: "relative" }}
                >
                  <img src={src} alt={`Avatar ${index + 1}`} />
                  {selectedAvatar === src && (
                    <div className="green-tick">✔</div>
                  )}
                </div>
              ))}
            </div>
            <div className="ChildAvator">
              {avatars.slice(20, 27).map((src, index) => (
                <div
                  key={index}
                  className="avatar-image-container"
                  onClick={() => handleImageClick(src)}
                  style={{ position: "relative" }}
                >
                  <img src={src} alt={`Avatar ${index + 1}`} />
                  {selectedAvatar === src && (
                    <div className="green-tick">✔</div>
                  )}
                </div>
              ))}
            </div>
            <div className="ChildAvator">
              {avatars.slice(27, 34).map((src, index) => (
                <div
                  key={index}
                  className="avatar-image-container"
                  onClick={() => handleImageClick(src)}
                  style={{ position: "relative" }}
                >
                  <img src={src} alt={`Avatar ${index + 1}`} />
                  {selectedAvatar === src && (
                    <div className="green-tick">✔</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Avator;
