import React, { useState } from "react";
import "./AdminOModal.css";
import Modal from "react-bootstrap/Modal";
import book from "./book.svg";
import school from "./bxs_school.svg";
import data from "./data-plus.svg";
import teachericon from "./game-icons_teacher.svg";
import volune from "./icon-park-solid_volume-notice.svg";
import event from "./material-symbols_event.svg";
import studentss from "./ph_student-bold.svg";
import staff from "./staff.svg";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";

const AdminOModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="AdminOModal">
      <>
        <button className="AdminOModal-Btn" onClick={handleShow}>
          <FiPlus className="fiicon-modal" />
        </button>
        <Modal show={show} onHide={handleClose} className="adminModal">
          <div className="AdminOModal-main">
            <div className="AdminOModal-child1">
              <Link to="/SchoolInformation">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={school} alt="" />
                </div>
                <p>School</p>
              </Link>
              <Link to="/CardViewMain/student">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={studentss} alt="" />
                </div>

                <p>Student</p>
              </Link>
              <Link to="/StaffView">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={teachericon} alt="" />
                </div>
                <p>Staff</p>
              </Link>
            </div>
            <div className="AdminOModal-child2">
              <Link to="/DashboardCalender">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={event} alt="" />
                </div>
                <p>Event</p>
              </Link>
              <Link to="/SchoolNotice">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={volune} alt="" />
                </div>
                <p>Notice</p>
              </Link>
              <Link to="/AddCourse">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={book} alt="" />
                </div>
                <p>Courses</p>
              </Link>
            </div>
            <div className="AdminOModal-child3">
              <div className="AdminOModal-child4"></div>
              <div className="AdminOModal-child5">Data Import</div>
              <div className="AdminOModal-child6"></div>
            </div>
            <div className="AdminOModal-child7">
              <Link to="/ImportData?role=student">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={data} alt="" />
                </div>

                <p>Student Bulk Data Import</p>
              </Link>
              <Link to="/ImportData?role=teacher">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={staff} alt="" />
                </div>

                <p>Staff Bulk Data Import</p>
              </Link>
            </div>
          </div>
        </Modal>
      </>
    </div>
  );
};

export default AdminOModal;
