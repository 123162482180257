import React from "react";
import GPACalculator from "../GPAcalculaters/GPAcalculator";

function CalculatorPage() {
  return (
    <div style={{ padding: "20px 0px" }}>
      <GPACalculator />
    </div>
  );
}

export default CalculatorPage;
