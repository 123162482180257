import React from "react";
import "./ChatRequest.css";
import { IoArrowBackSharp, IoMail } from "react-icons/io5";
import { FiSearch } from "react-icons/fi";
import Profile from "../../images/boy.png";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";

const ChatRequest = () => {
  return (
    <div className="ChatRequests_MAin">
      <div className="ChatSecOne">
        <div className="ChatBackTop">
          <IoArrowBackSharp />

          <h3>Message Request </h3>
        </div>
        <div className="ChatSecondBtns">
          <div className="search">
            <FiSearch />
            <input type="search" placeholder="Search" />
          </div>
          <div className="ChatCategories">
            <button>All Request</button>
            <button>Student Requests</button>
            <button>Teacher Requests</button>
          </div>
        </div>
        <div className="ChatReqTable">
          <table>
            <thead>
              <tr>
                <th>
                  <input type="checkbox" />
                </th>
                <th>Request Users</th>
                <th>Request to Chat</th>
                <th>Request Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input type="checkbox" />
                </td>
                <td>
                  <div className="ChatTAbleProfile">
                    <img src={Profile} alt="" />
                    <div className="ChatName">
                      <h3>John Deo</h3>
                      <p>Grade 2</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="ChatTAbleProfile">
                    <img src={Profile} alt="" />
                    <div className="ChatName1">
                      <h3>Goode</h3>
                      <p>Teacher</p>
                    </div>
                  </div>
                </td>
                <td>12/10/2023</td>
                <td>
                  <div className="ChatTableBtns">
                    <button className="ChatTableBtnOne">Accept</button>
                    <button className="ChatTableBtnTwo">Reject</button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" />
                </td>
                <td>
                  <div className="ChatTAbleProfile">
                    <img src={Profile} alt="" />
                    <div className="ChatName">
                      <h3>John Deo</h3>
                      <p>Grade 2</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="ChatTAbleProfile">
                    <img src={Profile} alt="" />
                    <div className="ChatName1">
                      <h3>Goode</h3>
                      <p>Teacher</p>
                    </div>
                  </div>
                </td>
                <td>12/10/2023</td>
                <td>
                  <div className="ChatTableBtns">
                    <button className="ChatTableBtnOne">Accept</button>
                    <button className="ChatTableBtnTwo">Reject</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="ChatSecTwo">
        <div className="ProfileCHat">
          <img src={Profile} alt="" />
          <h3>John Deo</h3>
          <p>Grade 2</p>
        </div>
        <div className="ChatContact">
          <h2>Contact Info</h2>
          <div className="ChatMAil">
            <IoMail />
            <p>kajope5182@ummoh.com</p>
          </div>
          <hr />
          <div className="ChatMAil">
            <FaPhoneAlt />
            <p>33757005467</p>
          </div>
          <hr />
          <div className="ChatMAil">
            <FaLocationDot />
            <p>2239 Hog Camp Road Schaumburg</p>
          </div>
        </div>
        <div className="RequestToo">
          <h1>Request to</h1>
          <div className="ReqToo">
            <img src={Profile} alt="" />
            <div>
              <h3>Goode</h3>
              <p>Teacher</p>
            </div>
          </div>
        </div>
        <div className="ChatViewBtn">
          <button>View Chat</button>
        </div>
      </div>
    </div>
  );
};

export default ChatRequest;
