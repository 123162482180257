import React, { useEffect, useState } from "react";
import "./AddressContact.css";
import { useParams } from "react-router-dom";
import EditAC from "./EditAddressContact/EditAC";
import { Country, State } from "country-state-city";
import axios from "axios";
import { baseURL } from "../../../../../constants";
axios.defaults.baseURL = baseURL;
const AddressContact = () => {
  const [showEdit, setShowEdit] = useState(false);

  const params = useParams();

  const [student, setStudent] = useState(null);

  const getAddressAndContact = async () => {
    try {
      const { data } = await axios.get(
        `/students/getAddressAndContactInfo/${params?.studentId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );

      setStudent(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddressAndContact();
  }, []);

  return !showEdit && student ? (
    <div className="AddressContact.main">
      <div className="AddressContact-main-child">
        <div className="AddressContact-child-1">
          <p>Address and Contact</p>
          <button onClick={() => setShowEdit(true)}>Edit Information</button>
        </div>
        <div className="AddressContact-child-2">
          <p>Student Home Address</p>
          <div className="AddressContact-child-3">
            <div>
              <label htmlFor="Street-Address-1">Street-Address-1</label>
              <div>
                <input
                  readOnly
                  value={student?.homeAddress?.address1}
                  type="text"
                  id="text"
                  placeholder="-"
                />
              </div>
            </div>
            <div>
              <label htmlFor="Street Address 2">Street Address 2</label>
              <div>
                <input
                  readOnly
                  value={student?.homeAddress?.address2}
                  type="text"
                  id="text"
                  placeholder="-"
                />
              </div>
            </div>
            <div>
              <label htmlFor="Country">Country</label>
              <div>
                <input
                  readOnly
                  value={
                    Country?.getCountryByCode(student?.homeAddress?.country)
                      ?.name
                  }
                  type="text"
                  id="text"
                  placeholder="-"
                />
              </div>
            </div>
          </div>
          <div className="AddressContact-child-4">
            <div>
              <label htmlFor="State/provenance/Locality">
                State/provenance/Locality
              </label>

              <div>
                <input
                  readOnly
                  value={
                    State?.getStateByCode(
                      student?.homeAddress?.stateOrProvinceOrLocality
                    )?.name
                  }
                  type="text"
                  id="text"
                  placeholder="-"
                />
              </div>
            </div>
            <div>
              <label htmlFor="City">City</label>
              <div>
                <input
                  readOnly
                  value={student?.homeAddress?.city}
                  type="text"
                  id="text"
                  placeholder="-"
                />
              </div>
            </div>
            <div>
              <label htmlFor="Zip/Postal Code">Zip/Postal Code</label>
              <div>
                <input
                  readOnly
                  value={student?.homeAddress?.zipOrPostalCode}
                  type="text"
                  id="text"
                  placeholder="-"
                />
              </div>
            </div>
          </div>
          <div className="AddressContact-Student-Mailing">
            <p>Student Mailing Address</p>
            <div className="AddressContact-child-5">
              <div>
                <label htmlFor="Street Address 1">Street Address 1</label>
                <div>
                  <input
                    readOnly
                    value={student?.mailingAddress?.address1}
                    type="text"
                    id="text"
                    placeholder="-"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="Street Address 2">Street Address 2</label>
                <div>
                  <input
                    readOnly
                    value={student?.mailingAddress?.address2}
                    type="text"
                    id="text"
                    placeholder="-"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="Country">Country</label>
                <div>
                  <input
                    readOnly
                    value={
                      Country?.getCountryByCode(
                        student?.mailingAddress?.country
                      )?.name
                    }
                    type="text"
                    id="text"
                    placeholder="-"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="AddressContact-child-6">
              <div>
                <label htmlFor="State/provenance/Locality">
                  State/provenance/Locality
                </label>
                <div>
                  <input
                    readOnly
                    value={
                      State?.getStateByCode(
                        student?.mailingAddress?.stateOrProvinceOrLocality
                      )?.name
                    }
                    type="text"
                    id="text"
                    placeholder="-"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="City">City</label>
                <div>
                  <input
                    readOnly
                    value={student?.mailingAddress?.city}
                    type="text"
                    id="text"
                    placeholder="-"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="Zip/Postal Code">Zip/Postal Code</label>
                <div>
                  <input
                    readOnly
                    value={student?.mailingAddress?.zipOrPostalCode}
                    type="text"
                    id="text"
                    placeholder="-"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="AddressContact-Personal-Information">
            <p>Personal Contact Information</p>

            <div className="AddressContact-child-7">
              <div>
                <label htmlFor="Home Phone">Home Phone</label>
                <div>
                  <input
                    readOnly
                    value={student?.personalInfo?.homePhone}
                    type="text"
                    id="text"
                    placeholder="-"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="Mobile Phone">Mobile Phone</label>
                <div>
                  <input
                    readOnly
                    value={student?.personalInfo?.mobilePhone}
                    type="text"
                    id="text"
                    placeholder="-"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="Personal Email">Personal Email</label>
                <div>
                  <input
                    readOnly
                    value={student?.personalInfo?.personalEmail}
                    type="text"
                    id="text"
                    placeholder="-"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="AddressContact-child-8">
            <div>
              <label htmlFor="Emergency Contact Number">
                Emergency Contact Number
              </label>
              <div>
                <input
                  readOnly
                  value={student?.personalInfo?.emergencyPhoneNumber}
                  type="text"
                  id="text"
                  placeholder="-"
                />
              </div>
            </div>
            <div>
              <label htmlFor="Father Number">Father Number</label>
              <div>
                <input
                  readOnly
                  value={student?.personalInfo?.fatherNumber}
                  type="text"
                  id="text"
                  placeholder="-"
                />
              </div>
            </div>
            <div>
              <label htmlFor="Brother Number">Brother Number</label>
              <div>
                <input
                  readOnly
                  value={student?.personalInfo?.brotherNumber}
                  type="text"
                  id="text"
                  placeholder="-"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <EditAC
      getAddressAndContact={getAddressAndContact}
      setShowEdit={setShowEdit}
      student={student}
    />
  );
};

export default AddressContact;
