import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import { IoMdHelpCircle, IoMdSettings } from "react-icons/io";
import { BiLogOut } from "react-icons/bi";
import "./TeacherLogoutModal.css";
import Dummy from "../../AdminComponents/images/dummy-user.png";
import { BsPersonFill } from "react-icons/bs";
import axios from "axios";
import { baseURL } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;

const TeacherLogoutModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("refreshToken");
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("token");
    setShow(false);
    dispatch(getUserProfile());
    navigate("/");
    window.location.reload();
  };
  const [teacher, setTeacher] = useState(null);
  const { user } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const getTeacher = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/teachers/getProfile/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        setTeacher(data?.profile);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTeacher();
  }, [user]);
  return (
    <>
      <div className="LogoutModal">
        <div className="mainModal">
          <div className="Logo" onClick={handleShow}>
            <img
              style={{
                borderRadius: "100%",
                objectFit: "cover",
              }}
              src={`${baseURL}/images/${teacher?.profilePicture}`}
              onError={({ currentTarget }) => {
                currentTarget.src = Dummy;
              }}
              alt="#"
            />
          </div>
          <div className="modalContainer">
            <Modal
              show={show}
              onHide={handleClose}
              animation={false}
              className="custom-modal"
            >
              <Modal.Header closeButton>
                <Link to="" className="MyProfile_link TeacherMyProfile_link">
                  <Modal.Title className="title TeacherTitle">
                    <BsPersonFill /> My Profile
                  </Modal.Title>
                </Link>
              </Modal.Header>

              <Modal.Body className="detlas">
                <ul>
                  <Link
                    to="/ProfieViews"
                    className="AccountSettig TeacherAccountSettig"
                  >
                    <IoMdSettings />
                    Profile View
                  </Link>

                  <Link to="#" className="AccountSettig TeacherAccountSettig">
                    <IoMdHelpCircle />
                    Help & Support
                  </Link>

                  <li onClick={handleLogout}>
                    <BiLogOut /> Log Out
                  </li>
                </ul>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherLogoutModal;
