import React, { useState } from "react";
import { ImRadioChecked } from "react-icons/im";
import Modal from "react-bootstrap/Modal";
import "./Myassingments.css";
import "../CreateAssignmentModal/CreateModal.css";
import { useNavigate, useParams } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modall from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { BsThreeDotsVertical } from "react-icons/bs";
import toast from "react-hot-toast";
import axios from "axios";
import { baseURL } from "../../../../../../constants";
axios.defaults.baseURL = baseURL;
const MyassingmentMain = ({
  data = [],
  func,
  isTestEdit = false,
  status,
  setLgShow: closeIt = () => {},
  isAssignment = false,
}) => {
  const [deleteId, setDeleteId] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setDeleteId("");
  };

  const assignmentsData = [
    {
      type: "OverDue today",
      typecolor: "#F93333",
      description: "Description",
      descriptionColor: "#F93333",
      bgColor: "#F93333",
      buttonBgColor: "#f93333",
      buttonTextColor: "#F93333",
      button: "Submit Assignment",
      time: "13th Dec 2023",
      spandotColor: "#F93333",
      date: "13",
      bcColor: "#F933331C",
      threeDot: "#F93333",
    },
    {
      radioColor: "#F93333",
      descriptionColor: "#16D03B",
      bgColor: "#16D03B",
      buttonBgColor: "#16D03B",
      buttonTextColor: "#16D03B",
      button: "Already submitted",
      time: "13th Dec 2023",
      spandotColor: "#16D03B",
      date: "13",
      bcColor: "#F0FFF3",
      mainbordercolor: "#16D03B",
      threeDot: "#11A529",
    },
    {
      type: "OverDue today",
      typecolor: "#FF7E3E",
      descriptionColor: "#FF7E3E",
      bgColor: "#FF7E3E",
      buttonBgColor: "#FF7E3E",
      buttonTextColor: "#16D03B",
      button: "Submit assignment",
      time: "13th Dec 2023",
      spandotColor: "#FF7E3E",
      date: "13",
      bcColor: "#FFF5F0",
      mainbordercolor: "#FF7E3E",
      threeDot: "#FF7E3E",
    },
    {
      type: "New assigments",
      typecolor: "#00AFEF",
      radioColor: "#00AFEF",
      descriptionColor: "#00AFEF",
      bgColor: "#00AFEF",
      buttonBgColor: "#00AFEF",
      buttonTextColor: "#00AFEF",
      button: "Already submitted",
      time: "13th Dec 2023",
      spandotColor: "#00AFEF",
      date: "13",
      bcColor: "#00AFEF26",
      mainbordercolor: "#00AFEF",
      threeDot: "#00AFEF",
    },
  ];

  const [lgShow, setLgShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const toggleSwitch = () => {
    setIsChecked(!isChecked);
  };

  const [folderName, setFolderName] = useState("No file chosen");
  const [imageName, setImageName] = useState("No file chosen");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [file, setFile] = useState(null);

  const handleFileChange = (event, fileType) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Handle the selected file, you can upload it or extract information
      const fileName = selectedFile.name;

      setFile(selectedFile);
      if (fileType === "folder") {
        setFolderName(fileName);
      } else if (fileType === "image") {
        setImageName(fileName);
      }
    } else {
      if (fileType === "folder") {
        setFolderName("No file chosen");
      } else if (fileType === "image") {
        setImageName("No file chosen");
      }
    }
  };

  const [editId, setEditId] = useState("");

  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [points, setPoints] = useState("");
  const [grading, setGrading] = useState("");
  const [assignOn, setAssignOn] = useState("");
  const [dueOn, setDueOn] = useState("");
  const [maxMarks, setMaxMarks] = useState();
  const params = useParams();
  const handleCreateAssignment = async () => {
    try {
      const newForm = new FormData();

      newForm.append("file", file);
      newForm.append("subjectId", params?.subjectId);

      !isTestEdit && newForm.append("name", name);
      isTestEdit && newForm.append("testName", name);
      newForm.append("category", category);
      newForm.append("title", title);
      newForm.append("description", description);
      newForm.append("points", points);
      newForm.append("grading", grading);
      newForm.append("assignedOn", assignOn);
      newForm.append("dueOn", dueOn);
      isTestEdit && newForm.append("maxMarks", maxMarks);

      if (!isTestEdit) {
        await axios.put(`/assignments/updateAssignment/${editId}`, newForm, {
          headers: {
            "Content-Type": "multipart/form-data",
            accessToken: `${localStorage.getItem("token")}`,
          },
        });
        toast.success("Assignment Updated");
      } else {
        await axios.put(`/Tests/${editId}`, newForm, {
          headers: {
            "Content-Type": "multipart/form-data",
            accessToken: `${localStorage.getItem("token")}`,
          },
        });
        toast.success("Test Updated");
      }

      func();

      setLgShow(false);
      setEditId("");
      setName("");
      setCategory("");
      setTitle("");
      setDescription("");
      setPoints("");
      setGrading("");
      setAssignOn("");
      setDueOn("");
      setMaxMarks("");
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(error?.response?.data?.details[0]?.detail);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const handleDeleteAssignment = async () => {
    try {
      if (status === "assignments") {
        await axios.delete(`/assignments/removeAssignment/${deleteId}`, {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        });
        toast.success("Assignment Removed");
        func();
      } else if (status === "tests") {
        await axios.delete(`/Tests/removeTest/${deleteId}`, {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        });
        toast.success("Test Removed");
        func();
      }

      func();
      handleClose();
      setLgShow(false);
      closeIt();
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const navigate = useNavigate();
  return (
    <div className="myassingment-main34">
      <Modall
        className="Allowmodalmain"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="CommentModal-Mmain-child1">
              <h4>Delete Assignment </h4>
              <p>
                Are you sure you want to delete the Assignment ? This will
                remove the Assignment and can’t be undone.
              </p>
              <div className="CommentModal-Mmain-child2">
                <button
                  onClick={() => handleClose()}
                  className="
                  CommentModal-Mmain-child2-allow"
                >
                  CANCEL
                </button>
                <button
                  onClick={handleDeleteAssignment}
                  className="
                  CommentModal-Mmain-child2-Dont-allow"
                >
                  DELETE
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modall>
      <div className="myassingment-child-1">
        <div className="myassingment-child-2-first">
          <div className="myassingment-radio-btn">
            <span></span>
            <p>OverDue</p>
            <ImRadioChecked
              style={{ color: "#F93333", width: "19px", height: "19px" }}
            />
          </div>
          <div className="myassingment-radio-btn">
            <span></span>
            <p>Due Soon </p>
            <ImRadioChecked
              style={{ color: "#FF7E3E", width: "19px", height: "19px" }}
            />
          </div>
          <div className="myassingment-radio-btn">
            <span></span>
            <p>Submitted</p>
            <ImRadioChecked
              style={{ color: "#16D03B", width: "19px", height: "19px" }}
            />
          </div>
          <div className="myassingment-radio-btn">
            <span></span>
            <p>New</p>
            <ImRadioChecked
              style={{ color: "#00AFEF", width: "19px", height: "19px" }}
            />
          </div>
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        {data?.map((assignment, index) => {
          const newIndex = index % assignmentsData.length;

          return (
            <div
              onClick={() => {
                status === "tests"
                  ? navigate(`/StudentAssingment/${assignment.id}?test=true`)
                  : isAssignment
                  ? navigate(`/StudentAssingment/${assignment.id}`)
                  : console.log("noting");
              }}
              className="myassingments-child-2m"
              key={index}
              style={{ marginTop: "10px", textDecoration: "none" }}
              to="/StudentAssingment"
            >
              <div
                className="myassingments-cardd"
                style={{
                  backgroundColor: assignmentsData[newIndex]?.bcColor,
                  borderColor: assignmentsData[newIndex]?.mainbordercolor,
                }}
              >
                <div className="myassingments-card-childs-1m">
                  <div className="myassingmentss-childss">
                    <div className="myassingments-childss-Data">
                      <div
                        className="myassingments-childs-dateE"
                        style={{
                          backgroundColor: assignmentsData[newIndex]?.bgColor,
                        }}
                      >
                        <p>{assignment?.id}</p>
                      </div>
                      <div className="Mychildss-myassingmentss">
                        <h6>{assignment?.title || assignment?.testName}</h6>
                        <div className="myassingments-tiMMe">
                          <div className="Myassingments-motherM">
                            <p>
                              {assignment?.assignedOn?.split("T")[0]}{" "}
                              <span
                                style={{
                                  color:
                                    assignmentsData[newIndex]?.spandotColor,
                                }}
                              >
                                ●
                              </span>
                              {assignment?.dueOn?.split("T")[0]}{" "}
                            </p>
                          </div>
                          <p style={{ fontSize: "8px" }}></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="myassingments-card-childs-2m">
                  <div className="descriptions-s">
                    <h5
                      style={{
                        color: assignmentsData[newIndex]?.descriptionColor,
                      }}
                    >
                      Description
                    </h5>
                    <p>{assignment?.description}</p>
                  </div>
                  <div className="mychilds-myassingments-dov">
                    {assignment?.status === "closed" ? (
                      <button
                        style={{
                          color: assignmentsData[newIndex]?.bgColor,
                          borderColor: assignmentsData[newIndex]?.buttonBgColor,
                        }}
                      >
                        {assignment.status}
                      </button>
                    ) : (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setLgShow(true);
                          setEditId(assignment?.id);
                        }}
                        style={{
                          color: assignmentsData[newIndex]?.bgColor,
                          borderColor: assignmentsData[newIndex]?.buttonBgColor,
                        }}
                      >
                        Edit
                      </button>
                    )}
                    <div onClick={(e) => e.stopPropagation()}>
                      <div className="ThreeDots_MAin">
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic">
                            <BsThreeDotsVertical />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <div className="Teacherattendance-main-child-2-4-buttons">
                              <Dropdown href="#/action-1">
                                <button
                                  className="
                            Teacherattendance-main-child-2-4-buttons-2"
                                >
                                  Due <input type="checkbox"></input>
                                </button>
                              </Dropdown>
                              <Dropdown href="#/action-2">
                                <button className="Teacherattendance-main-child-2-4-buttons-1">
                                  Submitted <input type="checkbox"></input>
                                </button>
                              </Dropdown>
                              <Dropdown href="#/action-3">
                                <button className="Teacherattendance-main-child-2-4-buttons-3">
                                  Overdue<input type="checkbox"></input>
                                </button>
                              </Dropdown>
                              <Dropdown href="#/action-3">
                                <button className="Teacherattendance-main-child-2-4-buttons-4">
                                  New<input type="checkbox"></input>
                                </button>
                              </Dropdown>
                              <Dropdown
                                onClick={() => {
                                  setDeleteId(assignment?.id);
                                  handleOpen();
                                }}
                              >
                                <button className="Teacherattendance-main-child-2-4-buttons-5">
                                  Delete
                                </button>
                              </Dropdown>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {/* <Submited/> */}
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => {
          setLgShow(false);
          setEditId("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton className="CreateModal-header">
          {/* <h3 className="CreateModal-h3">Edit Test</h3> */}
        </Modal.Header>
        <Modal.Body>
          <div className="CreateModal-body">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
            <input
              type="text"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              placeholder="Category"
            />
            <label>Assignment Details</label>
            <input
              placeholder="Title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Description"
            />
            {/* .....///////////...........///////////...... */}
            <div className="CreateModal-child">
              <div className="CreateModal-child-input">
                <label>Points</label>
                <input
                  value={points}
                  onChange={(e) => setPoints(e.target.value)}
                  type="number"
                  placeholder="Points"
                />
              </div>

              <div className="CreateModal-child-select">
                <label>Grading</label>
                <input
                  value={grading}
                  onChange={(e) => setGrading(e.target.value)}
                  type="text"
                />
              </div>
            </div>
            <div className="CreateModal-child">
              <div className="CreateModal-child-select">
                <label>Assigned on</label>
                <input
                  value={assignOn}
                  onChange={(e) => setAssignOn(e.target.value)}
                  type="date"
                />
              </div>
              <div className="CreateModal-child-select">
                <label>Due On</label>
                <input
                  value={dueOn}
                  onChange={(e) => setDueOn(e.target.value)}
                  type="date"
                />
              </div>
            </div>
            {/* .....///////////...........///////////...... */}
            <div className="CreateModal-Last">
              <div>
                {/* <div className="CreateModal-Last1">
                  <label>
                    <span>
                      <img src={Vactor} alt="Folder Icon" />
                    </span>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileChange(e, "folder")}
                    />
                  </label>
                  <h6>{folderName}</h6>
                </div>
                <div className="CreateModal-Last1" style={{ marginTop: "8px" }}>
                  <label>
                    <span>
                      <TfiExport />
                    </span>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileChange(e, "image")}
                    />
                  </label>
                  <h6>{imageName}</h6>
                </div> */}
                {isTestEdit && (
                  <div className="CreateModal-child-input">
                    <label>Max Marks</label>
                    <input
                      value={maxMarks}
                      onChange={(e) => setMaxMarks(e.target.value)}
                      type="number"
                      placeholder="Points"
                    />
                  </div>
                )}
              </div>
              <div className="CreateModal-Child2">
                <div className="CreateModal-Child21">
                  <p>
                    Create noticeboard announcement for all students in
                    classroom
                  </p>
                  <label
                    className={`custom-switch ${isChecked ? "checked" : ""}`}
                  >
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={toggleSwitch}
                    />
                    <span className="slider"></span>
                  </label>
                </div>
                <div className="CreateModal-Child22">
                  <button onClick={handleCreateAssignment}>
                    Edit Assigment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MyassingmentMain;
