import React from "react";
import "./StudentHeader.css";
import { CiSearch } from "react-icons/ci";
import { RiEqualizerFill } from "react-icons/ri";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { FaPlus } from "react-icons/fa6";
import ExcelIcon from "../../../images/vscode-icons_file-type-excel2.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import FilterModal from "../../../Filter/FilterModal";
import ByDate from "../../../ByDate/ByDate";
import { exportToCSV } from "../../../../../utils/features";

const StudentHeader = ({
  showTable,
  setShowTable,
  searchValue,
  setSearchValue,
  allParents,
  filterFunction = () => {},
}) => {
  const params = useParams();

  return (
    <div className="student-header">
      <h2 className="student-list-title">Guardian List</h2>
      <div className="student-content">
        <div className="left-content">
          <div id="searching">
            <CiSearch onClick={filterFunction} className="search-icon" />
            <input
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              placeholder="Search"
            />
          </div>
          {/* <div className="select-date">
            <input
              style={{ color: "#858d9d" }}
              className="search-input date-input"
              type="date"
              placeholder="Select Date"
            />
          </div> */}
          <ByDate />
          {/* <div className="filterrr">
            <RiEqualizerFill color="gray" className="filter-icon" />
            <p>Filters</p>
          </div> */}
          <FilterModal />
        </div>

        <div className="exlimation-icon-div">
          <AiOutlineExclamationCircle size={25} color="#00afef" />
          <div className="right-buttons">
            <FaPlus size={20} style={{ color: "white" }} />

            <Link to="/AddNEwParent">
              <p className="add-new-p">Add new</p>
            </Link>
          </div>

          <div className="export-data-div">
            <img
              src={ExcelIcon}
              style={{ width: "20px", height: "20px", objectFit: "contain" }}
            />
            <div
              onClick={() => exportToCSV(allParents)}
              style={{ textDecoration: "none" }}
            >
              <p style={{ color: "#148348" }}>EXPORT DATA</p>
            </div>
          </div>
          <Link
            to="/ImportData?role=parent"
            style={{ textDecoration: "none" }}
            className="import-data-div"
          >
            <FaPlus color="white" size={20} />
            <p style={{ color: "white" }}>Import Data</p>
          </Link>
          <BsThreeDotsVertical size={20} color="#00afef" />
        </div>
      </div>
    </div>
  );
};

export default StudentHeader;
