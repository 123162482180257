import React, { useState, useRef, useEffect } from "react";
import "./Editstafprofile.css";
import fiupload from "../../../images/fi_upload-cloud.png";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { baseURL } from "../../../../../constants";
import { useParams } from "react-router-dom";
axios.defaults.baseURL = baseURL;

const EditStafProfile = () => {
  const params = useParams();

  const [staffData, setStaffData] = useState(null);
  const getStaffSchool = async () => {
    try {
      const { data } = await axios.get(
        `/teachers/getTeacherSchoolInfoAndOfficialInfo/${params.staffId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setStaffData(data.teacherWithInfo);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStaffSchool();
  }, []);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="EditStaff-main">
      {/* <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row> */}
      <Col sm={3} className="EditStaff-Col3-main">
        <div className="EditStaff-COl3">
          <div className="EditStaff-Child-1">
            <div className="fiupload-img">
              <div className="upLoaDing">
                {selectedFile ? (
                  <img
                    src={URL.createObjectURL(selectedFile)}
                    alt="Staff"
                    style={{
                      width: "100%",
                      height: "171px",
                      cursor: "pointer",
                      borderRadius: "50%",
                    }}
                    onClick={handleImageClick}
                  />
                ) : (
                  <>
                    <img
                      src={fiupload}
                      alt="Staff"
                      style={{
                        width: "20px",
                        cursor: "pointer",
                        height: "20px",
                      }}
                      onClick={handleImageClick}
                    />
                    <label
                      htmlFor="upload"
                      style={{ cursor: "pointer", marginTop: "8px" }}
                    >
                      school logo
                    </label>
                  </>
                )}
                <input
                  type="file"
                  id="upload"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>
            </div>
            {staffData && <h2>{staffData.staffName}</h2>}
            <button>Teacher</button>
          </div>
        </div>
      </Col>
    </div>
  );
};

export default EditStafProfile;
