import React, { useEffect, useState } from "react";
import "./StaffInfoSchool.css";
import { FaSchool } from "react-icons/fa";
import EditStaffSchoolInfo from "../EditSchoolInfo/EditStaffSchoolInfo";
import axios from "axios";
import { baseURL } from "../../../../../constants";
import { useParams } from "react-router-dom";
axios.defaults.baseURL = baseURL;

const StaffInfoSchool = () => {
  const [staffData, setStaffData] = useState(null);
  const [teacherId, setTeacherId] = useState(1);
  const params = useParams();
  const getStaffSchool = async () => {
    try {
      const { data } = await axios.get(
        `/teachers/getTeacherSchoolInfoAndOfficialInfo/${params.staffId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setStaffData(data?.teacherWithInfo);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStaffSchool();
  }, []);

  const [showEdit, setShowEdit] = useState(false);
  return !showEdit && staffData ? (
    <div className="StaffInfoSchool-main">
      <div className="StaffInfoSchool-child-1">
        <div className="schoolinfo-head">
          <h3>School Information</h3>
          <h4>Official Information</h4>
        </div>
        <button onClick={() => setShowEdit(true)}>Edit Information</button>
      </div>
      <div className="StaffInfoSchool-row-1">
        <div>
          <label>Job title</label> <br />
          <input
            readOnly
            value={
              staffData?.OfficialInfos
                ? staffData?.OfficialInfos[0]?.jobTitle
                : ""
            }
            placeholder="-"
          />
        </div>
        <div>
          <label>Joining Date</label> <br />
          <input readOnly value={staffData.staffId} placeholder="-" />
        </div>
        <div>
          <label>Ending Date</label> <br />
          <input readOnly value={staffData?.alternateId} placeholder="-" />
        </div>
        <div>
          <label>Primary Grade Level Taught</label> <br />
          <input readOnly value={staffData?.alternateId} placeholder="-" />
        </div>
      </div>
      <div className="StaffInfoSchool-row-1">
        <div>
          <label>Other Grade Level Taught</label> <br />
          <input readOnly value={staffData?.stateId} placeholder="-" />
        </div>
        <div>
          <label>Primary Subject Taught</label> <br />
          <input readOnly value={staffData?.districtId} placeholder="-" />
        </div>
        <div>
          <label>Other Subject Taught</label> <br />
          <input readOnly value={staffData?.govIssuedNumber} placeholder="-" />
        </div>
        <div>
          <label>Other Govt Issue Number</label> <br />
          <input readOnly value={staffData?.govIssuedNumber} placeholder="-" />
        </div>
      </div>
      {/* div2 */}
      <div className="StaffInfoSchool-2">
        <p>School Information</p>
        <div className="StaffInfoSchool-table">
          <table>
            <thead>
              <tr>
                <th>School</th>
                <th>Profile</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {staffData?.OfficialInfos &&
                staffData?.OfficialInfos.map((hey) => {
                  return (
                    <tr>
                      <td>
                        <FaSchool />
                      </td>
                      <td>{hey?.jobTitle}</td>
                      <td>{hey?.joiningDate?.split("T")[0]}</td>
                      <td>{hey?.endDate?.split("T")[0]}</td>
                      <td style={{ color: "#018749" }}>Active</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ) : (
    <EditStaffSchoolInfo
      getStaffSchool={getStaffSchool}
      setShowEdit={setShowEdit}
      staffData={staffData}
    />
  );
};

export default StaffInfoSchool;
