import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { FaQuestion } from "react-icons/fa6";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import "./Feedback.css";
import Done from "../../StudentComponents/images/Group.png";
import { useEffect } from "react";
import { useSelector } from "react-redux";
// import toast from 'react-hot-toast'
import axios from "axios";
import { baseURL } from "../../../constants";
axios.defaults.baseURL = baseURL;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Feedback() {
  const [show, setShow] = useState(true);
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRatingChange = (event, newRating) => {
    console.log(newRating, "this is rating");
    setRating(newRating);
  };

  const { user } = useSelector((state) => state.getUserProfile);

  const [loading, setLoading] = useState(false);

  const [school, setSchool] = useState(null);

  const getSchool = async () => {
    try {
      const { data } = await axios.get(
        `/ParentBySchoolId/getschoolByTeacherId/${user?.dataValues?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            accessToken: localStorage.getItem("token"),
          },
        }
      );
      setSchool(data.school);
      console.log(data, "I'm school");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user) {
      getSchool();
    }
  }, [user]);

  const handleFeedback = async () => {
    try {
      setLoading(true);
      await axios.post(
        `/feedbacks/addfeedback`,
        {
          userId: user?.dataValues?.id,
          role: user?.role,
          rating,
          schoolId: school.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            accessToken: localStorage.getItem("token"),
          },
        }
      );
      setLoading(false);
      setShow(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="Feedback-MAin">
      <button className="Feedback-Btn" onClick={handleOpen}>
        <FaQuestion />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {show ? (
            <div>
              <h2 className="Feedback-MAinh2">General Feedback</h2>
              <Typography id="modal-modal-description">
                <p className="Feedback-MAinP">
                  Please rate your experience below{" "}
                </p>
                <span className="Feedback-MAinSP">{rating}/10 stars</span>
                <div className="Feedback-Stars">
                  <Stack spacing={1}>
                    <Rating
                      name="half-rating"
                      value={rating}
                      onChange={handleRatingChange}
                      precision={0.5}
                      max={10}
                    />
                  </Stack>
                </div>
                <p className="Feedback-MAinP2">
                  We are unable to respond to support requests made through this
                  form. Please reach out to our
                  <span>
                    <ins> support team</ins>
                  </span>
                  if you need assistance.
                </p>
                <button className="Feedback_btn" onClick={handleFeedback}>
                  {loading ? "Submitting..." : "Submit feedback"}
                </button>
              </Typography>
            </div>
          ) : (
            <div className="Feedback-Done">
              <img src={Done} alt="#" />
              <h3>We got your feedback!</h3>
              <p>And we really appreciate it, thank you!</p>
              <button
                onClick={() => {
                  setShow(true);
                  setOpen(false);
                }}
              >
                Done
              </button>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default Feedback;
