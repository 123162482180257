import React from "react";
import "./ReportCard.css";
import print from "../../../../StudentComponents/Medical/flat-color-icons_print.png";

function ReportCard() {
  function handlePrint() {
    window.print();
  }
  return (
    <div className="ReportCard-mainAdmin">
      <div className="ReportCard-Top">
        <h3>Report Card History</h3>
        <button onClick={handlePrint}>
          <img src={print} alt="#" />
          Print Report Card
        </button>
      </div>
      <div className="ReportTABLEEE">
        <table>
          <thead>
            <tr>
              <th>School</th>
              <th>Year</th>
              <th>Team</th>
              <th>Grade</th>
              <th>Type</th>
              <th>Report Title</th>
              <th>Report Card</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Screaming Eagle High School</td>
              <td>2020/2021</td>
              <td>Ist Sem</td>
              <td>12</td>
              <td>Grade</td>
              <td>Semester Report Card</td>
              <td className="ReportBlue">English</td>
              <td>10/03/2023</td>
            </tr>
            <tr>
              <td>Screaming Eagle High School</td>
              <td>2020/2021</td>
              <td>Ist Sem</td>
              <td>12</td>
              <td>Grade</td>
              <td>Semester Report Card</td>
              <td className="ReportBlue">English</td>
              <td>10/03/2023</td>
            </tr>
            <tr>
              <td>Screaming Eagle High School</td>
              <td>2020/2021</td>
              <td>Ist Sem</td>
              <td>12</td>
              <td>Grade</td>
              <td>Semester Report Card</td>
              <td className="ReportBlue">English</td>
              <td>10/03/2023</td>
            </tr>
            <tr>
              <td>Screaming Eagle High School</td>
              <td>2020/2021</td>
              <td>Ist Sem</td>
              <td>12</td>
              <td>Grade</td>
              <td>Semester Report Card</td>
              <td className="ReportBlue">English</td>
              <td>10/03/2023</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ReportCard;
