import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./Charttt.css";

const Charttt = ({ weekly = {}, monthly = {}, yearly = {} }) => {
  const [dataType, setDataType] = useState("weekly");

  const handleDataTypeChange = (event) => {
    setDataType(event.target.value);
  };

  const getSeriesData = () => {
    switch (dataType) {
      case "weekly":
        return [
          { x: "Sun", y: weekly?.sunday || 2 },
          { x: "Mon", y: weekly?.monday || 53 },
          { x: "Tue", y: weekly?.tuesday || 3 },
          { x: "Wed", y: weekly?.wednesday || 6 },
          { x: "Thu", y: weekly?.thursday || 34 },
          { x: "Fri", y: weekly?.friday || 5 },
          { x: "Sat", y: weekly?.saturday || 21 },
        ];

      case "monthly":
        return [
          { x: "Jan", y: monthly?.Jan || 54 },
          { x: "Feb", y: monthly?.Feb || 3 },
          { x: "Mar", y: monthly?.Mar || 52 },
          { x: "Apr", y: monthly?.Apr || 4 },
          { x: "May", y: monthly?.May || 12 },
          { x: "Jun", y: monthly?.Jun || 23 },
          { x: "Jul", y: monthly?.Jul || 75 },
          { x: "Aug", y: monthly?.Aug || 23 },
          { x: "Sep", y: monthly?.Sep || 35 },
          { x: "Oct", y: monthly?.Oct || 21 },
          { x: "Nov", y: monthly?.Nov || 35 },
          { x: "Dec", y: monthly?.Dec || 3 },
        ];
      case "yearly":
        return [
          { x: "2021", y: yearly?.Year1 || 34 },
          { x: "2022", y: yearly?.Year2 || 3 },
          { x: "2023", y: yearly?.Year3 || 23 },
        ];
      default:
        return [];
    }
  };
  const chartData = {
    series: [{ name: "Attendence", data: getSeriesData() }],
    options: {
      chart: { type: "bar", height: 380 },
      xaxis: { type: "category" },
      yaxis: {
        min: 0,
        max: 100,
        labels: { formatter: (val) => `${val}%` },
      },
      title: {
        text: `School Attendance overview`,
      },
      tooltip: { y: { formatter: (val) => `${val}%` } },
    },
  };

  return (
    <div className="ChartttMain">
      <div style={{ textAlign: "right", marginBottom: "10px" }}>
        <select id="dataType" value={dataType} onChange={handleDataTypeChange}>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </select>
      </div>
      <div id="chart">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={400}
        />
      </div>
    </div>
  );
};
export default Charttt;
