import React from "react";
import "./Notes.css";
import { PiDotsThreeCircle } from "react-icons/pi";
import profile from "../Images/entypo_drive.png";

const Notes = () => {
  return (
    <div className="NotesMain">
      <div className="Special-Top">
        <h3>Sticky Notes</h3>
        <button>+ New Note</button>
      </div>
      <div className="NotesBody">
        <div className="NotesCard">
          <div className="NotesTop">
            <h3>Subject: Great Job in Class!</h3>
            <PiDotsThreeCircle />
          </div>
          <p>
            Hi Ali,
            <br />
            <span>
              It's hard to believe that June is already over! Looking back on
              the month, there were a few highlights that stand out to me. One
              of the best things that happened was getting promoted at work.
              I've been working really hard and it's great to see that effort
              recognized.
            </span>
          </p>
          <h6>
            Best, <br />
            Sheryar Khan
          </h6>
          <div className="NotesImg">
            <div className="NotesImgFlex">
              <img src={profile} />
              <p>Sheryar</p>
            </div>
            <p>2024-08-03</p>
          </div>
        </div>
        <div className="NotesCard">
          <div className="NotesTop">
            <h3>Subject: Great Job in Class!</h3>
            <PiDotsThreeCircle />
          </div>
          <p>
            Hi Ali,
            <br />
            <span>
              It's hard to believe that June is already over! Looking back on
              the month, there were a few highlights that stand out to me. One
              of the best things that happened was getting promoted at work.
              I've been working really hard and it's great to see that effort
              recognized.
            </span>
          </p>
          <h6>
            Best, <br />
            Sheryar Khan
          </h6>
          <div className="NotesImg">
            <div className="NotesImgFlex">
              <img src={profile} />
              <p>Sheryar</p>
            </div>
            <p>2024-08-03</p>
          </div>
        </div>
        <div className="NotesCard">
          <div className="NotesTop">
            <h3>Subject: Great Job in Class!</h3>
            <PiDotsThreeCircle />
          </div>
          <p>
            Hi Ali,
            <br />
            <span>
              It's hard to believe that June is already over! Looking back on
              the month, there were a few highlights that stand out to me. One
              of the best things that happened was getting promoted at work.
              I've been working really hard and it's great to see that effort
              recognized.
            </span>
          </p>
          <h6>
            Best, <br />
            Sheryar Khan
          </h6>
          <div className="NotesImg">
            <div className="NotesImgFlex">
              <img src={profile} />
              <p>Sheryar</p>
            </div>
            <p>2024-08-03</p>
          </div>
        </div>
        <div className="NotesCard">
          <div className="NotesTop">
            <h3>Subject: Great Job in Class!</h3>
            <PiDotsThreeCircle />
          </div>
          <p>
            Hi Ali,
            <br />
            <span>
              It's hard to believe that June is already over! Looking back on
              the month, there were a few highlights that stand out to me. One
              of the best things that happened was getting promoted at work.
              I've been working really hard and it's great to see that effort
              recognized.
            </span>
          </p>
          <h6>
            Best, <br />
            Sheryar Khan
          </h6>
          <div className="NotesImg">
            <div className="NotesImgFlex">
              <img src={profile} />
              <p>Sheryar</p>
            </div>
            <p>2024-08-03</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notes;
