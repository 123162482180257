
  export  const MessagesData = [
      {
        id: 1,
        name: 'Andreana Viola',
        message: 'Hi, How are you today?',
        time: '08:30',
      },
      {
        id: 2,
        name: 'Francesco Long',
        message: 'Hi, How are you today?',
        time: '08:30',
      },
      {
        id: 3,
        name: 'Alexandra Michu',
        message: 'Hi, How are you today?',
        time: '23/11',
      },
    ];

