import React from "react";
import "./FilterIcons.css";
import Search_icon from ".././images/Vector.png";
import filter_icons from ".././images/filter-icon.png";

const FilterIcons = () => {
  return (
    <div className="Filter-icons-main">
      <div className="Filter-Icons-childs-1">
        <img src={Search_icon} alt="" />
        <input type="text" id="text" placeholder="Search..." />
      </div>
      <div className="Filter-Icons-childs-2">
        <input type="date" />
      </div>
      <div className="Filter-icons-childs-3">
        <img src={filter_icons} alt="" />
        <p>Filters</p>
      </div>
    </div>
  );
};

export default FilterIcons;
