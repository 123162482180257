import React from "react";
import { FaCalendar } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";
import "./MyGrade.css";
import axios from "axios";
import "../data/MyscheduleData";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { baseURL } from "../../../constants";
axios.defaults.baseURL = baseURL;

const Mygrade = ({ gradeData = [], status = "all", noRedirect = false }) => {
  const gradeStyles = [
    {
      result: "failed",
      labelColor: "#f93333",
      buttonColor: "#f93333",
      progressbarColor: "#f93333",
      gradebc: "#ffefef",
    },
    {
      result: "missed",
      buttonColor: "#9A9A9A",
      labelColor: "#9A9A9A",
      progressbarColor: "#9A9A9A",
      gradebc: "#E6E6E6",
    },
    {
      result: "passed",
      buttonColor: "#11A529",
      labelColor: "#11A529",
      progressbarColor: "#11A529",
      gradebc: "#Effff5",
    },
  ];

  const mapGradeData = (originalData = [], gradeStyles) => {
    if (!Array.isArray(originalData)) {
      return [];
    }

    return originalData.map((item) => {
      const style =
        gradeStyles.find((gradeStyle) => gradeStyle.result === item.result) ||
        {};
      return { ...item, ...style };
    });
  };

  const params = useParams();

  const navigate = useNavigate();

  console.log(gradeData, "this is my ghrade data");
  return (
    <div className="MyGrade-main-Map">
      {gradeData?.length > 0 &&
        gradeData?.map((gradecard, index) => (
          <div
            className="mygrade-main"
            key={index}
            style={{
              marginTop: "1px",
              backgroundColor:
                gradecard?.result !== "pass" ? "#FFEFEF" : "#EFFFF5",
            }}
          >
            <span
              style={{
                color: gradecard?.result !== "pass" ? "#f93333" : "#11A529",
              }}
            >
              {gradecard?.result}
            </span>
            <div className="mygrade-child">
              <div className="mygrade-child-1">
                <h2>Grade Title: {gradecard?.title}</h2>
                <p>Subject: {gradecard?.Subject?.subjectName}</p>
              </div>
              <div className="mygrade-time">
                <div className="calander-icon">
                  <FaCalendar style={{ marginTop: "3px" }} />
                  <p>{gradecard?.date?.split("T")[0]}</p>
                </div>
                <div className="time-icon">
                  <FaRegClock style={{ marginTop: "4px" }} />
                  <p>{gradecard?.time}</p>
                </div>
              </div>
              <div className="mygrade-percentage">
                <p className="mygrade-percentage-test">
                  {gradecard?.gradeType || gradecard?.gradetype}
                </p>
                <div className="Passing-Percentage">
                  <h6>Scored Percentage</h6>
                  <p>{gradecard?.scoredPercentage}%</p>
                </div>
                <div className="Scored-Percentage">
                  <h6>Total Percentage</h6>
                  <p style={{ color: "#f93333" }}>100%</p>
                </div>
              </div>
              <div className="progress-bar-line">
                <progress
                  className="mygrade-process-line"
                  id={`file-${gradecard?.id}`}
                  value={gradecard?.scoredPercentage}
                  max="100"
                  style={{
                    "--progressbar-color":
                      gradeStyles[
                        gradecard?.result === "fail"
                          ? 0
                          : gradecard?.result === "miss"
                          ? 1
                          : 2
                      ]?.buttonColor,
                    "--progress": gradecard?.passingPercentage,
                  }}
                />
              </div>
              <div className="mygrade-btn">
                <button
                  style={{
                    backgroundColor:
                      gradecard?.result !== "pass" ? "#f93333" : "#11A529",
                  }}
                >
                  <div
                    className="MyGradeBtn"
                    onClick={() => navigate(`/MyAssingment/${gradecard?.id}`)}
                  >
                    View Details
                  </div>
                </button>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Mygrade;
