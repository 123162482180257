import React, { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoCaretForwardOutline } from "react-icons/io5";
import { IoAdd } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { IoIosSearch } from "react-icons/io";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modall from "@mui/material/Modal";
import "./MangeSemester.css";
import toast from "react-hot-toast";
import axios from "axios";
import { baseURL } from "../../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


function ManageSemester() {
  const [assignmentsData, setAssignmentsData] = useState([
    {
      descriptionColor: "#F93333",
      bgColor: "#F93333",
      buttonBgColor: "#f93333",
      buttonTextColor: "#F93333",
      button: "Active",
      date: "1",
      programCodeColor: "#F93333",
      programDurationColor: "#F93333",
      drageIconColor: "#F93333",
      EditIconClor: "#F93333",
      deleteIconColor: "#F93333",
    },
    {
      descriptionColor: "#16D03B",
      bgColor: "#16D03B",
      buttonBgColor: "#16D03B",
      buttonTextColor: "#16D03B",
      button: "Active",
      bcColor: "#F0FFF3",
      mainbordercolor: "#16D03B",
      date: "2",
      programCodeColor: "#16D03B",
      programDurationColor: "#16D03B",
      drageIconColor: "#16D03B",
      EditIconClor: "#16D03B",
      deleteIconColor: "#16D03B",
    },
  ]);

  const [editId, setEditId] = useState("");
  const [deleteId, setDeleteId] = useState("");

  const [semisterName, setSemisterName] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isActive, setIsActive] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setEditId("");
    setDeleteId("");
  };
  const handleShow = () => {
    setShow(true);
    setSemisterName("");
    setGradeLevel("");
    setStartDate("");
    setEndDate("");
    setIsActive("");
  };
  const [open, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClosee = () => {
    setIsOpen(false);
    setEditId("");
    setDeleteId("");
  };

  const params = useParams();
  const { schoolId } = useSelector((state) => state?.schoolId);

  const handleAddSemister = async () => {
    try {
      if (!editId) {
        await axios.post(
          `/semesters/addSemester`,
          {
            schoolId: Number(schoolId),
            programId: Number(sessionStorage.getItem("programId")),
            courseId: Number(sessionStorage.getItem("courseId")),
            semesterName: semisterName,
            gradeLevel,
            startDate,
            endDate,
            isActive,
          },
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Semester Created Successfully");
        setSemisterName("");
        setGradeLevel("");
        setStartDate("");
        setEndDate("");
        setIsActive("");
      } else {
        await axios.put(
          `/semesters/updateSemester/${editId}`,
          {
            schoolId: Number(schoolId),
            programId: Number(sessionStorage.getItem("programId")),
            courseId: Number(sessionStorage.getItem("courseId")),
            semesterName: semisterName,
            gradeLevel,
            startDate,
            endDate,
            isActive,
          },
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Semester Updated Successfully");
        setSemisterName("");
        setGradeLevel("");
        setStartDate("");
        setEndDate("");
        setIsActive("");
      }

      handleClose();
      handleClosee();
      getSemisters();
    } catch (error) {
      console.log(error);

      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.error ||
            error?.response?.data?.details[0]?.message
        );
      } else if (error?.response?.data?.error) {
        toast.error(
          error?.response?.data?.error[0]?.detail ||
            error?.response?.data?.error[0]?.message ||
            error?.response?.data?.error[0]?.msg
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const [semesters, setSemesters] = useState(null);

  const getSemisters = async () => {
    try {
      const { data } = await axios.get(
        `/semesters/semestersbyProgramId/${sessionStorage.getItem("courseId")}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setSemesters(data?.semesters);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSemisters();
  }, [schoolId]);

  useEffect(() => {
    if (editId && semesters) {
      handleShow();
      const semester = semesters.find((semester) => semester?.id === editId);
      setSemisterName(semester?.semesterName);
      setGradeLevel(semester?.gradeLevel);
      setStartDate(semester?.startDate);
      setEndDate(semester?.endDate);
      setIsActive(semester?.isActive);
    }
  }, [editId, semesters]);

  const handleDeleteSemester = async () => {
    try {
      await axios.delete(`/semesters/removeSemester/${deleteId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      toast.success("Semester Deleted Successfully");
      getSemisters();
      handleClose();
      handleClosee();
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.error ||
            error?.response?.data?.details[0]?.message
        );
      } else if (error?.response?.data?.error) {
        toast.error(
          error?.response?.data?.error[0]?.detail ||
            error?.response?.data?.error[0]?.message ||
            error?.response?.data?.error[0]?.msg
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  return (
    <div className="CourseScreen-main">
      <div className="myassingment-TOP4567">
        <h3>School </h3>
        <IoCaretForwardOutline />
        <Link to="/ManageAcademics" style={{ textDecoration: "none" }}>
          <h4 style={{ color: "#00afef" }}>Manage Academics</h4>
        </Link>
        <IoCaretForwardOutline />
        <Link to="/ManageAcademics" style={{ textDecoration: "none" }}>
          <h4 style={{ color: "#00afef" }}>Program</h4>
        </Link>
        <IoCaretForwardOutline />
        <Link to="/AddCourse" style={{ textDecoration: "none" }}>
          <h4 style={{ color: "#00afef" }}>Courses</h4>
        </Link>
        <IoCaretForwardOutline />
        <h4>Semester</h4>
      </div>
      <div className="myassingment-SeC">
        <div className="myassingment-SeC-input">
          <IoIosSearch />
          <input
            placeholder="Search for Program,  Courses, Semester"
            type="text"
          />
        </div>
        <div className="myassingment-SeC-button">
          <div className="CMmodal-main">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
              }}
            >
              <div
                variant="primary"
                onClick={handleShow}
                className="myassingment-SeC-button-btn"
              >
                <IoAdd />
                Add new Semester
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        centered
        className="CMmodal-main0modal"
        style={{ marginLeft: "100px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Semester</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="Accedmic-modal-main">
            <label htmlFor="ProgramName">Semester Name</label>
            <div className="Accedmic-modal-main-child1">
              <input
                value={semisterName}
                onChange={(e) => setSemisterName(e.target.value)}
                placeholder="Semister Name"
              />
            </div>
          </div>

          <div className="Accedmic-modal-main1">
            <label htmlFor="ProgramName">Grade Level</label>
            <div className="Accedmic-modal-main-child1">
              <Form.Select
                value={gradeLevel}
                onChange={(e) => setGradeLevel(e.target.value)}
                aria-label="Default select example"
              >
                <option value="">Select Grade Level</option>
                <option value="kindergarten">Kindergarten</option>
                <option value="Grade 1">Grade 1</option>
                <option value="Grade 2">Grade 2</option>
                <option value="Grade 3">Grade 3</option>
                <option value="Grade 4">Grade 4</option>
                <option value="Grade 5">Grade 5</option>
                <option value="Grade 6">Grade 6</option>
                <option value="Grade 7">Grade 7</option>
                <option value="Grade 8">Grade 8</option>
                <option value="Grade 9">Grade 9</option>
                <option value="Grade 10">Grade 10</option>
                <option value="Grade 11">Grade 11</option>
                <option value="Grade 12">Grade 12</option>
              </Form.Select>
            </div>
          </div>
          <div className="Accedmic-modal-main1">
            <label htmlFor="ProgramName">Start Date</label>
            <div className="Accedmic-modal-main-child1">
              <input
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                type="date"
                placeholder="Start Date"
              />
            </div>
          </div>
          <div className="Accedmic-modal-main1">
            <label htmlFor="ProgramName">End Date</label>
            <div className="Accedmic-modal-main-child1">
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                placeholder="End Date"
              />
            </div>
          </div>
          <div className="Accedmic-modal-main1">
            <label htmlFor="ProgramName">Status</label>
            <div className="Accedmic-modal-main-child1">
              <Form.Select
                value={isActive}
                onChange={(e) => setIsActive(e.target.value)}
                aria-label="Default select example"
              >
                <option value={""}>Is Active?</option>
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </Form.Select>
            </div>
          </div>
          <hr style={{ width: "100%" }} />
          <div className="Accedmic-modal-main-child3">
            <button>Close</button>
            <button onClick={handleAddSemister}>Save</button>
          </div>
        </Modal.Body>
      </Modal>

      <div className="combimetable">
        <div className="myassingment-SeC-Table">
          <table>
            <thead>
              <tr>
                <th>
                  <input type="checkbox" />
                  Semester Name
                </th>
                <th>Grade Level</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Action</th>
              </tr>
            </thead>
          </table>
        </div>

        <div className="CommentModal-Mmain">
          <Modall
            className="Allowmodalmain"
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClosee}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <div className="CommentModal-Mmain-child1">
                  <h4>Delete Semester</h4>
                  <p>
                    Are you sure you want to delete this Semester? This will
                    remove the Semester and can’t be undone.
                  </p>
                  <div className="CommentModal-Mmain-child2">
                    <button
                      className=" 
                  CommentModal-Mmain-child2-Dont-allow"
                      onClick={handleClosee}
                    >
                      CANCEL
                    </button>
                    <button
                      onClick={handleDeleteSemester}
                      className="
                    CommentModal-Mmain-child2-allow
                  "
                    >
                      DELETE
                    </button>
                  </div>
                </div>
              </Box>
            </Fade>
          </Modall>
        </div>

        <div style={{ marginTop: "10px" }}>
          {semesters &&
            semesters?.map((assignment, index) => (
              <div
                className="CourseScreen-child-2m"
                key={index}
                style={{ marginTop: "10px" }}
              >
                <div
                  className="CourseScreen-cardd21"
                  style={{
                    backgroundColor:
                      assignmentsData[assignment?.isActive === false ? 0 : 1]
                        ?.bcColor,
                    borderColor:
                      assignmentsData[assignment?.isActive === false ? 0 : 1]
                        ?.mainbordercolor,
                  }}
                >
                  <div className="CourseScreen-card-childs-1m">
                    <div className="CourseScreens-childss12">
                      <div className="CourseScreen-childss-Data1">
                        <div
                          className="CourseScreen-childs-dateAb"
                          style={{
                            backgroundColor:
                              assignmentsData[
                                assignment?.isActive === false ? 0 : 1
                              ]?.bgColor,
                          }}
                        >
                          <p className="coursescreenp">{assignment?.id}</p>
                        </div>
                        <div className="Mychildss-CourseScreens1">
                          <h6 className="coursescreenp">
                            {assignment?.semesterName}{" "}
                          </h6>
                        </div>
                      </div>
                      <div>
                        <h6
                          className="coursescreenp"
                          style={{
                            color:
                              assignmentsData[
                                assignment?.isActive === false ? 0 : 1
                              ]?.programCodeColor,
                          }}
                        >
                          {assignment?.gradeLevel}
                        </h6>
                      </div>
                    </div>
                  </div>
                  {/* ///////////////////////////////// */}

                  <div className="CourseScreen-card-childs-3m">
                    <div className="descriptions-s112">
                      <p
                        className="coursescreenp"
                        style={{
                          color:
                            assignmentsData[
                              assignment?.isActive === false ? 0 : 1
                            ]?.programDurationColor,

                          fontWeight: "500",
                        }}
                      >
                        {assignment?.startDate?.split("T")[0]}
                      </p>
                    </div>
                    <div>
                      <p
                        className="coursescreenp"
                        style={{
                          color:
                            assignmentsData[
                              assignment?.isActive === false ? 0 : 1
                            ]?.programDurationColor,
                        }}
                      >
                        {assignment?.endDate?.split("T")[0]}
                      </p>
                    </div>
                    <div className="mychilds-CourseScreen-dovh">
                      <button
                        style={{
                          color:
                            assignmentsData[
                              assignment?.isActive === false ? 0 : 1
                            ]?.bgColor,
                          borderColor:
                            assignmentsData[
                              assignment?.isActive === false ? 0 : 1
                            ]?.buttonBgColor,
                        }}
                      >
                        {assignment?.isActive === true
                          ? "Active"
                          : "Not Active"}
                      </button>
                      <div className="mychilds-CourseScreen-icoNs">
                        <MdEdit
                          onClick={() => setEditId(assignment?.id)}
                          style={{
                            color:
                              assignmentsData[
                                assignment?.isActive === false ? 0 : 1
                              ]?.EditIconClor,
                          }}
                        />
                        <RiDeleteBin6Line
                          style={{
                            color:
                              assignmentsData[
                                assignment?.isActive === false ? 0 : 1
                              ]?.deleteIconColor,
                          }}
                          onClick={() => {
                            setDeleteId(assignment?.id);
                            handleOpen();
                          }} 
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* map */}
    </div>
  );
}

export default ManageSemester;
