import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { TbArrowsDiagonal } from "react-icons/tb";
import { IoAlertCircle } from "react-icons/io5";
import { Dropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./LineSchoolChart.css";
import { Switch } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../../../../constants";

axios.defaults.baseURL = baseURL;

const LineChart = ({ data1 = [], data2 = [] }) => {
  const chartContainer = useRef(null);
  const chartInstance = useRef(null);
  const [VendorCentrality, setVendorCentrality] = useState(
    "App Centrality Score"
  );

  const chartData = {
    AppCentralityScore: [35, 64, 34, 12, 53, 62, 1, 3],
    ViewFullDistrict: [4, 2, 2, 45, 34, 64, 34, 53],
    ViewSpecificSchool: [2, 67, 3, 45, 64, 2, 45, 6],
    ViewByGradeLevel: [7, 43, 2, 6, 34, 34, 2, 53],
  };

  const [myData, setMyData] = useState({});

  const [myNewData, setMyNewData] = useState([]);

  const { schoolId } = useSelector((state) => state.schoolId);

  const getData = async () => {
    try {
      const { data } = await axios.get(
        `/feedbacks/filterFeedback/${schoolId}`,
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );

      console.log(data, "this is fff");

      let myObj = [];

      setMyData(data);

      Object.keys(data).forEach((lm) => {
        myObj.push(data[lm]);
      });

      setMyNewData(myObj);
    } catch (error) {
      console.log(error);
    }
  };

  const [toggleButton, setToggleButton] = useState(false);
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    if (chartContainer && chartContainer.current) {
      const ctx = chartContainer.current.getContext("2d");

      chartInstance.current = new Chart(ctx, {
        type: "line",
        data: {
          labels: !toggleButton
            ? Object.keys(myData)
            : [
                "Dec 24",
                "Dec 25",
                "Dec 26",
                "Dec 27",
                "Dec 28",
                "Dec 29",
                "Dec 30",
                "Dec 31",
              ],
          datasets: [
            {
              label: !toggleButton ? "Feedback Score" : VendorCentrality,
              data: !toggleButton ? myNewData : chartData[VendorCentrality],
              fill: true,
              backgroundColor: "rgba(0, 175, 239, 0.1)",
              borderColor: "#00AFEF",
              opacity: ".2",
              borderWidth: 1,
              tension: 0.4,
            },
          ],
        },

        options: {
          scales: {
            x: {
              title: {
                display: true,
              },
              grid: {
                display: false,
              },
            },
            y: {
              title: {
                display: true,
              },
              beginAtZero: true,
              ticks: {
                callback: (value) => {
                  if (value === 0) return "";
                  if (value >= 1000) {
                    return value / 1000 + "k";
                  }
                  return value;
                },
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    }
  }, [VendorCentrality, myData, toggleButton]);

  const handleScoreChange = (eventKey) => {
    setVendorCentrality(eventKey);
  };

  return (
    <div className="AddminsLineChart1">
      <div className="AddDropdown">
        <div className="appCentrality">
          <h2>{toggleButton ? `App Efficiency` : `Feedback Score`} </h2>
          <span title="Something about this feature and what it does. ">
            <IoAlertCircle />
          </span>
          <span>
            <TbArrowsDiagonal />
          </span>

          <Switch
            value={toggleButton}
            onChange={() => setToggleButton(!toggleButton)}
            color="secondary"
          />
        </div>
        <Dropdown onSelect={handleScoreChange}>
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className="dropdown"
          >
            {VendorCentrality}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item eventKey="App Centrality Score">
              App Centrality Score
            </Dropdown.Item>
            <Dropdown.Item eventKey="View Full District">
              View Full District
            </Dropdown.Item>
            <Dropdown.Item eventKey="View Specific School">
              View Specific School
            </Dropdown.Item>
            <Dropdown.Item eventKey="View By Grade Level">
              View By Grade Level
            </Dropdown.Item>
            <Dropdown.Item eventKey="View Specific Classroom">
              View Specific Classroom
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <canvas ref={chartContainer} />
    </div>
  );
};

export default LineChart;
