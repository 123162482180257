import React, { useState, useEffect } from "react";
import "../../../StudentComponents/WelcomeBack/Welcome.css";
import Teacher from "../../Images/std.png";
import axios from "axios";
import { baseURL } from "../../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

function TeaherWelcomeBack() {
  const [teacherProfile, setTeacherProfile] = useState(null);

  const { user } = useSelector((state) => state.getUserProfile);

  const GetProfile = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/teachers/getProfile/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setTeacherProfile(data);
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    GetProfile();
  }, [user]);
  const [textTitle, setTextTitle] = useState("Noticeboard");

  const [text, setText] = useState("This is noticeboard");
  useEffect(() => {
    const texts = [
      { title: "Noticeboard", text: "This is noticeboard" },
      { title: "Deadline", text: "Don't forget the deadline" },
      { title: "Reminders", text: "Here are your reminders" },
    ];

    let index = 0;
    const interval = setInterval(() => {
      index = (index + 1) % texts.length;
      setTextTitle(texts[index].title);
      setText(texts[index].text);
    }, 3000);

    return () => clearInterval(interval);
  }, []);


  return (
    <div className="Welcome-main">
      <div className="Welcome-div1">
        <h1>Welcome back, {teacherProfile?.profile?.firstName}</h1>
        <p>
          <span style={{ fontWeight: "700", color: "#fff" }}>{textTitle}</span>{" "}
          {text}
        </p>
      </div>
      <div className="Welcome-div2">
        <img src={Teacher} alt="#" />
      </div>
    </div>
  );

}

export default TeaherWelcomeBack;
