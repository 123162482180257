import React, { useEffect, useState } from "react";
import "./EditStafAddress.css";
import toast from "react-hot-toast";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import PhoneInput from "react-phone-number-input";
import { FaPhoneAlt } from "react-icons/fa";
import { baseURL } from "../../../../../../constants";
import { isValidEmail } from "../../../../../../utils/regex.js";

axios.defaults.baseURL = baseURL;
const EditStafAddress = ({
  emergencyContact: emergencyContacts,
  teacherAddress: teacherAddresses,
  teacherContact: teacherContacts,
  setShowEdit,
  getStaffAddressInfo,
}) => {
  const [emergencyHomePhone, setEmergencyHomePhone] = useState(
    emergencyContacts?.homePhone
  );
  const [emergencyMobilePhone, setEmergencyMobilePhone] = useState(
    emergencyContacts?.mobilePhone
  );

  const [teacherHomePhone, setTeacherHomePhone] = useState(
    teacherContacts?.homePhone
  );
  const [teacherMobilePhone, setTeacherMobilePhone] = useState(
    teacherContacts?.mobilePhone
  );
  const [teacherOfficePhone, setTeacherOfficePhone] = useState(
    teacherContacts?.officePhone
  );

  useEffect(() => {
    if (!emergencyHomePhone?.startsWith("+1")) {
      setEmergencyHomePhone("+1");
    } else {
      setEmergencyHomePhone(emergencyHomePhone);
    }
  }, [emergencyHomePhone]);
  useEffect(() => {
    if (!emergencyMobilePhone?.startsWith("+1")) {
      setEmergencyMobilePhone("+1");
    } else {
      setEmergencyMobilePhone(emergencyMobilePhone);
    }
  }, [emergencyMobilePhone]);
  useEffect(() => {
    if (!teacherHomePhone?.startsWith("+1")) {
      setTeacherHomePhone("+1");
    } else {
      setTeacherHomePhone(teacherHomePhone);
    }
  }, [teacherHomePhone]);
  useEffect(() => {
    if (!teacherMobilePhone?.startsWith("+1")) {
      setTeacherMobilePhone("+1");
    } else {
      setTeacherMobilePhone(teacherMobilePhone);
    }
  }, [teacherMobilePhone]);

  useEffect(() => {
    if (!teacherOfficePhone?.startsWith("+1")) {
      setTeacherOfficePhone("+1");
    } else {
      setTeacherOfficePhone(teacherOfficePhone);
    }
  }, [teacherOfficePhone]);
  const [emergencyContact, setEmergencyContact] = useState({
    relationshipToStaff: emergencyContacts?.relationshipToStaff,
    mobilePhone: emergencyContacts?.mobilePhone,
    email: emergencyContacts?.email,
    emergencyContactName: emergencyContacts?.emergencyContactName,
  });
  const [teacherContact, setTeacherContact] = useState({
    personalEmail: teacherContacts?.personalEmail,
    schoolEmail: teacherContacts?.schoolEmail,
  });

  const [teacherAddress, setTeacherAddress] = useState({
    address1: teacherAddresses?.address1,
    address2: teacherAddresses?.address2,
    zipOrPostalCode: teacherAddresses?.zipOrPostalCode,
  });

  const emergencyContactChanges = (e) => {
    setEmergencyContact((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const teacherContactChanges = (e) => {
    setTeacherContact((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const teacherAddressChanges = (e) => {
    setTeacherAddress((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const params = useParams();

  const [selectedCountry, setSelectedCountry] = useState(
    teacherAddresses?.country || "US"
  );
  const [selectedRegion, setSelectedRegion] = useState(
    teacherAddresses?.stateProvinceOrLocality
  );
  const [selectedCity, setSelectedCity] = useState(teacherAddresses?.city);

  const updateTeacherHandler = async () => {
    try {
      if (teacherContact.personalEmail) {
        if (!isValidEmail(teacherContact.personalEmail)) return;
      }

      if (emergencyContact.email) {
        if (!isValidEmail(emergencyContact.email)) return;
      }
      const regex = /^(\+1\s?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
      if (
        !regex?.test(emergencyHomePhone) ||
        !regex?.test(emergencyMobilePhone) ||
        !regex?.test(teacherHomePhone) ||
        !regex?.test(teacherMobilePhone) ||
        !regex?.test(teacherOfficePhone)
      ) {
        return toast.error("All phone numbers must be valid");
      }
      const { data } = await axios.put(
        `/teachers/updateAddressInfo/${params.staffId}`,
        {
          emergencyContact: {
            ...emergencyContact,
            homePhone: emergencyHomePhone,
            mobilePhone: emergencyMobilePhone,
          },
          teacherContact: {
            ...teacherContact,
            homePhone: teacherHomePhone,
            mobilePhone: teacherMobilePhone,
            officePhone: teacherOfficePhone,
          },
          teacherAddress: {
            ...teacherAddress,
            country: selectedCountry,
            city: selectedCity,
            stateProvinceOrLocality: selectedRegion,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      getStaffAddressInfo();
      setShowEdit(false);
      toast.success("Staff Updated Successfully");
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error.response.data.message);
      }
    }
  };

  const handleCountryChange = (val) => {
    setSelectedCountry(val);
    setSelectedRegion("");
    setSelectedCity("");
  };

  const handleRegionChange = (val) => {
    setSelectedRegion(val);
    setSelectedCity("");
  };

  const handleCityChange = (val) => {
    setSelectedCity(val);
  };

  const countries = Country.getAllCountries();
  const regions = State.getStatesOfCountry(selectedCountry);
  const cities = City.getCitiesOfState(selectedCountry, selectedRegion);
  return (
    <div className="EditStafAddress-main">
      <div className="EditStafAddress-child-1">
        <h2>Address & Contact</h2>
        <p>Personal Contact Information</p>
        <div className="EditStafAddress-child-2">
          <div>
            <label>Teacher Home Phone</label> <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "7px",
              }}
            >
              <FaPhoneAlt />
              <PhoneInput
                placeholder="Enter Teacher home phone"
                value={teacherHomePhone}
                onChange={setTeacherHomePhone}
              />
            </div>
          </div>
          <div>
            <label>Teacher Mobile Phone</label> <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "7px",
              }}
            >
              <FaPhoneAlt />
              <PhoneInput
                placeholder="Enter teacher mobile phone"
                value={teacherMobilePhone}
                onChange={setTeacherMobilePhone}
              />
            </div>
          </div>
          <div>
            <label htmlFor="Home Phone">Personal Email</label>
            <div>
              <input
                name="personalEmail"
                value={teacherContact.personalEmail}
                onChange={teacherContactChanges}
                placeholder="-"
              />
            </div>
          </div>
        </div>
        <div className="EditStafAddress-child-3">
          <div>
            <label htmlFor="Home Phone">School Email</label>
            <div>
              <input
                name="schoolEmail"
                value={teacherContact.schoolEmail}
                onChange={teacherContactChanges}
                placeholder="-"
              />
            </div>
          </div>
          <div>
            <label>Teacher Office Phone</label> <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "7px",
              }}
            >
              <FaPhoneAlt />
              <PhoneInput
                placeholder="Enter Teacher office phone"
                value={teacherOfficePhone}
                onChange={setTeacherOfficePhone}
              />
            </div>
          </div>
        </div>
        <div className="EditStafAddress-4">
          <div className="EditStafAddress-5">
            <h3>Home Address</h3>
            {/* <FaLocationDot />
            <h5>Add on map</h5> */}
          </div>
          <div>
            <div className="EditStafAddress-child-3">
              <div>
                <label htmlFor="Home Phone">Street Address 1</label>
                <div>
                  <input
                    name="address1"
                    value={teacherAddress.address1}
                    onChange={teacherAddressChanges}
                    placeholder="-"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="Home Phone">Street Address 2</label>
                <div>
                  <input
                    name="address2"
                    value={teacherAddress.address2}
                    onChange={teacherAddressChanges}
                    placeholder="-"
                  />
                </div>
              </div>
              <div>
                <label>Country</label>
                <br />
                <select
                  value={selectedCountry}
                  onChange={(e) => handleCountryChange(e.target.value)}
                >
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option
                      key={country.isoCode}
                      selected={country?.isoCode === selectedCountry}
                      value={country.isoCode}
                    >
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label>State/Provence/Locality</label>
                <br />
                {selectedCountry && (
                  <select
                    value={selectedRegion}
                    onChange={(e) => handleRegionChange(e.target.value)}
                  >
                    <option value="">Select Region</option>
                    {regions.map((region) => (
                      <option
                        key={region.isoCode}
                        selected={region.isoCode === selectedRegion}
                        value={region.isoCode}
                      >
                        {region.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
            <div className="EditStafAddress-child-3">
              <div>
                <label>City</label>
                <br />
                {selectedRegion && (
                  <select
                    value={selectedCity}
                    onChange={(e) => handleCityChange(e.target.value)}
                  >
                    <option value="">Select City</option>
                    {cities.map((city) => (
                      <option
                        key={city.id}
                        selected={city.name === selectedRegion}
                        value={city.name}
                      >
                        {city.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <div>
                <label htmlFor="Home Phone">Zip/postal Code</label>
                <div>
                  <input
                    onChange={teacherAddressChanges}
                    value={teacherAddress.zipOrPostalCode}
                    type="text"
                    id="text"
                    name="zipOrPostalCode"
                    placeholder="-"
                  />
                </div>
              </div>
            </div>
            <h3>Emergency Contact Info</h3>
            <div className="EditStafAddress-child-3">
              <div>
                <label>Emergency Contact Name</label> <br />
                <input
                  name="emergencyContactName"
                  onChange={emergencyContactChanges}
                  value={emergencyContact.emergencyContactName}
                  placeholder="-"
                />
              </div>
              <div>
                <label>Relationship to Staff</label> <br />
                <input
                  name="relationshipToStaff"
                  onChange={emergencyContactChanges}
                  value={emergencyContact.relationshipToStaff}
                  placeholder="-"
                />
              </div>
              <div>
                <label>Emergency Home Phone</label> <br />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "7px",
                  }}
                >
                  <FaPhoneAlt />
                  <PhoneInput
                    placeholder="Enter emergency home phone"
                    value={emergencyHomePhone}
                    onChange={setEmergencyHomePhone}
                  />
                </div>
              </div>
            </div>
            <div className="EditStafAddress-child-3">
              <div>
                <label>Emergency Mobile Phone</label> <br />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "7px",
                  }}
                >
                  <FaPhoneAlt />
                  <PhoneInput
                    placeholder="Enter emergency mobile phone"
                    value={emergencyMobilePhone}
                    onChange={setEmergencyMobilePhone}
                  />
                </div>
              </div>
              <div>
                <label>Email</label> <br />
                <input
                  type="email"
                  name="email"
                  onChange={emergencyContactChanges}
                  value={emergencyContact.email}
                  placeholder="-"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="EditStafAddress-btnn">
          <button onClick={() => setShowEdit(false)}>Cancel</button>
          <button onClick={updateTeacherHandler}>Update</button>
        </div>
      </div>
    </div>
  );
};

export default EditStafAddress;
