import React, { useEffect, useState } from "react";
import "./ClassLectures.css";
import axios from "axios";
import { baseURL } from "../../../../constants";
import { useParams } from "react-router-dom";
axios.defaults.baseURL = baseURL;
function ClassLectures() {
  const [lectures, setLectures] = useState(null);
  const params = useParams();

  const getLectures = async () => {
    try {
      let { data } = await axios.get(
        `/lectures/getAllLectures/${params.subjectId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setLectures(data.lectures);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getLectures();
  }, []);

  
  const handleViewFiles = (file) => {
    let anchor = document.createElement("a");
    anchor.target = "_blank";
    document.body.appendChild(anchor);
    if (
      file?.split(".")?.pop() === "jpeg" ||
      file?.split(".")?.pop() === "png" ||
      file?.split(".")?.pop() === "jpg"
    ) {
      anchor.href = `${baseURL}/images/${file}`;
      anchor.click();
    } else if (
      file?.split(".")?.pop() === "csv" ||
      file?.split(".")?.pop() === "pdf"
    ) {
      anchor.href = `${baseURL}/documents/${file}`;
      anchor.click();
    }
    document.body.removeChild(anchor);
  };

  return (
    <div className="ClassLectures-main">
      <h2>Lectures</h2>
      {lectures &&
        lectures.map((lecture, index) => {
          return (
            <div className="ClassLec-Card" key={index}>
              <div className="main-ClassLectures">
                <div className="ClassLec-head">
                  <p>{index + 1}</p>
                  <h1>{lecture?.title}</h1>
                </div>
                <div className="ClassLec-From-To">
                  <button>
                    <div
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        color: "white",
                      }}
                      onClick={() => handleViewFiles(lecture?.file)}
                    >
                      View
                    </div>
                  </button>
                  <h6>
                    <span>Date : {lecture?.date?.split("T")[0]}</span>
                  </h6>
                  <hr />
                  <h6>
                    <span>From : {lecture?.createdAt?.split("T")[0]}</span>
                  </h6>
                  <hr />
                  <h6>
                    <span>To: {lecture?.updatedAt?.split("T")[0]}</span>
                  </h6>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default ClassLectures;