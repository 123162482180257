import React, { useEffect, useState } from "react";
import "./Teacherattendance.css";
import TopADDCards from "../TeacherNestedTabs/ADDCards/TopADDCards";
import Customerdetailsfilter from "../../Customerdetailsfilters/Customerdetailsfilter";
import axios from "axios";
import { baseURL } from "../../../../../constants";
import toast from "react-hot-toast";
import Dummy from "../../../../AdminComponents/images/dummy-user.png";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;

const Teacherattendance = () => {
  const [attendances, setAttendances] = useState(null);
  const getAttendances = async () => {
    try {
      const { data } = await axios.get(
        `/classrooms/getclassAnalytics/${localStorage.getItem("classroomId")}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setAttendances(data);
    } catch (error) {
      console.log(error);
    }
  };

  const [students, setStudents] = useState(null);

  const [status, setStatus] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `/students/getStudentsByClassroom/${localStorage.getItem(
          "classroomId"
        )}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setStatus((prev) => {
        return data?.classroom?.Subject?.StudentCourses?.map((st) => {
          return {
            id: st?.id,
            status: "",
          };
        });
      });
      setStudents(data?.classroom?.StudentCourses);
    })();
  }, []);
  useEffect(() => {
    getAttendances();
  }, []);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);
  const [schoolId, setSchoolId] = useState(null);
  const getSchoolByTeacherId = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/ParentBySchoolId/getSchoolByTeacherId/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setSchoolId(data.school.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchoolByTeacherId();
  }, [user]);

  const [studentAttendances, setStudentAttendances] = useState([]);

  const handleStatus = (studentId, status) => {
    const findStatus = studentAttendances.find((attendance) => {
      return (
        attendance?.studentId === studentId && attendance?.status === status
      );
    });

    const findAttendance = studentAttendances.find((attendance) => {
      return attendance?.studentId === studentId;
    });

    if (findStatus) {
      setStudentAttendances((prev) => {
        return prev.filter((isMatch) => {
          return isMatch?.studentId !== studentId;
        });
      });
    } else if (findAttendance) {
      setStudentAttendances((prev) => {
        return prev.map((attendance) => {
          return attendance?.studentId === studentId
            ? {
                ...attendance,
                status,
              }
            : attendance;
        });
      });
    } else {
      setStudentAttendances([
        ...studentAttendances,
        {
          status,
          studentId,
          schoolId: Number(schoolId),
          subjectId: Number(localStorage.getItem("subjectId")),
          teacherId: user?.dataValues?.id,
          classroomId: Number(localStorage.getItem("classroomId")),
        },
      ]);
    }
  };

  const handleAddAttendance = async () => {
    try {
      await axios.post(`/attendances/addAttendance`, [...studentAttendances], {
        headers: {
          "Content-Type": "application/json",
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      toast.success("Attendance Submitted Successfully");
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details?.[0]?.detail ||
            error?.response?.data?.details?.[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <div className="TeacherATTENDENCE_MAIN">
      <Customerdetailsfilter />
      <div className="Teacherattendance">
        <div className="TeacherattendanceCards">
          <TopADDCards />
        </div>
        <div className="Teacherattendance-main">
          <table className="Teacherattendance-table">
            <thead>
              <tr>
                <th>Student Name</th>
                <th>Guardian Name</th>
                <th>Date</th>
                <th>School Program</th>
                <th>Mark</th>
              </tr>
            </thead>
            <tbody>
              {students &&
                students.map((student) => (
                  <tr key={student.id}>
                    <td className="student-info">
                      <img
                        src={`${baseURL}/images/${student?.Student?.profilePicture}`}
                        alt="student-img"
                        onError={({ currentTarget }) => {
                          currentTarget.src = Dummy;
                        }}
                      />
                      <div>
                        {student?.Student?.firstName}{" "}
                        {student?.Student?.lastName}
                      </div>
                    </td>
                    <td>{student?.Student?.fatherName}</td>
                    <td>{student?.Student?.enrollDate?.split("T")[0]}</td>
                    <td>
                      <Form>
                        <Form.Check type="switch" id="custom-switch" />
                      </Form>
                    </td>
                    <td>
                      <div className="ThreeeButtons">
                        <button className="present">
                          Present
                          <input
                            onClick={() => handleStatus(student?.id, "present")}
                            type="checkbox"
                            checked={
                              !!studentAttendances.find(
                                (attendance) =>
                                  student?.id === attendance?.studentId &&
                                  attendance?.status === "present"
                              )
                            }
                          />
                        </button>
                        <button className="excused">
                          Excused
                          <input
                            onClick={() => handleStatus(student?.id, "excused")}
                            type="checkbox"
                            checked={
                              !!studentAttendances.find(
                                (attendance) =>
                                  student?.id === attendance?.studentId &&
                                  attendance?.status === "excused"
                              )
                            }
                          />
                        </button>
                        <button className="unexcused">
                          Unexcused
                          <input
                            onClick={() =>
                              handleStatus(student?.id, "unExcused")
                            }
                            type="checkbox"
                            checked={
                              !!studentAttendances.find(
                                (attendance) =>
                                  student?.id === attendance?.studentId &&
                                  attendance?.status === "unExcused"
                              )
                            }
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="TeacherattendanceBtn">
            <button onClick={handleAddAttendance}>Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teacherattendance;
