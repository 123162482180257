import React, { useEffect, useState } from "react";
import "../../../StudentComponents/WelcomeBack/Welcome.css";
import ParentPortal from "../../Images/cuate.png";
import "./ParentPortalWelcomeBack.css";
import axios from "axios";
import { baseURL } from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;

function ParentPortalWelcomeBack() {
  const [parentProfile, setParentProfile] = useState(null);

  const { user, loading, error } = useSelector((state) => state.getUserProfile);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const getParentProfile = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/parents/getParent/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        setParentProfile(data?.parent);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getParentProfile();
  }, [user]);

  const [textTitle, setTextTitle] = useState("Noticeboard");

  const [text, setText] = useState("This is noticeboard");
  useEffect(() => {
    const texts = [
      { title: "Noticeboard", text: "This is noticeboard" },
      { title: "Deadline", text: "Don't forget the deadline" },
      { title: "Reminders", text: "Here are your reminders" },
    ];

    let index = 0;
    const interval = setInterval(() => {
      index = (index + 1) % texts.length;
      setTextTitle(texts[index].title);
      setText(texts[index].text);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="Welcome-main1">
      <div className="Welcome-div1">
        <h1>
          Welcome back, {parentProfile?.firstName} {parentProfile?.middleName}{" "}
          {parentProfile?.lastName}
        </h1>
        <p>
          <span style={{ color: "white", fontWeight: "700" }}>{textTitle}</span>{" "}
          {text}
        </p>
      </div>
      <img src={ParentPortal} alt="#" />
    </div>
  );
}

export default ParentPortalWelcomeBack;
