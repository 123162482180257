import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useRef, useState } from "react";
import Dummy from "../../../AdminComponents/images/dummy-user.png";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  IoMdArrowBack,
  IoMdArrowDropleft,
  IoMdArrowDropright,
} from "react-icons/io";
import { IoCaretForward } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import "../../TeacherAllComponents/TeacherClassRoom/TeacherNestedTabs/createNewTest/StuSubmitedAss/Submited.css";
import { default as Green, default as azra } from "../Green.png";
import Red from "../Red.png";
import "../StuProfile.css";
import Timer from "../Timer.png";
import Graph from "../uP CHART.png";
import MyGrade from "./MyGrade/MyGrade.jsx";
import TuitionInfo from "./TuitionInfo/TuitionInfo.jsx";
import Dummy1 from "../../../AdminComponents/images/dummy-user.png";
import axios from "axios";
import Form from "react-bootstrap/Form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { baseURL } from "../../../../constants.js";
import { getUserProfile } from "../../../../redux/actions/userAction.js";
import ReportCard from "../../ReportCard/ReportCard.js";
axios.defaults.baseURL = baseURL;

function SixTabs() {
  const [assignmentId, setAssignmentId] = useState("");

  const [showStudentsGrades, setShowStudentsGrades] = useState(false);

  const [key, setKey] = useState("home");

  const [profile, setProfile] = useState(null);

  const [subject, setSubject] = useState(null);

  useEffect(() => {
    if (window.location.search?.split("=")[1] == "true") {
      setKey("assignment");
    }
  }, [window.location.search]);

  const getSubject = async () => {
    try {
      const { data } = await axios.get(
        `/subjects/getSubjectById/${localStorage.getItem("subjectId")}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );

      setSubject(data.subject.subjectName);
    } catch (error) {
      console.log(error);
    }
  };

  const [lgShow, setLgShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    assignmentTitle: "",
    instructions: "",
    status: "",
    date: "",
    time: "",
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let formattedValue = value;

    if (value.length === 10) {
      const parts = value.split("/");
      if (parts.length === 3) {
        const month = parts[0].padStart(2, "0");
        const day = parts[1].padStart(2, "0");
        const year = parts[2];
        formattedValue = `${month}/${day}/${year}`;
      }
    }

    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  const { studentId } = useParams();

  const [title, setTitle] = useState("");
  const [scoredPercentage, setScoredPercentage] = useState("");
  const [passingPercentage, setPassingPercentage] = useState("");
  const [instructions, setInstructions] = useState("");
  const [result, setResult] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [gradeType, setGradeType] = useState("");

  const { user } = useSelector((state) => state.getUserProfile);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const [schoolId, setSchoolId] = useState(null);

  const getSchoolByTeacherId = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/ParentBySchoolId/getSchoolByTeacherId/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setSchoolId(data.school.id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSchoolByTeacherId();
  }, [user]);

  const getGrades = async () => {
    try {
      const { data } = await axios.get(
        `/grades/all/${
          params?.studentId === ":studentId"
            ? localStorage.getItem("teacherStudent")
            : studentId
        }`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setGrades(data);
    } catch (error) {
      console.log(error);
    }
  };

  const [obtainmarkes, setObtainmarks] = useState("");

  const addGrade = async () => {
    try {
      const st =
        studentId === ":studentId"
          ? localStorage.getItem("teacherStudent")
          : studentId;
      const newForm = new FormData();
      newForm.append("title", title);
      newForm.append("scoredPercentage", scoredPercentage);
      newForm.append("passingPercentage", passingPercentage);
      newForm.append("instructions", instructions);
      newForm.append("date", date);
      newForm.append("time", time);
      newForm.append("gradeType", gradeType);
      newForm.append("studentId", st);
      newForm.append("schoolId", Number(schoolId));
      newForm.append("subjectId", localStorage.getItem("subjectId"));

      const { data } = await axios.post(
        `/grades/addGradeToAssignment/${assignmentId}`,
        {
          title,
          totalmarkes: scoredPercentage,
          passingmarkes: passingPercentage,
          instructions,
          date,
          time,
          studentId: st,
          schoolId,
          subjectId: localStorage.getItem("subjectId"),
          obtainmarkes,
        },
        {
          headers: {
            "Content-Type": "application/json",
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Grade Added Successfully");
      setTitle("");
      setScoredPercentage("");
      setPassingPercentage("");
      setInstructions("");
      setDate("");
      setTime("");
      setGradeType("");
      setAssignmentId("");

      setLgShow(false);
      getGrades();
    } catch (error) {
      console.log(error)
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message ||error?.response?.data?.error  );
      }
    }
  };

  const [student, setStudent] = useState("");

  const getStudent = async () => {
    try {
      const { data } = await axios.get(
        `/students/profile/${
          studentId === ":studentId"
            ? localStorage.getItem("teacherStudent")
            : studentId
        }`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setStudent(data?.student);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStudent();
  }, []);

  useEffect(() => {
    getSubject();
  }, []);
  const ref = useRef(null);

  const navigate = useNavigate();
  const [data, setData] = useState([
    {
      OrderID: "ALi ahmed",
      Customer: "1275435",
      image: azra,
      productName: "View Assigment",
      Total: "Text",
    },
    {
      OrderID: "ALi ahmed",
      Customer: "1275435",
      image: azra,
      productName: "View Assigment",
      Total: "Text",
    },
  ]);

  const [selectedRow, setSelectedRow] = useState(
    Array(data.length).fill(false)
  );

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 8;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = Array.isArray(data) ? data.slice(firstIndex, lastIndex) : [];
  const npage = data ? Math.ceil(data.length / recordsPerPage) : 0;
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function handleRowClick(index) {
    const updatedSelectedRows = [...selectedRow];
    updatedSelectedRows[index] = !updatedSelectedRows[index];

    setSelectedRow(updatedSelectedRows);
  }

  const [classRoom, setClassRoom] = useState(null);
  const params = useParams();

  const getStudentProfile = async () => {
    try {
      const { data } = await axios.get(
        `/students/profile/${
          params?.studentId === ":studentId"
            ? localStorage.getItem("teacherStudent")
            : studentId
        }`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setProfile(data?.student);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStudentProfile();
  }, []);

  const [grades, setGrades] = useState(null);

  useEffect(() => {
    getGrades();
  }, []);

  const [assignments, setAssignments] = useState(null);

  const getSubmittedAssignmentsOfStudent = async () => {
    try {
      const { data } = await axios.get(
        `/assignmentSubmissions/getSubmittedAssignmentsByStudent/${
          studentId === ":studentId"
            ? localStorage.getItem("teacherStudent")
            : studentId
        }`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setAssignments(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSubmittedAssignmentsOfStudent();
  }, []);

  const handleViewFiles = (file) => {
    let anchor = document.createElement("a");
    anchor.target = "_blank";
    document.body.appendChild(anchor);
    if (
      file?.split(".")?.pop() === "jpeg" ||
      file?.split(".")?.pop() === "png" ||
      file?.split(".")?.pop() === "jpg"
    ) {
      anchor.href = `${baseURL}/images/${file}`;
      anchor.click();
    } else if (
      file?.split(".")?.pop() === "csv" ||
      file?.split(".")?.pop() === "pdf"
    ) {
      anchor.href = `${baseURL}/documents/${file}`;
      anchor.click();
    }
    document.body.removeChild(anchor);
  };

  useEffect(() => {
    if (assignmentId) {
      setLgShow(true);
    }
  }, [assignmentId]);
  function handleMove() {
    navigate("/AllStudents");
  }

  const [assingment, setAsingment] = useState([]);

  const getAssingment = async () => {
    try {
      const { data } = await axios.get(
        `/assignmentSubmissions/getSubmittedAssignmentsCountByStudent/${studentId === ":studentId"
          ? localStorage.getItem("teacherStudent")
          : studentId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setAsingment(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAssingment();
  }, []);

  const [submit, setSubmit] = useState([]);
  const getSubmit = async () => {
    try {
      const { data } = await axios.get(
        `/assignments/getAssignmentCountByStudent/${studentId === ":studentId"
          ? localStorage.getItem("teacherStudent")
          : studentId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setSubmit(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSubmit();
  }, []);

  const [gpa, setGpa] = useState([]);

  const getGpa = async () => {
    try {
      const { data } = await axios.get(
        `/grades/calculateGPA/${studentId === ":studentId"
          ? localStorage.getItem("teacherStudent")
          : studentId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setGpa(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {

      getGpa();
  
  }, []);


  return (
    <div className="SixTabs-main">
      {key !== "tuition" ? (
        <div className="StuProfile-arrows">
          <p>Dashboard</p>
          <IoCaretForward />
          <p>Classrooms</p>
          <IoCaretForward />
          <p>{subject}</p>
          <span>
            <IoCaretForward />
            <p>Student List</p>
          </span>
        </div>
      ) : null}

      {key !== "tuition" ? (
        <div className="StuProfile-main">
          <div className="StuProfile-Top">
            <div className="StuProfile-Top1">
              <IoMdArrowBack
                onClick={handleMove}
                style={{ cursor: "pointer" }}
              />
              <h1>Student Profile</h1>
            </div>
            <div className="StuProfile-Top2">
              <Link to="/TeacherChat" className="StuProfile-Top-btn1">
                Parent Chat
              </Link>
              <Link to="/TeacherChat" className="StuProfile-Top-btn2">
                Student Chat
              </Link>
              <Link to="/TeacherChat" className="StuProfile-Top-btn2">
                Admin Chat
              </Link>
            </div>
          </div>
          <div className="Myprofile-main-1">
            <div className="Myprofile-main-child-1">
              <div className="profile">
                <div className="profile-child-1">
                  <img
                    src={`${baseURL}/images/${profile?.profilePicture}`}
                    onError={({ currentTarget }) => {
                      currentTarget.src = Dummy;
                    }}
                  />
                </div>
                <div className="profile-child-2">
                  <div className="profile-child-2-1">
                    <h2>{profile?.firstName + " " + profile?.lastName}</h2>
                  </div>
                  <div className="profile-child-2-2">
                    <p>{profile && "BSSE"}</p>
                    <button>
                      <span>●</span>
                      {profile?.online ? "Online" : "Offline"}
                    </button>
                  </div>
                  <div className="profile-child-2-3">
                    <p>{profile?.loggedInSince?.split("T")[0]}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="Myprofile-main-child-2">
              <div className="Myprofile-main-child-2-1">
                <img src={Timer} alt="#" />
                <h3>{new Date(profile?.joinedOn).toLocaleDateString()}</h3>
              </div>
              <div className="Myprofile-main-child-2-2">
                <div className="div-1">
                  <img src={Red} alt="#" />
                  <h3>
                    Assignments <span>{submit?.assignmentCount}</span>
                  </h3>
                </div>
                <div className="div-2">
                  <img src={Green} alt="#" />
                  <h3>
                    Completed{" "}
                    <span>{assingment?.submittedAssignmentsCount}</span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="Myprofile-main-child-3">
              <div className="Myprofile-main-child-3-card-1">
                <img src={Graph} alt="#" />
              </div>
              <div className="Myprofile-main-child-3-card-2">
                <h3>GPA</h3>
                <h1>{gpa?.gpa}</h1>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {key !== "tuition" ? (
        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          className="mb-3 SixTabs"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="home" title="Student Info" className="SixTab">
            <div className="Myprofile-main-2">
              <div className="Personal-Details">
                <div className="Personal-Details-child-1">
                  <h1>Personal Details</h1>
                  <h3>
                    Name :{" "}
                    <span>{profile?.firstName + " " + profile?.lastName}</span>
                  </h3>
                  <h3>
                    Date of Joining : <span>{profile?.joinedOn}</span>
                  </h3>
                  <h3>
                    Date of Birth :{" "}
                    <span>{profile?.dateOfBirth?.split("T")[0]}</span>
                  </h3>
                  <h3>
                    Contact Number : <span>{profile?.phoneNumber}</span>
                  </h3>
                  <h3>
                    Emergency Contact Number :{" "}
                    <span>{profile?.emergencyPhoneNumber}</span>
                  </h3>
                  <h3>
                    Email Address : <span>{profile?.email}</span>
                  </h3>
                </div>
                <div className="Personal-Details-child-3">
                  <h2>Education Details</h2>
                  <h3>
                    Highest Qualification :{" "}
                    <span>{profile?.highestQualification}</span>
                  </h3>
                  <h3>
                    Name of institution :{" "}
                    <span>{profile?.nameOfInstitution}</span>
                  </h3>
                  <h3>
                    Year of Qualification :{" "}
                    <span>{profile?.yearOfQualification}</span>
                  </h3>
                </div>
              </div>
              <div className="Personal-Details-child-3 akashCharak">
                <h1>Family Details</h1>
                <h3>
                  Father Name : <span>{profile?.fatherName}</span>
                </h3>
                <h3>
                  Profession : <span>{profile?.fatherProfession}</span>
                </h3>
                <h3>
                  Name of Organization :{" "}
                  <span>{profile?.nameOfOrganization}</span>
                </h3>
                <h3>
                  Contact Number : <span>{profile?.fatherContact}</span>
                </h3>
                <h3>
                  WhatsApp Number : <span>{profile?.fatherWhatsApp}</span>
                </h3>

                <h3>
                  Emergency Number :{" "}
                  <span>{profile?.emergencyPhoneNumber}</span>
                </h3>
                <h3>
                  Email Address : <span>{profile?.fatherEmail}</span>
                </h3>

                <h3>
                  Mother's Name : <span>{profile?.motherName}</span>
                </h3>
                <h3>
                  Profession : <span>{profile?.motherProfession}</span>
                </h3>
              </div>
            </div>
          </Tab>
          <Tab eventKey="grades" title="Grades" className="SixTab">
            {grades && (
              <MyGrade getGpa={getGpa} grades={grades} getGrades={getGrades} />
            )}
            
          </Tab>
          <Tab eventKey="assignment" title="Assignment" className="SixTab">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="order-main-childd">
                        <h5>Assignment Title</h5>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="order-main-childd">
                        <h5>Discription</h5>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="order-main-childd">
                        <h5>Action</h5>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                
                <TableBody>
                  {assignments &&
                    assignments.map((item, index) => (
                      <TableRow
                        key={index}
                        className={selectedRow[index] ? "selected" : ""}
                        onClick={() => handleRowClick(index)}
                      >
                        <TableCell>{item?.title}</TableCell>
                        <TableCell>
                          <div className="AzraTransitionTable-main-2">
                            <div className="AzraTransitionTable-main-3">
                              <div className="AzraTransitionTable-main-4">
                                <h5>{item?.Assignment?.description}</h5>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="StudentAssingButn">
                            <button
                              onClick={() => handleViewFiles(item?.fileUrl)}
                              className="AzraTransitionTableBtn"
                            >
                              View Assignment
                            </button>
                            <button
                              className="AzraTransitionTableBtn"
                              onClick={() => {
                                setLgShow(true);
                                setAssignmentId(item?.id);
                              }}
                            >
                              Add Grade{" "}
                            </button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div className="StudentGrading-main">
              <Modal
                size="lg"
                show={lgShow}
                onHide={() => {
                  setLgShow(false);
                  setAssignmentId("");
                }}
                aria-labelledby="example-modal-sizes-title-lg"
                className="StudentModal"
              >
                <Modal.Body>
                  <div className="StudentHead">
                    <h2>Student Grading</h2>
                  </div>
                  <div className="StudentGrading-MainBody">
                    <div className="StudentGrading-Profile">
                      <img
                        src={`${baseURL}/images/${student?.profilePicture}`}
                        onError={({ currentTarget }) => {
                          currentTarget.src = Dummy1;
                        }}
                        alt="#"
                      />
                    </div>
                    <div className="StudentGrading-Name">
                      <h4>
                        {student?.firstName} {student?.middleName}{" "}
                        {student?.lastName}
                      </h4>
                      <p className="Student-para">Student ID : {student?.id}</p>
                      <div className="StudentGrading-All">
                        <h6>
                          Subject: <span>{subject}</span>
                        </h6>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "15px",
                          }}
                        >
                          <div>
                            <input
                              style={{
                                width: "100%",
                                border: "1px solid lightgray",
                                borderRadius: "6px",
                                padding: "5px",
                                outline: "none",
                              }}
                              type="number"
                              placeholder="Total Marks"
                              value={scoredPercentage}
                              onChange={(e) =>
                                setScoredPercentage(e.target.value)
                              }
                            />
                          </div>
                          <div>
                            <input
                              style={{
                                width: "100%",
                                border: "1px solid lightgray",
                                borderRadius: "6px",
                                padding: "5px",
                                outline: "none",
                              }}
                              type="number"
                              value={passingPercentage}
                              onChange={(e) =>
                                setPassingPercentage(e.target.value)
                              }
                              placeholder="Passing Marks"
                            />
                          </div>
                          <div>
                            <input
                              style={{
                                width: "100%",
                                border: "1px solid lightgray",
                                borderRadius: "6px",
                                padding: "5px",
                                outline: "none",
                              }}
                              type="number"
                              value={obtainmarkes}
                              onChange={(e) => setObtainmarks(e.target.value)}
                              placeholder="Obtain Marks"
                            />
                          </div>
                        </div>
                        <label>Assignment Subject</label> <br />
                        <input
                          placeholder="Title"
                          className="StudentGrading-input"
                          name="assignmentTitle"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        <textarea
                          placeholder="Instructions (Optional)"
                          className="StudentGrading-textarea"
                          name="instructions"
                          value={instructions}
                          onChange={(e) => setInstructions(e.target.value)}
                        />
                        {/* <Form.Select
                          aria-label="Default select example"
                          style={{ marginTop: "1px", boxShadow: "none" }}
                          name="status"
                          value={result}
                          onChange={(e) => setResult(e.target.value)}
                        >
                          <option value={""}>Result</option>
                          <option value="pass">Pass</option>
                          <option value="fail">Fail</option>
                          <option value="miss">Missed</option>
                        </Form.Select> */}
                        <div className="StudentGrading-twoInputs">
                          <div style={{ width: "50%" }}>
                            <label htmlFor="selectDate">Date</label>
                            <br />
                            <input
                              id="selectDate"
                              type="date"
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                              // className="CustomDateInput"
                            />
                          </div>
                          <div style={{ width: "50%" }}>
                            <label>Time</label>
                            <br />
                            <input
                              type="time"
                              placeholder="- - : - -  - -"
                              name="time"
                              value={time}
                              onChange={(e) => setTime(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="StudentGrading-button">
                          <button onClick={addGrade}>Confirm</button>
                        </div>
                        <div className="StudentGrading-switch">
                          <label>notify all student on their email</label>
                          <Form>
                            <Form.Check type="switch" id="custom-switch" />
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>

            {/* ///////////////////////////////////////////////////////////////////// */}
            <div className="pagination-ordered">
              <IoMdArrowDropleft
                className="IoMdArrowDropleftee"
                onClick={prePage}
                disabled={currentPage === 1}
              />

              {number.map((id) => (
                <button key={id} onClick={() => changeCPage(id)}>
                  {id}
                </button>
              ))}
              <IoMdArrowDropright
                className="IoMdArrowDropleftee"
                onClick={nextPage}
                disabled={currentPage === npage}
              />
            </div>
          </Tab>
          <Tab
            eventKey="attendance"
            title="Attendance"
            className="SixTab"
          ></Tab>
          <Tab eventKey="transcript" title="Report Card" className="SixTab">
            <ReportCard />
          </Tab>
          <Tab
            key={key}
            eventKey="tuition"
            title="Tuition Info"
            className="SixTab"
          >
            {" "}
            Tuition Info{" "}
          </Tab>
        </Tabs>
      ) : (
        <TuitionInfo />
      )}
    </div>
  );
}

export default SixTabs;
