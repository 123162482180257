import React, { useEffect, useState } from "react";
import Charttt from "../../Charttt/Charttt";
import Card from "../ADDTabsPage/AttendenceCards/Card";
import CustomerDetailsFiltr from "../ADDTabsPage/customerdetailsfilters/CustomerDetailsFiltr";
import AttendenceFilter from "../AttendencePage/AttendenceFltr/AttendenceFilter";
import axios from "axios";
import { baseURL } from "../../../../constants";
axios.defaults.baseURL = baseURL;

function PageAttendence() {
  const [dataOverview, setDataOverview] = useState(null);
  const getDataOverview = async () => {
    try {
      const { data } = await axios.get("/classOverviews/getClassOverview", {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setDataOverview(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDataOverview();
  }, []);

  return (
    <div style={{ margin: "20px" }}>
      {/* {dataOverview && (
        <Charttt
          weekly={dataOverview[0].weekly}
          monthly={dataOverview[0].monthly}
          yearly={dataOverview[0].yearly}
        />
      )} */}
      <Charttt />
      <CustomerDetailsFiltr />
      <Card />
      <AttendenceFilter />
    </div>
  );
}

export default PageAttendence;
