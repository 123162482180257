import React, { useEffect, useState } from "react";
import "./StaffTabs.css";
import { useNavigate, useParams } from "react-router-dom";
import EditStaffInfo from "../Editstafprofile/Editstaffinfo/EditStaffInfo";
import axios from "axios";
import { baseURL } from "../../../../../constants";
axios.defaults.baseURL = baseURL;

function StaffTabs({ selectedFile }) {
  const [showEdit, setShowEdit] = useState(false);
  const navigation = useNavigate();
  const [staffData, setStaffData] = useState(null);
  const [teacherId, setTeacherId] = useState(1);

  const [fl, setFl] = useState(null);
  useEffect(() => {
    setFl(selectedFile);
  }, [selectedFile]);

  const params = useParams();
  const getStaffSchool = async () => {
    try {
      const { data } = await axios.get(
        `/teachers/getTeacherSchoolInfoAndOfficialInfo/${params.staffId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setStaffData(data.teacherWithInfo);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStaffSchool();
  }, []);

  useEffect(() => {
    if (window?.location?.search?.split("=")[1] == "true") {
      setShowEdit(true);
      navigation(`/StaffProfile/${params.staffId}?edit=true`);
    }
  }, [window.location.search]);
  return !showEdit && staffData ? (
    <div className="StaffProfile-main">
      <div className="StaffProfile-child-1">
        <div className="stafehead">
          <h3>General Information</h3>
          <h4>Identification Information</h4>
        </div>
        <button
          onClick={() => {
            setShowEdit(true);

            navigation(`/StaffProfile/${params.staffId}?edit=true`);
          }}
        >
          Edit Information
        </button>
      </div>
      <div className="StaffProfile-row-1">
        <div>
          <label>Staff Name</label> <br />
          <input value={staffData?.staffName} readOnly placeholder="-" />
        </div>
        <div>
          <label>Staff ID</label> <br />
          <input readOnly value={staffData?.staffId} placeholder="-" />
        </div>
        <div>
          <label>Certification</label> <br />
          <input value={staffData?.certifications} readOnly placeholder="-" />
        </div>
      </div>
      <div className="StaffProfile-row-1">
        <div>
          <label>State ID</label> <br />
          <input value={staffData?.stateId} readOnly placeholder="-" />
        </div>
        <div>
          <label>District ID</label> <br />
          <input value={staffData?.districtId} readOnly placeholder="-" />
        </div>
        <div>
          <label>other Govt Issue Number</label> <br />
          <input value={staffData?.govIssuedNumber} readOnly placeholder="-" />
        </div>
      </div>
      <h3 style={{ marginLeft: "20px" }}>Demographic Information</h3>
      <div className="StaffProfile-row-1">
        <div>
          <label>Date of birth</label> <br />
          <input
            value={staffData?.dateOfBirth?.split("T")[0]}
            readOnly
            placeholder="-"
          />
        </div>
        <div>
          <label>Gender</label> <br />
          <input value={staffData?.gender} readOnly placeholder="-" />
        </div>
        <div>
          <label>Native Language</label> <br />
          <input value={staffData?.nativeLanguage} readOnly placeholder="-" />
        </div>
      </div>
      <div className="StaffProfile-row-1">
        <div>
          <label>Nationality</label> <br />
          <input value={staffData?.nationality} readOnly placeholder="-" />
        </div>
        <div>
          <label>Native Language</label> <br />
          <input value={staffData?.nativeLanguage} readOnly placeholder="-" />
        </div>

        <div>
          <label>Spoken Language</label> <br />
          <input
            value={
              Array.isArray(staffData?.languagesSpoken)
                ? staffData?.languagesSpoken?.join(", ")
                : ""
            }
            readOnly
            placeholder="-"
          />
        </div>
      </div>
      <h3 style={{ marginLeft: "20px" }}>Access Information</h3>
      <div className="StaffProfile-row-1">
        <div>
          <label>Login Email Address</label> <br />
          <input
            value={staffData?.loginEmailAddress}
            readOnly
            placeholder="-"
          />
        </div>
        <div>
          <label>Portal Access</label> <br />
          <input
            value={staffData?.portalAccess == true ? "Active" : "Inactive"}
            readOnly
            placeholder="-"
          />
        </div>
      </div>
    </div>
  ) : (
    <EditStaffInfo
      setShowEdit={setShowEdit}
      getStaffSchool={getStaffSchool}
      staffData={staffData}
      fg={selectedFile}
      setFl={setFl}
    />
  );
}

export default StaffTabs;
