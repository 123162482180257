import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Modall from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import group_ui from "../Group 1000006052.png";
import combine_ui from "../Vector.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import Box from "@mui/material/Box";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../../../../constants";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";

axios.defaults.baseURL = baseURL;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Notice = ({ data = [], func }) => {
  const [editId, setEditId] = useState("");

  const [deleteId, setDeleteId] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setDeleteId("");
    setOpen(false);
    setEditId("");
  };

  const [lgShow, setLgShow] = useState(false);
  const [fromDate, setFromDate] = useState("");

  var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
  };

  var formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
  ];

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [validFrom, setValidFrom] = useState("");
  const [validTo, setValidTo] = useState("");
  const [visibleToSchool, setVisibleToSchool] = useState(false);
  const [visibleToStudents, setVisibleToStudents] = useState(false);
  const [visibleToParents, setVisibleToParents] = useState(false);
  const [visibleToVendor, setVisibleToVendor] = useState(false);
  const [visibleToTeacher, setVisibleToTeacher] = useState(false);
  const [visibleToAllSchools, setVisibleToAllSchools] = useState(false);
  const [visibleToGov, setVisibleToGov] = useState(false);

  const { schoolId } = useSelector((state) => state?.schoolId);
  const handleAddNoticeboard = async () => {
    try {
      await axios.put(
        `/notices/updateNotice/${editId}`,
        {
          schoolId,
          title,
          content,
          validFrom,
          validTo,
          visibleToSchool,
          visibleToStudents,
          visibleToParents,
          visibleToVendor,
          visibleToTeacher,
          visibleToAllSchools,
          visibleToGov,
        },
        {
          headers: {
            "Content-Type": "application/json",
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      func();
      toast.success("Notice Updated Successfully");
      handleClose();
      setEditId("");
      setLgShow(false);
      setTitle("");
      setContent("");
      setValidFrom("");
      setValidTo("");
      setVisibleToSchool(false);
      setVisibleToStudents(false);
      setVisibleToParents(false);
      setVisibleToVendor(false);
      setVisibleToTeacher(false);
      setVisibleToAllSchools(false);
      setVisibleToGov(false);
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (editId && data) {
      setLgShow(true);
      const findNotice = data?.find((notice) => {
        return notice?.id == editId;
      });
      setTitle(findNotice?.title);
      setContent(findNotice?.content);
      setValidFrom(findNotice?.validFrom);
      setValidTo(findNotice?.validTo);
      setVisibleToSchool(findNotice?.visibleToSchool);
      setVisibleToStudents(findNotice?.visibleToStudents);
      setVisibleToParents(findNotice?.visibleToParents);
      setVisibleToVendor(findNotice?.visibleToVendor);
      setVisibleToTeacher(findNotice?.visibleToTeacher);
      setVisibleToAllSchools(findNotice?.visibleToAllSchools);
      setVisibleToGov(findNotice?.visibleToGov);
    }
  }, [editId]);
  const handleDeleteNotice = async () => {
    try {
      await axios.delete(`/notices/removeNotice/${deleteId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      func();
      toast.success("Notice Deleted Successfully");
      handleClose();
      setLgShow(false);
      setDeleteId("");
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  return (
    <>
      <div className="CommentModal-Mmain">
        <Modall
          className="Allowmodalmain"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div className="CommentModal-Mmain-child1">
                <h4>Delete Notice</h4>
                <p>
                  Are you sure you want to delete this Notice? This will remove
                  the Notice and can’t be undone.
                </p>
                <div className="CommentModal-Mmain-child2">
                  <button
                    className="CommentModal-Mmain-child2-allow"
                    onClick={handleClose}
                  >
                    CANCEL
                  </button>
                  <button
                    onClick={handleDeleteNotice}
                    className="CommentModal-Mmain-child2-Dont-allow"
                  >
                    DELETE
                  </button>
                </div>
              </div>
            </Box>
          </Fade>
        </Modall>
      </div>
      <div className="ADDNewNotice">
        <div>
          <Modal
            className="newmodalmm"
            size="lg"
            show={lgShow}
            onHide={() => {
              setDeleteId("");
              setLgShow(false);
              setEditId("");
            }}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton className="new-Activity-header">
              <p>Add Notice</p>
            </Modal.Header>
            <Modal.Title id="example-modal-sizes-title-lg"></Modal.Title>
            <Modal.Body>
              <div className="NoticeModalAdd-mainn">
                <div>
                  <label htmlFor="title">Title</label>
                  <div>
                    <input
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      type="text"
                      id="text"
                      placeholder="Title"
                    />
                  </div>
                </div>
                <div className="NoticeModalAdd-mainn-child-1">
                  <label htmlFor="Content">Content</label>
                  <div className="reatctquill">
                    <ReactQuill
                      value={content}
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      placeholder="insert text.."
                      onChange={setContent}
                    ></ReactQuill>
                  </div>
                </div>
                <div className="NoticeModalAdd-mainn-child-2">
                  <div>
                    <label htmlFor="form">From</label>
                    <div>
                      <input
                        type="date"
                        value={validFrom}
                        onChange={(e) => setValidFrom(e.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="To">To</label>
                    <div>
                      <input
                        type="date"
                        value={validTo}
                        min={fromDate}
                        onChange={(e) => setValidTo(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="NoticeModalAdd-mainn-child-7">
                <div className="NoticeModalAdd-mainn-child-4">
                  <div className="childnotice1">
                    <input
                      checked={visibleToSchool}
                      onChange={() => setVisibleToSchool(!visibleToSchool)}
                      type="checkbox"
                    />
                    <p>School</p>
                  </div>
                  <div className="childnotice1">
                    <input
                      checked={visibleToStudents}
                      onChange={() => setVisibleToStudents(!visibleToStudents)}
                      type="checkbox"
                    />
                    <p>Student</p>
                  </div>
                  <div className="childnotice1">
                    <input
                      checked={visibleToParents}
                      onChange={() => setVisibleToParents(!visibleToParents)}
                      type="checkbox"
                    />
                    <p>Parent</p>
                  </div>
                </div>
                <div className="NoticeModalAdd-mainn-child-5">
                  <div className="childnotice1">
                    <input
                      checked={visibleToVendor}
                      onChange={() => setVisibleToVendor(!visibleToVendor)}
                      type="checkbox"
                    />
                    <p>Vendor</p>
                  </div>
                  <div className="childnotice1">
                    <input
                      checked={visibleToTeacher}
                      onChange={() => setVisibleToTeacher(!visibleToTeacher)}
                      type="checkbox"
                    />
                    <p>Teacher</p>
                  </div>
                  <div className="childnotice1">
                    <input
                      checked={visibleToAllSchools}
                      onChange={() =>
                        setVisibleToAllSchools(!visibleToAllSchools)
                      }
                      type="checkbox"
                    />
                    <p>All Schools</p>
                  </div>
                  <div className="childnotice1">
                    <input
                      checked={visibleToGov}
                      onChange={() => setVisibleToGov(!visibleToGov)}
                      type="checkbox"
                    />
                    <p>Government</p>
                  </div>
                </div>
              </div>
              <div className="NoticeModalAdd-mainn-child-8">
                <button onClick={handleClose}>Cancel</button>
                <button onClick={handleAddNoticeboard}>Submit</button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {data?.map((notice) => {
          return (
            <div className="School-Calander-main-child-2">
              <div className="School-Calander-main-child-2-child-1 ThreeDOtsNotice">
                <h1>{notice?.title}</h1>
                <div className="Mini_MAin Mini_Main_Teacher">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <BsThreeDotsVertical className="School-Calander-main-child-2-child-1-BsThreeDotsVertical" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className="Mini-Main-Div">
                        <Dropdown href="#/action-1">
                          <p
                            onClick={() => setEditId(notice?.id)}
                            className="Mini-Main-Btns2"
                          >
                            Edit
                          </p>
                        </Dropdown>
                        <Dropdown href="#/action-2">
                          <p
                            className="Mini-Main-Btns2"
                            onClick={() => {
                              handleOpen();
                              setDeleteId(notice?.id);
                            }}
                          >
                            Delete
                          </p>
                        </Dropdown>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <div className="School-Calander-main-child-2-child-2">
                <div className="school-calander-child-2-data">
                  <p>"{notice?.content?.replace(/<\/?p>/g, "")}"</p>
                </div>
                <div>
                  <div className="bosdii-lala">
                    <img src={combine_ui} alt="" />
                    <p>
                      Visible from {notice?.validFrom?.split("T")[0]} -{" "}
                      {notice?.validTo?.split("T")[0]}
                    </p>
                  </div>
                  <div className="bosdii-lala-2">
                    <img src={group_ui} alt="" />
                    <p>
                      Visible To{" "}
                      {notice?.visibleToAllSchools == true
                        ? "All Schools,"
                        : ""}{" "}
                      {notice?.visibleToGov == true ? "Government," : ""}{" "}
                      {notice?.visibleToParents == true ? "Parents," : ""}
                      {notice?.visibleToSchool == true ? "School," : ""}
                      {notice?.visibleToStudents == true ? "Students," : ""}
                      {notice?.visibleToTeacher == true ? "Teachers," : ""}
                      {notice?.visibleToVendor == true ? "Vendor," : ""}
                      {/* Parents */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Notice;
