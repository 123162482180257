import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import CalculatorPage from "../pages/CalculatorPage";
import Assigment from "../AssigmentResult/Assigment";
import MyAppAccess from "../pages/MyAppAccess";
import MyProfile from "../MyProfile/Myprofile";
import Sidebar from "../Sidebar/Sidebar";
import TopSearchBar from "../TopSearchBar/TopSearchBar";
import HomePage from "../pages/HomePage";
import ClassRoomPage from "../pages/ClassRoomPage";
import NestedTabs from "../NestedTabs/NestedTabs";
import Attendence from "../pages/Attendence";
import Transcripts from "../Transcripts/Transcripts";
import MyAssingmentSub from "../NestedTabs/MyAssingmentsSub/MyAssingmentSub";
import Request from "../Request/Request";
import Setting from "../setting/Setting";
import CalenderPage from "../CalenderPage/CalenderPage";
import Calanderwork from "../calanderwork/Calanderwork";
import Readmore from "../readmore/Readmore";
import ADDChatBox from "../ADDChatBox/ADDChatBox";
import Medical from "../Medical/Medical";
import MediHistory from "../MedicalHistory/MediHistory";
import Guidance from "../Guidance/Guidance";
import ReportCard from "../ReportCard/ReportCard";
// import Plan from "../Plan504/Plan";
import History from "../GradeHistory/History";
import Grades from "../StudentGrades/Grades";
// import Special from "../SpecialEducation/Special";
import GraStatus from "../GraduationStatus/GraStatus";
import Notice from "../GetNoticeFromAdmin/Notice";
import ExtraActivities from "../Extracurricular/ExtraActivities";
import { getUserProfile } from "../../../redux/actions/userAction";
import AddEvent from "../CalenderPage/AddEvent";
import { useDispatch } from "react-redux";

function StuRouting() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  return (
    <div>
      <Routes>
        <Route
          path="/Home"
          element={<PrivateRoute component={<HomePageWithSidebar />} />}
        />
        <Route
          path="/ClassRoomPage"
          element={<PrivateRoute component={<ClassRoomPageWithSidebar />} />}
        />
        <Route
          path="/NestedTabs/:subjectId"
          element={<PrivateRoute component={<NestedTabsWithSidebar />} />}
        />
        <Route path="/Attendence" element={<AttendenceWithSidebar />} />
        <Route path="/TranscriptStudent" element={<TranscriptsWithSidebar />} />
        <Route
          path="/MyAssingmentSub"
          element={<MyAssingmentSubWithSidebar />}
        />
        <Route path="/Request" element={<RequestWithSidebar />} />
        <Route path="/Myprofile" element={<MyProfileWithSidebar />} />
        <Route path="/Setting" element={<SettingWithSidebar />} />
        <Route path="/CalculatorPage" element={<CalculatorPageWithSidebar />} />
        <Route path="/Assigment" element={<AssigmentWithSidebar />} />
        <Route path="/MyAppAccess" element={<MyAppAccessWithSidebar />} />
        <Route path="/Calender" element={<CalenderPageWithSidebar />} />
        <Route path="/Calanderwork" element={<CalanderworkWithSidebar />} />
        <Route path="/Readmore" element={<ReadmoreWithSidebar />} />
        <Route path="/StudentChat" element={<ADDChatBoxWithSidebar />} />
        <Route path="/Medicallog" element={<MedicalWithSidebar />} />
        <Route path="/MedicalHistory" element={<MediHistoryWithSidebar />} />
        <Route path="/Guidance" element={<GuidanceWithSidebar />} />
        <Route path="/ReportCard" element={<ReportCardWithSidebar />} />
        {/* <Route path="/504Plan" element={<PlanWithSidebar />} /> */}
        <Route path="/GradesHistory" element={<HistoryWithSidebar />} />
        <Route path="/Grades" element={<GradesWithSidebar />} />
        {/* <Route path="/SpecialEducation" element={<SpecialWithSidebar />} /> */}
        <Route path="/GraduationStatus" element={<GraStatusWithSidebar />} />
        <Route
          path="/ExtraActivities"
          element={<ExtraActivitiesWithSidebar />}
        />
        <Route
          path="/MyAssingment/:gradeId"
          element={<AssigmentWithSidebar />}
        />

        <Route path="/StudentNoticeBoard" element={<NoticeWithSidebar />} />
        <Route
          path="/AddCalendarEventStudent"
          element={<AddEventWithSidebar />}
        />
      </Routes>
    </div>
  );
}
function PrivateRoute({ component }) {
  const isAuthenticated = true;

  if (isAuthenticated) {
    return component;
  } else {
    return <Navigate to="/Login" replace />;
  }
}

function HomePageWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <HomePage />
    </Sidebar>
  );
}

function ClassRoomPageWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <ClassRoomPage />
    </Sidebar>
  );
}

function NestedTabsWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <NestedTabs />
    </Sidebar>
  );
}
function AttendenceWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <Attendence />
    </Sidebar>
  );
}

function TranscriptsWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <Transcripts />
    </Sidebar>
  );
}
function MyAssingmentSubWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <MyAssingmentSub />
    </Sidebar>
  );
}
function RequestWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <Request />
    </Sidebar>
  );
}
function MyProfileWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <MyProfile />
    </Sidebar>
  );
}
function SettingWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <Setting />
    </Sidebar>
  );
}

function CalculatorPageWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <CalculatorPage />
    </Sidebar>
  );
}
function AssigmentWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <Assigment />
    </Sidebar>
  );
}
function MyAppAccessWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <MyAppAccess />
    </Sidebar>
  );
}

function CalenderPageWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <CalenderPage />
    </Sidebar>
  );
}
function CalanderworkWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <Calanderwork />
    </Sidebar>
  );
}
function ReadmoreWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <Readmore />
    </Sidebar>
  );
}
function ADDChatBoxWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <ADDChatBox />
    </Sidebar>
  );
}
function MedicalWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <Medical />
    </Sidebar>
  );
}
function MediHistoryWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <MediHistory />
    </Sidebar>
  );
}

function GuidanceWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <Guidance />
    </Sidebar>
  );
}

function ReportCardWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <ReportCard />
    </Sidebar>
  );
}

// function PlanWithSidebar() {
//   return (
//     <Sidebar>
//       <TopSearchBar />
//       <Plan />
//     </Sidebar>
//   );
// }
function HistoryWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <History />
    </Sidebar>
  );
}
function GradesWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <Grades />
    </Sidebar>
  );
}
// function SpecialWithSidebar() {
//   return (
//     <Sidebar>
//       <TopSearchBar />
//       <Special />
//     </Sidebar>
//   );
// }
function GraStatusWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <GraStatus />
    </Sidebar>
  );
}
function ExtraActivitiesWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <ExtraActivities />
    </Sidebar>
  );
}
function AddEventWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <AddEvent />
    </Sidebar>
  );
}
function NoticeWithSidebar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <Notice />
    </Sidebar>
  );
}
export default StuRouting;
