import React, { useState } from "react";
import "./TableView.css";
import { useTable, usePagination, useSortBy } from "react-table";
import { TiMediaPlayReverse } from "react-icons/ti";
import { TiMediaPlay } from "react-icons/ti";
import { PiDotsThree } from "react-icons/pi";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Dummy from "../../../images/dummy-user.png";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { BsThreeDots } from "react-icons/bs";
import { baseURL } from "../../../../../constants";
import toast from "react-hot-toast";
axios.defaults.baseURL = baseURL;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const TableView = ({ link, tableData, getParentTableData, filterFunction }) => {
  const [deleteId, setDeleteId] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setDeleteId("");
  };
  const { role } = useParams();

  const navigate = useNavigate();

  const columns = [
    {
      Header: "Guardian Name",
      accessor: "parentName",
    },
    {
      Header: "Relationships",
      accessor: "relationship",
    },
    {
      Header: "Personal Email",
      accessor: "personalEmail",
    },
    {
      Header: "Mobile Number",
      accessor: "mobileNumber",
    },
    {
      Header: "Action",
      accessor: "associatedStudent",
    },
  ];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    state: { pageIndex },
    pageCount,
    gotoPage,
    canNextPage,
    canPreviousPage,
  } = useTable(
    {
      columns,
      data: tableData,
      autoResetHiddenColumns: false,
      initialState: { pageSize: 15 },
    },
    useSortBy,
    usePagination
  );
  const deleteParent = async () => {
    try {
      await axios.delete(`/parents/remove/${deleteId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      toast.success("Parent Deleted Successfully");
      getParentTableData();
      handleClose();
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.message ||
            error?.response?.data?.details[0]?.detail
        );
      } else {
        toast.error(error?.response?.data?.error);
      }
    }
  };
  return (
    tableData && (
      <div className="table-container">
        <div className="CommentModal-Mmain">
          <Modal
            className="Allowmodalmain"
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <div className="CommentModal-Mmain-child1">
                  <h4>Delete Guardian</h4>
                  <p>
                    Are you sure you want to delete the Guardian? This will
                    remove the Guardian and can’t be undone.
                  </p>
                  <div className="CommentModal-Mmain-child2">
                    <button
                      onClick={() => handleClose()}
                      className="CommentModal-Mmain-child2-allow "
                    >
                      CANCEL
                    </button>
                    <button
                      onClick={deleteParent}
                      className="CommentModal-Mmain-child2-Dont-allow"
                    >
                      DELETE
                    </button>
                  </div>
                </div>
              </Box>
            </Fade>
          </Modal>
        </div>
        <div
          className="amrooooogand"
          to={link}
          style={{ textDecoration: "none" }}
        >
          <table style={{ overflow: "visible" }} {...getTableProps()}>
            <thead>
              {headerGroups.map((hg) => {
                return (
                  <tr {...hg.getHeaderGroupProps()}>
                    {hg.headers.map((header) => {
                      return (
                        <th
                          {...header.getHeaderProps(
                            header.getSortByToggleProps()
                          )}
                        >
                          {header.render("Header")}
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>
                          {cell.column.Header === "Guardian Name" ? (
                            <div
                              onClick={() =>
                                navigate(`/Addparant/${row.original?.id}`)
                              }
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "15px",
                                cursor: "pointer",
                              }}
                            >
                              <input
                                type="checkbox"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              />
                              <img
                                src={`${baseURL}/images/${cell.row.original?.image}`}
                                alt={"Person"}
                                style={{
                                  width: "50px",
                                  borderRadius: "100%",
                                  height: "50px",
                                }}
                                onError={({ currentTarget }) => {
                                  currentTarget.src = Dummy;
                                }}
                              />
                              <p style={{ cursor: "pointer" }}>
                                {cell.row.original?.parentName}
                              </p>
                            </div>
                          ) : cell.column.Header === "Action" ? (
                            <p
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <div className="Mini_MAin">
                                <Dropdown>
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <BsThreeDots />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <div className="Mini-Main-Div">
                                      <Dropdown href="#/action-1">
                                        <Link
                                          to={`/Addparanteditinfo/${row.original?.id}`}
                                          className="Mini-Main-Btns2"
                                        >
                                          Edit
                                        </Link>
                                      </Dropdown>
                                      <Dropdown href="#/action-2">
                                        <p
                                          onClick={() => {
                                            setDeleteId(cell.row.original?.id);
                                            handleOpen();
                                          }}
                                          className="Mini-Main-Btns2"
                                        >
                                          Delete
                                        </p>
                                      </Dropdown>
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </p>
                          ) : (
                            cell.render("Cell")
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="tablefooter">
          <div>
            <p>
              Showing {pageIndex + 1} of {pageCount} from {tableData.length}
            </p>
          </div>
          <div className="buttons">
            <button onClick={previousPage} className="previousButton">
              <TiMediaPlayReverse size={18} color="#00afef" />
            </button>
            <button
              style={{
                backgroundColor: pageIndex === 0 ? "#00afef" : "#c4e6f5",
                color: pageIndex === 0 ? "white" : "#00afef",
              }}
              onClick={() => gotoPage(0)}
              className="numbersButton"
            >
              1
            </button>

            <button
              style={{
                backgroundColor: pageIndex === 1 ? "#00afef" : "#c4e6f5",
                color: pageIndex === 1 ? "white" : "#00afef",
              }}
              onClick={() => gotoPage(1)}
              className="numbersButton"
            >
              2
            </button>
            <button
              style={{
                backgroundColor: pageIndex === 2 ? "#00afef" : "#c4e6f5",
                color: pageIndex === 2 ? "white" : "#00afef",
              }}
              onClick={() => gotoPage(2)}
              className="numbersButton"
            >
              3
            </button>
            <button
              style={{
                backgroundColor: pageIndex === 3 ? "#00afef" : "#c4e6f5",
                color: pageIndex === 3 ? "white" : "#00afef",
              }}
              onClick={() => gotoPage(3)}
              className="numbersButton"
            >
              4
            </button>
            <button
              style={{
                backgroundColor: pageIndex === 4 ? "#00afef" : "#c4e6f5",
                color: pageIndex === 4 ? "white" : "#00afef",
              }}
              onClick={() => gotoPage(4)}
              className="numbersButton"
            >
              5
            </button>
            <button
              className="numbersButton"
              onClick={() => gotoPage(pageCount - 1)}
            >
              <PiDotsThree size={23} />
            </button>
            <button
              style={{
                backgroundColor: "#c4e6f5",
                width: "35px",
                height: "35px",
                borderRadius: "10px",
                border: "none",
                backgroundColor: "#c4e6f5",
                cursor: "pointer",
              }}
              onClick={nextPage}
              className="next"
            >
              <TiMediaPlay size={18} color="#00afef" />
            </button>
          </div>
        </div>
        <p className="footer-text">© 2023 MyCentrality. All Rights Reserved.</p>
      </div>
    )
  );
};

export default TableView;
