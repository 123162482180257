import React from "react";
import moment from "moment";
import { baseURL } from "../../../../../constants";
const EventCard = ({ event = {} }) => {
  return (
    <>
      <div className="calendar-event-card">
        <div>
          <img
            src={`${baseURL}/images/${event?.coverPhoto}`}
            className="event-card-image"
          />
        </div>
        <div className="event-card-right-div">
          <h6>{event?.eventName}</h6>
          <p className="calendar-event-same-style">
            {moment(event?.startdate).format("YYYY Do MMMM hh:mm:ss")} -{" "}
            {moment(event?.enddate).format("YYYY Do MM hh:mm:ss")}
          </p>

          <p className="calendar-event-same-style">{event?.description}</p>
          <p className="calendar-event-same-style">{event?.address}</p>
          <div className="calendar-images-div">
            {new Array(3)
              .fill(
                "https://i.pinimg.com/736x/06/9e/00/069e008833a8f421133121e49a5b493a.jpg"
              )
              .map((img) => {
                return <img src={img} className="event-card-images" />;
              })}
            <div className="event-card-count">
              <p>15+</p>
            </div>
          </div>
        </div>
      </div>
      <div className="calendar-event-border-line" />
    </>
  );
};

export default EventCard;
