import React, { useEffect, useState } from "react";
import "./Welcome.css";
import Teacher from "../images/image 1.png";
import Hand from "../images/image 296.png";
import axios from "axios";
import { baseURL } from "../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;
function Welcome() {
  const [profile, setProfile] = useState({});
  const { user } = useSelector((state) => state.getUserProfile);

  const getStudenProfile = async () => {
    try {
      if (user) {
        let response = await axios.get(
          `/students/profile/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setProfile(response.data.student);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStudenProfile();
  }, [user]);

  const [textTitle, setTextTitle] = useState("Noticeboard");

  const [text, setText] = useState("This is noticeboard");
  useEffect(() => {
    const texts = [
      { title: "Noticeboard", text: "This is noticeboard" },
      { title: "Deadline", text: "Don't forget the deadline" },
      { title: "Reminders", text: "Here are your reminders" },
    ];

    let index = 0;
    const interval = setInterval(() => {
      index = (index + 1) % texts.length;
      setTextTitle(texts[index].title);
      setText(texts[index].text);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="Welcome-main">
      <div className="Welcome-div1">
        <h1>
          Welcome back, {profile?.firstName} {profile?.middleName}{" "}
          {profile?.lastName}
          <span>
            <img src={Hand} alt="#" />
          </span>
        </h1>
        <p>
          <span style={{ color: "white", fontWeight: "700" }}>{textTitle}</span>{" "}
          {text}
        </p>
      </div>
      <div className="Welcome-div2">
        <img src={Teacher} alt="#" />
      </div>
    </div>
  );
}

export default Welcome;
