import React, { useState, useEffect } from "react";
import { IoArrowBack, IoCheckmarkDone } from "react-icons/io5";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaRegCalendarAlt } from "react-icons/fa";
import { LuClock4 } from "react-icons/lu";
import ProfilePic from "../images/Shoaib.png";
import Quizet from "../images/Quizlet-Logo-2021-present 1.png";
import lines from "../images/lines-removebg-preview.png";
import "./Assigment.css";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Dummy from "../../AdminComponents/images/dummy-user.png";
import axios from "axios";
import { baseURL } from "../../../constants";
axios.defaults.baseURL = baseURL;

function Assigment() {
  const gradeStyles = [
    {
      result: "failed",
      labelColor: "#fff",
      buttonColor: "#f93333",
      progressbarColor: "#f93333",
      gradebc: "#ffefef",
    },
    {
      result: "missed",
      buttonColor: "#9A9A9A",
      labelColor: "#9A9A9A",
      progressbarColor: "#9A9A9A",
      gradebc: "#E6E6E6",
    },
    {
      result: "passed",
      buttonColor: "#11A529",
      labelColor: "#fff",
      progressbarColor: "#11A529",
      gradebc: "#Effff5",
    },
  ];
  const navigate = useNavigate();
  const location = useLocation();

  // const passingPercentage = 30;
  // const percentage = (passingPercentage / 100) * totalmarkes;
  // const passingFraction = `${passingPercentage / 10}/10`;

  const handleGoBack = () => {
    navigate(-1);
  };
  const { user } = useSelector((state) => state.getUserProfile);

  const [student, setStudent] = useState([]);

  const getStudent = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/students/profile/${user.dataValues.id}`,
          {
            headers: {
              accessToken: localStorage.getItem("token"),
            },
          }
        );
        setStudent(data.student);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (user) {
      getStudent();
    }
  }, [user]);

  const { gradeId } = useParams();

  const [grade, setGrade] = useState([]);
  const getGrade = async () => {
    try {
      const { data } = await axios.get(`/grades/${gradeId}`, {
        headers: {
          accessToken: localStorage.getItem("token"),
        },
      });
      setGrade(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getGrade();
  }, []);

  return (
    <div className="Assingment-main">
      <div className="BackArrowTop" onClick={handleGoBack}>
        <IoArrowBack />
        <h2>Assigment Result Details</h2>
      </div>
      <div className="ToomanyBtns">
        <div className="ProfileName">
          <img
            src={`${baseURL}/images/${student?.profilePicture}`}
            alt="userImge"
            onError={({ currentTarget }) => {
              currentTarget.src = Dummy;
            }}
          />
          <span>
            <h4>
              {student?.firstName}
              {} {student?.lastName}
            </h4>

            <h5>Student ID : {student?.studentId}</h5>
          </span>
        </div>
        <div className="ToomanyBtns-main">
          <button className="ToomanyBtns-one same">
            Result Declared on 12:30 AM | 22 September 2023 <IoCheckmarkDone />
          </button>
          <div className="LinesFOurBtns">
            <button className="SHARE same">Share</button>
            <button className="EXPORT same">
              <RiFileExcel2Line />
              Export Data
            </button>
            <button className="Parent same">Signature Parent</button>
            <button className="FillDown">Download</button>
          </div>
        </div>
      </div>
      {/* <h6>Artificial Intelligence with python Quiz.</h6> */}
      <div className="BSGIS-SECtion">
        <div className="BSGIS-SECtion-child1">
          <button
            className="STATUS"
            style={{
              backgroundColor:
                gradeStyles[
                  grade?.grade?.result === "fail"
                    ? 0
                    : grade?.grade?.result === "miss"
                    ? 1
                    : 2
                ]?.buttonColor,
            }}
          >
            {" "}
            <span
              style={{
                color:
                  gradeStyles[
                    grade?.grade?.result === "fail"
                      ? 0
                      : grade?.grade?.result === "miss"
                      ? 1
                      : 2
                  ]?.labelColor,
              }}
            >
              Status : {grade?.grade?.result}
            </span>
          </button>

          <button className="BATCHCO">{student?.batch}</button>
          <button className="ACtiveCLock">
            <LuClock4 />
            <span>{grade?.grade?.time}</span>
          </button>
          <button className="ActiveCalndr">
            <FaRegCalendarAlt />
            <span>{grade?.grade?.createdAt?.split("T")[0]}</span>
          </button>
          {/* <p className="PARAGRAPG">
            Course :
            <span className="PARAGRAPGSPan">
              Artificial Intelligence with python in Semester 1
            </span>
          </p> */}
          <p>
            Subject : <span>{grade?.grade?.Subject?.subjectName}</span>
          </p>
          <hr />
        </div>
        <div className="BSGIS-SECtion-child2">
          <h3>Improvement your Grade on </h3>
          <img src={Quizet} alt="Quizet Logo" />
          <button>Access app</button>
        </div>
      </div>
      <div className="TotalMarks-Main">
        <div>
          <h2>Total Marks : {grade?.grade?.totalmarkes}</h2>
          <h4>Obtain Marks: {grade?.grade?.obtainmarkes}</h4>
          <div className="progress-container">
            <div
              className="progress-label"
              style={{
                color:
                  gradeStyles[
                    grade?.grade?.result === "fail"
                      ? 0
                      : grade?.grade?.result === "miss"
                      ? 1
                      : 2
                  ]?.progressbarColor,
              }}
            >
              {grade?.grade?.scoredPercentage}%
            </div>
            <progress
              className="mygrade-process-line"
              id={`file-${grade?.grade?.id}`}
              value={grade?.grade?.scoredPercentage}
              max="100"
              style={{
                "--progressbar-color":
                  gradeStyles[
                    grade?.grade?.result === "fail"
                      ? 0
                      : grade?.grade?.result === "miss"
                      ? 1
                      : 2
                  ]?.buttonColor,
                "--progress": grade?.grade?.passingPercentage,
              }}
            >
              {grade?.grade?.passingPercentage}%
            </progress>
            <div className="progress-label2">
              {grade?.grade?.scoredPercentage / 10}/10
            </div>
          </div>
        </div>
        <div>
          <div className="Req_moQuest">
            <Link to="/Request">REQUEST A MOKE-UPS</Link>
          </div>
        </div>
      </div>
      <div className="QuizPaper">
        <div className="Paper">
          <p>Quiz Paper</p>
          <button>Advance Remote Sem Question Paper.pdf</button>
          <h3>Result</h3>
          <p>Student’s Submission</p>
          <button>Networking Mid Sem Question Paper.pdf</button>
        </div>
        <div className="notification">
          <div className="cards">
            <div className="card1">
              <img src={ProfilePic} alt="Ellie Jolieas" />
              <div className="imginfo">
                <h4>Ellie Jolieas</h4>
                <p className="para">04 Dec, 2023 | 04:00 PM</p>
              </div>
            </div>
            <div className="card1 card2">
              <img src={ProfilePic} alt="Teacher Feedback" />
              <div className="imginfo">
                <span>Teacher Feedback</span>
                <h4>Dear Student</h4>
                <p>Need to be more Improve your Assignment in Next Time </p>
              </div>
              <img src={lines} alt="Line" className="lineImage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Assigment;
