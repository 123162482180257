import React, { useEffect, useState } from "react";
import "./Event.css";
import { GrFormNext } from "react-icons/gr";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

const MySchedule = () => {
  const MyscheduleData = [
    {
      dateText: "8",
      heading: "Meeting",
      time: "8th - 10th Dec 2023",
      spanColor: "#00AFEF",
      dateTextColor: "#00AFEF",
      backgroundColor: "#F0F7FF",
    },
    {
      dateText: "13",
      heading: "Axam",
      time: "8th - 10th Dec 2023",
      spanColor: "#FF1D86",
      dateTextColor: "#FF1D86",
      backgroundColor: "#FFF0F7",
    },

    {
      dateText: "18",
      heading: "School Holday",
      time: "8th - 10th Dec 2023",
      spanColor: "#16D03B",
      dateTextColor: "#16D03B",
      backgroundColor: "#F0FFF3",
    },
  ];

  const { schoolId } = useSelector((state) => state?.schoolId);

  const [allEvents, setAllEvents] = useState(null);

  const getAllEvents = async () => {
    try {
      const { data } = await axios.get(`/events/getSchoolEvents/${schoolId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      // events/getSchoolEvents/SchoolId
      setAllEvents(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllEvents();
  }, [schoolId]);
  console.log(allEvents, "ufguyvbkh");
  return (
    <div className="myschedule-main">
      <div className="myschedule-heading">
        <h1>Events</h1>
        <Link to="/DashboardCalender">
          <span>See All</span>
        </Link>
      </div>
      {allEvents &&
        allEvents.events.slice(0, 3).map((item, index) => {
          return (
            <div
              key={index}
              className="myschedule-child"
              style={{
                marginTop: "19px",
                backgroundColor: item?.color || "#FFF0F7",
              }}
            >
              <div className="myschedule-child-data">
                <div
                  className="myschedule-child-date"
                  style={{
                    background: "#00afef",
                  }}
                >
                  <p>{item?.id}</p>
                </div>
                <div className="mychild-myschedule">
                  <div className="myschedule-time">
                    <h6>{item.eventName}</h6>
                    <div className="myschedule-mother">
                      <p>{item.time}</p>
                      <p>
                        {item.startdate?.split("T")[0]}{" "}
                        <span
                          style={{
                            color: "#00afef",
                            fontSize: "14px",
                          }}
                        >
                          ●
                        </span>{" "}
                        {item.enddate?.split("T")[0]}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="myschedule-icon">
                  <Link to="/MyAssignmentSub">
                    <GrFormNext style={{ width: "18px", height: "18px" }} />
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default MySchedule;
