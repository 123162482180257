import {BiSolidSchool} from "react-icons/bi";
import {BsArrowRight} from "react-icons/bs";
import {MdArrowUpward } from 'react-icons/md';
const Vendorsdata =[
    {
        id:1,
        Schoolicon:<BiSolidSchool/>,
        amount:'$250.00',
        arrowIcon:<BsArrowRight/>,
        percentage:"15%*",
        upwardArrowIcon:<MdArrowUpward/>
    },
    {
        id:1,
        Schoolicon:<BiSolidSchool/>,
        amount:'$250.00',
        arrowIcon:<BsArrowRight/>,
        percentage:"15%*",
        upwardArrowIcon:<MdArrowUpward/>
    },
    {
        id:1,
        Schoolicon:<BiSolidSchool/>,
        amount:'$250.00',
        arrowIcon:<BsArrowRight/>,
        percentage:"15%*",
        upwardArrowIcon:<MdArrowUpward/>
    },
    {
        id:1,
        Schoolicon:<BiSolidSchool/>,
        amount:'$250.00',
        arrowIcon:<BsArrowRight/>,
        percentage:"15%*",
        upwardArrowIcon:<MdArrowUpward/>
    },
    {
        id:1,
        Schoolicon:<BiSolidSchool/>,
        amount:'$250.00',
        arrowIcon:<BsArrowRight/>,
        percentage:"15%*",
        upwardArrowIcon:<MdArrowUpward/>
    },
    {
        id:1,
        Schoolicon:<BiSolidSchool/>,
        amount:'$250.00',
        arrowIcon:<BsArrowRight/>,
        percentage:"15%*",
        upwardArrowIcon:<MdArrowUpward/>
    },
    {
        id:1,
        Schoolicon:<BiSolidSchool/>,
        amount:'$250.00',
        arrowIcon:<BsArrowRight/>,
        percentage:"15%*",
        upwardArrowIcon:<MdArrowUpward/>
    },
    {
        id:1,
        Schoolicon:<BiSolidSchool/>,
        amount:'$250.00',
        arrowIcon:<BsArrowRight/>,
        percentage:"15%*",
        upwardArrowIcon:<MdArrowUpward/>
    },
    {
        id:1,
        Schoolicon:<BiSolidSchool/>,
        amount:'$250.00',
        arrowIcon:<BsArrowRight/>,
        percentage:"15%*",
        upwardArrowIcon:<MdArrowUpward/>
    },
    {
        id:1,
        Schoolicon:<BiSolidSchool/>,
        amount:'$250.00',
        arrowIcon:<BsArrowRight/>,
        percentage:"15%*",
        upwardArrowIcon:<MdArrowUpward/>
    },
    {
        id:1,
        Schoolicon:<BiSolidSchool/>,
        amount:'$250.00',
        arrowIcon:<BsArrowRight/>,
        percentage:"15%*",
        upwardArrowIcon:<MdArrowUpward/>
    },
    {
        id:1,
        Schoolicon:<BiSolidSchool/>,
        amount:'$250.00',
        arrowIcon:<BsArrowRight/>,
        percentage:"15%*",
        upwardArrowIcon:<MdArrowUpward/>
    },
]

export default Vendorsdata;