import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./LogoutModal.css";
import { FaRegChartBar } from "react-icons/fa";
import { MdBarChart } from "react-icons/md";
import { MdOutlineGridOn } from "react-icons/md";
import { Link } from "react-router-dom";
import { baseURL } from "../../../constants";
import { useNavigate } from "react-router-dom";
import Dummy from "../../AdminComponents/images/dummy-user.png";
import axios from "axios";
import { getUserProfile } from "../../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
const LogoutModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("refreshToken");
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("token");

    setShow(false);
    dispatch(getUserProfile());
    navigate("/");
    window.location.reload();
  };

  const [student, setStudent] = useState(null);

  const { user, loading, error } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);
  const getStudent = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/students/profile/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setStudent(data?.student);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStudent();
  }, [user]);

  return (
    <>
      <div className="LogoutModal">
        <div className="mainModal">
          <div className="Logo" onClick={handleShow}>
            <img
              style={{
                borderRadius: "100%",
                objectFit: "cover",
              }}
              src={`${baseURL}/images/${student?.profilePicture}`}
              onError={({ currentTarget }) => {
                currentTarget.src = Dummy;
              }}
              alt="#"
            />
          </div>
          <div className="modalContainer">
            <Modal
              show={show}
              onHide={handleClose}
              animation={false}
              className="custom-modal"
            >
              <div className="devSqur"></div>
              <Modal.Header closeButton>
                <Link to="/Myprofile" className="MyProfile_link">
                  <Modal.Title className="title">My Profile</Modal.Title>
                </Link>
              </Modal.Header>
              <Modal.Body className="detlas">
                <ul>
                  <Link to="/MyAppAccess" className="AccountSettig">
                    <FaRegChartBar /> App Access/Analytics
                  </Link>
                  {/* <li>
                    <MdOutlineLocalActivity />
                    Purchase Accessories
                  </li> */}

                  <Link to="/ReportCard" className="AccountSettig">
                    <MdOutlineGridOn /> My Report Card
                  </Link>
                  <li className="upGrade">
                    <MdBarChart />
                    Upgrade Your Avatar
                  </li>
                  <Link to="/Setting" className="AccountSettig">
                    Account Setting
                  </Link>
                  <li onClick={handleLogout}>Log Out</li>
                </ul>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default LogoutModal;
