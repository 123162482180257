import React from "react";
import ADDdatausagepage from "../ADDdataUsage/ADDdatausagepage";
import ADDdatausedpage from "../ADDdataused/ADDdatausedpage";

const ADDdatausagepages = () => {
  return (
    <div>
      <ADDdatausagepage />
      <ADDdatausedpage />
    </div>
  );
};

export default ADDdatausagepages;
