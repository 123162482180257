import React from "react";
import { ImRadioChecked } from "react-icons/im";
import "./MyAssingmentSub.css";
import { RiGridFill } from "react-icons/ri";
import { FiGrid } from "react-icons/fi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const colors = {
  "new Assignment": {
    bordercolor: "#00AFEF",
    backgroundcolor: "#00AFEF",
    descriptionColor: "#00AFEF",
    buttonBgColor: "#00AFEF",
    buttonTextColor: "#00AFEF",
    button: "Already submitted",
    spandotColor: "#00AFEF",
    bctextColor: "#4E5566",
  },
  "Due soon": {
    bordercolor: "#FF7E3E",
    backgroundcolor: "#FF7E3E",
    descriptionColor: "#FF7E3E",
    buttonTextColor: "#FF7E3E",
    button: "Already submitted",
    spandotColor: "#FF7E3E",
    bctextColorr: "#4E5566",
  },
  "OverDue Today": {
    bordercolor: "#F93333",
    backgroundcolor: "#F93333",
    descriptionColor: "#F93333",
    buttonTextColor: "#F93333",
    button: "Submit assignment",
    spandotColor: "#F93333",
    bctextColor: "#4E5566",
  },
  submitted: {
    bordercolor: "#16D03B",
    background: "#16D03B",
    descriptionColor: "#16D03B",
    buttonTextColor: "#16D03B",
    button: "Already submitted",
    spandotColor: "#16D03B",
    bctextColor: "#4E5566",
  },
};

function MyAssingmentSub({ assignments }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const myStyles = [
    {
      bordercolor: "#F93333",
      backgroundcolor: "rgba(249,51,51,0.11)",
      buttonbackgroundcolor: "rgba(249,51,51,0.11)",
      descriptionColor: "#F93333",
      buttonTextColor: "#F93333",
      button: "Submit assignment",
      spandotColor: "#F93333",
      bctextColor: "#F93333",
    },
    {
      bordercolor: "#FF7E3E",
      backgroundcolor: "rgba(22,208,59,0.14)",
      buttonbackgroundcolor: "rgba(22,208,59,1)",
      descriptionColor: "#FF7E3E",
      buttonTextColor: "rgba(249, 51, 51, 1)",
      button: "Submit assingment",
      spandotColor: "#FF7E3E",
      bctextColor: "#4E5566",
    },
    {
      bordercolor: "#16D03B",
      backgroundcolor: "rgba(240,255,243,0.14)",
      buttonbackgroundcolor: "rgba(240,255,243,1)",
      descriptionColor: "#16D03B",
      buttonTextColor: "#16D03B",
      spandotColor: "#16D03B",
      button: "Already submitted",
      bctextColor: "#16D03B",
    },
    {
      bordercolor: "#00AFEF",
      backgroundcolor: "rgba(0,175,239,0.15)",
      buttonbackgroundcolor: "rgba(0,175,239,0.15)",
      descriptionColor: "#00AFEF",
      buttonBgColor: "#00AFEF",
      buttonTextColor: "#00AFEF",
      spandotColor: "#00AFEF",
      bctextColor: "#00AFEF",
    },
  ];

  return (
    <div className="Parentmyassingment-main">
      <div className="myassingment-child-1">
        <div className="Parentassigment">
          <h3>Assigments</h3>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <RiGridFill />
            <FiGrid className="gray" />
          </div>
        </div>
        <div className="myassingmentB">
          <div className="myassingment-radio-btn">
            <span></span>
            <p>OverDue</p>
            <ImRadioChecked
              style={{ color: "#f93333", width: "19px", height: "19px" }}
            />
          </div>
          <div className="myassingment-radio-btn">
            <span></span>
            <p>Due Soon</p>
            <ImRadioChecked
              style={{ color: "#ff7e3e", width: "19px", height: "19px" }}
            />
          </div>
          <div className="myassingment-radio-btn">
            <span></span>
            <p>Submitted</p>
            <ImRadioChecked
              style={{ color: "#16d03b", width: "19px", height: "19px" }}
            />
          </div>
          <div className="myassingment-radio-btn">
            <span></span>
            <p>New</p>
            <ImRadioChecked
              style={{ color: "#00afef", width: "19px", height: "19px" }}
            />
          </div>
        </div>
      </div>
      <div>
        {assignments &&
          assignments.map((assignment, index) => {
            const newIndex = index % myStyles.length;
            return (
              <div
                className="myassingment-child-2"
                key={index}
                style={{ marginTop: "10px" }}
              >
                <div
                  className="myassingment-card"
                  style={{
                    backgroundColor: myStyles[newIndex]?.backgroundcolor,
                    borderColor: myStyles[newIndex]?.bordercolor,
                  }}
                >
                  <div className="myassingment-card-child-1">
                    <div className="myassingment-child">
                      <div className="myassingment-child-data">
                        <div className="myassingment-child-data">
                          <p
                            className="date"
                            style={{
                              colors: myStyles[newIndex]?.bcColor,
                              backgroundColor:
                                myStyles[newIndex]?.buttonTextColor,
                            }}
                          >
                            {assignment?.id}
                          </p>
                        </div>
                        <div className="mychild-myassingment">
                          <h6
                            style={{
                              color: myStyles[newIndex]?.descriptionColor,
                            }}
                          >
                            {assignment?.title}
                          </h6>
                          <div className="myassingment-time">
                            <div className="myassingment-mother">
                              <p>{assignment?.dueOn?.split("T")[0]}</p>
                              <span
                                style={{
                                  color: myStyles[newIndex]?.spandotColor,
                                }}
                              >
                                ●
                              </span>
                              <p>{assignment?.createdAt?.split("T")[0]}</p>
                            </div>
                            <p style={{ fontSize: "8px" }}></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="myassingment-card-child-2">
                    <div className="description">
                      <h5
                        style={{ color: myStyles[newIndex]?.buttonTextColor }}
                      >
                        {assignment?.description}
                      </h5>
                    </div>
                    <div className="dov">
                      <h4
                        style={{ color: myStyles[newIndex]?.buttonTextColor }}
                      >
                        {assignment?.type}
                      </h4>

                      <div>
                        <button
                          style={{
                            borderColor: myStyles[newIndex]?.bctextColor,
                            color: myStyles[newIndex]?.buttonTextColor,
                          }}
                          onClick={handleOpen}
                        >
                          Already Submitted
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default MyAssingmentSub;
