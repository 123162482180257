import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FaEdit } from "react-icons/fa";
import "../Myprofile.css";
import TuitionInfo from "../../Tuition/TuitionInfo";
import toast from "react-hot-toast";
import axios from "axios";
import { useSelector } from "react-redux";
import IeProgram from "../../IEProgram/IeProgram";

function TwoTabs({ profile, getStudentProfile }) {
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [joinedOn, setJoinedOn] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState("");
  const [loginEmailAddress, setLoginEmailAddress] = useState("");
  // eslint-disable-next-line
  const [centerContact, setCenterContact] = useState("");
  // eslint-disable-next-line
  const [centerEmail, setCenterEmail] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [district, setDistrict] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  console.log({
    firstName,
    dateOfBirth,
    phoneNumber,
    emergencyPhoneNumber,
    email: loginEmailAddress,
    centerContact,
    centerEmail,
    country: state,
    district: district,
    zipOrPostalCode: pinCode,
    address1: address,
  });
  const handleSubmitFirst = async () => {
    try {
      const fName = firstName?.split(" ")[0] || "";
      const mName = firstName?.split(" ")[1] || "";
      const lName = firstName?.split(" ")[2] || "";

      await axios.put(
        `/students/updateAddressInfo/${user?.dataValues?.id}`,
        {
          firstName: fName,
          middleName: mName,
          lastName: lName,
          dateOfBirth,
          phoneNumber,
          emergencyPhoneNumber,
          email: loginEmailAddress,
          centerContact,
          centerEmail,
          country: state,
          district: district,
          zipOrPostalCode: pinCode,
          address1: address,
        },
        {
          headers: {
            "Content-Type": "application/json",
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Profile Updated Successfullyy");
      getStudentProfile();
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(
          error?.response?.data?.message || error?.response?.data?.error
        );
      }
    }
  };
  const [highestQualification, setHighestQualification] = useState("");
  const [nameOfInstitution, setNameOfInstitution] = useState("");
  const [yearOfQualification, setYearOfQualitication] = useState("");
  const [studentId, setStudentId] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [profession, setProfession] = useState("");
  const [nameOfOrganization, setNameOfOrganization] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [fatherWhatsApp, setFatherWhatsapp] = useState("");
  const [fatherEmail, setFatherEmail] = useState("");
  const [motherName, setMotherName] = useState("");
  const [motherProfession, setMotherProfession] = useState("");

  const { user } = useSelector((state) => state.getUserProfile);
  const handleSubmitThird = async () => {
    try {
      await axios.put(
        `/students/updateProfile/${user?.dataValues?.id}`,
        {
          fatherName,
          fatherProfession: profession,
          nameOfOrganization,
          fatherContact: contactNumber,
          fatherWhatsApp,
          fatherEmail,
          motherName,
          motherProfession,
        },
        {
          headers: {
            "Content-Type": "application/json",
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Updated Successfully");
      getStudentProfile();
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else if (error?.response?.data?.error) {
        toast.error(
          error?.response?.data?.error[0]?.error ||
            error?.response?.data?.error[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const handleSubmitSecond = async () => {
    try {
      await axios.put(
        `/students/updateProfile/${user?.dataValues?.id}`,
        {
          highestQualification,
          nameOfInstitution,
          yearOfQualification,
          studentId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Updated Successfully");
      getStudentProfile();
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else if (error?.response?.data?.error) {
        toast.error(
          error?.response?.data?.error[0]?.error ||
            error?.response?.data?.error[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  return (
    <div className="TwoTabs-Main">
      <Tabs
        defaultActiveKey="home"
        id="uncontrolled-tab-example"
        className="mb-3 profileTabs"
      >
        <Tab eventKey="home" title="Profile" className="profileTab">
          <div className="Myprofile-main-2">
            <div className="edit-icon">
              <FaEdit onClick={() => setFirst(!first)} />
            </div>
            <div className="Personal-Details">
              <div className="Personal-Details-child-1">
                <h1>Personal Details</h1>
                <h3>
                  Name :{" "}
                  {!first ? (
                    <span>{profile?.firstName + " " + profile?.lastName}</span>
                  ) : (
                    <input
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="Name"
                    />
                  )}
                </h3>
                <h3>
                  Date of Joining:{" "}
                  {!first ? (
                    <span>{profile?.loggedInSince?.split("T")[0]}</span>
                  ) : (
                    <input
                      value={joinedOn}
                      onChange={(e) => setJoinedOn(e.target.value)}
                      placeholder="Date of Joining"
                    />
                  )}
                </h3>
                <h3>
                  Date of Birth :{" "}
                  {!first ? (
                    <span>{profile?.dateOfBirth?.split("T")[0]}</span>
                  ) : (
                    <input
                      value={dateOfBirth}
                      onChange={(e) => setDateOfBirth(e.target.value)}
                      placeholder="Date of Birth"
                    />
                  )}
                </h3>
                <h3>
                  Contact Number :{" "}
                  {!first ? (
                    <span>{profile?.phoneNumber}</span>
                  ) : (
                    <input
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      placeholder="Contact Number"
                    />
                  )}
                </h3>
                <h3>
                  Emergency Contact Number :{" "}
                  {!first ? (
                    <span>{profile?.emergencyPhoneNumber}</span>
                  ) : (
                    <input
                      value={emergencyPhoneNumber}
                      onChange={(e) => setEmergencyPhoneNumber(e.target.value)}
                      placeholder="Emergency Contact Number"
                    />
                  )}
                </h3>
                <h3>
                  Email Address :{" "}
                  {!first ? (
                    <span>{profile?.loginEmailAddress}</span>
                  ) : (
                    <input
                      value={loginEmailAddress}
                      onChange={(e) => setLoginEmailAddress(e.target.value)}
                      placeholder="Email Address"
                    />
                  )}
                </h3>
                <h2>Education Details</h2>
              </div>
              <div className="Personal-Details-child-2">
                <h2>Address Details</h2>
                <h3>
                  Pincode :{" "}
                  {!first ? (
                    <span>{profile?.pinCode}</span>
                  ) : (
                    <input
                      value={pinCode}
                      onChange={(e) => setPinCode(e.target.value)}
                      placeholder="Pin Code"
                    />
                  )}
                </h3>
                <h3>
                  District:{" "}
                  {!first ? (
                    <span>{profile?.district}</span>
                  ) : (
                    <input
                      value={district}
                      onChange={(e) => setDistrict(e.target.value)}
                      placeholder="District"
                    />
                  )}{" "}
                </h3>
                <h3>
                  State :{" "}
                  {!first ? (
                    <span>{profile?.country}</span>
                  ) : (
                    <input
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      placeholder="State"
                    />
                  )}{" "}
                </h3>
                <h3>
                  Address :{" "}
                  {!first ? (
                    <span>{profile?.address1}</span>
                  ) : (
                    <input
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      placeholder="Address"
                    />
                  )}
                </h3>
              </div>
              {first && (
                <button
                  style={{
                    alignSelf: "flex-end",
                    padding: ".3rem 1rem",
                    borderRadius: "12px",
                    border: "none",
                    backgroundColor: "#00AFEF",
                    color: "white",
                    fontWeight: "600",
                  }}
                  onClick={handleSubmitFirst}
                >
                  Save Changes
                </button>
              )}
            </div>
            <hr className="line-hr-two" />
            <div className="edit-icon" style={{ paddingTop: "5px" }}>
              <FaEdit onClick={() => setSecond(!second)} />
            </div>
            <div className="Personal-Details-child-3">
              <h3>
                Highest Qualification :{" "}
                {!second ? (
                  <span>{profile?.highestQualification}</span>
                ) : (
                  <input
                    value={highestQualification}
                    onChange={(e) => setHighestQualification(e.target.value)}
                    placeholder="Highest Qualification"
                  />
                )}
              </h3>
              <h3>
                Name of institution :{" "}
                {!second ? (
                  <span>{profile?.nameOfInstitution}</span>
                ) : (
                  <input
                    value={nameOfInstitution}
                    onChange={(e) => setNameOfInstitution(e.target.value)}
                    placeholder="Name of instituition"
                  />
                )}
              </h3>
              <h3>
                Year of Qualification :{" "}
                {!second ? (
                  <span>{profile?.yearOfQualification}</span>
                ) : (
                  <input
                    value={yearOfQualification}
                    onChange={(e) => setYearOfQualitication(e.target.value)}
                    placeholder="Year of qualification"
                  />
                )}
              </h3>
              <h3>
                Student Id :{" "}
                {!second ? (
                  <span>{profile?.studentId}</span>
                ) : (
                  <input
                    value={studentId}
                    onChange={(e) => setStudentId(e.target.value)}
                    placeholder="Student Id"
                  />
                )}
              </h3>
              {second && (
                <button
                  onClick={handleSubmitSecond}
                  style={{
                    alignSelf: "flex-end",
                    padding: ".3rem 1rem",
                    borderRadius: "12px",
                    border: "none",
                    backgroundColor: "#00AFEF",
                    color: "white",
                    fontWeight: "600",
                  }}
                >
                  Save Changes
                </button>
              )}
              <h1>Family Details</h1>
            </div>
            <hr className="line-hr-two" />
            <div className="edit-icon" style={{ paddingTop: "5px" }}>
              <FaEdit onClick={() => setThird(!third)} />
            </div>
            <div className="Personal-Details-child-41">
              <h3>
                Father Name :{" "}
                {!third ? (
                  <span>{profile?.fatherName}</span>
                ) : (
                  <input
                    value={fatherName}
                    onChange={(e) => setFatherName(e.target.value)}
                    placeholder="Father Name"
                  />
                )}
              </h3>
              <h3>
                Profession :{" "}
                {!third ? (
                  <span>{profile?.fatherProfession}</span>
                ) : (
                  <input
                    value={profession}
                    onChange={(e) => setProfession(e.target.value)}
                    placeholder="Profession"
                  />
                )}
              </h3>
              <h3>
                Name of Organization:{" "}
                {!third ? (
                  <span>{profile?.nameOfOrganization}</span>
                ) : (
                  <input
                    value={nameOfOrganization}
                    onChange={(e) => setNameOfOrganization(e.target.value)}
                    placeholder="Name of organization"
                  />
                )}
              </h3>
              <h3>
                Contact Number :{" "}
                {!third ? (
                  <span>{profile?.fatherContact}</span>
                ) : (
                  <input
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                    placeholder="Contact Number"
                  />
                )}
              </h3>
              <h3>
                WhatsApp Number :{" "}
                {!third ? (
                  <span>{profile?.fatherWhatsApp}</span>
                ) : (
                  <input
                    value={fatherWhatsApp}
                    onChange={(e) => setFatherWhatsapp(e.target.value)}
                    placeholder="Whatsapp Number"
                  />
                )}
              </h3>
              <h3>
                Email Address :{" "}
                {!third ? (
                  <span>{profile?.fatherEmail}</span>
                ) : (
                  <input
                    value={fatherEmail}
                    onChange={(e) => setFatherEmail(e.target.value)}
                    placeholder="Email Address"
                  />
                )}
              </h3>
              <h3>
                Mother Name :{" "}
                {!third ? (
                  <span>{profile?.motherName}</span>
                ) : (
                  <input
                    value={motherName}
                    onChange={(e) => setMotherName(e.target.value)}
                    placeholder="Mother Name"
                  />
                )}{" "}
              </h3>
              <h3>
                Profession :{" "}
                {!third ? (
                  <span>{profile?.motherProfession}</span>
                ) : (
                  <input
                    value={motherProfession}
                    onChange={(e) => setMotherProfession(e.target.value)}
                    placeholder="Profession"
                  />
                )}
              </h3>
              {third && (
                <button
                  onClick={handleSubmitThird}
                  style={{
                    alignSelf: "flex-end",
                    padding: ".3rem 1rem",
                    borderRadius: "12px",
                    border: "none",
                    backgroundColor: "#00AFEF",
                    color: "white",
                    fontWeight: "600",
                    marginBlock: ".9rem",
                  }}
                >
                  Save Changes
                </button>
              )}
            </div>
          </div>
        </Tab>
        <Tab eventKey="profile" title="Tution info" className="profileTab">
          <TuitionInfo />
        </Tab>
        <Tab eventKey="IE Program" title="IE Program" className="profileTab">
          <IeProgram />
        </Tab>
      </Tabs>
    </div>
  );
}

export default TwoTabs;
