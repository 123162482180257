import React from "react";
import "./VendorsOverview.css";
import { CiSearch } from "react-icons/ci";
import { RiEqualizerFill } from "react-icons/ri";
import VendorChart from "./VendorChart";
import spotify from "./images/logo-spotify.png";

const VendorsOverview = () => {
  return (
    <div className="VendorsOverview">
      <div className="VendorSpecificSearch">
        <div className="inputfilter">
          <div className="input">
            <CiSearch />
            <input type="text" placeholder="Vendor Specific Search" />
          </div>
          <button className="filterBtn">
            <RiEqualizerFill /> Filter
          </button>
        </div>
        <button className="AddVendorBtn">Add New Vendor</button>
      </div>
      <div className="VendorsChart">
        <VendorChart />
      </div>
      <div className="VendorScore">
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Vendors</th>
              <th>Vendor Score</th>
              <th>Centrality Score </th>
              <th>Needs</th>
              <th>Improvement %</th>
            </tr>

          </thead>
          <tbody>
            <tr className="VendorScoreBackGroung">
              <td>01</td>
              <td className="spotifyIcon">
                <img src={spotify} alt="Logo" /> Fleece Marigold
              </td>
              <td className="progressPercentages">
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "25%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    25%
                  </div>
                </div>
              </td>
              <td>100%</td>
              <td>abc</td>
              <td>Reading</td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VendorsOverview;
