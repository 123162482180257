import React, { useEffect, useState } from "react";
import group_ui from "../../AdminComponents/ADDNestedMain/detailModal/Group 1000006052.png";
import axios from "axios";
import { useSelector } from "react-redux";
import { baseURL } from "../../../constants";
axios.defaults.baseURL = baseURL;

const Notice = () => {
  // /noticeboards/getNoticeBoardBySchoolId/schoolId
  const [notice, setNotice] = useState([]);

  const { user } = useSelector((state) => state.getUserProfile);

  const getNotice = async () => {
    try {
      if (user?.dataValues?.id) {
        const { data } = await axios.get(
          `/ParentBySchoolId/getschoolBystudentId/${user?.dataValues?.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        const res = await axios.get(
          `/notices/getCurrentNoticesBySchool/${data.school.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setNotice(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getNotice();
  }, [user]);
  console.log(notice, "This is Noticeboard");
  return (
    <div className="NoticeTeacher">
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <div className="School-Calander-main-child-2">
          {notice?.notices?.map((not) => {
            return (
              <>
                <div className="School-Calander-main-child-2-child-1 ThreeDOtsNotice">
                  <h1>{not?.title}</h1>
                </div>
                <div className="School-Calander-main-child-2-child-2">
                  <div className="school-calander-child-2-data">
                    <p>“{not?.content}“</p>
                  </div>
                  <div>
                    <div className="bosdii-lala-2">
                      <img src={group_ui} alt="" />
                      <p>
                        Visible from {not?.validFrom?.split("T")[0]} -{" "}
                        {not?.validTo?.split("T")[0]}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Notice;
