import React, { useEffect, useState } from "react";
import {
  MdOutlineNavigateNext,
  MdOutlineLocalPhone,
  MdKeyboardBackspace,
  MdOutlineMailOutline,
} from "react-icons/md";
import "./Request.css";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { baseURL } from "../../../constants";
import { getUserProfile } from "../../../redux/actions/userAction";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import happy from "../images/happy.png";
import Dummy from "../../AdminComponents/images/dummy-user.png";
axios.defaults.baseURL = baseURL;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Request = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  const [teacherId, setTeacherId] = useState("");
  const getTeacherByClassroom = async () => {
    try {
      const { data } = await axios.get(
        `/classrooms/getTeacherByClassroomId/${localStorage.getItem(
          "classroomId"
        )}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setTeacherId(data?.id);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTeacherByClassroom();
  }, []);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const [studentName, setStudentName] = useState("");
  const [department, setDepartment] = useState("");
  const [subject, setSubject] = useState("");
  const [day, setDay] = useState("");
  const [time, setTime] = useState("");
  const [hours, setHours] = useState("");
  const [details, setDetails] = useState("");
  const [studentSign, setStudentSign] = useState("");

  const [student, setStudent] = useState(null);

  const getStudentProfile = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/students/profile/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setStudent(data.student);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!user) {
      dispatch(getUserProfile());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user) {
      getStudentProfile();
    }
  }, [user]);
  const handleAddMockUp = async () => {
    try {
      const formData = new FormData();
      formData.append("studentName", studentName);
      formData.append("department", department);
      formData.append("subject", subject);
      formData.append("day", day);
      formData.append("time", time);
      formData.append("hours", hours);
      formData.append("details", details);
      formData.append("studentSign", studentSign);
      formData.append("studentId", user.dataValues.id);
      formData.append("teacherId", teacherId);
      await axios.post(`/makeUpRequests/createMakeupRequest`, formData, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Mockup Added Successfully");
      handleOpen();
      setStudentName("");
      setDepartment("");
      setSubject("");
      setDay("");
      setTime("");
      setHours("");
      setDetails("");
      setStudentSign("");
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details?.[0].message ||
            error?.response?.data?.details?.[0].error
        );
      } else if (error?.response?.data?.error) {
        toast.error(
          error?.response?.data?.error?.[0].message ||
            error?.response?.data?.error?.[0].error
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="Request-Min">
      <div className="MakeRequest">
        <h2>Make up request</h2>
        <div className="ClassRoom">
          <p>Home</p>
          <span>
            <MdOutlineNavigateNext />
          </span>
          <p>Classroom</p>
          <span>
            <MdOutlineNavigateNext />
          </span>
          <p className="span">Make up request</p>
        </div>
      </div>
      <div className="Request">
        <div className="backBtton">
          <span onClick={handleGoBack}>
            <MdKeyboardBackspace />
          </span>
          <h3>Make-Up Time Request For Quiz</h3>
        </div>
        <div className="profile">
          <div className="img">
            <img
              style={{
                objectFit: "contain",
              }}
              src={`${baseURL}/images/${student?.profilePicture}`}
              alt="userImge"
              onError={({ currentTarget }) => {
                currentTarget.src = Dummy;
              }}
            />
            <div className="info">
              <h4>
                {student?.firstName} {student?.middleName} {student?.lastName}
              </h4>
              <p>Student ID : {student?.studentId}</p>
            </div>
          </div>
          <div className="imgInFo">
            <h5>Center Contact</h5>
            <p>
              <MdOutlineLocalPhone />
              {student?.phoneNumber}
            </p>
            <p>
              <MdOutlineMailOutline />
              {student?.loginEmailAddress}
            </p>
          </div>
        </div>
        <form action="">
          <div className="inputs">
            <div className="input">
              <label htmlFor="text">Student Name</label>
              <input
                value={studentName}
                onChange={(e) => setStudentName(e.target.value)}
                type="text"
                placeholder="Name...."
              />
            </div>
            <div className="input">
              <label htmlFor="text">Department</label>
              <input
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
                type="text"
                placeholder="Department/class...."
              />
            </div>
            <div className="input">
              <label htmlFor="text">Subject</label>
              <input
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                type="text"
                placeholder="English......"
              />
            </div>
          </div>

          <div className="inputs">
            <div className="input">
              <label htmlFor="day">Day</label>
              <input
                value={day}
                onChange={(e) => setDay(e.target.value)}
                type="day"
                placeholder="Label"
              />
            </div>
            <div className="input">
              <label htmlFor="time">Time</label>
              <input
                value={time}
                onChange={(e) => setTime(e.target.value)}
                type="time"
                placeholder="12:30"
              />
            </div>
            <div className="input">
              <label htmlFor="hours">Hours</label>
              <input
                value={hours}
                onChange={(e) => setHours(e.target.value)}
                type="hours"
                placeholder="1 Hours"
              />
            </div>
          </div>
          <div className="Details">
            <label htmlFor="Details">Details</label>
            <textarea
              name="Details"
              id="Details"
              cols="30"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              rows="10"
              placeholder="Detail Why You Mis the Assignment/Quiz........"
            ></textarea>
          </div>
        </form>
        <div className="understand">
          <h2>I understand that:</h2>
          <ul>
            <li>
              Any make-up time I work will not be paid at an overtime rate.
            </li>
            <li>
              A separate written request is rquest for each quiz that I request
              make-up time
            </li>
            <li>
              Any make-up time I work will not be paid at an overtime rate.
            </li>
            <li>
              A separate written request is rquest for each quiz that I request
              make-up time
            </li>
            <li>
              Any make-up time I work will not be paid at an overtime rate.
            </li>
            <li>
              A separate written request is rquest for each quiz that I request
              make-up time
            </li>
          </ul>
        </div>
        <div className="inputs">
          <div className="input inputss">
            <input
              value={studentSign}
              onChange={(e) => setStudentSign(e.target.value)}
              type="text"
            />
            <input type="text" />
          </div>
          <div className="label">
            <label htmlFor="">Student Signature</label>
            <div className="btnS">
              <button className="BtnsCancel">Cancel</button>
              <button
                onClick={() => {
                  handleAddMockUp();
                }}
                className="BtnsSend"
              >
                Send a request
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="Neted_Modalll">
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="TestSubModal">
              <img src={happy} />
              <h3>You have successfully sent request to chat!</h3>
              <button onClick={handleClose} className="TestSubmitBTN">
                Done
              </button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default Request;
