import React, { useEffect, useState } from "react";
import { MdOutlineCall } from "react-icons/md";
import { LuMail } from "react-icons/lu";
import "../../StudentComponents/MyProfile/Myprofile.css";
import axios from "axios";
import { baseURL } from "../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

function PersonalDetails() {
  const [studentProfile, setStudentProfile] = useState(null);

  const { studentId } = useSelector((state) => state.studentId);
  const getStudentProfile = async () => {
    try {
      const { data } = await axios.get(`/students/profile/${studentId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });

      setStudentProfile(data?.student);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStudentProfile();
  }, [studentId]);

  return (
    <div>
      <div
        className="Myprofile-main-2"
        style={{
          backgroundColor: "white",
          margin: "20px 0px",
        }}
      >
        <div className="Personal-Details">
          <div className="Personal-Details-child-1">
            <h1>Personal Details</h1>
            <h3>
              Name :{" "}
              <span>
                {studentProfile?.firstName} {studentProfile?.middleName}{" "}
                {studentProfile?.lastName}
              </span>
            </h3>
            <h3>
              Date of Joining :{" "}
              <span>{studentProfile?.createdAt?.split("T")[0]}</span>
            </h3>
            <h3>
              Date of Birth :{" "}
              <span>{studentProfile?.dateOfBirth?.split("T")[0]}</span>
            </h3>


            <h3>
              Contact Number : <span>{studentProfile?.phoneNumber}</span>
            </h3>
            <h3>
              Emergency Contact Number :{" "}
              <span>{studentProfile?.emergencyPhoneNumber}</span>
            </h3>
            <h3>
              Email Address : <span>{studentProfile?.email}</span>
            </h3>
          </div>
          <div className="Personal-Details-child-3">
            <h2>Center Contact</h2>
            <h3>
              <MdOutlineCall
                style={{ height: "20px", width: "20px", marginRight: "10px" }}
              />
              {studentProfile?.centerContact}
            </h3>
            <h3>
              <LuMail
                style={{ height: "20px", width: "20px", marginRight: "10px" }}
              />
              {studentProfile?.centerEmail}
            </h3>
          </div>
          <div className="Personal-Details-child-3">
            <h2>Address Detail</h2>
            <h3>Pincode : {studentProfile?.pinCode}</h3>
            <h3>District Id: {studentProfile?.districtId} </h3>
            <h3>Address : {studentProfile?.address}</h3>
          </div>
        </div>


        <hr className="line-hr-two" />
        <div className="Personal-Details-child-3" style={{ margin: "10px" }}>
          <h2>Education Details</h2>
          <h3>
            Highest Qualification : {studentProfile?.highestQualification}
          </h3>
          <h3>Name of institution : {studentProfile?.nameOfInstitution}</h3>
          <h3>Year of Qualification : {studentProfile?.yearOfQualification}</h3>
          <h3>Student Id: {studentProfile?.studentId}</h3>
        </div>
        <hr className="line-hr-two" />
        <div className="Personal-Details-child-3">
          <h1>Family Details</h1>
          <h3>
            Father Name : <span>{studentProfile?.fatherName}</span>
          </h3>
          <h3>
            Profession : <span>{studentProfile?.fatherProfession}</span>
          </h3>
          <h3>
            Name of Organization :{" "}
            <span>{studentProfile?.nameOfOrganization}</span>
          </h3>
          <h3>
            Contact Number : <span>{studentProfile?.fatherContact}</span>
          </h3>
          <h3>
            WhatsApp Number : <span>{studentProfile?.fatherWhatsApp}</span>
          </h3>
          <h3>
            Emergency Number :{" "}
            <span>{studentProfile?.emergencyPhoneNumber}</span>
          </h3>
          <h3>
            Email Address : <span>{studentProfile?.fatherEmail}</span>
          </h3>
          <h3>
            Mother's Name : <span>{studentProfile?.motherName}</span>
          </h3>
          <h3>
            Profession : <span>{studentProfile?.motherProfession}</span>
          </h3>
        </div>
      </div>
    </div>
  );
}

export default PersonalDetails;
