import React, { useEffect, useState } from "react";
import "./MyGrade.css";
import Line from "./Group 1000006264.png";
import men from "../../../../StudentComponents/images/Shoaib.png";
import StudentGrading from "../TeacherStuGrading/StudentGrading";
import { IoArrowBackOutline } from "react-icons/io5";
import axios from "axios";
import { useParams } from "react-router-dom";

const MyGradeSectionTwo = ({
  myGrade = {},
  getGrades = () => {},
  gradeId = "",
  setShowStudentsGrades = () => {},
  getGpa = () => { },
  getMyGrade=()=>{}
}) => {
  const gradeStyles = [
    {
      result: "failed",
      labelColor: "#fff",
      buttonColor: "#f93333",
      progressbarColor: "#f93333",
      gradebc: "#ffefef",
    },
    {
      result: "missed",
      buttonColor: "#9A9A9A",
      labelColor: "#9A9A9A",
      progressbarColor: "#9A9A9A",
      gradebc: "#E6E6E6",
    },
    {
      result: "passed",
      buttonColor: "#11A529",
      labelColor: "white",
      progressbarColor: "#11A529",
      gradebc: "#Effff5",
    },
  ];
  const { studentId } = useParams();

  const [student, setStudent] = useState("");

  const getStudent = async () => {
    try {
      const { data } = await axios.get(
        `/students/profile/${
          studentId === ":studentId"
            ? localStorage.getItem("teacherStudent")
            : studentId
        }`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setStudent(data?.student);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStudent();
  }, []);

  return (
    <div className="MyGradeSectionTwo">
      <div className="AdjustGrade">
        <h3>
          <span>
            <IoArrowBackOutline onClick={() => setShowStudentsGrades(false)} style={{cursor:'pointer'}}/>
          </span>
          {student?.program}
        </h3>

        <button>
          <StudentGrading
            getGpa={getGpa}
            gradeId={gradeId}
            getMyGrade={getMyGrade}
            getGrades={getGrades}
            myGrade={myGrade}
          />
        </button>
      </div>
      <div className="statusRow">
        <h5
          style={{
            backgroundColor:
              gradeStyles[
                myGrade?.result === "fail"
                  ? 0
                  : myGrade?.result === "miss"
                  ? 1
                  : 2
              ]?.buttonColor,
          }}
        >
          <span
            style={{
              color:
                gradeStyles[
                  myGrade?.result === "fail"
                    ? 0
                    : myGrade?.result === "miss"
                    ? 1
                    : 2
                ]?.labelColor,
            }}
          >
            Status : {myGrade?.result}
          </span>
        </h5>
        <button>{student?.batch}</button>
        <input readOnly value={myGrade?.time} type="time" />
        <input
          readOnly
          value={myGrade?.date ? myGrade?.date?.split("T")[0] : ""}
          type="date"
        />
      </div>
      <div className="course">
        <p>Title:</p>
        <h4>{myGrade?.title}</h4>
      </div>
      <div className="course coursetwo">
        <p>Subject:</p>
        <h4>{myGrade?.Subject?.subjectName}</h4>
      </div>

      <div className="totalNotification">
        <div className="totalMarks">
          <h3>Total Percentage : 100%</h3>
          <p className="PassingPercentage">
            Scored Percentage : {myGrade?.scoredPercentage}%
          </p>
          <div className="progressBar">

            <p
              style={{
                color:
                  gradeStyles[
                    myGrade?.result === "fail"
                      ? 0
                      : myGrade?.result === "miss"
                      ? 1
                      : 2
                  ]?.buttonColor,
              }}
            >
              {myGrade?.scoredPercentage}%
            </p>
            <div className="ProgressMain">
              <div className="progressOut">
                <div
                  style={{
                    width: `${myGrade?.scoredPercentage}%`,
                    backgroundColor:
                      gradeStyles[
                        myGrade?.result === "fail"
                          ? 0
                          : myGrade?.result === "miss"
                          ? 1
                          : 2
                      ]?.buttonColor,
                  }}
                  className="ProgressIn"
                ></div>
              </div>
              <p>
                {myGrade?.scoredPercentage}/100
              </p>
            </div>
          </div>
          <h5>Quiz Paper</h5>
          <button className="AdvanceRemoteBtn">
            Advance Remote Sem Question Paper.pdf
          </button>
          <h3 className="Result">Result</h3>
          <h6>Student’s Submission</h6>
          <button className="AdvanceRemoteBtn">
            Networking Mid Sem Question Paper.pdf
          </button>
        </div>
        <div className="notification">
          <div className="notificationCard">
            <img src={men} alt="profileImage" />
            <div className="imgInfo">
              <h2>Ellie Jolieas</h2>
              <p>04 Dec, 2023 | 04:00 PM</p>
            </div>
          </div>
          <div className="notificationCard secondnotificationCard">
            <img src={men} alt="profileImage" />
            <div className="imgInfo">
              <p>Teacher Feedback</p>
              <h2>Ellie Jolieas</h2>
              <textarea placeholder="Write a feedback tp student"></textarea>
            </div>
            <img className="LIneImage" src={Line} alt="LineImage" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyGradeSectionTwo;