import { BsFillHandbagFill } from "react-icons/bs";
import { BiSolidJoystick } from 'react-icons/bi';
import { PiStudentBold }  from "react-icons/pi";
import { FaSchool } from 'react-icons/fa6';
import {MdOutlineFeed} from "react-icons/md"
const Mapcarddata =[
    {
        id:1,
        icons:<BsFillHandbagFill/>,
        title:"School",
        category:'Gadget & Gear',
        price:'$160,',
        date:'17 May 2023'
    },
    {
        id:1,
        icons:<BiSolidJoystick/>,
        title:"School",
        category:'Gadget & Gear',
        price:'$160,',
        date:'17 May 2023' 
    },
    {
        id:1,
        icons:<PiStudentBold/>,
        title:"School",
        category:'Gadget & Gear',
        price:'$160,',
        date:'17 May 2023' 
    },
    {
        id:1,
        icons:< FaSchool/>,
        title:"School",
        category:'Gadget & Gear',
        price:'$160,',
        date:'17 May 2023' 
    },
    {
        id:1,
        icons:<MdOutlineFeed/>,
        title:"School",
        category:'Gadget & Gear',
        price:'$160,',
        date:'17 May 2023' 
    },
]

export default Mapcarddata;