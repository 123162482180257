import React, { useEffect, useState } from "react";
import "./Submited.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import azra from "./Student.png";
import { IoMdArrowDropright } from "react-icons/io";
import { IoMdArrowDropleft } from "react-icons/io";
import { LuDot } from "react-icons/lu";
import img1 from "./Vector.png";
import img2 from "./Vector1.png";
import img3 from "./Vector3.png";
import img4 from "./vector 2.png";
import Dummy from "../../../../../../AdminComponents/images/dummy-user.png"
import { IoCaretForward } from "react-icons/io5";
import { useSearchParams } from "react-router-dom";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../../../../../constants";
const Submited = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([
    {
      OrderID: "ALi ahmed",
      Customer: "1275435",
      image: azra,
      productName: "View Assigment",
      Total: "Text",
    },
    {
      OrderID: "ALi ahmed",
      Customer: "1275435",
      image: azra,
      productName: "View Assigment",
      Total: "Text",
    },
    {
      OrderID: "ALi ahmed",
      Customer: "1275435",
      image: azra,
      productName: "View Assigment",
      Total: "Text",
    },
    {
      OrderID: "ALi ahmed",
      Customer: "1275435",
      image: azra,
      productName: "View Assigment",
      Total: "Text",
    },
    {
      OrderID: "ALi ahmed",
      Customer: "1275435",
      image: azra,
      productName: "View Assigment",
      Total: "Text",
    },
    {
      OrderID: "ALi ahmed",
      Customer: "1275435",
      image: azra,
      productName: "View Assigment",
      Total: "Text",
    },
    {
      OrderID: "ALi ahmed",
      Customer: "1275435",
      image: azra,
      productName: "View Assigment",
      Total: "Text",
    },
    {
      OrderID: "ALi ahmed",
      Customer: "1275435",
      image: azra,
      productName: "View Assigment",
      Total: "Text",
    },
    {
      OrderID: "ALi ahmed",
      Customer: "1275435",
      image: azra,
      productName: "View Assigment",
      Total: "Text",
    },
  ]);

  const [selectedRow, setSelectedRow] = useState(
    Array(data.length).fill(false)
  );

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 8;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = Array.isArray(data) ? data.slice(firstIndex, lastIndex) : [];
  const npage = data ? Math.ceil(data.length / recordsPerPage) : 0;
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function handleRowClick(index) {
    const updatedSelectedRows = [...selectedRow];
    updatedSelectedRows[index] = !updatedSelectedRows[index];

    setSelectedRow(updatedSelectedRows);
  }
  const [classRoom, setClassRoom] = useState(null);

  const getClass = async () => {
    try {
      const { data } = await axios.get(
        `/classrooms/getClassroomById/${localStorage.getItem("classroomId")}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );

      setClassRoom(data?.classroomName);
    } catch (error) {
      console.log(error);
    }
  };

  const test = useSearchParams()[0].get("test")
  
  const { assignmentId } = useParams();
  const [students, setStudents] = useState(null);

  const getSubmittedStudents = async () => {
    try {
      const { data } = await axios.get(
        `/assignmentSubmissions/getStudentByAssignmentId/${assignmentId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setStudents(data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log("these are submitted students", students);
  useEffect(() => {
    getClass();
    getSubmittedStudents();
  }, []);
  
  const handleViewFiles = (file) => {
    let anchor = document.createElement("a");
    anchor.target = "_blank";
    document.body.appendChild(anchor);
    if (
      file?.split(".")?.pop() === "jpeg" ||
      file?.split(".")?.pop() === "png" ||
      file?.split(".")?.pop() === "jpg"
    ) {
      anchor.href = `${baseURL}/images/${file}`;
      anchor.click();
    } else if (
      file?.split(".")?.pop() === "csv" ||
      file?.split(".")?.pop() === "pdf"
    ) {
      anchor.href = `${baseURL}/documents/${file}`;
      anchor.click();
    }
    document.body.removeChild(anchor);
  };

  const [tests, setTest] = useState([])
  const getTest = async() =>{
    try{
      const { data } = await axios.get(`/`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        }
      })
      setTest(data)
    }
    catch(error){
      console.log(error)
    }
  }

  useEffect(() => {
    getTest()
  }, [])
  console.log(tests, "these are students")
  return (
    <div className="AzraTransitionTable-main">
      {!test &&<div className="SubAssingMent-Top">
        <Link to="/teacherhome">Home</Link>
        <IoCaretForward />
        <Link to="/TeacherClassRoomTopSearch">Classrooms</Link>
        <IoCaretForward />
        <Link>{classRoom}</Link>
        <IoCaretForward />
        <h5>Student Assignments</h5>
      </div>}
      <div className="CentralityTop">
        <h3>Student {test ? "Test" : "Assignment"}</h3>
        {!test && <div className="CentralityTop-2">
          <p>
            <LuDot /> OverDue{" "}
          </p>
          <img src={img1} alt="#" />
          <p>
            <LuDot /> Due Soon{" "}
          </p>
          <img src={img2} alt="#" />
          <p>
            <LuDot /> Submitted
          </p>
          <img src={img3} alt="#" />
          <p>
            <LuDot /> New{" "}
          </p>
          <img src={img4} alt="#" />
        </div>}
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <div className="order-main-childd">
                  <h5>Student Name</h5>
                </div>
              </TableCell>
              <TableCell>
                <div className="order-main-childd">
                  <h5> { test ? "Test" : "Assignment"} Title</h5>
                </div>
              </TableCell>
              <TableCell>
                <div className="order-main-childd">
                  <h5>Discription</h5>
                </div>
              </TableCell>
              <TableCell>
                <div className="order-main-childd">
                  <h5>Action</h5>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students &&
              students?.map((item, index) => (
                <TableRow
                  key={index}
                  className={selectedRow[index] ? "selected" : ""}
                  onClick={() => handleRowClick(index)}
                >
                  <TableCell scope="row">
                    <div
                      onClick={() =>
                        navigate(`/StuProfile/${item.id}?redirect=true`)
                      }
                      className="AzraTransitionTable-main-child133"
                    >
                      <img
                        src={`${baseURL}/images/${item?.profilePicture}`}
                        alt=""
                        onError={({ currentTarget }) => {
                          currentTarget.src = Dummy;
                        }}
                      />
                      <span>
                        {item?.firstName} {item?.lastName}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>{item?.assignment?.title}</TableCell>
                  <TableCell>
                    <div className="AzraTransitionTable-main-2">
                      <div className="AzraTransitionTable-main-3">
                        <div className="AzraTransitionTable-main-4">
                          <h5>{item?.assignment?.description}</h5>
                        </div>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <button
                      onClick={() => handleViewFiles(item?.fileUrl)}
                      className="AzraTransitionTableBtn"
                    >
                      View {test ? "Test" : "Assignment"}
                    </button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pagination-ordered">
        <IoMdArrowDropleft
          className="IoMdArrowDropleftee"
          onClick={prePage}
          disabled={currentPage === 1}
        />

        {number.map((id) => (
          <button key={id} onClick={() => changeCPage(id)}>
            {id}
          </button>
        ))}
        <IoMdArrowDropright
          className="IoMdArrowDropleftee"
          onClick={nextPage}
          disabled={currentPage === npage}
        />
      </div>
    </div>
  );
};

export default Submited;
