import React, { useState } from "react";
import "./AddNewStaffInfo.css";
import { useEffect, useRef } from "react";
import fiupload from "../../../../images/fi_upload-cloud.png";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import countryList from "country-list";
import Tab from "react-bootstrap/Tab";
import { IoAddOutline, IoCloseOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { IoDocumentTextOutline } from "react-icons/io5";
import { Autocomplete, TextField } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import languageList from "language-list";
import AccordionDetails from "@mui/material/AccordionDetails";
import CompleteModal from "./../../../../CompleteModal/Complete";
import axios from "axios";
import toast from "react-hot-toast";
import { baseURL } from "../../../../../../constants";
import {
  isValidAmericanPhoneNumber,
  isValidEmail,
  isValidName,
  isValidPassword,
  requiredValues,
} from "../../../../../../utils/regex.js";

import PhoneInput from "react-phone-number-input";
import RefreshIcon from "@mui/icons-material/Refresh";
import { FaPhoneAlt } from "react-icons/fa";
import Avator from "../../../../PickAvator/Avator";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;
function EditStaffInfo() {
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const generateRandomPassword = () => {
    const randomPassword = Math.random().toString(36).slice(-8);
    setPassword(randomPassword);
  };
  const [inputData, setInputData] = useState({
    schoolId: "",
    firstName: "",
    middleName: " ",
    lastName: "",
    staffName: "",
    staffId: "",
    certifications: "",
    alternateId: "",
    stateId: "",
    districtId: "",
    govIssuedNumber: "",
    dateOfBirth: "",
    gender: "",
    nationality: "",
    portalAccess: false,
    loginEmailAddress: "",
  });
  const [nativeLanguage, setNativeLanguage] = useState("");
  const [languagesSpoken, setLanguagesSpoken] = useState("");
  const [nationality, setNationality] = useState("");

  const languages = languageList();

  const [allLanguages, setAllLanguages] = useState(null);
  useEffect(() => {
    const allLanguages = languages.getData().map((item) => {
      return item.language;
    });
    setAllLanguages(allLanguages);
  }, []);
  const [editPhoto, setEditPhoto] = useState(false);
  const params = useParams("");
  // const [selectedFile, setSelectedFile] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef("");

  // const handleFileChange = (e) => {
  //   setSelectedFile(e.target.files[0]);
  // };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (window.location.search.split("=")[1] === "true") {
      setEditPhoto(true);
    } else {
      setEditPhoto(false);
    }
  }, [window.location.search.split("=")[1]]);

  const handleHideImage = () => {
    if (window.location.search[0] === "?") {
      setEditPhoto(false);
    }
  };

  const handleInputChanges = (e) => {
    setInputData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const [showCreatePassword, setShowCreatePassword] = useState(false);
  const navigate = useNavigate();

  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddTag = () => {
    if (inputValue.trim() !== "") {
      setTags([...tags, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleAddTag();
    }
  };

  const removeTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };
  const [disabled, setDisabled] = useState(false);
  const [password, setPassword] = useState("");
  const [loginEmailAddress, setLoginEmailAddress] = useState("");
  const [profile, setProfile] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("+1");
  const [countryOfBirth, setCountryOfBirth] = useState("");
  const [portalAccess, setPortalAccess] = useState(false);
  const [ssoLogin, setSSOLogin] = useState(false);
  const { schoolId } = useSelector((state) => state.schoolId);
  const [success, setSuccess] = useState(false);

  const [profilePicture, setProfilePicture] = useState(null);
  const [lgShow, setLgShow] = useState(false);

  const addNewStaff = async (e) => {
    e.preventDefault();

    if (
      !requiredValues([
        {
          value: inputData.firstName,
          name: "First name",
        },
        {
          value: inputData.lastName,
          name: "Last name",
        },
        {
          value: inputData.staffId,
          name: "Staff ID",
        },
        {
          value: inputData.stateId,
          name: "State ID",
        },
        {
          value: inputData.districtId,
          name: "District ID",
        },
        {
          value: inputData.gender,
          name: "Gender",
        },
        {
          value: phoneNumber,
          name: "Phone number",
        },
        {
          value: inputData.dateOfBirth,
          name: "Date of birth",
        },
        {
          value: countryOfBirth,
          name: "Country of birth",
        },
        {
          value: nationality,
          name: "Nationality",
        },
        {
          value: nativeLanguage,
          name: "NativeLanguage",
        },
        {
          value: loginEmailAddress,
          name: "Login Email Address",
        },
        {
          value: password,
          name: "Password",
        },
      ])
    )
      return;
    if (!isValidAmericanPhoneNumber(phoneNumber, "Phone number")) return;

    if (!isValidName(inputData.firstName, "First name")) return;
    if (inputData.middleName) {
      if (!isValidName(inputData.middleName, "Middle name")) return;
    }

    if (!isValidName(inputData.lastName)) return;
    if (!isValidPassword(password)) return;

    if (languagesSpoken.length < 1)
      return toast.error("Spoken languages are required");

    if (!isValidEmail(loginEmailAddress)) return;

    const newForm = new FormData();
    newForm.append("profilePicture", profilePicture);
    newForm.append("schoolId", Number(schoolId));
    newForm.append("firstName", inputData.firstName);
    newForm.append("middleName", inputData.middleName);
    newForm.append("lastName", inputData.lastName);
    newForm.append(
      "staffName",
      `${inputData.firstName} ${inputData.middleName} ${inputData.lastName}`
    );
    newForm.append("staffId", inputData.staffId);
    newForm.append("certifications", tags?.join(", "));
    // newForm.append("alternateId", inputData.alternateId);
    newForm.append("stateId", inputData.stateId);
    newForm.append("districtId", inputData.districtId);
    inputData.govIssuedNumber &&
      newForm.append("govIssuedNumber", inputData.govIssuedNumber);
    newForm.append("dateOfBirth", inputData.dateOfBirth);
    newForm.append("gender", inputData.gender);
    newForm.append("countryOfBirth", countryOfBirth);
    newForm.append("nationality", nationality);
    newForm.append("nativeLanguage", nativeLanguage);

    languagesSpoken?.split(",")?.forEach((element) => {
      newForm.append("languagesSpoken[]", element);
    });
    newForm.append("portalAccess", inputData.portalAccess);
    newForm.append("loginEmailAddress", loginEmailAddress);
    newForm.append("disabled", disabled);
    newForm.append("password", password);
    newForm.append("phoneNumber", phoneNumber);
    profile && newForm.append("profile", profile);
    try {
      await axios.post("/teachers/add", newForm, {
        headers: {
          "Content-Type": "multipart/form-data",
          accessToken: `${localStorage.getItem("token")}`,
        },
      });

      setSuccess(true);
    } catch (error) {
      toast.error(error.response.data.message[0].detail);
    }
  };

  return (
    <>
      {success && <CompleteModal link="/StaffView" />}
      <div className="container-fluid">
        <div className="row">
          <div className="StaffProfile-Main">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <div className="addstaffcol-div">
                  <div className="addstaffcol-div-child-1">
                    {" "}
                    <Col sm={12}>
                      <div className="EditStaff-main ">
                        <div className="EditStaff-Col3-main">
                          <div className="EditStaff-COl3">
                            <div className="EditStaff-Child-1">
                              <div className="fiupload-img">
                                <div
                                  onClick={() => setLgShow(true)}
                                  className="upLoaDing"
                                >
                                  {selectedFile ? (
                                    <img
                                      src={selectedFile}
                                      alt="Staff"
                                      style={{
                                        width: "100%",
                                        height: "171px",
                                        cursor: "pointer",
                                        borderRadius: "50%",
                                      }}
                                      // onClick={handleImageClick}
                                    />
                                  ) : (
                                    <>
                                      <img
                                        src={fiupload}
                                        alt="Staff"
                                        style={{
                                          width: "20px",
                                          cursor: "pointer",
                                          height: "20px",
                                        }}
                                        // onClick={handleImageClick}
                                      />
                                      <label
                                        style={{
                                          cursor: "pointer",
                                          marginTop: "8px",
                                        }}
                                      >
                                        Set Profile
                                      </label>
                                    </>
                                  )}
                                  {/* <input
                                    type="file"
                                    id="upload"
                                    name="profilePicture"
                                    ref={fileInputRef}
                                    style={{ display: "none" }}
                                    onChange={handleFileChange}
                                  /> */}
                                </div>
                              </div>
                              <h2>{inputData.firstName}</h2>
                              <button>{profile}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Nav
                        variant="pills"
                        className="flex-column AddNEWSTUDENT2"
                      >
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="AddNewStudent"
                          >
                            <IoDocumentTextOutline /> Staff Details
                          </AccordionSummary>
                          <AccordionDetails>
                            <Nav.Item>
                              <Nav.Link eventKey="first">General Info</Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                          <Nav.Link eventKey="second">School Info </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="third">
                            Address & Contact
                          </Nav.Link>
                        </Nav.Item> */}
                          </AccordionDetails>
                        </Accordion>
                      </Nav>
                    </Col>
                  </div>
                  <div className="addstaffcol-div-child-2">
                    <Col sm={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div className="EditStaffInfo-main">
                            <div className="ADDEditStudent">
                              <h3>Identification Information</h3>

                              <div className="EditStaffInfo-inputs">
                                <div>
                                  <label>First/Given Name *</label> <br />
                                  <input
                                    name="firstName"
                                    value={inputData?.firstName}
                                    onChange={handleInputChanges}
                                    placeholder="-"
                                    required
                                  />
                                </div>
                                <div>
                                  <label>Middle Name</label> <br />
                                  <input
                                    value={inputData?.middleName}
                                    onChange={handleInputChanges}
                                    name="middleName"
                                    placeholder="-"
                                  />
                                </div>
                                <div>
                                  <label>Last/Family Name *</label> <br />
                                  <input
                                    value={inputData?.lastName}
                                    onChange={handleInputChanges}
                                    name="lastName"
                                    placeholder="-"
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="EditStaffInfo-inputschild-1">
                              {/* <div>
                                <label>Staff Information *</label> <br />
                                <input placeholder="-" />
                              </div>
                              <div>
                                <label>Preferred/Common Name</label> <br />
                                <input placeholder="-" />
                              </div> */}
                              <div>
                                <label>Staff ID *</label> <br />
                                <input
                                  value={inputData?.staffId}
                                  onChange={handleInputChanges}
                                  name="staffId"
                                  placeholder="-"
                                  required
                                />
                              </div>
                              {/* <div>
                                <label>Certifications *</label> <br />
                                <input
                                  value={inputData?.certifications}
                                  onChange={handleInputChanges}
                                  name="certifications"
                                  placeholder="-"
                                  required
                                />
                              </div> */}
                              {/* <div className="fdssdffd">
                                <button>
                                  <IoAddOutline />
                                  Add More
                                </button>
                              </div> */}
                              <Avator
                                setSelectedFile={setSelectedFile}
                                profilePicture={profilePicture}
                                setProfilePicture={setProfilePicture}
                                lgShow={lgShow}
                                setLgShow={setLgShow}
                              />
                              <div className="fdssdffd">
                                <div className="input-container">
                                  <button
                                    onClick={handleAddTag}
                                    className="input-containerBtn"
                                  >
                                    <IoAddOutline />
                                    Add More
                                  </button>
                                  <div>
                                    <label>Certifications *</label> <br />
                                    <input
                                      type="text"
                                      value={inputValue}
                                      onChange={handleInputChange}
                                      onKeyDown={handleKeyDown}
                                    />
                                  </div>
                                </div>
                                <div className="tags-container">
                                  {tags.map((tag, index) => (
                                    <span key={index} className="tag">
                                      {tag}
                                      <button
                                        className="remove-tag-button"
                                        onClick={() => removeTag(index)}
                                      >
                                        <IoCloseOutline />
                                      </button>
                                    </span>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="EditStaffInfo-inputschild-1">
                              <div>
                                <label>State ID *</label> <br />
                                <input
                                  value={inputData?.stateId}
                                  onChange={handleInputChanges}
                                  name="stateId"
                                  placeholder="-"
                                  required
                                />
                              </div>
                              <div>
                                <label>District ID *</label> <br />
                                <input
                                  value={inputData?.districtId}
                                  onChange={handleInputChanges}
                                  name="districtId"
                                  placeholder="-"
                                  required
                                />
                              </div>
                              <div>
                                <label>Profile *</label> <br />
                                <input
                                  value={profile}
                                  onChange={(e) => setProfile(e.target.value)}
                                  name="districtId"
                                  placeholder="-"
                                  required
                                />
                              </div>
                              <div className="kdjfbvkjdfbkfd">
                                <label>Phone Number *</label> <br />
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "7px",
                                  }}
                                >
                                  <FaPhoneAlt />
                                  <PhoneInput
                                    placeholder="Enter contact number"
                                    value={phoneNumber}
                                    onChange={setPhoneNumber}
                                  />
                                </div>
                              </div>
                              <div>
                                <label>Admission Number *</label> <br />
                                <input placeholder="-" />
                              </div>
                            </div>
                            <div className="EditStaffInfo-inputschild-1">
                              <div>
                                <label>Out Govt Issued Number *</label> <br />
                                <input
                                  value={inputData?.govIssuedNumber}
                                  onChange={handleInputChanges}
                                  name="govIssuedNumber"
                                  placeholder="-"
                                />
                              </div>
                            </div>

                            <div className="EditStaffInfo-child-2">
                              <h3>Demographic Information</h3>
                              <div className="EditStaffInfo-inp">
                                <div>
                                  <label>Date of Birth *</label> <br />
                                  <input
                                    value={inputData?.dateOfBirth}
                                    onChange={handleInputChanges}
                                    name="dateOfBirth"
                                    type="date"
                                    placeholder="-"
                                  />
                                </div>
                                <div>
                                  <label>Gender *</label> <br />
                                  <select
                                    value={inputData?.gender}
                                    onChange={(e) => {
                                      setInputData((prev) => {
                                        return {
                                          ...prev,
                                          gender: e.target.value,
                                        };
                                      });
                                    }}
                                  >
                                    <option value="">-</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="Non-Binary">
                                      Non-Binary
                                    </option>
                                  </select>
                                </div>
                                <div>
                                  <label>Country of Birth *</label> <br />
                                  <Autocomplete
                                    disablePortal
                                    value={countryOfBirth}
                                    onChange={(event, newValue) => {
                                      setCountryOfBirth(newValue);
                                    }}
                                    id="combo-box-demo"
                                    options={countryList.getNames()}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="EditStaffInfo-inp">
                                <div>
                                  <label>Nationality *</label> <br />
                                  <Autocomplete
                                    disablePortal
                                    value={nationality}
                                    onChange={(event, newValue) => {
                                      setNationality(newValue);
                                    }}
                                    id="combo-box-demo"
                                    options={countryList.getNames()}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                  />
                                </div>
                                <div>
                                  <label>Native Language *</label> <br />
                                  {allLanguages && (
                                    <Autocomplete
                                      disablePortal
                                      value={nativeLanguage}
                                      onChange={(event, newValue) => {
                                        setNativeLanguage(newValue);
                                      }}
                                      id="combo-box-demo"
                                      options={allLanguages}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  )}
                                </div>
                                <div>
                                  <label>Languages Spoken *</label> <br />
                                  {allLanguages && (
                                    <Autocomplete
                                      disablePortal
                                      value={languagesSpoken}
                                      onChange={(event, newValue) => {
                                        setLanguagesSpoken(newValue);
                                      }}
                                      id="combo-box-demo"
                                      options={allLanguages}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              className="ADDEditSchoolInfo-Switch"
                              style={{
                                display: "flex",
                                gap: "20px",
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                            >
                              <div className="form-check form-switch">
                                <input
                                  required
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id=""
                                  // checked={portalAccess}
                                  onChange={() =>
                                    setPortalAccess(!portalAccess)
                                  }
                                />
                                <label className="form-check-label">
                                  Portal Access
                                </label>
                              </div>
                              {portalAccess ? (
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id=""
                                    checked={ssoLogin}
                                    onChange={() => setSSOLogin(!ssoLogin)}
                                  />
                                  <label className="form-check-label">
                                    Allow SSO Login
                                  </label>
                                </div>
                              ) : null}
                            </div>
                            {portalAccess && (
                              <div className="ADDSwitchShowInput">
                                <div className="ADDSwitchShowInput-child-1">
                                  <label>Access Information *</label> <br />
                                  <input
                                    required
                                    value={loginEmailAddress}
                                    onChange={(e) =>
                                      setLoginEmailAddress(e.target.value)
                                    }
                                    placeholder="Login Email Address*"
                                  />
                                </div>
                                <div className="ADDSwitchShowInput-child-2">
                                  <div class="form-check">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="flexCheckDefault"
                                      checked={showCreatePassword}
                                      onChange={() =>
                                        setShowCreatePassword(
                                          !showCreatePassword
                                        )
                                      }
                                    />
                                    <label
                                      class="form-check-label"
                                      for="flexCheckDefault"
                                      style={{ color: "black" }}
                                    >
                                      Set or Reset Password
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* ///////////////////Show Hide////////////////////// */}
                            {showCreatePassword ? (
                              <div className="ADDEditShowHide">
                                <h4>Password *</h4>
                                <h5>Student email to set or reset password</h5>
                                <div class="form-check">
                                  <input
                                    class="form-check-input "
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios1"
                                    value="option1"
                                  />
                                  <label
                                    class="form-check-label labelss"
                                    for="exampleRadios1"
                                  >
                                    Student will be able to create password on
                                    their own by clicking the link on their
                                    email
                                  </label>
                                </div>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios1"
                                    value="option1"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="exampleRadios1"
                                  >
                                    Create Password
                                  </label>
                                </div>
                                <div className="ADDShowCloseDiv-main">
                                  <div className="ADDShowCloseDiv">
                                    <input
                                      type={showPassword ? "text" : "password"}
                                      value={password}
                                      required
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                      placeholder="Enter your password"
                                    />
                                    <span
                                      className="eye-icon"
                                      onClick={handleTogglePassword}
                                    >
                                      {showPassword ? "👁️" : "👁️‍🗨️"}
                                    </span>
                                  </div>
                                  <p onClick={generateRandomPassword}>
                                    <RefreshIcon />
                                    Auto Generate Password
                                  </p>
                                </div>
                              </div>
                            ) : null}
                            {/* ............. */}
                            <div className="ADDEditFooter">
                              {/* <div class="form-check">
                                <input
                                  onChange={() => setDisabled(!disabled)}
                                  checked={disabled}
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDisabled"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckDisabled"
                                >
                                  Disabled checkbox
                                </label>
                              </div> */}
                              <button
                                type="button"
                                onClick={() => navigate(`/StaffView`)}
                                className="ADDEditFooter-btn1"
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                onClick={addNewStaff}
                                className="ADDEditFooter-btn2"
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </Tab.Pane>
                        {/* <Tab.Pane eventKey="second">
                      <AddStaffSchoolInfo />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <AddStafAddress />
                    </Tab.Pane> */}
                      </Tab.Content>
                    </Col>
                  </div>
                </div>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditStaffInfo;
