import React, { useState } from "react";
import "./IeProgram.css";
import "../../StudentComponents/NestedTabs/NestedTabs.css";
import { Tab, Tabs } from "react-bootstrap";
import { IoArrowBack } from "react-icons/io5";
import Form from "react-bootstrap/Form";
function IeProgram() {
  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState("");
  return (
    <div className="IeProgram-Main12">
      {!show ? (
        <div>
          <div className="IeProgram-Top12">
            <h3>Individualized Education Program</h3>
            <div className="IEPROGRAMSWITCH">
              <label>Program access</label>
              <Form>
                <Form.Check type="switch" id="custom-switch" />
              </Form>
            </div>
          </div>
          <div className="IeProgram-table-1">
            <table>
              <thead>
                <tr>
                  <th>Program</th>
                  <th>Entered</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td onClick={() => setShow(true)}>English Learner</td>
                  <td>09/05/2017</td>
                </tr>
                <tr>
                  <td onClick={() => setShow(true)}>Foster Program</td>
                  <td>09/05/2017</td>
                </tr>
                <tr>
                  <td onClick={() => setShow(true)}>Gate</td>
                  <td>09/05/2017</td>
                </tr>
                <tr>
                  <td onClick={() => setShow(true)}>
                    Gifted and Talented Education (GATE)
                  </td>
                  <td>09/05/2017</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div>
          <div className="EDitIEProgram">
            <h3>
              <IoArrowBack
                onClick={() => {
                  setIsEdit("");
                  setShow(false);
                }}
              />
              Program
            </h3>
            <h3>Entered</h3>
          </div>
          <div className="EDitIEProBody">
            <div className="EDitIEProH4">
              <h4>English Learner</h4>
              <h4>09/21/2020</h4>
            </div>

            <div className="EDitIEProSelect">
              <div className="EDitIEProSel">
                <label>Correspondence Language:</label>
                <br />
                <select>
                  <option value="" disabled></option>
                  <option value="Spanish">Spanish</option>
                  <option value="Hindi">Hindi</option>
                </select>
              </div>
              <div className="EDitIEProSel">
                <label>Reporting Language:</label>
                <br />
                <select>
                  <option value="" disabled></option>
                  <option value="Spanish">Spanish</option>
                  <option value="Hindi">Hindi</option>
                </select>
              </div>
              <div className="EDitIEProSel">
                <label>Language Fluency:</label>
                <br />
                <select>
                  <option value="" disabled></option>
                  <option value="English Learner">English Learner</option>
                  <option value="Hindi">Hindi</option>
                </select>
              </div>
              <div className="EDitIEProSel">
                <label>US School 3 Years:</label>
                <br />
                <select>
                  <option value="" disabled></option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>
          </div>

          <div className="EDitIEProTabs">
            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3 nestedTabs"
            >
              <Tab eventKey="home" title="General">
                <div className="EDitIEProTable">
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="3"></th>
                        <th colSpan="2">Overall</th>
                        <th colSpan="2">Oral lng</th>
                        <th colSpan="2">Written Lng</th>
                        <th colSpan="2">Listen</th>
                        <th colSpan="2">Speak</th>
                        <th colSpan="2">Read</th>
                        <th colSpan="2">Write</th>
                        <th></th>
                      </tr>
                      <tr>
                        <th>Tests</th>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Level</th>
                        <th>Score</th>
                        <th>Level</th>
                        <th>Score</th>
                        <th>Level</th>
                        <th>Score</th>
                        <th>Level</th>
                        <th>Score</th>
                        <th>Level</th>
                        <th>Score</th>
                        <th>Level</th>
                        <th>Score</th>
                        <th>Level</th>
                        <th>Score</th>
                        <th>Language Designation</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>English:</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>Spanish:</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>Other:</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Tab>
              <Tab eventKey="profile" title="Test">
                Tab content for Profile
              </Tab>
              <Tab eventKey="contact" title="Test History">
                Tab content for Contact
              </Tab>
            </Tabs>
          </div>

          <div className="EDitIEProHome">
            <div className="EDitIEProTop">
              <h2>Home Language Survey</h2>
            </div>
            <table>
              <thead>
                <tr>
                  <th>First</th>
                  <th>At Home</th>
                  <th>By Adults</th>
                  <th>Primary</th>
                  <th>Survery Compeleted Date</th>
                  <th>Source</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="EDitIEProTWO EDitIEProHome">
            <div className="EDitIEProTWOChild">
              <div className="EDitIEProTop">
                <h2>Initial ELPAC Result</h2>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Initial Date</th>
                    <th>Initial Flunency</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="EDitIEProTWOChild">
              <div className="EDitIEProTop">
                <h2>ELPAC Electronic Test Results</h2>
              </div>
              <div className="EDitIEProTWOChildSel">
                <select>
                  <option value="" disabled></option>
                  <option>Option One</option>
                  <option>Option Two</option>
                </select>
              </div>
            </div>
          </div>

          <div className="EDitIEProHome">
            <div className="EDitIEProTop">
              <h2>Important Dates</h2>
            </div>
            <table>
              <thead>
                <tr>
                  <th>US Entry</th>
                  <th>Us School Aga 3 & Up</th>
                  <th>US School K-12</th>
                  <th>CA School</th>
                  <th>Diantrict Entry</th>
                  <th>School Entry</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>-</td>
                  <td>-</td>
                  <td>9/21/2022</td>
                  <td>-</td>
                  <td>08/10/2028</td>
                  <td>09/18/2021</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="EDitIEProHome">
            <div className="EDitIEProTop">
              <h2>English Learner Program</h2>
            </div>

            <table>
              <thead>
                <tr>
                  <th>EL Start Date</th>
                  <th>Yrs End Date</th>
                  <th>Yrs In Prgm</th>
                  <th>YPCalc Date</th>
                  <th>Program</th>
                  <th>Curr Lang Des</th>
                  <th>Lang Of Inst</th>
                  <th>Eng Class Elig</th>
                  <th>Srvcs Rcvd</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>9/21/2020</td>
                  <td>-</td>
                  <td>2</td>
                  <td>-</td>
                  <td>1 Structured Eng Immersion</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>330- English Language Development (ELD)</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="EDitIEProHome">
            <div className="EDitIEProTop">
              <h2>Reclassification Information</h2>
            </div>
            <table>
              <thead>
                <tr>
                  <th colSpan="13">Follow- Up Dates/ Codes</th>
                </tr>
                <tr>
                  <th>Reasnable Fluency Dt</th>
                  <th>RFEP Dates</th>
                  <th>Code</th>
                  <th>3D Day</th>
                  <th>Code</th>
                  <th>1Yr</th>
                  <th>Code</th>
                  <th>2Yr</th>
                  <th>Code</th>
                  <th>3Yr</th>
                  <th>Code</th>
                  <th>4Yr</th>
                  <th>Code</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="EDitIEProHome">
            <div className="EDitIEProTop">
              <h2>Other Information</h2>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Birth City</th>
                  <th>State</th>
                  <th>Country</th>
                  <th>ELAS Code</th>
                  <th>ELAS Code</th>
                  <th>LTEL</th>
                  <th>Years EL</th>
                  <th>IFEP Date</th>
                  <th>ADEL Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Loss Angeles</td>
                  <td>California</td>
                  <td>US Of America</td>
                  <td>-</td>
                  <td>0</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Loss Angeles</td>
                  <td>California</td>
                  <td>US Of America</td>
                  <td>-</td>
                  <td>0</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td className="EDITCOMMENT" colSpan="9">
                    Comments
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="IeProgramEditBtns">
            <button className="EDitIEBtn1">Change</button>
            <button className="EDitIEBtn2">Print</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default IeProgram;
