import React, { useEffect, useState } from "react";
import "../Transcripts/Transcripts.css";
import TuitionInfoCom from "./TuitionInfoCom";
import TuitionInfoData from "./TuitionInfoData";
import axios from "axios";
import { baseURL } from "../../../constants";
import { useScroll } from "react-spring";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

function Transcripts() {
  const [semisters, setSemisters] = useState(null);
  const { user, loading, error } = useSelector((state) => state.getUserProfile);

  const getSemisters = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/tuitionInfos/getTuitionInfo/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setSemisters(data?.tuitionInfo);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSemisters();
  }, [user]);

  return (
    semisters && (
      <>
        {
          <div className="Transcripts-main">
            {Object.entries(semisters).map(([semester, info], index) => (
              <div className="table tableone" key={index}>
                <h3>{semester}</h3>
                <div className="tableMap">
                  <TuitionInfoCom data={info.tuitionInfo} />
                </div>
              </div>
            ))}
          </div>
        }
      </>
    )
  );
}

export default Transcripts;
