import React, { useEffect, useState } from "react";
import "./GraStatus.css";
import print from "../Medical/flat-color-icons_print.png";
// import { startOfDay } from "date-fns/fp";
import Dummy from "../../AdminComponents/images/dummy-user.png";
import Shoaib from "../../StudentComponents/images/Shoaib.png";
import {
  getMedicalStudentId,
  getParentStudents,
} from "../../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../../constants";
axios.defaults.baseURL = baseURL;

function GraStatus() {
  const { loading, students, error } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const { studentId: currentStudentId } = useSelector(
    (state) => state.medicalStudentId
  );

  const { user } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    if (user) {
      dispatch(getParentStudents(user?.dataValues?.id));
    }
  }, [user]);
  const [allStudents, setAllStudents] = useState([]);

  const [currentStudent, setCurrentStudent] = useState({
    name: "",
    image: "",
  });

  const [studentId, setStudentId] = useState(null);

  useEffect(() => {
    if (currentStudentId && students) {
      const student = students.find((st) => st.studentId == currentStudentId);

      setCurrentStudent({
        name: student?.Student?.firstName || "Name",
        image: `${baseURL}/images/${student?.Student?.profilePicture}`,
      });
    } else if (students && students?.length > 0) {
      setCurrentStudent({
        name: students?.Student?.[0]?.firstName || "Name",
        image: `${baseURL}/images/${students?.[0]?.Student?.profilePicture}`,
      });
      handleStoreId(students[0]?.studentId);
    }
  }, [students, currentStudentId]);

  const handleStoreId = (id) => {
    dispatch(getMedicalStudentId(id));
  };
  function handlePrint() {
    window.print()
}
  return (
    <div className="Graduation-main">
      <div className="Graduation-Top">
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <h3>Graduation Status</h3>
          <div className="dropdownn">
            <div className="dropdownn">
              {students && students?.length > 0 ? (
                <button
                  className="btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <>
                    <img
                      src={currentStudent.image}
                      alt="userName"
                      onError={({ currentTarget }) => {
                        currentTarget.src = Dummy;
                      }}
                    />
                    <span className="userName">{currentStudent.name}</span>
                  </>
                </button>
              ) : (
                <p>No Students</p>
              )}

              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {students &&
                  students?.map((item) => {
                    return (
                      <li
                        onClick={() => {
                          handleStoreId(item?.studentId);
                        }}
                      >
                        <a className="dropdown-item" href="#">
                          <img
                            src={`${baseURL}/images/${item?.Student?.profilePicture}`}
                            alt="User"
                            onError={({ currentTarget }) => {
                              currentTarget.src = Dummy;
                            }}
                          />
                          {item?.Student?.firstName}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        <button onClick={handlePrint}>
          <img src={print} alt="#" />
          Print Graduation Status Report{" "}
        </button>
      </div>
      <div className="Graduation-Body">
        <div className="Graduation-Child">
          <div className="Graduation-Child-input">
            <input type="checkbox" />
            <label>Show Credit Details</label>
          </div>
          <div className="Graduation-Child-input">
            <input type="checkbox" />
            <label>Hide Planned Credits</label>
          </div>
        </div>
        <p>
          Please note: Status reflected is contingent upon successful completion
          of current course enrollment.
        </p>
        <div className="Graduation-Top">
          <h3>Graduation Status</h3>
        </div>

        <div className="Graduation-Table">
          <table>
            <thead>
              <tr>
                <th>Subject Area</th>
                <th>Credit Required</th>
                <th>Credit Completed</th>
                <th>Credit Enrolled</th>
                <th>Credit Planned</th>
                <th>Credit Needed</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>A - English</td>
                <td>40.00</td>
                <td>35.00</td>
                <td>5.00</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>B - Physical Education</td>
                <td>30.00</td>
                <td>30.00</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="Graduation-Btns">
          <div className="Graduation-Btns-one">
            <h4>Credit Completed</h4>
            <h5>272.50</h5>
          </div>
          <div className="Graduation-Btns-two">
            <h4>Credit Enrolled</h4>
            <h5>272.50</h5>
          </div>
          <div className="Graduation-Btns-three">
            <h4>Credit Needed</h4>
            <h5>272.50</h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GraStatus;
