import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import "./Sidebar.css";
import GridView from "@mui/icons-material/GridView";
import WebhookIcon from "@mui/icons-material/Webhook";
import Logo from "../../AdminComponents/images/Group.png";
import {
  IoCaretForwardOutline,
  IoCaretDownOutline,
  IoCalendarNumber,
} from "react-icons/io5";
import Cartoon from "../Images/sidebarIMG.png";
import second from "../Images/secongimg.png";
import attendence from "../../ParentPortalComponents/Images/attendence.png";
import rest from "../../StudentComponents/images/rest.png";
const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const location = useLocation();

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = (name) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const generateMenuItem = (
    path,
    name,
    icon,
    hasDropdown = false,
    submenuItems = []
  ) => {
    const item = {
      path: path,
      name: name,
      icon: icon,
    };

    if (hasDropdown) {
      item.arrow = dropdownOpen[name] ? (
        <IoCaretDownOutline />
      ) : (
        <IoCaretForwardOutline />
      );
      item.subMenu = submenuItems;
      item.onClick = () => toggleDropdown(name);
    }

    return item;
  };

  const menuItem = [
    generateMenuItem("/teacherhome", "Home", <GridView />),
    generateMenuItem("/TeacherClassRoomTopSearch", "Classes", <GridView />),
    generateMenuItem("/TabBook", "Gradebook", <GridView />),
    generateMenuItem("/AllStudents", "Student Data", <GridView />),
    // generateMenuItem("/SchoolInformation", "School", <BiSolidSchool />, true, [
    //   { path: "/SchoolInformation", name: "School Information" },
    //   { path: "/DashboardCalender", name: "Calendar" },
    //   { path: "/SchoolNotice", name: "Notices" },
    // ]),
    generateMenuItem("/TeacherCalender", "Calendar", <IoCalendarNumber />),
    generateMenuItem("/MockupRequests", "MakeUp Requests", <WebhookIcon />),
    generateMenuItem(
      "/SchoolNoticeboard",
      "School Noticeboard",
      <WebhookIcon />
    ),
    generateMenuItem("/TeacherAccountSetting", "Setting", <WebhookIcon />),
  ];

  const activeMenuItem = menuItem.find(
    (item) => window.location.pathname === item.path
  );
  const [key, setKey] = useState("");
  useEffect(() => {
    setKey(window.location.search?.split("=")[1]);
  }, [window.location.search]);

  const [loc, setLoc] = useState(window.location.pathname);
  useEffect(() => {
    setLoc(window.location.pathname);
  }, [window.location.pathname]);
  return (
    <>
      <div className="dashboardewrapper">
        <div className={`sidebar ${isOpen ? "active" : ""}`}>
          <Link to="/teacherhome" className="Logos">
            <img src={Logo} alt="Logo" />
            <h4>MYCENTRALITY</h4>
          </Link>
          <div className="menubar">
            <div
              style={{ display: "flex", flexDirection: "column" }}
              className="Fullwidth"
            >
              {menuItem.map((item, index) => (
                <div key={index} className="Full-Width">
                  <NavLink
                    to={item.path}
                    className="Sidebar-link"
                    activeClassName="active"
                    onClick={item.onClick}
                  >
                    <div className="icon">{item.icon}</div>
                    <div className="link_text ms-2  d-sm-inline">
                      {item.name}
                      {item.arrow}
                    </div>
                  </NavLink>
                  {item.subMenu &&
                    dropdownOpen[item.name] &&
                    item.subMenu.map((subItem, subIndex) => (
                      <NavLink
                        to={subItem.path}
                        key={subIndex}
                        className="Sidebar-sublink"
                      >
                        <div className="link_text d-sm-inline done">
                          <li>
                            <Link to={subItem.path}>{subItem.name}</Link>
                          </li>
                        </div>
                      </NavLink>
                    ))}
                </div>
              ))}
            </div>
          </div>
          {loc === "/teacherhome" && (
            <img
              src={Cartoon}
              alt="Sidebar Illustration"
              className="CartoonImg"
            />
          )}
          {loc === "/TeacherClassRoomTopSearch" && (
            <img
              src={second}
              alt="Sidebar Illustration"
              className="CartoonImg"
            />
          )}
          {loc === "/Teacherattendance" && (
            <img
              src={attendence}
              alt="Sidebar Illustration"
              className="CartoonImg"
            />
          )}
          {key === "summary" && (
            <img src={rest} alt="Sidebar Illustration" className="CartoonImg" />
          )}
        </div>

        <div
          className={`navbar-toggle ${isOpen ? "open" : ""}`}
          onClick={toggleNavbar}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <main>{children}</main>
      </div>
    </>
  );
};

export default Sidebar;
