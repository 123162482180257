import React, { useEffect, useState } from "react";
import "./ScheduleData.css";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modall from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { baseURL } from "../../../../constants";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SecondProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Monday",
  "Tuesday",

  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ScheduleData() {
  const [editId, setEditId] = useState("");

  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const handleClose = () => {
    setShow(false);
    setDeleteId("");
    setEditId("");
  };
  const handleShow = () => setShow(true);
  const [open, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClosee = () => {
    setIsOpen(false);
    setDeleteId("");
    setEditId("");
  };

  const { schoolId } = useSelector((state) => state.schoolId);

  const [schedules, setSchedules] = useState(null);

  const getSchedules = async () => {
    try {
      const { data } = await axios.get(
        `/schedules/getScheduleBySchool/${schoolId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setSchedules(data?.schedules);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchedules();
  }, [schoolId]);

  const handleDeleteSchedule = async () => {
    try {
      const { data } = await axios.delete(
        `/schedules/removeSchedule/${deleteId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Successfully deleted schedule!");
      getSchedules();
      handleClose();
      handleClosee();
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else if (error?.response?.data?.error) {
        toast.error(
          error?.response?.data?.error[0]?.detail ||
            error?.response?.data?.error[0]?.message
        );
      } else {
        toast.error(
          error?.response?.data?.message || error?.response?.data?.msg
        );
      }
      console.log(error);
    }
  };

  const [teacherId, setTeacherId] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [roomId, setRoomId] = useState("");
  const [day, setDay] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [startdate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const handleMySubjects = () => {};
  const addSchedule = async () => {
    try {
      if (!editId) {
        await axios.post(
          `/schedules/addSchedule`,
          {
            schoolId,
            gradeLevel,
            teacherId,
            subjectId,
            classroomId: Number(roomId),
            day,
            startTime: startTime,
            endTime: endTime,
            startDate: startdate,
            endDate,
          },
          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Schedule Added Successfully!");
        getSchedules();
        handleClose();
        handleClosee();
        setTeacherId("");
        setGradeLevel("");
        setSubjectId("");
        setRoomId("");
        setDay("");
        setStartTime("");
        setEndTime("");
        setStartDate("");
        setEndDate("");
      } else {
        await axios.put(
          `/schedules/updateSchedule/${editId}`,
          {
            schoolId,
            gradeLevel,
            teacherId,
            subjectId,
            classroomId: Number(roomId),
            day,
            startTime: startTime,
            endTime: endTime,
            startDate: startdate,
            endDate,
          },
          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Schedule Updated Successfully!");
        getSchedules();
        handleClose();
        handleClosee();
        setTeacherId("");
        setGradeLevel("");
        setSubjectId("");
        setRoomId("");
        setDay("");
        setStartTime("");
        setEndTime("");
        setStartDate("");
        setEndDate("");
      }
    } catch (error) {
      console.log(error, "this is main error");
      if (error?.response?.data?.error) {
        toast.error(
          error?.response?.data?.error[0]?.detail ||
            error?.response?.data?.error[0]?.message ||
            error?.response?.data?.error[0]?.msg
        );
      } else if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message ||
            error?.response?.data?.details[0]?.msg
        );
      } else {
        toast.error(
          error?.response?.data?.error || error?.response?.data?.message
        );
      }
    }
  };

  useEffect(() => {
    (() => {
      if (editId) {
        handleShow();
        const sch = schedules?.find((item) => {
          return item?.id === editId;
        });

        setTeacherId(sch?.teacherId);
        setGradeLevel(sch?.gradeLevel);
        setSubjectId(sch?.subjectId);
        setRoomId(sch?.roomId);
        setDay(sch?.day);
        setStartTime(sch?.startTime);
        setEndTime(sch?.endTime);
        setStartDate(sch?.date?.toISOString()?.split("T")[0]);
        setEndDate(sch?.date?.toISOString()?.split("T")[0]);
      }
    })();
  }, [editId]);
  const [teachers, setTeachers] = useState(null);

  const getAllTeachers = async () => {
    try {
      const { data } = await axios.get(`/teachers/all/${schoolId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setTeachers(data?.teacherList);
    } catch (error) {
      console.log(error);
    }
  };

  const [subjects, setSubjects] = useState(null);

  const getAllSubjects = async () => {
    try {
      const { data } = await axios.get(`/subjects/getSubjects/${schoolId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setSubjects(data?.subjects);
    } catch (error) {
      console.log(error);
    }
  };

  const [rooms, setRooms] = useState(null);

  const getAllRooms = async () => {
    try {
      const { data } = await axios.get(
        `/classrooms/getClassroomsBySchoolId/${schoolId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setRooms(data?.classrooms);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTeachers();
    getAllSubjects();
    getAllRooms();
    getSchedules();
  }, [schoolId]);

  const [personName, setPersonName] = React.useState([]);

  const [subjectName, setSubjectName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
    setDay(event.target.value);
  };

  const handleSubject = (event) => {
    const {
      target: { value },
    } = event;
    setSubjectName(typeof value === "string" ? value.split(",") : value);

    const newArr = event.target.value?.map((val) => {
      return Number(val);
    });
    setSubjectId(newArr);
  };

  return (
    <>
      <div className="ScheduleDataMain">
        <p>Schedule Data</p>
        <div className="ScheduleData">
          <div className="EditSchedule">
            <h4>Schedule Data</h4>
            <button onClick={handleShow}>Add Schedule</button>
            <Modal
              size="lg"
              show={show}
              onHide={handleClose}
              centered
              className="CMmodal-main0modal"
              style={{ marginLeft: "100px" }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Schedule</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="Accedmic-modal-main">
                  <label htmlFor="ProgramName">Teacher</label>
                  <div className="Accedmic-modal-main-child1">
                    <Form.Select
                      value={teacherId}
                      onChange={(e) => setTeacherId(e.target.value)}
                      aria-label="Default select example"
                    >
                      <option value={""}>Select Teacher</option>
                      {teachers &&
                        teachers?.map((teacher) => (
                          <option value={teacher?.id}>
                            {teacher?.staffName}
                          </option>
                        ))}
                    </Form.Select>
                  </div>
                </div>

                <div className="Accedmic-modal-main1">
                  <label htmlFor="ProgramName">Grade Level</label>
                  <div className="Accedmic-modal-main-child1">
                    <Form.Select
                      value={gradeLevel}
                      onChange={(e) => setGradeLevel(e.target.value)}
                      aria-label="Default select example"
                    >
                      <option value={""}>Select Grade</option>
                      <option value="kindergarten">Kindergarten</option>
                      <option value="Grade 1">Grade 1</option>
                      <option value="Grade 2">Grade 2</option>
                      <option value="Grade 3">Grade 3</option>
                      <option value="Grade 4">Grade 4</option>
                      <option value="Grade 5">Grade 5</option>
                      <option value="Grade 6">Grade 6</option>
                      <option value="Grade 7">Grade 7</option>
                      <option value="Grade 8">Grade 8</option>
                      <option value="Grade 9">Grade 9</option>
                      <option value="Grade 10">Grade 10</option>
                      <option value="Grade 11">Grade 11</option>
                      <option value="Grade 12">Grade 12</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="Accedmic-modal-main1 Accedmic-modal-mainSubject">
                  <label htmlFor="ProgramName">Subject</label>
                  <div className="Accedmic-modal-main-child1">
                    <FormControl sx={{ m: 1, width: 300 }}>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={subjectName}
                        onChange={handleSubject}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={SecondProps}
                      >
                        {subjects &&
                          subjects.map((name) => (
                            <MenuItem
                              key={name?.id?.toString()}
                              value={name?.id?.toString()}
                            >
                              <Checkbox
                                checked={
                                  subjectName.indexOf(name?.id?.toString()) > -1
                                }
                              />
                              <ListItemText primary={name?.subjectName} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="Accedmic-modal-main1">
                  <label htmlFor="ProgramName">Classroom</label>
                  <div className="Accedmic-modal-main-child1">
                    <Form.Select
                      value={roomId}
                      onChange={(e) => setRoomId(e.target.value)}
                      aria-label="Default select example"
                    >
                      <option value={""}>Select Room</option>
                      {rooms &&
                        rooms?.map((room) => {
                          return (
                            <option value={room?.id}>
                              {room?.classroomName}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </div>
                </div>
                <div className="Accedmic-modal-main1 Accedmic-modal-mainDays">
                  <label htmlFor="ProgramName">Days</label>

                  <div>
                    <FormControl sx={{ m: 1, width: 300 }}>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={personName.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="Accedmic-modal-main1">
                  <label htmlFor="ProgramName">Start Time</label>
                  <div className="Accedmic-modal-main-child1">
                    <input
                      type="time"
                      value={startTime}
                      onChange={(e) => setStartTime(e.target.value)}
                      style={{ padding: "10px" }}
                    />
                  </div>
                </div>
                <div className="Accedmic-modal-main1">
                  <label htmlFor="ProgramName">End Time</label>
                  <div className="Accedmic-modal-main-child1">
                    <input
                      value={endTime}
                      onChange={(e) => setEndTime(e.target.value)}
                      type="time"
                      style={{ padding: "10px" }}
                    />
                  </div>
                </div>
                <div className="Accedmic-modal-main1">
                  <label htmlFor="ProgramName">Start Date</label>
                  <div className="Accedmic-modal-main-child1">
                    <input
                      value={startdate}
                      onChange={(e) => setStartDate(e.target.value)}
                      type="date"
                      style={{ padding: "10px" }}
                    />
                  </div>
                </div>
                <div className="Accedmic-modal-main1">
                  <label htmlFor="ProgramName">End Date</label>
                  <div className="Accedmic-modal-main-child1">
                    <input
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      type="date"
                      style={{ padding: "10px" }}
                    />
                  </div>
                </div>
                <hr style={{ width: "100%" }} />
                <div className="Accedmic-modal-main-child3">
                  <button onClick={handleClose}>Close</button>
                  <button onClick={addSchedule}>Save</button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <div className="CommentModal-Mmain">
            <Modall
              className="Allowmodalmain"
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClosee}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={open}>
                <Box sx={style}>
                  <div className="CommentModal-Mmain-child1">
                    <h4>Delete Data</h4>
                    <p>
                      Are you sure you want to delete this Data? This will
                      remove the Data and can’t be undone.
                    </p>
                    <div className="CommentModal-Mmain-child2">
                      <button
                        className="
                      CommentModal-Mmain-child2-allow
                      "
                        onClick={handleClosee}
                      >
                        CANCEL
                      </button>
                      <button
                        onClick={handleDeleteSchedule}
                        className="CommentModal-Mmain-child2-Dont-allow"
                      >
                        DELETE
                      </button>
                    </div>
                  </div>
                </Box>
              </Fade>
            </Modall>
          </div>
          <div className="ScheduleDataTable">
            <table className="table-overflow">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Teacher </th>
                  <th>Grade Level</th>
                  <th>Subject</th>
                  <th>Classroom Id</th>
                  <th>Day</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {schedules?.map((schedule) => {
                  return (
                    <tr>
                      <td>{schedule?.id}</td>
                      <td>
                        {schedule?.Teacher?.firstName}{" "}
                        {schedule?.Teacher?.lastName}
                      </td>
                      <td>{schedule?.gradeLevel}</td>
                      <td>{schedule?.subjectId}</td>
                      <td>{schedule?.classroomId}</td>
                      <td>
                        <span>{schedule?.day?.join(", ")}</span>
                      </td>
                      <td>{schedule?.startTime}</td>
                      <td>{schedule?.endTime}</td>
                      <td>{schedule?.startDate?.split("T")[0]}</td>
                      <td>{schedule?.endDate?.split("T")[0]}</td>
                      <td>
                        <FaEdit onClick={() => setEditId(schedule?.id)} />
                        <MdDelete
                          onClick={() => {
                            handleOpen();
                            setDeleteId(schedule?.id);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default ScheduleData;
