import React, { useEffect, useState } from "react";
import "./Guidance.css";
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoPersonCircleOutline } from "react-icons/io5";
import { AiOutlineMessage } from "react-icons/ai";
import print from "../Medical/flat-color-icons_print.png";
import axios from "axios";
import { useSelector } from "react-redux";
import { baseURL } from "../../../constants";
axios.defaults.baseURL = baseURL;

function Guidance() {
  const {studentId} = useSelector((state)=> state.studentId)

  const [discipline, setDiscipline] = useState([]);

  const fetchData = async () => {
    try {
      const { data } = await axios.get(`/disciplines/getDisciplineByStudentId/${studentId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setDiscipline(data.disciplines)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [studentId]);
  function handlePrint() {
    window.print()
  }

  
  return (
    <div className="Guidance-Main">
      <div className="Guidance-Top">
        <h3>Discipline Records</h3>
      </div>
      <div className="Guidance-BodyMain">
        <div className="Guidance-Second">
          <div className="Guidance-Second-top">
            <h3>Attitude Problem (A)</h3>
            <button onClick={handlePrint}>
              <img src={print} alt="#" />
              Print Discipline Record
            </button>
          </div>
        </div>
        {discipline.map((dic, index)=>(
          <div className="Guidance-Body" key={index}>
          <h3>
            <FaMapMarkerAlt />
            {dic?.Location}
          </h3>
          <h3>
            <IoPersonCircleOutline />
            Status: {dic?.status}
          </h3>
          <h3>
            <AiOutlineMessage />
            {dic?.comment}
          </h3>
          <h3>
              <span>Possible Motivation: </span>{dic?.PossibleMotivation}
          </h3>
          <h3>
            <span>Consequence: </span>{dic?.Consequence}
          </h3>
          <h3>
            <span>Last Update: </span>{dic?.updatedAt?.split("T")[0]}
          </h3>
        </div>
        ))}
      </div>
    </div>
  );
}

export default Guidance;
