import React, { useEffect, useState } from "react";
import "./AddGreats.css";
import { Country } from "country-state-city";
import Dummy from "../../../images/dummy-user.png";
import axios from "axios";
import { baseURL } from "../../../../../constants";
import { useParams } from "react-router-dom";
axios.defaults.baseURL = baseURL;

const AddGreats = () => {
  const [student, setStudent] = useState(null);
  const params = useParams();
  const getStudent = async () => {
    try {
      const { data } = await axios.get(
        `/students/profile/${params?.studentId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setStudent(data?.student);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStudent();
  }, []);

  const [school, setSchool] = useState(null);

  const getSchoolForStudent = async () => {
    try {
      if (student) {
        const { data } = await axios.get(
          `/schools/getschoolById/${student?.schoolId}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setSchool(data?.school);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchoolForStudent();
  }, [student]);

  return (
    <div className="AddGreats-main">
      <div className="AddGreats-main-child-1">
        <div className="AddGreats-main-child-1-1">
          <div className="AddGreats-main-img">
            {school?.logo && (
              <img src={`${baseURL}/images/${school?.logo}`} alt="img" />
            )}
            <div className="AddGreats-main-child-1-tixt">
              <h2>{school?.name} </h2>
              <p>
                {school?.address1}, {school?.address2}, {school?.city}
                {Country.getCountryByCode(school?.country)?.name},
              </p>
            </div>
          </div>
          <div className="AddGreats-main-child-1-buttons">
            <button>Student Progress Report</button>
            <p>Powered By Mycentrality </p>
          </div>
        </div>
        <div className="AddGreats-main-child-1-2">
          <h3>Report Generated on January 3, 2024</h3>
        </div>
      </div>
      <div className="AddGreats-main-child-2">
        <div className="AddGreats-main-child-2-1">
          <img
            onError={({ currentTarget }) => {
              currentTarget.src = Dummy;
            }}
            src={`${baseURL}/images/${student?.profilePicture}`}
            alt="cartoonimg"
          />
        </div>
        <div className="AddGreats-main-child-2-2">
          <h2>
            {student?.firstName} {student?.middleName} {student?.lastName}
          </h2>
          <table>
            <thead>
              <tr>
                <td>Studebt ID: {student?.id}</td>
                <td>Alternte ID: {student?.alternateId}</td>
                <td>Date of birth: {student?.dateOfBirth?.split("T")[0]}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Grade: {student?.gradeLevel} </td>
                <td>Gender: {student?.gender}</td>
                <td>
                  Highest Qualitification: {student?.highestQualification}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AddGreats;
