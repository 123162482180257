import React, { useState, useEffect } from "react";
import "../../../StudentComponents/TopSearchBar/TopSearchBar.css";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import Notification from "../../ADDnotification/Notification";
import LogoutModal from "./LogoutModal/LogoutModal";
import axios from "axios";
import { baseURL } from "../../../../constants";
import {
  addSchoolId,
  getAllSchools,
} from "../../../../redux/actions/userAction";
import { Link } from "react-router-dom";
import AdminOModal from "../../Adminoverviewmodal/AdminOModal";
import { useDispatch, useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

function ADDNestedTop() {
  const [notificationClicked, setNotificationClicked] = useState(false);

  // const [allSchools, setAllSchools] = useState(null);

  // const [schoolList, setSchoolList] = useState(null);

  const { schools } = useSelector((state) => state.getAllSchools);
  const { schoolId } = useSelector((state) => state.schoolId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSchools());
  }, []);

  const handleSchoolId = (e) => {
    dispatch(addSchoolId(e.target.value));
  };
  return (
    <div
      className="TopSearchBar-main "
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div className="TopSearchBar-one-Select">
        {/* <img src={Budeshila} alt="#" /> */}
        <select onChange={handleSchoolId}>
          {schools &&
            schools?.map((school, index) => {
              return (
                <option
                  selected={schoolId == school?.id ? true : false}
                  value={school?.id}
                >
                  {school?.name}{" "}
                </option>
              );
            })}
        </select>
      </div>
      <div className="TopSearchBar-two">
        <div className="TopSearchBar-Alpha">
          <div className="icon-container">
            <AdminOModal />
          </div>
          <Link
            to="/ChatChatBox"
            className="icon-container"
            style={{ textDecoration: "none" }}
          >
            <ChatOutlinedIcon />
          </Link>
          <div
            className={`icon-container1 ${
              notificationClicked ? "clicked" : ""
            }`}
            onClick={() => setNotificationClicked(true)}
          >
            <Notification />
          </div>
          <LogoutModal />
        </div>
      </div>
    </div>
  );
}

export default ADDNestedTop;
