import image1 from "../images/bogdan2 2 (1).png";
import image2 from "../images/bogdan2 2 (2).png";
import image3 from "../images/bogdan2 2 (3).png";
import image4 from "../images/bogdan2 2.png";
import image5 from "../images/bogdan2 2 (4).png";
import image6 from "../images/bogdan2 2 (1).png";
import image7 from "../images/bogdan2 2 (3).png";
const NotificationData = [
  {
    image: image1,
    name: "Bessie Cooper",
    time: "08:15",
    type: "Order",
    dotIcons: "●",
  },
  {
    image: image2,
    name: "Marvin McKinney",
    time: "08:15",
    type: "transfer",
    dotIcons: "●",
  },
  {
    image: image3,
    name: "Courtney Henry",
    time: "08:15",
    type: "transfer",
  },

  {
    image: image4,
    name: "Darrell Steward",
    time: "08:15",
    type: "transfer",
  },

  {
    image: image5,
    name: "Esther Howard",
    time: "08:15",
    type: "transfer",
  },
  {
    image: image6,
    name: "Marvin McKinney",
    time: "08:15",
    type: "transfer",
  },

  {
    image: image7,
    name: "Kathryn Murphy",
    time: "08:15",
    type: "transfer",
  },
];

export default NotificationData;
