import React, { useEffect } from "react";
import "./SubjectModal.css";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { IoAdd } from "react-icons/io5";
import Form from "react-bootstrap/Form";
import toast from "react-hot-toast";
import axios from "axios";
import { baseURL } from "../../../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

const SubjectModal = ({
  func = () => {},
  isEdit,
  setIsEdit,
  subject,
  setSubject,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setIsEdit("");
    setSubject({});
  };
  const handleShow = () => setShow(true);

  const [subjectName, setSubjectName] = useState("");
  const [creditHours, setCreditHours] = useState("");
  const [qualityPoints, setQualityPoints] = useState("");
  const [subjectCode, setSubjectCode] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isActive, setIsActive] = useState("");

  useEffect(() => {
    if (isEdit) {
      handleShow();
    }
  }, [isEdit]);
  const { schoolId } = useSelector((state) => state.schoolId);

  const addSubject = async () => {
    try {
      if (!isEdit) {
        await axios.post(
          `/subjects/addSubject`,
          {
            schoolId: Number(schoolId),
            subjectName,
            creditHours,
            qualityPoints,
            subjectCode,
            description,
            startDate,
            endDate,
            isActive,
          },
          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Subject Added Successfully");
        handleClose();
        setSubjectName("");
        setCreditHours("");
        setQualityPoints("");
        setSubjectCode("");
        setDescription("");
        setStartDate("");
        setEndDate("");
        setIsActive("");
      } else {
        await axios.put(
          `/subjects/updateSubject/${isEdit}`,
          {
            subjectName,
            creditHours,
            qualityPoints,
            subjectCode,
            description,
            startDate,
            endDate,
            isActive,
          },
          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Subject Updated Successfully");
      }

      func();
      handleClose();
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (subject) {
      setSubjectName(subject.subjectName);
      setCreditHours(subject.creditHours);
      setQualityPoints(subject.qualityPoints);
      setSubjectCode(subject.subjectCode);
      setDescription(subject.description);
      setStartDate(subject.startDate);
      setEndDate(subject.endDate);
      setIsActive(subject.isActive);
    }
  }, [subject]);

  return (
    <div className="CMmodal-main">
      <div
        variant="primary"
        onClick={handleShow}
        className="myassingment-SeC-button-btn"
      >
        <IoAdd />
        Add new Subject
      </div>

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        centered
        className="CMmodal-main0modal"
        style={{ marginLeft: "100px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Subject</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="Subject-modal-main">
              <label htmlFor="ProgramName">Subject Name</label>
              <div className="Subject-modal-main-child1">
                <input
                  value={subjectName}
                  onChange={(e) => setSubjectName(e.target.value)}
                  type="text"
                  id="text"
                  placeholder="Subject Name"
                />
              </div>
            </div>

            <div className="Subject-modal-main1">
              <label htmlFor="ProgramName">Subject Code</label>
              <div className="Subject-modal-main-child1">
                <input
                  value={subjectCode}
                  onChange={(e) => setSubjectCode(e.target.value)}
                  id="number"
                  type="text"
                  placeholder="Subject Code"
                />
              </div>
            </div>
            <div className="Subject-modal-main1">
              <label htmlFor="ProgramName">Description</label>
              <div className="Subject-modal-main-child1">
                <input
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  id="number"
                  type="text"
                  placeholder="Description"
                />
              </div>
            </div>
            <div className="Subject-modal-main1">
              <label htmlFor="ProgramName">Credit Hours</label>
              <div className="Subject-modal-main-child1">
                <input
                  value={creditHours}
                  onChange={(e) => setCreditHours(e.target.value)}
                  id="number"
                  type="number"
                  placeholder="Credit Hours"
                />
              </div>
            </div>
            <div className="Subject-modal-main1">
              <label htmlFor="ProgramName">Quality Points</label>
              <div className="Subject-modal-main-child1">
                <input
                  value={qualityPoints}
                  onChange={(e) => setQualityPoints(e.target.value)}
                  type="number"
                  id="number"
                  placeholder="Quality Points"
                />
              </div>
            </div>
            <div className="Subject-modal-main1">
              <label htmlFor="ProgramName">Start Date</label>
              <div className="Subject-modal-main-child1">
                <input
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  type="date"
                  id="date"
                  placeholder="Start Date"
                />
              </div>
            </div>
            <div className="Subject-modal-main1">
              <label htmlFor="ProgramName">End Date</label>
              <div className="Subject-modal-main-child1">
                <input
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  type="date"
                  id="date"
                  placeholder="Start Date"
                />
              </div>
            </div>
            <div className="Subject-modal-main1">
              <label htmlFor="ProgramName">Action</label>
              <div className="Subject-modal-main-child1">
                <Form.Select
                  value={isActive}
                  onChange={(e) => setIsActive(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value={""} selected disabled>
                    IsActive
                  </option>
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </Form.Select>
              </div>
            </div>
            <hr style={{ width: "100%" }} />
            <div className="Subject-modal-main-child3">
              <button onClick={handleClose}>Close</button>
              <button onClick={addSubject}>Save</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SubjectModal;
