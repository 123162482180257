import React, { useEffect, useState } from "react";
import "./Medical.css";
import Alert from "./Frame 1686554853.png";
import print from "./flat-color-icons_print.png";
import { useSelector } from "react-redux";
import axios from "axios";

function Medical() {
  const { user } = useSelector((state) => state.getUserProfile);

  const [plan, setPlan] = useState(null);
  const getPlan = async () => {
    try {
      const { data } = await axios.get(
        `/medicalRecords/medicalRecordsBystudentId/${user?.dataValues?.id}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );

      setPlan(data?.medicalRecords);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPlan();
  }, [user]);

  function handlePrint() {
    window.print();
  }

  return (
    <div className="Medical-Main">
      <div className="Medical-Top">
        <h3>Medical Logarithms Options</h3>
      </div>
      <div className="Medical-Second">
        <h4>
          Health Problems:
          {plan &&
            plan.map((pln) => {
              return <span>{`${pln?.HealthProblems}, `}</span>;
            })}{" "}
        </h4>
        <div className="Medical-Second-right">
          <div className="Medical-Second-Child">
            <img src={Alert} alt="#" />
            <p>Wear Glasses All the Time</p>
          </div>
          <button>Alert !</button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Code</th>
            <th>Start Time</th>
            <th>End Time</th>
          </tr>
        </thead>
        <tbody>
          {plan &&
            plan.map((pln) => {
              return (
                <tr>
                  <td>
                    {pln?.date?.split("T")[0]} <br />
                    <span>{pln?.comment}</span>
                  </td>
                  <td>{pln?.code}</td>
                  <td>{pln?.startTime}</td>

                  <td>{pln?.endTime}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="PrintImg">
        <button onClick={handlePrint}>
          <img src={print} alt="#" />
          Print Medical Profile
        </button>
      </div>
    </div>
  );
}
export default Medical;
