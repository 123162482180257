import React, { useEffect, useState } from "react";
import "./SchoolCalander.css";
import { FaCaretRight } from "react-icons/fa";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { FaPlus } from "react-icons/fa6";
import Tab from "@mui/material/Tab";
import { TabPanel } from "@mui/lab";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";
import axios from "axios";
import { baseURL } from "../../../../../constants";
import toast from "react-hot-toast";
import Notice from "./Notice";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

const SchoolCalander = () => {
  const [deleteId, setDeleteId] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setDeleteId("");
    setOpen(false);
  };

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [lgShow, setLgShow] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
  };

  var formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
  ];

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const handleProcedureContentChange = (content) => {};

  // const toggleVisibleToAllSchool = () => {
  //   setVisibleToAllSchool(!visibleToAllSchool);
  // };

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [validFrom, setValidFrom] = useState("");
  const [validTo, setValidTo] = useState("");
  // const [visibleToAllSchool, setVisibleToAllSchool] = useState(false);
  const [visibleToSchool, setVisibleToSchool] = useState(false);
  const [visibleToStudents, setVisibleToStudents] = useState(false);
  const [visibleToParents, setVisibleToParents] = useState(false);
  const [visibleToVendor, setVisibleToVendor] = useState(false);
  const [visibleToTeacher, setVisibleToTeacher] = useState(false);
  const [visibleToAllSchools, setVisibleToAllSchools] = useState(false);
  const [visibleToGov, setVisibleToGov] = useState(false);
  const { schoolId } = useSelector((state) => state?.schoolId);

  const [currentNotices, setCurrentNotices] = useState(null);

  const getCurrentNotices = async () => {
    try {
      const { data } = await axios.get(
        `/notices/getCurrentNoticesBySchool/${schoolId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setCurrentNotices(data?.notices);
    } catch (error) {
      console.log(error);
    }
  };

  const [upcomingNotices, setUpcomingNotices] = useState(null);

  const getUpcomingNotices = async () => {
    try {
      const { data } = await axios.get(
        `/notices/getUpcomingNoticesBySchool/${schoolId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setUpcomingNotices(data?.notices);
    } catch (error) {
      console.log(error);
    }
  };
  const [pastNotices, setPastNotices] = useState(null);

  const getPastNotices = async () => {
    try {
      const { data } = await axios.get(
        `/notices/getPastNoticesBySchool/${schoolId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setPastNotices(data?.notices);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCurrentNotices();
    getUpcomingNotices();
    getPastNotices();
  }, [schoolId]);

  const reGetData = () => {
    getUpcomingNotices();
    getCurrentNotices();
    getPastNotices();
  };

  const handleAddNoticeboard = async () => {
    try {
      await axios.post(
        `/notices/addNotice`,
        {
          schoolId,
          title,
          content,
          validFrom,
          validTo,
          visibleToSchool,
          visibleToStudents,
          visibleToParents,
          visibleToVendor,
          visibleToTeacher,
          visibleToAllSchools,
          visibleToGov,
        },
        {
          headers: {
            "Content-Type": "application/json",
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      handleClose();
      reGetData();
      setLgShow(false);
      setTitle("");
      setContent("");
      setValidFrom("");
      setValidTo("");
      setVisibleToSchool(false);
      setVisibleToStudents(false);
      setVisibleToParents(false);
      setVisibleToVendor(false);
      setVisibleToTeacher(false);
      setVisibleToAllSchools(false);
      setVisibleToGov(false);

      toast.success("Notice Added Successfully");
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  return (
    <div className="School-Calander-main">
      <div className="School-Calander-main-first">
        <Link to="/SchoolInformation">School</Link>
        <FaCaretRight className="Carleft-icons-Fa" />
        <p>Notice</p>
      </div>
      <div className="School-Calander-main-child-1">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="School-Calander-main-child-1-test-data-1">
                <div className="test2">
                  <TabList onChange={handleChange}>
                    <Tab label="Current" value="1" className="Currejdkd" />
                    <Tab label="Upcoming" value="2" className="Currejdkd" />
                    <Tab label="Past" value="3" className="Currejdkd" />
                  </TabList>
                </div>

                <div
                  className="School-Calander-main-Tabs-buttn"
                  onClick={() => setLgShow(true)}
                >
                  <FaPlus />
                  <div>
                    <button>Add new</button>
                  </div>
                </div>
              </div>
            </Box>
            {/* ////////./././././ */}
            <div className="ADDNewNotice">
              <div>
                <Modal
                  className="newmodalmm"
                  size="lg"
                  show={lgShow}
                  onHide={() => setLgShow(false)}
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Header closeButton className="new-Activity-header">
                    <p>Add Notice</p>
                  </Modal.Header>
                  <Modal.Title id="example-modal-sizes-title-lg"></Modal.Title>
                  <Modal.Body>
                    <div className="NoticeModalAdd-mainn">
                      <div>
                        <label htmlFor="title">Title</label>
                        <div>
                          <input
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            type="text"
                            id="text"
                            placeholder="Title"
                          />
                        </div>
                      </div>
                      <div className="NoticeModalAdd-mainn-child-1">
                        <label htmlFor="Content">Content</label>
                        <div className="reatctquill">
                          <ReactQuill
                            value={content}
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            placeholder="insert text.."
                            onChange={setContent}
                          ></ReactQuill>
                        </div>
                      </div>
                      <div className="NoticeModalAdd-mainn-child-2">
                        <div>
                          <label htmlFor="form">From</label>
                          <div>
                            <input
                              type="date"
                              value={validFrom}
                              onChange={(e) => setValidFrom(e.target.value)}
                            />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="To">To</label>
                          <div>
                            <input
                              type="date"
                              value={validTo}
                              min={fromDate}
                              onChange={(e) => setValidTo(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="NoticeModalAdd-mainn-child-7">
                      <div className="NoticeModalAdd-mainn-child-4">
                        <div className="childnotice1">
                          <input
                            checked={visibleToSchool}
                            onChange={() =>
                              setVisibleToSchool(!visibleToSchool)
                            }
                            type="checkbox"
                          />
                          <p>School</p>
                        </div>
                        <div className="childnotice1">
                          <input
                            checked={visibleToStudents}
                            onChange={() =>
                              setVisibleToStudents(!visibleToStudents)
                            }
                            type="checkbox"
                          />
                          <p>Student</p>
                        </div>
                        <div className="childnotice1">
                          <input
                            checked={visibleToParents}
                            onChange={() =>
                              setVisibleToParents(!visibleToParents)
                            }
                            type="checkbox"
                          />
                          <p>Parent</p>
                        </div>
                      </div>
                      <div className="NoticeModalAdd-mainn-child-5">
                        <div className="childnotice1">
                          <input
                            checked={visibleToVendor}
                            onChange={() =>
                              setVisibleToVendor(!visibleToVendor)
                            }
                            type="checkbox"
                          />
                          <p>Vendor</p>
                        </div>
                        <div className="childnotice1">
                          <input
                            checked={visibleToTeacher}
                            onChange={() =>
                              setVisibleToTeacher(!visibleToTeacher)
                            }
                            type="checkbox"
                          />
                          <p>Teacher</p>
                        </div>
                        <div className="childnotice1">
                          <input
                            checked={visibleToAllSchools}
                            onChange={() =>
                              setVisibleToAllSchools(!visibleToAllSchools)
                            }
                            type="checkbox"
                          />
                          <p>All Schools</p>
                        </div>
                        <div className="childnotice1">
                          <input
                            checked={visibleToGov}
                            onChange={() => setVisibleToGov(!visibleToGov)}
                            type="checkbox"
                          />
                          <p>Government</p>
                        </div>
                      </div>
                    </div>
                    <div className="NoticeModalAdd-mainn-child-8">
                      <button
                        onClick={() => {
                          handleClose();
                          setLgShow(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button onClick={handleAddNoticeboard}>Submit</button>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
            {/* ////////./././././ */}

            <TabPanel value="1" className="School-Calander-main-Tabs">
              {currentNotices && (
                <Notice data={currentNotices} func={reGetData} />
              )}
            </TabPanel>
            <TabPanel value="2">
              {upcomingNotices && (
                <Notice data={upcomingNotices} func={reGetData} />
              )}
            </TabPanel>
            <TabPanel value="3">
              {" "}
              {pastNotices && <Notice data={pastNotices} func={reGetData} />}
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
};

export default SchoolCalander;
