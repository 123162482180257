import React from "react";
import "./CustomerDetailsFiltr.css";
import { IoIosSearch } from "react-icons/io";
import { RiEqualizerFill } from "react-icons/ri";

const CustomerDetailsFiltr = () => {
  return (
    <div className="customerdetail-main1">
      <div>
        <h3>Attendance Summery</h3>
      </div>
      <div className="customerdetail-child-2">
        <div className="customerdetail-child-2-search">
          <IoIosSearch />
          <input type="text" placeholder="Search . . ." />
        </div>
        <div className="customerdetail-child-2-calander1">
          <input type="date" />
        </div>
        <div className="customerdetail-child-2-setting">
          <RiEqualizerFill />
          <p>Filters</p>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetailsFiltr;
