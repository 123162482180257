import React, { useEffect, useState } from "react";
import "./Request.css";
import Calender from "./material-symbols_calendar-month-sharp.png";
import { IoCaretForward } from "react-icons/io5";
import Dummy from '../../../AdminComponents/images/dummy-user.png'
import axios from "axios";
import { baseURL } from "../../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;
function Request() {
  const [mockupData, setMockupData] = useState([]);

  const { user } = useSelector((state) => state.getUserProfile);

  const getNotifications = async () => {
    try {
      if (user) {
        let { data } = await axios.get(
          `/makeuprequests/getRequestsByTeacher/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setMockupData(data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <>
      <p className="MockupRequest">
        Mockup Request <IoCaretForward />
      </p>
      <div className="Request-Main">
        <h2>Requests</h2>
        {mockupData?.requests?.map((dt) => {
          return (
            <div className="Request-Div">
              <h3>{dt?.department}</h3>
              <div className="Request-Main2">
                <div className="Request-Child">
                  <img src={`${baseURL}/images/${dt?.profilePicture}`}
                   onError={({ currentTarget }) => {
                    currentTarget.src = Dummy;
                  }}
                  alt="" />
                  <h4>{dt?.studentName}</h4>
                </div>
                <div className="Request-Child2">
                  <img src={Calender} alt="" />
                  <p>{dt.createdAt?.split("T")[0]}</p>
                </div>
                <button>View invoice</button>
              </div>
              <p className="Request-Childp">{dt.details}</p>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Request;
