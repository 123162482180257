import React, { useState } from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import "./ByDate.css";
import Datepicker from "./Date/DatePicker";
import { FaCalendarAlt } from "react-icons/fa";
import Dropdown from "react-bootstrap/Dropdown";

function ByDate() {
  const [showCalendar, setShowCalendar] = useState(false);

  const handleCheckboxChange = () => {
    setShowCalendar(!showCalendar);
  };

  return (
    <div className="BydateAll">
      <div className="BYdateSvg">
        <FaCalendarAlt />
      </div>
      <DropdownButton id="dropdown-basic-button" title="Select Date">
        <Dropdown href="#/action-1">
          <div className="ByDate-main">
            <div className="container Bydate">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="ByDate-h1">By Date</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 checkboxs">
                  <input type="checkbox" />
                  <label>This Week</label>
                </div>
                <div className="col-md-6 checkboxs">
                  <input type="checkbox" />
                  <label>Last Week</label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 checkboxs">
                  <input type="checkbox" />
                  <label>This Month</label>
                </div>
                <div className="col-md-6 checkboxs">
                  <input type="checkbox" />
                  <label>Last Month</label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 checkboxs">
                  <input type="checkbox" />
                  <label>This Year</label>
                </div>
                <div className="col-md-6 checkboxs">
                  <input type="checkbox" />
                  <label>Last Year</label>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12 mother">
                  <div className="LastInputCheckbox">
                    <input
                      type="checkbox"
                      id="showCalendar"
                      checked={showCalendar}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="showCalendar">Date Range</label>
                  </div>
                  <br />
                  {showCalendar && (
                    <div>
                      <div className="From-To">
                        <div className="btns">
                          <button>From</button>
                          <button>To</button>
                        </div>
                        <Datepicker />
                      </div>
                    </div>
                  )}
                </div>
                <div className="FIlter-Btn">
                  <button>Filter</button>
                </div>
              </div>
            </div>
          </div>
        </Dropdown>
      </DropdownButton>
    </div>
  );
}

export default ByDate;
