import React from "react";
import "./Medical.css";
import Alert from "./Frame 1686554853.png";
import print from "./flat-color-icons_print.png";
import { IoMdArrowBack } from "react-icons/io";
import { useEffect, useState } from "react";
import Dummy from "../../AdminComponents/images/dummy-user.png";
import Graph from "../../StudentComponents/MyProfile/Group 1000006080.png";
import Green from "../../StudentComponents/MyProfile/Group 1000006079.png";
import Red from "../../StudentComponents/MyProfile/Group 1000006078.png";
import Timer from "../../StudentComponents/MyProfile/Group 1000006077.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../../constants";
axios.defaults.baseURL = baseURL;
function Medical() {
  const [student, setStudent] = useState(null);
  function handlePrint() {
    window.print();
  }

  const getStudent = async () => {
    try {
      const { data } = await axios.get(
        `/students/profile/${localStorage.getItem("teacherStudent")}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setStudent(data?.student);
    } catch (error) {
      console.log(error);
    }
  };

  const [medicalLogs, setMedicalLogs] = useState(null);

  const getMedicalLog = async () => {
    try {
      const { data } = await axios.get(
        `/medicalRecords/medicalRecordsBystudentId/6`,
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );
      setMedicalLogs(data?.medicalRecords);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStudent();
    getMedicalLog();
  }, []);

  const [assingment, setAssingment] = useState([]);
  const { user } = useSelector((state) => state.getUserProfile);

  const getAssingment = async () => {
    try {
      const { data } = await axios.get(
        `/assignments/getAssignmentCountByStudent/${localStorage.getItem(
          "teacherStudent"
        )}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setAssingment(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAssingment();
  }, []);

  const [gpa, setGpa] = useState([]);

  const getGpa = async () => {
    try {
      const { data } = await axios.get(
        `/grades/calculateGPA/${localStorage.getItem("teacherStudent")}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setGpa(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getGpa();
  }, []);

  const [completed, setCompleted] = useState([]);

  const getCompleted = async () => {
    try {
      const { data } = await axios.get(
        `/assignmentSubmissions/getSubmittedAssignmentsCountByStudent/${localStorage.getItem(
          "teacherStudent"
        )}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setCompleted(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCompleted();
  }, []);

  return (
    <div className="Medical-Main">
      <div className="StuProfile-main">
        <div className="StuProfile-Top">
          <div className="StuProfile-Top1">
            <IoMdArrowBack style={{ cursor: "pointer" }} />
            <h1>Student Profile</h1>
          </div>
          <div className="StuProfile-Top2">
            <Link to="/TeacherChat" className="StuProfile-Top-btn1">
              Parent Chat
            </Link>
            <Link to="/TeacherChat" className="StuProfile-Top-btn2">
              Student Chat
            </Link>
            <Link to="/TeacherChat" className="StuProfile-Top-btn2">
              Admin Chat
            </Link>
          </div>
        </div>

        <div className="Myprofile-main-1">
          <div className="Myprofile-main-child-1">
            <div className="profile">
              <div className="profile-child-1">
                <img
                  onError={({ currentTarget }) => {
                    currentTarget.src = Dummy;
                  }}
                  src={`${baseURL}/images/${student?.profilePicture}`}
                />
              </div>
              <div className="profile-child-2">
                <div className="profile-child-2-1">
                  <h2>{student?.firstName + " " + student?.lastName}</h2>
                </div>
                <div className="profile-child-2-2">
                  <p>{student?.program}</p>
                  <button>
                    <span>●</span>
                    {student?.online ? "Online" : "Offline"}
                  </button>
                </div>
                <div className="profile-child-2-3">
                  <p>{student?.loggedInSince?.split("T")[0]}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="Myprofile-main-child-2">
            <div className="Myprofile-main-child-2-1">
              <img src={Timer} alt="#" />
              <h3>{student?.createdAt?.split("T")[0]}</h3>
            </div>
            <div className="Myprofile-main-child-2-2">
              <div className="div-1">
                <img src={Red} alt="#" />
                <h3>
                  Assignments <span>{assingment.assignmentCount}</span>
                </h3>
              </div>
              <div className="div-2">
                <img src={Green} alt="#" />
                <h3>
                  Completed <span>{completed.submittedAssignmentsCount}</span>
                </h3>
              </div>
            </div>
          </div>
          <div className="Myprofile-main-child-3">
            <div className="Myprofile-main-child-3-card-1">
              <img src={Graph} alt="#" />
            </div>
            <div className="Myprofile-main-child-3-card-2">
              <h3>GPA</h3>
              <h1>{gpa?.gpa}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="Medical-Top1">
        <h3>Medical Logarithms Options</h3>
      </div>
      <div className="Medical-Second">
        <h4>
          Health Problems:
          <span>
            {" "}
            {medicalLogs?.map((md) => `${md?.HealthProblems}, `)}
          </span>{" "}
        </h4>
        <div className="Medical-Second-right">
          <div className="Medical-Second-Child">
            <img src={Alert} alt="#" />
            <p>Wear Glasses All the Time</p>
          </div>
          <button>Alert !</button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Code</th>
            <th>Start Time</th>
            <th>End Time</th>
          </tr>
        </thead>
        <tbody>
          {medicalLogs?.map((log) => {
            return (
              <tr>
                <td>{log?.date?.split("T")[0]}</td>
                <td>{log?.code}</td>
                <td>{log?.startTime}</td>
                <td>{log?.endTime}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="PrintImg">
        <button onClick={handlePrint}>
          <img src={print} alt="#" />
          Print Medical Profile
        </button>
      </div>
    </div>
  );
}

export default Medical;
