import React, { useEffect, useState } from "react";
import "./TeacherClassRoom.css";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import "./CreatenewClas/CreateNewClass.css";
import toast from "react-hot-toast";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import axios from "axios";
import { baseURL } from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TeacherClassRoomTopSearch() {
  const [editId, setEditId] = useState("");
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleShow = () => setShow(true);
  const handleCloseShow = () => setShow(false);
  const handleClosee = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setThumbnailImage(null);
    setThumbnailPreview(null);
    setOpen(false);
    setEditId("");
  };
  const [thumbnailImage, setThumbnailImage] = useState(
    "https://source.unsplash.com/640x640?computer"
  );
  const navigate = useNavigate();
  const [thumbnailPreview, setThumbnailPreview] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const [classrooms, setClassrooms] = useState(null);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const [schoolId, setSchoolId] = useState(null);

  const getSchoolByParentId = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/ParentBySchoolId/getSchoolByTeacherId/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setSchoolId(data.school.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchoolByParentId();
  }, [user]);

  const getAllClassrooms = async () => {
    try {
      if (user) {
        console.log(user, "this is user");
        const { data } = await axios.get(
          `/classrooms/getClassroomsByTeacherId/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        setClassrooms(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [subjects, setSubjects] = useState(null);

  const getSubjects = async () => {
    try {
      if (schoolId) {
        const { data } = await axios.get(`/subjects/getSubjects/${schoolId}`, {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        });

        setSubjects(data?.subjects);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllClassrooms();
    getSubjects();
  }, [schoolId]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setThumbnailImage(file);
        setThumbnailPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [classroom, setClassroom] = useState("");
  const [myName, setMyName] = useState("");

  const [subjectName, setSubjectName] = useState("");

  const addNewClassroom = async () => {
    try {
      const newForm = new FormData();
      if (schoolId && user) {
        thumbnailImage && newForm.append("classroomBanner", thumbnailImage);
        newForm.append("classroomName", classroom);
        newForm.append("subjectId", Number(subjectName));
        newForm.append("schoolId", Number(schoolId));
        newForm.append("teacherId", user.dataValues.id);

        if (!editId) {
          await axios.post("/classrooms/addClassroom", newForm, {
            headers: {
              "Content-Type": "multipart/form-data",
              accessToken: `${localStorage.getItem("token")}`,
            },
          });

          toast.success("Classroom Added");
        } else {
          await axios.put(`/classrooms/updateClassroom/${editId}`, newForm, {
            headers: {
              "Content-Type": "multipart/form-data",
              accessToken: `${localStorage.getItem("token")}`,
            },
          });

          toast.success("Classroom Updated");
        }

        getAllClassrooms();
        handleClose();
        handleCloseShow();
      }
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.message ||
            error?.response?.data?.details[0]?.detail
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const handleDeleteClass = async () => {
    try {
      await axios.delete(`/classrooms/removeClassroom/${deleteId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      toast.success("Classroom Deleted");

      getAllClassrooms();
      handleClose();
      handleCloseShow();
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else if (error?.response?.data?.error) {
        toast.error(
          error?.response?.data?.error[0]?.message ||
            error?.response?.data?.error[0]?.detail
        );
      } else {
        toast.error("Something went wrong!");
      }
    }
  };

  useEffect(() => {
    if (editId && classrooms) {
      setOpen(true);
      const getClassrooms = classrooms?.find((classroom) => {
        return editId === classroom?.id;
      });

      setClassroom(getClassrooms?.className);
      setMyName(getClassrooms?.myName);
      setSubjectName(getClassrooms?.subjectName);
    }
  }, [editId, classrooms]);

  return (
    <div className="TeacherClassRoomTopSearch">
      <div className="overview">
        <div className="view">
          <p>
            Overview{" "}
            <span>
              <IoIosArrowForward /> Classes
            </span>
          </p>
        </div>
        <div className="myClassRoom">
          <h5>
            <span>Classes</span>
          </h5>
        </div>
      </div>
      <div className="CommentModal-Mmain">
        <Modal
          className="Allowmodalmain"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={show}
          onClose={handleCloseShow}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={show}>
            <Box sx={style}>
              <div className="CommentModal-Mmain-child1">
                <h4>Delete</h4>
                <p>
                  Are you sure you want to delete this comment? This will remove
                  the comment and can’t be undone.
                </p>
                <div className="CommentModal-Mmain-child2">
                  <button
                    className="CommentModal-Mmain-child2-allow"
                    onClick={() => handleCloseShow()}
                  >
                    CANCEL
                  </button>
                  <button
                    onClick={handleDeleteClass}
                    className=" CommentModal-Mmain-child2-Dont-allow"
                  >
                    DELETE
                  </button>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>

      <div className="Classroom-child-2-mapa">
        {classrooms &&
          classrooms?.map((classroom, index) => (
            <div
              className="Classroom-child-2 teacher-Classroom-child-2"
              key={classroom?.Classroom?.id}
            >
              <div
                className="Classroom-child-2-main Teacher-Classroom-child-2-main"
                style={{ background: "#fff" }}
              >
                <div
                  className="classroom-child-2-img teacher-Classroom-child-2-img"
                  style={{
                    backgroundColor: index % 2 === 0 ? "#B1C1DF" : "#B798E0",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                    src={`${baseURL}/images/${classroom?.classroomBanner}`}
                    alt="#"
                  />
                </div>
                <p
                  style={{
                    color: index % 2 === 0 ? "#658BEB" : "#B1C1DF",
                  }}
                >
                  {classroom?.classroomName}
                </p>
                <div
                  className="Linkeded"
                  onClick={() => {
                    localStorage.removeItem("classroomId");
                    localStorage.setItem("classroomId", classroom?.id);
                    localStorage.removeItem("subjectId", classroom?.subjectId);
                    localStorage.setItem("subjectId", classroom?.subjectId);
                    navigate(`/TeacherNestedTabs/${classroom?.subjectId}`);
                  }}
                >
                  <button className="ViewClassBtn">View Class</button>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="custom-toggle"
  >
    {children}
  </button>
));

export default TeacherClassRoomTopSearch;
