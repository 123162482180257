import React, { useState, useRef } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import "./ADDdatausagepage.css";
import FolderImg from "../images/folderimage.png";
import FilterIcons from "../FilterIcons/FilterIcons";

function FolderUpload() {
  const [folderList, setFolderList] = useState([]);
  const fileInputRef = useRef(null);

  const handleFolderChange = (event) => {
    const files = event.target.files;

    if (files.length > 0 && files[0].webkitRelativePath) {
      let totalSize = 0;

      for (let i = 0; i < files.length; i++) {
        totalSize += files[i].size;
      }

      const sizeInMB = (totalSize / (1024 * 1024)).toFixed(2);
      const folderName = files[0].webkitRelativePath.split("/")[0];

      const uploadDate = new Date();
      const dayOfWeek = new Intl.DateTimeFormat("en-US", {
        weekday: "long",
      }).format(uploadDate);

      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);

      const timeLabel =
        today.toDateString() === uploadDate.toDateString()
          ? "Today"
          : yesterday.toDateString() === uploadDate.toDateString()
          ? "Yesterday"
          : uploadDate.toLocaleDateString();

      const newFolder = {
        name: folderName,
        size: sizeInMB,
        dayOfWeek: dayOfWeek,
        timeLabel: timeLabel,
      };

      setFolderList((prevList) => [...prevList, newFolder]);
    } else {
      console.error("No files or invalid file selected");
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="ADDdatausage-main">
      <div className="ADDdatausage-button">
        <div>
          <FilterIcons />
        </div>
        <button
          onClick={handleClick}
          style={{ display: "flex", alignItems: "center" }}
        >
          <AddOutlinedIcon />
          <span style={{ color: "white" }}>Upload Data</span>
        </button>
      </div>
      <input
        type="file"
        webkitdirectory="true"
        onChange={handleFolderChange}
        style={{ display: "none" }}
        ref={fileInputRef}
      />

      {folderList.length > 0 && (
        <table className="ADDdatausage-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Date Created</th>
              <th>Date Modified</th>
              <th>Size</th>
            </tr>
          </thead>
          <tbody>
            {folderList.map((folder, index) => (
              <tr key={index}>
                <td className="FolderImageTD">
                  <img src={FolderImg} alt="#" />
                  {folder.name}
                </td>
                <td>{folder.dayOfWeek}</td>
                <td>{folder.timeLabel}</td>
                <td style={{ color: "#FF4439" }}>{folder.size} MB</td>
                <td>
                  <CheckCircleOutlinedIcon />
                </td>
                <td>
                  <MoreVertOutlinedIcon />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default FolderUpload;
