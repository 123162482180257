import React, { useEffect, useState } from "react";
import { RiFileExcel2Line } from "react-icons/ri";
import axios from "axios";
import Shoaib from "../../../StudentComponents/images/Shoaib.png";
import Dummy from "../../../AdminComponents/images/dummy-user.png";
import { baseURL } from "../../../../constants";
import "./Transcripts.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getMedicalHistoryStudentId,
  getParentStudents,
  getUserProfile,
} from "../../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;
function Transcripts() {
  const [transcript, setTranscript] = useState(null);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.getUserProfile);
  const { loading, students, error } = useSelector((state) => state.user);

  const { studentId: currentStudentId } = useSelector(
    (state) => state.medicalHistoryStudentId
  );

  useEffect(() => {
    if (user) {
      dispatch(getParentStudents(user?.dataValues?.id));
    }
  }, [user]);

  const [allStudents, setAllStudents] = useState([]);

  const [currentStudent, setCurrentStudent] = useState({
    name: "",
    image: "",
  });

  const [studentId, setStudentId] = useState(null);

  useEffect(() => {
    if (currentStudentId && students) {
      const student = students.find((st) => st.studentId == currentStudentId);

      setCurrentStudent({
        name: student?.Student?.firstName || "Name",
        image: `${baseURL}/images/${student?.Student?.profilePicture}`,
      });
    } else if (students && students?.length > 0) {
      setCurrentStudent({
        name: students?.Student?.[0]?.firstName || "Name",
        image: `${baseURL}/images/${students?.[0]?.Student?.profilePicture}`,
      });
      handleStoreId(students[0]?.studentId);
    }
  }, [students, currentStudentId]);

  const handleStoreId = (id) => {
    dispatch(getMedicalHistoryStudentId(id));
  };

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const [schoolId, setSchoolId] = useState(null);

  const getSchoolByStudentId = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/ParentBySchoolId/getschoolBystudentId/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setSchoolId(data.school.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(schoolId, "juu school Id");

  useEffect(() => {
    getSchoolByStudentId();
  }, [user]);

  const getTranscripts = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/students/transcript/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        setTranscript(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [results, setResults] = useState(null);

  const getStudentResults = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/results/getStudentResults/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setResults(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTranscripts();
    getStudentResults();
  }, [user]);

  const [school, setSchool] = useState("");

  const getSchool = async () => {
    try {
      if (schoolId) {
        const { data } = await axios.get(`/schools/getschoolById/${schoolId}`, {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        });
        setSchool(data?.school);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchool();
  }, [schoolId]);
  const st = [
    {
      name: "Aamir",
      image: Shoaib,
    },
    {
      name: "Rohan",
      image: Shoaib,
    },
    {
      name: "Ali",
      image: Shoaib,
    },
  ];
  return (
    <div className="Transcripts-main1">
      {/* <h1 className="Transcripts-Head">School Transcripts</h1> */}
      {/* <div className="transcitps">
        <div className="Transcripts-First-inputs">
          <div className="SCHOOLNAME">
            <label>SCHOOL NAME</label>
            <input
              readOnly
              value={transcript?.School?.name}
              style={{ marginLeft: "33px" }}
            />
            <br />
            <label>SCHOOL ADDRESS</label>
            <input readOnly value={transcript?.School?.address1} />
            <br />
            <label>SCHOOL PHONE</label>
            <input
              readOnly
              value={transcript?.School?.telephone}
              className="First-input"
            />
          </div>
          <div className="school-Logo">
            <img
              style={{ width: "100px", height: "100px", objectFit: "contain" }}
              src={`${baseURL}/images/${school?.logo}`}
            />
          </div>
        </div>
        <div className="Transcripts-Second">
          <div className="Transcripts-child1">
            <div className="inputts">
              <div className="inputt">
                <label>Student Name</label>
                <input
                  readOnly
                  value={`${transcript?.firstName} ${transcript?.lastName}`}
                />
              </div>
              <div className="inputt">
                <label>Student Id</label>
                <input readOnly value={`${user?.dataValues?.id}`} />
              </div>
              <div className="inputt ">
                <label>Expected Graduation Date</label>
                <input
                  readOnly
                  value={`${transcript?.expectedGraduationDate?.split("T")[0]}`}
                  className="mainInput"
                />
              </div>
            </div>
          </div>
          <div className="Transcripts-child1 Inputts">
            <div className="inputts ">
              <div className="inputt">
                <label>Credits Earned</label>
                <input readOnly value={transcript?.creditsEarned} />
              </div>
              <div className="inputt">
                <label>Cumulative GPA</label>
                <input readOnly value={transcript?.cumulativeGPA} />
              </div>
              <div className="inputt">
                <label>Date of birth</label>
                <input
                  readOnly
                  value={transcript?.dateOfBirth?.split("T")[0]}
                />
              </div>
            </div>
          </div>
        </div>
        <h3>Test Scores</h3>
        <div className="TestScore_div">
          <div className="inputts">
            <div className="inputt">
              <label>PSAT Date</label>
              <input readOnly value={transcript?.psat} />
            </div>
            <div className="inputt">
              <label>SAT Date</label>
              <input readOnly value={transcript?.sat} />
            </div>
            <div className="inputt">
              <label>ACT Date</label>
              <input readOnly value={transcript?.act} className="ACTDaTe" />
            </div>
          </div>
        </div>
      </div> */}
      <div className="Transcript-MainTop">
        <div className="Transcript-Top">
          <h3>Transcript</h3>
          <div className="dropdownn">
            <div className="dropdownn">
              {students && students?.length > 0 ? (
                <button
                  className="btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <>
                    <img
                      style={{
                        width: "30px",
                        height: "30px",
                        objectFit: "contain",
                      }}
                      src={currentStudent.image}
                      onError={({ currentTarget }) => {
                        currentTarget.src = Dummy;
                      }}
                    />
                    <span className="userName">{currentStudent.name}</span>
                  </>
                </button>
              ) : (
                <p>No Students</p>
              )}
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {students &&
                  students?.map((item) => {
                    return (
                      <li
                        onClick={() => {
                          handleStoreId(item?.studentId);
                        }}
                      >
                        <a className="dropdown-item" href="#">
                          <img
                            style={{
                              width: "30px",
                              height: "30px",
                              objectFit: "contain",
                            }}
                            onError={({ currentTarget }) => {
                              currentTarget.src = Dummy;
                            }}
                            src={`${baseURL}/images/${item?.Student?.profilePicture}`}
                            alt="User"
                          />
                          {item?.Student?.firstName}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        <div className="transcriptTable">
          <table>
            <thead>
              <tr>
                <th>Graduation Track</th>
                <th>Graduated</th>
                <th>Grad Stat</th>
                <th>Expected Grad</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Default</td>
                <td>Comment</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="transcriptGpa">
        <div className="Transcript-Top">
          <h3>GPAs</h3>
        </div>
        <h4>
          This process will recompute the student’s built-in 9pa and any
          district defined below.
        </h4>
        <div className="transcriptGpa-Body">
          <div>
            <h5>(W)Acad GPA</h5>
            <p>3,9691 3,773</p>
          </div>
          <div>
            <h5>(W)Total GPA</h5>
            <p>3,9691 3,773</p>
          </div>
          <div>
            <h5>(W)10-12 GAP</h5>
            <p>3,9691 3,773</p>
          </div>
          <div>
            <h5>Credit Att/Compl</h5>
            <p>3,9691 3,773</p>
          </div>
          <div>
            <h5>Class Rank Grad</h5>
            <p>3,9691 3,773</p>
          </div>
        </div>
        <div className="TranscriptGpaTable">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>GPA</th>
                <th>Class Rank/Size</th>
                <th>GPA Last Updated</th>
                <th>Class Rank Last Updated</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
      <div className="CourseHistory">
        <div className="Transcript-Top">
          <h3>Course History</h3>
        </div>
        <div className="CourseInput">
          <input type="checkbox" />
          <label>Sort By Subject</label>
          <input type="checkbox" />
          <label>Sort By Date Descending</label>
        </div>
        <div className="Transcript-Top1">
          <h3>2019-2020 Bald Intermediate School </h3>
          <h3>Grade 8 | Fall</h3>
        </div>
        <div className="TranscriptSchool">
          <table>
            <thead>
              <tr>
                <th>Schl</th>
                <th>Year</th>
                <th>Tm</th>
                <th>Grd</th>
                <th>Crs ID</th>
                <th>Std Course Title</th>
                <th>RT</th>
                <th>CP</th>
                <th>N/H</th>
                <th>Mark</th>
                <th>Atmp</th>
                <th>Comp</th>
                <th>SCT</th>
                <th className="ThLast">More</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>993</td>
                <td>19-20</td>
                <td>1</td>
                <td>8</td>
                <td>100386</td>
                <td>Algebra G</td>
                <td></td>
                <td></td>
                <td></td>
                <td>B</td>
                <td>0.00</td>
                <td>0.00</td>
                <td></td>
                <td>Less</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="Transcript-Top1">
          <h3>2019-2020 Bald Intermediate School </h3>
          <h3>Grade 9 | Spring</h3>
        </div>
        <div className="TranscriptSchool">
          <table>
            <thead>
              <tr>
                <th>Schl</th>
                <th>Year</th>
                <th>Tm</th>
                <th>Grd</th>
                <th>Crs ID</th>
                <th>Std Course Title</th>
                <th>RT</th>
                <th>CP</th>
                <th>N/H</th>
                <th>Mark</th>
                <th>Atmp</th>
                <th>Comp</th>
                <th>SCT</th>
                <th className="ThLast">More</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>993</td>
                <td>19-20</td>
                <td>1</td>
                <td>8</td>
                <td>100386</td>
                <td>Algebra G</td>
                <td></td>
                <td></td>
                <td></td>
                <td>B</td>
                <td>0.00</td>
                <td>0.00</td>
                <td></td>
                <td>Less</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* <div className="indicatesCommunity">
        <p>
          *indicates community college courses for college credit - see college
          transcript <br />
          ********** END OF ACADEMIC TRANSCRIPT **********
        </p>
        <div className="Issued">
          <label>Date Issued</label>
          <input type="date" />
        </div>
        <p className="transcript">
          This transcript is provided in accordance with the State of North
          Canada Department of Education regulations for graduation. I do hereby{" "}
          <br /> acknowledge that all information provided herein is accurate.
        </p>
        <div className="Issued AdminisTrator">
          <label className="SignaTure">
            Signature of the Administrator of High School Date
          </label>
          <div className="input">
            <span>
              <FaLeaf />
            </span>{" "}
            <input type="text" placeholder="Signature" />
          </div>
        </div>
        <div className="Issued">
          <label>Date</label>
          <input type="text" />
        </div>
      </div> */}
      <div className="btnNs">
        <button>Share</button>
        <button>
          <span>
            <RiFileExcel2Line />
          </span>
          Export Data
        </button>
        <button className="Print">Print Transcript</button>
      </div>
    </div>
  );
}

export default Transcripts;
