import React, { useState } from "react";
import { LuUploadCloud } from "react-icons/lu";
import { AiTwotoneDelete } from "react-icons/ai";
import "./NewsUpdate.css";

function NewsUpdate() {
  const [images, setImages] = useState([]);
  const [bannerImages, setBannerImages] = useState({});

  const handleImageUpload = (event) => {
    const uploadedImages = Array.from(event.target.files);
    if (uploadedImages.length > 0) {
      uploadedImages.forEach((image) => {
        if (image.type === "image/png" || image.type === "image/jpeg") {
          if (image.size <= 1000 * 1000) {
            const imageUrl = URL.createObjectURL(image);
            setImages((prevImages) => [...prevImages, imageUrl]);
          } else {
            alert("Image size should be 1000x1000 or less.");
          }
        } else {
          alert("Please upload png or jpg files only.");
        }
      });
    }
  };

  const handleImageDelete = (type) => {
    setBannerImages((prevState) => ({
      ...prevState,
      [type]: null,
    }));
  };

  return (
    <div>
      <div className="NewandUpdate-main">
        <h1>New & Updates</h1>
        <h3>Existing banners</h3>
        <div className="NewandUpdate-main-child0">
          <div className="NewandUpdate-main-cild2">
            <h5>Student Banner</h5>
            <div className="NewandUpdate-main-cild3">
              <div className="NewandUpdate-main-cild4">
                <AiTwotoneDelete
                  size={25}
                  onClick={() => handleImageDelete("student")}
                />
              </div>
              <div className="NewandUpdate-main-cild5">
                {bannerImages.student && (
                  <img src={bannerImages.student} alt="img" />
                )}
              </div>
              <div className="NewandUpdate-main-cild6"></div>
            </div>
          </div>

          <div className="NewandUpdate-main-cild2">
            <h5>Teacher Banner</h5>
            <div className="NewandUpdate-main-cild3">
              <div className="NewandUpdate-main-cild4">
                <AiTwotoneDelete
                  size={25}
                  onClick={() => handleImageDelete("teacher")}
                />
              </div>
              <div className="NewandUpdate-main-cild5">
                {bannerImages.teacher && (
                  <img src={bannerImages.teacher} alt="img" />
                )}
              </div>
              <div className="NewandUpdate-main-cild6"></div>
            </div>
          </div>

          <div className="NewandUpdate-main-cild2">
            <h5>Parent Banner</h5>
            <div className="NewandUpdate-main-cild3">
              <div className="NewandUpdate-main-cild4">
                <AiTwotoneDelete
                  size={25}
                  onClick={() => handleImageDelete("parent")}
                />
              </div>
              <div className="NewandUpdate-main-cild5">
                {bannerImages.parent && (
                  <img src={bannerImages.parent} alt="img" />
                )}
              </div>
              <div className="NewandUpdate-main-cild6"></div>
            </div>
          </div>
        </div>
        <hr className="hr-line" />
        <div className="new-banner">
          <h6>Upload New Banner</h6>
        </div>
        <div className="Uploadingimage-main">
          <div className="Uploadingimage-main-child">
            <div className="Uploadingimage-main-child12">
              <div className="Uploadingimage-main-child2">
                <label htmlFor="fileInput">
                  <LuUploadCloud className="LuUploadCloud" />
                </label>
                <input
                  id="fileInput"
                  type="file"
                  accept="image/jpeg, image/png"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                  multiple
                />
                <h6>Uploading image</h6>
              </div>
              <p>Upload a cover image for your product.</p>
              <p>
                File Format <span>jpeg, png</span> Recommended Size{" "}
                <span>600x600 (1:1) </span>
              </p>
            </div>
          </div>
          <div className="Uploadingimage-main-child6main">
            <div className="Uploadingimage-main-child7">
              <div className="Uploadingimage-main-child5">
                <div className="Uploadingimage-main-child2">
                  <label htmlFor="fileInput">
                    <LuUploadCloud className="LuUploadCloud" />
                  </label>
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/jpeg, image/png"
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                    multiple
                  />
                  <h6>Uploading image</h6>
                </div>
              </div>
              <div className="Uploadingimage-main-child3">
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`uploaded-image-${index}`}
                    style={{
                      width: "170px",
                      height: "167px",
                      borderRadius: "12px",
                      marginRight: "10px",
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="NewandUpdate-main-child8">
          <div>
            <div className="NewandUpdate-main-child14">
              <h5>Visible to</h5>
            </div>
            <div className="NewandUpdate-main-child12">
              <div className="NewandUpdate-main-child11">
                <input type="checkbox" />
                <p>Teacher</p>
              </div>
              <div className="NewandUpdate-main-child11">
                <input type="checkbox" />
                <p>Student</p>
              </div>
              <div className="NewandUpdate-main-child11">
                <input type="checkbox" />
                <p>Parent</p>
              </div>
            </div>
            <div className="NewandUpdate-main-child13">
              <button>Cancle</button>
              <button>Update</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsUpdate;
