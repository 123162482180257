import React, { useEffect, useState } from "react";
import "./ManageMain.css";
import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoCaretForwardOutline } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import ManageAcModal from "../NewProgram/ManageAcModal";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import axios from "axios";
import { baseURL } from "../../../../constants";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
axios.defaults.baseURL = baseURL;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ManageMain = () => {
  const [isEdit, setIsEdit] = useState("");
  const [programId, setProgramId] = useState("");
  const [programs, setPrograms] = useState(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setIsEdit("");
    setProgramId("");
  };

  const assignmentsData = [
    {
      descriptionColor: "#F93333",
      bgColor: "#F93333",
      buttonBgColor: "#f93333",
      buttonTextColor: "#F93333",
      button: "Active",
      date: "1",
      programCodeColor: "#F93333",
      programDurationColor: "#F93333",
      drageIconColor: "#F93333",
      EditIconClor: "#F93333",
      deleteIconColor: "#F93333",
    },
    {
      descriptionColor: "#16D03B",
      bgColor: "#16D03B",
      buttonBgColor: "#16D03B",
      buttonTextColor: "#16D03B",
      button: "Active",
      bcColor: "#F0FFF3",
      mainbordercolor: "#16D03B",
      date: "2",
      programCodeColor: "#16D03B",
      programDurationColor: "#16D03B",
      drageIconColor: "#16D03B",
      EditIconClor: "#16D03B",
      deleteIconColor: "#16D03B",
    },
  ];

  const navigate = useNavigate();
  const { schoolId } = useSelector((state) => state.schoolId);

  const getPrograms = async () => {
    try {
      const { data } = await axios.get(
        `/programs/getProgramsBySchool/${schoolId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setPrograms(data?.programs);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPrograms();
  }, [schoolId]);

  const handleDeleteProgram = async () => {
    try {
      await axios.delete(`/programs/removeProgram/${programId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      toast.success("Program Deleted");
      getPrograms();
      handleClose();
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <div className="myassingment-main1">
      <div className="myassingment-TOP3">
        <h3>School </h3>
        <IoCaretForwardOutline />
        <h4 style={{ color: "#00afef" }}>Manage Academics</h4>
        <IoCaretForwardOutline />
        <h4>Program</h4>
      </div>
      <div className="myassingment-SeC">
        <div className="myassingment-SeC-input">
          <IoIosSearch />
          <input
            placeholder="Search for Program,  Courses, Semester"
            type="text"
          />
        </div>
        <div className="myassingment-SeC-button">
          <ManageAcModal
            setIsEdit={setIsEdit}
            isEdit={isEdit}
            getPrograms={getPrograms}
          />
        </div>
      </div>
      <div className="myassingment-SeC-Table">
        <div className="rows">
          <p>
            <input type="checkbox" />
            Program Name
          </p>
          <p>Program Code</p>
          <p>Program Discription</p>
          <p>Program Duration </p>
          <p>Status</p>
        </div>
      </div>

      <div className="CommentModal-Mmain">
        <Modal
          className="Allowmodalmain"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div className="CommentModal-Mmain-child1">
                <h4>Delete Program</h4>
                <p>
                  Are you sure you want to delete this Program? This will remove
                  the Program and can’t be undone.
                </p>
                <div className="CommentModal-Mmain-child2">
                  <button
                    className="
                  CommentModal-Mmain-child2-Dont-allow
                  "
                    onClick={handleClose}
                  >
                    CANCEL
                  </button>
                  <button
                    onClick={handleDeleteProgram}
                    className="CommentModal-Mmain-child2-allow"
                  >
                    DELETE
                  </button>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
      <div style={{ marginTop: "10px" }}>
        {programs?.map((program, index) => {
          const newIndex = index % assignmentsData.length;

          return (
            <div
              onClick={() => {
                sessionStorage.removeItem("programId");
                sessionStorage.setItem("programId", program?.id);
                navigate(`/AddCourse`);
              }}
              className="myassingments-child-2m"
              key={index}
              style={{ marginTop: "10px" }}
            >
              <div
                className="myassingments-cardd21"
                style={{
                  backgroundColor:
                    assignmentsData[program?.isActive === true ? 1 : 0]
                      ?.bcColor,
                  borderColor:
                    assignmentsData[program?.isActive === true ? 1 : 0]
                      ?.mainbordercolor,
                }}
              >
                <div className="myassingments-card-childs-1m">
                  <div className="myassingmentss-childss12">
                    <div className="myassingments-childss-Data1">
                      <div
                        className="myassingments-childs-dateAb"
                        style={{
                          backgroundColor:
                            assignmentsData[program?.isActive === true ? 1 : 0]
                              ?.bgColor,
                        }}
                      >
                        <p>{program?.id}</p>
                      </div>
                      <div className="Mychildss-myassingmentss1">
                        <h6>{program?.programName}</h6>
                      </div>
                    </div>
                    <div className="FiftyPersent">
                      <h6
                        style={{
                          color:
                            assignmentsData[program?.isActive === true ? 1 : 0]
                              ?.programCodeColor,
                        }}
                      >
                        {program?.programCode}
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="myassingments-card-childs-3m">
                  <div className="descriptions-s1">
                    <h5
                      style={{
                        color:
                          assignmentsData[program?.isActive === true ? 1 : 0]
                            ?.descriptionColor,
                      }}
                    >
                      Description
                    </h5>
                    <p>{program?.programDescription}</p>
                  </div>
                  <div className="pTag">
                    <p
                      style={{
                        color:
                          assignmentsData[program?.isActive === true ? 1 : 0]
                            ?.programDurationColor,
                      }}
                    >
                      {program?.programDuration}
                    </p>
                  </div>
                  <div className="mychilds-myassingments-dovh">
                    <button
                      style={{
                        color:
                          assignmentsData[program?.isActive === true ? 1 : 0]
                            ?.bgColor,
                        borderColor:
                          assignmentsData[program?.isActive === true ? 1 : 0]
                            ?.buttonBgColor,
                      }}
                    >
                      {program?.isActive === true ? "Active" : "Not Active"}
                    </button>
                    <div className="mychilds-myassingments-icoNs">
                      <MdEdit
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsEdit(program?.id);
                        }}
                        style={{
                          color:
                            assignmentsData[program?.isActive === true ? 1 : 0]
                              ?.EditIconClor,
                        }}
                      />
                      <RiDeleteBin6Line
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpen();
                          setProgramId("");
                          setProgramId(program?.id);
                        }}
                        style={{
                          color:
                            assignmentsData[program?.isActive === true ? 1 : 0]
                              ?.deleteIconColor,
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ManageMain;
