import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./StudentGrading.css";
import Form from "react-bootstrap/Form";
import Dummy from "../../../../AdminComponents/images/dummy-user.png";
import axios from "axios";
import { useParams } from "react-router-dom";
import { baseURL } from "../../../../../constants";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../../redux/actions/userAction";

function StudentGrading({ myGrade = {}, getGrades = () => {}, gradeId = "", getGpa=()=>{}, getMyGrade=()=> {} }) {
  const [lgShow, setLgShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    assignmentTitle: "",
    instructions: "",
    status: "",
    date: "",
    time: "",
  });

  console.log(gradeId, "this is grade Id");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let formattedValue = value;

    if (value.length === 10) {
      const parts = value.split("/");
      if (parts.length === 3) {
        const month = parts[0].padStart(2, "0");
        const day = parts[1].padStart(2, "0");
        const year = parts[2];
        formattedValue = `${month}/${day}/${year}`;
      }
    }

    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  const { studentId } = useParams();

  const [title, setTitle] = useState("");
  const [scoredPercentage, setScoredPercentage] = useState("");
  const [passingPercentage, setPassingPercentage] = useState("");
  const [instructions, setInstructions] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [gradeType, setGradeType] = useState("");
  const [obtainmarkes, setObtainmarks] = useState("");
  // Selected File

  const { user } = useSelector((state) => state.getUserProfile);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const [schoolId, setSchoolId] = useState(null);
  const getSchoolByTeacherId = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/ParentBySchoolId/getSchoolByTeacherId/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setSchoolId(data.school.id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSchoolByTeacherId();
  }, [user]);

  const addGrade = async () => {
    try {
      const newForm = new FormData();
      newForm.append("title", title);
      newForm.append("scoredPercentage", scoredPercentage);
      newForm.append("passingPercentage", passingPercentage);
      newForm.append("instructions", instructions);
      newForm.append("date", date);
      newForm.append("time", time);
      newForm.append("gradeType", gradeType);
      newForm.append("file", selectedFile);
      newForm.append("studentId", studentId);
      newForm.append("schoolId", schoolId);
      newForm.append("obtainmarkes", obtainmarkes);
      newForm.append("subjectId", localStorage.getItem("subjectId"));

      const { data } = await axios.put(
        `/grades/update/${gradeId}`,
        {
          title,
          instructions,
          date,
          time,
          studentId: studentId === ":studentId" ? localStorage.getItem("teacherStudent") : studentId,
          obtainmarkes,
          passingmarkes: passingPercentage,
          totalmarkes: scoredPercentage,
          schoolId,
          subjectId: localStorage.getItem("subjectId"),
        },
        {
          headers: {
            "Content-Type": "application/json",
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      getGrades()
      getGpa()
      getMyGrade()
      toast.success("Grade Updated Successfully");
      setLgShow(false);
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const [student, setStudent] = useState("");

  const getStudent = async () => {
    try {
      const { data } = await axios.get(`/students/profile/${studentId === ":studentId" ? localStorage.getItem("teacherStudent") : studentId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setStudent(data?.student);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStudent();
  }, []);

  const [subject, setSubject] = useState(null);
  const getSubject = async () => {
    try {
      const { data } = await axios.get(
        `/subjects/getSubjectById/${localStorage.getItem("subjectId")}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setSubject(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSubject();
  }, []);

  return (
    <div className="StudentGrading-main">
      <Button onClick={() => setLgShow(true)}>Adjust Grade </Button>

      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
         aria-labelledby="example-modal-sizes-title-lg"
        className="StudentModal"
      >
        <Modal.Body>
          <div className="StudentHead">
            <h2>Student Grading</h2>
          </div>
          <div className="StudentGrading-MainBody">
            <div className="StudentGrading-Profile">
              <img
                src={`${baseURL}/images/${student?.profilePicture}`}
                onError={({ currentTarget }) => {
                  currentTarget.src = Dummy;
                }}
                alt="#"
              />
            </div>
            <div className="StudentGrading-Name">
              <h4>
                {student?.firstName} {student?.middleName} {student?.lastName}
              </h4>
              <p className="Student-para">Student ID: {student?.studentId}</p>
              <div className="StudentGrading-All">
                <h6>
                  Subject:<span>{subject?.subject?.subjectName}</span>
                </h6>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <div>
                    <input
                      style={{
                        width: "100%",
                        border: "1px solid lightgray",
                        borderRadius: "6px",
                        padding: "5px",
                        outline: "none",
                      }}
                      type="number"
                      placeholder="Total Marks"
                      value={scoredPercentage}
                      onChange={(e) => setScoredPercentage(e.target.value)}
                    />
                  </div>
                  <div>
                    <input
                      style={{
                        width: "100%",
                        border: "1px solid lightgray",
                        borderRadius: "6px",
                        padding: "5px",
                        outline: "none",
                      }}
                      type="number"
                      value={passingPercentage}
                      onChange={(e) => setPassingPercentage(e.target.value)}
                      placeholder="Passing Marks"
                    />
                  </div>
                  <div>

                    <input
                      style={{
                        width: "100%",
                        border: "1px solid lightgray",
                        borderRadius: "6px",
                        padding: "5px",
                        outline: "none",
                      }}
                      type="number"
                      value={obtainmarkes}
                      onChange={(e) => setObtainmarks(e.target.value)}
                      placeholder="Obtain Marks"
                    />
                  </div>
                </div>
                <label>Assignment Subject</label> <br />
                <input
                  placeholder="Title"
                  className="StudentGrading-input"
                  name="assignmentTitle"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <textarea
                  placeholder="Instructions (Optional)"
                  className="StudentGrading-textarea"
                  name="instructions"
                  value={instructions}
                  onChange={(e) => setInstructions(e.target.value)}
                />
                {/* <Form.Select
                  aria-label="Default select example"
                  style={{ marginTop: "1px", boxShadow: "none" }}
                  name="status"
                  value={result}
                  onChange={(e) => setResult(e.target.value)}
                >
                  <option value={""}>result</option>
                  <option value="pass">Pass</option>
                  <option value="fail">Fail</option>
                  <option value="miss">Missed</option>
                </Form.Select> */}
                <div className="StudentGrading-twoInputs">
                  <div style={{ width: "50%" }}>
                    <label htmlFor="selectDate">Date</label>
                    <br />
                    <input
                      id="selectDate"
                      type="date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <label>Time</label>
                    <br />
                    <input
                      type="time"
                      placeholder="- - : - -  - -"
                      name="time"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                      // className="CustomTimeInput"
                    />
                  </div>
                </div>
                {/* Input File Upload */}
                {/* <div className="StudentGrading-Upload">
                  <div className="StudentGrading-Up-child1">
                    <p
                      onClick={() =>
                        document.getElementById("fileInput").click()
                      }
                    >
                      Upload File
                    </p>
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    <RiUpload2Fill
                      onClick={() =>
                        document.getElementById("fileInput").click()
                      }
                    />
                    <h6>
                      {selectedFile ? selectedFile.name : "No file chosen"}
                    </h6>
                  </div>
                  <div className="StudentGrading-Up-child2">
                    <input
                      value={gradeType}
                      onChange={(e) => setGradeType(e.target.value)}
                      placeholder="Grade Type"
                    />
                  </div>
                </div> */}
                {/* End */}
                <div className="StudentGrading-button">
                  <button onClick={()=>{
                  addGrade()
                  }}>Confirm</button>
                </div>
                <div className="StudentGrading-switch">
                  <label>notify all student on their email</label>
                  <Form>
                    <Form.Check type="switch" id="custom-switch" />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default StudentGrading;
