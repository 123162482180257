import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useSelector } from "react-redux";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { FaLessThan } from "react-icons/fa6";
import { FaGreaterThan } from "react-icons/fa6";
import "./calender.css";
import Modal from "react-modal";
import { BsThreeDotsVertical } from "react-icons/bs";
import Events from "./Events";
import { Dropdown } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getStudentEvents,
  deleteEvent,
} from "../../../redux/actions/userAction";
import { baseURL } from "../../../constants";
import axios from "axios";
axios.defaults.baseURL = baseURL;
moment.locale("en-GB");

const localizer = momentLocalizer(moment);

const CalendarPage = ({}) => {
  const [currentDay, setCurrentDay] = useState("month");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    loading,
    events: dispatchEvents,
    error: dispatchError,
  } = useSelector((state) => state.events);

  console.log(dispatchEvents, "I'm dispatch events");
  console.log(dispatchError, "I'm dispatch error");
  const dispatch = useDispatch();

  const { events, error } = useSelector((state) => state.events);

  const [schoolId, setSchoolId] = useState("");

  const { user } = useSelector((state) => state.getUserProfile);

  const getSchool = async () => {
    try {
      const { data } = await axios.get(
        `/ParentBySchoolId/getschoolBystudentId/${user?.dataValues?.id}`,
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );

      setSchoolId(data?.school?.id);
    } catch (error) {
      console.log(error, "ah");
    }
  };

  useEffect(() => {
    if (user) {
      getSchool();
    }
  }, [user]);

  useEffect(() => {
    if (schoolId) {
      dispatch(getStudentEvents(schoolId));
    }
  }, [dispatch, schoolId]);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedEvent(null);
  };

  // Custom Event
  const Event = ({ event }) => {
    return (
      <span>
        <strong>{event?.eventName}</strong>
      </span>
    );
  };

  // Custom Toolbar
  const CustomToolbar = (toolbar) => {
    // console.log(toolbar, "We're toolbar");
    const goToBack = () => {
      toolbar.onNavigate("PREV");
    };

    const goToNext = () => {
      toolbar.onNavigate("NEXT");
    };
    const goToDayView = () => {
      toolbar.onView("day");
      setCurrentDay("day");
    };

    const goToWeekView = () => {
      toolbar.onView("week");
      setCurrentDay("week");
    };

    const goToMonthView = () => {
      toolbar.onView("month");
      setCurrentDay("month");
    };

    const goToToday = () => {
      toolbar.onNavigate("TODAY", new Date());
      setCurrentDay("today");
    };

    return (
      <div className="calendar-container">
        <p onClick={goToToday}>Today</p>
        <div className="switch-next-and-prev-buttons">
          <button className="left-day-button" onClick={goToBack}>
            <FaLessThan />
          </button>
          <span>{toolbar.label}</span>
          <button className="right-day-button" onClick={goToNext}>
            <FaGreaterThan />
          </button>
        </div>
        <div className="switch-days-button">
          <button
            style={{
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              backgroundColor: currentDay === "day" ? "#00afef" : "white",
              color: currentDay === "day" ? "white" : "#202224",
            }}
            className="calendar-day"
            onClick={goToDayView}
          >
            Day
          </button>
          <button
            style={{
              backgroundColor: currentDay === "week" ? "#00afef" : "white",
              color: currentDay === "week" ? "white" : "#202224",
            }}
            className="calendar-day"
            onClick={goToWeekView}
          >
            week
          </button>
          <button
            style={{
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
              backgroundColor: currentDay === "month" ? "#00afef" : "white",
              color: currentDay === "month" ? "white" : "black",
            }}
            className="calendar-day"
            onClick={goToMonthView}
          >
            Month
          </button>
        </div>
      </div>
    );
  };

  const { message, error: eventError } = useSelector((state) => state.events);

  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch({ type: "clearMessage" });
    }
    if (eventError) {
      toast.error(eventError);
      dispatch({ type: "clearError" });
    }
  }, [message, eventError, dispatch]);

  const navigate = useNavigate();
  const handleDeleteEvent = async (id) => {
    await dispatch(deleteEvent(id));
    await dispatch(getStudentEvents(schoolId));

    setSelectedEvent(null);
  };
  return (
    <div className="Calendar-Container">
      <Events events={dispatchEvents} />
      <div className="Calendar-div">
        <Calendar
          localizer={localizer}
          events={dispatchEvents}
          selectable
          onSelectEvent={handleEventClick}
          // allDayMaxRows={1}
          defaultView="month"
          dayLayoutAlgorithm={"no-overlap"}
          defaultDate={new Date()}
          startAccessor={"startdate"}
          endAccessor={"enddate"}
          eventPropGetter={(event) => ({
            style: {
              backgroundColor: event?.color || "#00afef",
              border: "1px solid darkgray",
              display: "block",
              padding: ".9rem",
            },
          })}
          style={{ height: "calc(100vh - 150px)" }}
          components={{
            toolbar: CustomToolbar,
            event: Event,
          }}
        />

        <Modal
          isOpen={!!selectedEvent}
          style={{
            overlay: {
              zIndex: 102353253250,
              background: "transparent",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              margin: "auto",
              transform: "translate(-50%, -50%)",
              width: "330px",
              height: "auto",
              padding: "0px 20px 20px 20px",
              borderRadius: "10px",
              zIndex: 6000000000,
              backgroundColor: "white",
            },
          }}
          onRequestClose={() => {
            setSelectedEvent(null);
            setIsModalOpen(false);
          }}
        >
          <div
            style={{
              display: "flex",
              position: "relative",
              flexDirection: "column",
              gap: "3px",
            }}
          >
            {selectedEvent?.coverPhoto && (
              <img
                style={{
                  width: "100%",
                  maxHeight: "200px",
                  objectFit: "cover",
                  borderRadius: "8px",
                  marginTop: "30px",
                }}
                src={`${baseURL}/images/${selectedEvent?.coverPhoto}`}
              />
            )}
            <h5 style={{ marginBlock: "10px" }}>{selectedEvent?.eventName}</h5>
            <p style={{ color: "gray" }}>{selectedEvent?.description}</p>
            <p style={{ color: "gray", fontSize: ".9rem" }}>
              {moment(selectedEvent?.startdate).format("YYYY Do MMMM hh:mm:ss")}{" "}
              - {moment(selectedEvent?.enddate).format("YYYY Do MM hh:mm:ss")}
            </p>
            <p style={{ color: "gray", fontSize: ".9rem" }}>
              {selectedEvent?.address}
            </p>
            <hr style={{ margin: "5px 0px" }} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                  marginBlock: "15px",
                }}
              >
                {selectedEvent?.members?.slice(0, 3)?.map((member) => {
                  return (
                    <img
                      src={member}
                      alt="Member"
                      style={{
                        width: "40px",
                        height: "40px",
                        objectFit: "cover",
                        borderRadius: "100%",
                      }}
                    />
                  );
                })}
                {selectedEvent?.members?.length > 3 && (
                  <div
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "100%",
                      background: "rgba(0, 175, 239, 0.2)",
                      display: "grid",
                      placeItems: "center",
                      border: "2px solid #00afef",
                      color: "#00afef",
                    }}
                  >
                    {selectedEvent?.members?.length - 3}+
                  </div>
                )}
              </div>
              <button
                style={{
                  border: "none",
                  backgroundColor: "#00afef",
                  color: "white",
                  padding: "8px 30px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Join
              </button>
            </div>

            <div
              className="Mini_MAin123"
              style={{
                position: "absolute",
                right: "-15px",
                cursor: "pointer",
                zIndex: 4,
              }}
            >
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <BsThreeDotsVertical />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <div className="Mini-Main-Div23">
                    <Dropdown
                      onClick={() =>
                        navigate(`/AddCalendarEvent?editId=${selectedEvent.id}`)
                      }
                      href="#/action-1"
                    >
                      Edit
                    </Dropdown>
                    <Dropdown
                      onClick={() => handleDeleteEvent(selectedEvent?.id)}
                      href="#/action-2"
                    >
                      Delete
                    </Dropdown>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default CalendarPage;
