import { BsArrowsAngleExpand, BsArrowUp, BsArrowDown } from "react-icons/bs";

const AddUsersActiveData = [
  {
    dailyActiveUsers: "Daily Active Users ",
    fullScreenIcon: <BsArrowsAngleExpand />,
    persntageIcon: <BsArrowDown />,
    persntageText: "59%",
    color: "#ED4F9D",
    backgroundColor: "#FDF2F8",
  },
  {
    dailyActiveUsers: "Daily Active Users ",
    fullScreenIcon: <BsArrowsAngleExpand />,
    persntageIcon: <BsArrowDown />,
    color: "#ED4F9D",
    backgroundColor: "#FDF2F8",
    persntageText: "59%",
  },
  {
    dailyActiveUsers: "Daily Active Users ",
    fullScreenIcon: <BsArrowsAngleExpand />,
    persntageIcon: <BsArrowUp />,
    color: "#24D164",
    backgroundColor: "#F0FDF4",
    persntageText: "1.0%",
  },
  {
    dailyActiveUsers: "Daily Active Users ",
    fullScreenIcon: <BsArrowsAngleExpand />,
    persntageIcon: <BsArrowDown />,
    color: "#ED4F9D",
    backgroundColor: "#FDF2F8",
    persntageText: "30%",
  },
];



export default AddUsersActiveData;
