import React, { useEffect, useState } from "react";
import "./GradeBook.css";
import { IoCheckmarkCircle } from "react-icons/io5";
import Blue from "./Vector.png";
import orange from "./orange.png";
import green from "./Green.png";
import grey from "./Grey.png";
import yellow from "./Yellow.png";
import check from "./Check.png";
import axios from "axios";
import { baseURL } from "../../../constants";
import { useParams } from "react-router-dom";
axios.defaults.baseURL = baseURL;

function GradeBook() {
  const params = useParams();
  const [grade, setGrade] = useState([]);

  const getGrade = async () => {
    try {
      const { data } = await axios.get(
        `/grades/getGradesByStudentId/${params.subjectId}`,
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );
      setGrade(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getGrade();
  }, []);
  console.log(grade, "This is me heryar");
  return (
    <div className="GradeBook-Main">
      <div className="GradeBook_top">
        <button>0 - Hon Geo/Trg CP - Spring</button>
        <button>Score by class</button>
      </div>
      <div className="GradeBook-Table">
        <table>
          <thead>
            <tr>
              <th className="GradeFive">#</th>
              <th className="GradeFive">Students Name</th>
              <th className="GradeFive">Grade</th>
              <th className="GradeFive">%</th>
              <th className="GradeFive">Marks</th>
              <th>
                Instructions
                <IoCheckmarkCircle />
              </th>
              <th>
                Title
                <IoCheckmarkCircle />
              </th>
              <th>
                Scored <br /> Percentage
                <IoCheckmarkCircle />
              </th>
              <th>
                Total <br /> Percentage
                <IoCheckmarkCircle />
              </th>
              <th>
                Result
                <IoCheckmarkCircle />
              </th>
              <th>
                Date
                <IoCheckmarkCircle />
              </th>
              <th>
                Time
                <IoCheckmarkCircle />
              </th>
            </tr>
          </thead>
          <tbody>
            {grade &&
              grade.map((Gra, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      {Gra?.Student?.firstName}
                      <br />
                      ID :<span> {Gra?.studentId}</span>
                    </td>
                    <td>{Gra?.letterGrade}</td>
                    <td>{Gra?.runningAvg}</td>
                    <td>{Gra?.obtainmarkes}</td>
                    <td>{Gra?.instructions}</td>
                    <td>{Gra?.title}</td>
                    <td>{Gra?.scoredPercentage}</td>
                    <td>100</td>
                    <td>{Gra?.result}</td>
                    <td>{Gra?.date?.split("T")[0]}</td>
                    <td>{Gra?.time}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="GardeBook-Last">
        <div className="GradeBookImag">
          <img src={Blue} alt="#" />
          <h3>Attendance Info</h3>
        </div>
        <div className="GradeBookImag">
          <img src={orange} alt="#" />
          <h3>Missing</h3>
        </div>
        <div className="GradeBookImag">
          <img src={green} alt="#" />
          <h3>#Correct {">"} Max</h3>
        </div>
        <div className="GradeBookImag">
          <img src={grey} alt="#" />
          <h3>Max = 0</h3>
        </div>
        <div className="GradeBookImag">
          <img src={yellow} alt="#" />
          <h3>Inactive Student</h3>
        </div>
        <div className="GradeBookImag">
          <img src={check} alt="#" />
          <h3>Grading Complete</h3>
        </div>
      </div>
    </div>
  );
}

export default GradeBook;
