import React, { useState } from "react";
import "./ADDMessage.css";
import img40 from "./40px.png";
import { MessagesData } from "./Messagedata/MessagesData";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../../constants";
axios.defaults.baseURL = baseURL;
const ADDMessages = () => {
  const [messages, setMessages] = useState([]);

  return (
    <div className="ADDMessages-main2">
      <div className="ADDMessages-main-title">
        <h2>New Messages Request</h2>
      </div>
      <div>
        {MessagesData.map((messageData) => (
          <div key={messageData.id} className="ADDMessages-main-child">
            <div className="Reqist-card-child-1">
              <img src={img40} alt="user pic" />
              <div className="ADDMessages-main-dot"></div>
            </div>
            <div className="Reqist-card-child-2">
              <div className="Reqist-card">
                <div className="Reqist-card-child-2">
                  <h4>{messageData.name}</h4>
                  <p>{messageData.message}</p>
                </div>
                <div className="Reqist-card-child-3">
                  <p>{messageData.time}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="ADDMessages-main-button">
        <Link to="/ChatChatBox">See All</Link>
      </div>
    </div>
  );
};

export default ADDMessages;
