import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./ADDdatausedpage.css";
import axios from "axios";
import { baseURL } from "../../../constants";
axios.defaults.baseURL = baseURL;
function ADDdatausedpage() {
  const totalStorage = 512;
  const usedStorage = 140;

  const categories = [
    {
      name: "Documents",
      variant: "danger",
      percentage: 70,
      color: "rgba(255, 68, 57, 1)",
    },
    {
      name: "Messages",
      variant: "warning",
      percentage: 13,
      color: "rgba(255, 152, 57, 1)",
    },

    {
      name: "Videos",
      variant: "primary",
      percentage: 10,
      color: "rgb(45, 45, 253)",
    },
    {
      name: "Photos",
      variant: "success",
      percentage: 5,
      color: "rgba(89, 168, 41, 1)",
    },
    {
      name: "Files",
      variant: "info",
      percentage: 3,
      color: "rgb(0, 174, 255)",
    },

    {
      name: "Audios",
      variant: "secondary",
      percentage: 2,
      color: "rgba(72, 72, 74, 1)",
    },
    {
      name: "System Data",
      variant: "dark",
      percentage: 1,

      color: "rgba(44, 44, 46, 1)",
    },
  ];

  const ref = useRef(null);

  useLayoutEffect(() => {
    ref.current?.style.setProperty("background-color", "blue", "important");
  }, []);

  const remainingStorage = totalStorage - usedStorage;

  const [loading, setLoading] = useState(true);
  const [dataUsage, setDataUsage] = useState(null);

  const getDataUsage = async () => {
    try {
      setLoading(true);
      const { data } = await axios("/datausages/getDateUsage", {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });

      setLoading(false);

      setDataUsage(data);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDataUsage();
  }, []);

  return (
    <>
      <div className="ADDdataused-Main">
        <div className="ADDdataused-head">
          <h4>Data Used</h4>
          {dataUsage && (
            <h5>
              <span>{dataUsage[0].usedStorge}</span> GB of
              <span>{dataUsage[0].totalStorge}</span>
            </h5>
          )}
        </div>
        <div className="progress-container">
          <ProgressBar now={87} variant="danger">
            {dataUsage &&
              categories &&
              dataUsage?.map((date, index) => {
                return index === 0 ? null : (
                  <ProgressBar
                    variant={
                      categories[index - 1]?.color
                        ? categories[index - 1]?.color
                        : "red"
                    }
                    now={43}
                    key={index}
                  />
                );
              })}
          </ProgressBar>
          {dataUsage && (
            <div className="remaining-storage">{dataUsage[0].freeSpace}</div>
          )}
        </div>
        <div className="ADDdataDots-main">
          <div className="ADDdataDots">
            <div style={{ display: "flex", gap: "10px" }}>
              {dataUsage &&
                dataUsage.map((cat, index) =>
                  index === 0 ? null : (
                    <p key={index}>
                      <span
                        style={{
                          color: categories[index - 1]?.color
                            ? categories[index - 1]?.color
                            : "red",
                          fontSize: "20px",
                        }}
                      >
                        ●
                      </span>

                      {cat.name}
                    </p>
                  )
                )}
            </div>
          </div>
          <button className="AddBUY">Buy More Storage</button>
        </div>
      </div>

      <div className="ADDSevenBars">
        <h1>Data Used</h1>
        {dataUsage &&
          dataUsage.map((cate, index) =>
            index === 0 ? null : (
              <div key={index} className="category-progress">
                <span
                  style={{
                    color: categories[index - 1]?.color
                      ? categories[index - 1]?.color
                      : "red",
                    fontSize: "20px",
                    marginRight: "10px",
                  }}
                >
                  ●
                </span>
                <span
                  style={{
                    color: "rgba(113, 113, 122, 1)",
                    fontWeight: 400,
                    fontSize: "15px",
                    width: "100px",
                  }}
                >
                  {cate.name}
                </span>
                <ProgressBar
                  variant={
                    categories[index - 1]?.variant
                      ? categories[index - 1]?.variant
                      : "red"
                  }
                  className="important-progress"
                  now={Math.floor(Math.random() * 100)}
                />
              </div>
            )
          )}
      </div>
    </>
  );
}

export default ADDdatausedpage;
