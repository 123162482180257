import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginAll.css";
import LOGOCEN from "../../images/Logo.png";
import Studentimg1 from "../../images/parent__5_-removebg-preview.png";
import Studentimg5 from "../../images/parent__1_-removebg-preview.png";
import Studentimg2 from "../../images/parent__4_-removebg-preview.png";
import Studentimg3 from "../../images/parent__3_-removebg-preview.png";
import Studentimg4 from "../../images/parent__2_-removebg-preview.png";
import Studentimg6 from "../../images/parent__6_-removebg-preview.png";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../redux/actions/userAction";
import Loader from "../../../AdminComponents/Loader/Loader";

function LoginCards() {
  const [selectedRole, setSelectedRole] = useState(null);
  const navigate = useNavigate();

  const handleNext = () => {
    if (!selectedRole) {
      return toast.error("Please select a role");
    } else if (selectedRole === "vendor" || selectedRole === "government") {
      return toast.error("This page is coming soon!");
    } else {
      navigate(`/login?role=${selectedRole}`);
    }
  };

  const [isFocused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const { user, isAuthenticated, loading, error } = useSelector(
    (state) => state.getUserProfile
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  useEffect(() => {
    if (!loading && isAuthenticated && user) {
      if (user.role === "parent") {
        navigate("/Parentportal");
      } else if (user.role === "admin") {
        navigate("/AdminstratorDashbord");
      } else if (user.role === "student") {
        navigate("/Home");
      } else if (user.role === "teacher") {
        navigate("/teacherhome");
      }
    }
  }, [isAuthenticated]);

  return loading ? (
    <Loader />
  ) : (
    <div className="login-main">
      <div className="logo123">
        <img src={LOGOCEN} />
        <h1>MYCENTRALITY</h1>
      </div>
      <div className="login-main-child">
        <div className="login-main-taxt">
          <h2>Login/Signup as</h2>
          <p>Tell Us About You</p>
        </div>
        <div className="login-main-cardd">
          <div
            onClick={() => setSelectedRole("student")}
            className={`cardd ${isFocused ? "focused" : ""}`}
            tabIndex="0"
            onFocus={handleFocus}
            onBlur={handleBlur}
          >
            <img src={Studentimg1} />
            <h2>Student</h2>
          </div>
          <div
            onClick={() => setSelectedRole("teacher")}
            className={`cardd ${isFocused ? "focused" : ""}`}
            tabIndex="0"
            onFocus={handleFocus}
            onBlur={handleBlur}
          >
            <img src={Studentimg5} />
            <h2>Teacher</h2>
          </div>
          <div
            onClick={() => setSelectedRole("parent")}
            className={`cardd ${isFocused ? "focused" : ""}`}
            tabIndex="0"
            onFocus={handleFocus}
            onBlur={handleBlur}
          >
            <img src={Studentimg2} />
            <h2>Parent/Guardian</h2>
          </div>
          <div
            onClick={() => setSelectedRole("admin")}
            className={`cardd ${isFocused ? "focused" : ""}`}
            tabIndex="0"
            onFocus={handleFocus}
            onBlur={handleBlur}
          >
            <img src={Studentimg3} />
            <h2>Adminstrator</h2>
          </div>
          <div
            onClick={() => setSelectedRole("vendor")}
            className={`cardd ${isFocused ? "focused" : ""}`}
            tabIndex="0"
            onFocus={handleFocus}
            onBlur={handleBlur}
          >
            <img src={Studentimg4} />
            <h2>Vendor</h2>
          </div>
          <div
            onClick={() => setSelectedRole("government")}
            className={`cardd ${isFocused ? "focused" : ""}`}
            tabIndex="0"
            onFocus={handleFocus}
            onBlur={handleBlur}
          >
            <img src={Studentimg6} />
            <h2>Government</h2>
          </div>
        </div>

        <button onClick={handleNext} className="login-main-button">
          Next
        </button>
      </div>
    </div>
  );
}

export default LoginCards;
