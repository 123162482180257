import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../notification/Notification.css";
import NotificationdataCard from "../notificationCard/NotificationdataCard";
import NotificationData from "../notificationCard/NotificationData";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import axios from "axios";
import toast from "react-hot-toast";
import { baseURL } from "../../../constants";
import { getUserProfile } from "../../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

const notifiactionDot = "●";

function OffCanvasExample({ name, ...props }) {
  const [notifications, setNotifications] = useState([]);
  const dispatch = useDispatch();

  const { user, loading, error } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);
  const getNotifications = async () => {
    try {
      if (user) {
        let { data } = await axios.get(
          `/notifications/getAllNotifications/${user.dataValues.id}/student`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setNotifications(data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getNotifications();
  }, [user]);

  const deleteNotification = async (notification_id) => {
    try {
      const { data } = await axios.delete(
        `/notifications/removeNotification/${notification_id}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      getNotifications();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);
  function notcard(val) {
    return (
      <NotificationdataCard
        key={val.id}
        image={val.image}
        name={val.name}
        time={val.createdAt}
        body={val.body}
        dotIcons={notifiactionDot}
        deleteNotification={val.id}
        delete={deleteNotification}
      />
    );
  }

  const handleClearAll = async () => {
    setNotifications([]);
  };

  const handleShowUnread = () => {
    const unreadNotifications = NotificationData.filter(
      (notification) => notification.type === "unread"
    );
  };

  return (
    <>
      <div className="row notiFication">
        <div onClick={handleShow}>
          <NotificationsNoneOutlinedIcon />
        </div>
        <Offcanvas show={show} onHide={handleClose} {...props}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Notification</Offcanvas.Title>
          </Offcanvas.Header>
          <hr className="Noti-hr" />
          <Offcanvas.Body>
            <div className="notifications-btn">
              <button className="all-notification-btn">All Notification</button>
              <button className="unread-btn" onClick={handleShowUnread}>
                Unread
              </button>
            </div>
            <h1 className="notification-heading">TODAY</h1>
            {notifications && (
              <div className="notification-map">
                {notifications && notifications.notifications?.map(notcard)}
              </div>
            )}
            <div className="notification-yesterday">
              <h6>Yesterday</h6>
            </div>
            {notifications && (
              <div className="notification-map2">
                {notifications && notifications.notifications?.map(notcard)}
              </div>
            )}
            <div className="notification-clean-btn">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleClearAll();
                }}
              >
                Clear all
              </button>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
}

function Example() {
  return (
    <>
      {["end"].map((placement, idx) => (
        <OffCanvasExample key={idx} placement={placement} name={placement} />
      ))}
    </>
  );
}

export default Example;
