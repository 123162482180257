import React, { useState } from "react";
import "./TableView.css";
import { useTable, usePagination, useSortBy } from "react-table";
import { TiMediaPlayReverse } from "react-icons/ti";
import { TiMediaPlay } from "react-icons/ti";
import { PiDotsThree } from "react-icons/pi";
import { Link, useNavigate, useParams } from "react-router-dom";
import Dummy from "../../images/dummy-user.png";
import { BsThreeDots } from "react-icons/bs";
import Dropdown from "react-bootstrap/Dropdown";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { baseURL } from "../../../../constants";
import axios from "axios";
import toast from "react-hot-toast";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const TableView = ({ tableData, getAllStaffs }) => {
  const [deleteId, setDeleteId] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setDeleteId("");
  };
  const { role } = useParams();

  const navigate = useNavigate();

  const columns = [
    {
      Header: "Name",
      accessor: "staffName",
    },
    {
      Header: "Staff ID",
      accessor: "staffId",
    },
    {
      Header: "Profile",
      accessor: "profile",
    },
    {
      Header: "Mobile Number",
      accessor: "mobileNumber",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    state: { pageIndex },
    pageCount,
    gotoPage,
    canNextPage,
    canPreviousPage,
  } = useTable(
    {
      columns,
      data: tableData,
      autoResetHiddenColumns: false,
      initialState: { pageSize: 15 },
    },
    useSortBy,
    usePagination
  );

  const handleDeleteStaff = async () => {
    try {
      await axios.delete(`/teachers/remove/${deleteId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      getAllStaffs();
      handleClose();
      toast.success("Staff Deleted Successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <div className="table-container">
      <div className="CommentModal-Mmain">
        <Modal
          className="Allowmodalmain"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div className="CommentModal-Mmain-child1">
                <h4>Delete Staff</h4>
                <p>
                  Are you sure you want to delete the Staff? This will remove
                  the Staff and can’t be undone.
                </p>
                <div className="CommentModal-Mmain-child2">
                  <button
                    onClick={() => handleClose()}
                    className="CommentModal-Mmain-child2-Dont-allow"
                  >
                    CANCEL
                  </button>
                  <button
                    onClick={handleDeleteStaff}
                    className="CommentModal-Mmain-child2-allow"
                  >
                    DELETE
                  </button>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
      <div className="amrooooogand">
        <table style={{ overflow: "visible" }} {...getTableProps()}>
          <thead>
            {headerGroups.map((hg) => {
              return (
                <tr {...hg.getHeaderGroupProps()}>
                  {hg.headers.map((header) => {
                    return (
                      <th
                        {...header.getHeaderProps(
                          header.getSortByToggleProps()
                        )}
                      >
                        {header.render("Header")}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody style={{ overflow: "visible" }} {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr style={{ overflow: "visible" }} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.column.Header === "Name" ? (
                          <div
                            onClick={() =>
                              navigate(`/StaffProfile/${row.original?.id}`)
                            }
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "15px",
                              cursor: "pointer",
                            }}
                          >
                            <input type="checkbox" />
                            <img
                              src={`${baseURL}/images/${cell.row.original.image}`}
                              alt={"Person"}
                              style={{
                                width: "50px",
                                borderRadius: "100%",
                                height: "50px",
                              }}
                              onError={({ currentTarget }) => {
                                currentTarget.src = Dummy;
                              }}
                            />
                            <p style={{ cursor: "pointer" }}>
                              {cell.row.original?.studentName ||
                                cell.row.original?.parentName ||
                                cell.row.original?.staffName}
                            </p>
                          </div>
                        ) : cell.column.Header === "Action" ? (
                          <p
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <div className="Mini_MAin">
                              <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                  <BsThreeDots />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <div className="Mini-Main-Div">
                                    <Dropdown href="#/action-1">
                                      <Link
                                        to={`/StaffProfile/${row.original?.id}?edit=true`}
                                        className="Mini-Main-Btns2"
                                      >
                                        Edit
                                      </Link>
                                    </Dropdown>
                                    <Dropdown href="#/action-2">
                                      <p
                                        onClick={() => {
                                          setDeleteId(cell.row.original?.id);
                                          handleOpen();
                                        }}
                                        className="Mini-Main-Btns2"
                                      >
                                        Delete
                                      </p>
                                    </Dropdown>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </p>
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="tablefooter">
        <div>
          <p>
            Showing {pageIndex + 1} of {pageCount} from {tableData.length}
          </p>
        </div>
        <div className="buttons">
          <button onClick={previousPage} className="previousButton">
            <TiMediaPlayReverse size={18} color="#00afef" />
          </button>
          <button
            style={{
              backgroundColor: pageIndex === 0 ? "#00afef" : "#c4e6f5",
              color: pageIndex === 0 ? "white" : "#00afef",
            }}
            onClick={() => gotoPage(0)}
            className="numbersButton"
          >
            1
          </button>

          <button
            style={{
              backgroundColor: pageIndex === 1 ? "#00afef" : "#c4e6f5",
              color: pageIndex === 1 ? "white" : "#00afef",
            }}
            onClick={() => gotoPage(1)}
            className="numbersButton"
          >
            2
          </button>
          <button
            style={{
              backgroundColor: pageIndex === 2 ? "#00afef" : "#c4e6f5",
              color: pageIndex === 2 ? "white" : "#00afef",
            }}
            onClick={() => gotoPage(2)}
            className="numbersButton"
          >
            3
          </button>
          <button
            style={{
              backgroundColor: pageIndex === 3 ? "#00afef" : "#c4e6f5",
              color: pageIndex === 3 ? "white" : "#00afef",
            }}
            onClick={() => gotoPage(3)}
            className="numbersButton"
          >
            4
          </button>
          <button
            style={{
              backgroundColor: pageIndex === 4 ? "#00afef" : "#c4e6f5",
              color: pageIndex === 4 ? "white" : "#00afef",
            }}
            onClick={() => gotoPage(4)}
            className="numbersButton"
          >
            5
          </button>
          <button
            className="numbersButton"
            onClick={() => gotoPage(pageCount - 1)}
          >
            <PiDotsThree size={23} />
          </button>
          <button
            style={{
              backgroundColor: "#c4e6f5",
              width: "35px",
              height: "35px",
              borderRadius: "10px",
              border: "none",
              backgroundColor: "#c4e6f5",
              cursor: "pointer",
            }}
            onClick={nextPage}
            className="next"
          >
            <TiMediaPlay size={18} color="#00afef" />
          </button>
        </div>
      </div>
      <p className="footer-text">© 2023 MyCentrality. All Rights Reserved.</p>
    </div>
  );
};

export default TableView;
