import React, { useState } from "react";
import { GrNext, GrPrevious } from "react-icons/gr";
import "./CardCarousel.css";

const CardCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const paragraphs = [
    "School starts on January 13th, and roll will be taken daily at the start of each student group's instructional time. If you have any questions, please contact your student's school office or email us at mycentrality21.",
    "Don't forget to attend the parent-teacher conference next Friday at 5 PM. It's a great opportunity to discuss your child's progress.",
    "We are excited to announce the school carnival happening on February 20th. Join us for fun games, food, and activities for all ages.",
    "Please be reminded that the science fair project submissions are due by March 15th. Good luck to all participants!",
  ];

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? paragraphs.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === paragraphs.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="Cardcarousel-container">
      <div className="carousel-slide">
        <div className="CArdslide active">
          <p>{paragraphs[activeIndex]}</p>
        </div>
      </div>
      <div className="CardCarousel-Btns">
        <button className="arrow-left" onClick={handlePrev}>
          <GrPrevious />
        </button>
        <button className="arrow-right" onClick={handleNext}>
          <GrNext />
        </button>
      </div>
    </div>
  );
};

export default CardCarousel;
