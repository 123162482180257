import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { CiSearch } from "react-icons/ci";

const VendorChart = () => {

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Net Profit",
        data: [4, 2, 3, 2, 3, 5, 3],
      },
      {
        name: "Revenue",
        data: [3, 4, 2, 4, 2, 4, 1],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "vendor 1",
          "vendor 2",
          "vendor 3",
          "vendor 4",
          "vendor 5",
          "vendor 6",
          "vendor 7",
        ],
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " Vendors Overview";
          },
        },
      },
    },
  });

  return (
    <div>
      <div className="SeaRch">
        <h4>Vendors Overview</h4>
        <div className="inputSeaRch">
          <input type="search" placeholder="Search for Vendor" />
          <CiSearch />
        </div>
      </div>
      <div id="chart">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default VendorChart;
