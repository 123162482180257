import React from "react";
import { FaCalendar } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";
import "./MyGrade.css";
import { Link } from "react-router-dom";
import "../../../StudentComponents/data/MyscheduleData";
const Mygrade = () => {
  const gradeStyles = [
    {
      result: "failed",
      labelColor: "#f93333",
      buttonColor: "#f93333",
      progressbarColor: "#f93333",
      gradebc: "#ffefef",
    },
    {
      result: "missed",
      buttonColor: "#9A9A9A",
      labelColor: "#9A9A9A",
      progressbarColor: "#9A9A9A",
      gradebc: "#E6E6E6",
    },
    {
      result: "passed",
      buttonColor: "#11A529",
      labelColor: "#11A529",
      progressbarColor: "#11A529",
      gradebc: "#Effff5",
    },
  ];
  // const mapGradeData = (originalData, gradeStyles) => {
  //   return originalData.map((item) => {
  //     const style =
  //       gradeStyles.find((gradeStyle) => gradeStyle.result === item.result) ||
  //       {};
  //     return { ...item, ...style };
  //   });
  // };
  // const [gradeData, setGradeData] = useState();
  // useEffect(() => {
  //   const getAllGrades = async () => {
  //     const allStudentGradesUrl = `${baseURL}/grades/allStudentGrades`;
  //     let response = await axios.get(allStudentGradesUrl);
  //     const gradadedData = mapGradeData(response.data, gradeStyles);
  //     console.log(gradadedData);
  //     setGradeData(gradadedData);
  //   };
  //   getAllGrades();
  // }, []);

  // const updateScoredPercentage = (id, newScore) => {
  //   const updatedData = gradeData.map((gradecard) => {
  //     if (gradecard.id === id) {
  //       return { ...gradecard, scoredPercentage: newScore };
  //     }
  //     return gradecard;
  //   });
  //   setGradeData(updatedData);
  // };

  return (
    <div className="MyGrade-main-Map">
      <div
        className="mygrade-main"
        style={{ marginTop: "1px", backgroundColor: gradeStyles?.gradebc }}
      >
        <span style={{ color: gradeStyles?.labelColor }}>
          {gradeStyles.result}
        </span>
        <div className="mygrade-child">
          <div className="mygrade-child-1">
            <h2>{gradeStyles.gradeTitle}</h2>
            <p>Subject : {gradeStyles.subject}</p>
          </div>
          <div className="mygrade-time">
            <div className="calander-icon">
              <FaCalendar style={{ marginTop: "3px" }} />
              <p>{gradeStyles.date}</p>
            </div>
            <div className="time-icon">
              <FaRegClock style={{ marginTop: "4px" }} />
              <p>{gradeStyles.time}</p>
            </div>
          </div>
          <div className="mygrade-percentage">
            <p className="mygrade-percentage-test">{gradeStyles.gradetype}</p>
            <div className="Passing-Percentage">
              <h6>Passing Percentage</h6>
              <p>{gradeStyles.passingPercentage}%</p>
            </div>
            <div className="Scored-Percentage">
              <h6>Scored Percentage</h6>
              <p style={{ color: "#f93333" }}>
                {gradeStyles.scoringPercentage}%
              </p>
            </div>
          </div>
          <div className="progress-bar-line">
            <progress
              className="mygrade-process-line"
              id={`file-${gradeStyles.id}`}
              value={gradeStyles.scoringPercentage}
              max="100"
              style={{ "--progressbar-color": gradeStyles?.progressbarColor }}
            >
              {gradeStyles.scoredPercentage}%
            </progress>
          </div>
          <div className="mygrade-btn">
            <button style={{ backgroundColor: gradeStyles?.buttonColor }}>
              <Link to={{ pathname: "/Assigment", state: gradeStyles }}>
                View Details
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mygrade;
