import React, { useState } from "react";
import Chart from "react-apexcharts";
import Dropdown from "react-bootstrap/Dropdown";
// import axios from "axios";
// import { baseURL } from "../../../../constants";

const TeacherHomeChart = ({ thisWeek = [], previousWeek = [] }) => {
  const [selectedWeek, setSelectedWeek] = useState("This Week");
  // const [data, setData] = useState([]);

  /*
  const seriesStructure = (week) => [
    {
      name: "Screen Time", 
      data:
        week === "thisWeek"
          ? thisWeek.map((element) => element?.screenTime)
          : previousWeek.map((element) => element?.screenTime),
    },
    {
      name: "App Usage",
      data:
        week === "thisWeek"
          ? thisWeek.map((element) => element?.appUsage)
          : previousWeek.map((element) => element?.appUsage),
    },
  ];
  */

  const seriesStructure = (week) => [
    {
      name: "Screen Time",
      data: [43, 432, 23, 34, 53, 23, 32],
    },
    {
      name: "App Usage",
      data: [43, 65, 34, 23, 654, 23, 53],
    },
  ];

  const options = {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 4,
      labels: {
        formatter: function (value) {
          return value + "%";
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: ["#FFF"],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
  };

  // useEffect(() => {
  //   const getAppUsageData = async () => {
  //     try {
  //       const appUsageAnalyticsUrl = `${baseURL}/timings/appUsageAnalytics/1`;
  //       let response = await axios.get(`${appUsageAnalyticsUrl}`);

  //       console.log(response.data, "Juuu");

  //       setData(response.data);
  //     } catch (err) {
  //       console.log(err.response.data.message);
  //     }
  //   };
  //   getAppUsageData();
  // }, []);

  const handleWeekChange = (week) => {
    setSelectedWeek(week);
  };

  return (
    <div className="app">
      <div className="row">
        <div className="chart-container">
          <div className="dropdowns">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {selectedWeek}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleWeekChange("This Week")}>
                  This Week
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleWeekChange("Previous Week")}
                >
                  Previous Week
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <Chart
            options={options}
            series={seriesStructure(selectedWeek)}
            type="bar"
            width="100%"
            height="400"
          />
        </div>
      </div>
    </div>
  );
};

export default TeacherHomeChart;
