import React, { Component } from "react";
import { render } from "react-dom";
import GaugeChart from "react-gauge-chart";

const MAX_VALUE = 20000;

class DollarMeter extends Component {
  constructor() {
    super();
    this.state = {
      gaugeValue: 0.58,
    };
  }

  render() {
    const { gaugeValue } = this.state;
    const valueInDollars = gaugeValue * MAX_VALUE;
    const fontSize = 10 + gaugeValue * 14;
    return (
      <div style={{ textAlign: "center" }}>
        <GaugeChart
          id="gauge-chart3"
          nrOfLevels={1}
          colors={["#00afef"]}
          arcWidth={0.2}
          percent={gaugeValue}
          textColor={"black"}
          hideText={true}
          style={{ position: "relative" }}
        />
        <div
          style={{
            fontSize: `${fontSize}px`,
            color: "black",
            paddingTop: "1px",
          }}
        >
          {`$${valueInDollars.toFixed(2)}`}
        </div>
      </div>
    );
  }
}

export default DollarMeter;

render(<DollarMeter />, document.getElementById("root"));
