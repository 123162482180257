import React, { useState, useEffect } from "react";
import { RxCrossCircled } from "react-icons/rx";
import { IoIosAddCircleOutline } from "react-icons/io";
import "./GPAcalculater.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../../constants";
axios.defaults.baseURL = baseURL;

function GPACalculator() {
  const { user } = useSelector((state) => state.getUserProfile);
  const [allSubjects, setAllSubjects] = useState(null);
  const [semesters, setSemesters] = useState([
    { selectedSubject: "", gpaData: null },
  ]);

  const getSubjects = async () => {
    try {
      const { data } = await axios.get(
        `/studentCourses/getSubjectbystudentId/${user?.dataValues?.id}`,
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );

      setAllSubjects(data?.studentCourses);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSubjects();
  }, [user]);

  const addSemester = () => {
    setSemesters([...semesters, { selectedSubject: "", gpaData: null }]);
  };

  const [currentGpa, setCurrentGpa] = useState(null);
  const clearAllSemesters = () => {
    setSemesters([]);
    setCurrentGpa(null);
  };

  const updateSemester = (index, updatedSemester) => {
    const newSemesters = semesters.map((semester, i) =>
      i === index ? updatedSemester : semester
    );
    setSemesters(newSemesters);
  };
  const removeSemester = (index) => {
    const newSemesters = semesters.filter((_, i) => i !== index);
    setSemesters(newSemesters);
  };

  const [transformedGpa, setTransformedGpa] = useState([]);

  useEffect(() => {
    if (semesters) {
      const filteredSemesters = semesters.filter((sem) => {
        return sem?.gpaData !== null;
      });

      const newArr = filteredSemesters.map((fil) => {
        return {
          totalmarkes: fil?.gpaData?.[0]?.totalmarkes,
          obtainmarkes: fil?.gpaData?.[0]?.obtainmarkes,
        };
      });
      setTransformedGpa(newArr);
    }
  }, [semesters]);

  const handleCalculateApi = async () => {
    try {
      const { data } = await axios.post(
        `/subjects/calculateGPA`,
        {
          grades: [...transformedGpa],
        },
        {
          headers: {
            accessToken: localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );

      setCurrentGpa(data?.gpa);
    } catch (error) {
      console.log(error, "GPA Error");
    }
  };

  return (
    <>
      <h2 className="Gpa-h2">GPA Calculator</h2>
      <div className="GpaSecond">
        <div className="GpaHead">
          <h6>Course Name</h6>
          <h6>Grade</h6>
          <h6>Credits</h6>
          <h6>Points</h6>
          <h6>{}</h6>
        </div>
        {semesters.map((semester, index) => (
          <Calculator
            key={index}
            index={index}
            allSubjects={allSubjects}
            semester={semester}
            updateSemester={updateSemester}
            removeSemester={removeSemester}
            userId={user?.dataValues?.id}
          />
        ))}

        <div className="TwoButtonsADD">
          <button className="TwoButtonsADD-1" onClick={addSemester}>
            <IoIosAddCircleOutline /> Add Course
          </button>
          <button onClick={clearAllSemesters} className="TwoButtonsADD-2">
            <RxCrossCircled /> Clear All
          </button>
        </div>
        <div className="GpaInpt GpaInput2">GPA: {currentGpa}</div>
        <div className="GPALastButtons">
          <button onClick={handleCalculateApi} className="Calculate-btn">
            Calculate
          </button>
          <button className="ADDSemi-btn" onClick={addSemester}>
            Add Semester
          </button>
        </div>
      </div>
    </>
  );
}

function Calculator({
  index,
  allSubjects = [],
  semester,
  updateSemester,
  removeSemester,
  userId,
}) {
  const { selectedSubject, gpaData } = semester;

  const { user } = useSelector((state) => state.getUserProfile);

  const getGpa = async (subjectId) => {
    try {
      const { data } = await axios.get(
        `/subjects/fetchGrades/${subjectId}/${userId}`,
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );

      const updatedSemester = {
        ...semester,
        gpaData: data,
      };
      updateSemester(index, updatedSemester);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedSubject) {
      getGpa(selectedSubject);
    }
    // eslint-disable-next-line
  }, [selectedSubject]);

  const handleSubjectChange = (e) => {
    const newSelectedSubject = e.target.value;
    updateSemester(index, { ...semester, selectedSubject: newSelectedSubject });
  };

  return (
    <div className="GpaDiv GpADiv2">
      <select value={selectedSubject} onChange={handleSubjectChange}>
        <option value="">Select Course</option>
        {allSubjects?.map((sub) => (
          <option key={sub?.subjectId} value={sub?.subjectId}>
            {sub?.Subject?.subjectName}
          </option>
        ))}
      </select>
      <input readOnly value={gpaData?.[0]?.letterGrade} />

      <input type="number" readOnly value={gpaData?.[0]?.totalmarkes || ""} />
      <input type="number" readOnly value={gpaData?.[0]?.obtainmarkes || ""} />
      <RxCrossCircled onClick={() => removeSemester(index)} />
    </div>
  );
}

export default GPACalculator;
