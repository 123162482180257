import React, { useEffect, useState } from "react";
import "./NestedTabs.css";
import {
  Tab,
  Nav,
  Row,
  Col,
  ProgressBar,
  Dropdown,
  Form,
} from "react-bootstrap";
import {
  IoAddSharp,
  IoArrowBack,
  IoArrowBackOutline,
  IoCaretForwardOutline,
} from "react-icons/io5";
import Summary from "./Summary/Summary";
import LineChart from "./Chart/LineChart";
import NoticeBoard from "./NoticeBoard/NoticeBoard";
import TopADDCards from "./ADDCards/TopADDCards";
import { TfiLayoutGrid3Alt, TfiLayoutGrid2 } from "react-icons/tfi";
import Modal from "react-bootstrap/Modal";
import TeacherMyassingmentMain from "./createNewTest/TeacherMyassingmentMain";
import "./CreateAssignmentModal/CreateModal.css";
import CreateModal from "./CreateAssignmentModal/CreateModal";
import { Link, useNavigate, useParams } from "react-router-dom";
import TeacherClassLectures from "../TeacherNestedTabs/ClassLectures/TeacherClassLectures";
import UploadModal from "../TeacherNestedTabs/ClassLectures/UploadLectures/UploadModal";
import axios from "axios";
import { baseURL } from "../../../../../constants";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { getUserProfile } from "../../../../../redux/actions/userAction";
import { MdModeEdit, MdOutlineDelete, MdOutlineImage } from "react-icons/md";
import { RiBallPenLine, RiDeleteBin6Line } from "react-icons/ri";
import { FaCheck } from "react-icons/fa6";
import GradeBook from "../../../GradeBook/GradeBook";
import { BsThreeDotsVertical } from "react-icons/bs";
axios.defaults.baseURL = baseURL;

const NestedTabs = () => {
  const indexing = ["a", "b", "c", "d"];
  const [editId, setEditId] = useState("");

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const [key, setKey] = useState("tab1");

  const params = useParams();

  const [assignments, setAssignments] = useState(null);

  const getAssignments = async () => {
    try {
      const { data } = await axios.get(
        `/assignments/getAssignmentsByClassroomId/${localStorage.getItem(
          "classroomId"
        )}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setAssignments(data);
    } catch (error) {
      console.log(error);
    }
  };
  const [grades, setGrades] = useState(null);

  const getGrades = async () => {
    try {
      const { data } = await axios.get(
        `/grades/getGradesBySubject/${params?.subjectId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setGrades(data?.grades);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGrades();

    getAssignments();
  }, []);
  console.log(grades);
  const [lgShow, setLgShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const toggleSwitch = () => {
    setIsChecked(!isChecked);
  };

  const [file, setFile] = useState(null);

  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [points, setPoints] = useState("");
  const [grading, setGrading] = useState("");
  const [assignOn, setAssignOn] = useState("");
  const [dueOn, setDueOn] = useState("");
  const [maxMarks, setMaxMarks] = useState("");

  const [tests, setTests] = useState(null);

  console.log(localStorage.getItem("classroomId"), "this is classroom");
  const getTests = async () => {
    try {
      const { data } = await axios.get(
        `/Tests/getAllTestsByClassroomId/${localStorage.getItem(
          "classroomId"
        )}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setTests(data?.tests);
    } catch (error) {
      console.log(error);
    }
  };

  const [schoolId, setSchoolId] = useState("");
  const getSchoolByTeacherId = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/ParentBySchoolId/getSchoolByTeacherId/${user?.dataValues?.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        setSchoolId(data?.school?.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchoolByTeacherId();
  }, [user]);
  console.log(schoolId, "this is my school Id");
  const handleCreateAssignment = async () => {
    try {
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.message ||
            error?.response?.data?.details[0]?.detail
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const [notices, setNotices] = useState(null);

  const getNoticeboard = async () => {
    try {
      const { data } = await axios.get(
        `/noticeboards/getNoticeboardsByClassroomId/${localStorage.getItem(
          "classroomId"
        )}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );

      setNotices(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTests();
    getNoticeboard();
  }, []);

  const [questions, setQuestions] = useState([
    {
      id: 1,
      text: "",
      image: null,
      options: [{ id: 1, text: "", image: null, isChecked: false }],
    },
  ]);
  const [classLecture, setClassLecture] = useState([]);

  const GetClassData = async () => {
    try {
      const { data } = await axios.get(
        `/lectures/getLecturesByClassroomId/${localStorage.getItem(
          "classroomId"
        )}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setClassLecture(data);
      setEditId("");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetClassData();
  }, []);

  const [subject, setSubject] = useState(null);
  const { subjectId } = useParams();
  const getSubject = async () => {
    try {
      const { data } = await axios.get(
        `/subjects/getSubjectById/${subjectId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setSubject(data?.subject);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSubject();
  }, []);

  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (index) => {
    setSelectedOption(index);
  };

  const options = [
    { text: "Rishi Sunak" },
    { text: "Bill Harry" },
    { text: "Prince Charles" },
    { text: "Lady Diana" },
  ];

  const now = 25;
  const noww = 50;
  const nowww = 75;
  const nowwww = 100;
  // eslint-disable-next-line
  const [wordCount, setWordCount] = useState(0);

  const maxWords = 5000;

  function valuetext(value) {
    return `${value}%`;
  }

  const [percentage, setPercentage] = useState(70);

  const handleSliderChange = (event, newValue) => {
    setPercentage(newValue);
  };

  const [tab, setTab] = useState("createTest");

  const [questionEditId, setQuestionEditId] = useState("");

  const addQuestion = () => {
    if (questionEditId) {
      setQuestions((prevQuestions) =>
        prevQuestions.map((question) =>
          question.id === questionEditId
            ? {
                ...question,
              }
            : question
        )
      );
      setQuestionEditId(null);
    } else {
      const newQuestionId = questions.length + 1;
      setQuestions([
        ...questions,
        {
          id: newQuestionId,
          text: "",
          image: null,
          options: [{ id: 1, text: "", image: null, isChecked: false }],
        },
      ]);
    }
  };

  const deleteQuestion = (questionId) => {
    const newQuestions = modifiedQuestions.filter(
      (question) => question.id !== questionId
    );
    console.log(questionId, newQuestions, "Mainer");
    setQuestions(newQuestions);
  };

  const addOption = (questionId) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question) => {
        if (question.id === questionId && question.options.length < 4) {
          return {
            ...question,
            options: [
              ...question.options,
              {
                id: question.options.length + 1,
                text: ``,
                image: null,
                isChecked: false,
              },
            ],
          };
        }
        return question;
      })
    );
  };

  const handleImageChange = (e, questionId, optionId = null) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setQuestions((prevQuestions) =>
          prevQuestions.map((question) => {
            if (question.id === questionId) {
              if (optionId === null) {
                return { ...question, image: file };
              } else {
                return {
                  ...question,
                  options: question.options.map((option) =>
                    option.id === optionId ? { ...option, image: file } : option
                  ),
                };
              }
            }
            return question;
          })
        );
      };
      reader.readAsDataURL(file);
    }
  };

  const handleQuestionTextChange = (e, questionId) => {
    const newQuestions = questions.map((question) =>
      question.id === questionId
        ? { ...question, text: e.target.value }
        : question
    );
    setQuestions(newQuestions);
  };

  const handleOptionTextChange = (e, questionId, optionId) => {
    const newQuestions = questions.map((question) =>
      question.id === questionId
        ? {
            ...question,
            options: question.options.map((option) =>
              option.id === optionId
                ? { ...option, text: e.target.value }
                : option
            ),
          }
        : question
    );
    setQuestions(newQuestions);
  };

  const handleCheckboxChange = (questionId, optionId) => {
    const newQuestions = questions.map((question) =>
      question.id === questionId
        ? {
            ...question,
            options: question.options.map((option) => ({
              ...option,
              isChecked: option.id === optionId,
            })),
          }
        : question
    );
    setQuestions(newQuestions);
  };

  const [modifiedQuestions, setModifiedQuestions] = useState([]);
  useEffect(() => {
    if (questions && questions.length > 0 && subject) {
      const newArr = questions.map((question) => {
        return {
          classroomId: localStorage.getItem("classroomId"),
          questionText: question.text,
          tag: subject?.subjectName,
          options: question.options.map((option) => ({
            optionText: option.text,
            isCorrect: option.isChecked,
          })),
        };
      });
      setModifiedQuestions(newArr);
    }

    if (questions.length <= 0) {
      setModifiedQuestions([]);
    }
  }, [questions, subject]);

  const [allQuestions, setAllQuestions] = useState(null);
  const [testerId, setTesterId] = useState("");

  const getAllQuestions = async () => {
    try {
      const { data } = await axios.get(
        `/StudentTests/getQuestionsByTestId/${testerId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );

      setAllQuestions(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (testerId) {
      getAllQuestions();
    }
  }, [testerId]);

  const [currentFilter, setCurrentFilter] = useState("assignments");

  const publishTest = async () => {
    try {
      const formData = new FormData();
      if (user) {
        const newForm = new FormData();
        newForm.append("teacherId", user.dataValues.id);
        newForm.append("file", file);
        newForm.append("subjectId", params?.subjectId);
        newForm.append("testName", name);
        newForm.append("category", category);
        newForm.append("title", title);
        newForm.append("description", description);
        newForm.append("points", points);
        newForm.append("grading", grading);
        newForm.append("assignedOn", assignOn);
        newForm.append("schoolId", schoolId);

        newForm.append("dueOn", dueOn);
        newForm.append("maxMarks", Number(maxMarks));
        newForm.append("classroomId", localStorage.getItem("classroomId"));

        const { data } = await axios.post(`/Tests/addTest`, newForm, {
          headers: {
            "Content-Type": "multipart/form-data",
            accessToken: `${localStorage.getItem("token")}`,
          },
        });
        console.log(data, "your main data");
        toast.success("Test Created Successfully");

        if (data) {
          console.log(formData, "this is form data");
          setTesterId(data?.test?.id);
          const { data: fData } = await axios.post(
            `/StudentTests/addstudenttest`,
            modifiedQuestions.map((question) => {
              return {
                classroomId: localStorage.getItem("classroomId"),
                questionText: question.questionText,
                tag: subject?.subjectName,
                testId: data?.test?.id,
                options: question.options.map((option) => ({
                  optionText: option.optionText,
                  isCorrect: option.isCorrect,
                })),
              };
            }),
            {
              headers: {
                accessToken: `${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );

          const newArr = fData?.questions?.map((qt) => {
            return {
              id: qt?.newQuestion?.id,
              questionText: qt?.newQuestion?.questionText,
              options: qt?.options,
            };
          });

          setAllQuestions(newArr);
          setQuestions([
            {
              id: 1,
              text: "",
              image: null,
              options: [{ id: 1, text: "", image: null, isChecked: false }],
            },
          ]);
          setName("");
          setCategory("");
          setTitle("");
          setDescription("");
          setPoints("");
          setGrading("");
          setAssignOn("");
          setDueOn("");
          setMaxMarks("");
          setFile(null);
          //  setLgShow(false);
          getTests();

          setTab("proceeded");
        }
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.error);
      }
    }
  };

  const abc = ["a.", "b.", "c.", "d."];
  const handleDeleteQuestion = async (id) => {
    try {
      await axios.delete(`/StudentTests/removeStudentTest/${id}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      getAllQuestions();
      toast.success("Question deleted successfully");
    } catch (error) {
      console.log(error);
      toast.error("Failed to delete question");
    }
  };

  const [quizRules, setQuizRules] = useState("");
  const [timePerQuestion, setTimePerQuestion] = useState("");
  const [seconds, setSeconds] = useState("");

  const [numberOfQuestions, setNumberOfQuestions] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const handleQuizProceed = () => {
    if (!quizRules || !timePerQuestion || !seconds)
      return toast.error("Please fill all the fields");

    setTab("quizRuleHandler");
  };

  const handleSecondQuizProceed = () => {
    if (!numberOfQuestions || !date || !time || !percentage)
      return toast.error("Please fill all the fields");

    setTab("quizRuleCreator");
  };

  const handleSubmitQuiz = async () => {
    try {
      // eslint-disable-next-line
      const { data } = await axios.post(
        `/quizs/add`,
        {
          quizRules,
          timePerQuestion,
          numberOfQuestions,
          passingPercentage: percentage,
          date,
          time,
          seconds,
          classroomId: localStorage.getItem("classroomId"),
          testId: testerId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            accessToken: localStorage.getItem("token"),
          },
        }
      );

      handleCreateAssignment();
      setTab("quizSuccess");
    } catch (error) {
      console.log(error, "this is error");
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.error);
      }
    }
  };

  const handleCreate = () => {
    if (
      !user?.dataValues?.id ||
      !params?.subjectId ||
      !name ||
      !category ||
      !title ||
      !description ||
      !points ||
      !grading ||
      !assignOn ||
      !schoolId ||
      !dueOn ||
      !maxMarks ||
      !localStorage.getItem("classroomId")
    )
      return toast.error("Please fill all the fields");
    setTab("addNewQuestion");
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (key === "tab1") {
      navigate("?key=summary");
    } else {
      navigate("?");
    }
  }, [key]);

  const [showWhat, setShowWhat] = useState("");

  const handleFilter = () => {
    setShowWhat(currentFilter);
  };

  useEffect(() => {
    setShowWhat("assignments");
  }, []);
  return (
    <div className="NESted-TAbs">
      <Tab.Container
        id="nested-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Row>
          <Col sm={12} className="backGroundColor">
            <div className="Nested-Dflex">
              <div className="whereAreYou">
                <div className="homee">
                  <Link to="/teacherhome" className="blueColor">
                    Home
                  </Link>
                  <span>
                    <IoCaretForwardOutline />
                  </span>
                  <Link to="/TeacherClassRoomTopSearch" className="blueColor">
                    Classrooms
                  </Link>
                  <span className="gray">
                    <IoCaretForwardOutline />
                  </span>
                  <p>{subject?.subjectName}</p>
                </div>
              </div>
              <Nav variant="tabs" className="nestedTabs">
                <Nav.Item>
                  <Nav.Link eventKey="tab1">Summary</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab2">Gradebook</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab3">Quizzes</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab5">Noticeboard</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab6">Class Lectures</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab7">Timetable</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="tab1">
                <Summary />
                <div className="TeacherClassRoomCards">
                  <TopADDCards />
                </div>
                <div className="Chart">
                  <LineChart />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="tab2">
                <GradeBook />
              </Tab.Pane>

              <Tab.Pane eventKey="tab3">
                <Tab.Container
                  id="nested-subtabs-assignments"
                  defaultActiveKey="graded"
                >
                  <div className="main-Links">
                    <div className="mainLinks">
                      <Row>
                        <Col sm={12}>
                          <Nav variant="tabs" className="navLinksMain">
                            <div className="createNewAssignment">
                              <div className="AssigmentContent">
                                <h4>Assignment Content </h4>{" "}
                                <span>
                                  <TfiLayoutGrid3Alt />
                                </span>
                                <span className="AssignmentIconColor">
                                  <TfiLayoutGrid2 />
                                </span>
                              </div>
                              <div className="FlexNew">
                                {showWhat === "assignments" && (
                                  <CreateModal func={getAssignments} />
                                )}
                                {showWhat === "tests" && (
                                  <div className="fazuu">
                                    <div className="talpar">
                                      <div>
                                        <button
                                          onClick={() => {
                                            setLgShow(true);
                                            setTab("createTest");
                                          }}
                                          className="CreateModal-btn"
                                        >
                                          Create New Test
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div className="NewDropDown">
                                  <Dropdown>
                                    <div className="TeacherDropDNW">
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <BsThreeDotsVertical />
                                      </Dropdown.Toggle>
                                    </div>

                                    <Dropdown.Menu>
                                      <div className="NewDropDownBody">
                                        <h2>Categories </h2>
                                        <div className="NewBody">
                                          <div
                                            onClick={() =>
                                              setCurrentFilter("assignments")
                                            }
                                            className="NewBodyChild"
                                          >
                                            <Form.Check
                                              checked={
                                                currentFilter === "assignments"
                                              }
                                              aria-label="option 1"
                                            />
                                            <label>Assignments</label>
                                          </div>
                                          <div
                                            onClick={() =>
                                              setCurrentFilter("projects")
                                            }
                                            className="NewBodyChild"
                                          >
                                            <Form.Check
                                              checked={
                                                currentFilter === "projects"
                                              }
                                              aria-label="option 1"
                                            />
                                            <label>Projects</label>
                                          </div>
                                        </div>
                                        <div className="NewBody">
                                          <div
                                            onClick={() =>
                                              setCurrentFilter("tests")
                                            }
                                            className="NewBodyChild"
                                          >
                                            <Form.Check
                                              checked={
                                                currentFilter === "tests"
                                              }
                                              aria-label="option 1"
                                            />
                                            <label>Tests</label>
                                          </div>
                                          <div
                                            onClick={() =>
                                              setCurrentFilter("quizes")
                                            }
                                            className="NewBodyChild"
                                          >
                                            <Form.Check
                                              checked={
                                                currentFilter === "quizes"
                                              }
                                              aria-label="option 1"
                                            />
                                            <label>Quizzes</label>
                                          </div>
                                        </div>
                                        <div className="NewBody">
                                          <div
                                            onClick={() =>
                                              setCurrentFilter("OHT")
                                            }
                                            className="NewBodyChild"
                                          >
                                            <Form.Check
                                              checked={currentFilter === "OHT"}
                                              aria-label="option 1"
                                            />
                                            <label>OHT</label>
                                          </div>
                                          <div
                                            onClick={() =>
                                              setCurrentFilter("extraCredits")
                                            }
                                            className="NewBodyChild"
                                          >
                                            <Form.Check
                                              checked={
                                                currentFilter === "extraCredits"
                                              }
                                              aria-label="option 1"
                                            />
                                            <label>Extra Credit</label>
                                          </div>
                                        </div>
                                        <hr />
                                        <div className="NewBody">
                                          <div
                                            onClick={() =>
                                              setCurrentFilter("exams")
                                            }
                                            className="NewBodyChild"
                                          >
                                            <Form.Check
                                              checked={
                                                currentFilter === "exams"
                                              }
                                              aria-label="option 1"
                                            />
                                            <label>Exams</label>
                                          </div>
                                          <div
                                            onClick={() =>
                                              setCurrentFilter("finalExams")
                                            }
                                            className="NewBodyChild"
                                          >
                                            <Form.Check
                                              checked={
                                                currentFilter === "finalExams"
                                              }
                                              aria-label="option 1"
                                            />
                                            <label>Final Exams</label>
                                          </div>
                                        </div>
                                        <div className="NewDescriptions">
                                          <h6>Other</h6>
                                          <input
                                            type="text"
                                            placeholder="Description goes here"
                                          />
                                          <button
                                            onClick={handleFilter}
                                            className="NeButtons"
                                          >
                                            Filter
                                          </button>
                                        </div>
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                            {assignments && showWhat === "assignments" && (
                              <TeacherMyassingmentMain
                                data={assignments}
                                status={"assignments"}
                                func={getAssignments}
                                isAssignment={true}
                              />
                            )}
                            {tests && showWhat === "tests" && (
                              <TeacherMyassingmentMain
                                isTestEdit={true}
                                data={tests}
                                func={getTests}
                                status={"tests"}
                                setLgShow={setLgShow}
                              />
                            )}
                          </Nav>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Tab.Container>
              </Tab.Pane>
              <Tab.Pane eventKey="tab4">
                <Tab.Container
                  id="nested-subtabs-tests"
                  defaultActiveKey="retake"
                >
                  <div className="TestContent">
                    <Row>
                      <Col sm={12}>
                        <Nav variant="tabs">
                          <div className="Testtt" style={{ width: "100%" }}>
                            <div className="CreateModal-Main">
                              <Modal
                                size="lg"
                                show={lgShow}
                                onHide={() => setLgShow(false)}
                                aria-labelledby="example-modal-sizes-title-lg"
                              >
                                <Modal.Header
                                  closeButton
                                  className="CreateModal-header"
                                ></Modal.Header>
                                <Modal.Body>
                                  {tab === "createTest" && (
                                    <div className="CreateModal-body">
                                      <input
                                        type="text"
                                        value={name}
                                        onChange={(e) =>
                                          setName(e.target.value)
                                        }
                                        placeholder="Name"
                                      />
                                      {/* <input
                                        type="text"
                                        value={category}
                                        onChange={(e) =>
                                          setCategory(e.target.value)
                                        }
                                        placeholder="Category"
                                      /> */}
                                      <select
                                        type="text"
                                        value={category}
                                        onChange={(e) =>
                                          setCategory(e.target.value)
                                        }
                                        style={{ backgroundColor: "#fff" }}
                                      >
                                        <option value="">Category</option>
                                        <option value="Multiple Choice">
                                          Multiple Choice
                                        </option>
                                        <option value="Fill in the blanks">
                                          Fill in the blanks
                                        </option>
                                        <option value="True/False">
                                          True/False
                                        </option>
                                        <option value="File Upload">
                                          File Upload
                                        </option>
                                        <option value="Projects">
                                          Projects
                                        </option>
                                      </select>
                                      <label>Assignment Details</label>
                                      <input
                                        placeholder="Title"
                                        type="text"
                                        value={title}
                                        onChange={(e) =>
                                          setTitle(e.target.value)
                                        }
                                      />
                                      <textarea
                                        value={description}
                                        onChange={(e) =>
                                          setDescription(e.target.value)
                                        }
                                        placeholder="Description"
                                      />
                                      <div className="CreateModal-child">
                                        <div className="CreateModal-child-input">
                                          <label>Points</label>
                                          <input
                                            value={points}
                                            onChange={(e) =>
                                              setPoints(e.target.value)
                                            }
                                            type="number"
                                            placeholder="Points"
                                          />
                                        </div>
                                        <div className="CreateModal-child-select">
                                          <label>Grading</label>
                                          <input
                                            value={grading}
                                            onChange={(e) =>
                                              setGrading(e.target.value)
                                            }
                                            type="text"
                                          />
                                        </div>
                                      </div>
                                      <div className="CreateModal-child">
                                        <div className="CreateModal-child-select">
                                          <label>Assigned on</label>
                                          <input
                                            value={assignOn}
                                            onChange={(e) =>
                                              setAssignOn(e.target.value)
                                            }
                                            type="date"
                                          />
                                        </div>
                                        <div className="CreateModal-child-select">
                                          <label>Due On</label>
                                          <input
                                            value={dueOn}
                                            onChange={(e) =>
                                              setDueOn(e.target.value)
                                            }
                                            type="date"
                                          />
                                        </div>
                                      </div>
                                      <div className="CreateModal-Last">
                                        <div className="TestModal-Upload">
                                          <div className="CreateModal-Last12">
                                            <label>Total Marks</label>
                                            <input
                                              type="number"
                                              value={maxMarks}
                                              onChange={(e) =>
                                                setMaxMarks(e.target.value)
                                              }
                                              placeholder="10"
                                            />
                                          </div>
                                        </div>
                                        <div className="CreateModal-Child2">
                                          <div className="CreateModal-Child212">
                                            <p>
                                              Create noticeboard announcement
                                              for all students in classroom
                                            </p>
                                            <label
                                              className={`custom-switch ${
                                                isChecked ? "checked" : ""
                                              }`}
                                            >
                                              <input
                                                type="checkbox"
                                                checked={isChecked}
                                                onChange={toggleSwitch}
                                              />
                                              <span className="slider"></span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="CreateModal-Child22">
                                        <button
                                          onClick={() => {
                                            handleCreate();
                                          }}
                                        >
                                          Create Question
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                  {/* ..................................First One............................ */}
                                  {(tab === "addNewQuestion" ||
                                    questionEditId) && (
                                    <div className="AddNEwQuestion">
                                      <div className="AddNEwTop">
                                        <h3>Add New Questions</h3>
                                        <button
                                          onClick={() => {
                                            setTab("questionAdded");
                                            setQuestionEditId("");
                                          }}
                                        >
                                          Proceed
                                        </button>
                                      </div>
                                      <h4>Tag : {subject?.subjectName}</h4>
                                      <hr />
                                      <div>
                                        {!questionEditId
                                          ? questions.map((question) => (
                                              <div
                                                key={question.id}
                                                className="TypEQuestion"
                                              >
                                                <div className="TypEQuesMain">
                                                  <div className="TYpeTOPP">
                                                    <input
                                                      placeholder="Type your question here"
                                                      value={question.text}
                                                      onChange={(e) =>
                                                        handleQuestionTextChange(
                                                          e,
                                                          question.id
                                                        )
                                                      }
                                                    />

                                                    {!questionEditId && (
                                                      <div
                                                        className="TypeBin"
                                                        onClick={() =>
                                                          deleteQuestion(
                                                            question.id
                                                          )
                                                        }
                                                      >
                                                        <RiDeleteBin6Line />
                                                      </div>
                                                    )}
                                                  </div>
                                                  {question.options.map(
                                                    (option) => (
                                                      <div
                                                        key={option.id}
                                                        className="Typeoption"
                                                      >
                                                        <div className="TypeoptionMain">
                                                          <input
                                                            type="checkbox"
                                                            checked={
                                                              option.isChecked
                                                            }
                                                            onChange={() =>
                                                              handleCheckboxChange(
                                                                question.id,
                                                                option.id
                                                              )
                                                            }
                                                          />
                                                          <input
                                                            type="text"
                                                            className="TypEinput"
                                                            placeholder={
                                                              option.text
                                                            }
                                                            value={option.text}
                                                            onChange={(e) =>
                                                              handleOptionTextChange(
                                                                e,
                                                                question.id,
                                                                option.id
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                  <button
                                                    onClick={() =>
                                                      addOption(question.id)
                                                    }
                                                  >
                                                    + Add Option
                                                  </button>
                                                </div>
                                                {!questionEditId && (
                                                  <div
                                                    className="TypeADD"
                                                    onClick={addQuestion}
                                                  >
                                                    <IoAddSharp />
                                                  </div>
                                                )}
                                              </div>
                                            ))
                                          : questions.map(
                                              (question) =>
                                                question.id ===
                                                  questionEditId && (
                                                  <div
                                                    key={question.id}
                                                    className="TypEQuestion"
                                                  >
                                                    <div className="TypEQuesMain">
                                                      <div className="TYpeTOPP">
                                                        <input
                                                          placeholder="Type your question here"
                                                          value={question.text}
                                                          onChange={(e) =>
                                                            handleQuestionTextChange(
                                                              e,
                                                              question.id
                                                            )
                                                          }
                                                        />
                                                        <div
                                                          className="TypeImage"
                                                          onClick={() =>
                                                            document
                                                              .getElementById(
                                                                `fileInput-${question.id}`
                                                              )
                                                              .click()
                                                          }
                                                        >
                                                          {question.image ? (
                                                            <img
                                                              src={URL.createObjectURL(
                                                                question.image
                                                              )}
                                                              alt="Selected"
                                                              style={{
                                                                width: "100%",
                                                                height: "100%",
                                                              }}
                                                            />
                                                          ) : (
                                                            <MdOutlineImage />
                                                          )}
                                                          <input
                                                            id={`fileInput-${question.id}`}
                                                            type="file"
                                                            accept="image/*"
                                                            style={{
                                                              display: "none",
                                                            }}
                                                            onChange={(e) =>
                                                              handleImageChange(
                                                                e,
                                                                question.id
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                        {!questionEditId && (
                                                          <div
                                                            className="TypeBin"
                                                            onClick={() =>
                                                              deleteQuestion(
                                                                question.id
                                                              )
                                                            }
                                                          >
                                                            <RiDeleteBin6Line />
                                                          </div>
                                                        )}
                                                      </div>
                                                      {question.options.map(
                                                        (option) => (
                                                          <div
                                                            key={option.id}
                                                            className="Typeoption"
                                                          >
                                                            <div className="TypeoptionMain">
                                                              <input
                                                                type="checkbox"
                                                                checked={
                                                                  option.isChecked
                                                                }
                                                                onChange={() =>
                                                                  handleCheckboxChange(
                                                                    question.id,
                                                                    option.id
                                                                  )
                                                                }
                                                              />
                                                              <input
                                                                type="text"
                                                                className="TypEinput"
                                                                placeholder={
                                                                  option.text
                                                                }
                                                                value={
                                                                  option.text
                                                                }
                                                                onChange={(e) =>
                                                                  handleOptionTextChange(
                                                                    e,
                                                                    question.id,
                                                                    option.id
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                            <div
                                                              className="TypeImage"
                                                              onClick={() =>
                                                                document
                                                                  .getElementById(
                                                                    `fileInput-${question.id}-${option.id}`
                                                                  )
                                                                  .click()
                                                              }
                                                            >
                                                              {option.image ? (
                                                                <img
                                                                  src={URL.createObjectURL(
                                                                    option.image
                                                                  )}
                                                                  alt="Selected"
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                    height:
                                                                      "100%",
                                                                  }}
                                                                />
                                                              ) : (
                                                                <MdOutlineImage />
                                                              )}
                                                              <input
                                                                id={`fileInput-${question.id}-${option.id}`}
                                                                type="file"
                                                                accept="image/*"
                                                                style={{
                                                                  display:
                                                                    "none",
                                                                }}
                                                                onChange={(e) =>
                                                                  handleImageChange(
                                                                    e,
                                                                    question.id,
                                                                    option.id
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                          </div>
                                                        )
                                                      )}
                                                      <button
                                                        onClick={() =>
                                                          addOption(question.id)
                                                        }
                                                      >
                                                        + Add Option
                                                      </button>
                                                    </div>
                                                    {!questionEditId && (
                                                      <div
                                                        className="TypeADD"
                                                        onClick={addQuestion}
                                                      >
                                                        <IoAddSharp />
                                                      </div>
                                                    )}
                                                  </div>
                                                )
                                            )}
                                      </div>
                                    </div>
                                  )}
                                  {/* ..................................First One End............................ */}
                                  {/* ..................................Second Start............................ */}
                                  {tab === "questionAdded" && (
                                    <div className="PreviewQuestion">
                                      <div className="AddNEwTop">
                                        <h3>Add New Questions</h3>
                                        <button
                                          onClick={() => {
                                            publishTest();
                                          }}
                                        >
                                          Save & Publish
                                        </button>
                                      </div>
                                      <h4>Tag : {subject?.subjectName}</h4>
                                      <hr />
                                      <div className="PrevBody">
                                        <div className="PrevTop">
                                          <div
                                            onClick={() => {
                                              if (questions.length <= 0) {
                                                setQuestions([
                                                  {
                                                    id: 1,
                                                    text: "",
                                                    image: null,
                                                    options: [
                                                      {
                                                        id: 1,
                                                        text: "",
                                                        image: null,
                                                        isChecked: false,
                                                      },
                                                    ],
                                                  },
                                                ]);
                                              }
                                              setTab("addNewQuestion");
                                            }}
                                            className="PrevBack"
                                          >
                                            <IoArrowBack />
                                          </div>
                                          <h4>Preview Questions </h4>
                                        </div>
                                        {modifiedQuestions.map((md, index) => {
                                          return (
                                            <div>
                                              <div className="PrevQ1">
                                                <h2>Q{index + 1}</h2>
                                                <div className="PrevBUttons">
                                                  <button
                                                    className="PrevBUttOne"
                                                    onClick={() => {
                                                      setQuestionEditId(md.id);
                                                      setTab("addNewQuestion");
                                                    }}
                                                  >
                                                    <RiBallPenLine />
                                                    Edit
                                                  </button>

                                                  <button
                                                    onClick={() =>
                                                      deleteQuestion(md.id)
                                                    }
                                                    className="PrevBUttTwo"
                                                  >
                                                    <RiDeleteBin6Line />
                                                    Delete
                                                  </button>
                                                </div>
                                              </div>
                                              <h6>{md.questionText}</h6>
                                              {md.file && (
                                                <img
                                                  style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    objectFit: "contain",
                                                  }}
                                                  alt="#"
                                                  src={URL.createObjectURL(
                                                    md.file
                                                  )}
                                                />
                                              )}
                                              <div className="PrevOptions">
                                                {md?.options?.map((opt, i) => {
                                                  return (
                                                    <div className="PrevOone">
                                                      <p>
                                                        <span>
                                                          {indexing[i]}.{" "}
                                                        </span>
                                                        {opt?.optionText}
                                                      </p>
                                                      {opt.image && (
                                                        <img
                                                          src={URL.createObjectURL(
                                                            opt.image
                                                          )}
                                                          alt="Selected"
                                                          style={{
                                                            width: "100px",
                                                            height: "100px",
                                                          }}
                                                        />
                                                      )}
                                                      {opt.isCorrect && (
                                                        <button>
                                                          <FaCheck />
                                                          Marked as Correct
                                                        </button>
                                                      )}
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}
                                  {/* ..................................Second End............................ */}
                                  {/* ..................................Third Start............................ */}

                                  {tab === "edit" && (
                                    <div className="EditQuestion">
                                      <div className="EditQuestionTop">
                                        <h1>Q1</h1>
                                        <button
                                          onClick={() => setTab("proceeded")}
                                        >
                                          Save
                                        </button>
                                      </div>
                                      <h6>
                                        Who was elected as the prime minister of
                                        Britain in November 2022 ?
                                      </h6>
                                      <div className="EditADDimage"></div>
                                      <div className="PrevOptions">
                                        {options.map((option, index) => (
                                          <div
                                            key={index}
                                            className="PrevOone"
                                            style={{
                                              backgroundColor:
                                                selectedOption === index
                                                  ? "#F5F5F5"
                                                  : "transparent",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <p>
                                              <input
                                                type="checkbox"
                                                onChange={() =>
                                                  handleOptionClick(index)
                                                }
                                                checked={
                                                  selectedOption === index
                                                }
                                              />{" "}
                                              <span>
                                                {String.fromCharCode(
                                                  97 + index
                                                )}
                                                .{" "}
                                              </span>
                                              {option.text}
                                            </p>
                                            {selectedOption === index && (
                                              <button>
                                                <FaCheck /> Marked as Correct
                                              </button>
                                            )}
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                                  {/* ..................................Third End............................ */}
                                  {/* ..................................Fourth Start............................ */}
                                  {tab === "proceeded" && (
                                    <div className="Mcqs-main">
                                      <div className="Mcqs-main-child-main">
                                        <div className="Mcqs-main-child1">
                                          <h2>Details</h2>
                                          <button
                                            onClick={() =>
                                              setTab("addNewQuestion")
                                            }
                                          >
                                            +Add Question{" "}
                                          </button>
                                        </div>
                                        <div className="Mcqs-main-child2">
                                          <h3>Questions</h3>
                                          <h4>Actions</h4>
                                        </div>

                                        {allQuestions?.map(
                                          (question, index) => {
                                            return (
                                              <div>
                                                <div className="Mcqs-main-child3">
                                                  <div className="Mcqs-main-child3-question">
                                                    <h4>
                                                      Q{index + 1}.
                                                      <br />
                                                      <br />{" "}
                                                      {question?.questionText}
                                                    </h4>
                                                  </div>
                                                  <div className="Mcqs-main-child4">
                                                    <MdModeEdit
                                                      onClick={() =>
                                                        setTab("edit")
                                                      }
                                                      size={18}
                                                    />
                                                    <MdOutlineDelete
                                                      onClick={() =>
                                                        handleDeleteQuestion(
                                                          question.id
                                                        )
                                                      }
                                                      size={18}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="Mcqs-main-child55">
                                                  <div className="Mcqs-main-child5">
                                                    {question?.Options?.map(
                                                      (option, index) => {
                                                        return (
                                                          <div
                                                            style={{
                                                              border:
                                                                option?.isCorrect &&
                                                                "1px solid #0cc818",
                                                              background:
                                                                option?.isCorrect &&
                                                                "#effff0",
                                                            }}
                                                            className="McqsDIV1"
                                                          >
                                                            <p>
                                                              {abc[index]}{" "}
                                                              {
                                                                option?.optionText
                                                              }
                                                            </p>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}

                                        <div className="Mcqs-main-child6">
                                          <button
                                            onClick={() => setTab("quizRule")}
                                          >
                                            Next
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {/* ..................................Fourth End............................ */}
                                  {/* ..................................Fifth Start............................ */}
                                  {tab === "quizRule" && (
                                    <div className="CreateQuiz">
                                      <h2>Create New Quiz</h2>
                                      <ProgressBar
                                        now={now}
                                        label={`${now}%`}
                                      />
                                      <div className="CreateCardMain">
                                        <div className="CreateCard">
                                          <h2>Quiz Rules</h2>
                                          <textarea
                                            placeholder="Start Typing the Rules"
                                            value={quizRules}
                                            onChange={(e) =>
                                              setQuizRules(e.target.value)
                                            }
                                          />
                                          <h5>
                                            {" "}
                                            {wordCount}/{maxWords}
                                          </h5>
                                          <div className="CreateTime">
                                            <input
                                              placeholder="Enter Time Per Question"
                                              className="CreateTimeINput1"
                                              value={timePerQuestion}
                                              onChange={(e) =>
                                                setTimePerQuestion(
                                                  e.target.value
                                                )
                                              }
                                            />
                                            <input
                                              placeholder="Seconds"
                                              className="CreateTimeINput2"
                                              value={seconds}
                                              onChange={(e) =>
                                                setSeconds(e.target.value)
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="CreateBtns">
                                          <p
                                            onClick={() => setTab("proceeded")}
                                          >
                                            <IoArrowBackOutline />
                                            Back
                                          </p>
                                          <button onClick={handleQuizProceed}>
                                            Proceed
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {/* ..................................Fifth End............................ */}

                                  {/* ..................................Sixth Start............................ */}
                                  {tab === "quizRuleHandler" && (
                                    <div className="CreateQuiz">
                                      <h2>Create New Quiz</h2>
                                      <ProgressBar
                                        now={noww}
                                        label={`${noww}%`}
                                      />
                                      <div className="CreateCardMain">
                                        <div className="CreateCard">
                                          <h2>Quiz Specifications</h2>
                                          <input
                                            placeholder="Number of Questions"
                                            className="cREATEsECONDiNPUT"
                                            onChange={(e) =>
                                              setNumberOfQuestions(
                                                e.target.value
                                              )
                                            }
                                            value={numberOfQuestions}
                                          />
                                          <div className="CreatePercentage">
                                            <p>Passing Percentage</p>
                                            <h6>{percentage}%</h6>
                                          </div>
                                          <Box sx={{ width: 300 }}>
                                            <Slider
                                              aria-label="Percentage"
                                              value={percentage}
                                              onChange={handleSliderChange}
                                              getAriaValueText={valuetext}
                                              valueLabelDisplay="auto"
                                              step={1}
                                              min={0}
                                              max={100}
                                            />
                                          </Box>
                                          <h4>Quiz Schedule</h4>
                                          <div className="CreteTIME">
                                            <input
                                              value={date}
                                              onChange={(e) =>
                                                setDate(e.target.value)
                                              }
                                              type="date"
                                            />
                                            <input
                                              value={time}
                                              onChange={(e) =>
                                                setTime(e.target.value)
                                              }
                                              type="time"
                                            />
                                          </div>
                                          <button
                                            onClick={handleSecondQuizProceed}
                                            className="CreteContinue"
                                          >
                                            Continue
                                          </button>
                                        </div>
                                        <div className="CreateBtns">
                                          <p onClick={() => setTab("quizRule")}>
                                            <IoArrowBackOutline />
                                            Back
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {/* ..................................Sixth End............................ */}
                                  {/* ..................................Seventh Start............................ */}
                                  {tab === "quizRuleCreator" && (
                                    <div className="CreateQuiz">
                                      <h2>Create New Quiz</h2>
                                      <ProgressBar
                                        now={nowww}
                                        label={`${nowww}%`}
                                      />
                                      <div className="CreateCardMain">
                                        <div className="CreateCard">
                                          <h2>Preview</h2>
                                          {/* <h1>
                                            <span>Course :</span> B.Tech Spcl.
                                            in Health Informatics
                                          </h1> */}
                                          <h1>
                                            <span>Subject :</span>{" "}
                                            {subject?.subjectName}
                                          </h1>
                                          <h1>
                                            Number of Questions :{" "}
                                            {numberOfQuestions}
                                          </h1>
                                          <div className="CreatePercentage">
                                            <p>Passing Percentage</p>
                                            <h6>{percentage}%</h6>
                                          </div>
                                          <Box sx={{ width: 300 }}>
                                            <Slider
                                              aria-label="Percentage"
                                              value={percentage}
                                              getAriaValueText={valuetext}
                                              valueLabelDisplay="auto"
                                              step={1}
                                              min={0}
                                              max={100}
                                            />
                                          </Box>
                                          <h4>Quiz Schedule</h4>
                                          <div className="CreteTIME">
                                            <input
                                              readOnly
                                              value={
                                                new Date(date)
                                                  .toISOString()
                                                  .split("T")[0]
                                              }
                                              type="date"
                                            />
                                            <input
                                              readOnly
                                              value={time}
                                              type="time"
                                            />
                                          </div>
                                        </div>
                                        <div className="CreateBtns">
                                          <p
                                            onClick={() =>
                                              setTab("quizRuleHandler")
                                            }
                                          >
                                            <IoArrowBackOutline />
                                            Back
                                          </p>
                                          <button onClick={handleSubmitQuiz}>
                                            Confirm
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {/* ..................................Seventh End............................ */}
                                  {/* ..................................Last Start............................ */}

                                  {tab === "quizSuccess" && (
                                    <div className="LastPage ">
                                      <ProgressBar
                                        now={nowwww}
                                        label={`${nowwww}%`}
                                      />

                                      <h1>Quiz Created Successfully</h1>
                                      <p>
                                        New Quiz within B.Tech spcl. in Health
                                        Informatics with <br /> subject Network
                                        Engineering Added Succesfully with the{" "}
                                      </p>
                                      <button
                                        onClick={() => {
                                          setTab("createTest");
                                          setLgShow(false);
                                        }}
                                      >
                                        Back to Dashboard
                                      </button>
                                    </div>
                                  )}

                                  {/* ..................................Last End............................ */}
                                </Modal.Body>
                              </Modal>
                            </div>
                          </div>
                        </Nav>
                      </Col>
                    </Row>
                  </div>
                </Tab.Container>
              </Tab.Pane>
              <Tab.Pane eventKey="tab5">
                {<NoticeBoard func={getNoticeboard} data={notices} />}
              </Tab.Pane>
              <Tab.Pane eventKey="tab6">
                <div className="ClassLectures_MAin">
                  <h3>Lectures</h3>
                  <UploadModal
                    setEditId={setEditId}
                    editId={editId}
                    GetClassData={GetClassData}
                    classLecture={classLecture}
                  />
                </div>
                <TeacherClassLectures
                  setEditId={setEditId}
                  editId={editId}
                  GetClassData={GetClassData}
                  classLecture={classLecture}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="tab7">
                <div className="TimeTableMain">
                  <table>
                    <thead>
                      <tr>
                        <th>Day</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Total Time</th>
                        <th>Venue</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Monday</td>
                        <td>12:30PM</td>
                        <td>03:30PM</td>
                        <td>2hours</td>
                        <td>Class 210</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};
export default NestedTabs;
