import React, { useState, useEffect } from "react";
import "./ForgotPassword.css";
import logo from "./images/logo.png";
import frame from "./images/Frame.png";
import emailImage from "./images/email.png";
import Done from "../images/Group.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RiLockFill } from "react-icons/ri";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import toast from "react-hot-toast";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../constants";
axios.defaults.baseURL = baseURL;

const ForgotPassword = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timer, setTimer] = useState(60);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleButtonClick = async () => {
    try {
      if (!validator.isEmail(email)) return toast.error("Email must be valid");

      setLoading(true);
      await axios.post(
        `/auth/sendotp`,
        {
          email,
          role,
        },
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );
      setLoading(false);

      setIsModalOpen(true);
      setTimer(60);
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const [disableButton, setDisableButton] = useState(false);
  useEffect(() => {
    if (timer <= 0) {
      setDisableButton(true);
    }
  }, [timer]);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [progressing, setProgressing] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const role = useSearchParams()[0].get("role");

  console.log(role, "My role");
  const handleOtpChange = (index, value) => {
    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < 3) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  useEffect(() => {
    if (isModalOpen && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(countdown);
    } else if (timer === 0) {
    }
  }, [isModalOpen, timer]);

  const [currentDiv, setCurrentDiv] = useState("first");

  const navigate = useNavigate();
  useEffect(() => {
    if (currentDiv === "third") {
      setTimeout(() => {
        setIsModalOpen(false);
        navigate(`/login?role=${role}`);
        toast.success("Password Updated Successfully");
      }, 2000);
    }
  }, [currentDiv]);

  const handleVerifyEmail = async () => {
    try {
      await axios.post(
        `/auth/verify-otp`,
        {
          email,
          role,
          otp: otp.join(""),
        },
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );
      setCurrentDiv("second");
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const handleSubmitNewPassword = async () => {
    try {
      if (password !== confirmPassword)
        return toast.error("New password and confirm password isn't matching");
      await axios.post(`/auth/updatePassword`, {
        role,
        email,
        newPassword: password,
      });
      setOtp(["", "", "", ""]);
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setCurrentDiv("third");
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  return (
    <div className="ForgotPassword">
      <div className="main">
        <img src={logo} alt="logo" />
        <div className="img">
          <img src={frame} alt="mainImage" />
          <form onSubmit={(e) => e.preventDefault()} action="">
            <img src={logo} alt="logo" />
            <h3>Forgot Password</h3>
            <p>
              Select which contact details should we use to reset your password
            </p>
            {/* <div className="input">
              <img src={msg} alt="number" />
              <div className="inputData">
                <label htmlFor="number">via SMS:</label>
                <input name="number" type="number" placeholder="+1" />
              </div>
            </div> */}
            <div className="input">
              <img src={emailImage} alt="email" />
              <div className="inputData">
                <label htmlFor="email">via Email:</label>
                <input
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Shuaibmycentrality@gmail.com"
                />
              </div>
            </div>
            <button
              disabled={loading}
              type="submit"
              onClick={() => handleButtonClick(false)}
            >
              {loading ? "Sending..." : "Continue"}
            </button>
          </form>
        </div>
      </div>

      {isModalOpen && (
        <div className="modalll otpModal">
          {currentDiv === "first" ? (
            <div className="modal-contentt">
              <p>Code has been sent to {email}</p>
              <div className="inputOTP">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                    onFocus={(e) => e.target.select()}
                  />
                ))}
              </div>
              <div className="timer">
                {timer <= 0 ? (
                  <p onClick={() => handleButtonClick(true)}>
                    {loading ? "Sending..." : "Resend Code"}
                  </p>
                ) : (
                  <p>
                    Resend code in <span>{timer}</span>s
                  </p>
                )}
              </div>
              <button
                onClick={() => {
                  handleVerifyEmail();
                }}
              >
                Verify
              </button>
            </div>
          ) : currentDiv === "second" ? (
            <div className="ForgotPASSS modal-contentt">
              <p>Create Your New Password</p>
              <div className="password-container12">
                <RiLockFill />
                <div className="password-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="New Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <span
                    className="toggle-password"
                    onClick={togglePasswordVisibility}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                  </span>
                </div>
              </div>
              <div className="password-container12">
                <RiLockFill />
                <div className="password-input">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirm-password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />

                  <span
                    className="toggle-password"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    <FontAwesomeIcon
                      icon={showConfirmPassword ? faEye : faEyeSlash}
                    />
                  </span>
                </div>
              </div>
              <div className="ForgotCheckBox">
                <input type="checkbox" />
                <label>Remember me</label>
              </div>
              <button
                onClick={() => {
                  handleSubmitNewPassword();
                }}
              >
                Continue
              </button>
            </div>
          ) : currentDiv === "third" ? (
            <div className="Congrats modal-contentt">
              <img src={Done} alt="#" />
              <h2>Congratulations!</h2>
              <p>
                Your account is ready to use. You will <br /> be redirected to
                the Login Screen in a <br /> few seconds..
              </p>

              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
