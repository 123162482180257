import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Dropdown, DropdownButton } from "react-bootstrap";
import "./SchoolPopulation.css";
import axios from "axios";
import { baseURL } from "../../../constants";
import { BsArrowsAngleExpand } from "react-icons/bs";
import { Link } from "react-router-dom";
axios.defaults.baseURL = baseURL;

const SchoolPopulation = () => {
  const [selectedInterval, setSelectedInterval] = useState("week");
  const [extraCurricularActivities, setExtraCurricularActivities] = useState([]);

  const handleIntervalChange = (interval) => {
    setSelectedInterval(interval);
  };

  const getExtraCurricularActivities = async () => {
    try {
      const { data } = await axios.get("/extracurriculaActivitesScore/getextracurriculaActivitesScore");
      setExtraCurricularActivities(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getExtraCurricularActivities();
  }, []);

  const [curricularData, setCurricularData] = useState([])

  const [actualData, setActualData] = useState([])

  const [curricularLabel, setCurricularLabel] = useState("Weekly")

  const handleGetCurricularActivities = async () => {
    try {
      const { data } = await axios.get(
        `/extracurricularActivitys/filterActivities?filter=${selectedInterval}`,
        {
          headers: {
            "Content-Type": "application/json",
            accessToken: localStorage.getItem("token"),
          },
        }
      );
      let modifiedData = Object.entries(data).flat()

      if (selectedInterval === "week") {
        setCurricularLabel("Weekly")
      } else if (selectedInterval === "year") {
        setCurricularLabel("Yearly")
      } else if (selectedInterval === "month") {
        setCurricularLabel("Monthly")
      }

      const newArr = []
      
      Object.entries(modifiedData[1].performance).forEach((arr) => {
         newArr.push(arr[1]?.toFixed(0))  
      })

      console.log(newArr, "this is new array")
      setActualData(newArr)

      setCurricularData(data);
      setExtraCurricularActivities(newArr)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetCurricularActivities();
  }, [selectedInterval]);
  
  const getChartData = () => {
  
     
        return {
          series: extraCurricularActivities,
          colors: [
            "#00AFEF",
            "rgba(22, 208, 59, 0.27)",
            "rgba(255, 154, 201, 0.7)",
            "rgba(46, 167, 110, 1)",
          ],
          options: {
            chart: {
              height: 300,
              type: "radialBar",
              width: "100%",
            },
            plotOptions: {
              radialBar: {
                dataLabels: {
                  name: {
                    fontSize: "22px",
                  },
                  value: {
                    fontSize: "16px",
                  },
                  total: {
                    show: true,
                    label: curricularLabel,
                    formatter: function (w) {
                      return "100%"; // Static value to show 100%
                    },
                  },
                },
              },
            },
            fill: {
              colors: [
                "#00AFEF",
                "rgba(22, 208, 59, 0.27)",
                "rgba(255, 154, 201, 0.7)",
                "rgba(46, 167, 110, 1)",
              ],
            },
            labels: ["Academics", "Sports", "Arts", "Creativity"],
          },
        };
      
     
    
  };

  const chartData = getChartData();


  return (
    <div className="SchoolPopulationMain">
      <div className="dropdownHedding">
        <h2>Extracurricular Activities Score <Link to="/FullChart"><BsArrowsAngleExpand /></Link></h2>
        <DropdownButton
          id="dropdown-basic-button"
          title={`${
            selectedInterval.charAt(0).toUpperCase() + selectedInterval.slice(1)
          }`}
        >
          <Dropdown.Item onClick={() => handleIntervalChange("week")}>
            Weekly
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleIntervalChange("month")}>
            Monthly
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleIntervalChange("year")}>
            Yearly
          </Dropdown.Item>
        </DropdownButton>
      </div>
      <div
        id="chart-container"
        style={{
          width: "100%",
          backgroundColor: "white",
          padding: "0px 10px",
          borderBottomLeftRadius: "12px",
          borderBottomRightRadius: "12px",
        }}
      >
        {chartData && (
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="radialBar"
            height={300}
            width="100%"
          />
        )}

        <div className="persentage">
          <h3>
            Academics
            <span style={{ color: chartData.colors[0] }}>
              ● {chartData.series[0]}%
            </span>
          </h3>
          <h3>
          Sports
            <span style={{ color: chartData.colors[1] }}>
              ● {chartData.series[1]}%
            </span>
          </h3>
          <h3>
            Arts
            <span style={{ color: chartData.colors[2] }}>
              ● {chartData.series[2]}%
            </span>
          </h3>
          <h3>
          Creative
            <span style={{ color: chartData.colors[3] }}>
              ● {chartData.series[3]}%
            </span>
          </h3>
        </div>
      </div>
    </div>
  );

};

export default SchoolPopulation;
