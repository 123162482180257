import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { CgLoadbarDoc } from "react-icons/cg";
import "./Sidebar.css";
import GridView from "@mui/icons-material/GridView";
import WebhookIcon from "@mui/icons-material/Webhook";
import Logo from "../../StudentComponents/images/Logo.png";
// import Wifi from "../images/filled.png";
// import Cartoon from "../images/cartoon.png";
import { IoCaretForwardOutline, IoCaretDownOutline } from "react-icons/io5";
import { getUserProfile } from "../../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { PiStarFour } from "react-icons/pi";
import SideImg from "../Images/sidebarimg.png";
import attendence from "../Images/attendence.png";
// import axios from "axios";
// import { baseURL } from "../../../constants";
const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({});

  const location = useLocation();

  const { user } = useSelector((state) => state.getUserProfile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = (name) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const generateMenuItem = (
    path,
    name,
    icon,
    hasDropdown = false,
    submenuItems = []
  ) => {
    const item = {
      path: path,
      name: name,
      icon: icon,
    };

    if (hasDropdown) {
      item.arrow = dropdownOpen[name] ? (
        <IoCaretDownOutline />
      ) : (
        <IoCaretForwardOutline />
      );
      item.subMenu = submenuItems;
      item.onClick = () => toggleDropdown(name);
    }

    return item;
  };
  const menuItem = [
    generateMenuItem("/parentportal", "Home", <GridView />),
    generateMenuItem(
      "/ParentInfoMain",
      "Student Info",
      <CgLoadbarDoc />,
      true,
      [
        { path: "/ParentInfoMain", name: "Profile" },
        // { path: "/ParentInfoMain", name: "Demographic" },
        { path: "/ChildPlan", name: "504 Plan" },
        { path: "/ChildSpecial", name: "Special Education" },
      ]
    ),
    generateMenuItem(
      "/ParentGrades",
      "My Student Grades",
      <CgLoadbarDoc />,
      true,
      [
        { path: "/ParentGrades", name: "Gradebook" },
        // { path: "/GradebookDetails", name: "Gradebook Details" },
        { path: "/ChildReportCard", name: "Report Card" },
        { path: "/ChildGrades", name: "Grades" },
        { path: "/ChildHistory", name: "Grades History" },
        { path: "/ChildStatus", name: "Graduation Status" },
        { path: "/ParentTranscripts", name: "Transcripts" },
      ]
    ),
    generateMenuItem("/ParentCalender", "Calendar", <WebhookIcon />),
    generateMenuItem("/AttendencePage", "Attendence", <CgLoadbarDoc />),
    generateMenuItem("/ParentAssigments", "Assignment", <CgLoadbarDoc />),

    generateMenuItem("/ChildMedical", "Medical", <CgLoadbarDoc />, true, [
      { path: "/ChildMedical", name: "Medical log" },
      { path: "/ChildMediHistory", name: "Medical History" },
    ]),
    generateMenuItem("/ChildGuidance", "Discipline", <CgLoadbarDoc />),
    generateMenuItem("/ParentTuitionInfo", "Tuition Info", <CgLoadbarDoc />),
    generateMenuItem(
      "/Extracurricularactivities",
      "Extracurricular Activities",
      <PiStarFour />
    ),
    generateMenuItem("/AccountSetting", "Setting", <CgLoadbarDoc />),
  ];

  const activeMenuItem = menuItem.find(
    (item) => window.location.pathname === item.path
  );
  // const sidebarImage =
  //   activeMenuItem && activeMenuItem.imgSrc ? activeMenuItem.imgSrc : SideImg;
  const [key, setKey] = useState("");

  useEffect(() => {
    setKey(window.location.search?.split("=")[1]);
  }, [window.location.search]);

  const [loc, setLoc] = useState(window.location.pathname);
  useEffect(() => {
    setLoc(window.location.pathname);
  }, [window.location.pathname]);
  return (
    <>
      <div className="dashboardewrapper">
        <div className={`sidebar ${isOpen ? "active" : ""}`}>
          <div className="top_section">
            <Link to="/parentportal" className="Logo">
              <img src={Logo} alt="#" className="Main-Logo-Dashboard" />
              <h4>MYCENTRALITY</h4>
            </Link>
            <hr />
          </div>
          <div className="menubar">
            <div
              style={{ display: "flex", flexDirection: "column" }}
              className="Fullwidth"
            >
              {menuItem.map((item, index) => (
                <div key={index} className="Full-Width">
                  <NavLink
                    to={item.path}
                    className="Sidebar-link"
                    activeClassName="active"
                    onClick={item.onClick}
                  >
                    <div className="icon">{item.icon}</div>
                    <div className="link_text ms-3  d-sm-inline">
                      {item.name}
                      {item.arrow}
                    </div>
                  </NavLink>
                  {item.subMenu &&
                    dropdownOpen[item.name] &&
                    item.subMenu.map((subItem, subIndex) => (
                      <NavLink
                        to={subItem.path}
                        key={subIndex}
                        className="Sidebar-sublink"
                      >
                        <div className="link_text ms-3  d-sm-inline done">
                          <li>
                            <Link to={subItem.path}>{subItem.name}</Link>
                          </li>
                        </div>
                      </NavLink>
                    ))}
                </div>
              ))}
            </div>
          </div>
          {loc === "/parentportal" && (
            <img
              src={SideImg}
              alt="Sidebar Illustration"
              className="CartoonImg"
            />
          )}
          {loc === "/AttendencePage" && (
            <img
              src={attendence}
              alt="Sidebar Illustration"
              className="CartoonImg"
            />
          )}
        </div>

        <div
          className={`navbar-toggle ${isOpen ? "open" : ""}`}
          onClick={toggleNavbar}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <main>{children}</main>
      </div>
    </>
  );
};

export default Sidebar;
