import React from "react";
import "./MediHistory.css";
import print from "../Medical/flat-color-icons_print.png";
import { useEffect, useState } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { IoMdArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import Dummy from "../../AdminComponents/images/dummy-user.png";
import Graph from "../../StudentComponents/MyProfile/Group 1000006080.png";
import Green from "../../StudentComponents/MyProfile/Group 1000006079.png";
import Red from "../../StudentComponents/MyProfile/Group 1000006078.png";
import Timer from "../../StudentComponents/MyProfile/Group 1000006077.png";
import axios from "axios";
import { FaImage, FaRegFilePdf } from "react-icons/fa";
import { baseURL } from "../../../constants";
function MediHistory() {
  const [student, setStudent] = useState(null);
  const [documents, setDocuments] = useState(null);
  const handleViewFile = (fl) => {
    const anchor = document.createElement("a");

    if (fl?.split(".")?.pop() === "pdf") {
      anchor.href = `${baseURL}/documents/${fl}`;
    } else {
      anchor.href = `${baseURL}/images/${fl}`;
    }

    document.body.appendChild(anchor);

    anchor.target = "_blank";

    anchor.click();

    document.body.removeChild(anchor);
  };
  const getStudent = async () => {
    try {
      const { data } = await axios.get(
        `/students/profile/${localStorage.getItem("teacherStudent")}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setStudent(data?.student);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStudent();
  }, []);

  const [medical, setMedical] = useState([]);
  const getMedical = async () => {
    try {
      const { data } = await axios.get(
        `/medicalHistories/getMedicalHistoryBystudentId/${localStorage.getItem(
          "teacherStudent"
        )}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setMedical(data.medicalHistory);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getMedical();
  }, []);
  function handlePrint() {
    window.print();
  }

  const [gpa, setGpa] = useState([]);

  const getGpa = async () => {
    try {
      const { data } = await axios.get(
        `/grades/calculateGPA/${localStorage.getItem("teacherStudent")}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setGpa(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getGpa();
  }, []);
  const [assingment, setAssingment] = useState([]);

  const getAssingment = async () => {
    try {
      const { data } = await axios.get(
        `/assignments/getAssignmentCountByStudent/${localStorage.getItem(
          "teacherStudent"
        )}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setAssingment(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAssingment();
  }, []);

  const [completed, setComplted] = useState([]);

  const getCompleted = async () => {
    try {
      const { data } = await axios.get(
        `/assignmentSubmissions/getSubmittedAssignmentsCountByStudent/${localStorage.getItem(
          "teacherStudent"
        )}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setComplted(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompleted();
  }, []);

  return (
    <div className="MediHistory-main">
      <div className="StuProfile-main">
        <div className="StuProfile-Top">
          <div className="StuProfile-Top1">
            <IoMdArrowBack style={{ cursor: "pointer" }} />
            <h1>Student Profile</h1>
          </div>
          <div className="StuProfile-Top2">
            <Link to="/TeacherChat" className="StuProfile-Top-btn1">
              Parent Chat
            </Link>
            <Link to="/TeacherChat" className="StuProfile-Top-btn2">
              Student Chat
            </Link>
            <Link to="/TeacherChat" className="StuProfile-Top-btn2">
              Admin Chat
            </Link>
          </div>
        </div>
        <div className="Myprofile-main-1">
          <div className="Myprofile-main-child-1">
            <div className="profile">
              <div className="profile-child-1">
                <img
                  onError={({ currentTarget }) => {
                    currentTarget.src = Dummy;
                  }}
                  src={`${baseURL}/images/${student?.profilePicture}`}
                />
              </div>
              <div className="profile-child-2">
                <div className="profile-child-2-1">
                  <h2>{student?.firstName + " " + student?.lastName}</h2>
                </div>
                <div className="profile-child-2-2">
                  <p>{student?.program}</p>
                  <button>
                    <span>●</span>
                    {student?.online ? "Online" : "Offline"}
                  </button>
                </div>
                <div className="profile-child-2-3">
                  <p>{student?.loggedInSince?.split("T")[0]}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="Myprofile-main-child-2">
            <div className="Myprofile-main-child-2-1">
              <img src={Timer} alt="#" />
              <h3>{student?.createdAt?.split("T")[0]}</h3>
            </div>
            <div className="Myprofile-main-child-2-2">
              <div className="div-1">
                <img src={Red} alt="#" />
                <h3>
                  Assignments <span>{assingment?.assignmentCount}</span>
                </h3>
              </div>
              <div className="div-2">
                <img src={Green} alt="#" />
                <h3>
                  Completed <span>{completed?.submittedAssignmentsCount}</span>
                </h3>
              </div>
            </div>
          </div>
          <div className="Myprofile-main-child-3">
            <div className="Myprofile-main-child-3-card-1">
              <img src={Graph} alt="#" />
            </div>
            <div className="Myprofile-main-child-3-card-2">
              <h3>GPA</h3>
              <h1>{gpa?.gpa}</h1>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="MediHistory-Top1">
        <h3>
          Health Problems:<span> Allergy: Peanut</span>{" "}
        </h3>
      </div> */}
      <div className="MediHistory-Second">
        <h4>Medical History</h4>
      </div>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Code</th>
            <th>Grade</th>
            <th>Age</th>
            <th>Start Time</th>
            <th>End Time</th>
          </tr>
        </thead>
        <tbody>
          {medical &&
            medical.map((medi, index) => (
              <tr key={index}>
                <td>{medi?.date?.split("T")[0]}</td>
                <td>{medi?.code}</td>
                <td>{medi?.grade}</td>
                <td>{medi?.age}</td>
                <td>{medi?.starttime}</td>
                <td>{medi?.endtime}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="PrintImg">
        <button onClick={handlePrint}>
          <img src={print} alt="#" />
          Print Medical Profile
        </button>
      </div>
      <h2 className="H2Mediacal">MEDICAL DOCOMENT</h2>
      {documents &&
        documents?.map((fl) => {
          return (
            <div className="MHistory-Pdf">
              <div className="MHistory-pdfchild">
                {fl?.split(".")?.pop() === "pdf" ? (
                  <FaRegFilePdf />
                ) : (
                  <FaImage />
                )}

                <p>
                  {fl?.slice(0, 15)}... {fl?.split(".")?.pop()}
                </p>
              </div>
              <div className="MediHistory-btns">
                <button>Download</button>
                <button onClick={() => handleViewFile(fl)}>
                  <IoEyeOutline />
                  view
                </button>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default MediHistory;
