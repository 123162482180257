import React, { useEffect, useState } from "react";
import "./Transcripts.css";
import { RiDeleteBin6Line, RiFileExcel2Line } from "react-icons/ri";
import axios from "axios";
import { baseURL } from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../redux/actions/userAction";
import { FiEdit } from "react-icons/fi";
import Modal from "react-bootstrap/Modal";
import { IoDownloadOutline } from "react-icons/io5";
import { MdOutlineCancel } from "react-icons/md";
axios.defaults.baseURL = baseURL;
function Transcripts() {
  const [transcript, setTranscript] = useState(null);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const [schoolId, setSchoolId] = useState(null);
  const getSchoolByStudentId = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/ParentBySchoolId/getschoolBystudentId/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setSchoolId(data.school.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchoolByStudentId();
  }, [user]);

  const getTranscripts = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/students/transcript/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        setTranscript(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [results, setResults] = useState(null);

  const getStudentResults = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/results/getStudentResults/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setResults(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTranscripts();
    getStudentResults();
  }, [user]);

  const [school, setSchool] = useState("");

  const getSchool = async () => {
    try {
      if (schoolId) {
        const { data } = await axios.get(`/schools/getschoolById/${schoolId}`, {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        });
        setSchool(data?.school);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchool();
  }, [schoolId]);
  const [lgShow, setLgShow] = useState(false);
  return (
    <div className="Transcripts-main12">
      <div className="Transcript-MainTop">
        <div className="Transcript-Top">
          <h3>Transcript</h3>
        </div>
        <div className="transcriptTable">
          <table>
            <thead>
              <tr>
                <th>Graduation Track</th>
                <th>Graduated</th>
                <th>Grad Stat</th>
                <th>Expected Grad</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Default</td>
                <td>Comment</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="transcriptGpa">
        <div className="Transcript-Top">
          <h3>GPAs</h3>
        </div>
        <h4>
          This process will recompute the student’s built-in 9pa and any
          district defined below.
        </h4>
        <div className="transcriptGpa-Body">
          <div>
            <h5>(W)Acad GPA</h5>
            <p>3,9691 3,773</p>
          </div>
          <div>
            <h5>(W)Total GPA</h5>
            <p>3,9691 3,773</p>
          </div>
          <div>
            <h5>(W)10-12 GAP</h5>
            <p>3,9691 3,773</p>
          </div>
          <div>
            <h5>Credit Att/Compl</h5>
            <p>3,9691 3,773</p>
          </div>
          <div>
            <h5>Class Rank Grad</h5>
            <p>3,9691 3,773</p>
          </div>
        </div>
        <div className="TranscriptGpaTable">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>GPA</th>
                <th>Class Rank/Size</th>
                <th>GPA Last Updated</th>
                <th>Class Rank Last Updated</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
      <div className="CourseHistory">
        <div className="Transcript-Top">
          <h3>Course History</h3>
        </div>
        <div className="CourseInput">
          <input type="checkbox" />
          <label>Sort By Subject</label>
          <input type="checkbox" />
          <label>Sort By Date Descending</label>
        </div>
        <div className="Transcript-Top1">
          <h3>2019-2020 Bald Intermediate School </h3>
          <h3>Grade 8 | Fall</h3>
        </div>
        <div className="TranscriptSchool">
          <table>
            <thead>
              <tr>
                <th>Schl</th>
                <th>Year</th>
                <th>Tm</th>
                <th>Grd</th>
                <th>Crs ID</th>
                <th>Std Course Title</th>
                <th>RT</th>
                <th>CP</th>
                <th>N/H</th>
                <th>Mark</th>
                <th>Atmp</th>
                <th>Comp</th>
                <th>SCT</th>
                <th className="ThLast">More</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>993</td>
                <td>19-20</td>
                <td>1</td>
                <td>8</td>
                <td>100386</td>
                <td>Algebra G</td>
                <td></td>
                <td></td>
                <td></td>
                <td>B</td>
                <td>0.00</td>
                <td>0.00</td>
                <td></td>
                <td>Less</td>
                <td>
                  <FiEdit onClick={() => setLgShow(true)} />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="TranscriptH6">
            <h6>
              Enrolled: <span>89</span>
            </h6>
            <h6>
              Absences: <span>89</span>
            </h6>
            <h6>
              Present: <span>89</span>
            </h6>
          </div>
          <div className="TranscriptH6">
            <h6>
              Excused Abs: <span>89</span>
            </h6>
            <h6>
              Unexcused Abs: <span>89</span>
            </h6>
            <h6>
              Suspended: <span>89</span>
            </h6>
            <h6>
              Tardy: <span>89</span>
            </h6>
          </div>
          <div className="TranscriptH6">
            <h6>
              Citizenship: <span>89</span>
            </h6>
            <h6>
              Section School: <span>89</span>
            </h6>
            <h6>
              Section: <span>89</span>
            </h6>
            <h6>
              Staff: <span>89</span>
            </h6>
            <h6>
              Date of Completion: <span>89</span>
            </h6>
          </div>
          <div className="TranscriptH6">
            <h6>
              Characteristics: <span>89</span>
            </h6>
          </div>
        </div>
        <div className="Transcript-Top1">
          <h3>2019-2020 Bald Intermediate School </h3>
          <h3>Grade 9 | Spring</h3>
        </div>
        <div className="TranscriptSchool">
          <table>
            <thead>
              <tr>
                <th>Schl</th>
                <th>Year</th>
                <th>Tm</th>
                <th>Grd</th>
                <th>Crs ID</th>
                <th>Std Course Title</th>
                <th>RT</th>
                <th>CP</th>
                <th>N/H</th>
                <th>Mark</th>
                <th>Atmp</th>
                <th>Comp</th>
                <th>SCT</th>
                <th className="ThLast">More</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>993</td>
                <td>19-20</td>
                <td>1</td>
                <td>8</td>
                <td>100386</td>
                <td>Algebra G</td>
                <td></td>
                <td></td>
                <td></td>
                <td>B</td>
                <td>0.00</td>
                <td>0.00</td>
                <td></td>
                <td>Less</td>
                <td>
                  <FiEdit onClick={() => setLgShow(true)} />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="TranscriptH6">
            <h6>
              Enrolled: <span>89</span>
            </h6>
            <h6>
              Absences: <span>89</span>
            </h6>
            <h6>
              Present: <span>89</span>
            </h6>
          </div>
          <div className="TranscriptH6">
            <h6>
              Excused Abs: <span>89</span>
            </h6>
            <h6>
              Unexcused Abs: <span>89</span>
            </h6>
            <h6>
              Suspended: <span>89</span>
            </h6>
            <h6>
              Tardy: <span>89</span>
            </h6>
          </div>
          <div className="TranscriptH6">
            <h6>
              Citizenship: <span>89</span>
            </h6>
            <h6>
              Section School: <span>89</span>
            </h6>
            <h6>
              Section: <span>89</span>
            </h6>
            <h6>
              Staff: <span>89</span>
            </h6>
            <h6>
              Date of Completion: <span>89</span>
            </h6>
          </div>
          <div className="TranscriptH6">
            <h6>
              Characteristics: <span>89</span>
            </h6>
          </div>
        </div>
        <div className="Transcript-Top1">
          <h3>2023-2024 Bald Intermediate School </h3>
          <h3>Grade 10 | Summer</h3>
        </div>
        <div className="TranscriptSchool">
          <table>
            <thead>
              <tr>
                <th>Schl</th>
                <th>Year</th>
                <th>Tm</th>
                <th>Grd</th>
                <th>Crs ID</th>
                <th>Std Course Title</th>
                <th>RT</th>
                <th>CP</th>
                <th>N/H</th>
                <th>Mark</th>
                <th>Atmp</th>
                <th>Comp</th>
                <th>SCT</th>
                <th className="ThLast">More</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>993</td>
                <td>19-20</td>
                <td>1</td>
                <td>8</td>
                <td>100386</td>
                <td>Algebra G</td>
                <td></td>
                <td></td>
                <td></td>
                <td>B</td>
                <td>0.00</td>
                <td>0.00</td>
                <td></td>
                <td>Less</td>
                <td>
                  <FiEdit onClick={() => setLgShow(true)} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="Transcript-Top1">
          <h3>2023-2024 Bald Intermediate School </h3>
          <h3>Grade 10 | Spring</h3>
        </div>
        <div className="TranscriptSchool">
          <table>
            <thead>
              <tr>
                <th>Schl</th>
                <th>Year</th>
                <th>Tm</th>
                <th>Grd</th>
                <th>Crs ID</th>
                <th>Std Course Title</th>
                <th>RT</th>
                <th>CP</th>
                <th>N/H</th>
                <th>Mark</th>
                <th>Atmp</th>
                <th>Comp</th>
                <th>SCT</th>
                <th className="ThLast">More</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>993</td>
                <td>19-20</td>
                <td>1</td>
                <td>8</td>
                <td>100386</td>
                <td>Algebra G</td>
                <td></td>
                <td></td>
                <td></td>
                <td>B</td>
                <td>0.00</td>
                <td>0.00</td>
                <td></td>
                <td>Less</td>
                <td>
                  <FiEdit onClick={() => setLgShow(true)} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="btnNs">
        <button>Share</button>
        <button>
          <span>
            <RiFileExcel2Line />
          </span>
          Export Data
        </button>
        <button className="Print">Print Transcript</button>
      </div>
      <Modal
        size="xl"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
        className="TransMainModal"
      >
        <Modal.Body>
          <div className="TransModal">
            <p>2023-2024 Bald Intermediate School </p>
            <div className="TrasnTopDelBtns">
              <button>
                {" "}
                <IoDownloadOutline />
                Save{" "}
              </button>
              <button>
                <MdOutlineCancel />
                Cancel
              </button>
              <button>
                <RiDeleteBin6Line />
                Delete{" "}
              </button>
            </div>
          </div>
          <div className="TransBodyCript">
            <table>
              <thead>
                <tr>
                  <th>Schl</th>
                  <th>Year</th>
                  <th>Tm</th>
                  <th>Grd</th>
                  <th>Crs ID</th>
                  <th>Std Course Title</th>
                  <th>RT</th>
                  <th>CP</th>
                  <th>N/H</th>
                  <th>Mark</th>
                  <th>Atmp</th>
                  <th>Comp</th>
                  <th>SCT</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input />
                  </td>
                  <td>
                    <select>
                      <option value=""></option>
                      <option>2024-2025</option>
                      <option>2023-2024</option>
                      <option>2022-2023</option>
                      <option>2021-2022</option>
                      <option>2020-2021</option>
                      <option>2019-2020</option>
                      <option>2018-2019</option>
                      <option>2017-2018</option>
                      <option>2016-2017</option>
                      <option>2015-2016</option>
                      <option>2014-2015</option>
                      <option>2013-2014</option>
                      <option>2012-2013</option>
                      <option>2011-2012</option>
                      <option>2010-2011</option>
                      <option>2009-2010</option>
                      <option>2008-2009</option>
                      <option>2007-2008</option>
                      <option>2006-2007</option>
                      <option>2005-2006</option>
                      <option>2004-2005</option>
                    </select>
                  </td>
                  <td>
                    <select className="TrasnSelect1">
                      <option value=""></option>
                      <option>0: Summer</option>
                      <option>1: Fall</option>
                      <option>2: Spring</option>
                      <option>3: Winter</option>
                    </select>
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <select className="TrasnSelect">
                      <option value=""></option>
                      <option>1: Cournt Everywhere</option>
                      <option>
                        2: Credit Att: | No Credit Att: No | GPA: No
                      </option>
                      <option>
                        3: Credit Att: | No Credit Att: No | GPA: Yes
                      </option>
                      <option>
                        4: Credit Att: | No Credit Att: No | GPA: No
                      </option>
                      <option>
                        5: Credit Att: | No Credit Att: No | GPA: Yes
                      </option>
                      <option>
                        6: Credit Att: | No Credit Att: No | GPA: No
                      </option>
                      <option>
                        A: Credit Att: | No Credit Att: No | GPA: Yes
                      </option>
                      <option>
                        D: Credit Att: | No Credit Att: No | GPA: No
                      </option>
                      <option>
                        B: Credit Att: | No Credit Att: No | GPA: No
                      </option>
                      <option>
                        E: Credit Att: | No Credit Att: No | GPA: Yes
                      </option>
                      <option>
                        Y: Credit Att: | No Credit Att: No | GPA: No
                      </option>
                    </select>
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="TransExcused">
              <div className="TransExcusedChild">
                <label>Enrolled</label>
                <input placeholder="92" type="number" />
              </div>
              <div className="TransExcusedChild">
                <label>Absences</label>
                <input placeholder="0" type="number" />
              </div>
              <div className="TransExcusedChild">
                <label>Present</label>
                <input placeholder="0" type="number" />
              </div>
            </div>
            <div className="TransExcused1">
              <div className="TransExcusedChild">
                <label>Excused Abs</label>
                <input placeholder="92" type="number" />
              </div>
              <div className="TransExcusedChild">
                <label>Un Excused Abs</label>
                <input placeholder="0" type="number" />
              </div>
              <div className="TransExcusedChild">
                <label>Suspended</label>
                <input placeholder="0" type="number" />
              </div>
              <div className="TransExcusedChild">
                <label>Tardy</label>
                <input placeholder="0" type="number" />
              </div>
            </div>
            <div className="TransExcused2">
              <div className="TransExcusedChild">
                <label>Citizenship</label>
                <input placeholder="92" type="number" />
              </div>
              <div className="TransExcusedChild">
                <label>Section School</label>
                <input placeholder="0" type="number" />
              </div>
              <div className="TransExcusedChild">
                <label>Section </label>
                <input placeholder="0" type="number" />
              </div>
              <div className="TransExcusedChild">
                <label>Staff ID </label>
                <input placeholder="0" type="number" />
              </div>
              <div className="TransExcusedChild">
                <label>Date of Completion </label>
                <input type="date" className="trasndate" />
              </div>
            </div>

            <div className="TransExcused">
              <div className="TransExcusedChild123">
                <label>Subject Area Override</label>
                <select>
                  <option>A: English</option>
                  <option>B: Phyiscal Education</option>
                  <option>C: Social Science</option>
                  <option>D: Mathematics</option>
                  <option>E: Visual/Perform Arts</option>
                  <option>F: Career Tech Arts</option>
                  <option>G: Science: Life</option>
                  <option>H: Science: Phyiscal</option>
                  <option>I: Health & Care Education</option>
                  <option>J: Computer Science</option>
                  <option>K: World Language</option>
                </select>
              </div>
            </div>

            <div className="TransExcused">
              <div className="TransExcusedChild123">
                <label>Characteristics</label>
                <input />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Transcripts;
