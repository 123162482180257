import React, { useEffect, useState } from "react";
import "./AddFinancal.css";
import { MdKeyboardArrowRight } from "react-icons/md";
import { TbPencilMinus } from "react-icons/tb";
import { LiaAwardSolid } from "react-icons/lia";
import { TbTargetArrow } from "react-icons/tb";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Mapcarddata from "./Allcardmapdata/Mapcarddata";
import Vendorsdata from "./Allcardmapdata/Vendorsdata";
import ADDBarChart from "../Adminstrator/AdminstratorDashbord/BarChart/ADDBarChart";
import CardsCaro from "./ADDCaroCards/CardsCaro";
import DollarMeter from "./ADDDollarMeter/DollarMeter";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../constants";
axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;

const AddFinancal = () => {
  const [loading, setLoading] = useState(true);
  const [financialOverview, setFinancialOverview] = useState(null);
  const getFinancialOverview = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        "/financials/getFinancialOverviewAnalytics",
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setLoading(false);
      setFinancialOverview(data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const [recentTrasactions, setRecentTransactions] = React.useState(null);

  const getRecentTransactions = async () => {
    try {
      const { data } = await axios.get(
        "/recenttransactions/getrecenttransaction",
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setRecentTransactions(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFinancialOverview();
    getRecentTransactions();
  }, []);

  return (
    <div className="AddFinancal-main">
      <div className="ADDcards-main">
        <div className="Total-balance">
          <h2>Total Balance</h2>
          <div className="Total-balance-card">
            <div className="Total-balance-card-child-1">
              <div className="title-icons">
                <h1>$240,399 </h1>
              </div>
              <p>Total Balance</p>
            </div>
            <hr />
            <div className="Total-balance-card-child-2">
              <CardsCaro />
            </div>
          </div>
        </div>

        <div className="Total-balance">
          <h2>Goals</h2>
          <div className="Goals-card">
            <div className="Goals-card-child-1">
              <div className="title-icons">
                <h1>$20,000 </h1>
                <div className="Tb-icons">
                  <TbPencilMinus />
                </div>
              </div>
              <p>May, 2023</p>
            </div>
            <hr />
            <div className="Goals-card-child-2">
              <div className="Goals-card-child-2-1">
                <div className="bage">
                  <div>
                    <LiaAwardSolid />
                  </div>
                  <div>
                    <p>Target Achieved</p>
                    <h3>$12,500</h3>
                  </div>
                </div>
                <div className="bage">
                  <div>
                    <TbTargetArrow />
                  </div>
                  <div>
                    <p>This month Target</p>
                    <h3>$20,000</h3>
                  </div>
                </div>
              </div>
              <div className="Goals-card-child-2-2">
                <DollarMeter />
              </div>
            </div>
          </div>
        </div>

        <div className="Total-balance">
          <div className="title-all">
            <h2>Upcoming Bill</h2>
            <div className="all-buttons-svg">
              <button className="svg-button">
                view all
                <MdKeyboardArrowRight />
              </button>
            </div>
          </div>
          <div className="Upcomine-card">
            <div className="Upcomine-card-child-1">
              <div className="days">
                <p>MAY</p>
                <h4>17</h4>
              </div>
              <div className="taxt">
                <h3>High School Bill</h3>
                <p>Last Charge - 14 May, 2024</p>
              </div>
              <div className="amounts">
                <h3>$150</h3>
              </div>
            </div>
            <hr className="line-hr" />
            <div className="Upcomine-card-child-2">
              <div className="days-1">
                <p>MAY</p>
                <h4>17</h4>
              </div>
              <div className="taxt-2">
                <h3>High School Bill</h3>
                <p>Last Charge - 14 May, 2024</p>
              </div>
              <div className="amounts-3">
                <h3>$150</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ADDFIANAN">
        {/* {financialOverview && <ADDBarChart data={financialOverview} />} */}
        <ADDBarChart />
      </div>
      <div className="Recent-transaction-main">
        <div className="recent-transection">
          <div className="recent-transection-title">
            <h3>Recent Transaction</h3>
            <div className="seaAll-btn">
              <Link to="/TransationTable">View all</Link>
              <MdKeyboardArrowRight />
            </div>
          </div>
          <div className="recent-transection-card">
            <Tabs
              defaultActiveKey="All"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="All" title="All">
                <div>
                  {recentTrasactions &&
                    recentTrasactions.map((card) => (
                      <>
                        <div key={card.id} className="All-card-map">
                          <div className="card-map-1">
                            <div className="div-icon">
                              <img
                                src={"https://source.unsplash.com/640x640?tech"}
                                alt={"Item Image"}
                              />
                            </div>
                          </div>
                          <div className="card-map-main">
                            <div className="card-map-2">
                              <h3>{card.title}</h3>
                              <p>{card.category}</p>
                            </div>
                            <div className="card-map-3">
                              <h3>{card.price}</h3>
                              <p>{card.date}</p>
                            </div>
                          </div>
                        </div>
                        <hr className="map-hr" />
                      </>
                    ))}
                </div>
              </Tab>
              <Tab eventKey="Revenue" title="Revenue">
                This tab is coming soon.
              </Tab>
              <Tab eventKey="Expenses" title="Expenses">
                This tab is coming soon.
              </Tab>
            </Tabs>
          </div>
        </div>
        <div className="Vendors">
          <div className="Vendors-title">
            <h3>Vendors</h3>
          </div>
          <div className="Vendors-card-main">
            {Vendorsdata.map((vendor) => (
              <div key={vendor.id} className="Vendors-card-main-child">
                <div className="Vendors-card">
                  <div className="Vendors-card-child-1">
                    <span>{vendor.Schoolicon}</span>
                  </div>
                  <div className="Vendors-card-child-2">
                    <h2>
                      {vendor.amount} <span>{vendor.arrowIcon}</span>
                    </h2>
                    <p>
                      {vendor.percentage} <span>{vendor.upwardArrowIcon}</span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFinancal;
