import React, { useEffect, useState } from "react";
import "./Setting.css";
import "react-phone-input-2/lib/style.css";
import { LuUpload } from "react-icons/lu";
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";
import axios from "axios";
import { baseURL } from "../../../constants";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { FaPhoneAlt } from "react-icons/fa";
import Dummy from "../../AdminComponents/images/dummy-user.png";
import PhoneInput from "react-phone-number-input";

import { getUserProfile } from "../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;
const Setting = () => {
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleOnChange = (value) => {
    setPhoneNumber(value);
  };

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 1024 * 1024) {
        alert("Image size should be less than 1MB.");
        return;
      }
      setSelectedFile(file);
      const objectUrl = URL.createObjectURL(file);
      setImageUrl(objectUrl);
    }
  };
  const [student, setStudent] = useState(null);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [title, setTitle] = useState("");
  const [biography, setBioGraphy] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (student) {
      setFirstName(student.firstName);
      setLastName(student.lastName);
      setEmail(student.email);
      setPhoneNumber(student.phoneNumber);
      setTitle(student.title);
      setBioGraphy(student.biography);
    }
  }, [student]);
  const getStudent = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/students/profile/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setStudent(data?.student);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStudent();
  }, [user]);

  const updateProfile = async () => {
    try {
      const formData = new FormData();

      selectedFile && formData.append("profilePicture", selectedFile);
      firstName && formData.append("firstName", firstName);
      lastName && formData.append("lastName", lastName);
      title && formData.append("title", title);
      email && formData.append("email", email);
      biography && formData.append("biography", biography);
      phoneNumber && formData.append("phoneNumber", phoneNumber);

      if (user) {
        let { data } = await axios.put(
          `/students/updateProfile/${user.dataValues.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        getStudent();
        toast.success("Profile Updated Successfully");
      }
    } catch (err) {
      if (err?.response?.data?.details) {
        toast.error(
          err?.response?.data?.details[0]?.detail ||
            err?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
  };
  const [inputText, setInputText] = useState("");

  const countWords = (text) => {
    const words = text.trim().split(/\s+/);
    const wordCount = words.length;
    if (wordCount <= 50) {
      return `${wordCount}/50`;
    } else {
      const truncatedText = words.slice(0, 50).join(" ");
      setInputText(truncatedText);
      return "50/50 (Max reached)";
    }
  };

  const [wordCountDisplay, setWordCountDisplay] = useState("0/50");

  const updatePassword = async () => {
    if (newPassword !== confirmPassword)
      return toast.error("New password and confirm password is not matching");

    try {
      if (user) {
        let { data } = await axios.put(
          `/students/updatePassword/${user.dataValues.id}`,
          {
            currentPassword,
            newPassword,
          },
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setPasswordChanged(true);
      }
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.details) {
        toast.error(
          err?.response?.data?.details[0]?.detail ||
            err?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(err?.response?.data?.message);
      }
      setPasswordChanged(false);
    }
  };

  const togglePasswordVisibility = (field) => {
    switch (field) {
      case "current":
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  const handleSaveChanges = () => {
    if (!currentPassword || !newPassword) {
      alert("Please fill in all required fields");
      return;
    }

    setPasswordsMatch(true);

    if (newPassword !== confirmPassword) {
      setPasswordsMatch(false);
      return;
    }

    setPasswordChanged(true);
    setTimeout(() => {
      setPasswordChanged(false);
    }, 5000);
  };

  const [makeUpRequest, setMakeUpRequest] = useState(false);
  const [message, setMessage] = useState(false);
  const [profileVisit, setProfileVisit] = useState(false);
  const [assignmentUpdate, setAssignmentUpdate] = useState(false);
  const [lectureUpload, setLectureUpload] = useState(false);
  const [noticeUpdate, setNoticeUpdate] = useState(false);
  const [eventUpdate, setEventUpdate] = useState(false);
  const [testUpdate, setTestUpdate] = useState(false);
  const [passwordChange, setPasswordChange] = useState(false);

  const handleSaveNotificationChanges = async () => {
    try {
      if (user) {
        await axios.post(
          `/settings/addOrUpdateStudentNotificationSettings`,
          {
            studentId: user.dataValues.id,
            profileVisit: profileVisit,
            assignmentUpdate: assignmentUpdate,
            leactureUpload: lectureUpload,
            noticeUpdate: noticeUpdate,
            eventUpdate: eventUpdate,
            testUpdate: testUpdate,
            passwordChange: passwordChange,
            message: message,
            makeUpRequest: makeUpRequest,
          },
          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Changes Updated Successfully");
      }
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <div className="Setting-main12">
      <div className="setting-child-1">
        <div className="setting-account-setting">
          <h6>Account Settings</h6>
          <div className="account-setting-input-name">
            <label htmlFor="Full name">Full name</label>
            <div className="first-name">
              <input
                type="text"
                id="text"
                placeholder="First name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <input
                type="text"
                id="text"
                placeholder="Last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div className="account-setting-input-username">
            <label htmlFor="Full name">Email</label>
            <div>
              <input
                type="text"
                id="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="account-setting-input-number">
            <label htmlFor="Full name">Phone Number</label>
            <div className="account-setting-input-number2">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "7px",
                }}
              >
                <FaPhoneAlt />
                <PhoneInput
                  placeholder="Enter contact number"
                  value={phoneNumber}
                  onChange={(value) => setPhoneNumber(value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="setting-account-upload">
          <div className="upload-profile">
            {!imageUrl ? (
              <img
                src={`${baseURL}/images/${student?.profilePicture}`}
                alt="Default"
                style={{
                  width: "200px",
                  height: "200px",
                  objectFit: "contain",
                }}
                onError={({ currentTarget }) => {
                  currentTarget.src = Dummy;
                }}
              />
            ) : (
              <img
                src={imageUrl}
                alt="Uploaded"
                style={{
                  width: "200px",
                  height: "200px",
                  objectFit: "contain",
                }}
                onError={({ currentTarget }) => {
                  currentTarget.src = Dummy;
                }}
              />
            )}
            <div className="chose-file-btn">
              <LuUpload className="Aiout-icon" />
              <div className="mno">
                <input
                  type="file"
                  id="source-file"
                  onChange={handleFileInputChange}
                />
                <label className="source-file" htmlFor="source-file">
                  Upload Photo
                </label>
              </div>
            </div>
            <p className="image-size">
              Image size should be under 1MB and image ratio needs to be 1:1
            </p>
          </div>
        </div>
      </div>

      <div className=" setting-child-2">
        <label htmlFor="Tittle">Title</label>
        <div className="account-setting-input-title">
          <input
            type="text"
            value={title}
            name="text"
            id="text"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            placeholder="Your tittle, proffesion or small biography"
          />
          <p>{wordCountDisplay}</p>
        </div>
        <div className="account-setting-biography">
          <label htmlFor="Biography">Biography</label>
          <textarea
            name="text"
            id="tex"
            value={biography}
            onChange={(e) => {
              setBioGraphy(e.target.value);
            }}
            placeholder="Your tittle, proffesion or small biography"
          ></textarea>
        </div>
      </div>
      <div className="account-setting-btn">
        <button
          onClick={(e) => {
            e.preventDefault();
            updateProfile();
          }}
        >
          Save Changes
        </button>
      </div>

      <div className="setting-child-3">
        <div className="Account-setting-notification">
          <h5>Notifications</h5>
          <div className="Notifications-checkboxes">
            <li>
              <input
                checked={makeUpRequest}
                onChange={() => setMakeUpRequest(!makeUpRequest)}
                type="checkbox"
                id="checkbox"
              />
            </li>
            <p>I want to know Student mack-up time request.</p>
          </div>
          <div className="Notifications-checkboxes">
            <li>
              <input
                checked={message}
                onChange={() => setMessage(!message)}
                type="checkbox"
                id="checkbox"
              />
            </li>
            <p>I want to know when any message me.</p>
          </div>
          <div className="Notifications-checkboxes">
            <li>
              <input
                checked={profileVisit}
                onChange={() => setProfileVisit(!profileVisit)}
                type="checkbox"
                id="checkbox"
              />
            </li>
            <p>I want to know about profile visit.</p>
          </div>
          <div className="Notifications-checkboxes">
            <li>
              <input
                checked={assignmentUpdate}
                onChange={() => setAssignmentUpdate(!assignmentUpdate)}
                type="checkbox"
                id="checkbox"
              />
            </li>
            <p>I want to know about assignment updates.</p>
          </div>
          <div className="Notifications-checkboxes">
            <li>
              <input
                checked={lectureUpload}
                onChange={() => setLectureUpload(!lectureUpload)}
                type="checkbox"
                id="checkbox"
              />
            </li>
            <p>I want to know about lecture uploads.</p>
          </div>
          <div className="Notifications-checkboxes">
            <li>
              <input
                checked={noticeUpdate}
                onChange={() => setNoticeUpdate(!noticeUpdate)}
                type="checkbox"
                id="checkbox"
              />
            </li>
            <p>I want to know about notice updates.</p>
          </div>
          <div className="Notifications-checkboxes">
            <li>
              <input
                checked={eventUpdate}
                onChange={() => setEventUpdate(!eventUpdate)}
                type="checkbox"
                id="checkbox"
              />
            </li>
            <p>I want to know about event updates.</p>
          </div>
          <div className="Notifications-checkboxes">
            <li>
              <input
                checked={testUpdate}
                onChange={() => setTestUpdate(!testUpdate)}
                type="checkbox"
                id="checkbox"
              />
            </li>
            <p>I want to know about test updates.</p>
          </div>
          <div className="Notifications-checkboxes">
            <li>
              <input
                checked={passwordChange}
                onChange={() => setPasswordChange(!passwordChange)}
                type="checkbox"
                id="checkbox"
              />
            </li>
            <p>I want to know about password changes.</p>
          </div>
          <div className="setting-notification-btn">
            <button onClick={handleSaveNotificationChanges}>
              Save changes
            </button>
          </div>
        </div>

        <div className="Account-setting-Change-password">
          <h5>Change password</h5>

          <div className="Account-setting-Current-Password">
            <label htmlFor="Current Password">Current Password</label>
            <div className="Change-password-input">
              <input
                type={showCurrentPassword ? "text" : "password"}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder="Current Password"
              />
              {showCurrentPassword ? (
                <FiEyeOff onClick={() => togglePasswordVisibility("current")} />
              ) : (
                <FiEye onClick={() => togglePasswordVisibility("current")} />
              )}
            </div>
          </div>

          <div className="Account-setting-New-Password">
            <label htmlFor="New Password">New Password</label>
            <div className="New-Password-input">
              <input
                type={showNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="New Password"
              />
              {showNewPassword ? (
                <FiEyeOff onClick={() => togglePasswordVisibility("new")} />
              ) : (
                <FiEye onClick={() => togglePasswordVisibility("new")} />
              )}
            </div>
          </div>

          <div className="Account-setting-Confirm-Password">
            <label htmlFor="Confirm-Password">Confirm Password</label>
            <div className="Confirm-password-input">
              <input
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm Password"
              />
              {showConfirmPassword ? (
                <FiEyeOff onClick={() => togglePasswordVisibility("confirm")} />
              ) : (
                <FiEye onClick={() => togglePasswordVisibility("confirm")} />
              )}
            </div>
          </div>
          {!passwordsMatch && (
            <div style={{ color: "red", marginTop: "10px" }}>
              Passwords don't match!
            </div>
          )}

          {passwordChanged && (
            <div style={{ color: "green", marginTop: "10px" }}>
              Password changed successfully!
            </div>
          )}

          <div className="setting-password-btn">
            <button
              onClick={() => {
                updatePassword();
              }}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
