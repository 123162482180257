import React, { useEffect, useState } from "react";
import { FaCalendar } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";
import "./MyGrade.css";
import axios from "axios";
import "../../../../StudentComponents/data/MyscheduleData";
import MyGradeSectionTwo from "./MyGradeSectionTwo";
const Mygrade = ({ grades = [], getGrades = () => {}, getGpa=()=>{} }) => {
  const gradeStyles = [
    {
      result: "failed",
      labelColor: "#f93333",
      buttonColor: "#f93333",
      progressbarColor: "#f93333",
      gradebc: "#ffefef",
    },
    {
      result: "missed",
      buttonColor: "#9A9A9A",
      labelColor: "#9A9A9A",
      progressbarColor: "#9A9A9A",
      gradebc: "#E6E6E6",
    },
    {
      result: "passed",
      buttonColor: "#11A529",
      labelColor: "#11A529",
      progressbarColor: "#11A529",
      gradebc: "#Effff5",
    },
  ];

  // const mapGradeData = (originalData, gradeStyles) => {
  //   return originalData.map((item) => {
  //     const style =
  //       gradeStyles.find((gradeStyle) => gradeStyle.result === item.result) ||
  //       {};
  //     return { ...item, ...style };
  //   });
  // };
  // const [gradeData, setGradeData] = useState();
  // useEffect(() => {
  //   const getAllGrades = async () => {
  //     const allStudentGradesUrl = `${baseURL}/grades/allStudentGrades`;
  //     let response = await axios.get(allStudentGradesUrl);
  //     const gradadedData = mapGradeData(response.data, gradeStyles);
  //     console.log(gradadedData);
  //     setGradeData(gradadedData);
  //   };
  //   getAllGrades();
  // }, []);

  // const updateScoredPercentage = (id, newScore) => {
  //   const updatedData = gradeData.map((gradecard) => {
  //     if (gradecard.id === id) {
  //       return { ...gradecard, scoredPercentage: newScore };
  //     }
  //     return gradecard;
  //   });
  //   setGradeData(updatedData);
  // };

  const [showStudentsGrades, setShowStudentsGrades] = useState(false);

  const [gradeId, setGradeId] = useState("");

  const [myGrade, setMyGrade] = useState(null);

  const getMyGrade = async () => {
    try {
      if (gradeId) {
        const { data } = await axios.get(`/grades/${gradeId}`, {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        });
        setMyGrade(data);
      }
      
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
   getMyGrade()
  }, [gradeId]);

  return (
    <div className="MyGrade-main-Map1">
      {!showStudentsGrades ? (
        grades?.map((grade) => {
          return (
            <div
              className="mygrade-main"
              style={{
                marginTop: "1px",
                backgroundColor:
                  gradeStyles[
                    grade?.result === "fail"
                      ? 0
                      : grade?.result === "miss"
                      ? 1
                      : 2
                  ]?.gradebc,
              }}
            >
              <span
                style={{
                  color:
                    gradeStyles[
                      grade?.result === "fail"
                        ? 0
                        : grade?.result === "miss"
                        ? 1
                        : 2
                    ]?.labelColor,
                }}
              >
                {gradeStyles.result}
              </span>
              <div className="mygrade-child">
                <div className="mygrade-child-1">
                  <h2>{grade?.title}</h2>
                  <p>Subject : {grade?.Subject?.subjectName}</p>
                </div>
                <div className="mygrade-time">
                  <div className="calander-icon">
                    <FaCalendar style={{ marginTop: "3px" }} />
                    <p>{grade?.date ? grade?.date?.split("T")[0] : ""}</p>
                  </div>
                  <div className="time-icon">
                    <FaRegClock style={{ marginTop: "4px" }} />
                    <p>{grade?.time}</p>
                  </div>
                </div>
                <div className="mygrade-percentage">
                  <p className="mygrade-percentage-test">{grade?.gradeType}</p>
                  <div className="Passing-Percentage">
                    <h6>Scored Percentage</h6>
                    <p>{grade?.scoredPercentage}%</p>
                  </div>
                  <div className="Scored-Percentage">
                    <h6>Total Percentage</h6>
                    <p style={{ color: "#f93333" }}>100%</p>
                  </div>
                </div>
                <div className="progress-bar-line">
                  <progress
                    className="mygrade-process-line"
                    id={`file-${grade?.id}`}
                    value={(grade?.obtainmarkes / grade?.totalmarkes) * 100}
                    max="100"
                    style={{
                      "--progressbar-color":
                        gradeStyles[
                          grade?.result === "fail"
                            ? 0
                            : grade?.result === "miss"
                            ? 1
                            : 2
                        ]?.buttonColor,
                      "--progress": grade?.passingPercentage,
                    }}
                  >
                    {grade?.passingPercentage}%
                  </progress>
                </div>
                <div className="mygrade-btn">
                  <button
                    onClick={() => {
                      setGradeId(grade?.id);
                      setShowStudentsGrades(true);
                    }}
                    style={{
                      backgroundColor:
                        gradeStyles[
                          grade?.result === "fail"
                            ? 0
                            : grade?.result === "miss"
                            ? 1
                            : 2
                        ]?.buttonColor,
                    }}
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <MyGradeSectionTwo
          getGpa={getGpa}
            getGrades={getGrades}
            getMyGrade={getMyGrade}
          setShowStudentsGrades={setShowStudentsGrades}
          gradeId={gradeId}
          myGrade={myGrade?.grade}
          />
      )}

    </div>
  );
};

export default Mygrade;
