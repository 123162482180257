import React, { useEffect, useState } from "react";
import "./Special.css";
import print from "../../../StudentComponents/Medical/flat-color-icons_print.png";
import Clndr from "./Vector.png";
import Degree from "./mdi_university.png";
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import Dummy from "../../../AdminComponents/images/dummy-user.png";
import Green from "../../../StudentComponents/MyProfile/Group 1000006079.png";
import Red from "../../../StudentComponents/MyProfile/Group 1000006078.png";
import Timer from "../../../StudentComponents/MyProfile/Group 1000006077.png";
import Graph from "../../../StudentComponents/MyProfile/Group 1000006080.png";
import { baseURL } from "../../../../constants";
import axios from "axios";
import {
  IoAddCircleSharp,
  IoArrowBack,
  IoRefreshCircle,
} from "react-icons/io5";
axios.defaults.baseURL = baseURL;
function Special() {
  const [student, setStudent] = useState(null);

  const getStudent = async () => {
    try {
      const { data } = await axios.get(
        `/students/profile/${localStorage.getItem("teacherStudent")}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setStudent(data?.student);
    } catch (error) {
      console.log(error);
    }
  };

  //

  useEffect(() => {
    getStudent();
  }, []);
  const [gpa, setGpa] = useState([]);

  const getGpa = async () => {
    try {
      const { data } = await axios.get(
        `/grades/calculateGPA/${localStorage.getItem("teacherStudent")}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setGpa(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getGpa();
  }, []);

  const [assingment, setAssingment] = useState([]);

  const getAssingment = async () => {
    try {
      const { data } = await axios.get(
        `/assignments/getAssignmentCountByStudent/${localStorage.getItem(
          "teacherStudent"
        )}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setAssingment(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAssingment();
  }, []);

  const [completed, setComplted] = useState([]);

  const getCompleted = async () => {
    try {
      const { data } = await axios.get(
        `/assignmentSubmissions/getSubmittedAssignmentsCountByStudent/${localStorage.getItem(
          "teacherStudent"
        )}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setComplted(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCompleted();
  }, []);
  const [show, setShow] = useState(false);
  return (
    <div className="SpecialEducationTeacher">
      <div className="StuProfile-main">
        <div className="StuProfile-Top">
          <div className="StuProfile-Top1">
            <IoMdArrowBack style={{ cursor: "pointer" }} />
            <h1>Student Profile</h1>
          </div>
          <div className="StuProfile-Top2">
            <Link to="/TeacherChat" className="StuProfile-Top-btn1">
              Parent Chat
            </Link>
            <Link to="/TeacherChat" className="StuProfile-Top-btn2">
              Student Chat
            </Link>
            <Link to="/TeacherChat" className="StuProfile-Top-btn2">
              Admin Chat
            </Link>
          </div>
        </div>
        <div className="Myprofile-main-1">
          <div className="Myprofile-main-child-1">
            <div className="profile">
              <div className="profile-child-1">
                <img
                  onError={({ currentTarget }) => {
                    currentTarget.src = Dummy;
                  }}
                  alt="#"
                  src={`${baseURL}/images/${student?.profilePicture}`}
                />
              </div>
              <div className="profile-child-2">
                <div className="profile-child-2-1">
                  <h2>{student?.firstName + " " + student?.lastName}</h2>
                </div>
                <div className="profile-child-2-2">
                  <p>{student?.program}</p>
                  <button>
                    <span>●</span>
                    {student?.online ? "Online" : "Offline"}
                  </button>
                </div>
                <div className="profile-child-2-3">
                  <p>{student?.loggedInSince?.split("T")[0]}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="Myprofile-main-child-2">
            <div className="Myprofile-main-child-2-1">
              <img src={Timer} alt="#" />
              <h3>{student?.createdAt?.split("T")[0]}</h3>
            </div>
            <div className="Myprofile-main-child-2-2">
              <div className="div-1">
                <img src={Red} alt="#" />
                <h3>
                  Assignments <span>{assingment?.assignmentCount}</span>
                </h3>
              </div>
              <div className="div-2">
                <img src={Green} alt="#" />
                <h3>
                  Completed <span>{completed?.submittedAssignmentsCount}</span>
                </h3>
              </div>
            </div>
          </div>
          <div className="Myprofile-main-child-3">
            <div className="Myprofile-main-child-3-card-1">
              <img src={Graph} alt="#" />
            </div>
            <div className="Myprofile-main-child-3-card-2">
              <h3>GPA</h3>
              <h1>{gpa?.gpa}</h1>
            </div>
          </div>
        </div>
      </div>
      {!show ? (
        <div className="Specail-MAin">
          <div className="Special-Top">
            <h3>Special Education</h3>
            <button onClick={() => setShow(true)}>
              <IoAddCircleSharp />
              Add new Record
            </button>
          </div>
          <div className="Special-Second-MAin">
            <div className="Special-Second">
              <h3>Basic Information</h3>
              <button>
                <img src={print} alt="#" />
                Print the plan
              </button>
            </div>
            <div className="Special-Third-MAin">
              <div className="Special-Third">
                <div>
                  <h4>Disability</h4>
                  <p>
                    Speech or Language <br /> Impairment (SLI)
                  </p>
                </div>
                <div>
                  <h4>Disability</h4>
                  <p>
                    Emontional Disturbance <br /> (ED)
                  </p>
                </div>
                <div>
                  <p>
                    Flex Scheduling High School <br /> With ATT 9999894 Public{" "}
                    <br /> Day School
                  </p>
                </div>
                <div>
                  <h4>
                    <img src={Clndr} alt="#" />
                    Date
                  </h4>
                  <p>11/22/2016</p>
                </div>
              </div>
              <div className="Special-Third">
                <div>
                  <h4>Dist of Service</h4>
                  <p>
                    Eagle Unified School <br /> District 6599999
                  </p>
                </div>
                <div>
                  <h4>Dist of Residence</h4>
                  <p>
                    0 Eagle Unified School <br /> District 6599999
                  </p>
                </div>
                <div>
                  <h4>Dist of Residence</h4>
                  <p>
                    Eagle Unified School <br /> District 6599999
                  </p>
                </div>
                <div>
                  <h4>SELPA</h4>
                  <p>
                    3002-South Orange <br /> County Selpa
                  </p>
                </div>
              </div>
            </div>
            <div className="Special-Second-MAin">
              <div className="Special-Second">
                <h3>Plan Information</h3>
              </div>
              <div className="Special-last">
                <h2>1=10 - Individualized Education Program (IEP)</h2>
                <div className="Degree">
                  <h2>
                    <img src={Degree} alt="#" />
                    Plan:{" "}
                    <span>
                      To Participate in high school curriculum leading to a
                      diploma <br /> Gen e part % equal to or greater than 80
                      percent
                    </span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="LastDate">
              <div className="LastDateChild">
                <img src={Clndr} alt="#" />
                <h2>
                  Next Annual Review:<span>11/30/2023</span>
                </h2>
              </div>
              <div className="LastDateChild">
                <img src={Clndr} alt="#" />
                <h2>
                  Last Triennial Review:<span>11/30/2021</span>
                </h2>
              </div>
            </div>
            <div className="LastDate">
              <div className="LastDateChild">
                <img src={Clndr} alt="#" />
                <h2>
                  Next Triennial Review:<span>11/30/2024</span>
                </h2>
              </div>
              <div className="LastDateChild">
                <img src={Clndr} alt="#" />
                <h2>
                  Last IEP Meeting:<span>11/30/2022</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="Specail-MAin">
          <div className="Special-Top">
            <h3>
              <IoArrowBack onClick={() => setShow(false)} />
              Basic Information
            </h3>
          </div>
          <div className="ActivitiesBody">
            <div className="ActivitiesDate">
              <div className="ActivitiesRowOne">
                <div className="ActiLabel">
                  <p>Disability</p>
                </div>
                <select>
                  <option value="">Activity</option>
                  <option value="Award">Award</option>

                  <option value="Sports">Sports</option>
                </select>
              </div>
              <div className="ActivitiesRowOne">
                <div className="ActiLabel">
                  <p>Enter</p>
                </div>
                <input type="date" />
              </div>
              <div className="ActivitiesRowOne">
                <div className="ActiLabel">
                  <p>Dist of Service </p>
                </div>

                <select>
                  <option value="">Extracurricular Activity</option>
                  <option value="Archery">Archery</option>
                  <option value="Badminton">Badminton</option>
                </select>
              </div>
            </div>
            <div className="ActivitiesDate">
              <div className="ActivitiesRowOne">
                <div className="ActiLabel">
                  <p>Dist of Residence</p>
                </div>
                <select>
                  <option value="">Extracurricular Activity</option>
                  <option value="Archery">Archery</option>
                  <option value="Badminton">Badminton</option>
                </select>
              </div>
              <div className="ActivitiesRowOne">
                <div className="ActiLabel">
                  <p>Special Education Local Plan Area</p>
                </div>
                <select>
                  <option value="">Extracurricular Activity</option>
                  <option value="Archery">Archery</option>
                  <option value="Badminton">Badminton</option>
                </select>
              </div>
              <div className="ActivitiesRowOne">
                <div className="ActiLabel">
                  <p> Individualized Education Program (IEP)</p>
                </div>
                <select>
                  <option value="">Extracurricular Activity</option>
                  <option value="Archery">Archery</option>
                  <option value="Badminton">Badminton</option>
                </select>
              </div>
            </div>

            <div className="ActivitiesDate">
              <div className="ActivitiesRowOne">
                <div className="ActiLabel">
                  <p>Next Annual Review</p>
                </div>
                <input type="time" />
              </div>
              <div className="ActivitiesRowOne">
                <div className="ActiLabel">
                  <p>Last Triennial Review</p>
                </div>
                <input type="time" />
              </div>
              <div className="ActivitiesRowOne">
                <div className="ActiLabel">
                  <p>Next Triennial Review</p>
                </div>
                <input type="time" />
              </div>
            </div>
            <div className="ActivitiesDate">
              <div className="ActivitiesRowOne">
                <div className="ActiLabel">
                  <p>Last IEP Meeting</p>
                </div>
                <input type="time" />
              </div>
            </div>
            <div className="ActivitiesSave">
              <button>
                <IoRefreshCircle />
                Reset
              </button>
              <button>Save new Record</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Special;
