import React, { useEffect, useState, useCallback, useRef } from "react";
import "./ADDChatBox.css";
import { FaRegSmile } from "react-icons/fa";
import { IoMdCall, IoIosSend } from "react-icons/io";
import { useSearchParams } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { RiBarChartHorizontalLine } from "react-icons/ri";
import { GrAttachment } from "react-icons/gr";
import { useSelector } from "react-redux";
import EmojiPicker from "emoji-picker-react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import {
  useGetMyChatsQuery,
  useGetChatMessagesQuery,
  useGetChatDetailsQuery,
  useGetAdminChatMembersQuery,
} from "../../../../redux/chatsApis";

import { baseURL } from "../../../../constants";
import { useSocket } from "../../../../SocketProvider";
axios.defaults.baseURL = baseURL;
const MessageInput = ({ onSendMessage }) => {
  const [newMessage, setNewMessage] = useState("");
  const socket = useSocket();
  const [isShow, setIsShow] = useState(false);

  const handleSendMessage = () => {
    if (newMessage.trim() === "") return;

    onSendMessage(newMessage);
    setNewMessage("");
  };

  return (
    <div className="message-input">
      <button className="emojiColor">
        <GrAttachment />
      </button>
      <input
        type="text"
        placeholder="Type your message..."
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
      />
      <button className="emojiColor">
        <FaRegSmile onClick={() => setIsShow(true)} />
        <EmojiPicker open={isShow} onEmojiClick={() => setIsShow(false)} />
      </button>
      <button onClick={handleSendMessage}>
        <IoIosSend />
      </button>
    </div>
  );
};

const MessageBox = ({ messages, senderId }) => {
  console.log(messages, senderId, "Console");
  return (
    <div style={{ display: "flex", width: "100%" }} className="message-box">
      {messages.map((message, index) => (
        <div
          key={index}
          className={
            message?.senderId != senderId
              ? "student-message"
              : "teacher-message"
          }
          style={{
            marginBottom: "30px",
            alignSelf:
              senderId === message?.senderId ? "flex-end" : "flex-start",
            backgroundColor:
              message?.senderId === senderId ? "white" : "#00afef",
            color: message?.senderId === senderId ? "#00afef" : "white",
          }}
        >
          {message?.text}
          <span
            style={{
              color: message?.senderId === senderId ? "#00afef" : "white",
            }}
            className="timestamp"
          >
            {moment(new Date(message?.createdAt)).fromNow()}
          </span>
        </div>
      ))}
    </div>
  );
};

const ADDChatBox = () => {
  // const [activeTab, setActiveTab] = useState("tab1");
  const [firstTabVisible, setFirstTabVisible] = useState(false);
  const { user } = useSelector((state) => state.getUserProfile);
  const chatId = useSearchParams()[0].get("chatId");
  const socket = useSocket();
  // const handleTabClick = (tab) => {
  //   setActiveTab(tab);
  //   setFirstTabVisible(false);
  // };

  const handleToggleClick = () => {
    setFirstTabVisible((prevVisible) => !prevVisible);
  };

  useEffect(() => {
    if (socket) {
      socket.on("NEW_MESSAGE", getMessage);
      return () => {
        socket.off("NEW_MESSAGE", getMessage);
      };
    }
  }, [socket]);

  // const myId = 1;

  const navigate = useNavigate();

  useEffect(() => {}, [chatId]);

  const [data, setData] = useState([]);

  const {
    data: chatsData,
    error,
    refetch: refetchMyChats,
  } = useGetMyChatsQuery(
    {
      role: user?.role,
      userId: user?.dataValues?.id,
    },
    {
      skip: !user,
    }
  );
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (chatsData && searchValue) {
      const fil = chatsData?.chats?.filter((n) => {
        console.log(searchValue, "Main");
        return n?.name?.toLowerCase().includes(searchValue?.toLowerCase());
      });

      setData({
        chats: fil,
      });
    } else if (chatsData) {
      console.log(chatsData, "Haha chats");
      setData(chatsData);
    }
  }, [chatsData, searchValue]);

  const {
    data: msgs,
    refetch: messagesRefetch,
    error: msgError,
  } = useGetChatMessagesQuery({ chatId }, { skip: !chatId });

  const {
    data: chatDetails,
    refetch: chatDetailsRefetch,
    error: chatDetailsError,
  } = useGetChatDetailsQuery(
    { chatId, role: user?.role, userId: user?.dataValues?.id },
    { skip: !chatId }
  );

  useEffect(() => {
    if (msgs) {
      setMessages(msgs);
    }
  }, [msgs]);

  useEffect(() => {
    if (chatId) {
      messagesRefetch();
      chatDetailsRefetch();
    }
  }, [chatId]);

  const handleSendMessage = (text) => {
    socket.emit("NEW_MESSAGE", {
      members: chatDetails?.chat?.members?.map((mem) => `${mem.role}${mem.id}`),
      text,
      chatId,
      role: user?.role,
      createdAt: new Date(Date.now()),
    });
    // setMessages((prev) => {
    //   return [
    //     ...prev,
    //     {
    //       text: text,
    //       chatId: chatId,
    //       role: user?.role,
    //       senderId: `${user?.role}${user?.dataValues?.id}`,
    //       createdAt: new Date(Date.now()),
    //     },
    //   ];
    // });
  };

  const [messages, setMessages] = useState([]);

  const getMessage = useCallback((data) => {
    // if (data?.chatId?.toString() !== chatId?.toString()) return;

    setMessages((prev) => {
      return [
        ...prev,
        {
          text: data?.text,
          chatId: data?.chatId,
          role: data?.role,
          senderId: data?.senderId,
          createdAt: data?.createdAt,
        },
      ];
    });
  }, []);

  const { data: adminMembers, error: adminsError } =
    useGetAdminChatMembersQuery(
      { adminId: user?.dataValues?.id },
      { skip: !user?.dataValues?.id }
    );

  console.log(adminsError, "Chat admin members");
  const [transformData, setTransformData] = useState([]);

  useEffect(() => {
    if (adminMembers) {
      const filteredData = adminMembers
        ?.map((member) => {
          return [
            ...member?.teachers?.map((t) => {
              return {
                id: t.id,
                role: "teacher",
              };
            }),
            ...member?.students?.map((s) => {
              return {
                id: s.id,
                role: "student",
              };
            }),
            ...member?.parents?.map((p) => {
              return {
                id: p.id,
                role: "parent",
              };
            }),
          ];
        })
        .flat();
      setTransformData(filteredData);
    }
  }, [adminMembers]);

  console.log(transformData, "I'm transform data");
  useEffect(() => {
    if (transformData) {
      (async () => {
        try {
          console.log(transformData, "Juu tarsh");
          transformData.forEach(async (formD) => {
            try {
              const res = await axios.post(
                `/chats/addChat`,
                {
                  members: [
                    `${user?.role}${user?.dataValues?.id}`,
                    `${formD?.role}${formD?.id}`,
                  ],
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    accessToken: localStorage.getItem("token"),
                  },
                }
              );
              const res2 = await axios.post(
                `/chats/addUserChat`,
                {
                  chatId: res?.data?.chat?.id,
                  [`${formD?.role}Id`]: formD?.id,
                  adminId: user?.dataValues?.id,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    accessToken: localStorage.getItem("token"),
                  },
                }
              );
              refetchMyChats();
            } catch (error) {}
          });
        } catch (error) {}
      })();
    }
  }, [transformData]);
  const { onlineMembers } = useSelector((state) => state.misc);
  const [isOnline, setIsOnline] = useState(false);

  useEffect(() => {
    if (chatDetails) {
      const opponentMember = chatDetails?.chat?.members?.find((member) => {
        return (
          `${user?.role}${user?.dataValues?.id}` !==
          `${member?.role}${member?.id}`
        );
      });

      console.log(`${opponentMember?.role}${opponentMember?.id}`, "Big bro");
      const isOnline = onlineMembers?.includes(
        `${opponentMember?.role}${opponentMember?.id}`
      );

      setIsOnline(isOnline);
    }
  }, [chatDetails, onlineMembers]);
  return (
    <div className="chat-box">
      <div
        className={`toggle ${firstTabVisible ? "active" : ""}`}
        onClick={handleToggleClick}
      >
        <RiBarChartHorizontalLine />
      </div>
      <div
        className={`side-nav-tabs sideBar ${
          firstTabVisible ? "first-tab-open" : ""
        }`}
      >
        <div className="tabs firstTab">
          <div className="btnAndSearch">
            <div className="Buttons">
              <button>Teacher</button>
              <button>Counselor</button>
              <button>Principal</button>
            </div>
            <div className="ChatRequests">
              <p>Message</p>
              <Link to="/ChatRequest">Requests(12)</Link>
            </div>
            <div className="search">
              <FiSearch />
              <input
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                type="search"
                placeholder="Search"
              />
            </div>
            <h3>Announcement</h3>
          </div>
          <div className="ChatAmirGandu">
            {data?.chats?.map((chat) => {
              return (
                <div
                  style={{
                    backgroundColor:
                      chat?.chatId?.toString() === chatId?.toString()
                        ? "#d2effb"
                        : "white",
                  }}
                  className={`tab`}
                  onClick={() => navigate(`?chatId=${chat?.chatId}`)}
                >
                  <div className="userMassage">
                    <img
                      src={`${baseURL}/images/${chat?.avatar?.[0]}`}
                      onError={({ currentTarget }) => {
                        currentTarget.src =
                          "https://cdn.pixabay.com/photo/2016/07/07/16/46/dice-1502706_640.jpg";
                      }}
                      alt="userInfo"
                    />
                    <div className="userInfo">
                      <h2>{chat?.name}</h2>
                      {/* <div className="time">
                      <p>{chat?.lastMessage}</p>
                      <span>{chat?.lastUpdated}</span>
                    </div> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="ChatActiveStatus">Profile Visibility</div>
        </div>

        <div className="tab-content userMassages">
          <div className="userMassageBox">
            <div className="userCall">
              <div className="parent">
                <img
                  src={`${baseURL}/images/${chatDetails?.chat?.name}`}
                  onError={({ currentTarget }) => {
                    currentTarget.src =
                      "https://cdn.pixabay.com/photo/2016/07/07/16/46/dice-1502706_640.jpg";
                  }}
                  alt="Parent Info"
                />
                <div className="userInfoCall">
                  <h3>
                    {chatDetails?.chat?.name}{" "}
                    <span>({chatDetails?.chat?.role})</span>{" "}
                  </h3>
                  <p>{isOnline ? "Online" : "Offline"}</p>
                </div>
              </div>
              {/* <div className="callMsg">
                <BsCameraVideo />
                <IoMdCall />
                <BsThreeDotsVertical />
              </div> */}
            </div>
            <div className="ClassMassage">
              <MessageBox
                senderId={`${user?.role}${user?.dataValues?.id}`}
                messages={messages}
              />
            </div>
            <div className="MassageBoxUser">
              <MessageInput onSendMessage={handleSendMessage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ADDChatBox;
