import React from "react";
import ChartComponent from "../../../StudentComponents/Chart/ChartComponent";
import ParentStuInfo from "../../ParentStudentInfo/ParentStuInfo";
import RecentlyApp from "../../ParentRecently/RecentlyApp";

const ParentAppsAccess = () => {
  return (
    <div className="ParentAppsAccess">
      <ParentStuInfo />
      <ChartComponent />
      <RecentlyApp />
    </div>
  );
};

export default ParentAppsAccess;
