import React from "react";
import ParentStuInfo from "./ParentStuInfo";
import PersonalDetails from "./PersonalDetails";

function ParentInfoMain() {
  return (
    <div className="ParentAppsAccess ">
      <ParentStuInfo />
      <PersonalDetails />
    </div>
  );
}

export default ParentInfoMain;
