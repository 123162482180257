import React, { useState } from "react";
import "./EditStudentEnrolmentInfo.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

const EditStudentEnrolmentInfo = ({
  setShowEdit,
  getStudentEnrollmentInfo,
}) => {
  const [enrollmentDate, setEnrollmentDate] = useState("");
  const [rollingRetentionOption, setRollingRetentionOption] = useState("");
  const [estimatedGraduationDate, setEstimatedGraduationDate] = useState("");

  const [school, setSchool] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [enrollmentCode, setEnrollmentCode] = useState("");
  const [exitDate, setExitDate] = useState("");
  const [exitCode, setExitCode] = useState("");
  const [program, setProgram] = useState("");

  const params = useParams();

  const handleUpdateEnrollment = async () => {
    try {
      const { data } = await axios.put(
        `/students/updateStudentSchoolAndEnrollmentInfo/${params?.studentId}`,
        {
          enrollmentDate: enrollmentDate,
          rollingRetentionOption,
          estimatedGraduationDate: estimatedGraduationDate,
          school,
          gradeLevel,
          enrollmentCode,
          exitDate,
          exitCode,
          program,
        },
        {
          headers: {
            "Content-Type": "application/json",
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );

      toast.success("Profile Updated Successfully");
      getStudentEnrollmentInfo();
      setShowEdit(false);
    } catch (error) {
      console.log(error, "This is serror");
      toast.error(error?.response?.data?.error);
    }
  };

  return (
    <div className="EditStudent-main">
      <div className="EditStudent-child1-1">
        <div className="StudentEnrolmentInfo-main-child-1">
          <div>
            <h2>School & Enrolment Info</h2>
            <p>School Information</p>
          </div>
        </div>
        <div className="EditStudent-main-child-2">
          <div>
            <label htmlFor="Enrolment">Enrolment Date</label>
            <div className="select-labell">
              <input
                value={enrollmentDate}
                onChange={(e) => setEnrollmentDate(e.target.value)}
                type="date"
                placeholder="-"
              />
            </div>
          </div>
          <div>
            <label htmlFor="Rolling">Rolling Retention Option</label>
            <div className="select-labell">
              <input
                value={rollingRetentionOption}
                onChange={(e) => setRollingRetentionOption(e.target.value)}
                placeholder="-"
              />
            </div>
          </div>
        </div>
        <p className="Enrollment">Enrollment Info</p>
        <div className="EditStudent-main-child-2">
          <div>
            <label htmlFor="School">School</label>
            <div className="select-labell">
              <input
                type="text"
                value={school}
                onChange={(e) => setSchool(e.target.value)}
                placeholder="-"
              />
            </div>
          </div>
          <div>
            <label htmlFor="School">Grade Level</label>
            <div className="select-labell">
              <select
                value={gradeLevel}
                onChange={(e) => setGradeLevel(e.target.value)}
              >
                <option value="">Select Grade Level</option>
                <option value="kindergarten">Kindergarten</option>
                <option value="Grade 1">Grade 1</option>
                <option value="Grade 2">Grade 2</option>
                <option value="Grade 3">Grade 3</option>
                <option value="Grade 4">Grade 4</option>
                <option value="Grade 5">Grade 5</option>
                <option value="Grade 6">Grade 6</option>
                <option value="Grade 7">Grade 7</option>
                <option value="Grade 8">Grade 8</option>
                <option value="Grade 9">Grade 9</option>
                <option value="Grade 10">Grade 10</option>
                <option value="Grade 11">Grade 11</option>
                <option value="Grade 12">Grade 12</option>
              </select>
            </div>
          </div>
          <div>
            <div className="select-labell">
              <label>Estimated Graduation Date</label>
              <input
                value={estimatedGraduationDate}
                onChange={(e) => setEstimatedGraduationDate(e.target.value)}
                type="date"
                placeholder="-"
              />
            </div>
          </div>
          <div>
            <label htmlFor="School">Enrollment Code</label>
            <div className="select-labell">
              <input
                type="text"
                value={enrollmentCode}
                onChange={(e) => setEnrollmentCode(e.target.value)}
                placeholder="-"
              />
            </div>
          </div>
        </div>
        <div className="EditStudent-main-child-2">
          <div>
            <label htmlFor="School">Exit Date</label>
            <div className="select-labell">
              <input
                value={exitDate}
                onChange={(e) => setExitDate(e.target.value)}
                type="date"
                placeholder="-"
              />
            </div>
          </div>
          <div>
            <label htmlFor="School">Exit Code</label>
            <div className="select-labell">
              <input
                value={exitCode}
                onChange={(e) => setExitCode(e.target.value)}
                type="text"
                placeholder="-"
              />
            </div>
          </div>
          <div>
            <label htmlFor="School">Program</label>
            <div className="select-labell">
              <input
                value={program}
                onChange={(e) => setProgram(e.target.value)}
                type="text"
                placeholder="-"
              />
            </div>
          </div>
        </div>
        <div className="EditStudent-main-child-4">
          <div className="EditStudent-main-child-4-btnn">
            <button onClick={() => setShowEdit(false)}>Cancel</button>
            <button onClick={handleUpdateEnrollment}>Update</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditStudentEnrolmentInfo;
