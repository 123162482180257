import React, { useEffect, useState } from "react";
import ParentStuInfo from "../ParentStudentInfo/ParentStuInfo";
import GradesParent from "../Grades-main/MyGrade/GradesParent.jsx";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../../constants.js";
axios.defaults.baseURL = baseURL;

function ParentGrades() {
  const [recentGrades, setRecentGrades] = useState(null);

  const { studentId } = useSelector((state) => state.studentId);

  const getRecentGrades = async () => {
    try {
      const { data } = await axios.get(`/grades/recent/${studentId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });

      setRecentGrades(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getRecentGrades();
  }, [studentId]);
  console.log(recentGrades, "This is me sheryar")
  return (
    <div className="ParentAppsAccess ">
      <ParentStuInfo />
      {recentGrades && (
        <GradesParent isShow={true} status={"all"} data={recentGrades} />
      )}
    </div>
  );
}

export default ParentGrades;
