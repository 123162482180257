import React, { useState, useEffect } from "react";
import { IoArrowBack, IoCheckmarkDone } from "react-icons/io5";
import { FaRegCalendarAlt } from "react-icons/fa";
import { LuClock4 } from "react-icons/lu";
import ProgressBar from "react-bootstrap/ProgressBar";
import ProfilePic from "../../StudentComponents/images/Shoaib.png";
import Quizet from "./Quizlet-Logo-2021-present 1.png";
import lines from "./lines-removebg-preview.png";
import ModalChat from "./PrentRequetsChatModal/ModalChat";
import "./Assigment.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Dummy from '../../AdminComponents/images/dummy-user.png'
import axios from "axios";
import { baseURL } from "../../../constants";

function Assigment() {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(new Date());
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  // const gradecard = state && state.gradecard;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const formattedDate = currentDate.toLocaleDateString();

  // const passingPercentage = 30;
  // const totalMarks = 50;
  // const percentage = (passingPercentage / 100) * totalMarks;
  // const passingFraction = `${passingPercentage / 10}/10`;

  const handleGoBack = () => {
    navigate(-1);
  };

  const [grade, setGrade] = useState(null);


  const getGrade = async () => {
    try {
      const { data } = await axios.get(
        `/grades/${window.location.search?.split("=")[1]}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setGrade(data?.grade);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGrade();
  }, []);


  const [student, setStudent] = useState(null);

  const getStudent = async () => {
    try {
      if (grade) {
        const { data } = await axios.get(`/students/profile/${grade?.id}`, {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        });
        setStudent(data?.student);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStudent();
  }, [grade]);
  return (
    <div className="Assingment-main">
      <div className="BackArrowTop" onClick={handleGoBack}>
        <IoArrowBack />
        <h2>Assigment Result Details</h2>
      </div>
      <div className="ToomanyBtns">
        <div className="ProfileName">
          <img
            style={{
              borderRadius: "100%",
              width: "110px",
              height: "110px",
              resizeMode: "contain",
            }}
            src={`${baseURL}/images/${student?.profilePicture}`}
            onError={({ currentTarget }) => {
              currentTarget.src = Dummy;
            }}
            alt="Profile"
          />
          <span>
            <h4>
              {student?.firstName} {student?.middleName} {student?.lastName}
            </h4>
            <h5>Student ID : {student?.id}</h5>
          </span>
        </div>
        <div className="ToomanyBtns-main">
          <button className="ToomanyBtns-one same">
            Result Declared on 12:30 AM | 22 September 2023 <IoCheckmarkDone />
          </button>
          <div className="LinesFOurBtns">
            <button className="Parent same">Signature </button>
            <button className="FillDown">Download</button>
          </div>
          <ModalChat />
        </div>
      </div>
      <h6>BSGIS & Remote Sensing mid semester Quiz.</h6>
      <div className="BSGIS-SECtion">
        <div className="BSGIS-SECtion-child1">
          <button
            style={{
              color: "black",
              backgroundColor:
                grade?.result === "pass"
                  ? "lightgreen"
                  : grade?.result === "miss"
                  ? "lightgray"
                  : "lightred",
            }}
            className="STATUS"
          >
            Status : {grade?.result}
          </button>
          <button className="BATCHCO">Batch 3CO - JVY</button>
          <button className="ACtiveCLock">
            <LuClock4 />
            <span>{currentTime.toLocaleTimeString()}</span>
          </button>
          <button className="ActiveCalndr">
            <FaRegCalendarAlt />
            <span>{formattedDate}</span>
          </button>
          <p className="PARAGRAPG">
            Title :<span className="PARAGRAPGSPan">{grade?.title}</span>
          </p>
          <p>
            Subject : <span>{grade?.Subject?.subjectName}</span>
          </p>
          <hr />
        </div>
        <div className="BSGIS-SECtion-child2">
          <h3>Improvement your Grade on </h3>
          <img src={Quizet} alt="Quizet Logo" />
          <button>Access app</button>
        </div>
      </div>
      <div className="TotalMarks-Main">
        <div>
          <h2>Total Percentage : {grade?.scoredPercentage}</h2>
          <h4>Passing Percentage</h4>
          <div className="progress-container">
            <div
              style={{
                color:
                  grade?.result === "pass"
                    ? "green"
                    : grade?.result === "miss"
                    ? "gray"
                    : "red",
              }}
              className="progress-label top-left"
            >
              {grade?.passingPercentage}%
            </div>
            <ProgressBar
              variant={
                grade?.result === "pass"
                  ? "success"
                  : grade?.result === "miss"
                  ? "secondary"
                  : "danger"
              }
              now={grade?.passingPercentage}
            />
            <div className="progress-label2">{grade?.scoredPercentage}</div>
          </div>
        </div>
      </div>
      <div className="QuizPaper">
        <div className="Paper">
          <p>Quiz Paper</p>
          <button>Advance Remote Sem Question Paper.pdf</button>
          <h3>Result</h3>
          <p>Student’s Submission</p>
          <button>Networking Mid Sem Question Paper.pdf</button>
        </div>
        <div className="notification">
          <div className="cards">
            <div className="card1">
              <img src={ProfilePic} alt="Ellie Jolieas" />
              <div className="imginfo">
                <h4>Ellie Jolieas</h4>
                <p className="para">04 Dec, 2023 | 04:00 PM</p>
              </div>
            </div>
            <div className="card1 card2">
              <img src={ProfilePic} alt="Teacher Feedback" />
              <div className="imginfo">
                <span>Teacher Feedback</span>
                <h4>Dear Student</h4>
                <p>Need to be more Improve your Assignment in Next Time </p>
              </div>
              <img src={lines} alt="Line" className="lineImage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Assigment;
