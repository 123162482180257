// import React, { useState } from "react";
// import { Chart } from "react-google-charts";
// import "./CircleChart.css";
// import { TbArrowsDiagonal } from "react-icons/tb";
// import { IoAlertCircle } from "react-icons/io5";
// import { Dropdown } from "react-bootstrap";
// import "../LineChart/LineChart";
// import { Link } from "react-router-dom";
// import axios from "axios";
// import { baseURL } from "../../../../../constants";
// axios.defaults.baseURL = baseURL;
// axios.defaults.withCredentials = true;

// const CustomProgressBar = ({ label, value, color }) => {
//   return (
//     <div className="progress-bar-container">
//       <span className="progress-label">
//         <span className="dotty" style={{ backgroundColor: color }}></span>
//         {label}

//       </span>

//       <div className="backgroundColorprogress">
//         <div
//           className="progress-bar"
//           role="progressbar"
//           style={{ width: `${value}%`, backgroundColor: color }}
//           aria-valuenow={value}
//           aria-valuemin="0"
//           aria-valuemax="100"
//         ></div>
//       </div>
//     </div>
//   );
// };

// const getPlaceholderData = () => {
//   const randomData = [
//     ["Task", "Hours per Day"],
//     ["Placeholder Task 1", Math.floor(Math.random() * 5) + 1],
//     ["Placeholder Task 2", Math.floor(Math.random() * 5) + 1],
//     ["Placeholder Task 3", Math.floor(Math.random() * 5) + 1],
//   ];

//   return randomData;
// };

// const CircleChartWithProgressBars = ({
//   data,
//   totalHours,
//   colors,
//   chartType,
// }) => {
//   const [loading, setLoading] = useState(true);
//   const [centralitysScore, setCentralitysScore] = useState(null);

//   let chartData = data[chartType];

//   if (!chartData) {
//     // Handle the case where data for the selected chart type is not defined
//     console.error(`Data for chart type '${chartType}' is not defined.`);
//     chartData = getPlaceholderData();
//   }

//   const options = {
//     title: "My Daily Activities",
//     colors: colors.slice(0, chartData.length - 1),
//   };

//   return (
//     <div className="circleCharts">
//       <div className="lineChart">
//         <Chart
//           chartType="PieChart"
//           data={chartData}
//           options={options}
//           width={"100%"}
//           height={"100%"}
//           className="circleChart"
//         />
//       </div>
//       <div className="progress-bars">
//         {chartData.slice(1).map((item, index) => (
//           <CustomProgressBar
//             key={index}
//             value={(item[1] / totalHours) * 100}
//             color={colors[index] || "#ccc"}
//             label={`${item[0]} - ${item[1]} hours`}
//           />
//         ))}
//       </div>
//     </div>
//   );
// };

// // TODO: Integerate
// const CombinedChart = ({ data1 = [], data2 = [] }) => {
//   const [chartType, setChartType] = useState("AppCentralityScore");

//   const data = {
//     AppCentralityScore: [
//       ["Task", "Hours per Day"],
//       ["Teacher Feedback", data1.TeacherFeedback],
//       ["Test Score", data1.TestScore],
//       ["Commute", data1.commute],
//       ["Student Feedback", data1.StudentFeedback],
//       ["Parent Feedback", data1.parentFeedback],
//     ],
//     VendorCentralityScore: [
//       ["Task", "Hours per Day"],
//       ["Example Feedback", data2.ExampleFeedback],
//       ["Another Score", data2.AnotherScore],
//       ["Additional Task", data2.AdditionalTask],
//     ],
//   };

//   const colors = ["#4caf50", "#2196f3", "#f44336", "#ff9800", "#9c27b0"];

//   const totalHours = data[chartType]
//     .slice(1)
//     .reduce((total, item) => total + item[1], 0);

//   const handleChartTypeChange = (eventKey) => {
//     setChartType(eventKey);
//   };

//   return (
//     <div className="CombinedChart">
//       <div className="AddDropdown circleDropdown">
//         <div className="appCentrality">
//           <h2>App Centrality Score </h2>
//           <span title="Something about this feature and what it does. ">
//             <IoAlertCircle />
//           </span>
// <Link to="/FullCircleChart">
//   <span>
//     <TbArrowsDiagonal />
//   </span>
// </Link>
//         </div>
//         <Dropdown onSelect={handleChartTypeChange}>
//           <Dropdown.Toggle
//             variant="success"
//             id="dropdown-basic"
//             className="dropdown"
//           >
//             Filter Data
//           </Dropdown.Toggle>

//           <Dropdown.Menu>
//             {Object.keys(data).map((type) => (
//               <Dropdown.Item key={type} eventKey={type}>
//                 {type}
//               </Dropdown.Item>
//             ))}
//           </Dropdown.Menu>
//         </Dropdown>
//       </div>
//       <CircleChartWithProgressBars
//         data={data}
//         totalHours={totalHours}
//         colors={colors}
//         chartType={chartType}
//       />
//     </div>
//   );
// };

// export default CombinedChart;
import React, { useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import "./CircleChart.css";
import { IoAlertCircle } from "react-icons/io5";
import { Link } from "react-router-dom";
import { TbArrowsDiagonal } from "react-icons/tb";
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const App = () => {
  const [selectedOption, setSelectedOption] = useState("Option 1");

  const [dataPoints, setDataPoints] = useState([
    { y: 18, label: "Attendance" },
    { y: 49, label: "Test Score" },
    { y: 9, label: "Student F" },
    { y: 5, label: "Parent F" },
    { y: 19, label: "Teacher F" },
  ]);

  const handleOptionChange = (event) => {
    const option = event.target.value;

    setSelectedOption(option);

    if (option === "Vendor Centrality score") {
      setDataPoints([
        { y: 18, label: "Attendance" },
        { y: 49, label: "Test Score" },
        { y: 9, label: "Student F" },
        { y: 5, label: "Parent F" },
        { y: 19, label: "Teacher F" },
      ]);
    } else if (option === "App Centrality Score") {
      setDataPoints([
        { y: 30, label: " 2 Attendance" },
        { y: 40, label: " 2 Test Score" },
        { y: 15, label: " 2 Student F" },
        { y: 10, label: " 2 Parent F" },
        { y: 5, label: " 2 Teacher F" },
      ]);
    }
  };

  const labelColors = {
    Attendance: "#4CAF50",
    "Test Score": "#2196F3",
    "Student F": "#FFC107",
    "Parent F": "#E91E63",
    "Teacher F": "#9C27B0",
    " 2 Attendance": "#4CAF50",
    " 2 Test Score": "#2196F3",
    " 2 Student F": "#FFC107",
    " 2 Parent F": "#E91E63",
    " 2 Teacher F": "#9C27B0",
  };

  const options = {
    exportEnabled: false,
    animationEnabled: false,

    data: [
      {
        type: "pie",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>: {y}%",
        showInLegend: true,
        legendText: "{label}",
        indexLabelFontSize: 16,
        indexLabel: "{label} - {y}%",
        dataPoints: dataPoints.map((point) => ({
          ...point,
          color: labelColors[point.label],
        })),
      },
    ],
  };

  return (
    <div className="CIRCLECHARTAdmin">
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="CircleCentrality">
            <h3>Centrality Score</h3>
            <IoAlertCircle />
            <Link to="/FullCircleChart">
              <span>
                <TbArrowsDiagonal />
              </span>
            </Link>
          </div>
          <select value={selectedOption} onChange={handleOptionChange}>
            <option disabled value="">
              Filter Data
            </option>
            <option value="Vendor Centrality score">
              Vendor Centrality score
            </option>
            <option value="App Centrality Score">App Centrality Score</option>
          </select>
        </div>
        <CanvasJSChart options={options} />
      </div>
      <div className="progress-bars">
        {dataPoints.map((point, index) => (
          <div key={index} className="progress-bar">
            <div className="progress-bar-label">{point.label}</div>
            <div className="progress">
              <div
                className="progress-bar-fill"
                style={{
                  width: `${point.y}%`,
                  backgroundColor: labelColors[point.label],
                }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default App;
