import React, { useState } from "react";
import "./TabBook.css";
import Modal from "react-bootstrap/Modal";
import InputColor from "react-input-color";

function TabBook() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [color, setColor] = useState("");
  return (
    <div className="TabBook-Main">
      <div className="TabBook-Top">
        <h3>School</h3>
        <div className="TabBook-TopBtns">
          <button onClick={handleShow}>Add new Term</button>
        </div>
      </div>
      <div className="TabBookBody">
        {/* <Link to="/GradeBook" className="TabBookChild"> */}
        <div className="TabBookChild">
          <h2>Course 1</h2>
          <p>Math</p>
          <h6>1st Quarter</h6>
          <select>
            <option disabled>Details</option>
            <option>By Class</option>
            <option>By Assignment</option>
            <option>By Student</option>
            <option>Add Assignment</option>
            <option> Manage Gradebook</option>
          </select>
        </div>
        <div className="TabBookChild1">
          <h2>Course 2</h2>
          <p>Physical Eduction</p>
          <h6>2st Quarter</h6>
          <select>
            <option disabled>Details</option>
            <option value="one">By Class</option>
            <option value="two">By Assignment</option>
            <option value="three">By Student</option>
            <option value="four">Add Assignment</option>
            <option value="fifth"> Manage Gradebook</option>
          </select>
        </div>
        <div className="TabBookChild2">
          <h2>Course 3</h2>
          <p>Social Studies</p>
          <h6>3rd Quarter</h6>
          <select>
            <option disabled>Details</option>
            <option value="one">By Class</option>
            <option value="two">By Assignment</option>
            <option value="three">By Student</option>
            <option value="four">Add Assignment</option>
            <option value="fifth"> Manage Gradebook</option>
          </select>
        </div>
        <div className="TabBookChild3">
          <h2>Course 4</h2>
          <p>Work Habit</p>
          <h6>4th Quarter</h6>
          <select>
            <option disabled>Details</option>
            <option value="one">By Class</option>
            <option value="two">By Assignment</option>
            <option value="three">By Student</option>
            <option value="four">Add Assignment</option>
            <option value="fifth"> Manage Gradebook</option>
          </select>
        </div>
      </div>
      <div className="ModalMuiTab">
        <Modal
          show={show}
          onHide={handleClose}
          centered
          className="TabBookModal"
        >
          <Modal.Header closeButton>Add/ Edit School Term</Modal.Header>
          <Modal.Body>
            <div className="Accedmic-modal-mainTab">
              <label htmlFor="ProgramName">Select School Term:</label>
              <div className="Accedmic-modal-main-child1">
                <select>
                  <option>Select Term</option>
                  <option>one</option>
                  <option>two</option>
                </select>
              </div>
            </div>
            <div className="Accedmic-modal-mainTab">
              <label htmlFor="ProgramName">Select Quarter:</label>
              <div className="Accedmic-modal-main-child1">
                <select>
                  <option>1st Quarter</option>
                  <option>one</option>
                  <option>two</option>
                </select>
              </div>
            </div>
            <div className="Accedmic-modal-mainTab">
              <label htmlFor="ProgramName">Select Course:</label>
              <div className="Accedmic-modal-main-child1">
                <select>
                  <option>Select Course/Subject</option>
                  <option>one</option>
                  <option>two</option>
                </select>
              </div>
            </div>
            <div className="Accedmic-modal-mainTab">
              <label htmlFor="ProgramName">Start Date:</label>
              <div className="Accedmic-modal-main-child1">
                <input type="date" />
              </div>
            </div>
            <div className="Accedmic-modal-mainTab">
              <label htmlFor="ProgramName">End Date:</label>
              <div className="Accedmic-modal-main-child1">
                <input type="date" />
              </div>
            </div>
            <div className="Accedmic-modal-mainTab">
              <label htmlFor="ProgramName">Select Color:</label>
              <div className="Accedmic-modal-main-child1">
                <InputColor
                  initialValue="#00afef"
                  onChange={setColor}
                  placement="right"
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default TabBook;
