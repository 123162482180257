import "react-phone-number-input/style.css";
import React, { useState } from "react";
import "./ADDNewStudentinfo.css";
import { useEffect, useRef } from "react";
import fiupload from "../../../images/fi_upload-cloud.png";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { IoDocumentTextOutline } from "react-icons/io5";
import RefreshIcon from "@mui/icons-material/Refresh";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate, useParams } from "react-router-dom";
import countryList from "country-list";
import toast from "react-hot-toast";
import Select from "react-select";
import axios from "axios";
import { baseURL } from "../../../../../constants";
import PhoneInput from "react-phone-number-input";
import languageList from "language-list";
import CompleteModal from "../../../CompleteModal/Complete";
import { FaPhoneAlt } from "react-icons/fa";
import { Autocomplete, TextField } from "@mui/material";
import {
  isValidName,
  isValidAmericanPhoneNumber,
  isValidPassword,
  requiredValues,
  isValidEmail,
} from "../../../../../utils/regex";
import { useSelector } from "react-redux";
import Avator from "../../../PickAvator/Avator";
axios.defaults.baseURL = baseURL;

const options = [
  { value: "1", label: "Guardian" },
  { value: "2", label: "Amir" },
  { value: "3", label: "Charak" },
];

function ADDNewStudentinfo() {
  const { schoolId } = useSelector((state) => state.schoolId);

  const [parents, setParents] = useState([]);

  const getParents = async () => {
    try {
      const { data } = await axios.get(`/parents/getParents/${schoolId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });

      const newArr = data?.parents.map((data) => {
        return {
          value: data?.id,
          label: `${data?.firstName} ${data?.middleName} ${data?.lastName}`,
        };
      });

      setParents(newArr);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(parents, "these are parents");

  useEffect(() => {
    getParents();
  }, [schoolId]);

  const [inputData, setInputData] = useState({
    schoolId: "",
    firstName: "",
    middleName: "",
    lastName: "",
    staffName: "",
    staffId: "",
    alternateId: "",
    stateId: "",
    districtId: "",
    govIssuedNumber: "",
    dateOfBirth: "",
    gender: "",
    travelByBus: "",
    ethnicGroup: "",
    countryOfBirth: "",
    nationality: "",
    nativeLanguage: "",
    spokenLanguages: "",
    portalAccess: false,
    loginEmailAddress: "",
  });

  const [editPhoto, setEditPhoto] = useState(false);
  const params = useParams("");
  const [selectedFile, setSelectedFile] = useState("");
  const fileInputRef = useRef("");
  const [allLanguages, setAllLanguages] = useState(null);
  const languages = languageList();

  useEffect(() => {
    const allLanguages = languages.getData().map((item) => {
      return item.language;
    });
    setAllLanguages(allLanguages);
  }, []);
  const handleFileChange = (e) => {};

  useEffect(() => {
    if (window.location.search.split("=")[1] === "true") {
      setEditPhoto(true);
    } else {
      setEditPhoto(false);
    }
  }, [window.location.search.split("=")[1]]);

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const generateRandomPassword = () => {
    const randomPassword = Math.random().toString(36).slice(-8);
    setPassword(randomPassword);
  };

  const [disabled, setDisabled] = useState(false);
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [studentInformation, setStudentInformation] = useState("");
  const [prefferedName, setPrefferedName] = useState("");
  const [stateId, setStateId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [admissionNumber, setAdmissionNumber] = useState("");
  const [govtIssuedNumber, setGovtIssuedNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [ethnic, setEthinic] = useState("");
  const [travelByBus, setTravelByBus] = useState("");
  const [countryOfBirth, setCountryOfBirth] = useState("");
  const [nationality, setNationality] = useState("");
  const [nativeLanguage, setNativeLanguage] = useState("");
  const [spokenLanguages, setSpokenLanguages] = useState("");
  const [portalAccess, setPortalAccess] = useState(false);
  const [ssoLogin, setSSOLogin] = useState(false);
  const [studentId, setStudentId] = useState("");
  const [parentId, setParentId] = useState("");
  const [loginEmailAddress, setLoginEmailAddress] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [contactNumber, setContactNumber] = useState("+1");
  const [relationship, setRelationship] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);

  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);
  useEffect(() => {
    if (!contactNumber?.startsWith("+1")) {
      setContactNumber("+1");
    } else {
      setContactNumber(contactNumber);
    }
  }, [contactNumber]);

  const handleAddStudent = async () => {
    try {
      if (
        !requiredValues([
          {
            value: firstName,
            name: "First Name",
          },
          {
            value: lastName,
            name: "Last Name",
          },
          {
            value: stateId,
            name: "State Id",
          },
          {
            value: districtId,
            name: "District Id",
          },
          {
            value: studentInformation,
            name: "Student Information",
          },
          {
            value: admissionNumber,
            name: "Admission Number",
          },
          {
            value: govtIssuedNumber,
            name: "Government Issued Number",
          },
          {
            value: dateOfBirth,
            name: "Date of birth",
          },
          {
            value: gender,
            name: "Gender",
          },
          {
            value: travelByBus,
            name: "Travel by bus",
          },
          {
            value: countryOfBirth,
            name: "Country of birth",
          },
          {
            value: nationality,
            name: "Nationality",
          },
          {
            value: contactNumber,
            name: "Contact Number",
          },

          {
            value: nativeLanguage,
            name: "Native language",
          },
          {
            value: parentId,
            name: "Guardian Name",
          },
          {
            value: ethnic,
            name: "Ethinic Group",
          },
          {
            value: loginEmailAddress,
            name: "Login email address",
          },
          {
            value: schoolId,
            name: "School Id",
          },
          {
            value: gradeLevel,
            name: "Grade Level",
          },
          {
            value: studentId,
            name: "Student Id",
          },
          {
            value: relationship,
            name: "Relationship",
          },
        ])
      )
        return;

      if (!isValidName(firstName, "First name")) return;

      if (middleName) {
        if (!isValidName(middleName, "Middle name")) return;
      }

      if (!isValidName(lastName, "Last name")) return;

      if (prefferedName) {
        if (!isValidName(prefferedName, "Preffered name")) return;
      }

      if (!isValidAmericanPhoneNumber(contactNumber, `Contact number`)) return;

      if (!isValidName(relationship, "Relationship with student")) return;
      if (!isValidPassword(password)) return;

      if (!spokenLanguages || spokenLanguages.length < 1)
        return toast.error("Spoken languages are required");

      if (!isValidAmericanPhoneNumber(contactNumber)) return;

      if (!isValidEmail(loginEmailAddress)) return;

      const newForm = new FormData();
      newForm.append("firstName", firstName);
      newForm.append("middleName", middleName);
      newForm.append("lastName", lastName);
      newForm.append("studentInformation", studentInformation);
      newForm.append("prefferedName", prefferedName);
      newForm.append("stateId", stateId);
      newForm.append("districtId", districtId);
      newForm.append("admissionNumber", admissionNumber);
      newForm.append("disabled", disabled);
      newForm.append("govtIssuedNumber", govtIssuedNumber);
      newForm.append("dateOfBirth", dateOfBirth);
      newForm.append("gender", gender);
      newForm.append("ethnicGroup", ethnic);
      newForm.append("travelBybus", travelByBus);
      newForm.append("countryOfBirth", countryOfBirth);
      newForm.append("nationality", nationality);
      newForm.append("nativeLanguage", nativeLanguage);
      newForm.append("password", password);
      newForm.append("portalAccess", portalAccess);
      newForm.append("ssoLogin", ssoLogin);
      newForm.append("parentId", parentId);
      newForm.append("loginEmailAddress", loginEmailAddress);
      newForm.append("schoolId", schoolId);
      newForm.append("phoneNumber", contactNumber);
      newForm.append("gradeLevel", gradeLevel);
      newForm.append("studentId", studentId);
      newForm.append("profilePicture", profilePicture);
      const { data } = await axios.post(`/students/add`, newForm, {
        headers: {
          "Content-Type": "multipart/form-data",
          accessToken: `${localStorage.getItem("token")}`,
        },
      });

      if (data) {
        await axios.post(
          `/relations/add`,
          {
            parentId: Number(parentId),

            studentId: Number(data?.id),
            relationship,
          },
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
      }

      setSuccess(true);
      setSelectedFile("");
      setFirstName("");
      setMiddleName("");
      setLastName("");
      setStudentInformation("");
      setPrefferedName("");
      setStateId("");
      setDistrictId("");
      setAdmissionNumber("");
      setGovtIssuedNumber("");
      setDateOfBirth("");
      setGender("");
      setEthinic("");
      setCountryOfBirth("");
      setNationality("");
      setNativeLanguage("");
      setSpokenLanguages("");
      setPortalAccess(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.message ||
            error?.response?.data?.details[0]?.detail
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const handleChange = (selectedOption) => {
    console.log(selectedOption.value, "I've selected this");
    setParentId(selectedOption ? selectedOption.value : null);
  };

  const [lgShow, setLgShow] = useState(false);
  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      {success && <CompleteModal link="/CardViewMain/student" />}
      <Row>
        <div className="addstudent-amroo-main">
          <div className="addstudent-amroo-main-child-1">
            <Col sm={12}>
              <div className="EditStaff-main ">
                <div className="EditStaff-Col3-main">
                  <div className="EditStaff-COl3">
                    <div className="EditStaff-Child-1">
                      <div className="fiupload-img">
                        <div className="upLoaDing">
                          {selectedFile ? (
                            <img
                              src={selectedFile}
                              alt="Staff"
                              style={{
                                width: "100%",
                                height: "171px",
                                cursor: "pointer",
                                borderRadius: "50%",
                              }}
                              onClick={() => setLgShow(true)}
                            />
                          ) : (
                            <div
                              onClick={() => setLgShow(true)}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                src={fiupload}
                                alt="Staff"
                                style={{
                                  width: "20px",
                                  cursor: "pointer",
                                  height: "20px",
                                }}
                              />
                              <label
                                style={{
                                  cursor: "pointer",
                                  marginTop: "8px",
                                }}
                              >
                                Set Profile
                              </label>
                            </div>
                          )}
                          <input
                            type="file"
                            id="upload"
                            name="profilePicture"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />
                        </div>
                      </div>
                      <div>
                        <Avator
                          setSelectedFile={setSelectedFile}
                          profilePicture={profilePicture}
                          setProfilePicture={setProfilePicture}
                          lgShow={lgShow}
                          setLgShow={setLgShow}
                        />
                      </div>
                      <h2>
                        {firstName} {middleName} {lastName}
                      </h2>
                      <button>{prefferedName}</button>
                    </div>
                  </div>
                </div>
              </div>
              <Nav variant="pills" className="flex-column AddNEWSTUDENT2">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="AddNewStudent"
                  >
                    <IoDocumentTextOutline /> Student Details
                  </AccordionSummary>
                  <AccordionDetails>
                    <Nav.Item>
                      <Nav.Link eventKey="first">General Info</Nav.Link>
                    </Nav.Item>
                  </AccordionDetails>
                </Accordion>
              </Nav>
            </Col>
          </div>
          <div className="addstudent-amroo-main-child-2">
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <div className="ADDEditStudentInfo-main">
                    <div className="ADDEditStudent">
                      <h3>Identification Information</h3>

                      <div className="ADDEditStudentInfo-inputs">
                        <div>
                          <label>First/Given Name *</label> <br />
                          <input
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder="-"
                          />
                        </div>
                        <div>
                          <label>Middle Name</label> <br />
                          <input
                            value={middleName}
                            onChange={(e) => setMiddleName(e.target.value)}
                            placeholder="-"
                          />
                        </div>
                        <div>
                          <label>Last/Family Name *</label> <br />
                          <input
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder="-"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="ADDEditStudentInfo-inputschild-1">
                      <div>
                        <label>Student Information *</label> <br />
                        <input
                          value={studentInformation}
                          onChange={(e) =>
                            setStudentInformation(e.target.value)
                          }
                          placeholder="-"
                        />
                      </div>
                      <div>
                        <label>Preferred/Common Name </label> <br />
                        ADDNewStudentinfo
                        <input
                          value={prefferedName}
                          onChange={(e) => setPrefferedName(e.target.value)}
                          placeholder="-"
                        />
                      </div>
                      <div>
                        <label>Student ID *</label> <br />
                        <input
                          value={studentId}
                          onChange={(e) => setStudentId(e.target.value)}
                          placeholder="-"
                        />
                      </div>
                    </div>
                    <div className="ADDEditStudentInfo-inputschild-1">
                      <div>
                        <label>State ID *</label> <br />
                        <input
                          value={stateId}
                          onChange={(e) => setStateId(e.target.value)}
                          placeholder="-"
                        />
                      </div>
                      <div>
                        <label>Relationship With Student</label> <br />
                        <input
                          value={relationship}
                          onChange={(e) => setRelationship(e.target.value)}
                          placeholder="-"
                        />
                      </div>
                      <div>
                        <label>Guardian Name *</label> <br />
                        <Select
                          value={options.find(
                            (option) => option.value === parentId
                          )}
                          onChange={handleChange}
                          options={parents}
                        />
                      </div>
                      <div>
                        <label>District ID *</label> <br />
                        <input
                          value={districtId}
                          onChange={(e) => setDistrictId(e.target.value)}
                          placeholder="-"
                        />
                      </div>
                      <div>
                        <label>Admission Number *</label> <br />
                        <input
                          value={admissionNumber}
                          onChange={(e) => setAdmissionNumber(e.target.value)}
                          placeholder="-"
                        />
                      </div>
                      <div className="ADDEditStudentInfo-inpOne">
                        <label>Ethnicity Groups *</label>
                        <select
                          value={ethnic}
                          onChange={(e) => setEthinic(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Group
                          </option>
                          <option value="Asian">Asian</option>
                          <option value="American">American</option>
                          <option value="German">German</option>
                          <option value="Italian">Italian</option>
                          <option value="Irish">Irish</option>
                          <option value="South American">South American</option>
                        </select>
                      </div>
                      <div className="ADDEditStudentInfo-inpOne">
                        <label>Travel By Bus *</label>
                        <select
                          value={travelByBus}
                          onChange={(e) => setTravelByBus(e.target.value)}
                        >
                          <option value="" disabled></option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                      </div>
                    </div>
                    <div className="ADDEditStudentInfo-inputschild-1">
                      <div>
                        <label>Out Govt Issued Number *</label> <br />
                        <input
                          value={govtIssuedNumber}
                          onChange={(e) => setGovtIssuedNumber(e.target.value)}
                          placeholder="-"
                        />
                      </div>
                    </div>

                    <div className="ADDEditStudentInfo-child-2">
                      <h3>Demographic Information</h3>
                      <div className="ADDEditStudentInfo-inp">
                        <div>
                          <label>Date of birth *</label> <br />
                          <input
                            value={dateOfBirth}
                            onChange={(e) => setDateOfBirth(e.target.value)}
                            type="date"
                            placeholder="-"
                          />
                        </div>
                        <div>
                          <label>Gender *</label> <br />
                          <select
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                          >
                            <option value="">_</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="Non-Binary">Non-Binary</option>
                          </select>
                        </div>
                        <div>
                          <label>Country of birth *</label> <br />
                          <Autocomplete
                            disablePortal
                            value={countryOfBirth}
                            onChange={(event, newValue) => {
                              setCountryOfBirth(newValue);
                            }}
                            id="combo-box-demo"
                            options={countryList.getNames()}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </div>
                      </div>
                      <div className="ADDEditStudentInfo-inp">
                        <div>
                          <label>Nationality *</label> <br />
                          <Autocomplete
                            disablePortal
                            value={nationality}
                            onChange={(event, newValue) => {
                              setNationality(newValue);
                            }}
                            id="combo-box-demo"
                            options={countryList.getNames()}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </div>
                        <div>
                          <label>Native Language *</label> <br />
                          {allLanguages && (
                            <Autocomplete
                              disablePortal
                              value={nativeLanguage}
                              onChange={(event, newValue) => {
                                setNativeLanguage(newValue);
                              }}
                              id="combo-box-demo"
                              options={allLanguages}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          )}
                        </div>
                        <div>
                          <label>Contact Number *</label> <br />
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "7px",
                            }}
                          >
                            <FaPhoneAlt />
                            <PhoneInput
                              placeholder="Enter contact number"
                              value={contactNumber}
                              onChange={setContactNumber}
                            />
                          </div>
                        </div>
                        <div>
                          <label>Grade Level *</label> <br />
                          <select
                            value={gradeLevel}
                            onChange={(e) => setGradeLevel(e.target.value)}
                          >
                            <option value="">Select Grade Level</option>
                            <option value="kindergarten">Kindergarten</option>
                            <option value="Grade 1">Grade 1</option>
                            <option value="Grade 2">Grade 2</option>
                            <option value="Grade 3">Grade 3</option>
                            <option value="Grade 4">Grade 4</option>
                            <option value="Grade 5">Grade 5</option>
                            <option value="Grade 6">Grade 6</option>
                            <option value="Grade 7">Grade 7</option>
                            <option value="Grade 8">Grade 8</option>
                            <option value="Grade 9">Grade 9</option>
                            <option value="Grade 10">Grade 10</option>
                            <option value="Grade 11">Grade 11</option>
                            <option value="Grade 12">Grade 12</option>
                          </select>
                        </div>
                        <div className="AutoCOMPTE">
                          <label>Languages Spoken *</label> <br />
                          {allLanguages && (
                            <Autocomplete
                              disablePortal
                              value={spokenLanguages}
                              onChange={(event, newValue) => {
                                setSpokenLanguages(newValue);
                              }}
                              id="combo-box-demo"
                              options={allLanguages}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="ADDEditSchoolInfo-Switch"
                      style={{
                        display: "flex",
                        gap: "20px",
                        alignItems: "center",
                      }}
                    >
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id=""
                          checked={portalAccess}
                          onChange={() => setPortalAccess(!portalAccess)}
                        />
                        <label className="form-check-label">
                          Portal Access
                        </label>
                      </div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id=""
                        />
                        <label className="form-check-label">
                          Allow SSO Login
                        </label>
                      </div>
                    </div>
                    {portalAccess && (
                      <div className="ADDSwitchShowInput">
                        <div className="ADDSwitchShowInput-child-1">
                          <label>Access Information *</label> <br />
                          <input
                            value={loginEmailAddress}
                            onChange={(e) =>
                              setLoginEmailAddress(e.target.value)
                            }
                            placeholder="Login Email Address*"
                          />
                        </div>
                        <div className="ADDSwitchShowInput-child-2">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              checked={ssoLogin}
                              onChange={() => setSSOLogin(!ssoLogin)}
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                              style={{ color: "black" }}
                            >
                              Set or Reset Password
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* ///////////////////Show Hide////////////////////// */}
                    {ssoLogin ? (
                      <div className="ADDEditShowHide">
                        <h4>Password *</h4>
                        <h5>Student email to set or reset password</h5>
                        <div class="form-check">
                          <input
                            class="form-check-input "
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios1"
                            value="option1"
                          />
                          <label
                            class="form-check-label labelss"
                            for="exampleRadios1"
                          >
                            Student will be able to create password on their own
                            by clicking the link on their email
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios1"
                            value="option1"
                          />
                          <label class="form-check-label" for="exampleRadios1">
                            Create Password
                          </label>
                        </div>
                        <div className="ADDShowCloseDiv-main">
                          <div className="ADDShowCloseDiv">
                            <input
                              type={showPassword ? "text" : "password"}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              placeholder="Enter your password"
                            />
                            <span
                              className="eye-icon"
                              onClick={handleTogglePassword}
                            >
                              {showPassword ? "👁️" : "👁️‍🗨️"}
                            </span>
                          </div>
                          <p onClick={generateRandomPassword}>
                            <RefreshIcon />
                            Auto Generate Password
                          </p>
                        </div>
                      </div>
                    ) : null}
                    {/* ///////////////////////End Show Hide/////////////////////////// */}
                    <div className="ADDEditFooter">
                      {/* <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          checked={disabled}
                          id="flexCheckDisabled"
                          onChange={() => setDisabled(!disabled)}
                        />
                        <label class="form-check-label" for="flexCheckDisabled">
                          Disabled checkbox
                        </label>
                      </div> */}
                      <button
                        onClick={() => navigate(`/CardViewMain/student`)}
                        className="ADDEditFooter-btn1"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleAddStudent}
                        className="ADDEditFooter-btn2"
                      >
                        Add Student
                      </button>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </div>
        </div>
      </Row>
    </Tab.Container>
  );
}

export default ADDNewStudentinfo;
