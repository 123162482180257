import React, { useEffect, useState } from "react";
import "./Guidance.css";
import print from "../Medical/flat-color-icons_print.png";
import { AiOutlineMessage } from "react-icons/ai";
import { IoPersonCircleOutline } from "react-icons/io5";
import { FaMapMarkerAlt } from "react-icons/fa";
import axios from "axios";
import { useSelector } from "react-redux";
import { baseURL } from "../../../constants";
axios.defaults.baseURL = baseURL;

function Guidance() {
  const [disciplines, setDisciplines] = useState(null);

  const { user } = useSelector((state) => state.getUserProfile);

  const getSchoolByStudent = async () => {
    try {
      if (user?.dataValues?.id) {
        const { data } = await axios.get(
          `/ParentBySchoolId/getschoolBystudentId/${user?.dataValues?.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        const res = await axios.get(
          `/disciplines/getBySchoolId/${data.school.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setDisciplines(res.data.disciplines);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchoolByStudent();
  }, [user]);
  function handlePrint() {
    window.print();
  }
  return (
    <div className="Guidance-Main">
      <div className="Guidance-Top">
        <h3>Discipline Records</h3>
      </div>
      <div className="Guidance-BodyMain A">
        {disciplines &&
          disciplines?.map((discipline) => {
            return (
              <div className="Guidance-BodyMain">
                <div className="Guidance-Second">
                  <div className="Guidance-Second-top">
                    <h3>Attitude Problem (A)</h3>
                    <button onClick={handlePrint}>
                      <img src={print} alt="#" />
                      Print Discipline Record
                    </button>
                  </div>
                </div>
                <div className="Guidance-Body">
                  <div className="Guidance-Body-Edit">
                    <h3>
                      <FaMapMarkerAlt />
                      {discipline?.Location}
                    </h3>
                  </div>
                  <h3>
                    <IoPersonCircleOutline />
                    Status: {discipline?.status}
                  </h3>
                  <h3>
                    <AiOutlineMessage />
                    {discipline?.comment}
                  </h3>
                  <h3>
                    <span>Possible Motivation: </span>
                    {discipline?.PossibleMotivation}
                  </h3>
                  <h3>
                    <span>Consequence: </span>
                    {discipline?.Consequence}
                  </h3>
                  <h3>
                    <span>Created By: </span>Admin
                  </h3>
                  <h3>
                    <span>Last Update: </span>
                    {discipline?.updatedAt?.split("T")[0]}
                  </h3>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Guidance;
