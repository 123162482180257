import React, { useEffect, useState } from "react";
import TeacherWelcomeBack from "../TeacherWelcomeBack/TeacherWelcomeBack";
import ADDMessages from "../TeacherMessagesRequest/ADDMessages";
import TeacherMySchedule from "../TeacherMySchedule/TeacherMySchedule";
import TeacherClassRoom from "../TeacherClassRoom/TeacherClassRoom";
import TeacherHomeChart from "../TeacherCharts/TeacherHomChart";
import axios from "axios";
import { baseURL } from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;

function TeacherHome() {
  const [screenTime, setScreenTime] = useState(null);
  console.log(screenTime);
  const getScreenTime = async () => {
    try {
      const { data } = await axios.get("/getTeacherOverview/getAppUsage", {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setScreenTime(data);
    } catch (error) {
      console.log(error);
    }
  };
  const [classrooms, setClassrooms] = useState(null);

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);
  const getAllClassrooms = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/classrooms/getClassroomsByTeacherId/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        setClassrooms(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getScreenTime();
    getAllClassrooms();
    // eslint-disable-next-line
  }, [user]);

  return (
    <div className="Home-main">
      <div className="Home-70">
        <TeacherWelcomeBack />
        <TeacherHomeChart />
        {classrooms && (
          <TeacherClassRoom
            getAllClassrooms={getAllClassrooms}
            showAll={false}
            data={classrooms}
          />
        )}
      </div>
      <div className="Home-30">
        <TeacherMySchedule />
        <ADDMessages />
      </div>
    </div>
  );
}

export default TeacherHome;
