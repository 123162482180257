import React, { useState } from "react";
import "./History.css";
import { IoArrowBack } from "react-icons/io5";
import { CiSaveDown2 } from "react-icons/ci";
import { MdOutlineRefresh } from "react-icons/md";
import print from "../../../../StudentComponents/Medical/flat-color-icons_print.png";
import { FiEdit } from "react-icons/fi";

function History() {
  function handlePrint() {
    window.print();
  }
  const [show, setShow] = useState(false);
  return (
    <>
      {!show ? (
        <div className="History-MainAdmin">
          <div className="History-Top">
            <h3>Grades History</h3>
            <button onClick={handlePrint}>
              <img src={print} />
              Print
            </button>
          </div>
          <div className="GradesTAbLEEE">
            <table>
              <thead>
                <tr>
                  <th>MK#</th>
                  <th>Heading</th>
                  <th>Per</th>
                  <th>Section</th>
                  <th>Crs-ID</th>
                  <th>Course Title</th>
                  <th>Teacher(s)</th>
                  <th>Mark</th>
                  <th>CRD</th>
                  <th>CIT</th>
                  <th>WH</th>
                  <th>ABS</th>
                  <th>TDY</th>
                  <th>ENR</th>
                  <th>EXC</th>
                  <th>UNX</th>
                  <th>SUS</th>
                  <th>Comment</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Ist Qtr</td>
                  <td>P1</td>
                  <td>1118</td>
                  <td>0317</td>
                  <td>IB Eng HL2</td>
                  <td>*May,Ava</td>
                  <td>A</td>
                  <td>5.00</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>29</td>
                  <td>29</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td></td>
                  <td>
                    <FiEdit onClick={() => setShow(true)} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="History-MainAdmin">
          <div className="History-Top">
            <h3>
              {" "}
              <IoArrowBack onClick={() => setShow(false)} />
              Grades History
            </h3>
            <div className="GradeThreeBts">
              <button onClick={() => setShow(false)}>
                <CiSaveDown2 />
                Save the Record
              </button>
              <button>
                <MdOutlineRefresh />
                Reset Records
              </button>
            </div>
          </div>
          <div className="GradesTAbLEEE">
            <table>
              <thead>
                <tr>
                  <th>MK#</th>
                  <th>Heading</th>
                  <th>Per</th>
                  <th>Section</th>
                  <th>Crs-ID</th>
                  <th>Course Title</th>
                  <th>Teacher(s)</th>
                  <th>Mark</th>
                  <th>CRD</th>
                  <th>CIT</th>
                  <th>WH</th>
                  <th>ABS</th>
                  <th>TDY</th>
                  <th>ENR</th>
                  <th>EXC</th>
                  <th>UNX</th>
                  <th>SUS</th>
                  <th>Comment</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <select>
                      <option>A</option>
                      <option>A-</option>
                      <option>B+</option>
                      <option>B</option>
                      <option>B-</option>
                      <option>C+</option>
                      <option>C-</option>
                      <option>C</option>
                      <option>D-</option>
                      <option>D</option>
                      <option>F-</option>
                      <option>F</option>
                    </select>
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <input />
                  </td>
                  <td>
                    <select>
                      <option>A</option>
                      <option>B</option>
                      <option>C</option>
                      <option>D</option>
                      <option>E</option>
                      <option>F</option>
                      <option>G</option>
                      <option>H</option>
                      <option>I</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default History;
