import { Routes, Route } from "react-router-dom";
import LoginCards from "../StudentComponents/LoginPages/RoleSelection/LoginCards";
import Login from "../StudentComponents/LoginPages/LoginPages/Login";
import SignUp from "../StudentComponents/LoginPages/SignupPages/Addsignup";
import ForgotPassword from "../StudentComponents/ForgotPassword/ForgotPassword";
import FirstPage from "../StudentComponents/LoginPages/FirstPage/FirstPage";

const Authentication = () => {
  return (
    <Routes>
      <Route path={"/"} element={<LoginCards />} />
      <Route path={"/login"} element={<Login />} />
      <Route path={"/SignUp"} element={<SignUp />} />
      <Route path={"/ForgotPassword"} element={<ForgotPassword />} />
      <Route path={"/FirstPage"} element={<FirstPage />} />
    </Routes>
  );
};

export default Authentication;
