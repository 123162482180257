import React from "react";
import "./History.css";
import printIcon from "../Medical/flat-color-icons_print.png";

function History() {
  function handlePrint() {
    window.print()
}

  return (
    <div className="History-Main">
      <div className="History-Top">
        <h3>Grades History</h3>
        <button onClick={handlePrint}>
          <img src={printIcon} alt="#" />
          Print Grade History
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th>MK#</th>
            <th>Heading</th>
            <th>Per</th>
            <th>Section</th>
            <th>Crs-ID</th>
            <th>Course Title</th>
            <th>Teacher(s)</th>
            <th>Mark</th>
            <th>CRD</th>
            <th>CIT</th>
            <th>WH</th>
            <th>ABS</th>
            <th>TDY</th>
            <th>ENR</th>
            <th>EXC</th>
            <th>UNX</th>
            <th>SUS</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Ist Qtr</td>
            <td>P1</td>
            <td>1118</td>
            <td>0317</td>
            <td>IB Eng HL2</td>
            <td>*May,Ava</td>
            <td>A</td>
            <td>5.00</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>29</td>
            <td>29</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
export default History;
