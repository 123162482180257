import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./CourseTab.css";
import axios from "axios";
import { baseURL } from "../../../../../constants";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
axios.defaults.baseURL = baseURL;

function CourseAssignModal({ getCourses }) {
  const [lgShow, setLgShow] = useState(false);
  const [studentCourses, setStudentCourses] = useState([]);

  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedProgram, setSelectedProgram] = useState("");
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [selectedMarkingPeriod, setSelectedMarkingPeriod] = useState("");
  const [subjectId, setSubjectId] = useState("");

  const [subjects, setSubjects] = useState(null);

  const { schoolId } = useSelector((state) => state?.schoolId);

  const getSubjects = async () => {
    try {
      const { data } = await axios.get(`/subjects/getSubjects/${schoolId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setSubjects(data?.subjects);
    } catch (error) {
      console.log(error);
    }
  };

  const [programs, setPrograms] = useState(null);
  const getPrograms = async () => {
    try {
      const { data } = await axios.get(
        `/programs/getProgramsBySchool/${schoolId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );

      setPrograms(data?.programs);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCourses = async () => {
    try {
      const { data } = await axios.get(`/courses/all/${schoolId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setStudentCourses(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [teachers, setTeachers] = useState(null);

  const getTeachers = async () => {
    try {
      const { data } = await axios.get(`/teachers/all/${schoolId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setTeachers(data?.teacherList);
    } catch (error) {
      console.log(error);
    }
  };

  const [rooms, setRooms] = useState("");

  const getRooms = async () => {
    try {
      const { data } = await axios.get(`/rooms/getSchoolRooms/${schoolId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setRooms(data?.rooms);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSubjects();
    getPrograms();
    getAllCourses();
    getTeachers();
    getRooms();
  }, [schoolId]);

  const params = useParams();

  const [classrooms, setClassrooms] = useState([]);

  const getAllClassrooms = async () => {
    try {
      const { data } = await axios.get(
        `/classrooms/getClassroomsBySchoolId/${schoolId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );

      setClassrooms(data?.classrooms);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllClassrooms();
  }, [schoolId]);

  const handleAssignCourse = async () => {
    try {
      const { data } = await axios.post(
        "/studentCourses/assignStudentCourse",
        {
          studentId: params?.studentId,
          courseId: selectedCourse,
          subjectId: subjectId,
          programId: selectedProgram,
          classroomId: Number(selectedMarkingPeriod),
          teacherId: selectedTeacher,
        },
        {
          headers: {
            "Content-Type": "application/json",
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Course Assigned Successfully");
      getCourses();
      setLgShow(false);
    } catch (error) {
      if (error?.response?.data?.error) {
        toast.error(
          error?.response?.data?.error[0]?.error ||
            error?.response?.data?.error[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <div className="CourseAssign-main">
      <div onClick={() => setLgShow(true)}>Assign Course Section</div>
      <Modal
        size="xl"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="CourseAssign-Modal"
      >
        <Modal.Header closeButton>
          <h2 className="Course-h2">Assign Course Section</h2>
        </Modal.Header>
        <Modal.Body>
          <div className="CourseAssign-Select">
            <select onChange={(e) => setSelectedCourse(e.target.value)}>
              <option value="1">Select Course</option>

              {studentCourses &&
                studentCourses?.courses?.map((item) => (
                  <option key={item?.id} value={item?.id}>
                    {item?.courseName}
                  </option>
                ))}
            </select>
            <select
              value={subjectId}
              onChange={(e) => setSubjectId(e.target.value)}
            >
              <option value="">Select Subject</option>
              {subjects &&
                subjects?.map((item) => (
                  <option key={item?.id} value={item?.id}>
                    {item?.subjectName}
                  </option>
                ))}
            </select>
            <select onChange={(e) => setSelectedProgram(e.target.value)}>
              <option value="">Select Program</option>
              {programs &&
                programs?.map((item) => (
                  <option key={item?.id} value={item?.id}>
                    {item?.programName}
                  </option>
                ))}
            </select>
            <select onChange={(e) => setSelectedMarkingPeriod(e.target.value)}>
              <option value={""}>Select Classroom</option>
              {classrooms &&
                classrooms?.map((classroom) => {
                  return (
                    <option value={classroom?.id}>
                      {classroom?.classroomName}
                    </option>
                  );
                })}
            </select>
            <select
              value={selectedTeacher}
              onChange={(e) => setSelectedTeacher(e.target.value)}
            >
              <option value={""}>Select Teacher</option>
              {teachers &&
                teachers?.map((teacher) => {
                  return (
                    <option value={teacher?.id}>{teacher?.staffName}</option>
                  );
                })}
            </select>
          </div>
          <div className="CourseAssign-Button">
            <button onClick={handleAssignCourse}>
              Assign Selected Course Section(s)
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CourseAssignModal;
