import React, { useState, useRef, useEffect } from "react";
import "./EditSchool.css";
import UploadImg from "../images/fi_upload-cloud.png";
import axios from "axios";
import { baseURL } from "../../../constants";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import CompleteModal from "../CompleteModal/Complete";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Country, State, City } from "country-state-city";
import toast from "react-hot-toast";
axios.defaults.baseURL = baseURL;

axios.defaults.withCredentials = true;

function EditSchoolInfo() {
  const [selectedCountry, setSelectedCountry] = useState("US");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const handleCountryChange = (val) => {
    setSelectedCountry(val);
    setSelectedRegion("");
    setSelectedCity("");
  };

  const handleRegionChange = (val) => {
    setSelectedRegion(val);
    setSelectedCity("");
  };

  const handleCityChange = (val) => {
    setSelectedCity(val);
  };

  const countries = Country.getAllCountries();
  const regions = State.getStatesOfCountry(selectedCountry);
  const cities = City.getCitiesOfState(selectedCountry, selectedRegion);
  const [selectedImage, setSelectedImage] = useState(null);
  const imageInputRef = useRef(null);
  const [choosedImage, setChoosedImage] = useState(null);
  const [contactNumber, setContactNumber] = useState("");
  const [school, setSchool] = useState(null);

  const params = useParams();

  const getSchool = async () => {
    try {
      const { data } = await axios.get(
        `/schools/getschoolById/${params?.schoolId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setSchool(data?.school);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchool();
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
    setChoosedImage(file);
  };

  const handleImageRemove = () => {
    setSelectedImage(null);
    if (imageInputRef.current) {
      imageInputRef.current.value = null;
    }
  };

  const [personalNumber, setPersonalNumber] = useState("");
  const [telephone, setTelephone] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!personalNumber?.startsWith("+1")) {
      setPersonalNumber("+1");
    } else {
      setPersonalNumber(personalNumber);
    }
  }, [personalNumber]);

  useEffect(() => {
    if (!telephone?.startsWith("+1")) {
      setTelephone("+1");
    } else {
      setTelephone(telephone);
    }
  }, [telephone]);
  const [schoolData, setSchoolData] = useState({
    name: "",
    alternativeName: "",
    alternativeId: "",
    alternateSchool: "",
    schoolId: "",
    stateId: "",
    districtId: "",
    description: "",
    schoolLevel: "",
    classification: "",
    lowestGradeLevel: "",
    highestGradeLevel: "",
    openedOn: "",
    address1: "",
    address2: "",
    city: "",
    principal: "",
    assistantPrincipal: "",
    division: "",
    stateOrRegionOrProvince: "",
    district: "",
    email: "",
    country: "US",
    schoolClassification: "",
  });

  useEffect(() => {
    if (school) {
      setSchoolData({
        name: school?.name,
        alternativeName: school?.alternativeName,
        alternativeId: school?.alternativeId,
        alternateSchool: school?.alternateSchool,
        schoolId: school?.schoolId,
        stateId: school?.stateId,
        districtId: school?.districtId,
        schoolLevel: school?.schoolLevel,
        classification: school?.classification,
        lowestGradeLevel: school?.lowestGradeLevel,
        highestGradeLevel: school?.highestGradeLevel,
        openedOn: new Date(school?.openedOn).toISOString().split("T")[0],
        address1: school?.address1,
        address2: school?.address2,
        city: school?.city,
        principal: school?.principal,
        assistantPrincipal: school?.assistantPrincipal,
        division: school?.division,
        stateOrRegionOrProvince: school?.stateOrRegionOrProvince,
        district: school?.district,
        email: school?.email,
        country: school?.country,
        schoolClassification: school?.schoolClassification,
        description: school?.description,
      });
      setTelephone(String(school?.telephone));
      setPersonalNumber(String(school?.personalNumber));
      setSelectedCountry(school?.country);
      setSelectedRegion(school?.stateOrRegionOrProvince);
      setSelectedCity(school?.district);
    }
  }, [school]);
  const handleInputChange = (e) => {
    setSchoolData((prevData) => {
      return {
        ...prevData,
        [e.target.name]: e.target.value,
      };
    });
  };

  const { user } = useSelector((state) => state.getUserProfile);

  const handleAdd = async (e) => {
    e.preventDefault();

    try {
      const regex = /^(\+1\s?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
      if (!regex?.test(telephone) || !regex?.test(personalNumber))
        return toast.error("All Phone numbers must be valid");

      const newForm = new FormData();

      if (!selectedCity)
        return toast.error("'City' is not allowed to be empty");
      choosedImage && newForm.append("logo", choosedImage);
      newForm.append("name", schoolData.name);
      newForm.append("alternativeName", schoolData.alternativeName);
      newForm.append("alternativeId", schoolData.schoolId);
      newForm.append("schoolId", schoolData.schoolId);
      newForm.append("stateId", schoolData.stateId);
      newForm.append("districtId", schoolData.districtId);
      newForm.append("schoolLevel", schoolData.schoolLevel);
      newForm.append("description", schoolData.description);
      newForm.append("lowestGradeLevel", schoolData.lowestGradeLevel);
      newForm.append("highestGradeLevel", schoolData.highestGradeLevel);
      newForm.append("gender", schoolData.gender);
      newForm.append("openedOn", schoolData.openedOn);
      newForm.append("address1", schoolData.address1);
      newForm.append("address2", schoolData.address2);
      // newForm.append("city", schoolData.city);
      newForm.append("principal", schoolData.principal);
      newForm.append("assistantPrincipal", schoolData.assistantPrincipal);
      newForm.append("telephone", telephone);
      // newForm.append("division", schoolData.division);
      newForm.append("personalNumber", personalNumber);
      newForm.append("stateOrRegionOrProvince", selectedRegion);
      newForm.append("district", selectedCity);
      newForm.append("email", schoolData.email);
      newForm.append("country", selectedCountry);
      newForm.append("schoolClassification", schoolData.schoolClassification);
      newForm.append("adminId", user?.dataValues?.id);

      if (params?.schoolId) {
        await axios.put(`/schools/updateSchool/${params?.schoolId}`, newForm, {
          headers: {
            "Content-Type": "multipart/form-data",
            accessToken: `${localStorage.getItem("token")}`,
          },
        });

        setSuccess(true);
      } else {
        await axios.post("/schools/add", newForm, {
          headers: {
            "Content-Type": "multipart/form-data",
            accessToken: `${localStorage.getItem("token")}`,
          },
        });

        setSuccess(true);
      }
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details?.[0]?.detail ||
            error?.response?.data?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/SchoolList");
  };

  return (
    <form onSubmit={handleAdd}>
      {success && <CompleteModal link="/SchoolList" />}
      <div className="EDITALLMain">
        <div className="ALLEditMAIN">
          <div className="EditInformationMain">
            <div className="MainEDIT">
              {selectedImage ? (
                <div className="ImageContainer">
                  <img
                    src={selectedImage}
                    alt="Selected"
                    className="SelectedImage"
                  />
                  <div className="ChangeButton" onClick={handleImageRemove}>
                    Change Logo
                  </div>
                </div>
              ) : (
                <label htmlFor="imageInput" className="ImageContainer">
                  <input
                    type="file"
                    id="imageInput"
                    onChange={handleImageChange}
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={imageInputRef}
                  />
                  <img src={UploadImg} alt="#" className="EditLogo-Img" />
                  <p> School Logo</p>
                </label>
              )}
            </div>
            <h2>Higher secondary school</h2>
            <textarea
              className="AddSchoolDescription"
              name="description"
              placeholder="Description"
              onChange={handleInputChange}
              value={schoolData.description}
            />
          </div>
          <div className="EditInformation-Inputs">
            <h3>General Information</h3>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <label>School Name *</label>
                      <br />
                      <input
                        type="text"
                        name="name"
                        value={schoolData.name}
                        onChange={handleInputChange}
                        placeholder="_"
                        required
                      />
                    </td>
                    <td>
                      <label>Alternative Name</label>
                      <br />
                      <input
                        type="text"
                        name="alternativeName"
                        value={schoolData.alternativeName}
                        placeholder="_"
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <label>School ID *</label>
                      <br />
                      <input
                        type="text"
                        name="schoolId"
                        value={schoolData.schoolId}
                        placeholder="_"
                        onChange={handleInputChange}
                        required
                      />
                    </td>
                    <td>
                      <label>Alternative ID</label>
                      <br />
                      <input
                        type="text"
                        name="alternativeId"
                        value={schoolData.alternativeId}
                        placeholder="_"
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>State ID *</label>
                      <br />
                      <input
                        type="text"
                        name="stateId"
                        value={schoolData.stateId}
                        placeholder="_"
                        onChange={handleInputChange}
                        required
                      />
                    </td>
                    <td>
                      <label>District ID *</label>
                      <br />
                      <input
                        type="text"
                        name="districtId"
                        value={schoolData.districtId}
                        placeholder="_"
                        onChange={handleInputChange}
                        required
                      />
                    </td>
                    <td>
                      <label>School Level *</label>
                      <br />
                      <select
                        name="schoolLevel"
                        value={schoolData.schoolLevel}
                        onChange={handleInputChange}
                      >
                        <option value="">Select School Level</option>
                        <option value="collage">Preschool</option>
                        <option value="Kindergartento5thGrade(K-5)">
                          Kindergarten to 5th Grade (K-5)
                        </option>
                        <option value="MiddleSchool">Middle School</option>
                        <option value="High School">High school</option>
                        <option value="Undergraduate">Undergraduate</option>
                        <option value="Graduate">Graduate</option>
                        <option value="custom">
                          Custom (Lowest Grade Level - Highest Grade Level)
                        </option>
                      </select>
                    </td>
                    <td>
                      <label>School Classification *</label>
                      <br />
                      <select
                        name="schoolClassification"
                        value={schoolData.schoolClassification}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Classification</option>
                        <option value="militarySchool">Military School</option>
                        <option value="privateSchool">
                          Public Charter School
                        </option>
                        <option value="PrivateSchool">Private School</option>
                        <option value="PublicSchoolDistrict">
                          Public School District
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Lowest Grade Level *</label>
                      <br />
                      <select
                        name="lowestGradeLevel"
                        value={schoolData.lowestGradeLevel}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Lowest Grade</option>
                        <option value="kindergarten">Kindergarten</option>
                        <option value="Grade1">Grade 1</option>
                        <option value="Grade2">Grade 2</option>
                        <option value="Grade3">Grade 3</option>
                        <option value="Grade4">Grade 4</option>
                        <option value="Grade5">Grade 5</option>
                        <option value="Grade6">Grade 6</option>
                        <option value="Grade7">Grade 7</option>
                        <option value="Grade8">Grade 8</option>
                        <option value="Grade9">Grade 9</option>
                        <option value="Grade10">Grade 10</option>
                        <option value="Grade11">Grade 11</option>
                        <option value="Grade12">Grade 12</option>
                      </select>
                    </td>
                    <td>
                      <label>Highest Grade Level *</label>
                      <br />
                      <select
                        name="highestGradeLevel"
                        value={schoolData.highestGradeLevel}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Higest Grade</option>
                        <option value="kindergarten">Kindergarten</option>
                        <option value="Grade 1">Grade 1</option>
                        <option value="Grade 2">Grade 2</option>
                        <option value="Grade 3">Grade 3</option>
                        <option value="Grade 4">Grade 4</option>
                        <option value="Grade 5">Grade 5</option>
                        <option value="Grade 6">Grade 6</option>
                        <option value="Grade 7">Grade 7</option>
                        <option value="Grade 8">Grade 8</option>
                        <option value="Grade 9">Grade 9</option>
                        <option value="Grade 10">Grade 10</option>
                        <option value="Grade 11">Grade 11</option>
                        <option value="Grade 12">Grade 12</option>
                      </select>
                    </td>
                    <td>
                      <label>Date School Opened *</label>
                      <br />
                      <input
                        type="date"
                        name="openedOn"
                        value={schoolData.openedOn}
                        placeholder="_"
                        onChange={handleInputChange}
                        required
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="EditInformationChils-2">
          <div>
            <h1>Address Information</h1>
            <form>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <label>Street Address 1 *</label>
                      <br />
                      <input
                        type="text"
                        name="address1"
                        value={schoolData.address1}
                        placeholder="_"
                        onChange={handleInputChange}
                        required
                      />
                    </td>
                    <td>
                      <label>Street address 2</label>
                      <br />
                      <input
                        type="text"
                        name="address2"
                        value={schoolData.address2}
                        placeholder="_"
                        onChange={handleInputChange}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    {" "}
                    <td>
                      <label>Country *</label>
                      <br />
                      <select
                        value={selectedCountry}
                        onChange={(e) => handleCountryChange(e.target.value)}
                      >
                        <option value="">Select Country</option>
                        {countries.map((country) => (
                          <option key={country.isoCode} value={country.isoCode}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <label>State/Region/Province *</label>
                      <br />
                      {selectedCountry && (
                        <select
                          value={selectedRegion}
                          onChange={(e) => handleRegionChange(e.target.value)}
                        >
                          <option value="">Select Region</option>
                          {regions.map((region) => (
                            <option key={region.isoCode} value={region.isoCode}>
                              {region.name}
                            </option>
                          ))}
                        </select>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>City *</label>
                      <br />
                      {selectedRegion && (
                        <select
                          value={selectedCity}
                          onChange={(e) => handleCityChange(e.target.value)}
                        >
                          <option value="">Select City</option>
                          {cities.map((city) => (
                            <option key={city.id} value={city.name}>
                              {city.name}
                            </option>
                          ))}
                        </select>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
          <div className="EDITSCHOOLINPUTS">
            <h1>Contact Information</h1>
            <form>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <label>Principal *</label>
                      <br />
                      <input
                        type="text"
                        name="principal"
                        value={schoolData.principal}
                        placeholder="_"
                        onChange={handleInputChange}
                        required
                      />
                    </td>
                    <td>
                      <label>Assistant Principal</label>
                      <br />
                      <input
                        type="text"
                        name="assistantPrincipal"
                        value={schoolData.assistantPrincipal}
                        placeholder="_"
                        onChange={handleInputChange}
                        required
                      />
                    </td>
                    <td>
                      <label>Telephone *</label>
                      <br />
                      <PhoneInput
                        placeholder="_"
                        value={telephone}
                        onChange={setTelephone}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Personal Number *</label>
                      <br />
                      <PhoneInput
                        placeholder="_"
                        value={personalNumber}
                        onChange={setPersonalNumber}
                      />
                    </td>
                    <td>
                      <label>Email *</label>
                      <br />
                      <input
                        type="text"
                        name="email"
                        value={schoolData.email}
                        placeholder="_"
                        onChange={handleInputChange}
                        className="EditEmailInput"
                        required
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>

        <div className="EDITTWObtns">
          <button className="EDITTWObtns-one" onClick={handleClick}>
            Cancel
          </button>
          <button className="EDITTWObtns-two" type="submit">
            Add
          </button>
        </div>
      </div>
    </form>
  );
}

export default EditSchoolInfo;
