import React, { createContext, useMemo, useContext } from "react";
import { useSelector } from "react-redux";
import { baseURL } from "./constants";
import { io } from "socket.io-client";

const SocketContext = createContext();

// This is now a custom hook.
const useSocket = () => {
  return useContext(SocketContext);
};

const SocketProvider = ({ children }) => {
  const { user } = useSelector((state) => state.getUserProfile);

  const socket = useMemo(
    () =>
      io(baseURL, {
        auth: {
          token: localStorage.getItem("token"),
          role: user?.role,
        },
      }),
    []
  );

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export { SocketProvider, useSocket };
