import React, { useEffect, useState } from "react";
import "./Plan.css";
import { IoMdArrowDropdown } from "react-icons/io";
import print from "../Medical/flat-color-icons_print.png";
import axios from "axios";
import { baseURL } from "../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

function Plan() {
  const [plan, setPlan] = useState(null);
  function handlePrint() {
    window.print();
  }
  const { studentId } = useSelector((state) => state.studentId);

  const getPlan = async () => {
    try {
      const { data } = await axios.get(
        `/plans/getPlansByStudentId/${studentId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(data, "this is data");
      setPlan(data?.plan);
    } catch (error) {
      console.log(error, "yah naa");
    }
  };

  useEffect(() => {
    getPlan();
  }, []);

  return (
    <div className="Plan-Main">
      <div className="Plan-Top">
        <h3>
          504 Plan{" "}
          <span>
            7/14/2023 - 8/4/2023 <IoMdArrowDropdown />
          </span>
        </h3>
        <button onClick={handlePrint}>
          <img src={print} alt="#" />
          Print the plan
        </button>
      </div>
      <div className="Plan-Body">
        <h3>Plan Details</h3>
        <div className="Plan-Body-Child">
          <h4>
            Effective Date: <span>{plan?.effectiveDate?.split("T")[0]}</span>
          </h4>
          <h4>
            Next Review Date: <span>{plan?.nextReviewDate?.split("T")[0]}</span>
          </h4>
          <h4>
            Leave Date: <span>{plan?.leaveDate?.split("T")[0]}</span>
          </h4>
        </div>
        <div className="Plan-Body-Child1">
          <h4>
            Meeting Type: <span>{plan?.meetingType}</span>
          </h4>
          <h4>
            Meeting Date: <span>{plan?.meetingDate?.split("T")[0]}</span>
          </h4>
        </div>
        <div className="Plan-Body-Child1">
          <h4>
            Consent to Evaluate Received:{" "}
            <span>{plan?.Consent ? "Yes" : "No"}</span>
          </h4>
          <h4>
            Received Date: <span>{plan?.EvaluateReceived?.split("T")[0]}</span>
          </h4>
        </div>
        <div className="Plan-Body-Child1">
          <h4>
            Rights & Safeguards Delivered: <span>{plan?.Right}</span>
          </h4>
          <h4>
            Date Delivered:{" "}
            <span>{plan?.SafeguardsDelivered?.split("T")[0]}</span>
          </h4>
        </div>
        <div className="Plan-Body-Child1">
          <h4>
            Authorization for Exchange of Information:{" "}
            <span>{plan?.Authorization}</span>
          </h4>
          <h4>
            Date Received:{" "}
            <span>{plan?.ExchangeofInformation?.split("T")[0]}</span>
          </h4>
        </div>
        <h4>
          Description of Disability: <span>{plan?.Description}</span>
        </h4>
        <h4>
          Team Decision: <span>{plan?.TeamDecision}</span>
        </h4>
        <h4>
          Comments: <span>{plan?.Comment}</span>
        </h4>
        <h4>
          Referrer: <span>{plan?.Referrer}</span>
        </h4>
      </div>
    </div>
  );
}

export default Plan;
