import React, { useEffect, useState } from "react";
import "./Myprofile.css";
import victor1 from "./Group 1000006077.png";
import victor2 from "./Group 1000006078.png";
import victor3 from "./Group 1000006079.png";
import victor4 from "./Group 1000006080.png";
import TwoTabs from "./MyProfileTwoTabs/TwoTabs";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../redux/actions/userAction";
import Dummy from "../../AdminComponents/images/dummy-user.png";
import axios from "axios";
import { baseURL } from "../../../constants";
axios.defaults.baseURL = baseURL;

const Myprofile = () => {
  const [profile, setProfile] = useState({});
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);


  const getStudentProfile = async () => {
    try {
      if (user) {
        let { data } = await axios.get(
          `/students/profile/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setProfile(data.student);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStudentProfile();
  }, [user]);
   
  const [averageGrade, setAverageGrade] = useState("")

  const getGrade = async () => {
    try {
      const { data } = await axios.get(`/grades/calculateGPA/${user?.dataValues?.id}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        }
      })

      setAverageGrade(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
  getGrade()
  }, [user])

  const [assingment, setAssingment] = useState([])

  const getAssingment = async() =>{
    try{
      const {data} = await axios.get(`/assignments/getAssignmentCountByStudent/${user?.dataValues?.id}`,{
        headers:{
          accessToken: `${localStorage.getItem("token")}`
        }
      })  
      setAssingment(data)
    }
    catch (error){
      console.log(error)
    }
  }
  useEffect(()=>{
    getAssingment()
  },[user])

  const [completed, setComplted] = useState([])

  const getCompleted = async () =>{
    try{
      const {data} = await axios.get(`/assignmentSubmissions/getSubmittedAssignmentsCountByStudent/${user?.dataValues?.id}`,{
        headers:{
          accessToken:`${localStorage.getItem("token")}`
        }
      })
      setComplted(data)
    }
    catch(error){
      console.log(error)
    }
  }
  useEffect(()=>{
    getCompleted()
  }, [user])
  return (
    <div className="Myprofile-main">
      <h1>My Profile</h1>
      <div className="Myprofile-main-1">
        <div className="Myprofile-main-child-1">
          <div className="profile">
            <div className="profile-child-1">
              <img
                style={{ borderRadius: "100%", objectFit: "contain" }}
                src={`${baseURL}/images/${profile?.profilePicture}`}
                onError={({ currentTarget }) => {
                  currentTarget.src = Dummy;
                }}
                alt="#"
              />

            </div>
            <div className="profile-child-2">
              <div className="profile-child-2-1">
                <h2>{profile?.firstName + " " + profile?.lastName}</h2>
              </div>
              <div className="profile-child-2-2">
                <p>{profile && "BSSE"}</p>
                <button>
                  <span>●</span> {profile.online ? "Online" : "Offline"}
                </button>
              </div>
              <div className="profile-child-2-3">
                <p>{profile?.loggedInSince?.split("T")[0]}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="Myprofile-main-child-2">
          <div className="Myprofile-main-child-2-1">
            <img src={victor1} alt="#" />{" "}
            <h3>{profile?.createdAt?.split("T")[0]}</h3>
          </div>
          <div className="Myprofile-main-child-2-2">
            <div className="div-1">
              <img src={victor2} alt="#" />
              <h3>
                Assignments <span>{assingment?.assignmentCount}</span>
              </h3>
            </div>
            <div className="div-2">
              <img src={victor3} alt="#" />
              <h3>
                Completed <span>{completed?.submittedAssignmentsCount}</span>
              </h3>
            </div>
          </div>
        </div>
        <div className="Myprofile-main-child-3">
          <div className="Myprofile-main-child-3-card-1">
            <img src={victor4} alt="#" />
          </div>
          <div className="Myprofile-main-child-3-card-2">
            <h3>GPA</h3>
            <h1>{averageGrade?.gpa}</h1>
          </div>
        </div>
      </div>
      <TwoTabs getStudentProfile={getStudentProfile} profile={profile} />
    </div>
  );
};

export default Myprofile;