import React, { useState, useEffect } from "react";
import "../ParentTuitionInfo/TuitionInfo.css";
import axios from "axios";
import Dummy from "../../AdminComponents/images/dummy-user.png";
import { baseURL } from "../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

function ParentStuInfo() {
  const [studentProfile, setStudentProfile] = useState(null);

  const { studentId } = useSelector((state) => state.studentId);

  const GetParentData = async () => {
    try {
      const { data } = await axios.get(`/students/profile/${studentId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setStudentProfile(data?.student);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetParentData();
  }, [studentId]);

  return (
    <>
      <div className="TeacherStudentProfile">
        <div className="studentInfo1">
          <img
            src={`${baseURL}/images/${studentProfile?.profilePicture}`}
            alt="profilePicture"
            onError={({ currentTarget }) => {
              currentTarget.src = Dummy;
            }}
          />
          <div>
            {/* <h1>
              {parentProfile?.parent?.firstName}{" "}
                {parentProfile?.parent?.middleName}{" "}
                {parentProfile?.parent?.lastName}
            </h1> */}
            <h2>
              Student ID:
              <span>{studentProfile?.studentId}</span>
            </h2>
          </div>
        </div>
        <div className="studentInfo">
          <h2>
            Father Name : <span>{studentProfile?.fatherName}</span>
          </h2>
          {/* <h2>
            Session:
            <span>{studentProfile?.session}</span>
          </h2>
          <h2>
            Degree :<span>{studentProfile?.program}</span>
          </h2> */}
        </div>
      </div>
    </>
  );
}

export default ParentStuInfo;
