import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./ModalChat.css";
import NewMessage from "../Vector.png";
import { MdOutlineMessage } from "react-icons/md";

function ModalChat() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="ModalChat-main">
      <button className="ModalChat-mainbtn" onClick={handleShow}>
        <MdOutlineMessage />
        RequetsChat
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        className="ModalChat-body"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h3>Request For Message</h3>
        </Modal.Header>
        <Modal.Body className="ModalChatMain-Body">
          <div className="New_main">
            <img src={NewMessage} />
          </div>
          <h2>Request Teacher for chat ticket</h2>
          <p>
            when Teacher will accept your request then <br />
            you will Chat Directly to teacher
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="CancelBtn" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="ReqBtn" onClick={handleClose}>
            Request For message
          </Button>

        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalChat;
