import { createReducer } from "@reduxjs/toolkit";

const initialStates = {
  events: [],
};

const eventsReducer = createReducer(initialStates, (builder) => {
  builder
    .addCase("addEventRequest", (state) => {
      state.loading = true;
    })
    .addCase("addEventSuccess", (state, action) => {
      state.loading = false;
      state.message = action.payload;
    })
    .addCase("addEventFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("deleteEventRequest", (state) => {
      state.loading = true;
    })
    .addCase("deleteEventSuccess", (state, action) => {
      state.loading = false;
      state.message = action.payload;
    })
    .addCase("deleteEventFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("getEventRequest", (state) => {
      state.loading = true;
    })
    .addCase("getEventSuccess", (state, action) => {
      state.loading = false;
      state.message = action.payload;
    })
    .addCase("getEventFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("updateEventRequest", (state) => {
      state.loading = true;
    })
    .addCase("updateEventSuccess", (state, action) => {
      state.loading = false;
      state.message = action.payload;
    })
    .addCase("updateEventFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("getEventsRequest", (state) => {
      state.loading = true;
    })
    .addCase("getEventsSuccess", (state, action) => {
      state.loading = false;
      state.events = action.payload;
    })
    .addCase("getEventsFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("clearError", (state) => {
      state.error = null;
    })
    .addCase("clearMessage", (state) => {
      state.message = null;
    });
});

export default eventsReducer;
