import React, { useState, useEffect } from "react";
import { ImRadioChecked } from "react-icons/im";
import { assingmentsDataSub } from "../data/MyscheduleData";
import axios from "axios";
import { IoArrowBackSharp, IoEyeOutline, IoSearch } from "react-icons/io5";
import Dummy from "../../AdminComponents/images/dummy-user.png";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import Slider from "@mui/material/Slider";
// import { Slider } from "primereact/slider";
import "./MyassigmentMain.css";
import { AiOutlineClockCircle } from "react-icons/ai";
import Modal from "@mui/material/Modal";
import { Dropdown, Form } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import toast from "react-hot-toast";
import happy from "../images/happy.png";
import { baseURL } from "../../../constants";
import { RiEqualizerFill } from "react-icons/ri";
import { useParams } from "react-router-dom";
axios.defaults.baseURL = baseURL;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const MyassigmentMain = ({ data = [], status = "" }) => {
  const [percentage, setPercentage] = useState(70);
  const [testId, setTestId] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setTestId("");
  };
  const [showTest, setShowTest] = useState(false);
  const handleSliderChange = (event, newValue) => {
    setPercentage(newValue);
  };
  function valuetext(value) {
    return `${value}%`;
  }
  console.log(data, "this is my datatas");
  const handleTakeTest = (id, status) => {
    if (status === "active") {
      setTestId(id);
    }
  };

  useEffect(() => {
    if (testId) {
      setShowTest(true);
    } else {
      setShowTest(false);
    }
  }, [testId]);

  const [myTests, setMyTests] = useState();
  const [testing, setTesting] = useState(null);

  const getTesting = async () => {
    try {
      const { data } = await axios.get(`/quizs/getQuizByTestId/${testId}`, {
        headers: {
          accessToken: localStorage.getItem("token"),
        },
      });
      setTesting(data);
    } catch (error) {
      console.log(error);
    }
  };
  const getMyTest = async () => {
    try {
      const { data } = await axios.get(
        `/StudentTests/getQuestionsByTestId/${testId}`,
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );
      setMyTests(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (testId) {
      getTesting();
      getMyTest();
    }
  }, [testId]);

  const [student, setStudent] = useState(null);

  const { user } = useSelector((state) => state.getUserProfile);

  const getStudent = async () => {
    try {
      const { data } = await axios.get(
        `/students/profile/${user?.dataValues?.id}`,
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );
      setStudent(data?.student, "this is data student");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStudent();
  }, []);

  const [takenTest, setTakenTest] = useState({});
  const handleTests = (questionId, optionId) => {
    console.log(questionId, optionId);

    setTakenTest((prev) => {
      return {
        ...prev,
        [questionId]: optionId,
      };
    });
  };

  const [school, setSchool] = useState("");

  const getSchool = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/ParentBySchoolId/getschoolBystudentId/${user?.dataValues?.id}`,
          {
            headers: {
              accessToken: localStorage.getItem("token"),
            },
          }
        );
        setSchool(data?.school);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchool();
  }, [user]);

  const handleSubmitTest = async () => {
    try {
      const { data } = await axios.post(
        `/StudentTests/submitPaper/${localStorage.getItem("classroomId")}`,
        {
          testId: testId,
          studentId: user?.dataValues?.id,
          answers: takenTest,
          teacherId: localStorage.getItem("tId"),
          schoolId: Number(school?.id),
        },
        {
          headers: {
            accessToken: localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Test Submitted Successfully");
      handleOpen();
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const [subject, setSubject] = useState(null);
  const { subjectId } = useParams();
  const getSubject = async () => {
    try {
      const { data } = await axios.get(
        `/subjects/getSubjectById/${subjectId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setSubject(data?.subject);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSubject();
  }, [user]);

  return (
    <>
      <div className="myassingment-main">
        {!testId && (
          <div className="StudeFilter">
            <div className="myassingment-child-1">
              <div className="myassingment-radio-btn">
                <span></span>
                <p>OverDue</p>
                <ImRadioChecked
                  style={{ color: "#f93333", width: "19px", height: "19px" }}
                />
              </div>
              <div className="myassingment-radio-btn">
                <span></span>
                <p>Due Soon</p>
                <ImRadioChecked
                  style={{ color: "#ff7e3e", width: "19px", height: "19px" }}
                />
              </div>
              <div className="myassingment-radio-btn">
                <span></span>
                <p>Submitted</p>
                <ImRadioChecked
                  style={{ color: "#16d03b", width: "19px", height: "19px" }}
                />
              </div>
              <div className="myassingment-radio-btn">
                <span></span>
                <p>New</p>
                <ImRadioChecked
                  style={{ color: "#00afef", width: "19px", height: "19px" }}
                />
              </div>
            </div>
            <div className="NewDropDown">
              <Dropdown>
                <div className="StudeFilterBtn">
                  <button className="StudeDropBtn">
                    <IoSearch />
                    <input type="text" placeholder="Search" />
                  </button>
                  <Dropdown.Toggle id="dropdown-basic">
                    <div className="StudeDropDown">
                      <button className="StudeDropBtn1">
                        <RiEqualizerFill />
                        Filters
                      </button>
                    </div>
                  </Dropdown.Toggle>
                </div>
                <Dropdown.Menu>
                  <div className="NewDropDownBody">
                    <h2>Categories </h2>
                    <div className="NewBody">
                      <div className="NewBodyChild">
                        <Form.Check aria-label="option 1" />
                        <label>Tests</label>
                      </div>
                      <div className="NewBodyChild">
                        <Form.Check aria-label="option 1" />
                        <label>Projects</label>
                      </div>
                    </div>
                    <div className="NewBody">
                      <div className="NewBodyChild">
                        <Form.Check aria-label="option 1" />
                        <label>Quizzes</label>
                      </div>
                    </div>
                    <div className="NewBody">
                      <div className="NewBodyChild">
                        <Form.Check aria-label="option 1" />
                        <label>OHT</label>
                      </div>
                      <div className="NewBodyChild">
                        <Form.Check aria-label="option 1" />
                        <label>Extra Credit</label>
                      </div>
                    </div>
                    <hr />
                    <div className="NewBody">
                      <div className="NewBodyChild">
                        <Form.Check aria-label="option 1" />
                        <label>Exams</label>
                      </div>
                    </div>
                    <div className="NewDescriptions">
                      <h6>Other</h6>
                      <input type="text" placeholder="Description goes here" />
                      <button className="NeButtons">Filter</button>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        )}
        <div>
          {data &&
            !testId &&
            data?.tests?.map((assingment, index) => {
              const newIndex = index % assingmentsDataSub.length;

              return (
                <div
                  className="myassingment-child-2"
                  key={index}
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    localStorage.setItem("tId", assingment?.teacherId);
                    handleTakeTest(assingment?.id, assingment?.status);
                  }}
                >
                  <div
                    className="myassingment-card"
                    style={{
                      backgroundColor: assingmentsDataSub[newIndex]?.bcColor,
                      borderColor:
                        assingmentsDataSub[newIndex]?.mainbordercolor,
                    }}
                  >
                    <div className="myassingment-card-child-1">
                      <div className="myassingment-child">
                        <div className="myassingment-child-data">
                          <div className="myassingment-child-date">
                            <p
                              className="date"
                              style={{
                                backgroundColor:
                                  assingmentsDataSub[newIndex]?.bgColor,
                              }}
                            >
                              {assingment?.id}
                            </p>
                          </div>
                          <div className="mychild-myassingment">
                            <h6>{assingment?.testName}</h6>
                            <div className="myassingment-time">
                              <div className="myassingment-mother">
                                <p>{assingment.time}</p>
                                <p>{assingment?.dueOn?.split("T")[0]}</p>
                                <span
                                  style={{
                                    color:
                                      assingmentsDataSub[newIndex]
                                        ?.spandotColor,
                                  }}
                                >
                                  ●
                                </span>
                                <p>{assingment?.assignedOn?.split("T")[0]}</p>
                              </div>
                              <p style={{ fontSize: "8px" }}></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="myassingment-card-child-2">
                      <div className="description">
                        <h5
                          style={{
                            color:
                              assingmentsDataSub[newIndex]?.descriptionColor,
                          }}
                        >
                          {assingment?.description}
                        </h5>
                        {/* <p>here is the Assingment Detail Description</p> */}
                      </div>
                      <div className="dov">
                        <button
                          style={{
                            color: assingmentsDataSub[newIndex]?.bgColor,
                            borderColor:
                              assingmentsDataSub[newIndex]?.buttonBgColor,
                          }}
                        >
                          {assingment?.status}
                          {assingment?.TestSubmissions &&
                            assingment?.TestSubmissions[0]?.status}
                          {status == "Upcoming" ? "Upcoming" : ""}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {testId && (
        <div className="TestResult-Main">
          <div className="TestREsultHead">
            <IoArrowBackSharp onClick={() => setTestId("")} />
            <h3>Test Result Details</h3>
          </div>
          <div className="TestProfile">
            <img
              src={`${baseURL}/images/${student?.profilePicture}`}
              onError={({ currentTarget }) => {
                currentTarget.src = Dummy;
              }}
            />
            <div className="TestStuName">
              <h5>
                {student?.firstName} {student?.middleName} {student?.lastName}
              </h5>
              <p>
                Student ID : <span>{student?.studentId}</span>
              </p>
              <h4>
                <IoEyeOutline /> View Profile
              </h4>
            </div>
          </div>
          <div className="TestStudy">
            {/* <h4>Articulate structure of C++ and Java in Semester 1</h4>
          <h4>
            Course <span>B.Tech Spcl. in Health Informatics</span>
          </h4> */}
            <h4>
              Subject: <span>{subject?.subjectName}</span>
            </h4>
          </div>

          <div className="TestTabs">
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
              <Tab eventKey="home" title="Details">
                <div className="TestDetails">
                  <h2>Number of Questions : {testing?.numberOfQuestions}</h2>
                  <div className="CreateCard">
                    <div className="CreatePercentage">
                      <p>Passing Percentage</p>
                      <h6>{testing?.passingPercentage}%</h6>
                    </div>
                    <Box sx={{ width: 300 }}>
                      <Slider
                        // disabled
                        aria-label="Percentage"
                        value={testing?.passingPercentage}
                        // getAriaValueText={valuetext}
                        // valueLabelDisplay="auto"
                        min={0}
                        max={100}
                      />
                    </Box>
                    {/* <Slider value={testing?.passingPercentage}  className="w-14rem" /> */}
                    <h4>Quiz Schedule</h4>
                    <div className="CreteTIME">
                      {testing?.date && (
                        <input
                          value={
                            new Date(testing?.date).toISOString().split("T")[0]
                          }
                          type="date"
                        />
                      )}
                      {testing?.time && (
                        <input value={testing?.time} type="time" />
                      )}
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="profile" title="Start The Test">
                <div className="TestResQuestion">
                  <div className="TestResTop">
                    <div className="TestResGreen">
                      <p>
                        when you leave this page The Test Automatically Mark As
                        Complete{" "}
                      </p>
                    </div>
                    <div className="TestResTime">
                      <p>Time Start </p>
                      <span>
                        <AiOutlineClockCircle />
                        12:00
                      </span>
                    </div>
                  </div>
                  <h4>Choose the correct Option</h4>
                  <hr />
                  <div className="TestAllQuestions">
                    {myTests &&
                      myTests?.map((test, index) => {
                        return (
                          <div className="TestQuestions">
                            <h5>Q{index + 1}.</h5>
                            <h5>{test?.questionText}</h5>
                            {test?.Options?.map((opt) => {
                              return (
                                <div
                                  style={{
                                    backgroundColor:
                                      takenTest[test?.id] === opt?.id
                                        ? "#00AFEF33"
                                        : "white",
                                  }}
                                  onClick={() => handleTests(test?.id, opt?.id)}
                                  className="TestOptions"
                                >
                                  <p>{opt?.optionText}</p>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                  </div>
                </div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} className="Neted_Modalll">
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <div className="TestSubModal">
                        <img src={happy} />
                        <h3>Your test successfully submitted!</h3>
                        <button onClick={handleClose} className="TestSubmitBTN">
                          Done
                        </button>
                      </div>
                    </Typography>
                  </Box>
                </Modal>
                <div className="TestSubmitButton">
                  <button
                    onClick={() => {
                      handleSubmitTest();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      )}
    </>
  );
};
export default MyassigmentMain;
