import React from "react";
import group_ui from "../../AdminComponents/ADDNestedMain/detailModal/Group 1000006052.png";

const Notice = () => {
  return (
    <div className="NoticeTeacher">
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <div className="School-Calander-main-child-2">
          <div className="School-Calander-main-child-2-child-1 ThreeDOtsNotice">
            <h1>Important</h1>
          </div>

          <div className="School-Calander-main-child-2-child-2">
            <div className="school-calander-child-2-data">
              <p>
                “ School starts on january 13th, and roll will be taken daily at
                the start of each student group's instructional time. If you
                have any questions, please contact your student's school office
                or email us at mycentrality21 “
              </p>
            </div>
            <div>
              <div className="bosdii-lala-2">
                <img src={group_ui} alt="" />
                <p>Visible from December 29, 2023 - December 31, 2024</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notice;
