import React from 'react'
import './Calanderwork.css'
import { Link } from 'react-router-dom';
import { MdOutlineNavigateNext } from "react-icons/md";

const Calanderwork = () => {
  return (
    <>
      <div className='gpaCaculation'>
        <table >
          <thead>
            <tr className='backnonetr'>
              <th  className='backnone' colSpan={2}>
              <p>Standard Grade Point Scale: A = 4.0</p>
              </th></tr>
            <tr>
              <th>Grade</th>
              <th>Unweighted Grade Points</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='DACKGROUNG'>A+</td>
              <td className='DACKGROUNG'>4.0</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>A</td>
              <td>4.0</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>A-</td>
              <td>3.7</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>B+</td>
              <td>3.3</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>B</td>
              <td>3.0</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>B-</td>
              <td>2.7</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>C+</td>
              <td>2.3</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>C</td>
              <td>2</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>C-</td>
              <td>1.7</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>D+</td>
              <td>1.3</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>D</td>
              <td>1.0</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>D-</td>
              <td>0.7</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>F</td>
              <td>0.0</td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr><th colSpan={2}  className='backnone'><p>Honors Grade Point Scale: A = 4.5</p></th></tr>
            <tr>
              <th className='DACKGROUNG'>Grade</th>
              <th className='DACKGROUNG'>Weighted Grade Points</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='DACKGROUNG'>A+</td>
              <td>4.5</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>A</td>
              <td>4.5</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>A-</td>
              <td>4.2</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>B+</td>
              <td>3.8</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>B</td>
              <td>3.5</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>B-</td>
              <td>3.2</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>C+</td>
              <td>2.8</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>C</td>
              <td>2.5</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>C-</td>
              <td>2.2</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>D+</td>
              <td>1.8</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>D</td>
              <td>1.5</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>D-</td>
              <td>1.2</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>F</td>
              <td>0.0</td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr><th colSpan={2}  className='backnone'><p>AP Grade Point Scale: A = 5.0</p></th></tr>
            <tr>
              <th>Grade</th>
              <th>unweighted Grade Point</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='DACKGROUNG'>A+</td>
              <td>5.0</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>A</td>
              <td>5.0</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>A-</td>
              <td>4.7</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>B+</td>
              <td>4.3</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>B</td>
              <td>4.0</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>B-</td>
              <td>3.7</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>C+</td>
              <td>3.3</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>C</td>
              <td>3.0</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>C-</td>
              <td>2.7</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>D+</td>
              <td>2.3</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>D</td>
              <td>2.0</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>D-</td>
              <td>1.7</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>F</td>
              <td>0.0</td>
            </tr>
          </tbody>
        </table>
        <table className='tableFullwidth'>
          <thead>
            <tr><th colSpan={6}  className='backnone'><p>AP Grade Point Scale: A = 5.0</p></th></tr>
            <tr>
              <th>Course</th>
              <th>Creadit Hours</th>
              <th>Grade</th>
              <th>Scale</th>
              <th>Grade Points</th>
              <th>Quality Point</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='DACKGROUNG'>CHEMISTRY 102</td>
              <td>3</td>
              <td>B+</td>
              <td>Std</td>
              <td>3.3</td>
              <td>(3 × 3.3) = 9.9</td>
            </tr>
            <tr>
              <td className='DACKGROUNG'>CHEMISTRY LAB</td>
              <td>1</td>
              <td>A</td>
              <td>Std</td>
              <td>4.0</td>
              <td>(1 × 4.0) = 4.0</td>
            </tr><tr>
              <td className='DACKGROUNG'>PHYSICS H</td>
              <td>3</td>
              <td>B-</td>
              <td>Hon</td>
              <td>3.2</td>
              <td>(3 × 3.2) = 9.6</td>
            </tr><tr>
              <td className='DACKGROUNG'>CALCULUS</td>
              <td>4</td>
              <td>C</td>
              <td>AP</td>
              <td>3.0</td>
              <td>(4 × 3.0) = 12.0</td>
            </tr><tr>
              <td className='DACKGROUNG'>HISTORY 101</td>
              <td>4</td>
              <td>A</td>
              <td>Hon</td>
              <td>4.5</td>
              <td>(4 × 4.5) = 18.0</td>
            </tr><tr>
              <td className='DACKGROUNG'>GOLF 2000</td>
              <td>2*</td>
              <td>P</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
            </tr><tr>
              <td className='DACKGROUNG'>TOTAL</td>
              <td>15</td>
              <td></td>
              <td></td>
              <td></td>
              <td>53.5</td>
            </tr><tr>
              <td className='DACKGROUNG'>GPA</td>
              <td>53.5/15 =</td>
              <td>3.7 GPA</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='readMore'>
      <p>GPA Calculator Instructions:</p> 
        <Link to="/Readmore">
          <span>Click Here</span>
          <MdOutlineNavigateNext />
        </Link>
      </div>
    </>
  )
}

export default Calanderwork
