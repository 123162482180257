import React, { useState, useEffect } from "react";
import "../../StudentComponents/TopSearchBar/TopSearchBar.css";
import "./ParentPortalTopSearchBar.css";
import { IoSearch } from "react-icons/io5";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import ParentPortalNotification from "../ParentPortalNotification/ParentPortalNotifcation";
import ParentPortalLogoutModal from "../ParentPortalModal/ParentPortalLogoutModal";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Shoaib from "../../StudentComponents/images/Shoaib.png";
import axios from "axios";
import { baseURL } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import Dummy from "../../AdminComponents/images/dummy-user.png";
import {
  getParentStudents,
  getStudentId,
} from "../../../redux/actions/userAction";
import { Link } from "react-router-dom";
import Feedback from "../Feedback/Feedback";
axios.defaults.baseURL = baseURL;

function ParentPortalTopSearchBar() {
  const [notificationClicked, setNotificationClicked] = useState(false);
  // const [students, setStudents] = useState(null);

  const { loading, students, error } = useSelector((state) => state.user);

  console.log(students, "these are stss");
  const dispatch = useDispatch();

  const { studentId: currentStudentId } = useSelector(
    (state) => state.studentId
  );

  console.log(currentStudentId, "Juuuy bay");

  const { user } = useSelector((state) => state.getUserProfile);

  console.log(user?.dataValues?.id, "juu yH");
  useEffect(() => {
    if (user) {
      dispatch(getParentStudents(user?.dataValues?.id));
    }
  }, [user]);

  // const GetParentData = async () => {
  //   try {
  //     const { data } = await axios.get(
  //       `/students/getStudentsByParentId/${
  //         JSON.parse(localStorage.getItem("user"))?.id
  //       }`
  //     );
  //     setStudents(data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   GetParentData();
  // }, []);

  const st = [
    {
      name: "Aamir",
      image: Shoaib,
    },
    {
      name: "Rohan",
      image: Shoaib,
    },
    {
      name: "Ali",
      image: Shoaib,
    },
  ];

  const [allStudents, setAllStudents] = useState([]);

  const [currentStudent, setCurrentStudent] = useState({
    name: "",
    image: "",
  });

  console.log(students, "these six");

  const [studentId, setStudentId] = useState(null);

  console.log(students, "these are success student");
  useEffect(() => {
    if (currentStudentId && students) {
      const student = students.find((st) => st.studentId == currentStudentId);

      setCurrentStudent({
        name: student?.Student?.firstName || "Name",
        image: `${baseURL}/images/${student?.Student?.profilePicture}`,
      });
    } else if (students && students?.length > 0) {
      setCurrentStudent({
        name: students?.Student?.[0]?.firstName || "Name",
        image: `${baseURL}/images/${students?.[0]?.Student?.profilePicture}`,
      });
      handleStoreId(students[0]?.studentId);
    }
  }, [students, currentStudentId]);

  const handleStoreId = (id) => {
    dispatch(getStudentId(id));
  };

  return (
    <div className="TopSearchBar-main ParentPortalTopSearchBar">
      <div className="dropdown">
        <div className="dropdown">
          {students && students?.length > 0 ? (
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <>
                <img
                  src={currentStudent.image}
                  alt="userName"
                  onError={({ currentTarget }) => {
                    currentTarget.src = Dummy;
                  }}
                />
                <span className="userName">{currentStudent.name}</span>
              </>
            </button>
          ) : (
            <p>No Students</p>
          )}
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {students &&
              students?.map((item) => {
                return (
                  <li
                    onClick={() => {
                      handleStoreId(item?.studentId);
                    }}
                  >
                    <a className="dropdown-item" href="#">
                      <img
                        src={`${baseURL}/images/${item?.Student?.profilePicture}`}
                        alt="User"
                        onError={({ currentTarget }) => {
                          currentTarget.src = Dummy;
                        }}
                      />
                      {item?.Student?.firstName}
                    </a>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      <div className="TopSearchBar-one">
        <IoSearch />
        <input placeholder="Search... " />
      </div>
      <div className="TopSearchBar-two">
        <div className="TopSearchBar-Alpha">
          <Link to="/ParentChat" className="icon-container">
            <ChatOutlinedIcon />
          </Link>
          <div
            className={`icon-container1 ${
              notificationClicked ? "clicked" : ""
            }`}
            onClick={() => setNotificationClicked(true)}
          >
            <ParentPortalNotification />
          </div>
          <Feedback />
          <ParentPortalLogoutModal />
        </div>
      </div>
    </div>
  );
}

export default ParentPortalTopSearchBar;
