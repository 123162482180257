import StudentsView from "./StudentsView/StudentsView";
import React, { useEffect, useState } from "react";
import Image from "../../images/boy.png";
import { BsThreeDots } from "react-icons/bs";
import { useParams } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

function CardView() {
  const params = useParams();

  const [cardData, setCardData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [studentData, setStudentData] = useState(null);

  const { schoolId } = useSelector((state) => state.schoolId);

  const [allStudents, setAllStudents] = useState([])


  const getStudentData = async () => {
    try {
      if (schoolId) {
        const { data } = await axios.get(`/students/all/${schoolId}`, {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        });

        const newArr = data.students.map((d) => {
          return {
            studentName: d.firstName,
            studentId: d.studentId,
            gradeLevel: d.gradeLevel,
            contactNum: d.phoneNumber,
            image: d.profilePicture,
            action: <BsThreeDots />,
            id: d.id,
          };
        });

        setAllStudents(data.students);
        setStudentData(newArr);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [staffTableData, setStaffTableData] = useState(null);

  const getParentTableData = async () => {
    try {
      const { data } = await axios.get("/staffListes/getStaffList", {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      const newArr = data.map((d) => {
        return {
          staffName: d.name,
          staffId: d.staffId,
          profile: d.profile,
          mobileNumber: d.phoneNumber,
          action: <BsThreeDots />,
          image: Image,
        };
      });
      setStaffTableData(newArr);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStudentData();
    getParentTableData();
  }, [schoolId]);

  const [loading, setLoading] = useState(true);
  const [routeLink, setRouteLink] = useState("");

  useEffect(() => {
    setLoading(true);
    setCardData(null);
    if (params.role === "student") {
      if (studentData) {
        const studentC = studentData.map((d) => {
          return {
            studentName: d.studentName,
            studentId: d.studentId,
            image: d.image,
            id: d.id,
          };
        });
        setCardData(studentC);
        setTableData(studentData);
      }
    } else if (params.role === "staff") {
      if (staffTableData) {
        const staffD = staffTableData.map((d) => {
          return {
            staffName: d.staffName,
            image: Image,
            staffRole: d.profile,
          };
        });
        setCardData(staffD);
        setTableData(staffTableData);
      }

      setRouteLink("/StaffProfile");
    } else {
      setCardData(null);
    }
    setLoading(false);
  }, [params.role, params.studentId, studentData, staffTableData, schoolId]);

  return (
    <StudentsView
      getStudentData={getStudentData}
      cardData={cardData}
      allStudents={allStudents}
      tableData={tableData}
      link={routeLink}
    />
  );
}

export default CardView;
