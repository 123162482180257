import React from "react";
import Ordercom from "./Ordercom";
import axios from "axios";
import { baseURL } from "../../../../../constants";
axios.defaults.baseURL = baseURL;

const Order = ({ apiData = [] }) => {
  return (
    apiData && (
      <div>
        <Ordercom apiData={apiData} />
      </div>
    )
  );
};

export default Order;
