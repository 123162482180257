import React from "react";
import ChartComponent from "../Chart/ChartComponent";
import RecentlyApp from "../Recently/Recently/RecentlyApp";

const MyAppAccess = () => {
  return (
    <div className="MyAPPACCESS">
      <ChartComponent />
      <RecentlyApp />
    </div>
  );
};

export default MyAppAccess;
