import React, { useEffect } from "react";
import "./RoomModal.css";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { IoAdd } from "react-icons/io5";
import axios from "axios";
import { baseURL } from "../../../../../constants";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

const RoomModal = ({ func = () => {}, roomId = "", setRoomId }) => {
  const [roomNum, setRoomNum] = useState("");
  const [location, setLocation] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setRoomId("");
    setRoomNum("");

    setLocation("");
  };
  const handleShow = () => setShow(true);

  const getRoom = async (id) => {
    try {
      const { data } = await axios.get(`/rooms/getRoomById/${id}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setRoomNum(data?.room?.roomNumber);
      setLocation(data?.room?.location);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (roomId) {
      handleShow();
      getRoom(roomId);
    }
  }, [roomId]);

  const { schoolId } = useSelector((state) => state.schoolId);

  const addRoomLocation = async () => {
    try {
      if (!roomId) {
        await axios.post(
          "/rooms/addRoom",
          {
            schoolId: Number(schoolId),

            roomNumber: roomNum,
            location: location,
          },
          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Room Added Successfully");
        setRoomNum("");
        setLocation("");
        handleClose();
      } else {
        await axios.put(
          `/rooms/updateRoom/${roomId}`,
          {
            schoolId: Number(schoolId),

            roomNumber: roomNum,
            location,
          },
          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Room Updated Successfully");
      }

      handleClose();
      func();
    } catch (error) {
      if (error?.response?.data?.error) {
        toast.error(
          error?.response?.data?.error[0]?.detail ||
            error?.response?.data?.error[0]?.message
        );
      } else if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
      console.log(error);
    }
  };
  return (
    <div className="CMmodal-main">
      <div
        variant="primary"
        onClick={handleShow}
        className="myassingment-SeC-button-btn"
      >
        <IoAdd />
        Add new Room
      </div>

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        className="CMmodal-main0modal"
        centered
        style={{ marginLeft: "100px" }}
      >
        <Modal.Header
          closeButton
          style={{ padding: "10px 0px", margin: "0px" }}
        >
          <Modal.Title>Room</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <div className="Room-modal-main">
              <label htmlFor="ProgramName">Room Num</label>
              <div className="Room-modal-main-child1">
                <input
                  value={roomNum}
                  onChange={(e) => setRoomNum(e.target.value)}
                  type="text"
                  id="text"
                  placeholder="Room Name"
                />
              </div>
            </div>
            <div className="Room-modal-main">
              <label htmlFor="ProgramName">Location</label>
              <div className="Room-modal-main-child1">
                <input
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  type="text"
                  id="text"
                  placeholder="Location"
                />
              </div>
            </div>

            <hr style={{ width: "100%" }} />
            <div className="Room-modal-main-child3">
              <button onClick={() => handleClose()}>Close</button>
              <button onClick={addRoomLocation}>Save</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RoomModal;
