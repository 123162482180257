import React, { useState, useEffect } from "react";
import "./TeacherGrades.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { baseURL } from "../../../../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

const StudentsGrades = () => {
  const [studentsGrade, setStudentsGrade] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    const getStudentsGrade = async () => {
      try {
        const { data } = await axios.get(
          "/allSchoolGrades/getAllGradesInSchool",
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setStudentsGrade(data);
      } catch (error) {
        console.log(error);
      }
    };

    getStudentsGrade();
  }, []);

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  const { schoolId } = useSelector((state) => state.schoolId);

  useEffect(() => {
    const getStudentsGrade = async () => {
      try {
        const { data } = await axios.get(
          `/grades/getGradesBySchool/${schoolId}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setStudentsGrade(data?.grades);
      } catch (error) {
        console.log(error);
      }
    };

    getStudentsGrade();
  }, [schoolId]);

  const handleOpenFile = (image) => {
    const a = document.createElement("a");
    a.href = image;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <div className="TeacherGrades">
      <div className="table">
        <table>
          <thead>
            <tr>
              <th className="center">S#</th>
              <th className="center">
                Students <br /> Name
              </th>
              <th className="center">
                Running Avg & <br /> Letter Grade
              </th>
              <th>
                Due: Dec 30,2023 <br /> QUIZ: 1 <br /> Quiz <br /> Weightage:
                40%
              </th>
              <th className="skyblUe">
                Due: Dec 30,2023 <br /> Assignment 1 <br /> Homework <br />{" "}
                Weightage: 10%
              </th>
              <th className="orAng">
                Notebook <br /> Check <br /> 02/12/2023 <br /> #13:5
              </th>
              <th className="liGhtGreen">
                Vocabulary <br /> Quiz <br /> 02/12/2023 <br /> #13:5
              </th>
              <th className="orAng">
                Class <br /> Oht <br /> 02/12/2023 <br /> #13:5
              </th>
              <th className="liGhtGreen">
                Assignment <br /> Marks <br /> 02/12/2023 <br /> #13:5
              </th>
              <th>
                Presentation <br /> Marks <br /> 02/12/2023 <br /> #13:5
              </th>
              <th className="orAng">
                Notebook Check <br /> 02/12/2023 <br /> #13:5
              </th>
            </tr>
          </thead>
          <tbody>
            {studentsGrade &&
              studentsGrade.map((tdata) => {
                return (
                  <tr>
                    <td>{tdata?.id}</td>
                    <td className="GabrielNing">
                      <p>
                        {tdata?.Student?.firstName} {tdata?.Student?.lastName}
                      </p>
                    </td>
                    <td className="persentage">{tdata?.gradeType}</td>
                    <td>{tdata?.instructions}</td>
                    <td>{tdata?.title}</td>
                    <td>{tdata?.passingPercentage}</td>
                    <td>{tdata?.scoredPercentage}</td>
                    <td>{tdata?.result}</td>
                    <td>{tdata?.date?.split("T")[0]}</td>
                    <td>{tdata?.time}</td>
                    <td
                      onClick={() =>
                        handleOpenFile(`${baseURL}/images/${tdata?.file}`)
                      }
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          width: "40px",
                          height: "40px",
                          objectFit: "cover",
                        }}
                        src={`${baseURL}/images/${tdata?.file}`}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="tableBtn">
        <button onClick={handleEditClick}>
          {isEditMode ? "Save" : "Edit"}
        </button>
      </div>
    </div>
  );
};

export default StudentsGrades;
