import React, { useState } from "react";
import "./Addparant.css";
import parantimg from "./fotor-20240126152243.png";
import { GrNotes } from "react-icons/gr";
import { MdFamilyRestroom, MdDelete } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import { IoAddSharp } from "react-icons/io5";
import { IoCaretForwardOutline } from "react-icons/io5";
import Dummy from "../../images/dummy-user.png";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../../constants";

const Addparant = () => {
  const params = useParams();

  const [parent, setParent] = useState(null);

  const getParent = async () => {
    try {
      const { data } = await axios.get(
        `/parents/getParent/${params.parentId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setParent(data?.parent);
    } catch (error) {
      console.log(error);
    }
  };

  useState(() => {
    getParent();
  }, []);

  return (
    parent && (
      <>
        <div className="myassingment-TOP1">
          <Link style={{ color: "#00afef" }} to="/ParentTable">
            Parent/Guardian List
          </Link>
          <IoCaretForwardOutline />
          <Link>Parent/Guardian Information</Link>
        </div>

        <div className="Addparant-main">
          <div className="Addparant-main-child-1">
            <div className="Addparant-main-child-1-img">
              <img
                style={{ borderRadius: "100%", objectFit: "cover" }}
                src={`${baseURL}/images/${parent?.profilePicture}`}
                onError={({ currentTarget }) => {
                  currentTarget.src = Dummy;
                }}
                alt="img"
              />
            </div>
            <h2>
              {parent?.firstName} {parent?.middleName} {parent?.lastName}
            </h2>
            <button>Guardian</button>
            <div className="Addparant-main-child-1-tap-button">
              <GrNotes /> <h3>General Info</h3>
            </div>
          </div>
          <div className="Addparant-main-child-2">
            <div className="Addparant-main-child-2-General-Information">
              <h2>General Information</h2>
              <Link to={`/Addparanteditinfo/${params.parentId}`}>
                <button>Edit Information</button>
              </Link>
            </div>
            <div className="Addparant-main-child-2-Identification-Information">
              <div className="Addparant-main-child-2-title">
                <h2>Identification Information</h2>
              </div>
              <div className="Addparant-main-child-2-Identification-Information-form">
                <div className="Addparant-main-child-2-input-and-label">
                  <label>Name</label>
                  <input
                    readOnly
                    value={`${parent?.firstName} ${parent?.middleName} ${parent?.lastName}`}
                  />
                </div>
                <div className="Addparant-main-child-2-input-and-label">
                  <label>User Profile</label>
                  <input readOnly value={parent?.userProfile} />
                </div>
                <div className="Addparant-main-child-2-input-and-label">
                  <label>Email</label>
                  <input readOnly value={parent?.email} />
                </div>
              </div>
              <div className="Addparant-main-child-2-Identification-Information-form">
                <div className="Addparant-main-child-2-input-and-label">
                  <label>Mobile Number</label>
                  <input
                    readOnly
                    value={parent?.personalNumber}
                    placeholder="-"
                  />
                </div>
                <div className="Addparant-main-child-2-input-and-label">
                  <label>Home Phone</label>
                  <input readOnly value={parent?.homePhone} />
                </div>
                <div className="Addparant-main-child-2-input-and-label">
                  <label>Work Email</label>
                  <input readOnly value={parent?.workEmail} />
                </div>
                <div className="Addparant-main-child-2-input-and-label">
                  <label>Work Phone</label>
                  <input readOnly value={parent?.workNumber} />
                </div>
              </div>
            </div>
            <div className="Addparant-main-child-2-Access-Information">
              <div className="Addparant-main-child-2-title">
                <h2>Access Information</h2>
              </div>
              <div className="Addparant-main-child-2-Identification-Information-form">
                <div className="Addparant-main-child-2-input-and-label">
                  <label>Login Email Address</label>
                  <input
                    readOnly
                    value={parent?.loginEmailAddress}
                    placeholder="-"
                  />
                </div>
                <div className="Addparant-main-child-2-input-and-label">
                  <label>Portal Access</label>
                  <input
                    readOnly
                    value={parent?.portalAccess == true ? "Active" : "Inactive"}
                  />
                </div>
                {/* <div className="Addparant-main-child-2-input-and-label">
                  <label>Disable parent</label>
                  <input readOnly value={parent?.disabled} />
                </div> */}
              </div>
            </div>
            <div className="Addparant-main-child-2-Associated-Students">
              <h2>Associated Students</h2>
              <div className="Addparant-main-child-2-Associated-Students-cards">
                <div className="Addparant-main-child-2-card">
                  <div className="cardinfo">
                    <img src={parantimg} alt="userPicture" />
                    <div className="imgInfo">
                      <h4>Sheryar</h4>
                      <p>
                        Father <span>● Primary</span>
                      </p>
                    </div>
                    <div className="icons">
                      <span className="green">
                        <MdFamilyRestroom />
                      </span>
                      <span className="gray">
                        <FaPen />
                      </span>
                      <span className="red">
                        <MdDelete />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="Addparant-main-child-2-Associated-Students-Addcard-2">
                  <IoAddSharp />
                  <h2>Associate Student</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default Addparant;
