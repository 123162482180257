import React, { useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import {
  IoAddCircle,
  IoPersonCircleOutline,
  IoRefreshCircle,
} from "react-icons/io5";
import { AiOutlineMessage } from "react-icons/ai";
import print from "../../StudentComponents/Medical/flat-color-icons_print.png";
import "./Discipline.css";
import Dummy from "../../AdminComponents/images/dummy-user.png";
import Graph from "../../StudentComponents/MyProfile/Group 1000006080.png";
import Green from "../../StudentComponents/MyProfile/Group 1000006079.png";
import Red from "../../StudentComponents/MyProfile/Group 1000006078.png";
import Timer from "../../StudentComponents/MyProfile/Group 1000006077.png";
import { FiEdit } from "react-icons/fi";
import { IoMdArrowBack } from "react-icons/io";
import axios from "axios";
import { baseURL } from "../../../constants";
import { useEffect } from "react";
import { IoArrowBack } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
axios.defaults.baseURL = baseURL;

function Discipline() {
  const [isEdit, setIsEdit] = useState("");
  function handlePrint() {
    window.print();
  }

  const [show, setShow] = useState(false);
  const [schoolId, setSchoolId] = useState();
  const [date, setDate] = useState();
  const [code, setCode] = useState();
  const [status, setStatus] = useState();
  const [comment, setComment] = useState();
  const [possible, setPossible] = useState();
  const [consequence, setConsequence] = useState();
  const [location, setLocation] = useState();
  const [disciplines, setDisciplines] = useState(null);

  const { user } = useSelector((state) => state.getUserProfile);

  const getSchoolByTeacherId = async () => {
    try {
      const { data } = await axios.get(
        `/ParentBySchoolId/getSchoolByTeacherId/${user?.dataValues?.id}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setSchoolId(data?.school?.id);
    } catch (error) {
      console.log(error);
    }
  };

  const getDisciplines = async () => {
    try {
      if (schoolId) {
        const { data } = await axios.get(
          `/disciplines/getBySchoolId/${schoolId}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setDisciplines(data?.disciplines);
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(disciplines, "these are disciplines");
  useEffect(() => {
    getDisciplines();
  }, [schoolId]);
  useEffect(() => {
    getSchoolByTeacherId();
  }, [user]);

  const fetchData = async () => {
    const newData = new FormData();
    newData.append("date", date);
    newData.append("code", code);
    newData.append("status", status);
    newData.append("comment", comment);
    newData.append("PossibleMotivation", possible);
    newData.append("Consequence", consequence);
    newData.append("Location", location);

    try {
      if (isEdit) {
        const { data } = await axios.put(
          `/disciplines/update/${isEdit}`,
          {
            schoolId,
            date,
            code,
            status,
            comment,
            possible,
            consequence,
            location,
            studentId: localStorage.getItem("teacherStudent"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Edited Successfully");
        setIsEdit("");
        getDisciplines();
        setShow(false);
      } else {
        const { data } = await axios.post(
          `/disciplines/add`,
          {
            schoolId,
            date,
            code,
            status,
            comment,
            PossibleMotivation: possible,
            Consequence: consequence,
            Location: location,
            studentId: localStorage.getItem("teacherStudent"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Added Successfully");
        setIsEdit("");
        getDisciplines();
        setShow(false);
      }
      setSchoolId("");
      setDate("");
      setCode("");
      setStatus("");
      setComment("");
      setPossible("");
      setConsequence("");
      setLocation("");
    } catch (error) {
      console.log(error, "this is current error");
      toast.error(error?.response?.data?.error);
    }
  };

  console.log(date);
  const [student, setStudent] = useState(null);

  const getStudent = async () => {
    try {
      const { data } = await axios.get(
        `/students/profile/${localStorage.getItem("teacherStudent")}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setStudent(data?.student);
    } catch (error) {
      console.log(error);
    }
  };

  //

  useEffect(() => {
    getStudent();
  }, []);

  useEffect(() => {
    if (isEdit && disciplines) {
      setShow(true);

      const currentDiscipline = disciplines.find((current) => {
        return current.id === isEdit;
      });
      setSchoolId(currentDiscipline?.schoolId);
      setDate(currentDiscipline?.date);
      setCode(currentDiscipline?.code);
      setStatus(currentDiscipline?.status);
      setComment(currentDiscipline?.comment);
      setPossible(currentDiscipline?.PossibleMotivation);
      setConsequence(currentDiscipline?.Consequence);
      setLocation(currentDiscipline?.Location);
    }
  }, [isEdit, disciplines]);

  const handleClearData = () => {
    setSchoolId("");
    setDate("");
    setCode("");
    setStatus("");
    setComment("");
    setPossible("");
    setConsequence("");
    setLocation("");
  };

  const editDiscipline = async () => {
    try {
      const { data } = await axios.delete(`/disciplines/remove/${isEdit}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      toast.success("Successfully deleted displine!");
      getDisciplines();
      setIsEdit("");
      setSchoolId("");
      setDate("");
      setCode("");
      setStatus("");
      setComment("");
      setPossible("");
      setConsequence("");
      setLocation("");
      console.log(data);
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else if (error?.response?.data?.error) {
        toast.error(
          error?.response?.data?.error[0]?.detail ||
            error?.response?.data?.error[0]?.message
        );
      } else {
        toast.error(
          error?.response?.data?.message || error?.response?.data?.msg
        );
      }
      console.log(error);
    }
  };

  const [assingment, setAssingment] = useState([]);

  const getAssingment = async () => {
    try {
      const { data } = await axios.get(
        `/assignments/getAssignmentCountByStudent/${localStorage.getItem(
          "teacherStudent"
        )}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setAssingment(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAssingment();
  }, []);

  const [completed, setComplted] = useState([]);

  const getCompleted = async () => {
    try {
      const { data } = await axios.get(
        `/assignmentSubmissions/getSubmittedAssignmentsCountByStudent/${localStorage.getItem(
          "teacherStudent"
        )}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setComplted(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCompleted();
  }, []);

  const [gpa, setGpa] = useState([]);

  const getGpa = async () => {
    try {
      const { data } = await axios.get(
        `/grades/calculateGPA/${localStorage.getItem("teacherStudent")}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setGpa(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getGpa();
  }, []);

  return (
    <div className="Guidance-Main21">
      <div className="StuProfile-main">
        <div className="StuProfile-Top">
          <div className="StuProfile-Top1">
            <IoMdArrowBack style={{ cursor: "pointer" }} />
            <h1>Student Profile</h1>
          </div>
          <div className="StuProfile-Top2">
            <Link to="/TeacherChat" className="StuProfile-Top-btn1">
              Parent Chat
            </Link>
            <Link to="/TeacherChat" className="StuProfile-Top-btn2">
              Student Chat
            </Link>
            <Link to="/TeacherChat" className="StuProfile-Top-btn2">
              Admin Chat
            </Link>
          </div>
        </div>
        <div className="Myprofile-main-1">
          <div className="Myprofile-main-child-1">
            <div className="profile">
              <div className="profile-child-1">
                <img
                  onError={({ currentTarget }) => {
                    currentTarget.src = Dummy;
                  }}
                  src={`${baseURL}/images/${student?.profilePicture}`}
                />
              </div>
              <div className="profile-child-2">
                <div className="profile-child-2-1">
                  <h2>{student?.firstName + " " + student?.lastName}</h2>
                </div>
                <div className="profile-child-2-2">
                  <p>{student?.program}</p>
                  <button>
                    <span>●</span>
                    {student?.online ? "Online" : "Offline"}
                  </button>
                </div>
                <div className="profile-child-2-3">
                  <p>{student?.loggedInSince?.split("T")[0]}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="Myprofile-main-child-2">
            <div className="Myprofile-main-child-2-1">
              <img src={Timer} alt="#" />
              <h3>{student?.createdAt?.split("T")[0]}</h3>
            </div>
            <div className="Myprofile-main-child-2-2">
              <div className="div-1">
                <img src={Red} alt="#" />
                <h3>
                  Assignments <span>{assingment?.assignmentCount}</span>
                </h3>
              </div>
              <div className="div-2">
                <img src={Green} alt="#" />
                <h3>
                  Completed <span>{completed?.submittedAssignmentsCount}</span>
                </h3>
              </div>
            </div>
          </div>
          <div className="Myprofile-main-child-3">
            <div className="Myprofile-main-child-3-card-1">
              <img src={Graph} alt="#" />
            </div>
            <div className="Myprofile-main-child-3-card-2">
              <h3>GPA</h3>
              <h1>{gpa?.gpa}</h1>
            </div>
          </div>
        </div>
      </div>
      {!show ? (
        <div className="GuidanceTOP_MAIn">
          <div className="Guidance-Top">
            <h3>Discipline Records</h3>
            <button onClick={() => setShow(true)}>
              <IoAddCircle />
              Add new Record
            </button>
          </div>
          <div className="Guidance-BodyMain">
            {disciplines &&
              disciplines?.map((discipline) => {
                return (
                  <div className="Guidance-BodyMain">
                    <div className="Guidance-Second">
                      <div className="Guidance-Second-top">
                        <h3>Attitude Problem (A)</h3>
                        <button onClick={handlePrint}>
                          <img src={print} alt="#" />
                          Print Discipline Record
                        </button>
                      </div>
                    </div>
                    <div className="Guidance-Body">
                      <div className="Guidance-Body-Edit">
                        <h3>
                          <FaMapMarkerAlt />
                          {discipline?.Location}
                        </h3>
                        <button>
                          <FiEdit onClick={() => setIsEdit(discipline?.id)} />
                        </button>
                      </div>
                      <h3>
                        <IoPersonCircleOutline />
                        Status: {discipline?.status}
                      </h3>
                      <h3>
                        <AiOutlineMessage />
                        {discipline?.comment}
                      </h3>
                      <h3>
                        <span>Possible Motivation: </span>
                        {discipline?.PossibleMotivation}
                      </h3>
                      <h3>
                        <span>Consequence: </span>
                        {discipline?.Consequence}
                      </h3>
                      <h3>
                        <span>Created By: </span>Admin
                      </h3>
                      <h3>
                        <span>Last Update: </span>16/4/2024 By Admin
                      </h3>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div className="GuidanceTOP_MAIn">
          <div className="Guidance-Top1">
            <IoArrowBack
              onClick={() => {
                setIsEdit("");
                setShow(false);
              }}
            />
            <h3>Discipline Records</h3>
          </div>
          <div className="Discipline_Table">
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Code</th>
                  <th>Location</th>
                  <th>Possible Motivation</th>
                  <th>Consequence</th>
                  {/* <th>School</th> */}
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="date"
                      name="date"
                      onChange={(e) => setDate(e.target.value)}
                      value={date}
                    />
                  </td>
                  <td>
                    <select
                      name="code"
                      onChange={(e) => setCode(e.target.value)}
                      value={code}
                    >
                      <option disabled value=""></option>
                      <option value="Disposition">Disposition</option>
                      <option value="Violation">Violation</option>
                    </select>
                  </td>
                  <td>
                    <select
                      name="Location"
                      onChange={(e) => setLocation(e.target.value)}
                      value={location}
                    >
                      <option disabled value=""></option>
                      <option value="Basketball Courts">
                        Basketball Courts
                      </option>
                      <option value="Bus Stop">Bus Stop</option>
                      <option value="Cafeteria">Cafeteria</option>
                      <option value="Classroom">Classroom</option>
                      <option value="Computer Lab">Computer Lab</option>
                      <option value="Field">Field</option>
                      <option value="Hallway">Hallway</option>
                      <option value="Library">Library</option>
                      <option value="Off-Campus">Off-Campus</option>
                      <option value="Office">Office</option>
                      <option value="Restroom">Restroom</option>
                      <option value="Special Event/Field Trip">
                        Special Event/Field Trip
                      </option>
                    </select>
                  </td>
                  <td>
                    <select
                      name="PossibleMotivation"
                      onChange={(e) => setPossible(e.target.value)}
                      value={possible}
                    >
                      <option disabled value=""></option>
                      <option value="Disruption">
                        Disruption of school Action...
                      </option>
                      <option value="Horeplay">Horeplay</option>
                      <option value="Weapon">
                        Weapon, Possession of E....
                      </option>
                      <option value="Harassment">Harassment (E) 48900.4</option>
                      <option value="Alcohol">
                        Alcohol, Possession of (E...
                      </option>
                      <option value="Property">
                        Property, Destruction of...
                      </option>
                      <option value="Sexual Assault">
                        Sexual Assault (E) 48915..
                      </option>
                      <option value="Dress">Dress, Code Violation</option>
                      <option value="Stolen">Stolen Property</option>
                      <option value="Theft">Theft (S) 48900..</option>
                      <option value="Other">Other</option>
                    </select>
                  </td>
                  <td>
                    <select
                      name="Consequence"
                      onChange={(e) => setConsequence(e.target.value)}
                      value={consequence}
                    >
                      <option disabled></option>
                      <option value="Counseled">Counseled</option>
                      <option value="Contract">Contract</option>
                      <option value="Loss of privileges">
                        Loss of privileges
                      </option>
                      <option value="Lose Recess">Lose Recess</option>
                    </select>
                  </td>
                  {/* <td>
                    <input
                      type="text"
                      name="teacherId"
                      value={schoolId}
                      onChange={(e) => setSchoolId(e.target.value)}
                    />
                  </td> */}
                  {/* <td>
                    <select
                      name="schoolId"
                      value={schoolId}
                      onChange={(e) => setSchoolId(e.target.value)}
                    >
                      <option disabled></option>
                      <option value="Golden Eagle School">
                        Golden Eagle School
                      </option>
                      <option value="Golden Eagle School Two">
                        Golden Eagle School Two
                      </option>
                    </select>
                  </td> */}
                  <td>
                    <select
                      name="status"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option disabled value=""></option>
                      <option value="Follow-up Needed">Follow-up Needed</option>
                      <option value="Pending">Pending</option>
                      <option value="Resolved">Resolved</option>
                      <option value="Served">Served</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="Discipline_TextArea">
              <textarea
                placeholder="Comment"
                onChange={(e) => setComment(e.target.value)}
                value={comment}
              />
            </div>
            <div className="MedicalTWoButton">
              <div className="MedicalTWoChild">
                {isEdit && (
                  <button
                    onClick={() => {
                      setShow(false);
                      editDiscipline();
                    }}
                  >
                    Delete
                  </button>
                )}
                <button onClick={handleClearData}>
                  <IoRefreshCircle />
                  Reset
                </button>
                <button onClick={fetchData}>Save new Record</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Discipline;
