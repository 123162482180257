import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { MdFamilyRestroom, MdDelete } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import "./Family.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form } from "react-bootstrap";
import { FaRegEye, FaEyeSlash } from "react-icons/fa";
import { AiOutlineReload } from "react-icons/ai";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import axios from "axios";
import { Country, State, City } from "country-state-city";
import {
  isValidName,
  isValidAmericanPhoneNumber,
  isValidEmail,
  isValidPassword,
  requiredValues,
} from "../../../../../utils/regex.js";

import { baseURL } from "../../../../../constants";
import { useParams } from "react-router-dom";
axios.defaults.baseURL = baseURL;

const Family = () => {
  const [showModal, setShowModal] = useState(false);
  const [showAddOtherContactModal, setShowAddOtherContactModal] =
    useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleCardClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCloseAddOtherContactModal = () => {
    setShowAddOtherContactModal(false);
  };
  const handleAddOtherContactClick = () => {
    setShowAddOtherContactModal(true);
  };

  const [isStudentCreatePassword, setIsStudentCreatePassword] = useState(false);
  const [isCreatePassword, setIsCreatePassword] = useState(false);

  const handleStudentCreatePasswordChange = () => {
    setIsStudentCreatePassword(true);
    setIsCreatePassword(false);
  };

  const handleCreatePasswordChange = () => {
    setIsStudentCreatePassword(false);
    setIsCreatePassword(true);
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const generatePassword = () => {
    const length = 12;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";

    let newPassword = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      newPassword += charset[randomIndex];
    }

    setPassword(newPassword);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const [showSecondDiv, setShowSecondDiv] = useState(false);

  const handleSwitchChange = () => {
    setShowSecondDiv(!showSecondDiv);
  };

  const handleCancel = () => {
    setIsStudentCreatePassword(false);
    setIsCreatePassword(false);
  };

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [deleteId, setDeleteId] = useState("");

  const handleDeleteClick = async (id) => {
    setSelectedCard(id);
    setShowDeleteConfirmationModal(true);
    setDeleteId(id);
  };

  const [familyInfo, setFamilyInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [salutation, setSalutation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactRelation, setContactRelation] = useState("");
  const [mobilePhone, setMobilePhone] = useState("+1");
  const [homePhone, setHomePhone] = useState("+1");
  const [personalEmail, setPersonalEmail] = useState("");
  const [portalAccess, setPortalAccess] = useState(false);
  const [loginEmailAddress, setLoginEmailAddress] = useState("");
  const [sendEmail, setSendEmail] = useState(false);
  const [password, setPassword] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [country, setCountry] = useState("");
  const [stateOrProvinceOrLocality, setStateOrProvinceOrLocality] =
    useState("");
  const [city, setCity] = useState("");
  const [zipOrPostalCode, setZipOrPostalCode] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [resetPassword, setResetPassword] = useState(false);
  const [sameAsStudentAddress, setSameAsStudentAddress] = useState(false);
  const [profileId, setProfileId] = useState("");

  const params = useParams();
  const [familyData, setFamilyData] = useState(null);
  const getFamilyData = async () => {
    try {
      const { data } = await axios.get(`/contacts/all/${params?.studentId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setFamilyData(data);
    } catch (error) {
      console.log(error);
    }
  };
  const [editId, setEditId] = useState("");

  const [selectedCountry, setSelectedCountry] = useState("US");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const handleClickEditButton = (id) => {
    setEditId(id);
    setShowEditModal(true);
  };
  const handleAddContact = async (e) => {
    e?.preventDefault();
    try {
      if (
        !requiredValues([
          {
            value: firstName,
            name: "First name",
          },
          {
            value: lastName,
            name: "Last name",
          },
          {
            value: contactRelation,
            name: "Contact Relation",
          },
          {
            value: personalEmail,
            name: "Personal Email",
          },
          {
            value: selectedCountry,
            name: "Country",
          },
          {
            value: address1,
            name: "Address 1",
          },
          {
            value: address2,
            name: "Address 2",
          },
          {
            value: zipOrPostalCode,
            name: "Zip or Postal Code",
          },
          {
            value: loginEmailAddress,
            name: "Login Email Address",
          },
          {
            value: password,
            name: "Password",
          },
        ])
      )
        return;
      if (!isValidName(firstName, "First name")) return;

      if (middleName) {
        if (!isValidName(middleName, "Middle name")) return;
      }

      if (!isValidName(lastName, "Last name")) return;

      if (!isValidAmericanPhoneNumber(mobilePhone, "Mobile phone")) return;
      if (!isValidAmericanPhoneNumber(homePhone, "Home phone")) return;

      if (!isValidEmail(personalEmail)) return;
      if (!isValidEmail(loginEmailAddress)) return;

      if (!isValidPassword(password)) return;

      const newForm = new FormData();
      newForm.append("studentId", params.studentId);
      newForm.append("salutation", salutation);
      newForm.append("firstName", firstName);
      newForm.append("middleName", middleName);
      newForm.append("lastName", lastName);
      newForm.append("contactRelation", contactRelation);
      newForm.append("mobilePhone", mobilePhone);
      newForm.append("homePhone", homePhone);
      newForm.append("personalEmail", personalEmail);
      newForm.append("portalAccess", portalAccess);
      newForm.append("loginEmailAddress", loginEmailAddress);
      newForm.append("sendEmail", sendEmail);
      newForm.append("password", password);
      newForm.append("address1", address1);
      newForm.append("address2", address2);
      newForm.append("country", selectedCountry);
      newForm.append("stateOrProvinceOrLocality", selectedRegion);
      newForm.append("city", selectedCity);
      newForm.append("zipOrPostalCode", zipOrPostalCode);

      newForm.append("profilePicture", profilePicture);

      const { data } = await axios.post(`/contacts/add`, newForm, {
        headers: {
          "Content-Type": "multipart/form-data",
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      toast.success("Contact Added Successfully");
      setSalutation("");
      setFirstName("");
      setMiddleName("");
      setLastName("");
      setContactRelation("");
      setMobilePhone("");
      setHomePhone("");
      setPersonalEmail("");
      setPortalAccess(false);
      setLoginEmailAddress("");
      setSendEmail(false);
      setPassword("");
      setAddress1("");
      setAddress2("");
      setCountry("");
      setStateOrProvinceOrLocality("");
      setCity("");

      setZipOrPostalCode("");
      setProfilePicture("");

      setShowModal(false);
      setShowAddOtherContactModal(false);
      getFamilyData();
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    getFamilyData();
  }, []);

  const handleDeleteContact = async () => {
    try {
      const { data } = await axios.delete(`/contacts/remove/${deleteId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      toast.success("Contact Removed");
      setShowDeleteConfirmationModal(false);
      setDeleteId("");
      getFamilyData();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (!homePhone?.startsWith("+1")) {
      setHomePhone("+1");
    } else {
      setHomePhone(homePhone);
    }
  }, [homePhone]);

  useEffect(() => {
    if (!mobilePhone?.startsWith("+1")) {
      setMobilePhone("+1");
    } else {
      setMobilePhone(mobilePhone);
    }
  }, [mobilePhone]);

  const handleEditContact = async (e) => {
    e?.preventDefault();
    try {
      if (
        !requiredValues([
          {
            value: firstName,
            name: "First name",
          },
          {
            value: lastName,
            name: "Last name",
          },
          {
            value: contactRelation,
            name: "Contact Relation",
          },
          {
            value: personalEmail,
            name: "Personal Email",
          },
          {
            value: selectedCountry,
            name: "Country",
          },
          {
            value: address1,
            name: "Address 1",
          },
          {
            value: address2,
            name: "Address 2",
          },
          {
            value: zipOrPostalCode,
            name: "Zip or Postal Code",
          },
          {
            value: loginEmailAddress,
            name: "Login Email Address",
          },
          {
            value: password,
            name: "Password",
          },
        ])
      )
        return;
      if (!isValidName(firstName, "First name")) return;

      if (middleName) {
        if (!isValidName(middleName, "Middle name")) return;
      }

      if (!isValidName(lastName, "Last name")) return;

      if (!isValidAmericanPhoneNumber(mobilePhone, "Mobile phone")) return;
      if (!isValidAmericanPhoneNumber(homePhone, "Home phone")) return;

      if (!isValidEmail(personalEmail)) return;
      if (!isValidEmail(loginEmailAddress)) return;

      if (!isValidPassword(password)) return;

      const newForm = new FormData();
      newForm.append("studentId", params.studentId);
      newForm.append("salutation", salutation);
      newForm.append("firstName", firstName);
      newForm.append("middleName", middleName);
      newForm.append("lastName", lastName);
      newForm.append("contactRelation", contactRelation);
      newForm.append("mobilePhone", mobilePhone);
      newForm.append("homePhone", homePhone);
      newForm.append("personalEmail", personalEmail);
      newForm.append("portalAccess", portalAccess);
      newForm.append("loginEmailAddress", loginEmailAddress);
      newForm.append("sendEmail", sendEmail);
      newForm.append("password", password);
      newForm.append("address1", address1);
      newForm.append("address2", address2);
      newForm.append("country", selectedCountry);
      newForm.append("stateOrProvinceOrLocality", selectedRegion);
      newForm.append("city", selectedCity);
      newForm.append("zipOrPostalCode", zipOrPostalCode);

      newForm.append("profilePicture", profilePicture);
      const { data } = await axios.put(`/contacts/update/${editId}`, newForm, {
        headers: {
          "Content-Type": "multipart/form-data",
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      toast.success("Contact Updated Successfully");
      getFamilyData();
      setEditId("");
      setShowEditModal(false);
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const handleCountryChange = (val) => {
    setSelectedCountry(val);
    setSelectedRegion("");
    setSelectedCity("");
  };

  const handleRegionChange = (val) => {
    setSelectedRegion(val);
    setSelectedCity("");
  };

  const handleCityChange = (val) => {
    setSelectedCity(val);
  };

  const countries = Country.getAllCountries();
  const regions = State.getStatesOfCountry(selectedCountry);
  const cities = City.getCitiesOfState(selectedCountry, selectedRegion);
  return (
    <div className="FamilyInformation">
      <div className="FamilyInformation-Main">
        <h3>Family Information</h3>
        <p>Student Profile</p>
        <div className="studentProfile">
          <Tabs
            defaultActiveKey="profile"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="contacts" title="Contacts">
              {familyData?.contacts &&
                familyData?.contacts?.map((family, index) => {
                  return (
                    <div className="card">
                      <div className="cardinfo">
                        <img
                          src={`${baseURL}/images/${family?.profilePicture}`}
                          alt="userPicture"
                          onClick={() => {
                            setProfileId(index);
                            handleCardClick();
                          }}
                        />
                        <div className="imgInfo">
                          <h4
                            onClick={() => {
                              setProfileId(index);
                              handleCardClick();
                            }}
                          >
                            {family?.firstName} {family?.middleName}{" "}
                            {family?.lastName}
                          </h4>
                          <p>
                            <span>● Primary</span>
                          </p>
                        </div>
                        <div className="icons">
                          <span className="green">
                            <MdFamilyRestroom />
                          </span>
                          <span
                            className="gray"
                            onClick={() => handleClickEditButton(family?.id)}
                          >
                            <FaPen />
                          </span>
                          <span
                            className="red"
                            onClick={() => handleDeleteClick(family?.id)}
                          >
                            <MdDelete />
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Tab>
            <Tab eventKey="Sibling Info" title="Sibling Info"></Tab>
          </Tabs>
        </div>
        <div className="buTTons">
          <button>+ Add Secondary Contact</button>
          <button onClick={handleAddOtherContactClick}>
            + Add Other Contact
          </button>
        </div>

        {/* Modal component */}
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          className="FamilyInformation-Modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {familyData?.contacts?.[profileId]?.firstName}{" "}
              {familyData?.contacts?.[profileId]?.middleName}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {familyData && typeof profileId === "number" && (
              <div className="modal-familyy">
                <div className="row-1">
                  <div className="name">
                    <span>Full name</span>
                    <p>
                      {familyData?.contacts?.[profileId]?.firstName}{" "}
                      {familyData?.contacts?.[profileId]?.middleName}{" "}
                      {familyData?.contacts?.[profileId]?.lastName}
                    </p>
                  </div>
                  <div className="name">
                    <span>Mobile Phone</span>
                    <p>{familyData?.contacts?.[profileId]?.mobilePhone}</p>
                  </div>
                  <div className="name">
                    <span>Personal Email</span>
                    <p>{familyData?.contacts?.[profileId]?.personalEmail}</p>
                  </div>
                  <div className="name">
                    <span>Login Username</span>
                    <p>
                      {familyData?.contacts?.[profileId]?.loginEmailAddress}
                    </p>
                  </div>
                </div>
                <div className="row-1">
                  <div className="name">
                    <span>Contact Relationship</span>
                    <p>{familyData?.contacts?.[profileId]?.contactRelation}</p>
                  </div>
                  <div className="name">
                    <span>Home phone</span>
                    <p>{familyData?.contacts?.[profileId]?.homePhone}</p>
                  </div>
                  <div className="name">
                    <span>Portal access</span>
                    <p>
                      {familyData?.contacts?.[
                        profileId
                      ]?.portalAccess.toString()}
                    </p>
                  </div>
                  <div className="name">
                    <span>Address</span>
                    <p>
                      {familyData?.contacts?.[profileId]?.address1},{" "}
                      {familyData?.contacts?.[profileId]?.address2}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
        <Modal
          show={showAddOtherContactModal}
          onHide={handleCloseAddOtherContactModal}
          className="FamilyInformation-Modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add And Link Contact</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form">
                <div className="firstDiv">
                  <select
                    name="salutation"
                    value={salutation}
                    onChange={(e) => setSalutation(e.target.value)}
                  >
                    <option value={""}>Salutation*</option>
                    <option value={"Dr"}>Dr</option>
                    <option value={"Miss"}>Miss</option>
                    <option value={"Mr"}>Mr</option>
                    <option value={"Prof"}>Prof</option>
                  </select>
                  <input
                    type="text"
                    placeholder="First/Given Name*"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Middle Name*"
                    name="middleName"
                    value={middleName}
                    onChange={(e) => setMiddleName(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Last/Family Name*"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <select
                    name="contactRelation"
                    value={contactRelation}
                    onChange={(e) => setContactRelation(e.target.value)}
                  >
                    <option value={""}>Contact Relation*</option>
                    <option value={"Mother"}>Mother</option>
                    <option value={"Father"}>Father</option>
                    <option value={"Brother"}>Brother</option>
                    <option value={"Grand Father"}>Grand Father</option>
                  </select>
                  <input
                    type="text"
                    placeholder=""
                    name="mobilePhone"
                    value={mobilePhone}
                    onChange={(e) => setMobilePhone(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Home phone*"
                    name="homePhone"
                    value={homePhone}
                    onChange={(e) => setHomePhone(e.target.value)}
                  />
                  <input
                    type="email"
                    placeholder="Personal Email"
                    name="personalEmail"
                    value={personalEmail}
                    onChange={(e) => setPersonalEmail(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Address 1*"
                    name="address1"
                    value={address1}
                    onChange={(e) => setAddress1(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Address 2"
                    name="personalEmail"
                    value={address2}
                    onChange={(e) => setAddress2(e.target.value)}
                  />
                  <select
                    value={selectedCountry}
                    onChange={(e) => handleCountryChange(e.target.value)}
                  >
                    <option value="">Select Country</option>
                    {countries.map((country) => (
                      <option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </select>

                  {selectedCountry && (
                    <select
                      value={selectedRegion}
                      onChange={(e) => handleRegionChange(e.target.value)}
                    >
                      <option value="">Select Region</option>
                      {regions.map((region) => (
                        <option
                          key={region.isoCode}
                          selected={
                            region.isoCode === selectedRegion ? true : false
                          }
                          value={region.isoCode}
                        >
                          {region.name}
                        </option>
                      ))}
                    </select>
                  )}
                  {selectedRegion && (
                    <select
                      value={selectedCity}
                      onChange={(e) => handleCityChange(e.target.value)}
                    >
                      <option value="">Select District</option>
                      {cities.map((city) => (
                        <option
                          key={city.id}
                          selected={city.name === selectedCity ? true : false}
                          value={city.name}
                        >
                          {city.name}
                        </option>
                      ))}
                    </select>
                  )}
                  <input
                    type="text"
                    placeholder="Zip Or Postal Code"
                    name="personalEmail"
                    value={zipOrPostalCode}
                    onChange={(e) => setZipOrPostalCode(e.target.value)}
                  />
                  <div>
                    <label
                      style={{
                        fontSize: "14px",
                        backgroundColor: "#00AFEF",
                        color: "white",
                        padding: ".3rem 1rem",
                        borderRadius: "15px",
                        cursor: "pointer",
                      }}
                      htmlFor="contact-profile-pic"
                    >
                      Choose Profile Picture
                    </label>
                    <input
                      id="contact-profile-pic"
                      type="file"
                      placeholder="Profile Picture"
                      name="personalEmail"
                      style={{ display: "none" }}
                      onChange={(e) => setProfilePicture(e.target.files[0])}
                    />
                    {profilePicture && (
                      <span style={{ fontSize: "12px", paddingInline: "7px" }}>
                        {profilePicture?.name}
                      </span>
                    )}
                  </div>
                </div>
                <div className="swiTch">
                  <Form.Check
                    type="switch"
                    id="flexSwitchCheckChecked"
                    label="Portal access"
                    checked={portalAccess}
                    onChange={(e) => setPortalAccess(!portalAccess)}
                  />
                </div>
                {portalAccess && (
                  <div className="secondDiv">
                    <div className="AccessInformation">
                      <p>Access Information</p>
                      <div className="reseT">
                        <input
                          className="email"
                          type="email"
                          placeholder="Login Email Address*"
                          name="loginEmailAddress"
                          value={loginEmailAddress}
                          onChange={(e) => setLoginEmailAddress(e.target.value)}
                        />
                        <label>
                          <input
                            type="checkbox"
                            name="setResetPassword"
                            checked={resetPassword}
                            onChange={() => setResetPassword(!resetPassword)}
                          />
                          Set or Reset Password
                        </label>
                      </div>
                      <div className="studentEmail">
                        <h3>Password</h3>
                        <p>Student email to set or reset password</p>
                        <label
                          className={isStudentCreatePassword ? "selected" : ""}
                        >
                          <input
                            type="radio"
                            onChange={(e) => setSendEmail(!sendEmail)}
                            checked={sendEmail}
                          />
                          Student will be able to create password on their own
                          by clicking the link on their email
                        </label>
                        <label className={isCreatePassword ? "selected" : ""}>
                          <input
                            type="radio"
                            onChange={handleCreatePasswordChange}
                            checked={isCreatePassword}
                          />
                          Create Password
                        </label>
                        <div className="inputs">
                          <div className="input">
                            <input
                              type={showPassword ? "text" : "password"}
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            {showPassword ? (
                              <FaEyeSlash onClick={togglePasswordVisibility} />
                            ) : (
                              <FaRegEye onClick={togglePasswordVisibility} />
                            )}
                          </div>
                          <label>
                            <AiOutlineReload onClick={generatePassword} /> Auto
                            Generate Password
                          </label>
                        </div>
                      </div>
                      <div className="Address">
                        <p>Access Information</p>
                        <div className="label">
                          <label>
                            <input type="radio" name="addressOption" />
                            Same as Student ‘s Home Address
                          </label>
                          <label>
                            <input type="radio" name="addressOption" />
                            Add new Address
                          </label>
                        </div>
                        <div className="buttons">
                          <button type="button" onClick={handleCancel}>
                            Cancel
                          </button>
                          <button
                            onClick={handleAddContact}
                            className="Submit"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          className="FamilyInformation-Modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add And Link Contact</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form">
                <div className="firstDiv">
                  <select
                    name="salutation"
                    value={salutation}
                    onChange={(e) => setSalutation(e.target.value)}
                  >
                    <option value={""}>Salutation*</option>
                    <option value={"Dr"}>Dr</option>
                    <option value={"Miss"}>Miss</option>
                    <option value={"Mr"}>Mr</option>
                    <option value={"Prof"}>Prof</option>
                  </select>
                  <input
                    type="text"
                    placeholder="First/Given Name*"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Middle Name*"
                    name="middleName"
                    value={middleName}
                    onChange={(e) => setMiddleName(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Last/Family Name*"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <select
                    name="contactRelation"
                    value={contactRelation}
                    onChange={(e) => setContactRelation(e.target.value)}
                  >
                    <option value={""}>Contact Relation*</option>
                    <option value={"Mother"}>Mother</option>
                    <option value={"Father"}>Father</option>
                    <option value={"Brother"}>Brother</option>
                    <option value={"Grand Father"}>Grand Father</option>
                  </select>
                  {/* <input
                    type="number"
                    placeholder="Mobile Phone*"
                    name="mobilePhone"
                    value={mobilePhone}
                    onChange={(e) => setMobilePhone(e.target.value)}
                  />
                  <input
                    type="number"
                    placeholder="Home phone*"
                    name="homePhone"
                    value={homePhone}
                    onChange={(e) => setHomePhone(e.target.value)}
                  /> */}
                  <input
                    type="text"
                    placeholder=""
                    name="Mobile Phone"
                    value={mobilePhone}
                    onChange={(e) => setMobilePhone(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Home phone*"
                    name="homePhone"
                    value={homePhone}
                    onChange={(e) => setHomePhone(e.target.value)}
                  />
                  <input
                    type="email"
                    placeholder="Personal Email"
                    name="homePhone"
                    value={personalEmail}
                    onChange={(e) => setPersonalEmail(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Address 1*"
                    name="homePhone"
                    value={address1}
                    onChange={(e) => setAddress1(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Address 2"
                    name="personalEmail"
                    value={address2}
                    onChange={(e) => setAddress2(e.target.value)}
                  />
                  <select
                    value={selectedCountry}
                    onChange={(e) => handleCountryChange(e.target.value)}
                  >
                    <option value="">Select Country</option>
                    {countries.map((country) => (
                      <option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                  {selectedCountry && (
                    <select
                      value={selectedRegion}
                      onChange={(e) => handleRegionChange(e.target.value)}
                    >
                      <option value="">Select Region</option>
                      {regions.map((region) => (
                        <option
                          key={region.isoCode}
                          selected={
                            region.isoCode === selectedRegion ? true : false
                          }
                          value={region.isoCode}
                        >
                          {region.name}
                        </option>
                      ))}
                    </select>
                  )}
                  {selectedRegion && (
                    <select
                      value={selectedCity}
                      onChange={(e) => handleCityChange(e.target.value)}
                    >
                      <option value="">Select District</option>
                      {cities.map((city) => (
                        <option
                          key={city.id}
                          selected={city.name === selectedCity ? true : false}
                          value={city.name}
                        >
                          {city.name}
                        </option>
                      ))}
                    </select>
                  )}
                  <input
                    type="text"
                    placeholder="Zip Or Postal Code"
                    name="personalEmail"
                    value={zipOrPostalCode}
                    onChange={(e) => setZipOrPostalCode(e.target.value)}
                  />
                  <div>
                    <label
                      style={{
                        fontSize: "14px",
                        backgroundColor: "#00AFEF",
                        color: "white",
                        padding: ".3rem 1rem",
                        borderRadius: "15px",
                        cursor: "pointer",
                      }}
                      htmlFor="contact-profile-pic"
                    >
                      Choose Profile Picture
                    </label>
                    <input
                      id="contact-profile-pic"
                      type="file"
                      placeholder="Profile Picture"
                      name="personalEmail"
                      style={{ display: "none" }}
                      onChange={(e) => setProfilePicture(e.target.files[0])}
                    />
                    {profilePicture && (
                      <span style={{ fontSize: "12px", paddingInline: "7px" }}>
                        {profilePicture?.name}
                      </span>
                    )}
                  </div>
                </div>
                <div className="swiTch">
                  <Form.Check
                    type="switch"
                    id="flexSwitchCheckChecked"
                    label="Portal access"
                    checked={portalAccess}
                    onChange={(e) => setPortalAccess(!portalAccess)}
                  />
                </div>
                {portalAccess && (
                  <div className="secondDiv">
                    <div className="AccessInformation">
                      <p>Access Information</p>
                      <div className="reseT">
                        <input
                          className="email"
                          type="email"
                          placeholder="Login Email Address*"
                          name="loginEmailAddress"
                          value={loginEmailAddress}
                          onChange={(e) => setLoginEmailAddress(e.target.value)}
                        />
                        <label>
                          <input
                            type="checkbox"
                            name="setResetPassword"
                            checked={resetPassword}
                            onChange={() => setResetPassword(!resetPassword)}
                          />
                          Set or Reset Password
                        </label>
                      </div>
                      <div className="studentEmail">
                        <h3>Password</h3>
                        <p>Student email to set or reset password</p>
                        <label
                          className={isStudentCreatePassword ? "selected" : ""}
                        >
                          <input
                            type="radio"
                            onChange={(e) => setSendEmail(!sendEmail)}
                            checked={sendEmail}
                          />
                          Student will be able to create password on their own
                          by clicking the link on their email
                        </label>
                        <label className={isCreatePassword ? "selected" : ""}>
                          <input
                            type="radio"
                            onChange={handleCreatePasswordChange}
                            checked={isCreatePassword}
                          />
                          Create Password
                        </label>
                        <div className="inputs">
                          <div className="input">
                            <input
                              type={showPassword ? "text" : "password"}
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            {showPassword ? (
                              <FaEyeSlash onClick={togglePasswordVisibility} />
                            ) : (
                              <FaRegEye onClick={togglePasswordVisibility} />
                            )}
                          </div>
                          <label>
                            <AiOutlineReload onClick={generatePassword} /> Auto
                            Generate Password
                          </label>
                        </div>
                      </div>
                      <div className="Address">
                        <p>Access Information</p>
                        <div className="label">
                          <label>
                            <input type="radio" name="addressOption" />
                            Same as Student ‘s Home Address
                          </label>
                          <label>
                            <input type="radio" name="addressOption" />
                            Add new Address
                          </label>
                        </div>
                        <div className="buttons">
                          <button type="button" onClick={handleCancel}>
                            Cancel
                          </button>
                          <button
                            onClick={handleEditContact}
                            className="Submit"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal
          show={showDeleteConfirmationModal}
          onHide={() => setShowDeleteConfirmationModal(false)}
          className="DeleteConfirm"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this card?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteConfirmationModal(false)}
            >
              Cancel
            </Button>
            <Button onClick={handleDeleteContact} variant="danger">
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Family;
