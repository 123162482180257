import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./Loginmodal.css";
import Sucess from "../../../images/WhatsApp_Image_2024-01-11_at_7.31.10_PM-removebg-preview.png";
import { MdErrorOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Loginmodal({
  setIsLoggedIn,
  role,
  isLoggedIn,
  loading,
  error,
  user,
}) {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigation = useNavigate();

  React.useEffect(() => {
    setTimeout(() => {
      if (role === "student") {
        navigation("/Home");
        window.location.reload();
      } else if (role === "admin") {
        navigation("/AdminstratorDashbord");
        window.location.reload();
      } else if (role === "teacher") {
        navigation("/teacherhome");
        window.location.reload();
      } else if (role === "parent") {
        navigation("/parentportal");
        window.location.reload();
      }
    }, 500);
  }, []);
  return (
    <div>
      <Modal
        open={isLoggedIn}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="LogModal"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="Loginmdl-main">
              <img src={Sucess} alt="#" />
              <p>
                COMPLETED <MdErrorOutline />
              </p>
              <h3>Successfully complete!</h3>
              <Spinner animation="border" variant="info" />
              <h4>Preparing next step...</h4>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
