import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import TopSearchBar from "../ADDTopSearchBar/TopSearchBar";
import Sidebar from "../ADDSidebar/Sidebar";
import MyAccountSubscription from "../myaccountSubscription/MyAccountSubscription";
import FullCircleChart from "../Adminstrator/AdminstratorDashbord/CircleChart/FullCircleChart";
import ADDdatausagepages from "../ADDPages/ADDdatausagepages";
import ADDHelpMain from "../ADDHelpCenter/ADDHelpMain";
import ADDAppIntegration from "../ADDAppIntegration/AddAppIntegration";
import Addefficiency from "../Addefficiencyscale/Addefficiency";
import TransationTable from "../TransationTable/TransationTable";
import AddFinancal from "../ADDFinancalOverview/AddFinancal";
import SchoolList from "../schoolList/SchoolList";
import NESDashboard from "../ADDNestedMain/NESDashboard";
import ADDNestedTop from "../ADDNestedMain/ADDNestedTop/ADDNestedTop";
import SchoolCalander from "../../AdminComponents/ADDNestedMain/detailModal/schoolCalander/SchoolCalander";
import ChatChatBox from "../../AdminComponents/ADDNestedMain/ADDChatBox/ADDChatBox";
import Adiminstratorpicha from "../Adiminstratorpicha/Adiminstratorpicha";
import ADDNestedSideBar from "../ADDNestedMain/ADDNestedSideBAr/ADDNSidebar";
import ADDProfile from "../ADDNestedMain/ADDTabsPage/ADDProfile";
import AdminstratorDashbord from "../Adminstrator/AdminstratorDashbord/AdminstratorDashbord";
import ADDSchool from "../ADDNestedMain/ADDSchool-info/ADDSchool";
import EditSchoolInfo from "../ADDEditSclINFO/EditSchoolInfo";
// import ADDCalender from "../ADDNestedMain/ADDCalenderPage/ADDCalender";
import ADDEditStudentinfo from "../ADDNestedMain/StudentsEditInfo/ADDEditStudentinfo";
import EditStudentEnrolmentInfo from "../ADDNestedMain/EditstudentEnrolmentInfo/EditStudentEnrolmentInfo";
import Editstafprofile from "../ADDNestedMain/ADDStaffProfile/Editstafprofile/EditStafProfile";
import EditStafAddress from "../ADDNestedMain/ADDStaffProfile/ADDStaffADDress/EditStafAddress/EditStafAddress";
import Academicplan from "../ADDAcademic-planing/Academicplan";
import AddReport from "../ADDNestedMain/Addreport/AddReport";
import StudentsGrades from "../ADDNestedMain/StudentsGrades/StudentsGrades";
import Classroom from "../ADDNestedMain/classRooms/Classroom";
import PageAttendence from "../ADDNestedMain/AttendencePage/PageAttendence";
import VendorsOverview from "../VendorsOverview/VendorsOverview";
import CardViewMain from "../ADDNestedMain/StudentsCardView/CardViewMain";
import ImportData from "../ADDNestedMain/StudentsCardView/ImportData/ImportData";
import StaffProfile from "../ADDNestedMain/ADDStaffProfile/StaffProfile";
import StudentsView from "../ADDNestedMain/ParentTable/StudentsView/StudentsView";
import StaffView from "../StaffTableView/StudentsView/StaffView";
import Addparant from "../ADDNestedMain/Addparantform/Addparant";
import Addparanteditinfo from "../ADDNestedMain/Addparantform/Addparanteditinfo/Addparanteditinfo";
import AddSchoolInfo from "../ADDNestedMain/ADDNewSchool/AddSchoolInfo";
import AddNewStaff from "../ADDNestedMain/ADDStaffProfile/Editstafprofile/AddNewStaff/AddNewStaffInfo";
import AddNewparanteditinfo from "../ADDNestedMain/Addparantform/AddNewparanteditinfo/AddNewparanteditinfo";
import ADDNewStudentinfo from "../ADDNestedMain/ADDTabsPage/AddNewStudentsEditInfo/ADDNewStudentinfo";
import ManageMain from "../ADDNestedMain/Manage/ManageMain";
import Myassingmentsubject from "../ADDNestedMain/myasingmentsubject/Myassingmentsubject";
import ScheduleData from "../ADDNestedMain/ADDSheduleData/ScheduleData";
import MyRooms from "../ADDNestedMain/AddRooms/MyRooms";
import Calender from "../ADDNestedMain/ADDTabsPage/CalenderPage/Calender";
import AddCourse from "../ADDNestedMain/ADDCourse/AddCourse";
import ManageSemester from "../ADDNestedMain/ADDSemester/ManageSemester";
import NewsUpdate from "../NewsAndUpdate/NewsUpdate";
import NewClassroom from "../ADDNestedMain/AddNewClassroom/NewClassroom";
import SchoolPopulation from "../SchoolPopulation/SchoolPopulation";
import ChatRequest from "../ADDNestedMain/ChatRequest/ChatRequest";
import TabBook from "../ADDNestedMain/TabGradeBook/TabBook";
import AddEvent from "../ADDNestedMain/ADDTabsPage/CalenderPage/AddEvent";
import axios from "axios";
import { baseURL } from "../../../constants";
import { useDispatch } from "react-redux";
import { getUserProfile } from "../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;

function ADDrouting() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  return (
    <div>
      <Routes>
        <Route
          path="/AdminstratorDashbord"
          element={
            <PrivateRoute component={<AdminstratorDashbordWithSideBar />} />
          }
        />
        <Route
          path="/MyAccountSub"
          element={
            <PrivateRoute component={<MyAccountSubscriptionWithSideBar />} />
          }
        />
        <Route
          path="/FullCircleChart"
          element={<PrivateRoute component={<FullCircleChartWithSideBar />} />}
        />
        <Route
          path="/ADDdatausagepages"
          element={
            <PrivateRoute component={<ADDdatausagepagesWithSideBar />} />
          }
        />
        <Route
          path="/ADDHelpMain"
          element={<PrivateRoute component={<ADDHelpMainWithSideBar />} />}
        />
        <Route
          path="/Addefficiency"
          element={<PrivateRoute component={<AddefficiencyWithSideBar />} />}
        />
        <Route
          path="/AddAppIntegration"
          element={
            <PrivateRoute component={<ADDAppIntegrationWithSideBar />} />
          }
        />
        <Route
          path="/TransationTable"
          element={<PrivateRoute component={<TransationTableWithSideBar />} />}
        />
        <Route
          path="/ADDFinancal"
          element={<PrivateRoute component={<AddFinancalWithSideBar />} />}
        />
        <Route
          path="/SchoolList"
          element={<PrivateRoute component={<SchoolListWithSideBar />} />}
        />
        <Route
          path="/ADDNewSchool"
          element={<PrivateRoute component={<EditSchoolInfoWithSideBar />} />}
        />
        <Route
          path="/ADDNewSchool/:schoolId"
          element={<PrivateRoute component={<EditSchoolInfoWithSideBar />} />}
        />
        <Route
          path="/AddnewSchool1"
          element={<PrivateRoute component={<AddSchoolInfoWithSideBar />} />}
        />
        <Route
          path="/Adiminstratorpicha"
          element={
            <PrivateRoute component={<AdiminstratorpichaWithSideBar />} />
          }
        />
        <Route
          path={`/NestedDashbord`}
          element={<PrivateRoute component={<NESDashboardWithSideBar />} />}
        />
        <Route
          path="/SchoolNotice"
          element={<PrivateRoute component={<SchoolCalanderWithSideBar />} />}
        />
        <Route
          path="/ChatChatBox"
          element={<PrivateRoute component={<ChatChatBoxWithSideBar />} />}
        />
        {/* <Route
          path="/ADDCalender"
          element={<PrivateRoute component={<ADDCalenderWithSideBar />} />}
        /> */}
        <Route
          path="/SchoolInformation"
          element={<PrivateRoute component={<ADDSchoolWithSideBar />} />}
        />
        <Route
          path="/EditStudentInfo"
          element={
            <PrivateRoute component={<ADDEditStudentinfoWithSideBar />} />
          }
        />
        <Route
          path="/EditEnrolmentInfo"
          element={
            <PrivateRoute component={<EditStudentEnrolmentInfoWithSideBar />} />
          }
        />
        <Route
          path="/EditStaffProfile"
          element={<PrivateRoute component={<EditstafprofileWithSideBar />} />}
        />
        <Route
          path="/EditStafAddress"
          element={<PrivateRoute component={<EditStafAddressWithSideBar />} />}
        />
        <Route
          path="/Academicplan"
          element={<PrivateRoute component={<AcademicplanWithSideBar />} />}
        />
        <Route
          path="/AddReports"
          element={<PrivateRoute component={<AddReportWithSideBar />} />}
        />
        <Route
          path="/StudentsGrades"
          element={<PrivateRoute component={<StudentsGradesWithSideBar />} />}
        />
        <Route
          path="/ADDClassroom"
          element={<PrivateRoute component={<ClassroomWithSideBar />} />}
        />
        <Route
          path="/PageAttendence"
          element={<PrivateRoute component={<PageAttendenceWithSideBar />} />}
        />
        <Route
          path="/VendorsOverview"
          element={<PrivateRoute component={<VendorsOverviewWithSideBar />} />}
        />
        <Route
          path="/CardViewMain/:role"
          element={<PrivateRoute component={<CardViewMainWithSideBar />} />}
        />
        <Route
          path="/ImportData"
          element={<PrivateRoute component={<ImportDataWithSideBar />} />}
        />
        <Route
          path="/StudentProfile/:studentId"
          element={<PrivateRoute component={<ADDProfileWithSideBar />} />}
        />
        <Route
          path="/StaffProfile/:staffId"
          element={<PrivateRoute component={<StaffProfileWithSideBar />} />}
        />
        <Route
          path="/ParentTable"
          element={<PrivateRoute component={<StudentsViewWithSideBar />} />}
        />
        <Route
          path="/StaffView"
          element={<PrivateRoute component={<StaffViewWithSideBar />} />}
        />
        <Route
          path="/Addparant/:parentId"
          element={<PrivateRoute component={<AddparantWithSideBar />} />}
        />
        <Route
          path={`/Addparanteditinfo/:parentId`}
          element={
            <PrivateRoute component={<AddparanteditinfoWithSideBar />} />
          }
        />
        <Route
          path="/AddNewStaff"
          element={<PrivateRoute component={<AddNewStaffWithSideBar />} />}
        />
        <Route
          path="/AddNEwParent"
          element={
            <PrivateRoute component={<AddNewparanteditinfoWithSideBar />} />
          }
        />
        <Route
          path="/AddNewStudent"
          element={
            <PrivateRoute component={<ADDNewStudentinfoWithSideBar />} />
          }
        />
        <Route
          path="/ManageAcademics"
          element={<PrivateRoute component={<ManageMainWithSideBar />} />}
        />
        <Route
          path="/ManageSubjects"
          element={
            <PrivateRoute component={<MyassingmentsubjectWithSideBar />} />
          }
        />
        <Route
          path="/ScheduleData"
          element={<PrivateRoute component={<ScheduleDataWithSideBar />} />}
        />
        <Route
          path="/Rooms"
          element={<PrivateRoute component={<MyRoomsWithSideBar />} />}
        />
        <Route
          path="/DashboardCalender"
          element={<PrivateRoute component={<CalenderWithSideBar />} />}
        />
        <Route
          path="/AddCourse"
          element={<PrivateRoute component={<AddCourseWithSideBar />} />}
        />
        <Route
          path={`/AddSemester`}
          element={<PrivateRoute component={<ManageSemesterWithSideBar />} />}
        />
        <Route
          path={`/NewsAndUpdate`}
          element={<PrivateRoute component={<NewsUpdateWithSideBar />} />}
        />
        <Route
          path={`/CreateClassRoom`}
          element={<PrivateRoute component={<NewClassroomWithSideBar />} />}
        />
        <Route
          path={`/FullChart`}
          element={<PrivateRoute component={<SchoolPopulationWithSideBar />} />}
        />
        <Route
          path={`/ChatRequest`}
          element={<PrivateRoute component={<ChatRequestWithSideBar />} />}
        />
        <Route
          path={`/AddCalendarEvent`}
          element={<PrivateRoute component={<AddEventWithSideBar />} />}
        />
        <Route
          path={`/AddTabBook`}
          element={<PrivateRoute component={<TabBookWithSideBar />} />}
        />
      </Routes>
    </div>
  );
}
function PrivateRoute({ component }) {
  const isAuthenticated = true;

  if (isAuthenticated) {
    return component;
  } else {
    return <Navigate to="/Login" replace />;
  }
}

function AdminstratorDashbordWithSideBar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <AdminstratorDashbord />
    </Sidebar>
  );
}

function MyAccountSubscriptionWithSideBar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <MyAccountSubscription />
    </Sidebar>
  );
}

function FullCircleChartWithSideBar() {
  const [scores, setScores] = useState(null);
  console.log(scores);
  const getScores = async () => {
    try {
      const { data } = await axios.get("/centralitys/getCentralityScore", {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setScores(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getScores();
  }, []);

  return (
    <Sidebar>
      <TopSearchBar />
      {/* {scores && (
        <FullCircleChart
          data1={scores.appCentrality}
          data2={scores.vendorCentrality}
        />
      )} */}
      <FullCircleChart />
    </Sidebar>
  );
}

function ADDdatausagepagesWithSideBar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <ADDdatausagepages />
    </Sidebar>
  );
}
function NewsUpdateWithSideBar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <NewsUpdate />
    </Sidebar>
  );
}
function ADDHelpMainWithSideBar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <ADDHelpMain />
    </Sidebar>
  );
}
function AddefficiencyWithSideBar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <Addefficiency />
    </Sidebar>
  );
}

function ADDAppIntegrationWithSideBar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <ADDAppIntegration />
    </Sidebar>
  );
}
function TransationTableWithSideBar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <TransationTable />
    </Sidebar>
  );
}

function AddFinancalWithSideBar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <AddFinancal />
    </Sidebar>
  );
}
function SchoolListWithSideBar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <SchoolList />
    </Sidebar>
  );
}
function AdiminstratorpichaWithSideBar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <Adiminstratorpicha />
    </Sidebar>
  );
}
function VendorsOverviewWithSideBar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <VendorsOverview />
    </Sidebar>
  );
}
function NESDashboardWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <NESDashboard />
    </ADDNestedSideBar>
  );
}

// function ADDCalenderWithSideBar() {
//   return (
//     <ADDNestedSideBar>
//       <ADDNestedTop />
//       <ADDCalender />
//     </ADDNestedSideBar>
//   );
// }
function SchoolCalanderWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <SchoolCalander />
    </ADDNestedSideBar>
  );
}
function AddEventWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <AddEvent />
    </ADDNestedSideBar>
  );
}
function ChatChatBoxWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <ChatChatBox />
    </ADDNestedSideBar>
  );
}
function ChatRequestWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <ChatRequest />
    </ADDNestedSideBar>
  );
}
function TabBookWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <TabBook />
    </ADDNestedSideBar>
  );
}

function CardViewMainWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <CardViewMain />
    </ADDNestedSideBar>
  );
}

function ADDSchoolWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <ADDSchool />
    </ADDNestedSideBar>
  );
}
function EditSchoolInfoWithSideBar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <EditSchoolInfo />
    </Sidebar>
  );
}
function AddSchoolInfoWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <AddSchoolInfo />
    </ADDNestedSideBar>
  );
}

function ADDNewStudentinfoWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <ADDNewStudentinfo />
    </ADDNestedSideBar>
  );
}
function ADDEditStudentinfoWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <ADDEditStudentinfo />
    </ADDNestedSideBar>
  );
}
function StaffViewWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <StaffView />
    </ADDNestedSideBar>
  );
}
function EditStudentEnrolmentInfoWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <EditStudentEnrolmentInfo />
    </ADDNestedSideBar>
  );
}

function StaffProfileWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <StaffProfile />
    </ADDNestedSideBar>
  );
}

function EditstafprofileWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <Editstafprofile />
    </ADDNestedSideBar>
  );
}
function EditStafAddressWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <EditStafAddress />
    </ADDNestedSideBar>
  );
}

function AcademicplanWithSideBar() {
  return (
    <Sidebar>
      <TopSearchBar />
      <Academicplan />
    </Sidebar>
  );
}
function AddReportWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <AddReport />
    </ADDNestedSideBar>
  );
}
function StudentsGradesWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <StudentsGrades />
    </ADDNestedSideBar>
  );
}

function ClassroomWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <Classroom />
    </ADDNestedSideBar>
  );
}

function PageAttendenceWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <PageAttendence />
    </ADDNestedSideBar>
  );
}

function ImportDataWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <ImportData />
    </ADDNestedSideBar>
  );
}
function ADDProfileWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <ADDProfile />
    </ADDNestedSideBar>
  );
}

function StudentsViewWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <StudentsView />
    </ADDNestedSideBar>
  );
}
function AddparantWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <Addparant />
    </ADDNestedSideBar>
  );
}

function AddparanteditinfoWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <Addparanteditinfo />
    </ADDNestedSideBar>
  );
}
function AddNewStaffWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <AddNewStaff />
    </ADDNestedSideBar>
  );
}
function AddNewparanteditinfoWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <AddNewparanteditinfo />
    </ADDNestedSideBar>
  );
}
function ManageMainWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <ManageMain />
    </ADDNestedSideBar>
  );
}
function MyassingmentsubjectWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <Myassingmentsubject />
    </ADDNestedSideBar>
  );
}
function ScheduleDataWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <ScheduleData />
    </ADDNestedSideBar>
  );
}
function MyRoomsWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <MyRooms />
    </ADDNestedSideBar>
  );
}

function CalenderWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <Calender />
    </ADDNestedSideBar>
  );
}
function AddCourseWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <AddCourse />
    </ADDNestedSideBar>
  );
}
function ManageSemesterWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <ManageSemester />
    </ADDNestedSideBar>
  );
}
function NewClassroomWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <NewClassroom />
    </ADDNestedSideBar>
  );
}
function SchoolPopulationWithSideBar() {
  return (
    <ADDNestedSideBar>
      <ADDNestedTop />
      <SchoolPopulation />
    </ADDNestedSideBar>
  );
}
export default ADDrouting;
