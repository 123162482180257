import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./TestScore.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../../../constants";
import { Dropdown, Accordion } from "react-bootstrap";
import { Autocomplete, TextField } from "@mui/material";

axios.defaults.baseURL = baseURL;
function TestScore() {
  const [gradeScore, setGradeScore] = useState(null);
  const [series, setSeries] = useState([0]);
  const [loading, setLoading] = useState(true);

  const getGradeScore = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get("/gardescores/getGradeScore", {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });

      setGradeScore(data);
      setSeries([data[0]?.weekly]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getGradeScore();
  }, []);

  const [chartWidth, setChartWidth] = useState("100%");
  const [timeFrame, setTimeFrame] = useState("Kindergarten");

  const { schoolId } = useSelector((state) => state.schoolId);

  const [totalPercentage, setTotalPercentage] = useState(0);

  const [subjectsArr, setSubjectsArr] = useState([]);
  const getMyGradeScore = async (c) => {
    try {
      let url = "";

      switch (c) {
        case "gradeLevel":
          url = `/grades/examStatistics/${schoolId}?gradeLevel=${selectedGradeLevel}`;
          break;

        default:
          break;
      }
      const { data } = await axios.get(url, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      let newObj = {
        [timeFrame]: {},
      };

      data?.subjectAverages.forEach((subject) => {
        newObj[timeFrame][subject.subject] = subject.percentage;
      });

      if (typeof data?.totalAveragePercentage) {
        setTotalPercentage(data?.totalAveragePercentage);
      }

      let arr = [...data?.subjectAverages];

      setSubjectsArr(arr);

      if (Number(data?.totalAveragePercentage?.replace("%", ""))) {
        setSeries([Number(data?.totalAveragePercentage?.replace("%", ""))]);
      } else {
        setSeries([0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMyGradeScore();
  }, [timeFrame, schoolId]);

  const options = {
    chart: {
      type: "radialBar",
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: "97%",
          margin: 5,
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: "#999",
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "22px",
          },
        },
      },
    },

    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    labels: ["Average Results"],
  };

  const chartContainerStyle = {
    width: chartWidth,
  };

  const [teacher, setTeacher] = useState([]);
  const [selectedGradeLevel, setSelectedGradeLevel] = useState("");

  const getTeachers = async () => {
    try {
      const { data } = await axios.get(`/teachers/all/${schoolId}`, {
        headers: {
          accessToken: localStorage.getItem("token"),
        },
      });
      setTeacher(data.teacherList);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTeachers();
  }, [schoolId]);

  const [grade, setGrade] = useState([]);

  const getGrade = async () => {
    try {
      const { data } = await axios.get(
        `/students/getAllGradeLevels/${schoolId}`,
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );
      setGrade(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGrade();
  }, [schoolId]);

  const [subject, setSubject] = useState([]);
  const getSubject = async () => {
    try {
      const { data } = await axios.get(`/subjects/getSubjects/${schoolId}`, {
        headers: {
          accessToken: localStorage.getItem("token"),
        },
      });
      setSubject(data.subjects);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSubject();
  }, [schoolId]);

  return (
    <div className="TestScore-Main">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%" }}>
          <div className="NesLineChart">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Filter
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown>
                  <div className="DropDownBAckGround">
                    <Accordion>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Grade Level</Accordion.Header>
                        <Accordion.Body>
                          {grade.map((gra, index) => (
                            <div
                              onClick={() => setSelectedGradeLevel(gra)}
                              className="LineCheckBox"
                              key={index}
                            >
                              <input
                                checked={gra === selectedGradeLevel}
                                type="checkbox"
                              />
                              <h3>{gra}</h3>
                            </div>
                          ))}
                          <div className="LineButton">
                            <button
                              onClick={() => {
                                getMyGradeScore("gradeLevel");
                              }}
                            >
                              Done
                            </button>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Teacher</Accordion.Header>
                        <Accordion.Body>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={teacher.map((s) => s.staffName)}
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                }}
                              />
                            )}
                          />
                          <div className="LineButton">
                            <button>Done</button>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>Subjects</Accordion.Header>
                        <Accordion.Body>
                          {subject.map((sub, index) => (
                            <div className="LineCheckBox" key={index}>
                              <input type="checkbox" />
                              <h3>{sub?.subjectName}</h3>
                            </div>
                          ))}
                          <div className="LineButton">
                            <button>Done</button>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </Dropdown>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {!loading && (
            <ReactApexChart
              options={options}
              series={series}
              type="radialBar"
            />
          )}
        </div>
      </div>
      <div className="ChildChartsContainer">
        {subjectsArr.map((sub) => {
          return (
            <div className="ChildChartsContChild">
              <CircularProgressbar
                text={`${Number(sub?.percentage?.replace("%", "")).toFixed(
                  0
                )}%`}
                value={Number(sub?.percentage?.replace("%", ""))}
              />
              <label>{sub?.subject}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TestScore;
