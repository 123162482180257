import toast from "react-hot-toast";
import validator from "validator";

const isValidName = (value, name) => {
  if (!/[a-zA-Z\s]/.test(value)) {
    toast.error(`${name} can only contain alphabetic characters and spaces.`);
    return false;
  } else {
    return true;
  }
};

const isValidAmericanPhoneNumber = (value, name) => {
  if (!/^(?:\+?1[-.●]?)?\(?(\d{3})\)?[-.●]?(\d{3})[-.●]?(\d{4})$/.test(value)) {
    toast.error(`${name} is not a American number`);
    return false;
  } else {
    return true;
  }
};

const requiredValues = (values) => {
  let isValid = true;
  values.some((value) => {
    if (!value.value) {
      toast.error(`${value.name} is required`);
      isValid = false;
      return true; // Exit the loop early
    }
    return false; // Continue iterating if no invalid value found
  });
  return isValid;
};
const isValidPassword = (value) => {
  if (value.length < 8) {
    toast.error(`Password must be atleast 8 characters`);
    return false;
  } else {
    return true;
  }
};

const isValidEmail = (value) => {
  if (!validator.isEmail(value)) {
    toast.error("Please provide a valid email");
    return false;
  } else {
    return true;
  }
};

export {
  isValidName,
  isValidAmericanPhoneNumber,
  requiredValues,
  isValidPassword,
  isValidEmail,
};
