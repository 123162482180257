import React, { useState, useEffect, useRef } from "react";
import { Doughnut } from "react-chartjs-2";
import { DropdownButton, Dropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { MdErrorOutline } from "react-icons/md";
import "./DonutChart.css";
import axios from "axios";
import { baseURL } from "../../../../constants";
import { CgArrowsExpandRight } from "react-icons/cg";
import { useSelector } from "react-redux";
import { CiFilter } from "react-icons/ci";
import Accordion from "react-bootstrap/Accordion";
import { BsSliders } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import { Chart } from "react-google-charts";
import BarChart from "../ADDdonutChart/Charttt/BarChart";
import CanvasJSReact from "@canvasjs/react-charts";
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
axios.defaults.baseURL = baseURL;

export const datas = [
  ["", "Trimester", "Quarter"],
  ["1", 1.0, 0.4],
  ["2", 1.17, 0.46],
  ["3", 0.66, 1.12],
  ["4", 1.03, 0.54],
  ["5", 1.0, 0.4],
];

export const optio = {
  legend: { position: "none" },
  vAxis: {
    format: "percent",
    viewWindow: {
      min: 0,
      max: 1.2,
    },
  },
  colors: ["#FF9359", "#00AFEF"],
};

export let dataa = [["Asian", "Hours per Day"]];

export const optionss = {
  title: "Ethnicity Groups",
  pieHole: 0.4,
  is3D: false,
  legend: { position: "none" },
  slices: {
    0: { color: "#00AFEF" },
    1: { color: "#11A529" },
    2: { color: "#FF7E3E" },
    3: { color: "#FA898B" },
    4: { color: "#E34444" },
  },
};

const colors = ["#00AFEF", "#11A529", "#FF7E3E", "#FA898B", "#E34444"];
const DonutChart = () => {
  const [bus, setBus] = useState({});
  const [getterEthinics, setGetterEthinics] = useState({});
  const [myAges, setMyAges] = useState({});
  const [schoolPopulation, setSchoolPopulation] = useState(null);

  const [ages, setAges] = useState([]);
  const [currentTab, setCurrentTab] = useState("gradeLevel");

  const [myData, setMyData] = useState([]);

  const getSchoolPopulation = async () => {
    try {
      const { data } = await axios.get(
        "/schoolpopulations/getSchoolPopulation",
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setSchoolPopulation(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchoolPopulation();
  }, []);

  const [timePeriod, setTimePeriod] = useState("Age");
  const [legend, setLegend] = useState("");
  const chartReference = useRef(null);

  const [data, setData] = useState(null);

  const { schoolId } = useSelector((state) => state.schoolId);

  const [currentFilter, setCurrentFilter] = useState("age");
  const getData = async () => {
    try {
      const { data } = await axios.get(
        `/schools/students/stats/${schoolId}?filter=${currentFilter}`,
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );
      setData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, [schoolId, currentFilter]);

  const dataSets = {
    Age: {
      population: data ? data?.age?.map((ag) => ag.age) : [],
      total: !schoolPopulation ? 32 : schoolPopulation[0]?.weekly.total,
      parent: !schoolPopulation ? 10 : schoolPopulation[0]?.weekly.parent,
      staff: !schoolPopulation ? 10 : schoolPopulation[0]?.weekly.staff,
      students: !schoolPopulation ? 12 : schoolPopulation[0]?.weekly.students,
    },
    // ... other data sets
  };
  const [filteredData, setFilteredData] = useState({});

  const chartData = {
    labels:
      currentTab === "gradeLevel"
        ? Object.keys(filteredData)
        : currentTab === "ethnicGroups"
        ? Object.keys(getterEthinics)
        : currentTab === "ageFilter"
        ? Object.keys(myAges)
        : currentTab === "busTravel"
        ? Object.keys(bus)
        : [],
    datasets: [
      {
        data:
          currentTab === "gradeLevel"
            ? Object.keys(filteredData).map((eg) => filteredData[eg])
            : currentTab === "ageFilter"
            ? Object.keys(myAges).map((eg) => myAges[eg])
            : currentTab === "ethnicGroups"
            ? Object.keys(getterEthinics).map((eg) => getterEthinics[eg])
            : currentTab === "busTravel"
            ? Object.keys(bus).map((eg) => bus[eg])
            : [],
        backgroundColor: colors.map((color) => color),
      },
    ],
  };

  const options = {
    cutout: "70%",
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.label}: ${context.raw}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  const handleSelect = (eventKey) => {
    setTimePeriod(eventKey);
  };

  const legendCallback = () => {
    if (chartReference.current && chartReference.current.chartInstance) {
      const chartInstance = chartReference.current.chartInstance;
      const text = [];
      text.push('<ul class="chart-legend">');
      chartInstance.data.labels.forEach((label, index) => {
        const bgColor = chartInstance.data.datasets[0].backgroundColor[index];
        text.push(
          `<li><span style="background-color:${bgColor}; border-radius: 50%; width: 10px; height: 10px; display: inline-block;"></span> ${label}</li>`
        );
      });
      text.push("</ul>");
      return text.join("");
    }
    return "";
  };

  useEffect(() => {
    setLegend(legendCallback());
  }, [dataSets, timePeriod]);

  const [keys, setKeys] = useState([]);

  // const filteredKeys = () => {
  //   setKeys(Object.keys(filteredData))
  // }

  // useEffect(() => {
  //   filteredKeys()
  // }, [filteredData])

  const renderDots = () => {
    const labels =
      currentTab === "gradeLevel"
        ? Object.keys(filteredData)
        : currentTab === "ethnicGroups"
        ? Object.keys(getterEthinics)
        : currentTab === "ageFilter"
        ? Object.keys(myAges)
        : currentTab === "busTravel"
        ? Object.keys(bus)
        : [];
    return labels.map((label, index) => {
      const bgColor = chartData.datasets[0].backgroundColor[index];
      const dataValue = chartData.datasets[0].data[index];
      return (
        <div key={label} className="dot-container">
          <span
            className="dotdonut"
            style={{ backgroundColor: bgColor }}
          ></span>
          <span className="dot-label">{`${label}: ${dataValue}`}</span>
        </div>
      );
    });
  };
  const [gradeLevelOptions, setGradeLevelOptions] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const [secondGradeLevel, setSecondGradeLevel] = useState([]);

  // const [agesFilter, setAgesFilter] = useState([])

  const getSecondGradeLevel = async () => {
    try {
      const { data } = await axios.get(
        `/students/getStudentGradeLevelsAndCounts/${schoolId}`,
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );

      const newArr = [];

      data.gradeLevelCounts.forEach((dt) => {
        newArr.push({ x: dt?.gradeLevel, y: dt?.count });
      });

      setSecondGradeLevel(newArr);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilter = async (dt = null) => {
    try {
      setCurrentTab("gradeLevel");
      const passingData = dt ? dt : selectedFilters;
      const { data } = await axios.post(
        `/students/getGradeLevelfilter/${schoolId}`,
        {
          gradeLevels: passingData,
        },
        {
          headers: {
            accessToken: localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );

      await getSecondGradeLevel();
      setFilteredData(data);

      let t = 0;

      const newK = [];

      Object.entries(data).forEach((i) => {
        newK.push({ x: i[0], y: i[1] });
      });

      Object?.keys(data).forEach((elem) => {
        t += data[elem];
      });
      setMyData(newK);

      setTotal(t);
      console.log(newK, "this is new k");
    } catch (error) {
      console.log(error);
    }
  };

  console.log(myData, "Juu my dataaaa");

  const getGradeLevelOptions = async () => {
    try {
      const { data } = await axios.get(
        `/students/getAllGradeLevels/${schoolId}`,
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );
      setGradeLevelOptions(data);

      const arr = [];

      data.forEach((elem, index) => {
        if (index < 5) {
          arr.push(elem);
        }
      });

      setSelectedFilters(arr);
      handleFilter(arr);
    } catch (error) {
      console.log(error);
    }
  };

  const getAgeGroups = async () => {
    try {
      const { data } = await axios.get(`/students/getAgeGroups/${schoolId}`, {
        headers: {
          accessToken: localStorage.getItem("token"),
        },
      });
      setAges(data.ages);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getGradeLevelOptions();
    getAgeGroups();
  }, [schoolId]);

  const handleChecker = (gd) => {
    setSelectedFilters((prev) => {
      return prev.includes(gd)
        ? prev.filter((id) => {
            return id !== gd;
          })
        : prev.length < 5
        ? [...prev, gd]
        : prev;
    });
  };
  const [filteredAge, setFilteredAge] = useState([]);

  const [filteredEthinics, setFilteredEthinics] = useState([]);

  const [filteredEthinics2, setFilteredEthinics2] = useState([]);
  const handleCheckerAge = (age) => {
    setFilteredAge((prev) => {
      return prev.includes(age)
        ? prev.filter((id) => {
            return id !== age;
          })
        : prev.length < 5
        ? [...prev, age]
        : prev;
    });
  };

  const handleFilterEthinics = (age) => {
    setFilteredEthinics((prev) => {
      return prev.includes(age)
        ? prev.filter((id) => {
            return id !== age;
          })
        : prev.length < 5
        ? [...prev, age]
        : prev;
    });
  };
  const handleFilterEthinics2 = (age) => {
    setFilteredEthinics2((prev) => {
      return prev.includes(age)
        ? prev.filter((id) => {
            return id !== age;
          })
        : [...prev, age];
    });
  };

  const [total, setTotal] = useState(0);

  const [ethinicGroups, setEthinicGroups] = useState([]);

  const [secondEthin, setSecondEthin] = useState([]);

  const [fil2, setFil2] = useState({});

  const [ethinicContainer, setEthinicContainer] = useState([
    "Asian",
    "Hours per Day",
  ]);

  const handleFilteredEthinics2 = async (dt) => {
    try {
      const { data } = await axios.post(
        `/students/EthnicGroupsfilter/${schoolId}`,
        {
          ethnicGroups: dt ? dt : filteredEthinics2,
        },
        {
          headers: {
            accessToken: localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );

      let main = [["Asian", "Hours per Day"]];

      for (let k in data) {
        main.push([k, data[k]]);
      }

      setEthinicContainer(main);

      console.log(data, "Mainer");
    } catch (error) {
      console.log(error);
    }
  };

  const [allEthins, setAllEthins] = useState([]);

  const getEthinicGroups = async () => {
    try {
      const { data } = await axios.get(
        `/students/getAllEthnicGroups/${schoolId}`,
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );
      console.log(data, "Nay la");

      setSecondEthin(data?.students);
      setEthinicGroups(data?.students);
      setAllEthins(data?.students);
      setFilteredEthinics2(data?.students);
      await handleFilteredEthinics2(data?.students);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEthinicGroups();
  }, [schoolId]);

  // const getDiscipline = async () => {
  //    try {
  //      const { data } = await axios.get(`/disciplines/getDisciplineStatistics/${schoolId}`, {
  //        headers: {
  //          accessToken: localStorage.getItem("token"),

  //       }
  //     })
  //     console.log(data, "disciplines")
  //    } catch (error) {
  //     console.log(error)
  //    }
  // }

  // useEffect(() => {
  //    getDiscipline()

  const [totalAges, setTotalAges] = useState(0);

  const [secondAges, setSecondAges] = useState([]);

  const handleSecondAges = async () => {
    try {
      const { data } = await axios.get(
        `/students/getStudentAgesAndCounts/${schoolId}`,
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );
      console.log(data, "this is second age");
      const newArr = [];

      console.log(data.ageCounts, "juu naa baal");
      data?.ageCounts?.forEach((dt) => {
        newArr.push({ x: dt?.age?.toString(), y: dt?.count });
      });

      setSecondAges(newArr);
      console.log(data, "Second ages");
    } catch (error) {
      console.log(error);
    }
  };

  const handleAgesFilter = async () => {
    try {
      setCurrentTab("ageFilter");
      const { data } = await axios.post(
        `/students/getAgefilter/${schoolId}`,
        {
          ages: filteredAge,
        },
        {
          headers: {
            accessToken: localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );
      let total = 0;
      setMyAges(data);

      Object.keys(data).forEach((eg) => {
        total += data[eg];
      });

      setTotalAges(total);
      await handleSecondAges();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleAgesFilter();
  }, [schoolId]);

  const [lgShow, setLgShow] = useState(false);

  console.log(filteredEthinics, "These are filtered ethincs");

  const totall = ethinicContainer
    .slice(1)
    .reduce((acc, curr) => acc + curr[1], 0);
  const labels = ethinicContainer.slice(1).map((item, index) => ({
    task: item[0],
    percentage: ((item[1] / totall) * 100).toFixed(2) + "%",
    color: colors[index],
  }));

  const optionsss = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2",
    axisX: {
      labelFormatter: function (e) {
        const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
        return weekdays[e.value - 1];
      },
    },
    data: [
      {
        type: "line",
        lineColor: "#00afef",
        markerColor: "#03AB00",
        dataPoints: [
          { x: 1, y: 64 },
          { x: 2, y: 61 },
          { x: 3, y: 64 },
          { x: 4, y: 62 },
          { x: 5, y: 64 },
          { x: 6, y: 60 },
          { x: 7, y: 58 },
        ],
      },
    ],
  };

  // const [filtersEthinics, setFiltersEthinics] = useState({})

  const [totalEthinics, setTotalEthinics] = useState(0);

  const [secondEthinics, setSecondEthinics] = useState({});

  const handleSecondEthinics = async () => {
    try {
      dataa = [["Asian", "Hours per Day"]];
      const { data } = await axios.get(
        `/students/getAllEthnicGroupsAndCounts/${schoolId}`,
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );

      setSecondEthinics(data);

      data?.forEach((st) => {
        dataa.push([st?.ethnicGroup, st?.count]);
      });
    } catch (error) {
      console.log(error);
    }
  };
  console.log(secondEthinics, "these are second");
  const handleFilteredEthinics = async () => {
    try {
      setCurrentTab("ethnicGroups");
      const { data } = await axios.post(
        `/students/EthnicGroupsfilter/${schoolId}`,
        {
          ethnicGroups: filteredEthinics,
        },
        {
          headers: {
            accessToken: localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );

      await handleSecondEthinics();

      setGetterEthinics(data);
      let t = 0;

      Object?.keys(data).forEach((elem) => {
        t += data[elem];
      });

      setTotalEthinics(t);
    } catch (error) {
      console.log(error);
    }
  };

  const [travelCount, setTravelCount] = useState(0);

  const handleBusTravelar = async () => {
    try {
      setCurrentTab("busTravel");
      const { data } = await axios.get(
        `/students/getStudentsByTravelByBus/${schoolId}`,
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );
      setBus(data?.counts);
      let t = 0;

      // Object?.keys(data?.counts).forEach((elem) => {
      //   t += data?.counts[elem]
      // })

      setTravelCount(data.counts.totalStudents);
    } catch (error) {
      console.log(error);
    }
  };

  const [isSelectAll, setIsSelectAll] = useState(false);

  useEffect(() => {
    if (isSelectAll) {
      setFilteredEthinics2(allEthins);
    } else {
      setFilteredEthinics2([]);
    }
  }, [isSelectAll]);
  return (
    <div
      style={{ position: "relative", maxWidth: "100%", margin: "auto" }}
      className="Donut-Main"
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <h5>
          {currentTab === "gradeLevel"
            ? "Grade Level"
            : currentTab === "ageFilter"
            ? "Age"
            : currentTab === "busTravel"
            ? "Bus Travel"
            : currentTab === "ethnicGroups"
            ? "Ethnic Groups"
            : ""}{" "}
          <MdErrorOutline />{" "}
          {currentTab !== "busTravel" && (
            <CgArrowsExpandRight onClick={() => setLgShow(true)} />
          )}
        </h5>
        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-xl"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div>
              {currentTab === "ethnicGroups" && (
                <div className="DonUtCHILDTwo" style={{ textAlign: "center" }}>
                  <div>
                    <DropdownButton id="dropdown-basic-button" title="Filters">
                      <Dropdown>
                        <div className="DonutCHILDS">
                          <div className="DONUTTOP">
                            <h2>Filter</h2>
                            <CiFilter />
                          </div>

                          <h3>Ethnicity Groups</h3>
                          <div className="DonutFlex">
                            <input
                              checked={isSelectAll}
                              onChange={() => setIsSelectAll(!isSelectAll)}
                              type="checkbox"
                            />
                            <label>Select All</label>
                          </div>
                          {secondEthin?.map((hikThi) => {
                            return (
                              <div
                                onClick={() => handleFilterEthinics2(hikThi)}
                                className="DonutFlex"
                              >
                                <input
                                  checked={filteredEthinics2.includes(hikThi)}
                                  type="checkbox"
                                />
                                <label>{hikThi}</label>
                              </div>
                            );
                          })}
                          <div className="DonutBtns">
                            <button
                              onClick={() => handleFilteredEthinics2(null)}
                            >
                              Done
                            </button>
                            <button onClick={() => setFilteredEthinics2([])}>
                              Reset
                            </button>
                          </div>
                        </div>
                      </Dropdown>
                    </DropdownButton>
                  </div>
                  <Chart
                    chartType="PieChart"
                    width="100%"
                    height="400px"
                    data={ethinicContainer}
                    options={optionss}
                  />

                  <div style={{ marginTop: "20px" }}>
                    {labels.map((label, index) => (
                      <div
                        key={index}
                        style={{ display: "inline-block", marginRight: "20px" }}
                      >
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: label.color,
                            display: "inline-block",
                            marginRight: "5px",
                          }}
                        ></div>
                        <span>
                          {label.task}: {label.percentage}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {currentTab === "gradeLevel" && (
                <div>
                  <BarChart
                    func={handleFilter}
                    currentTab={currentTab}
                    text={"Grade Level"}
                    data={myData}
                  />
                </div>
              )}
              {currentTab === "ageFilter" && (
                <div>
                  <BarChart
                    currentTab={currentTab}
                    text={"Age"}
                    data={secondAges}
                  />
                </div>
              )}
              {currentTab === "onTrackToGraduate" && (
                <div className="CANvAsChart">
                  <h2>On track to graduate</h2>
                  <CanvasJSChart options={optionsss} />
                </div>
              )}
              {currentTab === "passing" && (
                <div style={{ textAlign: "center", position: "relative" }}>
                  <Accordion>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header className="DonutChild">
                        <h3>Passing Students</h3>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="DonutCHILDS">
                          <div className="DONUTTOP">
                            <h2>Filter</h2>
                            <CiFilter />
                          </div>

                          <h3>Passing Students</h3>
                          <div className="DonutFlex">
                            <input type="checkbox" />
                            <label>Semester</label>
                          </div>
                          <div className="DonutFlex">
                            <input type="checkbox" />
                            <label>Trimester</label>
                          </div>
                          <div className="DonutFlex">
                            <input type="checkbox" />
                            <label>Quarter</label>
                          </div>
                          <div className="DonutFlex">
                            <input type="checkbox" />
                            <label>Yearly</label>
                          </div>
                          <div className="DonutBtns">
                            <button>Done</button>
                            <button>Reset</button>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      zIndex: 1,
                    }}
                  ></div>
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={datas}
                    options={optio}
                  />
                  <div style={{ marginTop: "20px" }}>
                    <div
                      style={{ display: "inline-block", marginRight: "20px" }}
                    >
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#FF9359",
                          display: "inline-block",
                          marginRight: "5px",
                        }}
                      ></div>
                      <span>Trimester</span>
                    </div>
                    <div style={{ display: "inline-block" }}>
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#00AFEF",
                          display: "inline-block",
                          marginRight: "5px",
                        }}
                      ></div>
                      <span>Quarter</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>

        <div className="FilterAll1">
          <div className="FilterAllSvgg">
            <BsSliders />
          </div>

          <DropdownButton id="dropdown-basic-button" title="Filters">
            <Dropdown>
              <div className="DonutFilter">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="DonutChild">
                      <h3>Grade Level</h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="DonutCHILDS">
                        <div className="DONUTTOP">
                          <h2>Filter</h2>
                          <CiFilter />
                        </div>
                        <h3>Grade Level</h3>

                        {gradeLevelOptions &&
                          gradeLevelOptions.map((gradeLevel) => {
                            return (
                              <div
                                onClick={() => handleChecker(gradeLevel)}
                                className="DonutFlex"
                              >
                                <input
                                  checked={selectedFilters.includes(gradeLevel)}
                                  type="checkbox"
                                />
                                <label>{gradeLevel}</label>
                              </div>
                            );
                          })}

                        <div className="DonutBtns">
                          <button onClick={() => handleFilter(null)}>
                            Done
                          </button>
                          <button onClick={() => setSelectedFilters([])}>
                            Reset
                          </button>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header className="DonutChild">
                      <h3>Ethnicity groups</h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="DonutCHILDS">
                        <div className="DONUTTOP">
                          <h2>Filter</h2>
                          <CiFilter />
                        </div>
                        <h3>Ethnicity Groups</h3>

                        {ethinicGroups?.map((hikThi) => {
                          return (
                            <div
                              onClick={() => handleFilterEthinics(hikThi)}
                              className="DonutFlex"
                            >
                              <input
                                checked={filteredEthinics.includes(hikThi)}
                                type="checkbox"
                              />
                              <label>{hikThi}</label>
                            </div>
                          );
                        })}

                        <div className="DonutBtns">
                          <button onClick={handleFilteredEthinics}>Done</button>
                          <button>Reset</button>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header className="DonutChild">
                      <h3>Age</h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="DonutCHILDS">
                        <div className="DONUTTOP">
                          <h2>Filter</h2>
                          <CiFilter />
                        </div>
                        <h3>Age</h3>
                        {ages &&
                          ages.map((age) => {
                            return (
                              <div
                                onClick={() => handleCheckerAge(age)}
                                className="DonutFlex"
                              >
                                <input
                                  checked={filteredAge.includes(age)}
                                  type="checkbox"
                                />
                                <label>{age}</label>
                              </div>
                            );
                          })}
                      </div>
                      <div className="DonutBtns">
                        <button onClick={handleAgesFilter}>Done</button>
                        <button onClick={() => setFilteredAge([])}>
                          Reset
                        </button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header className="DonutChild">
                      <h3>Passing Students</h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="DonutCHILDS">
                        <div className="DONUTTOP">
                          <h2>Filter</h2>
                          <CiFilter />
                        </div>
                        <h3>Passing Students</h3>
                        <div className="DonutFlex">
                          <input type="checkbox" />
                          <label>Semester</label>
                        </div>
                        <div className="DonutFlex">
                          <input type="checkbox" />
                          <label>Trimester</label>
                        </div>
                        <div className="DonutFlex">
                          <input type="checkbox" />
                          <label>Quarter</label>
                        </div>
                        <div className="DonutFlex">
                          <input type="checkbox" />
                          <label>Yearly</label>
                        </div>
                        <div className="DonutBtns">
                          <button>Done</button>
                          <button>Reset</button>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header className="DonutChild">
                      <h3>Failing Students</h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="DonutCHILDS">
                        <div className="DONUTTOP">
                          <h2>Filter</h2>
                          <CiFilter />
                        </div>
                        <h3>Failing Student</h3>
                        <div className="DonutFlex">
                          <input type="checkbox" />
                          <label>Semester</label>
                        </div>
                        <div className="DonutFlex">
                          <input type="checkbox" />
                          <label>Trimester</label>
                        </div>
                        <div className="DonutFlex">
                          <input type="checkbox" />
                          <label>Quarter</label>
                        </div>
                        <div className="DonutFlex">
                          <input type="checkbox" />
                          <label>Yearly</label>
                        </div>
                        <div className="DonutBtns">
                          <button>Done</button>
                          <button>Reset</button>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header className="DonutChild">
                      <h3>Excelling Students</h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="DonutCHILDS">
                        <div className="DONUTTOP">
                          <h2>Filter</h2>
                          <CiFilter />
                        </div>
                        <h3>Excelling Students</h3>
                        <div className="DonutFlex">
                          <input type="checkbox" />
                          <label>Semester</label>
                        </div>
                        <div className="DonutFlex">
                          <input type="checkbox" />
                          <label>Trimester</label>
                        </div>
                        <div className="DonutFlex">
                          <input type="checkbox" />
                          <label>Quarter</label>
                        </div>
                        <div className="DonutFlex">
                          <input type="checkbox" />
                          <label>Yearly</label>
                        </div>

                        <div className="DonutBtns">
                          <button>Done</button>
                          <button>Reset</button>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header className="DonutChild">
                      <h3>On track to graduate</h3>
                    </Accordion.Header>
                    <Accordion.Body></Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header className="DonutChild">
                      <h3>On track to flunk</h3>
                    </Accordion.Header>
                    <Accordion.Body></Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="8">
                    <Accordion.Header className="DonutChild">
                      <h3>On track to repeat</h3>
                    </Accordion.Header>
                    <Accordion.Body></Accordion.Body>
                  </Accordion.Item>
                  <button
                    onClick={handleBusTravelar}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      color: "#4E5566",
                      fontSize: "14px",
                      paddingInline: "10px",
                    }}
                  >
                    Traveling By Bus
                  </button>
                </Accordion>
              </div>
            </Dropdown>
          </DropdownButton>
        </div>
      </div>

      <Doughnut ref={chartReference} data={chartData} options={options} />

      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        className="DonutText"
      >
        <h4>Total</h4>
        <h3>
          {currentTab === "gradeLevel"
            ? total
            : currentTab === "ageFilter"
            ? totalAges
            : currentTab === "ethnicGroups"
            ? totalEthinics
            : currentTab === "busTravel"
            ? travelCount
            : 0}
        </h3>
      </div>
      <div className="dot-legend">{renderDots()}</div>
      <div
        dangerouslySetInnerHTML={{ __html: legend }}
        className="LegendContainer"
      />
    </div>
  );
};

export default DonutChart;
