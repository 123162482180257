import React from "react";
import TextEditor from "./TextEditor";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./ComposeMessageModal.css";
import { FiMail } from "react-icons/fi";
import "quill/dist/quill.snow.css";

function ComposeMessageModal() {
  const [show, setShow] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSwitchChange = () => {
    setSwitchChecked(!switchChecked);
  };
  const [uploadedFile, setUploadedFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setUploadedFile({
        name: file.name,
        url: URL.createObjectURL(file),
      });
    }
  };

  const handleContainerClick = () => {
    fileInputRef.current.click();

    setUploadedFile(null);
  };

  const fileInputRef = React.createRef();
  return (
    <div className="CMmodal-main">
      <div onClick={handleShow}>
        <FiMail />
      </div>

      <Modal show={show} onHide={handleClose} className="CMmodal-main0modal">
        <Modal.Header closeButton>
          <Modal.Title>Compose Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label>To</label>
            <br />
            <input type="text" />
          </div>
          <div>
            <label>Subject</label>
            <br />
            <input type="text" />
          </div>
          <div>
            <label>Content</label>
            <TextEditor />
          </div>

          <div className="ComposeUploadAttach" onClick={handleContainerClick}>
            <p>Attachment</p>
            <div className="ComposeUploadAttach-one">
              <div className="ComposeUploadAttach-one0img">
                {uploadedFile && (
                  <img
                    style={{
                      width: "70px",
                      height: "70px",
                      borderRadius: "100%",
                      objectFit: "cover",
                    }}
                    src={uploadedFile.url}
                    alt={uploadedFile.name}
                  />
                )}
              </div>
              <div>
                {!uploadedFile ? (
                  <>
                    <h3>Upload file from your Local Drive</h3>
                    <p>Click here to select a file or drop the file here</p>
                  </>
                ) : (
                  <p>{uploadedFile.url}</p>
                )}
              </div>
            </div>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>
          {/* .................................................................... */}

          <div className="Compose-Switch">
            <span className="Compose-label">Send this as email also</span>
            <label className="switchlabel">
              <input
                type="checkbox"
                checked={switchChecked}
                onChange={handleSwitchChange}
              />
              <span className="sliderspan"></span>
            </label>
          </div>

          <div className="ComposeFooterBtns">
            <button className="ComposeFooterBtn1">Cancel</button>
            <button className="ComposeFooterBtn2">Send Message</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default ComposeMessageModal;
