import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./UploadModal.css";
import { LuUpload } from "react-icons/lu";
import { AiOutlineCloudUpload } from "react-icons/ai";
import toast from "react-hot-toast";
import axios from "axios";
import { baseURL } from "../../../../../../../constants";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;

function UploadModal({
  GetClassData,
  editId = "",
  setEditId = () => {},
  classLecture = [],
}) {
  const [lgShow, setLgShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setSelectedFile(file);
  };

  const [title, setTitle] = useState("");
  const [instructions, setInstructions] = useState("");
  const [date, setDate] = useState("");
  const [period, setPeriod] = useState("");
  const params = useParams();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const handleAddLecture = async () => {
    try {
      const newForm = new FormData();
      newForm.append("title", title);
      newForm.append("instructions", instructions);
      newForm.append("date", date);
      selectedFile && newForm.append("file", selectedFile);
      newForm.append("period", period);

      newForm.append("teacherId", user.dataValues.id);
      newForm.append("subjectId", params?.subjectId);
      newForm.append("classroomId", localStorage.getItem("classroomId"));
      if (editId) {
        const { data } = await axios.put(
          `/lectures/updateLecture/${editId}`,
          newForm,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Lecture Updated Successfully");
        setTitle("");
        setInstructions("");
        setDate("");
        setPeriod("");
        setSelectedFile("");
        setLgShow();
        setEditId("");
        GetClassData();
      } else {
        const { data } = await axios.post(`/lectures/addLecture`, newForm, {
          headers: {
            "Content-Type": "multipart/form-data",
            accessToken: `${localStorage.getItem("token")}`,
          },
        });
        setTitle("");
        setInstructions("");
        setDate("");
        setPeriod("");
        setSelectedFile("");
        setLgShow();
        GetClassData();
        toast.success("Lecture Added Successfully");
      }
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (editId && classLecture) {
      setLgShow(true);
      const findLecture = classLecture?.find((element) => {
        return editId === element?.id;
      });
      setTitle(findLecture?.title);
      setInstructions(findLecture?.instructions);
      setDate(findLecture?.date);
      setPeriod(findLecture?.period);
    }
  }, [editId]);
  return (
    <div className="UploadModal-Main">
      <button onClick={() => setLgShow(true)} className="UploadModal-button">
        Upload new Lecture
      </button>

      <Modal
        size="lg"
        show={lgShow}
        onHide={() => {
          setEditId("");
          setLgShow(false);
          setTitle("");
          setInstructions("");
          setDate("");
          setSelectedFile("");
          setPeriod("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="UploadModal-header" closeButton>
          Upload new Lecture
        </Modal.Header>
        <Modal.Body>
          <div className="UploadModal-body">
            <label>Title</label>
            <br />
            <input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Title"
            />
            <textarea
              value={instructions}
              onChange={(e) => setInstructions(e.target.value)}
              placeholder="Instructuions (Optional)"
            />
            <div className="UploadModal-Select">
              <div className="UploadModal-Div1">
                <label>Date</label>
                <br />
                <input
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  type="date"
                />
              </div>
              <div className="UploadModal-Div2">
                <label>Period</label>
                <br />
                <select
                  value={period}
                  onChange={(e) => setPeriod(e.target.value)}
                >
                  3.
                  <option value={"period 1"}>period 1</option>
                  <option value={"period 2"}>period 2</option>
                  <option value={"period 3"}>period 3</option>
                </select>
              </div>
            </div>
            <div className="UploadModal-Switch">
              <p>
                Create noticeboard announcement for all students in classroom
              </p>
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
              </div>
            </div>
            <div className="UploadModal-last">
              <div onClick={handleDivClick} className="UploadModal-last-1">
                <LuUpload />
                <div className="UploadModal-last-child">
                  <AiOutlineCloudUpload />
                  <h5>Upload</h5>
                </div>
                <h6>
                  {selectedFile

                    ? `Selected File: ${selectedFile.name}`
                    : "File Format jpeg, png Recommended Size 600x600 (1:1)"}
                </h6>
              </div>
              {/* Hidden file input */}
              <input
                type="file"
                accept="image/jpeg, image/png, image/jpg .pdf, .csv"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
              <button onClick={handleAddLecture}>Continue</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default UploadModal;