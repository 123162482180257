import React from "react";
import Welcome from "../WelcomeBack/Welcome";
import RecentGrads from "../RecentGrads/Recentgrades";
import "./Home.css";
import MySchedule from "../Myschedule/MySchedule";
import Classroom from "../classRooms/Classroom";
import GPAcalculatorHome from "../GPAcalculaters/GPAcalculatorHome";
import Chart from "../Chart/ChartComponent";
import { Link } from "react-router-dom";

function HomePage() {
  return (
    <div className="Home-main">
      <div className="Home-70">
        <Welcome />
        <Chart />
        <Classroom showAll={false} />
      </div>
      <div className="Home-30">
        <MySchedule />
        <RecentGrads />
        <GPAcalculatorHome />
        <Link to="/Calanderwork" className="Click">
          <ins style={{ color: "#00AFEF" }}>Click</ins> <span>here for detail</span>
        </Link>
      </div>
    </div>
  );
}

export default HomePage;
