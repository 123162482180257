import React, { useEffect, useState } from "react";
import { MdArrowDropDown } from "react-icons/md";
import "./AddPlan.css";
import axios from "axios";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import Dummy from "../../AdminComponents/images/dummy-user.png";
import Graph from "../../StudentComponents/MyProfile/Group 1000006080.png";
import Green from "../../StudentComponents/MyProfile/Group 1000006079.png";
import Red from "../../StudentComponents/MyProfile/Group 1000006078.png";
import Timer from "../../StudentComponents/MyProfile/Group 1000006077.png";
import { baseURL } from "../../../constants";
import { useSelector } from "react-redux";
import { IoMdArrowBack } from "react-icons/io";
axios.defaults.baseURL = baseURL;

function AddPlan() {
  const [show, setShow] = useState(false);
  const { user } = useSelector((state) => state.getUserProfile);

  const [formData, setFormData] = useState({
    effectiveDate: "",
    nextReviewDate: "",
    leaveDate: "",
    meetingDate: "",
    meetingType: "",
    Consent: "",
    EvaluateReceived: "",
    Right: "",
    SafeguardsDelivered: "",
    Authorization: "",
    ExchangeofInformation: "",
    Description: "",
    Referrer: "",
    PlanFacilitator: "",
    Comment: "",
    TeamDecision: "",
  });

  const {
    effectiveDate,
    nextReviewDate,
    leaveDate,
    meetingDate,
    meetingType,
    Consent,
    EvaluateReceived,
    Right,
    SafeguardsDelivered,
    Authorization,
    ExchangeofInformation,
    Description,
    Referrer,
    PlanFacilitator,
    Comment,
    TeamDecision,
  } = formData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [gpa, setGpa] = useState([]);

  const getGpa = async () => {
    try {
      const { data } = await axios.get(
        `/grades/calculateGPA/${localStorage.getItem("teacherStudent")}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setGpa(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getGpa();
  }, []);

  const [plan, setPlan] = useState(null);

  const getPlan = async () => {
    try {
      const { data } = await axios.get(
        `/plans/getPlansByStudentId/${localStorage.getItem("teacherStudent")}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(data);
      setPlan(data?.plan);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPlan();
  }, []);
  const AddPlan = async () => {
    try {
      const { data } = await axios.post(
        `/plans/add`,
        {
          effectiveDate,
          nextReviewDate,
          leaveDate,
          meetingDate,
          meetingType,
          Consent,
          EvaluateReceived,
          Right,
          SafeguardsDelivered,
          Authorization,
          ExchangeofInformation,
          Description,
          Referrer,
          PlanFacilitator,
          Comment,
          TeamDecision,
          studentId: localStorage.getItem("teacherStudent"),
        },

        {
          headers: {
            "Content-Type": "application/json",
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      getPlan();
      setShow(false);
      setFormData({
        effectiveDate: "",
        nextReviewDate: "",
        leaveDate: "",
        meetingDate: "",
        meetingType: "",
        Consent: "",
        EvaluateReceived: "",
        Right: "",
        SafeguardsDelivered: "",
        Authorization: "",
        ExchangeofInformation: "",
        Description: "",
        Referrer: "",
        PlanFacilitator: "",
        Comment: "",
        TeamDecision: "",
      });
      toast.success("Plan Added Successfully");
    } catch (error) {
      if (error.response?.data?.details) {
        toast.error(
          error.response?.data?.details[0]?.error ||
            error.response?.data?.details[0]?.message ||
            error.response?.data?.details[0]?.detail
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (plan) {
      setFormData({
        effectiveDate: plan?.effectiveDate?.split("T")[0] || "",
        nextReviewDate: plan?.nextReviewDate?.split("T")[0] || "",
        leaveDate: plan?.leaveDate?.split("T")[0] || "",
        meetingDate: plan?.meetingDate?.split("T")[0] || "",
        meetingType: plan?.meetingType,
        Consent: plan?.Consent,
        EvaluateReceived: plan?.EvaluateReceived?.split("T")[0] || "",
        Right: plan?.Right,
        SafeguardsDelivered: plan?.SafeguardsDelivered?.split("T")[0] || "",
        Authorization: plan?.Authorization,
        ExchangeofInformation: plan?.ExchangeofInformation?.split("T")[0] || "",
        Description: plan?.Description,
        Referrer: plan?.Referrer,
        PlanFacilitator: plan?.PlanFacilitator,
        Comment: plan?.Comment,
        TeamDecision: plan?.TeamDecision,
      });
    }
  }, [plan]);

  const [student, setStudent] = useState(null);

  const getStudent = async () => {
    try {
      const { data } = await axios.get(
        `/students/profile/${localStorage.getItem("teacherStudent")}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setStudent(data?.student);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStudent();
  }, []);

  const [assingment, setAssingment] = useState([]);

  const getAssingment = async () => {
    try {
      const { data } = await axios.get(
        `/assignments/getAssignmentCountByStudent/${localStorage.getItem(
          "teacherStudent"
        )}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setAssingment(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAssingment();
  }, []);

  const [completed, setComplted] = useState([]);

  const getCompleted = async () => {
    try {
      const { data } = await axios.get(
        `/assignmentSubmissions/getSubmittedAssignmentsCountByStudent/${localStorage.getItem(
          "teacherStudent"
        )}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setComplted(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCompleted();
  }, []);
  return (
    <div className="AddPlan-Main1">
      <div className="StuProfile-main">
        <div className="StuProfile-Top">
          <div className="StuProfile-Top1">
            <IoMdArrowBack style={{ cursor: "pointer" }} />
            <h1>Student Profile</h1>
          </div>
          <div className="StuProfile-Top2">
            <Link to="/TeacherChat" className="StuProfile-Top-btn1">
              Parent Chat
            </Link>
            <Link to="/TeacherChat" className="StuProfile-Top-btn2">
              Student Chat
            </Link>
            <Link to="/TeacherChat" className="StuProfile-Top-btn2">
              Admin Chat
            </Link>
          </div>
        </div>
        <div className="Myprofile-main-1">
          <div className="Myprofile-main-child-1">
            <div className="profile">
              <div className="profile-child-1">
                <img
                  onError={({ currentTarget }) => {
                    currentTarget.src = Dummy;
                  }}
                  src={`${baseURL}/images/${student?.profilePicture}`}
                />
              </div>
              <div className="profile-child-2">
                <div className="profile-child-2-1">
                  <h2>{student?.firstName + " " + student?.lastName}</h2>
                </div>
                <div className="profile-child-2-2">
                  <p>{student?.program}</p>
                  <button>
                    <span>●</span>
                    {student?.online ? "Online" : "Offline"}
                  </button>
                </div>
                <div className="profile-child-2-3">
                  <p>{student?.loggedInSince?.split("T")[0]}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="Myprofile-main-child-2">
            <div className="Myprofile-main-child-2-1">
              <img src={Timer} alt="#" />
              <h3>{student?.createdAt?.split("T")[0]}</h3>
            </div>
            <div className="Myprofile-main-child-2-2">
              <div className="div-1">
                <img src={Red} alt="#" />
                <h3>
                  Assignments <span>{assingment?.assignmentCount}</span>
                </h3>
              </div>
              <div className="div-2">
                <img src={Green} alt="#" />
                <h3>
                  Completed <span>{completed?.submittedAssignmentsCount}</span>
                </h3>
              </div>
            </div>
          </div>
          <div className="Myprofile-main-child-3">
            <div className="Myprofile-main-child-3-card-1">
              <img src={Graph} alt="#" />
            </div>
            <div className="Myprofile-main-child-3-card-2">
              <h3>GPA</h3>
              <h1>{gpa?.gpa}</h1>
            </div>
          </div>
        </div>
      </div>

      {!show ? (
        <div>
          <div className="AddPlan-Top1">
            <h4>
              504 Plan
              {/* <span>
                7/14/2023 - 8/4/2023 <MdArrowDropDown />
              </span>
              <span>
                New Plan <MdArrowDropDown />
              </span> */}
            </h4>
          </div>
          <div className="AddPlan-Second1">
            <div className="PlanFlex">
              <h2>Plan Details</h2>
              <button onClick={() => setShow(true)}>Add Plan</button>
            </div>
            <div className="Addplan-Flex">
              <h3>
                Effective Date:{" "}
                <span>{plan?.effectiveDate?.split("T")[0]}</span>
              </h3>
              <h3>
                Next Review Date:{" "}
                <span>{plan?.nextReviewDate?.split("T")[0]}</span>
              </h3>
              <h3>
                Leave Date: <span>{plan?.leaveDate?.split("T")[0]}</span>
              </h3>
            </div>
            <div className="Addplan-Flex">
              <h3>
                Meeting Type: <span>{plan?.meetingType}</span>
              </h3>
              <h3>
                Meeting Date: <span>{plan?.meetingDate?.split("T")[0]}</span>
              </h3>
            </div>
            <div className="Addplan-Flex">
              <h3>
                Consent to Evaluate Received:{" "}
                <span>{plan?.Consent == true ? "Yes" : "No"}</span>
              </h3>
              <h3>
                Received Date:{" "}
                <span>{plan?.EvaluateReceived?.split("T")[0]}</span>
              </h3>
            </div>
            <div className="Addplan-Flex">
              <h3>
                Rights & Safeguards Delivered: <span>{plan?.Right}</span>
              </h3>
              <h3>
                Date Delivered:{" "}
                <span>{plan?.SafeguardsDelivered?.split("T")[0]}</span>
              </h3>
            </div>
            <div className="Addplan-Flex">
              <h3>
                Authorization for Exchange of Information:{" "}
                <span>{plan?.Authorization}</span>
              </h3>
              <h3>
                Date Received:{" "}
                <span>{plan?.ExchangeofInformation?.split("T")[0]}</span>
              </h3>
            </div>
            <div className="Addplan-Flex">
              <h3>
                Description of Disability: <span>{plan?.Description}</span>
              </h3>
            </div>
            <div className="Addplan-Flex">
              <h3>
                Team Decision: <span>{plan?.TeamDecision}</span>
              </h3>
            </div>
            <div className="Addplan-Flex">
              <h3>
                Comments: <span>{plan?.Comment}</span>
              </h3>
            </div>
            <div className="Addplan-Flex">
              <h3>
                Referrer: <span>{plan?.Referrer}</span>
              </h3>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="AddPlan-Top1">
            <h4>
              Plan Dates
              <span>
                New Plan <MdArrowDropDown />
              </span>
            </h4>
          </div>
          <div className="AddPLannn1">
            <div className="AddplAnInputs">
              <div className="AddplAnInputs50">
                <label>Effective Date</label>
                <br />
                <input
                  name="effectiveDate"
                  value={formData.effectiveDate}
                  onChange={handleChange}
                  type="date"
                />
              </div>
              <div className="AddplAnInputs50">
                <label>Next Review Date</label>
                <br />
                <input
                  name="nextReviewDate"
                  onChange={handleChange}
                  value={formData.nextReviewDate}
                  type="date"
                />
              </div>
            </div>
            <div className="AddplAnInputs">
              <div className="AddplAnInputs50">
                <label>Leave Date</label>
                <br />
                <input
                  name="leaveDate"
                  onChange={handleChange}
                  value={formData.leaveDate}
                  type="date"
                />
              </div>
              <div className="AddplAnInputs50">
                <label>Meeting Date</label>
                <br />
                <input
                  name="meetingDate"
                  onChange={handleChange}
                  value={formData.meetingDate}
                  type="date"
                />
              </div>
            </div>
            <div className="AddplAnInputs1">
              <label>Meeting Type</label>
              <br />
              <select
                value={formData.meetingType}
                onChange={handleChange}
                name="meetingType"
              >
                <option disabled></option>
                <option value="Amendment (AMD)">Amendment (AMD)</option>
                <option value="Annual Referral (ANR)">
                  Annual Referral (ANR)
                </option>
                <option value="Initial Referral (INR)">
                  Initial Referral (INR)
                </option>
                <option value="Re-evaluation (REV)">Re-evaluation (REV)</option>
              </select>
            </div>
            <div className="AddplAnInputs2">
              <label>Consent to Evaluate</label>
              <br />
              <select
                name="Consent"
                onChange={handleChange}
                value={formData.Consent}
              >
                <option disabled></option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
              <input
                value={formData.EvaluateReceived}
                name="EvaluateReceived"
                onChange={handleChange}
                type="date"
              />
            </div>
            <div className="AddplAnInputs2">
              <label>Rights & Safeguards</label>
              <br />
              <select
                value={formData.Right}
                name="Right"
                onChange={handleChange}
              >
                <option disabled></option>
                <option value="one">Option One</option>
                <option value="two">Option Two</option>
                <option value="three">Option Three</option>
                <option value="four">Option Four</option>
              </select>
              <input
                value={formData.SafeguardsDelivered}
                name="SafeguardsDelivered"
                onChange={handleChange}
                type="date"
              />
            </div>
            <div className="AddplAnInputs2">
              <label>Authorization for Exchange of Information</label>
              <br />
              <select
                value={formData.Authorization}
                name="Authorization"
                onChange={handleChange}
              >
                <option disabled></option>
                <option value="one">Option One</option>
                <option value="two">Option Two</option>
                <option value="three">Option Three</option>
                <option value="four">Option Four</option>
              </select>
              <input
                value={formData.ExchangeofInformation}
                name="ExchangeofInformation"
                onChange={handleChange}
                type="date"
              />
            </div>
            <div className="PlanDescription">
              <label>Description of Impairment</label>
              <br />
              <textarea
                value={formData.Description}
                name="Description"
                onChange={handleChange}
              />
            </div>
            <div className="AddplAnInputs">
              <div className="AddplAnInputs50">
                <label>Referrer</label>
                <br />
                <input
                  value={formData.Referrer}
                  name="Referrer"
                  onChange={handleChange}
                  type="text"
                />
              </div>
              <div className="AddplAnInputs50">
                <label>Plan Facilitator</label>
                <br />
                <select
                  value={formData.PlanFacilitator}
                  name="PlanFacilitator"
                  onChange={handleChange}
                >
                  <option disabled></option>
                  <option value="Grade 0">Grade 0</option>
                  <option value="Grade 1">Grade 1</option>
                  <option value="Grade 2">Grade 2</option>
                  <option value="Grade 3">Grade 3</option>
                </select>
              </div>
            </div>
            <div className="PlanDescription">
              <label>Comment</label>
              <br />
              <textarea
                onChange={handleChange}
                name="Comment"
                value={formData.Comment}
              />
            </div>
            <div className="AddplAnInputs23">
              <label>Team Decision</label>
              <br />
              <select
                value={formData}
                name="TeamDecision"
                onChange={handleChange}
              >
                <option disabled></option>
                <option
                  value="No Longer Meets Eligibility Requirements Based on the
                    Reevaluation and Exited"
                >
                  No Longer Meets Eligibility Requirements Based on the
                  Reevaluation and Exited
                </option>
                <option
                  value="Student Qualifies for Special Ed Services and Needs will be
                    met through an IEP"
                >
                  Student Qualifies for Special Ed Services and Needs will be
                  met through an IEP
                </option>
                <option value="Meets section 504 Eligibility Criteria">
                  Meets section 504 Eligibility Criteria
                </option>
                <option
                  value="Does not Meets section 504 Eligibility Criteria But Considered
                    as individual with Disability"
                >
                  Does not Meets section 504 Eligibility Criteria But Considered
                  as individual with Disability
                </option>
              </select>
            </div>
            <div className="LastPLanBtns">
              <div className="LastPLanBtns1">
                <button
                  onClick={() => {
                    AddPlan();
                  }}
                >
                  Save
                </button>
                <button onClick={() => setShow(false)}>Cancel</button>
              </div>
              <select>
                <option disabled>Print the Plan</option>
                <option value="one">Option One</option>
                <option value="two">Option Two</option>
                <option value="three">Option Three</option>
                <option value="four">Option Four</option>
              </select>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddPlan;
