import React from "react";
import "./Academicplan.css";
import ADDAcademic from "./ADDAcademic";
import { RiEqualizerFill } from "react-icons/ri";
import { GoSearch } from "react-icons/go";

const Academicplan = () => {      
  return (
    <div className="Academicplan-main">
      <div className="Academicplan-main-title">
        <h3>District school Data</h3>
      </div>
      <div className="Academicplan-main-search-filter-button">
        <div className="Academicplan-main-search-filter-button-child-1">
          <div className="search-icone-input">
            <GoSearch />
            <input placeholder="search"></input>
          </div>
          <button>
            <RiEqualizerFill />
            Filters
          </button>
        </div>
        <div className="Academicplan-main-search-filter-button-child-2">
          <button>Send to Govt</button>
        </div>
      </div>
      <div className="Academicplan-main-child-2-card">
        {ADDAcademic.map((item) => (
          <div key={item.id} className="child-2-card-main">
            <div className="img-and-taxt">
              <img src={item.image} alt="img" />
              <div className="img-and-taxt-child">
                <p>Download</p>
                <h3>{item.title}</h3>
              </div>
            </div>
            <div className="child-2-card-main-button">
              <button>
                <img src={item.exportImage} alt="img" /> Export Data
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Academicplan;
