import React, { useState, useEffect } from "react";
import Print from "../../../StudentComponents/Medical/flat-color-icons_print.png";
import { IoIosAddCircle, IoMdArrowBack } from "react-icons/io";
import { IoArrowBack, IoRefreshCircle } from "react-icons/io5";
import "../../StudentProfile/StuProfile.css";
import "./Extra.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import toast from "react-hot-toast";
import axios from "axios";
import dayjs from "dayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import Dummy from "../../../AdminComponents/images/dummy-user.png";
import Graph from "../../../StudentComponents/MyProfile/Group 1000006080.png";
import Green from "../../../StudentComponents/MyProfile/Group 1000006079.png";
import Red from "../../../StudentComponents/MyProfile/Group 1000006078.png";
import Timer from "../../../StudentComponents/MyProfile/Group 1000006077.png";
import { useSelector } from "react-redux";
import { baseURL } from "../../../../constants";
axios.defaults.baseURL = baseURL;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Extra() {
  const [show, setShow] = useState(false);
  const [hours, setHours] = useState("");
  const [previewHours, setPreviewHours] = useState("");

  useEffect(() => {
    if (previewHours) {
      setHours(moment(previewHours.$d).format("HH:mm:ss"));
    }
  }, [previewHours]);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    // setSchoolId("");
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [formData, setFormData] = useState({
    date: "",
    code: "",
    type: "",
    hours: "",
    startTime: "",
    endTime: "",
  });

  const { date, code, type, startTime, endTime } = formData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [editId, setEditId] = useState("");

  useEffect(() => {
    if (previewHours) {
      setHours(moment(previewHours.$d).format("HH:mm:ss"));
    }
  }, [previewHours]);
  const { schoolId } = useSelector((state) => state.schoolId);

  const postActivities = async () => {
    try {
      if (editId) {
        await axios.put(
          `/extracurricularActivitys/updateExtracurricularActivity/${editId}`,
          {
            date,
            code,
            startTime,
            endTime,
            hours,
            type,
            schoolId,
            studentId: localStorage.getItem("teacherStudent"),
            isDeleted: false,
          },

          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Activity Edited Successfully");
      } else {
        await axios.post(
          `/extracurricularActivitys/add`,
          {
            date,
            code,
            startTime,
            endTime,
            type,
            hours,
            studentId: localStorage.getItem("teacherStudent"),
            schoolId,
            isDeleted: false,
          },

          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Activity Added Successfully");
      }

      setEditId("");
      setShow(false);

      getActivity();
      setFormData({
        date: "",
        code: "",
        startTime: "",
        endTime: "",
        hours: "",
        studentId: "",
      });
    } catch (error) {
      if (error.response?.data?.details) {
        toast.error(
          error.response?.data?.details[0]?.error ||
            error.response?.data?.details[0]?.message ||
            error.response?.data?.details[0]?.detail
        );
      } else {
        toast.error(error?.response?.data?.error);
      }
      console.log(error);
    }
  };

  const clearData = () => {
    setFormData({
      date: "",
      code: "",
      startTime: "",
      endTime: "",
      type: "",
      hours: "",
    });
  };

  const [activities, setActivities] = useState([]);
  const getActivity = async () => {
    try {
      const { data } = await axios.get(
        `/extracurricularActivitys/getExtracurricularActivitiesByStudentId/${localStorage.getItem(
          "teacherStudent"
        )}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setActivities(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getActivity();
  }, []);

  console.log(activities, "This is My Teacher Data");
  const [deleteId, setDelete] = useState("");
  const deleteActivity = async () => {
    try {
      const { data } = await axios.delete(
        `/extracurricularActivitys/removeExtracurricularActivities/${deleteId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      handleClose();
      toast.success("Successfully deleted Activity!");
      getActivity();
      console.log(data);
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else if (error?.response?.data?.error) {
        toast.error(
          error?.response?.data?.error[0]?.detail ||
            error?.response?.data?.error[0]?.message
        );
      } else {
        toast.error(
          error?.response?.data?.message || error?.response?.data?.msg
        );
      }
      console.log(error);
    }
  };

  useEffect(() => {
    if (editId && activities) {
      const getCurrentPlan = activities?.find((pln) => {
        return editId === pln.id;
      });

      setFormData({
        date: getCurrentPlan?.date?.split("T")[0],
        code: getCurrentPlan?.code,
        startTime: getCurrentPlan?.startTime,
        endTime: getCurrentPlan?.endTime,
        hours: getCurrentPlan?.hours,
        type: getCurrentPlan?.type,
      });
      setShow(true);
    } else {
      setShow(false);
    }
  }, [editId, activities]);
  const [student, setStudent] = useState(null);

  const getStudent = async () => {
    try {
      const { data } = await axios.get(
        `/students/profile/${localStorage.getItem("teacherStudent")}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setStudent(data?.student);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStudent();
  }, []);

  function handlePrint() {
    window.print();
  }

  const [gpa, setGpa] = useState([]);

  const getGpa = async () => {
    try {
      const { data } = await axios.get(
        `/grades/calculateGPA/${localStorage.getItem("teacherStudent")}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setGpa(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getGpa();
  }, []);
  const [assingment, setAssingment] = useState([]);

  const getAssingment = async () => {
    try {
      const { data } = await axios.get(
        `/assignments/getAssignmentCountByStudent/${localStorage.getItem(
          "teacherStudent"
        )}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setAssingment(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAssingment();
  }, []);

  const [completed, setComplted] = useState([]);

  const getCompleted = async () => {
    try {
      const { data } = await axios.get(
        `/assignmentSubmissions/getSubmittedAssignmentsCountByStudent/${localStorage.getItem(
          "teacherStudent"
        )}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setComplted(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCompleted();
  }, []);

  return (
    <div>
      <div className="Medical-Main">
        <div className="StuProfile-main">
          <div className="StuProfile-Top">
            <div className="StuProfile-Top1">
              <IoMdArrowBack style={{ cursor: "pointer" }} />
              <h1>Student Profile</h1>
            </div>
            <div className="StuProfile-Top2">
              <Link to="/TeacherChat" className="StuProfile-Top-btn1">
                Parent Chat
              </Link>
              <Link to="/TeacherChat" className="StuProfile-Top-btn2">
                Student Chat
              </Link>
              <Link to="/TeacherChat" className="StuProfile-Top-btn2">
                Admin Chat
              </Link>
            </div>
          </div>
          <div className="Myprofile-main-1">
            <div className="Myprofile-main-child-1">
              <div className="profile">
                <div className="profile-child-1">
                  <img
                    onError={({ currentTarget }) => {
                      currentTarget.src = Dummy;
                    }}
                    src={`${baseURL}/images/${student?.profilePicture}`}
                  />
                </div>
                <div className="profile-child-2">
                  <div className="profile-child-2-1">
                    <h2>{student?.firstName + " " + student?.lastName}</h2>
                  </div>
                  <div className="profile-child-2-2">
                    <p>{student?.program}</p>
                    <button>
                      <span>●</span>
                      {student?.online ? "Online" : "Offline"}
                    </button>
                  </div>
                  <div className="profile-child-2-3">
                    <p>{student?.loggedInSince?.split("T")[0]}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="Myprofile-main-child-2">
              <div className="Myprofile-main-child-2-1">
                <img src={Timer} alt="#" />
                <h3>{student?.createdAt?.split("T")[0]}</h3>
              </div>
              <div className="Myprofile-main-child-2-2">
                <div className="div-1">
                  <img src={Red} alt="#" />
                  <h3>
                    Assignments <span>{assingment?.assignmentCount}</span>
                  </h3>
                </div>
                <div className="div-2">
                  <img src={Green} alt="#" />
                  <h3>
                    Completed{" "}
                    <span>{completed?.submittedAssignmentsCount}</span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="Myprofile-main-child-3">
              <div className="Myprofile-main-child-3-card-1">
                <img src={Graph} alt="#" />
              </div>
              <div className="Myprofile-main-child-3-card-2">
                <h3>GPA</h3>
                <h1>{gpa?.gpa}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="ActivitiesMain">
          {!show ? (
            <div>
              <div className="ActivitiesTop">
                <h3>Extracurricular Activities</h3>
                <div className="ActivitieTopBtns">
                  <button onClick={() => setShow(true)}>
                    <IoIosAddCircle />
                    Add new Record
                  </button>
                  <button onClick={handlePrint}>
                    <img src={Print} />
                    Print
                  </button>
                </div>
              </div>
              <div className="ActivitiesTable">
                <table>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Type</th>
                      <th>Extracurricular Activity</th>
                      <th>Hours</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {activities.map((activity, index) => (
                      <tr key={index}>
                        <td>{activity?.date?.split("T")[0]}</td>
                        <td>{activity?.type}</td>
                        <td>{activity?.code}</td>
                        <td>{activity?.hours}</td>
                        <td>{activity?.startTime}</td>
                        <td>{activity?.endTime}</td>
                        <td>
                          <MdOutlineModeEditOutline
                            onClick={() => {
                              setEditId(activity.id);
                            }}
                          />
                          <BsThreeDotsVertical
                            onClick={() => {
                              handleOpen();
                              setDelete(activity.id);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Modal
                className="Allowmodalmain"
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
              >
                <Fade in={open}>
                  <Box sx={style}>
                    <div className="CommentModal-Mmain-child1">
                      <h4>Delete Activity</h4>
                      <p>
                        Are you sure you want to delete this Activity? This will
                        remove the Activity and can’t be undone.
                      </p>
                      <div className="CommentModal-Mmain-child2">
                        <button
                          className="CommentModal-Mmain-child2-allow"
                          onClick={handleClose}
                        >
                          CANCEL
                        </button>
                        <button
                          className="CommentModal-Mmain-child2-Dont-allow"
                          onClick={deleteActivity}
                        >
                          DELETE
                        </button>
                      </div>
                    </div>
                  </Box>
                </Fade>
              </Modal>
            </div>
          ) : (
            <div>
              <div className="ActivitiesTop">
                <h3>
                  <IoArrowBack onClick={() => setShow(false)} />
                  Extracurricular activities
                </h3>
              </div>
              <div className="ActivitiesBody">
                <div className="ActivitiesDate">
                  <div className="ActivitiesRowOne">
                    <div className="ActiLabel">
                      <p>Date</p>
                    </div>
                    <input
                      type="date"
                      value={formData.date}
                      name="date"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="ActivitiesRowOne">
                    <div className="ActiLabel">
                      <p>Type</p>
                    </div>
                    <select
                      value={formData.type}
                      name="type"
                      onChange={handleChange}
                    >
                      <option value="">Type</option>
                      <option value="Award">Award</option>

                      <option value="Sports">Sports</option>
                      <option value="Academics">Academics</option>
                      <option value="Creative">Creative</option>
                      <option value="Art">Art</option>
                      <option value="Activity">Activity</option>
                      <option value="Certifications-licenses">
                        Certifications, Licenses
                      </option>
                      <option value="Program-completions">
                        Program Completions
                      </option>
                      <option value="Performance-acknowledgments">
                        Performance Acknowledgments
                      </option>
                      <option value="Other-graduation-requirements">
                        Other Graduation Requirements
                      </option>
                    </select>
                  </div>
                  <div className="ActivitiesRowOne">
                    <div className="ActiLabel">
                      <p>Extracurricular Activity </p>
                    </div>

                    <select
                      value={formData.code}
                      name="code"
                      onChange={handleChange}
                    >
                      <option value="">Extracurricular Activity</option>
                      <option value="Archery">Archery</option>
                      <option value="Badminton">Badminton</option>
                      <option value="Basketball">Basketball</option>
                      <option value="Boxing">Boxing</option>
                      <option value="Bowling">Bowling</option>
                      <option value="Baseball">Baseball</option>
                      <option value="Crew">Crew</option>
                      <option value="Cross-country">Cross Country</option>
                      <option value="Diving">Diving</option>
                      <option value="Field-hockey">Field Hockey</option>
                      <option value="Fencing">Fencing</option>
                      <option value="Football">Football</option>
                      <option value="Golf">Golf</option>
                      <option value="Gymnastics">Gymnastics</option>
                      <option value="Ice-hockey">Ice Hockey</option>
                      <option value="Lacrosse">Lacrosse</option>
                      <option value="Martial-arts">Martial Arts</option>
                      <option value="Polo">Polo</option>
                      <option value="Racquetball">Racquetball</option>
                      <option value="Rugby">Rugby</option>
                      <option value="Sailing">Sailing</option>
                      <option value="Skiing">Skiing</option>
                      <option value="Soccer">Soccer</option>
                      <option value="Softball">Softball</option>
                      <option value="Squash">Squash</option>
                      <option value="Swimming">Swimming</option>
                      <option value="Synchronized-swimming">
                        Synchronized Swimming
                      </option>
                      <option value="Team-handball">Team Handball</option>
                      <option value="Tennis">Tennis</option>
                      <option value="Track-and-field">Track and Field</option>
                      <option value="Volleyball">Volleyball</option>
                      <option value="Water-polo">Water Polo</option>
                      <option value="Wrestling">Wrestling</option>
                      <option value="Rodeo">Rodeo</option>
                      <option value="Scuba-diving">Scuba Diving</option>
                      <option value="Team-manager">Team Manager</option>
                      <option value="Cheerleading">Cheerleading</option>
                      <option value="Other-sport">Other Sport</option>
                    </select>
                  </div>
                </div>
                <div className="ActivitiesDate">
                  <div className="ActivitiesRowOne">
                    <div className="ActiLabel">
                      <p>Duration</p>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["TimePicker", "TimePicker", "TimePicker"]}
                      >
                        <DemoItem label={'"minutes" and "seconds"'}>
                          <TimePicker
                            value={previewHours}
                            onChange={(value) => {
                              setPreviewHours(value);
                              setHours(value);
                            }}
                            ampm={false}
                            referenceDate={dayjs("2022-04-17")}
                            views={["hours", "minutes", "seconds"]}
                            format="HH:mm:ss"
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <div className="ActivitiesRowOne">
                    <div className="ActiLabel">
                      <p>Start Time</p>
                    </div>
                    <input
                      type="time"
                      value={formData.startTime}
                      name="startTime"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="ActivitiesRowOne">
                    <div className="ActiLabel">
                      <p>End Time</p>
                    </div>
                    <input
                      value={formData.endTime}
                      name="endTime"
                      onChange={handleChange}
                      type="time"
                    />
                  </div>
                </div>
                <div className="ActivitiesSave">
                  <button
                    onClick={() => {
                      clearData();
                    }}
                  >
                    <IoRefreshCircle />
                    Reset
                  </button>
                  <button
                    onClick={() => {
                      postActivities();
                    }}
                  >
                    Save new Record
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Extra;
