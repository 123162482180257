import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  onlineMembers: [],
};

const misc = createSlice({
  name: "misc",
  initialState,
  reducers: {
    setOnlineMembers: (state, action) => {
      state.onlineMembers = action.payload;
    },
  },
});

export default misc;

export const { setOnlineMembers } = misc.actions;
