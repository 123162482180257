import React from "react";
import "./NotificationdataCard.css";
import { baseURL } from "../../../constants";
import { MdDeleteSweep } from "react-icons/md";

const NotificationdataCard = (props) => {

  return (
    <>
      <div className="notification-main">
        <div className="IMG">
          <img
            style={{ objectFit: "cover" }}
            src={`${baseURL}/images/${props.image}`}
            alt="#"
          />
        </div>
        <div className="notification-child">
          <div class="notification-new">
            <h6>{props.name}</h6>
            <p>{props.body}</p>
          </div>
          <div class="notifaction-para">
            <p>{props.createdAt}</p>
            <div class="notification-dot">
              <p>{props.dotIcons}</p>
              <p
                style={{ color: "red" }}
                onClick={() => props.delete(props.deleteNotification)}

              >
                <MdDeleteSweep />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationdataCard;