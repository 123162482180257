import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import "./EditStaffSchoolInfo.css";
import toast from "react-hot-toast";
import axios from "axios";
import { baseURL } from "../../../../../constants";
import { useParams } from "react-router-dom";
axios.defaults.baseURL = baseURL;

const EditStaffSchoolInfo = ({ setShowEdit }) => {
  const [isSchoolInfoVisible, setIsSchoolInfoVisible] = useState(false);

  const toggleSchoolInfo = () => {
    setIsSchoolInfoVisible(!isSchoolInfoVisible);
  };
  const [staffData, setStaffData] = useState(null);
  const params = useParams();
  const getStaffSchool = async () => {
    try {
      const { data } = await axios.get(
        `/teachers/getTeacherSchoolInfoAndOfficialInfo/${params.staffId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setStaffData(data.teacherWithInfo);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStaffSchool();
  }, []);

  const [jobTitle, setJobTitle] = useState("");
  const [joiningDate, setJoiningDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [primaryGradeLevel, setPrimaryGradeLevel] = useState("");
  const [otherGradeLevelTaught, setOtherGradeLevelTaught] = useState("");
  const [otherSubjectTaught, setOtherSubjectTaught] = useState("");

  const [profile, setProfile] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate2, setEndDate2] = useState("");
  const [status, setStatus] = useState("");
  const [teacherId, setTeacherId] = useState("");
  useEffect(() => {
    if (staffData) {
      setJobTitle(staffData.jobTitle);
      setJoiningDate(staffData.joiningDate);
      setEndDate(staffData.endDate);
      setPrimaryGradeLevel(staffData.primaryGradeLevel);
      setOtherGradeLevelTaught(staffData.otherGradeLevelTaught);
      setOtherSubjectTaught(staffData.otherSubjectTaught);
      setProfile(staffData.profile);
      setStartDate(staffData.startDate);
      setEndDate2(staffData.endDate);
      setStatus(staffData.status);
      setTeacherId(staffData.teacherId);
    }
  }, [staffData]);
  const handleUpdateStaff = async () => {
    try {
      const { data } = await axios.put(
        `/teachers/updateTeacherSchoolInfoAndOfficialInfo/${params?.staffId}`,
        {
          officialInfo: {
            jobTitle,
            joiningDate,
            endDate,
            primaryGradeLevel,
            otherGradeLevelTaught,
            otherSubjectTaught,
          },

          schoolInfo: {
            profile,
            startDate,
            endDate: endDate2,
            status,
          },
        },

        {
          headers: {
            "Content-Type": "application/json",
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );

      getStaffSchool();
      toast.success("Updated Successfully");
      setShowEdit(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };
  return (
    <div className="EditSchoolInfo-main">
      <p>School Information</p>
      <div className="EditSchoolInfo-child-1">
        <h4>Official Information</h4>
        <div className="EditSchoolInfo-child-2">
          <div>
            <label htmlFor="Job Title">Job Title</label>
            <div>
              <input
                type="text"
                id="text"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
              />
            </div>
          </div>
          <div>
            <label htmlFor="Joining Date">Joining Date</label>
            <input
              type="date"
              id="date"
              value={joiningDate}
              onChange={(e) => setJoiningDate(e.target.value)}
              placeholder="kuch b"
            />
          </div>
          <div>
            <label htmlFor="Joining Date">End Date</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              id="date"
            />
          </div>
        </div>
        <div className="EditSchoolInfo-child-3">
          <div>
            <label htmlFor="Primary Grade Level">Primary Grade Level</label>
            <div className="EditSchoolInfo-child-4">
              <select
                name="Primary Grade Level"
                id="Primary Grade Level"
                placeholder="Gender"
                onChange={(e) => setPrimaryGradeLevel(e.target.value)}
              >
                <option value="" disabled selected hidden>
                  -
                </option>
                <option value="level A">Level A</option>
                <option value="level B">Level B</option>
                <option value="level C">Level C</option>
              </select>
            </div>
          </div>
          <div>
            <label htmlFor="Other Grade Level Taught">
              Other Grade Level Taught
            </label>
            <div className="EditSchoolInfo-child-4">
              <select
                name="Other Grade Level Taught"
                id="Other Grade Level Taught"
                placeholder="Gender"
                onChange={(e) => setOtherGradeLevelTaught(e.target.value)}
              >
                <option value="" disabled selected hidden>
                  -
                </option>
                <option value="levelA">Level A</option>
                <option value="levelB">Level B</option>
                <option value="levelC">Level C</option>
              </select>
            </div>
          </div>
          <div>
            <label htmlFor="Other Subject Taught">Other Subject Taught</label>
            <div className="EditSchoolInfo-child-4">
              <select
                name="Other Subject Taught"
                id="Other Subject Taught"
                placeholder="Gender"
                onChange={(e) => setOtherSubjectTaught(e.target.value)}
              >
                <option value="" disabled selected hidden>
                  -
                </option>
                <option value="subjectA">Subject A</option>
                <option value="subjectB">Subject B</option>
                <option value="subjectC">Subject C</option>
              </select>
            </div>
          </div>
        </div>
        <div className="EditSchoolInfo-child-5">
          <div className="EditSchoolInfo-child-6">
            <h3>School Information</h3>
            <button onClick={toggleSchoolInfo}>Add School Info</button>
          </div>
          {isSchoolInfoVisible && (
            <div className="EditSchoolInfo-child-21">
              <div className="EditSchoolInfo-child-7">
                <div>
                  <label>School</label>
                  {/* <input
                    type="text"
                    id="text"
                    value={profile}
                    onChange={(e) => setProfile(e.target.value)}
                  /> */}
                  <select
                    name="Other Subject Taught"
                    id="Other Subject Taught"
                    placeholder="Gender"
                    onChange={(e) => setOtherSubjectTaught(e.target.value)}
                  >
                    <option value="" disabled selected hidden>
                      -
                    </option>
                    <option value="subjectA">Subject A</option>
                    <option value="subjectB">Subject B</option>
                    <option value="subjectC">Subject C</option>
                  </select>
                </div>
              </div>
              <div className="EditSchoolInfo-child-7">
                <div>
                  <label>Profile</label>
                  {/* <input
                    type="text"
                    id="text"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  /> */}
                  <select
                    name="Other Subject Taught"
                    id="Other Subject Taught"
                    placeholder="Gender"
                    onChange={(e) => setOtherSubjectTaught(e.target.value)}
                  >
                    <option value="" disabled selected hidden>
                      -
                    </option>
                    <option value="subjectA">Subject A</option>
                    <option value="subjectB">Subject B</option>
                    <option value="subjectC">Subject C</option>
                  </select>
                </div>
              </div>
              <div className="EditSchoolInfo-child-8">
                <div>
                  <label htmlFor="Joining Date">Joining Date</label>
                  <div>
                    <input
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      type="date"
                      id="date"
                      placeholder=""
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="Joining Date">End Date</label>
                  <div>
                    <input
                      value={endDate2}
                      onChange={(e) => setEndDate2(e.target.value)}
                      type="date"
                      id="date"
                    />
                  </div>
                </div>
              </div>
              <IoClose className="oiClose-icon" onClick={toggleSchoolInfo} />
            </div>
          )}
        </div>
        <div className="EditSchoolInfo-btnn">
          <button onClick={() => setShowEdit(false)}>Cancel</button>
          <button onClick={handleUpdateStaff}>Update</button>
        </div>
      </div>
    </div>
  );
};

export default EditStaffSchoolInfo;
