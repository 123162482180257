import React, { useEffect, useState } from "react";
import "./ClassLectures.css";
import axios from "axios";
import { baseURL } from "../../../../../../constants";
import { BsThreeDotsVertical } from "react-icons/bs";
import Dropdown from "react-bootstrap/Dropdown";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modall from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import toast from "react-hot-toast";
axios.defaults.baseURL = baseURL;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
axios.defaults.baseURL = baseURL;
function TeacherClassLectures({
  classLecture = [],
  GetClassData,
  editId = "",
  setEditId = () => {},
}) {
  const [deleteId, setDeleteId] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setDeleteId("");
  };

  const deleteLecture = async () => {
    try {
      await axios.delete(`/lectures/removeLecture/${deleteId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      toast.success("Lecture Deleted");
      GetClassData();
      handleClose();
      setEditId("");
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const [uri, setUri] = useState("");

  const handleViewFiles = (file) => {
    let anchor = document.createElement("a");
    anchor.target = "_blank";
    document.body.appendChild(anchor);
    if (
      file?.split(".")?.pop() === "jpeg" ||
      file?.split(".")?.pop() === "png" ||
      file?.split(".")?.pop() === "jpg"
    ) {
      anchor.href = `${baseURL}/images/${file}`;
      anchor.click();
    } else if (
      file?.split(".")?.pop() === "csv" ||
      file?.split(".")?.pop() === "pdf"
    ) {
      anchor.href = `${baseURL}/documents/${file}`;
      anchor.click();
    }
    document.body.removeChild(anchor);
  };

  return (
    <>
      <div className="TeacherClassLectures-main">
        {classLecture?.map((lectures, index) => {
          return (
            <div className="TeacherClassLectures-ClassLec-Card" key={index}>
              <div className="TeacherClassLectures-main-ClassLectures">
                <div className="TeacherClassLectures-main-ClassLectures-child-1">
                  <div className="ClassLec-head">
                    <p>{index + 1}</p>
                    <h1>{lectures?.title}</h1>
                  </div>
                  <div className="TeacherClassLectures-main-ClassLectures-child-1-ClassLec-From-To">
                    <button>
                      <div
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "white",
                        }}
                        onClick={() => handleViewFiles(lectures?.file)}
                      >
                        View
                      </div>
                    </button>

                    <h6>
                      <span>Date : {lectures?.date?.split("T")[0]}</span>
                    </h6>
                    <hr />
                    <h6>
                      <span>From : {lectures?.createdAt?.split("T")[0]}</span>
                    </h6>
                    <hr />
                    <h6>
                      <span>To :{lectures?.updatedAt?.split("T")[0]}</span>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="TeacherClassLectures-ClassLec-Card-child-1">
                <div className="TWO_MAin">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <BsThreeDotsVertical />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className="TWo-Main-Div">
                        <Dropdown href="#/action-1">
                          <button
                            onClick={() => {
                              setEditId(lectures?.id);
                            }}
                            className="TWo-Main-Btns1"
                          >
                            Edit
                          </button>
                        </Dropdown>
                        <Dropdown href="#/action-2">
                          <button
                            className="TWo-Main-Btns2"
                            onClick={() => {
                              handleOpen();
                              setDeleteId(lectures?.id);
                            }}
                          >
                            Delete
                          </button>
                        </Dropdown>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <Modall
                className="Allowmodalmain"
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
              >
                <Fade in={open}>
                  <Box sx={style}>
                    <div className="CommentModal-Mmain-child1">
                      <h4>Delete Lecture</h4>
                      <p>
                        Are you sure you want to delete the Lecture? This
                        will remove the Lecture and can’t be undone.
                      </p>
                      <div className="CommentModal-Mmain-child2">
                        <button
                          onClick={() => handleClose()}
                          className="
                          CommentModal-Mmain-child2-allow"
                        >
                          CANCEL
                        </button>
                        
                        <button
                          onClick={deleteLecture}
                          className="
                          CommentModal-Mmain-child2-Dont-allow"
                        >
                          DELETE
                        </button>
                      </div>
                    </div>
                  </Box>
                </Fade>
              </Modall>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default TeacherClassLectures;
