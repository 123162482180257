import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./CreateModal.css";
import Vactor from "../../../../Images/entypo_drive.png";
import { TfiExport } from "react-icons/tfi";
import axios from "axios";
import { baseURL } from "../../../../../../constants";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;

function CreateModal({ func = () => {} }) {
  const [lgShow, setLgShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const toggleSwitch = () => {
    setIsChecked(!isChecked);
  };
  const [folderName, setFolderName] = useState("No file chosen");
  const [imageName, setImageName] = useState("No file chosen");

  const [file, setFile] = useState(null);

  const handleFileChange = (event, fileType) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const fileName = selectedFile.name;

      setFile(selectedFile);
      if (fileType === "folder") {
        setFolderName(fileName);
      } else if (fileType === "image") {
        setImageName(fileName);
      }
    } else {
      if (fileType === "folder") {
        setFolderName("No file chosen");
      } else if (fileType === "image") {
        setImageName("No file chosen");
      }
    }
  };

  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [points, setPoints] = useState("");
  const [grading, setGrading] = useState("");
  const [assignOn, setAssignOn] = useState("");
  const [dueOn, setDueOn] = useState("");

  const params = useParams();

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const [schoolId, setSchoolId] = useState(null);

  const getSchoolByTeacher = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/ParentBySchoolId/getSchoolByTeacherId/${user.dataValues.id}`
        );
        setSchoolId(data.school.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchoolByTeacher();
  }, [user]);

  const handleCreateAssignment = async () => {
    try {
      const newForm = new FormData();

      newForm.append("file", file);
      newForm.append("subjectId", params?.subjectId);
      newForm.append("name", name);
      newForm.append("category", category);
      newForm.append("title", title);
      newForm.append("description", description);
      newForm.append("points", points);
      newForm.append("grading", grading);
      newForm.append("assignedOn", assignOn);
      newForm.append("dueOn", dueOn);
      newForm.append("schoolId", Number(schoolId));
      newForm.append("classroomId", localStorage.getItem("classroomId"));

      await axios.post(`/assignments/createAssignment`, newForm, {
        headers: {
          "Content-Type": "multipart/form-data",
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      func();

      toast.success("Assignment Created Successfully");
      setLgShow(false);
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(error?.response?.data?.details[0]?.detail);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <div className="CreateModal-Main">
      <button onClick={() => setLgShow(true)} className="CreateModal-btn">
        Create New Assignment
      </button>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => {
          setLgShow(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton className="CreateModal-header">
          <h3 className="CreateModal-h3">CREATE ASSIGNMENT</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="CreateModal-body">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
            <input
              type="text"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              placeholder="Category"
            />
            <label>Assignment Details</label>
            <input
              placeholder="Title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Description"
            />
            {/* .....///////////...........///////////...... */}
            <div className="CreateModal-child">
              <div className="CreateModal-child-input">
                <label>Points</label>
                <input
                  value={points}
                  onChange={(e) => setPoints(e.target.value)}
                  type="number"
                  placeholder="Points"
                />
              </div>
              <div className="CreateModal-child-select">
                <label>Grading</label>
                <input
                  value={grading}
                  onChange={(e) => setGrading(e.target.value)}
                  type="text"
                />
              </div>
            </div>
            <div className="CreateModal-child">
              <div className="CreateModal-child-select">
                <label>Assigned on</label>
                <input
                  value={assignOn}
                  onChange={(e) => setAssignOn(e.target.value)}
                  type="date"
                />
              </div>
              <div className="CreateModal-child-select">
                <label>Due On</label>
                <input
                  value={dueOn}
                  onChange={(e) => setDueOn(e.target.value)}
                  type="date"
                />
              </div>
            </div>
            {/* .....///////////...........///////////...... */}
            <div className="CreateModal-Last">
              <div>
                <div className="CreateModal-Last1">
                  <label>
                    <span>
                      <img src={Vactor} alt="Folder Icon" />
                    </span>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileChange(e, "folder")}
                    />
                  </label>
                  <h6>{folderName}</h6>
                </div>
                <div className="CreateModal-Last1" style={{ marginTop: "8px" }}>
                  <label>
                    <span>
                      <TfiExport />
                    </span>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileChange(e, "image")}
                    />
                  </label>
                  <h6>{imageName}</h6>
                </div>
              </div>
              <div className="CreateModal-Child2">
                <div className="CreateModal-Child21">
                  <p>
                    Create noticeboard announcement for all students in
                    classroom
                  </p>
                  <label
                    className={`custom-switch ${isChecked ? "checked" : ""}`}
                  >
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={toggleSwitch}
                    />
                    <span className="slider"></span>
                  </label>
                </div>
                <div className="CreateModal-Child22">
                  <button onClick={handleCreateAssignment}>
                    Create Assigment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CreateModal;
