import React, { useState, useEffect } from "react";
import "./StudentsGrades.css";
import { IoIosSearch } from "react-icons/io";
import { RiEqualizerFill } from "react-icons/ri";
import { Dropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaBook } from "react-icons/fa";
import axios from "axios";
import { baseURL } from "../../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;
const StudentsGrades = () => {
  const [studentsGrade, setStudentsGrade] = useState(null);

  const { schoolId } = useSelector((state) => state.schoolId);

  useEffect(() => {
    const getStudentsGrade = async () => {
      try {
        const { data } = await axios.get(
          `/grades/getGradesBySchool/${schoolId}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setStudentsGrade(data?.grades);
      } catch (error) {
        console.log(error);
      }
    };
    getStudentsGrade();
  }, [schoolId]);
  console.log(studentsGrade,"This is my data")

  const [selectedOption, setSelectedOption] = useState("Select Class");

  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
  };

  const [grade, setGrade] = useState([]);
  const getGrade = async () => {
    try {
      const { data } = await axios.get(
        `/students/getAllGradeLevels/${schoolId}`,
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );
      setGrade(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getGrade();
  }, []);
  return (
    <div className="StudentsGrades">
      <h3>Students Grades</h3>
      <div className="selectDate">
        <div className="filter">
          <div className="input">
            <span>
              <IoIosSearch />
            </span>
            <input type="search" placeholder="Search" />
          </div>
          <input type="date" placeholder="Select date" className="daTe" />
          <button className="Filters">
            <span>
              <RiEqualizerFill />
            </span>
            Filters
          </button>
        </div>
        <div className="exportData">
          <div className="dropdown">
            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {selectedOption}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {grade.map((g, index) => (
                  <Dropdown.Item key={index} eventKey={g}>
                    {g}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <button className="EXPORTDATA">
            <FaBook /> EXPORT DATA
          </button>
        </div>
      </div>
      <div className="table">
        <table>
          <thead>
            <tr>
              <th className="center">Id</th>
              <th className="center">
                Students <br /> Name
              </th>
              <th className="center">
                Running Avg & <br /> Letter Grade
              </th>
              <th>Instructions</th>
              <th className="skyblUe">Title</th>
              <th className="orAng">
                Scored <br /> Percentage
              </th>
              <th className="liGhtGreen">
                Total <br /> Percentage
              </th>
              <th className="skyblUe">Result</th>
              <th className="orAng">Date</th>
              <th className="liGhtGreen">Time</th>
            </tr>
          </thead>
          <tbody>
            {studentsGrade &&
              studentsGrade.map((tdata) => {
                return (
                  <tr>
                    <td>{tdata?.id}</td>
                    <td className="GabrielNing">
                      <p>
                        {tdata?.Student?.firstName} {tdata?.Student?.lastName} <br/>
                       <span> ID: {tdata?.studentId}</span>
                      </p>
                    </td>
                    <td style={{padding:'0px'}}>
                      <div className="persentage">
                      {tdata?.runningAvg}% [ {tdata?.letterGrade} ]
                      </div>
                    </td>
                    <td>{tdata?.instructions}</td>
                    <td>{tdata?.title}</td>
                    <td>{tdata?.scoredPercentage}%</td>
                    <td>100%</td>
                    <td>{tdata?.result}</td>
                    <td>{tdata?.date?.split("T")[0]}</td>
                    <td>{tdata?.time}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StudentsGrades;
