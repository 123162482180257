import "./Addparanteditinfo.css";
import "react-phone-number-input/style.css";
import React, { useState, useRef, useEffect } from "react";
import { GrNotes } from "react-icons/gr";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { baseURL } from "../../../../../constants";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-number-input";
import { IoCaretForwardOutline } from "react-icons/io5";
import Dummy from "../../../images/dummy-user.png";
import Avatar from "../../../PickAvator/Avator";

import { Link } from "react-router-dom";
import {
  isValidAmericanPhoneNumber,
  isValidEmail,
  isValidName,
  isValidPassword,
  requiredValues,
} from "../../../../../utils/regex.js";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

const Addparanteditinfo = () => {
  const [profilePicture, setProfilePicture] = useState(null);
  const [lgShow, setLgShow] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [preview, setPreview] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setPreview(null);
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const [showSecondDiv, setShowSecondDiv] = useState(false);

  const handleSwitchChange = () => {
    setShowSecondDiv(!showSecondDiv);
  };

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userProfile, setUserProfile] = useState("");
  const [email, setEmail] = useState("");
  const [personalNumber, setPersonalNumber] = useState("");
  const [homePhone, setHomePhone] = useState("");
  const [workNumber, setWorkNumber] = useState("+1");

  const [workEmail, setWorkEmail] = useState("");
  const [portalAccess, setPortalAccess] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [isShow, setIsShow] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [handleFirstSwitch, setHandleFirstSwitch] = useState(false);
  const [handleSecondSwitch, setHandleSecondSwitch] = useState(false);
  const [loginEmailAddress, setLoginEmailAddress] = useState("");

  const params = useParams();

  const [parent, setParent] = useState(null);

  const getParent = async () => {
    try {
      const { data } = await axios.get(
        `/parents/getParent/${params.parentId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setParent(data?.parent);
    } catch (error) {
      console.log(error);
    }
  };

  useState(() => {
    getParent();
  }, []);

  useEffect(() => {
    if (parent) {
      setFirstName(parent?.firstName);
      setMiddleName(parent?.middleName);
      setLastName(parent?.lastName);
      setUserProfile(parent?.userProfile);
      setEmail(parent?.email);
      setPersonalNumber(String(parent?.personalNumber));
      setHomePhone(String(parent?.homePhone));
      setWorkEmail(parent?.workEmail);
      setPortalAccess(parent?.portalAccess);
      setDisabled(parent?.disabled);
      setLoginEmailAddress(parent?.loginEmailAddress);
      setPreview(parent?.profilePicture);
      setWorkNumber(parent?.workNumber);
    }
  }, [parent]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const generateRandomPassword = () => {
    const randomPassword = Math.random().toString(36).slice(-8);
    setPassword(randomPassword);
  };
  //SchoolId

  const navigate = useNavigate();
  const { schoolId } = useSelector((state) => state.schoolId);

  useEffect(() => {
    if (!personalNumber?.startsWith("+1")) {
      setPersonalNumber("+1");
    } else {
      setPersonalNumber(personalNumber);
    }
  }, [personalNumber]);

  useEffect(() => {
    if (!homePhone?.startsWith("+1")) {
      setHomePhone("+1");
    } else {
      setHomePhone(homePhone);
    }
  }, [homePhone]);

  useEffect(() => {
    if (!workNumber?.startsWith("+1")) {
      setWorkNumber("+1");
    } else {
      setWorkNumber(workNumber);
    }
  }, [workNumber]);
  const handleUpdateParent = async () => {
    try {
      if (
        !requiredValues([
          {
            value: firstName,
            name: "First name",
          },
          {
            value: lastName,
            name: "Last name",
          },
        ])
      )
        return;
      if (!isValidName(firstName, "First name")) return;
      if (middleName) {
        if (!isValidName(middleName, "Middle name")) return;
      }
      if (!isValidName(lastName, "Last name")) return;

      if (!isValidEmail(email)) return;

      if (!isValidAmericanPhoneNumber(personalNumber, "Personal number"))
        return;
      if (!isValidAmericanPhoneNumber(homePhone, "Home phone")) return;

      if (workEmail) {
        if (!isValidEmail(workEmail)) return;
      }

      if (!isValidAmericanPhoneNumber(workNumber, "Work number")) return;

      if (!isValidEmail(loginEmailAddress, "Login email address")) return;

      if (password) {
        if (!isValidPassword(password)) return;
      }

      const newForm = new FormData();
      newForm.append("firstName", firstName);
      newForm.append("middleName", middleName);
      newForm.append("lastName", lastName);
      newForm.append("userProfile", userProfile);
      newForm.append("email", email);
      newForm.append("personalNumber", personalNumber);
      newForm.append("homePhone", homePhone);
      newForm.append("workEmail", workEmail);
      newForm.append("workNumber", workNumber);

      newForm.append("portalAccess", portalAccess);
      newForm.append("disabled", disabled);
      newForm.append("schoolId", Number(schoolId));
      profilePicture && newForm.append("profilePicture", profilePicture);
      newForm.append("loginEmailAddress", loginEmailAddress);
      {
        password && newForm.append("password", password);
      }

      const { data } = await axios.put(
        `/parents/updateParent/${params.parentId}`,
        newForm,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Profile Updated Successfully");
      navigate(`/Addparant/${params?.parentId}`);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  return (
    <>
      <div className="myassingment-TOP">
        <Link style={{ color: "#00afef" }} to="/ParentTable">
          Parent/Guardian List
        </Link>
        <IoCaretForwardOutline />
        <Link to={`/Addparant/${params.parentId}`}>
          Parent/Guardian Information
        </Link>
        <IoCaretForwardOutline />
        <Link>Parent/Guardian Edit information</Link>
      </div>
      <div className="Addparanteditinfo-main">
        <div className="Addparanteditinfo-main-child-1">
          <div className="upLoaDing">
            {/* {selectedFile ? ( */}
            <img
              src={
                selectedFile
                  ? selectedFile
                  : preview
                  ? `${baseURL}/images/${preview}`
                  : ""
              }
              // src={`${baseURL}/images/${student?.profilePicture}`}
              onError={({ currentTarget }) => {
                currentTarget.src = Dummy;
              }}
              alt="Staff"
              style={{
                width: "170px",
                height: "170px",
                cursor: "pointer",
                borderRadius: "100%",
                padding: "1px",
              }}
              // onClick={handleImageClick}
              onClick={() => setLgShow(true)}
            />

            {/* <input
              type="file"
              id="upload"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            /> */}
            <Avatar
              lgShow={lgShow}
              setLgShow={setLgShow}
              profilePicture={profilePicture}
              setProfilePicture={setProfilePicture}
              setSelectedFile={setSelectedFile}
            />
          </div>
          <h2>
            {firstName} {middleName} {lastName}
          </h2>
          <button className="Addparanteditinfo-main-child-1-button">
            {userProfile}
          </button>
          <div className="Addparant-main-child-1-tap-button">
            <GrNotes /> <h3>General Info</h3>
          </div>
        </div>
        <div className="Addparanteditinfo-main-child-2">
          <div className="Addparanteditinfo-main-child-2-title">
            <h2>Identification information</h2>
          </div>
          <div className="Addparanteditinfo-main-child-2-Identification-information">
            <div className="Information-form">
              <div className="Addparanteditinfo-main-child-2-input-and-label">
                <label>First Given Name</label>
                <input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="-"
                />
              </div>
              <div className="Addparanteditinfo-main-child-2-input-and-label">
                <label>Middle Name</label>
                <input
                  value={middleName}
                  onChange={(e) => setMiddleName(e.target.value)}
                  placeholder="-"
                />
              </div>
              <div className="Addparanteditinfo-main-child-2-input-and-label">
                <label>Last Name</label>
                <input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="-"
                />
              </div>
            </div>
            <div className="Information-form">
              <div className="Addparanteditinfo-main-child-2-input-and-label">
                <label>User Profile</label>
                <input
                  value={userProfile}
                  onChange={(e) => setUserProfile(e.target.value)}
                  placeholder="-"
                />
              </div>
              <div className="Addparanteditinfo-main-child-2-input-and-label">
                <label>Email</label>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="-"
                />
              </div>
              <div className="Addparanteditinfo-main-child-2-input-and-label">
                <label>Mobile Number</label>
                <PhoneInput
                  value={personalNumber}
                  onChange={setPersonalNumber}
                  placeholder="Enter personal number"
                />
              </div>
            </div>
            <div className="Information-form">
              <div className="Addparanteditinfo-main-child-2-input-and-label">
                <label>Home Phone</label>
                <PhoneInput
                  value={homePhone}
                  onChange={setHomePhone}
                  placeholder="Enter home number"
                />
              </div>
              <div className="Addparanteditinfo-main-child-2-input-and-label">
                <label>Work Email</label>
                <input
                  value={workEmail}
                  onChange={(e) => setWorkEmail(e.target.value)}
                  placeholder="-"
                />
              </div>
              <div className="Addparanteditinfo-main-child-2-input-and-label">
                <label>Work Phone</label>
                <input
                  value={workNumber}
                  onChange={(e) => setWorkNumber(e.target.value)}
                  placeholder="-"
                />
              </div>
            </div>
            <div
              className="ADDEditSchoolInfo-Switch"
              style={{ display: "flex", gap: "20px", alignItems: "center" }}
            >
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id=""
                  checked={portalAccess}
                  onChange={() => {
                    setPortalAccess(!portalAccess);
                    setHandleFirstSwitch(!handleFirstSwitch);
                  }}
                />
                <label className="form-check-label">Portal Access</label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id=""
                />
                <label className="form-check-label">Allow SSO Login</label>
              </div>
            </div>
          </div>

          <div className="ADDSwitchShowInput">
            <div className="ADDSwitchShowInput-child-1">
              <label>Access Information</label> <br />
              <input
                value={loginEmailAddress}
                onChange={(e) => setLoginEmailAddress(e.target.value)}
                placeholder="Login Email Address*"
              />
            </div>
            <div className="ADDSwitchShowInput-child-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  checked={handleSecondSwitch}
                  onChange={() => setHandleSecondSwitch(!handleSecondSwitch)}
                />
                <label class="form-check-label" for="flexCheckDefault">
                  Set or Reset Password
                </label>
              </div>
            </div>
          </div>

          {handleSecondSwitch ? (
            <div className="ADDEditShowHide">
              <h4>Password</h4>
              <h5>Student email to set or reset password</h5>
              <div class="form-check">
                <input
                  class="form-check-input "
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios1"
                  value="option1"
                />
                <label class="form-check-label labelss" for="exampleRadios1">
                  Student will be able to create password on their own by
                  clicking the link on their email
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios1"
                  value="option1"
                />
                <label class="form-check-label" for="exampleRadios1">
                  Create Password
                </label>
              </div>
              <div className="ADDShowCloseDiv-main">
                <div className="ADDShowCloseDiv">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                  />
                  <span className="eye-icon" onClick={handleTogglePassword}>
                    {showPassword ? "👁️" : "👁️‍🗨️"}
                  </span>
                </div>
                <p onClick={generateRandomPassword}>
                  <RefreshIcon />
                  Auto Generate Password
                </p>
              </div>
            </div>
          ) : null}
          <div className="Addparanteditinfo-main-child-2-tow-button">
            <button
              onClick={() => navigate(`/Addparant/${params?.parentId}`)}
              className="Addparanteditinfo-main-child-2-tow-button-1"
            >
              Cancel
            </button>
            <button
              onClick={handleUpdateParent}
              className="Addparanteditinfo-main-child-2-tow-button-2"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Addparanteditinfo;
