import React, { useState, useEffect } from "react";
import axios from "axios";
import { GoPeople } from "react-icons/go";
import { PiChartBarHorizontalDuotone } from "react-icons/pi";
import { PiNotebookDuotone } from "react-icons/pi";
import "./TopADDCard.css";
import { baseURL } from "../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

function TopADDCards() {
  const [studentLength, setStudentLength] = useState(null);

  const { studentId } = useSelector((state) => state.studentId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.post(
          `/attendances/getStudentAttendanceAnalyticsForParent/${studentId}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setStudentLength(data);
      } catch (error) {
        console.error(error.response.data.message);
      }
    };

    fetchData();
  }, [studentId]);

  return (
    studentLength && (
      <>
        <div className="ADDtopCards">
          <div className="ADDtopCards-1">
            <div className="ADDtopCards-1-svg">
              <GoPeople />
            </div>
            <div>
              <h2>Total Student</h2>
              <p>{studentLength?.totalDays}</p>
            </div>
          </div>
          <div className="ADDtopCards-2">
            <div className="ADDtopCards-2-svg">
              <PiChartBarHorizontalDuotone />
            </div>
            <div>
              <h2>Total Present</h2>
              <p>{studentLength?.totalPresentDays}</p>
            </div>
          </div>
          <div className="ADDtopCards-3">
            <div className="ADDtopCards-3-svg">
              <PiNotebookDuotone />
            </div>
            <div>
              <h2>Absent</h2>
              <p>{studentLength?.totalAbsentDays}</p>
            </div>
          </div>
          <div className="ADDtopCards-3">
            <div className="ADDtopCards-3-svg">
              <PiNotebookDuotone />
            </div>
            <div>
              <h2>UnExcused</h2>
              <p>{studentLength?.totalUnexcusedDays}</p>
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default TopADDCards;
