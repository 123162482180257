import React, { useEffect, useState } from "react";
import "./CourseSchedule.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { FaUserEdit } from "react-icons/fa";
import { ImBin2 } from "react-icons/im";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Modall from "react-bootstrap/Modal";
import Fade from "@mui/material/Fade";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
const CourseSchedule = () => {
  const [deleteId, setDeleteId] = useState("");
  const [open, setOpen] = useState(false);
  const [editId, setEditId] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setDeleteId("");
    setEditId("");
  };
  const [lgShow, setLgShow] = useState(false);
  const [studentCourses, setStudentCourses] = useState([]);

  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedProgram, setSelectedProgram] = useState("");
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [selectedMarkingPeriod, setSelectedMarkingPeriod] = useState("");
  const [subjectId, setSubjectId] = useState("");

  const [subjects, setSubjects] = useState(null);

  const { schoolId } = useSelector((state) => state?.schoolId);

  const getSubjects = async () => {
    try {
      const { data } = await axios.get(`/subjects/getSubjects/${schoolId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setSubjects(data?.subjects);
    } catch (error) {
      console.log(error);
    }
  };

  const [programs, setPrograms] = useState(null);

  const getPrograms = async () => {
    try {
      const { data } = await axios.get(
        `/programs/getProgramsBySchool/${schoolId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );

      setPrograms(data?.programs);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCourses = async () => {
    try {
      const { data } = await axios.get(`/courses/all/${schoolId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setStudentCourses(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [teachers, setTeachers] = useState(null);

  const getTeachers = async () => {
    try {
      const { data } = await axios.get(`/teachers/all/${schoolId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setTeachers(data?.teacherList);
    } catch (error) {
      console.log(error);
    }
  };

  const [rooms, setRooms] = useState("");

  const getRooms = async () => {
    try {
      const { data } = await axios.get(`/rooms/getSchoolRooms/${schoolId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setRooms(data?.rooms);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSubjects();
    getPrograms();
    getAllCourses();
    getTeachers();
    getRooms();
  }, [schoolId]);

  const params = useParams();

  const handleAssignCourse = async () => {
    try {
      const { data } = await axios.post(
        "/studentCourses/assignStudentCourse",
        {
          studentId: params?.studentId,
          courseId: selectedCourse,
          subjectId: subjectId,
          programId: selectedProgram,
          roomId: selectedMarkingPeriod,
          teacherId: selectedTeacher,
        },
        {
          headers: {
            "Content-Type": "application/json",
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Course Assigned Successfully");
      getCourses();
    } catch (error) {
      if (error?.response?.data?.error) {
        toast.error(
          error?.response?.data?.error[0]?.error ||
            error?.response?.data?.error[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [schedules, setSchedules] = useState(null);

  const getCourses = async () => {
    try {
      const { data } = await axios.get(
        `/studentCourses/getstudentCourseByTeacherId/${params?.staffId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setSchedules(data?.studentCourses);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  const handleDeleteSchedule = async () => {
    try {
      const { data } = await axios.delete(
        `/studentCourses/removeStudentCourse/${deleteId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      getCourses();
      handleClose();
      toast.success("Deleted Successfully");
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.detail
        );
      } else if (error?.response?.data?.error) {
        toast.error(
          error?.response?.data?.error[0]?.detail ||
            error?.response?.data?.error[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (editId) {
      setLgShow(true);
    }
  }, [editId]);

  return (
    <>
      <Modal
        className="Allowmodalmain"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="CommentModal-Mmain-child1">
              <h4>Delete course</h4>
              <p>
                Are you sure you want to delete this course? This will remove
                the course and can’t be undone.
              </p>
              <div className="CommentModal-Mmain-child2">
                <button
                  className="
                  CommentModal-Mmain-child2-allow"
                  onClick={handleClose}
                >
                  CANCEL
                </button>
                
                <button
                  onClick={handleDeleteSchedule}
                  className="
                  CommentModal-Mmain-child2-Dont-allow"
                >
                DELETE
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* <div className="CourseAssign-main">
        <div onClick={() => setLgShow(true)}>Assign Course Section</div>
        <Modall
          size="xl"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
          className="CourseAssign-Modal"
        >
          <Modal.Header closeButton>
            <h2 className="Course-h2">Assign Course Section</h2>
          </Modal.Header>
          <Modal.Body>
            <div className="CourseAssign-Select">
              <select onChange={(e) => setSelectedCourse(e.target.value)}>
                <option value="1">Select Course</option>

                {studentCourses &&
                  studentCourses?.courses?.map((item) => (
                    <option key={item?.id} value={item?.id}>
                      {item?.courseName}
                    </option>
                  ))}
              </select>
              <select
                value={subjectId}
                onChange={(e) => setSubjectId(e.target.value)}
              >
                <option value="">Select Subject</option>
                {subjects &&
                  subjects?.map((item) => (
                    <option key={item?.id} value={item?.id}>
                      {item?.subjectName}
                    </option>
                  ))}
              </select>
              <select onChange={(e) => setSelectedProgram(e.target.value)}>
                <option value="">Select Program</option>
                {programs &&
                  programs?.map((item) => (
                    <option key={item?.id} value={item?.id}>
                      {item?.programName}
                    </option>
                  ))}
              </select>
              <select
                onChange={(e) => setSelectedMarkingPeriod(e.target.value)}
              >
                <option value={""}>Select Room</option>
                {rooms &&
                  rooms?.map((room) => {
                    return <option value={room?.id}>{room?.roomNumber}</option>;
                  })}
              </select>
              <select
                value={selectedTeacher}
                onChange={(e) => setSelectedTeacher(e.target.value)}
              >
                <option value={""}>Select Teacher</option>
                {teachers &&
                  teachers?.map((teacher) => {
                    return (
                      <option value={teacher?.id}>{teacher?.staffName}</option>
                    );
                  })}
              </select>
            </div>
            <div className="CourseAssign-Button">
              <button onClick={handleAssignCourse}>
                Assign Selected Course Section(s)
              </button>
            </div>
          </Modal.Body>
        </Modall>
      </div> */}
      <div className="CourseTab-main">
        <div className="CourseTab-row-1">
          <h2>Schedule Courses</h2>
        </div>

        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Course Section</th>
              <th>Teacher</th>
              <th>Classroom</th>
              <th>Program</th>
              <th>Subject</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {schedules?.map((schedule) => {
              return (
                <tr className="TableFirstrow">
                  <td>{schedule?.id}</td>
                  <td>{schedule?.Course?.courseName}</td>
                  <td>
                    {schedule?.Teacher?.firstName} {schedule?.Teacher?.lastName}
                  </td>
                  <td>{schedule?.Classroom?.classroomName}</td>
                  <td>{schedule?.Program?.programName}</td>
                  <td>{schedule?.Subject?.subjectName}</td>
                  <td>
                    {/* <FaUserEdit
                      onClick={() => {
                        setEditId(schedule?.id);
                      }}
                    /> */}
                    <ImBin2
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleOpen();
                        setDeleteId(schedule?.id);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CourseSchedule;
