import React, { useState, useEffect } from "react";
import { IoSearchOutline } from "react-icons/io5";
import "./Customerdetailsfilter.css";
import { FaCalendarDays } from "react-icons/fa6";
import ByDate from "../ByDate/ByDate";
import FilterModal from "../ByDate/Filter/FilterModal";

const CustomerDetailsFiltr = () => {
  const [attendanceDate, setAttendanceDate] = useState("");
  const [isChecked, setChecked] = useState(false);
  const handleToggle = () => {
    setChecked(!isChecked);
  };

  useEffect(() => {
    updateDateToNextDay();
  }, []);

  const updateDateToNextDay = () => {
    const currentDate = new Date();
    const nextDay = new Date(currentDate);
    nextDay.setDate(currentDate.getDate() + 1);

    const formattedNextDay = formatDate(nextDay);
    setAttendanceDate(formattedNextDay);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  return (
    <>
      <div className="customerdetail-main">
        <div className="customerdetail-child-1">
          <p>Attendance</p>
          <div className="inputs">
            <FaCalendarDays />
            <input
              type="date"
              id="attendanceDate"
              value={attendanceDate}
              onChange={(e) => setAttendanceDate(e.target.value)}
            />
          </div>
        </div>
        <div className="customerdetail-child-2">
          <div className="customerdetail-child-2-search">
            <IoSearchOutline />
            <input type="text" placeholder="Search ....." />
          </div>
          <ByDate />
          <FilterModal />
        </div>
      </div>
    </>
  );
};

export default CustomerDetailsFiltr;
