import { useEffect, useState } from "react";
import StudentHeader from "../StudentHeader/StudentHeader";
import "./StudentsView.css";
import TableView from "../TableView/TableView";
import CardView from "../CardView/CardView";
import { useParams } from "react-router-dom";

const StudentsView = ({ link, cardData, tableData, getStudentData, allStudents }) => {
  const params = useParams();
  const [showTable, setShowTable] = useState(true);

  const [searchValue, setSearchValue] = useState("");

  const [filteredStudents, setFilteredStudents] = useState([]);

  
  useEffect(() => {
    if (tableData) {
      setFilteredStudents(tableData);
    }
  }, [tableData]);

  const handleSearch = () => {
    if (searchValue === "") {
      setFilteredStudents(tableData);
    } else {
      if (tableData && tableData?.length > 0) {
        setFilteredStudents(() => {
          return tableData.filter((student) => {
            return student?.studentName
              ?.toLowerCase()
              .includes(searchValue?.toLowerCase());
          });
        });
      }
    }
  };


  useEffect(() => {
    if (tableData) {
      handleSearch()
    }
  }, [searchValue, tableData])
//    useEffect(() => {
//   const handleKeyDown = (e) => {
//     if (e.key === "Enter") {
//       handleSearch();
//     }
//   };

//   window.addEventListener("keydown", handleKeyDown);

//   // Cleanup function
//   return () => {
//     window.removeEventListener("keydown", handleKeyDown);
//   };
// }, []);

  return (
    <div className="students-view">
      <StudentHeader
        filterFunction={handleSearch}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        showTable={showTable}
        allStudents={allStudents}
        setShowTable={setShowTable}
      />
      {showTable && tableData ? (
        <TableView
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          allStudents={allStudents}
          getStudentData={getStudentData}
          tableData={filteredStudents}
          link={link}
        />
      ) : cardData ? (
        <CardView cardData={filteredStudents} link={link} />
      ) : null}
    </div>
  );
};

export default StudentsView;
