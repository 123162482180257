import React from "react";
import "./Medical.css";
import Alert from "./Frame 1686554853.png";
import print from "./flat-color-icons_print.png";
import Shoaib from "../../StudentComponents/images/Shoaib.png";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getParentStudents } from "../../../redux/actions/userAction";
import { getMedicalStudentId } from "../../../redux/actions/userAction";
import axios from "axios";
import Dummy from "../../AdminComponents/images/dummy-user.png";
import { baseURL } from "../../../constants";
axios.defaults.baseURL = baseURL;
function Medical() {

  const { loading, students, error } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const { studentId: currentStudentId } = useSelector(
    (state) => state.medicalStudentId
  );

  const { user } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    if (user) {
      dispatch(getParentStudents(user?.dataValues?.id));
    }
  }, [user]);

  const [records, setRecords] = useState(null);

  const getMedicalRecords = async () => {
    try {
      if (currentStudentId) {
        const { data } = await axios.get(
          `/medicalRecords/medicalRecordsBystudentId/${currentStudentId}`,
          {
            headers: {
              accessToken: localStorage.getItem("token"),
            },
          }
        );
        setRecords(data?.medicalRecords);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMedicalRecords();
  }, [currentStudentId]);

  console.log(records, "These are rrr");
  // const GetParentData = async () => {
  //   try {
  //     const { data } = await axios.get(
  //       `/students/getStudentsByParentId/${
  //         JSON.parse(localStorage.getItem("user"))?.id
  //       }`
  //     );
  //     setStudents(data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   GetParentData();
  // }, []);

  const [allStudents, setAllStudents] = useState([]);

  const [currentStudent, setCurrentStudent] = useState({
    name: "",
    image: "",
  });

  const [studentId, setStudentId] = useState(null);

  useEffect(() => {
    if (currentStudentId && students) {
      const student = students.find((st) => st.studentId == currentStudentId);

      setCurrentStudent({
        name: student?.Student?.firstName || "Name",
        image: `${baseURL}/images/${student?.Student?.profilePicture}`,
      });

    } else if (students && students?.length > 0) {
      setCurrentStudent({
        name: students?.Student?.[0]?.firstName || "Name",
        image: `${baseURL}/images/${students?.[0]?.Student?.profilePicture}`,
      });
      handleStoreId(students[0]?.studentId);

    }
  }, [students, currentStudentId]);

  const handleStoreId = (id) => {
    dispatch(getMedicalStudentId(id));
  };
  function handlePrint() {
    window.print()
}
  return (
    <div className="Medical-Main">
      <div className="Medical-Top">
        <h3>Medical Logarithms Options</h3>
        <div className="dropdownn">
          <div className="dropdownn">
            {students && students?.length > 0 ? (
              <button
                className="btn dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <>
                  <img
                    src={currentStudent.image}
                    alt="userName"
                    onError={({ currentTarget }) => {
                      currentTarget.src = Dummy;
                    }}
                  />
                  <span className="userName">{currentStudent.name}</span>
                </>
              </button>
            ) : (
              <p>No Students</p>
            )}
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {students &&
                students?.map((item) => {
                  return (
                    <li
                      onClick={() => {
                        handleStoreId(item?.studentId);
                      }}
                    >
                      <a className="dropdown-item" href="#">
                        <img
                          src={`${baseURL}/images/${item?.Student?.profilePicture}`}
                          alt="User"
                          onError={({ currentTarget }) => {
                            currentTarget.src = Dummy;
                          }}
                        />
                        {item?.Student?.firstName}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
      <div className="Medical-Second">
        <h4>
          Health Problems:<span> Allergy: Peanut</span>{" "}
        </h4>
        <div className="Medical-Second-right">
          <div className="Medical-Second-Child">
            <img src={Alert} alt="#" />
            <p>Wear Glasses All the Time</p>
          </div>
          <button>Alert !</button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Code</th>
            <th>Start Time</th>
            <th>End Time</th>
          </tr>
        </thead>
        <tbody>
          {records?.map((record) => {
            return (
              <tr>
                <td>{record?.date?.split("T")[0]}</td>
                <td>{record?.code}</td>
                <td>{record?.startTime}</td>
                <td>{record?.endTime}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="PrintImg">
        <button onClick={handlePrint}>
          <img src={print} alt="#" />
          Print Medical Profile
        </button>
      </div>
    </div>
  );
}
export default Medical;
