import React, { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import "./Myassingmentsubject.css";
import { IoCaretForwardOutline } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import RoomModal from "./SubjectModal/RoomModal";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modall from "@mui/material/Modal";
import axios from "axios";
import { baseURL } from "../../../../constants";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const MyRooms = () => {
  const assignmentsData = [
    {
      descriptionColor: "#F93333",
      bgColor: "#F93333",
      buttonBgColor: "#f93333",
      buttonTextColor: "#F93333",
      button: "Active",
      date: "1",
      programCodeColor: "#F93333",
      programDurationColor: "#F93333",
      drageIconColor: "#F93333",
      EditIconClor: "",
    },
    {
      descriptionColor: "#16D03B",
      bgColor: "#16D03B",
      buttonBgColor: "#16D03B",
      buttonTextColor: "#16D03B",
      button: "Active",
      bcColor: "#F0FFF3",
      mainbordercolor: "#16D03B",
      date: "2",
      programCodeColor: "#16D03B",
      programDurationColor: "#F93333",
      drageIconColor: "#16D03B",
    },
  ];

  const [roomId, setRoomId] = useState("");
  const [roomId2, setRoomId2] = useState("");

  const [open, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClosee = () => {
    setIsOpen(false);
    setRoomId("");
    setRoomId2("");
  };

  const [rooms, setRooms] = useState(null);
  const { schoolId } = useSelector((state) => state.schoolId);

  const getRooms = async () => {

    try {
      const { data } = await axios.get(`/rooms/getSchoolRooms/${schoolId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setRooms(data?.rooms);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRooms();
  }, [schoolId]);


  const handleDeleteRoom = async () => {
    try {
      await axios.delete(`/rooms/removeRoom/${roomId2}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      toast.success("Room Deleted Successfully");
      getRooms();
      handleClosee();
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.message ||
            error?.response?.data?.details[0]?.detail
        );
      } else if (error?.response?.data?.error) {
        toast.error(
          error?.response?.data?.error[0]?.message ||
            error?.response?.data?.error[0]?.error ||
            error?.response?.data?.error[0].detail
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <div className="myassingment-main2">
      <div className="myassingment-TOP">
        <h3>School </h3>
        <IoCaretForwardOutline />
        <h4 style={{ color: "#00afef" }}>Manage Academics</h4>
        <IoCaretForwardOutline />
        <h4> Room</h4>
      </div>
      <div className="myassingment-SeC">
        <div className="myassingment-SeC-input">
          <IoIosSearch />
          <input placeholder="Search ." type="text" />
        </div>
        <div className="myassingment-SeC-button">
          <RoomModal setRoomId={setRoomId} roomId={roomId} func={getRooms} />
        </div>
      </div>
      <div
        className="myassingment-SeC-Table "
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h6>
          <input type="checkbox" />
          Room number
        </h6>
        <h6>Location</h6>
        <h6>Action</h6>
      </div>
      <div className="CommentModal-Mmain">
        <Modall
          className="Allowmodalmain"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClosee}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div className="CommentModal-Mmain-child1">
                <h4>Delete Room</h4>
                <p>
                  Are you sure you want to delete this Room? This will remove
                  the Room and can’t be undone.
                </p>
                <div className="CommentModal-Mmain-child2">
                  <button
                    className="CommentModal-Mmain-child2-allow"
                    onClick={handleClosee}
                  >
                    CANCEL
                  </button>
                  <button
                    onClick={handleDeleteRoom}
                    className="CommentModal-Mmain-child2-Dont-allow"
                  >
                    DELETE
                  </button>
                </div>
              </div>
            </Box>
          </Fade>
        </Modall>
      </div>
      {/* map */}
      <div style={{ marginTop: "10px" }}>
        {rooms?.map((assignment, index) => {
          const newIndex = index % assignmentsData.length;

          return (
            <div
              className="myassingments-child-2m"
              key={assignment?.id}
              style={{ marginTop: "10px" }}
            >
              <div
                className="myassingments-cardd"
                style={{
                  backgroundColor: assignmentsData[newIndex]?.bcColor,
                  borderColor: assignmentsData[newIndex]?.mainbordercolor,
                }}
              >
                <div className="myassingments-card-childs-1m1">
                  <div className="myassingmentss-childss">
                    <div className="myassingments-childss-Data">
                      <div
                        className="myassingments-childs-dateE"
                        style={{
                          backgroundColor: assignmentsData[newIndex]?.bgColor,
                        }}
                      >
                        <p>{assignment?.id}</p>
                      </div>
                      <div className="Mychildss-myassingmentss">
                        <h6>{assignment?.roomNumber}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Mychildss-myassingmentss">
                  <h6>{assignment?.location}</h6>
                </div>

                <div className="mychilds-myassingments-dov">
                  <div className="mychilds-myassingments-icoNs">
                    <MdEdit
                      onClick={() => setRoomId(assignment?.id)}
                      style={{
                        color: assignmentsData[newIndex]?.drageIconColor,
                      }}
                    />
                    <RiDeleteBin6Line
                      style={{
                        color: assignmentsData[newIndex]?.drageIconColor,
                      }}
                      onClick={() => {
                        handleOpen();
                        setRoomId2(assignment?.id);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MyRooms;
