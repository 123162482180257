import React, { useState } from "react";
import Chart from "react-apexcharts";
import Dropdown from "react-bootstrap/Dropdown";
import { TbArrowsDiagonal } from "react-icons/tb";
import { IoAlertCircle } from "react-icons/io5";
import "./ADDBarChart.css";

const ADDBarChart = ({ data: graphData = null }) => {
  const [selectedWeek, setSelectedWeek] = useState("Monthly");
  const [options, setOptions] = useState({
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    yaxis: {
      min: 0,
      tickAmount: 4,
      labels: {
        formatter: function (value) {
          return formatYAxisLabel(value);
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 2,
        columnWidth: "80%",
      },
    },
    fill: {
      colors: ["#2563EB", "#38BDF8", "#DBEAFE"],
    },
    toolbar: {
      show: false,
    },
    legend: {
      markers: {
        fillColors: ["#2563EB", "#38BDF8", "#DBEAFE"],
        radius: 50,
      },
    },
  });

  const [series] = useState({
    Weekly: [
      {
        name: "Remaining Funds",
        data: graphData
          ? graphData.weekly.map((e) => e.RemainingFund)
          : [34, 53, 23, 43, 23, 40, 30],
      },
      {
        name: "Expenses",
        data: graphData
          ? graphData.weekly.map((e) => e.Expenses)
          : [34, 53, 23, 43, 23, 40, 30],
      },
      {
        name: "Total Budget",
        data: graphData
          ? graphData.weekly.map((e) => e.TotalBudget)
          : [34, 53, 23, 43, 23, 40, 30],
      },
    ],
    Monthly: [
      {
        name: "Remaining Funds",
        data: graphData
          ? graphData.monthly.map((e) => e.RemainingFund)
          : [3, 53, 23, 43, 3, 40, 30],
      },
      {
        name: "Expenses",
        data: graphData
          ? graphData.monthly.map((e) => e.Expenses)
          : [34, 3, 23, 3, 23, 40, 30],
      },
      {
        name: "Total Budget",
        data: graphData
          ? graphData.monthly.map((e) => e.TotalBudget)
          : [34, 53, 3, 43, 23, 40, 3],
      },
    ],
    Yearly: [
      {
        name: "Remaining Funds",
        data: graphData
          ? graphData.yearly.map((e) => e.RemainingFund)
          : [34, 5, 23, 43, 23, 6, 5],
      },
      {
        name: "Expenses",
        data: graphData
          ? graphData.yearly.map((e) => e.Expenses)
          : [34, 53, 2, 43, 23, 6, 5],
      },
      {
        name: "Total Budget",
        data: graphData
          ? graphData.yearly.map((e) => e.TotalBudget)
          : [34, 53, 23, 43, 23, 6, 5],
      },
    ],
  });

  const handleWeekChange = (week) => {
    setSelectedWeek(week);
  };

  return (
    <div className="AddBarChart">
      <div className="AddBarChartRoww">
        <div className="chart-container">
          <div className="AddDropdown">
            <div className="appCentrality">
              <h2>Financial Overview</h2>
              <span title="Something about this feature and what it does. ">
                <IoAlertCircle />
              </span>
              <span>
                <TbArrowsDiagonal />
              </span>
            </div>
            <Dropdown onSelect={handleWeekChange}>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {selectedWeek}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="Weekly">Weekly</Dropdown.Item>
                <Dropdown.Item eventKey="Monthly">Monthly</Dropdown.Item>
                <Dropdown.Item eventKey="Yearly">Yearly</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {selectedWeek && (
            <Chart
              options={options}
              series={series[selectedWeek]}
              type="bar"
              width="100%"
              height="400"
              className="custom-chart-class"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const formatYAxisLabel = (value) => {
  if (value >= 1e9) {
    return (value / 1e9).toFixed(1) + "B";
  } else if (value >= 1e6) {
    return (value / 1e6).toFixed(1) + "M";
  } else if (value >= 1e3) {
    return (value / 1e3).toFixed(1) + "k";
  } else {
    return value;
  }
};

export default ADDBarChart;
