import React from "react";
import "./Special.css";
import print from "../Medical/flat-color-icons_print.png";
import Clndr from "./Vector.png";
import Degree from "./mdi_university.png";

function Special() {
  function handlePrint() {
    window.print();
  }
  return (
    <div className="Specail-MAin">
      <div className="Special-Top">
        <h3>Special Education</h3>
      </div>
      <div className="Special-Second-MAin">
        <div className="Special-Second">
          <h3>Basic Information</h3>
          <button onClick={handlePrint}>
            <img src={print} alt="#" />
            Print the plan
          </button>
        </div>
        <div className="Special-Third-MAin">
          <div className="Special-Third">
            <div>
              <h4>Disability</h4>
              <p>
                Speech or Language <br /> Impairment (SLI)
              </p>
            </div>
            <div>
              <h4>Disability</h4>
              <p>
                Emontional Disturbance <br /> (ED)
              </p>
            </div>
            <div>
              <p>
                Flex Scheduling High School <br /> With ATT 9999894 Public{" "}
                <br /> Day School
              </p>
            </div>
            <div>
              <h4>
                <img src={Clndr} alt="#" />
                Date
              </h4>
              <p>11/22/2016</p>
            </div>
          </div>
          <div className="Special-Third">
            <div>
              <h4>Dist of Service</h4>
              <p>
                Eagle Unified School <br /> District 6599999
              </p>
            </div>
            <div>
              <h4>Dist of Residence</h4>
              <p>
                Eagle Unified School <br /> District 6599999
              </p>
            </div>
            <div>
              <h4>Dist of Residence</h4>
              <p>
                Eagle Unified School <br /> District 6599999
              </p>
            </div>
            <div>
              <h4>SELPA</h4>
              <p>
                3002-South Orange <br /> County Selpa
              </p>
            </div>
          </div>
        </div>
        <div className="Special-Second-MAin">
          <div className="Special-Second">
            <h3>Plan Information</h3>
          </div>
          <div className="Special-last">
            <h2>1=10 - Individualized Education Program (IEP)</h2>
            <div className="Degree">
              <h2>
                <img src={Degree} alt="#" />
                Plan:{" "}
                <span>
                  To Participate in high school curriculum leading to a diploma{" "}
                  <br /> Gen e part % equal to or greater than 80 percent
                </span>
              </h2>
            </div>
          </div>
        </div>
        <div className="LastDate">
          <div className="LastDateChild">
            <img src={Clndr} alt="#" />
            <h2>
              Next Annual Review:<span>11/30/2023</span>
            </h2>
          </div>
          <div className="LastDateChild">
            <img src={Clndr} alt="#" />
            <h2>
              Last Triennial Review:<span>11/30/2021</span>
            </h2>
          </div>
        </div>
        <div className="LastDate">
          <div className="LastDateChild">
            <img src={Clndr} alt="#" />
            <h2>
              Next Triennial Review:<span>11/30/2024</span>
            </h2>
          </div>
          <div className="LastDateChild">
            <img src={Clndr} alt="#" />
            <h2>
              Last IEP Meeting:<span>11/30/2022</span>
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Special;
