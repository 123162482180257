import React, { useEffect, useState } from "react";
import { GrFormNext } from "react-icons/gr";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../redux/actions/userAction.js";
import axios from "axios";
import { baseURL } from "../../../../constants";
axios.defaults.baseURL = baseURL;

const TeacherMySchedule = () => {
  const [events, setEvents] = useState([]);
  const [cardEvents, setCardEvents] = useState([]);

  const { user } = useSelector((state) => state.getUserProfile);
  const dispatch = useDispatch();

  const [schoolId, setSchoolId] = useState(null);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    const getSchoolByParentId = async () => {
      try {
        if (user) {
          const { data } = await axios.get(
            `/ParentBySchoolId/getSchoolByTeacherId/${user.dataValues.id}`,
            {
              headers: {
                accessToken: `${localStorage.getItem("token")}`,
              },
            }
          );
          setSchoolId(data.school.id);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (user) {
      getSchoolByParentId();
    }
  }, [user]);

  useEffect(() => {
    const getEvents = async () => {
      try {
        if (schoolId) {
          const { data } = await axios.get(
            `/events/getSchoolEvents/${schoolId}`,
            {
              headers: {
                accessToken: `${localStorage.getItem("token")}`,
              },
            }
          );
          setEvents(data.events || []);
          setCardEvents(data.events || []);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (schoolId) {
      getEvents();
    }
  }, [schoolId]);

  return (
    <div className="myschedule-main">
      <div className="myschedule-heading">
        <h1>Upcoming Events</h1>
        <Link to="/TeacherCalender">
          <span>See All</span>
        </Link>
      </div>
      {cardEvents?.slice(0, 3).map((event) => (
        <div
          key={event.id} // Added a key prop for React to manage list items
          className="myschedule-child"
          style={{
            marginTop: "19px",
            backgroundColor: event?.color || "#FFF0F7",
          }}
        >
          <div className="myschedule-child-data">
            <div
              className="myschedule-child-date"
              style={{
                background: "#FF1D86",
              }}
            >
              <p>{event?.id}</p>
            </div>
            <div className="mychild-myschedule">
              <div className="myschedule-time">
                <h6>{event?.eventName}</h6>
                <div className="myschedule-mother">
                  <p>{event?.startdate?.split("T")[0]}</p>
                  <span
                    style={{
                      color: "#FF1D86",
                      fontSize: "14px",
                    }}
                  >
                    ●
                  </span>
                  <p>{event?.enddate?.split("T")[0]}</p>
                </div>
              </div>
            </div>
            <div className="myschedule-icon">
              <Link>
                <GrFormNext style={{ width: "18px", height: "18px" }} />
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TeacherMySchedule;
