import React, { useEffect, useState } from "react";
import "./ADDProfile.css";
import EditStudentInfo from "../StudentsEditInfo/ADDEditStudentinfo";
import axios from "axios";
import { baseURL } from "../../../../constants";
axios.defaults.baseURL = baseURL;
function GeneralInformation({
  student,
  getStudent,
  setShowEditPicture,
  showEditPicture,
  selectedFile,
}) {
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    if (window?.location?.search?.split("=")[1] == "true") {
      setShowEdit(true);
    } else {
      setShowEdit(false);
    }
  }, [window.location.search]);

  return !showEdit ? (
    <div className="GeneralInfromation-main">
      <div className="GeneralChild-1">
        <h2>General Information</h2>
        <button
          onClick={() => {
            setShowEditPicture(true);
            setShowEdit(true);
          }}
        >
          Edit Information
        </button>
      </div>
      <div className="GeneralInfromation-inputs-main">
        <div>
          <label>Student Information</label>
          <br />
          <input type="text" readOnly value={student?.studentInformation} />
        </div>
        <div>
          <label>Preferred/common Name</label>
          <br />
          <input type="text" readOnly value={student?.prefferedName} />
        </div>
        <div>
          <label>Student ID</label>
          <br />
          <input type="text" readOnly value={student?.studentId} />
        </div>
      </div>
      <div className="GeneralInfromation-inputs-two ">
        <div>
          <label>Govt Issue Number</label>
          <br />
          <input type="text" readOnly value={student?.govtIssuedNumber} />
        </div>
        <div>
          <label>Ethnicity Group</label>
          <br />
          <input type="text" readOnly value={student?.ethnicGroup} />
        </div>
        <div>
          <label>Admission Number</label>
          <br />
          <input type="text" readOnly value={student?.admissionNumber} />
        </div>
      </div>

      <div className="DemographicInformation-main">
        <h3>Demographic Information</h3>
        <div className="DemographicInformation-inputs ">
          <div>
            <label>Date of birth</label>
            <br />
            <input
              type="text"
              readOnly
              value={student?.dateOfBirth?.split("T")[0]}
            />
          </div>

          <div>
            <label>Gender</label>
            <br />
            <input type="text" readOnly value={student?.gender} />
          </div>
          <div>
            <label>Country of birth</label>
            <br />
            <input type="text" readOnly value={student?.countryOfBirth} />
          </div>
        </div>
      </div>
      <div className="DemographicInformation-inputs-two">
        <div>
          <label>Nationality</label>
          <br />
          <input type="text" readOnly value={student?.nationality} />
        </div>
        <div>
          <label>Travel By Bus</label>
          <br />
          <input
            type="text"
            readOnly
            value={student?.travelBybus == true ? "Yes" : "No"}
          />
        </div>
      </div>
      <div className="DemographicInformation-main">
        <h3>Access Information</h3>
        <div className="DemographicInformation-inputs ">
          <div>
            <label>Login Email Address</label>
            <br />
            <input type="text" readOnly value={student?.loginEmailAddress} />
          </div>
          <div>
            <label>Portal Access</label>
            <br />
            <input
              type="text"
              readOnly
              value={student?.portalAccess == true ? "Active" : "Inactive"}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <EditStudentInfo
      selectedFile={selectedFile}
      setShowEdit={setShowEdit}
      getStudent={getStudent}
      showEditPicture={showEditPicture}
      setShowEditPicture={setShowEditPicture}
      student={student}
    />
  );
}

export default GeneralInformation;
