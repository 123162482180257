import React, { useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import "./Sidebar.css";
import GridView from "@mui/icons-material/GridView";
import { CgLoadbarDoc } from "react-icons/cg";
import WebhookIcon from "@mui/icons-material/Webhook";
import Logo from "../../AdminComponents/images/Group.png";
import { IoCaretForwardOutline, IoCaretDownOutline } from "react-icons/io5";
import { BiSolidSchool } from "react-icons/bi";

const ClassRoomTabsSidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const location = useLocation();

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = (name) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const generateMenuItem = (
    path,
    name,
    icon,
    hasDropdown = false,
    submenuItems = []
  ) => {
    const item = {
      path: path,
      name: name,
      icon: icon,
    };
    if (hasDropdown) {
      item.arrow = dropdownOpen[name] ? (
        <IoCaretDownOutline />
      ) : (
        <IoCaretForwardOutline />
      );
      item.subMenu = submenuItems;
      item.onClick = () => toggleDropdown(name);
    }

    return item;
  };

  const menuItem = [
    generateMenuItem("/teacherhome", "Home", <GridView />),
    generateMenuItem("/TeacherClassRoomTopSearch", "Classes", <GridView />),
    generateMenuItem("/TabBook", "Gradebook", <GridView />),
    generateMenuItem(
      "/StuProfile/:studentId",
      "Student Data",
      <BiSolidSchool />,

      true,
      [
        { path: "/StuProfile/:studentId", name: "Profile" },
        { path: "/Teacher504Plan", name: "504 Plan" },
        { path: "/TeacherDiscipline", name: "Discipline" },
        { path: "/TeacherIEProgram", name: "IE Program" },
        { path: "/TeacherMedical", name: "Medical Log" },
        { path: "/TeacherMedicalHistory", name: "Medical History" },
        { path: "/Extracurricular", name: "Extracurricular Activities" },
        { path: "/TeacherSpecialEducation", name: "Special Education" },
        { path: "/TeacherNotes", name: "Notes" },
      ]
    ),

    generateMenuItem("/TeacherCalender", "Calendar", <CgLoadbarDoc />),
    generateMenuItem("/MockupRequests", "MakeUp Requests", <WebhookIcon />),
    generateMenuItem("/TeacherAccountSetting", "Setting", <WebhookIcon />),
  ];

  const activeMenuItem = menuItem.find(
    (item) => location.pathname === item.path
  );
  console.log(activeMenuItem);
  return (
    <>
      <div className="dashboardewrapper">
        <div className={`sidebar ${isOpen ? "active" : ""}`}>
          <Link to="/teacherhome" className="Logos">
            <img src={Logo} alt="Logo" />
            <h4>MYCENTRALITY</h4>
          </Link>
          <div className="menubar">
            <div
              style={{ display: "flex", flexDirection: "column" }}
              className="Fullwidth"
            >
              {menuItem.map((item, index) => (
                <div key={index} className="Full-Width">
                  <NavLink
                    to={item.path}
                    className="Sidebar-link"
                    activeClassName="active"
                    onClick={item.onClick}
                  >
                    <div className="icon">{item.icon}</div>
                    <div className="link_text ms-2  d-sm-inline">
                      {item.name}
                      {item.arrow}
                    </div>
                  </NavLink>
                  {item.subMenu &&
                    dropdownOpen[item.name] &&
                    item.subMenu.map((subItem, subIndex) => (
                      <NavLink
                        to={subItem.path}
                        key={subIndex}
                        className="Sidebar-sublink"
                      >
                        <div className="link_text d-sm-inline done">
                          <li>
                            <Link to={subItem.path}>{subItem.name}</Link>
                          </li>
                        </div>
                      </NavLink>
                    ))}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          className={`navbar-toggle ${isOpen ? "open" : ""}`}
          onClick={toggleNavbar}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <main>{children}</main>
      </div>
    </>
  );
};

export default ClassRoomTabsSidebar;
