import React, { useState, useEffect } from "react";
import axios from "axios";
import { GoPeople } from "react-icons/go";
import { PiChartBarHorizontalDuotone } from "react-icons/pi";
import { PiNotebookDuotone } from "react-icons/pi";
import "./TopADDCard.css";
import { baseURL } from "../../../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;

function TopADDCards() {
  const [studentLength, setStudentLength] = useState(null);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user) {
          const { data } = await axios.get(
            `/attendances/getAttendanceAnalyticsByTeacher/${
              user.dataValues.id
            }/${localStorage.getItem("classroomId")}`,
            {
              headers: {
                accessToken: `${localStorage.getItem("token")}`,
              },
            }
          );
          setStudentLength(data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [user]);

  return (
    studentLength && (
      <>
        <div className="ADDtopCards11">
          <div className="ADDtopCards-11">
            <div className="ADDtopCards-11-svg">
              <GoPeople />
            </div>
            <div>
              <h2>Total Student</h2>
              <p>{studentLength?.totalStudents}</p>
            </div>
          </div>
          <div className="ADDtopCards-21">
            <div className="ADDtopCards-21-svg">
              <PiChartBarHorizontalDuotone />
            </div>
            <div>
              <h2>Total Present</h2>
              <p>{studentLength?.presentStudents}</p>
            </div>
          </div>
          <div className="ADDtopCards-31">
            <div className="ADDtopCards-31-svg">
              <PiNotebookDuotone />
            </div>
            <div>
              <h2>Excused</h2>
              <p>{studentLength?.excusedStudents}</p>
            </div>
          </div>
          <div className="ADDtopCards-4">
            <div className="ADDtopCards-4-svg">
              <PiNotebookDuotone />
            </div>
            <div>
              <h2>UnExcused</h2>
              <p>{studentLength?.absentStudents}</p>
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default TopADDCards;