import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import MYCEN from "../../images/Left Content.png";
import UserProfile from "../../images/Replace Here.png";
import "./ModalInvoice.css";

function ModalInvoice() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="INOVICEmain">
        <button onClick={handleShow}>Invoice</button>

        <Modal show={show} onHide={handleClose} keyboard={false}>
          <div className="ModalINvoicemain">
            <Modal.Body>
              <div className="INvoive-main">
                <img src={MYCEN} alt="#" className="MInimg" />
                <div className="Invoice-child1">
                  <span className="InvoiceProfile">
                    <img src={UserProfile} alt="#" />
                    <h6>Mauro Sicard </h6>
                  </span>
                  <div className="InVoice-Amount">
                    <p>Total amount:</p>
                    <h2>19,570.00</h2>
                    <h4>$ USD</h4>
                  </div>
                </div>
              </div>
              <div className="BRIXAgency">
                <div className="Invoicenumber">
                  <div className="Issued">
                    <p>Invoice number:</p> <h3>Nº: 000027</h3>
                  </div>
                  <div className="Issued">
                    <p>Issued:</p> <h3>June 26, 2024</h3>
                  </div>
                  <div className="Issued">
                    <p>Due date:</p> <h3>July 26,2024</h3>
                  </div>
                </div>
                <div className="MauroSicard ">
                  <div className="card">
                    <h3>Mauro Sicard </h3>
                    <p>(612) 856 - 0989</p>
                    <p>contact@maurosicard.com</p>
                    <p>
                      Pablo Alto, San Francisco, CA 92102, United States of
                      America
                    </p>
                  </div>
                  <div className="card">
                    <h3>BRIX Agency </h3>
                    <p>(612) 856 - 0989</p>
                    <p>contact@maurosicard.com</p>
                    <p>
                      Pablo Alto, San Francisco, CA 92102, United States of
                      America
                    </p>
                  </div>
                </div>
                <div className="Description">
                  <div className="hedding">
                    <div className="first">
                      <p>Description</p>
                    </div>
                    <p>Qty</p>
                    <p>Price</p>
                    <p>Total</p>
                  </div>
                  <div className="hedding">
                    <div className="first">
                      <h3>Web design</h3>
                    </div>
                    <p>1</p>
                    <p>$5,250.00</p>
                    <span>$5,250.00</span>
                  </div>
                  <div className="hedding">
                    <div className="first">
                      <h3>Web design</h3>
                    </div>
                    <p>1</p>
                    <p>$5,250.00</p>
                    <span>$5,250.00</span>
                  </div>
                  <div className="hedding">
                    <div className="first">
                      <h3>Web design</h3>
                    </div>
                    <p>1</p>
                    <p>$5,250.00</p>
                    <span>$5,250.00</span>
                  </div>
                  <div className="hedding">
                    <div className="first">
                      <h3>Web design</h3>
                    </div>
                    <p>1</p>
                    <p>$5,250.00</p>
                    <span>$5,250.00</span>
                  </div>
                  <div className="hedding">
                    <div className="first">
                      <h3>Web design</h3>
                    </div>
                    <p>1</p>
                    <p>$5,250.00</p>
                    <span>$5,250.00</span>
                  </div>
                </div>
                <div className="ADDBtn">
                  <button>
                    Total amount: <span>$19,570.00</span>
                  </button>
                </div>
                <div className="TermConditions">
                  <h3>Terms & Conditions:</h3>
                  <p>
                    Fees and payment terms will be established in the <br />{" "}
                    contract or agreement prior to the commencement of the{" "}
                    <br /> project. An initial deposit will be required before
                    any <br /> design work begins. We reserve the right to
                    suspend or <br /> halt work in the event of non-payment.
                  </p>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default ModalInvoice;
