import React, { useEffect, useRef, useState } from "react";
import TopADDCards from "./ADDCards/TopADDCards";
import DonutChart from "./ADDdonutChart/DonutChart";
import "./NESDashboard.css";
import SchoolPopulation from "../SchoolPopulation/SchoolPopulation";
import LineChart from "./LineChart/LineChart";
import Event from "../ADDNestedMain/ADDEvents/Events";
import TestScore from "./GradeScore/TestScrore";
import ADDMessages from "./ADD-Messages-Request/ADDMessages";
import CardCarousel from "../ADDNestedMain/Carousel/CardCarousel";
import { IoCaretForwardOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import LineSchoolChart from "../Adminstrator/AdminstratorDashbord/LineChart/LineSchoolChart";
import Accordion from "react-bootstrap/Accordion";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import { baseURL } from "../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

function NESDashboard() {
  const [testScoreData, setTestScoreData] = useState(null);

  const getTestScoreData = async () => {
    try {
      const { data } = await axios.get("/testScores/getTestScore", {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });

      setTestScoreData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const [dataOverview, setDataOverview] = useState(null);
  const getDataOverview = async () => {
    try {
      const { data } = await axios.get("/classOverviews/getClassOverview", {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setDataOverview(data);
    } catch (error) {
      console.log(error);
    }
  };

  const { schoolId } = useSelector((state) => state?.schoolId);

  const [school, setSchool] = useState(null);

  const getSchool = async () => {
    try {
      const { data } = await axios.get(`/schools/getSchoolById/${schoolId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setSchool(data?.school);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchool();
    // eslint-disable-next-line
  }, [schoolId]);
  useEffect(() => {
    getDataOverview();
    getTestScoreData();
  }, []);

  const [series, setSeries] = useState([
    {
      data: [],
    },
  ]);

  const [selectedOption, setSelectedOption] = useState(
    "Incidents by Disposition"
  );

  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current && chartRef.current.chart) {
      chartRef.current.chart.updateSeries(series);
    }
  }, [series]);

  const [myData, setMyData] = useState(null);

  const getDisciplineStatistics = async () => {
    try {
      let url = "";

      switch (selectedOption) {
        case "Incidents by Disposition":
          url = `/disciplines/getDisciplineStatistics/${schoolId}?code=Disposition`;
          break;

        case "Incidents for all Violation":
          url = `/disciplines/getDisciplineStatistics/${schoolId}?code=Violation`;
          break;

        case "Incidents by Student":
          url = `/disciplines/getDisciplineStatistics/${schoolId}`;
          break;

        default:
          break;
      }

      const { data } = await axios.get(url, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });

      setMyData(data);

      console.log(data, "may this");
      setSeries([
        {
          data: data?.yAxis,
        },
      ]);
    } catch (error) {
      console.log(error, "this is container");
    }
  };

  useEffect(() => {
    getDisciplineStatistics();
    // eslint-disable-next-line
  }, [selectedOption]);
  const handleOptionChange = (event) => {
    const option = event.target.value;
    setSelectedOption(option);
  };

  const options = {
    chart: {
      height: 350,
      type: "bar",
    },

    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 0,
    },
    grid: {
      row: {
        colors: ["#fff", "#f2f2f2"],
      },
    },
    xaxis: {
      categories: myData && myData.xAxis.filter((dt) => dt && dt),
      tickPlacement: "on",
      labels: {
        rotate: -25,
      },
    },

    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100],
      },
    },
  };

  return (
    <div className="NESTEDMAIN">
      <div className="NesWidth70">
        <div className="NesWidth70-left">
          <Link to="/SchoolList">List of Schools</Link>
          <IoCaretForwardOutline />
          <p>{school?.name}</p>
        </div>
        <TopADDCards />

        <div className="NestedDashAccordion">
          <Accordion defaultActiveKey={["0", "1", "2", "3"]} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Notices</Accordion.Header>
              <Accordion.Body>
                <CardCarousel />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Centrality Score</Accordion.Header>
              <Accordion.Body>
                <div
                  style={{
                    height: "550px",
                    background: "white",
                  }}
                >
                  <LineSchoolChart />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Programs</Accordion.Header>
              <Accordion.Body>
                <div className="ThreeCharts">
                  <div className="ThreeCharts-three">
                    <TestScore />
                  </div>
                  <div className="ThreeCharts-one">
                    <LineChart lineData={testScoreData} />
                  </div>
                  <div className="ThreeCharts-two">
                    <SchoolPopulation />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>School Discipline Over</Accordion.Header>
              <Accordion.Body>
                <div style={{ background: "#fff" }}>
                  <div className="NestedDashboardAcco">
                    <h2>Total Number of Incidents For All Violation Codes</h2>
                    <div className="DisplineChartTop">
                      <select
                        value={selectedOption}
                        onChange={handleOptionChange}
                      >
                        <option value="Incidents by Disposition">
                          Incidents by Disposition
                        </option>
                        <option value="Incidents for all Violation">
                          Incidents for all Violation
                        </option>
                        <option value="Incidents by Student">
                          Incidents by Student
                        </option>
                      </select>
                    </div>
                  </div>
                  <div id="chart">
                    {myData && (
                      <ReactApexChart
                        options={options}
                        series={series}
                        type="bar"
                        height={350}
                        ref={chartRef}
                      />
                    )}
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>

        {/* {dataOverview && (
          <Charttt
            weekly={dataOverview[0].weekly}
            monthly={dataOverview[0].monthly}
            yearly={dataOverview[0].yearly}
          />
        )} */}
      </div>
      <div className="NesWidth30">
        <DonutChart />
        <Event />
        <ADDMessages />
      </div>
    </div>
  );
}

export default NESDashboard;
