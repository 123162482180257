import { useState, useEffect } from "react";
import "./NewNotice.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Successfulmodal from "../TeacherClassRoom/TeacherNestedTabs/NoticeBoard/Successfulmodal/SuccessfullModal";
import axios from "axios";
import { baseURL } from "../../../../constants";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;

const NewNotice = ({ func = () => {} }) => {
  const [lgShow, setLgShow] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [classroomId, setClassroomId] = useState("");

  const [classrooms, setClassrooms] = useState(null);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const [schoolId, setSchoolId] = useState(null);

  const getSchoolByParentId = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/ParentBySchoolId/getSchoolByTeacherId/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setSchoolId(data.school.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchoolByParentId();
    // eslint-disable-next-line
  }, [user]);

  const getClassrooms = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/classrooms/getClassroomsByTeacherId/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setClassrooms(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getClassrooms();
    // eslint-disable-next-line
  }, [user]);

  const [loading, setLoading] = useState(false);

  const addNoticeboard = async () => {
    try {
      if (schoolId) {
        const { data } = await axios.post(
          `/noticeboards/addNoticeBoard`,
          {
            schoolId: Number(schoolId),
            classroomId: Number(classroomId),
            title,
            description,
            date,
          },
          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        console.log(data);

        func();
        toast.success("Noticeboard Added");

        setLoading(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="NewNotice-main">
      <div>
        <Button onClick={() => setLgShow(true)}>Add Noticeboard</Button>
        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton className="new-Activity-header">
            <p>Noticeboard</p>
          </Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg"></Modal.Title>
          <Modal.Body>
            <div className="new-notice-mainn">
              <div>
                <label htmlFor="title">Title</label>
                <div>
                  <input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    type="text"
                    id="text"
                    placeholder="Title"
                  />
                </div>
              </div>
              <div className="new-notice-mainn-child-1">
                <div>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    name="text"
                    id="text"
                    placeholder="Description"
                  ></textarea>
                </div>
              </div>
              <div>
                <div className="new-notice-mainn-child-2">
                  <div className="tice-mainn-child">
                    <label htmlFor="Date">Date</label>
                    <div className="inn-child">
                      <input
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        type="date"
                        id="date"
                      />
                    </div>
                  </div>
                  <div className="tice-mainn-child">
                    <div className="inn-child">
                      <label htmlFor="Class">Class</label>
                      <div>
                        <select
                          value={classroomId}
                          onChange={(e) => setClassroomId(e.target.value)}
                          name="class"
                          id="class"
                        >
                          <option value="">Select Class</option>
                          {classrooms?.map((item) => {
                            return (
                              <option value={item?.id}>
                                {item?.classroomName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="new-notice-mainn-child-3">
                {loading && (
                  <Successfulmodal setLoading={setLoading} loading={loading} />
                )}
              </div>
            </div>
            <button
              style={{
                width: "100%",
                padding: ".7rem 1rem",
                border: "none",
                borderRadius: "14px",
                backgroundColor: "#00AFEF",
                color: "white",
              }}
              onClick={addNoticeboard}
            >
              Create
            </button>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default NewNotice;
