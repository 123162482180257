import React from "react";
import "./ParentGpa.css";

function ParentGpa() {
  return (
    <>
      <h1 className="ParentGpa-Mainh1">"Current Student GPA"</h1>
      <div className="ParentGpa-Main">
        <table>
          <thead>
            <tr>
              <th>Course Name</th>
              <th>Grade</th>
              <th>Credits</th>
              <th>Scale</th>
              <th>POINTS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Advanced Calculus</td>
              <td>A</td>
              <td>3</td>
              <td>3</td>
              <td>3</td>
            </tr>
            <tr>
              <td>Advanced Calculus</td>
              <td>A</td>
              <td>3</td>
              <td>3</td>
              <td>3</td>
            </tr>
            <tr>
              <td>Advanced Calculus</td>
              <td>A</td>
              <td>3</td>
              <td>3</td>
              <td>3</td>
            </tr>
            <tr>
              <td>Advanced Calculus</td>
              <td>A</td>
              <td>3</td>
              <td>3</td>
              <td>3</td>
            </tr>
          </tbody>
        </table>
        <span className="CurrentGpa">GPA=4.7</span>
        <button>Download</button>
      </div>
    </>
  );
}

export default ParentGpa;
