import React, { useEffect, useState } from "react";
import "./MHistory.css";
import { IoAddCircle, IoArrowBack } from "react-icons/io5";
import print from "../../../StudentComponents/Medical/flat-color-icons_print.png";
import { FaRegFilePdf } from "react-icons/fa";
import "../../../StudentComponents/MedicalHistory/MediHistory.css";
import { IoEyeOutline } from "react-icons/io5";
import { FaRegFile } from "react-icons/fa";
import { TiCameraOutline } from "react-icons/ti";
import { IoRefreshCircle } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { FaImage } from "react-icons/fa";
import axios from "axios";
import toast from "react-hot-toast";
import { baseURL } from "../../../../constants";
import { FiEdit } from "react-icons/fi";
axios.defaults.baseURL = baseURL;

function MHistory() {
  const [show, setShow] = useState(false);
  function handlePrint() {
    window.print();
  }
  const [pdfFileName, setPdfFileName] = useState("");
  const [imageFileName, setImageFileName] = useState("");
  const [editId, setEditId] = useState("");
  const [image, setImage] = useState(null);
  const [pdf, setPdf] = useState(null);

  const params = useParams();
  const handlePdfUpload = (event) => {
    const file = event.target.files[0];
    setPdfFileName(file.name);
    setPdf(file);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImageFileName(file.name);
    setImage(file);
  };
  const [date, setDate] = useState("");
  const [code, setCode] = useState("");
  const [grade, setGrade] = useState("");
  const [age, setAge] = useState("");
  const [starttime, setStartTime] = useState("");
  const [endtime, setEndTime] = useState("");
  const [commentforTeacher, setCommentforTeacher] = useState("");
  const [commentforParent, setCommentforParent] = useState("");

  console.log(image, pdf);
  const [medicalHistory, setMedicalHistory] = useState(null);
  const [documents, setDocuments] = useState(null);

  const getMedicalHistory = async () => {
    try {
      const { data } = await axios.get(
        `/medicalHistories/getallMedicalHistory`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setMedicalHistory(data);

      const arr = [];

      data.forEach((doc) => {
        if (doc?.file && doc?.filepdf) {
          arr.push(doc?.file, doc?.filepdf);
        } else if (doc?.file) {
          arr.push(doc?.file);
        } else if (doc?.filepdf) {
          arr.push(doc?.filepdf);
        }
      });

      setDocuments(arr);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const newData = new FormData();
      newData.append("date", date);
      newData.append("code", code);
      newData.append("grade", grade);
      newData.append("age", age);
      newData.append("starttime", starttime);
      newData.append("endtime", endtime);
      newData.append("commentforTeacher", commentforTeacher);
      newData.append("commitforParent", commentforParent);
      newData.append("studentId", params.studentId);
      image && newData.append("file", image);
      pdf && newData.append("filepdf", pdf);

      if (editId) {
        const { data } = await axios.put(
          `/medicalHistories/updateMedicalHistory/${editId}`,
          newData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Edited Successfully");
        console.log(data);
      } else {
        const { data } = await axios.post(`/medicalHistories/add`, newData, {
          headers: {
            "Content-Type": "multipart/form-data",
            accessToken: `${localStorage.getItem("token")}`,
          },
        });
        toast.success("Added Successfully");
      }

      setDate("");
      setCode("");
      setGrade("");
      setAge("");
      setStartTime("");
      setEndTime("");
      setCommentforTeacher("");
      setCommentforParent("");
      setImage(null);
      setPdf(null);
      setImageFileName("");
      setPdfFileName("");
      getMedicalHistory();
      setShow(false);
      setEditId("");
    } catch (error) {
      console.log(error, "this is my error");
      if (error?.response?.data?.error) {
        toast.error(error?.response?.data?.error);
      }
    }
  };

  useEffect(() => {
    getMedicalHistory();
  }, []);

  const handleViewFile = (fl) => {
    const anchor = document.createElement("a");

    if (fl?.split(".")?.pop() === "pdf") {
      anchor.href = `${baseURL}/documents/${fl}`;
    } else {
      anchor.href = `${baseURL}/images/${fl}`;
    }

    document.body.appendChild(anchor);

    anchor.target = "_blank";

    anchor.click();

    document.body.removeChild(anchor);
  };

  const pdfDownload = (fl) => {
    const anchor = document.createElement("a");

    if (fl?.split(".")?.pop() === "pdf") {
      anchor.href = `${baseURL}/documents/${fl}`;
    } else {
      anchor.href = `${baseURL}/images/${fl}`;
    }
    anchor.download = fl;

    document.body.appendChild(anchor);

    anchor.target = "_blank";

    anchor.click();

    document.body.removeChild(anchor);
  };
  const deleteHistory = async (id) => {
    try {
      const { data } = await axios.delete(
        `/medicalHistories/removeMedicalHistory/${id}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Successfully deleted history!");
      getMedicalHistory();
      console.log(data);
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else if (error?.response?.data?.error) {
        toast.error(
          error?.response?.data?.error[0]?.detail ||
            error?.response?.data?.error[0]?.message
        );
      } else {
        toast.error(
          error?.response?.data?.message || error?.response?.data?.msg
        );
      }
      console.log(error);
    }
  };

  useEffect(() => {
    if (editId && medicalHistory) {
      setShow(true);
      const currentMedicalHistory = medicalHistory?.find((h) => {
        return h?.id === editId;
      });

      setDate(currentMedicalHistory?.date?.split("T")[0]);
      setCode(currentMedicalHistory?.code);
      setGrade(currentMedicalHistory?.grade);
      setAge(currentMedicalHistory?.age);
      setStartTime(currentMedicalHistory?.starttime);
      setEndTime(currentMedicalHistory?.endtime);
      setCommentforTeacher(currentMedicalHistory?.commentforTeacher);
      setCommentforParent(currentMedicalHistory?.commitforParent);
    }
  }, [editId, medicalHistory]);

  const resetForm = () => {
    setDate("");
    setCode("");
    setGrade("");
    setAge("");
    setStartTime("");
    setEndTime("");
    setCommentforTeacher("");
    setCommentforParent("");
    setImage(null);
    setPdf(null);
    setImageFileName("");
    setPdfFileName("");
    setEditId("");
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`/medicalHistories/removeMedicalHistory/${editId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setDate("");
      setCode("");
      setGrade("");
      setAge("");
      setStartTime("");
      setEndTime("");
      setCommentforTeacher("");
      setCommentforParent("");
      setImage(null);
      setPdf(null);
      setImageFileName("");
      setPdfFileName("");
      setEditId("");
      setShow(false);
      getMedicalHistory();

      toast.success("Successfully deleted history!");
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
      }
    }
  };

  return (
    <div className="MHistory-Main">
      {!show ? (
        <div>
          <div className="MHistory-Top">
            <h4>
              Health Problems: <span>Allergy: Peanut </span>
            </h4>
          </div>
          <div className="MHistory-Second1">
            <h3>Medical History</h3>
            <button onClick={() => setShow(true)}>
              <IoAddCircle />
              Add new Record
            </button>
          </div>
          <div className="MHistory-Table">
            <table className="MHistory-Table-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Code</th>
                  <th>Grade</th>
                  <th>Age</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                </tr>
              </thead>
              <tbody>
                {medicalHistory &&
                  medicalHistory?.map((history) => {
                    return (
                      <tr>
                        <td>{history?.date?.split("T")[0]}</td>
                        <td>{history?.code}</td>
                        <td>{history?.grade}</td>
                        <td>{history?.age}</td>
                        <td>{history?.starttime}</td>
                        <td>{history?.endtime}</td>
                        <td>
                          <FiEdit
                            onClick={() => setEditId(history?.id)}
                            size={19}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="MHistory-Print">
            <button onClick={handlePrint}>
              <img src={print} alt="#" />
              Print Medical Profile
            </button>
          </div>
          <h2>MEDICAL DOCOMENT</h2>
          {documents &&
            documents?.map((fl) => {
              return (
                <div className="MHistory-Pdf">
                  <div className="MHistory-pdfchild">
                    {fl?.split(".")?.pop() === "pdf" ? (
                      <FaRegFilePdf />
                    ) : (
                      <FaImage />
                    )}

                    <p>
                      {fl?.slice(0, 15)}... {fl?.split(".")?.pop()}
                    </p>
                  </div>
                  <div className="MediHistory-btns">
                    <button onClick={() => deleteHistory(fl?.id)}>
                      Delete
                    </button>
                    <button onClick={() => pdfDownload(fl)}>Download</button>
                    <button onClick={() => handleViewFile(fl)}>
                      <IoEyeOutline />
                      view
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <div>
          <div className="MHistory-Top">
            <h4>
              Health Problems: <span>Allergy: Peanut </span>
            </h4>
          </div>
          <div className="MHistory-Second">
            <IoArrowBack
              onClick={() => {
                setShow(false);
                setEditId("");
              }}
            />
            <h3>Medical History</h3>
          </div>
          <div className="MHistory-Table1">
            <div className="mytablem">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Code</th>
                    <th>Grade</th>
                    <th>Age</th>
                    <th>Start</th>
                    <th>End</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="date"
                        name="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </td>
                    <td>
                      <select
                        name="code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                      >
                        <option disabled></option>
                        <option value="Asthma">Asthma</option>
                        <option value="Abrasion">Abrasion</option>
                        <option value="Back/ Neck Injury">
                          Back/ Neck Injury
                        </option>
                        <option value="Chicken Pox">Chicken Pox</option>
                      </select>
                    </td>
                    <td>
                      <input
                        type="text"
                        value={grade}
                        name="grade"
                        onChange={(e) => setGrade(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="age"
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="time"
                        name="starttime"
                        value={starttime}
                        onChange={(e) => setStartTime(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="time"
                        name="endtime"
                        value={endtime}
                        onChange={(e) => setEndTime(e.target.value)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="ADDpdfImage">
              <div className="ADDpdfChild1">
                <label htmlFor="pdf-upload" className="ADDpdfChildBack">
                  <input
                    type="file"
                    id="pdf-upload"
                    accept=".pdf"
                    onChange={handlePdfUpload}
                    style={{ display: "none" }}
                  />
                  <span>
                    <FaRegFile />
                  </span>
                </label>
                <h3>Upload Pdf Doc</h3>
              </div>
              <div className="ADDpdfChild2">
                <label htmlFor="image-upload" className="ADDimageChildBack">
                  <input
                    type="file"
                    id="image-upload"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                  />
                  <span>
                    <TiCameraOutline />
                  </span>
                </label>
                <p>Upload</p>
              </div>
              <div className="ADDimagePdfName">
                <p>Uploaded PDF: {pdfFileName}</p>
                <p>Uploaded Image: {imageFileName}</p>
              </div>
            </div>
            <div className="ADDimageTextArea">
              <textarea
                placeholder="For Teacher Comment"
                name="commentforTeacher"
                value={commentforTeacher}
                onChange={(e) => setCommentforTeacher(e.target.value)}
              />
              <textarea
                placeholder="For Parent Comment"
                name="commentforParent"
                value={commentforParent}
                onChange={(e) => setCommentforParent(e.target.value)}
              />
            </div>
            <div className="MedicalTWoButton">
              <div className="MedicalTWoChild">
                {editId && <button onClick={handleDelete}>Delete</button>}
                <button onClick={resetForm}>
                  <IoRefreshCircle />
                  Reset
                </button>
                <button onClick={fetchData}>Save new Record</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MHistory;
