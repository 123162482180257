import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { IoMdHelpCircle, IoMdSettings } from "react-icons/io";
import { BiLogOut } from "react-icons/bi";
import "./ParentPortalLogoutModal.css";
import { BsPersonFill } from "react-icons/bs";
import Dummy from "../../AdminComponents/images/dummy-user.png";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserProfile } from "../../../redux/actions/userAction";
import { baseURL } from "../../../constants";

const ParentPortalLogoutModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("refreshToken");
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("token");

    setShow(false);
    dispatch(getUserProfile());
    navigate("/");
    window.location.reload();
  };

  const { user } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  return (
    <>
      <div className="LogoutModal">
        <div className="mainModal">
          <div className="Logo" onClick={handleShow}>
            <img
              src={`${baseURL}/images/${user?.dataValues?.profilePicture}`}
              onError={({ currentTarget }) => {
                currentTarget.src = Dummy;
              }}
              alt="#"
            />
          </div>
          <div className="modalContainer">
            <Modal
              show={show}
              onHide={handleClose}
              animation={false}
              className="custom-modal"
            >
              <div className="devSqur"></div>
              <Modal.Header closeButton>
                <Link to="" className="MyProfile_link TeacherMyProfile_link">
                  <Modal.Title className="title TeacherTitle">
                    <BsPersonFill /> My Profile
                  </Modal.Title>
                </Link>
              </Modal.Header>
              <Modal.Body className="detlas">
                <ul>
                  <Link className="AccountSettig TeacherAccountSettig">
                    <IoMdSettings />
                    Profile View
                  </Link>

                  <Link to="#" className="AccountSettig TeacherAccountSettig">
                    <IoMdHelpCircle />
                    Help & Support
                  </Link>

                  <li onClick={handleLogout}>
                    <BiLogOut /> Log Out
                  </li>
                </ul>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParentPortalLogoutModal;
