import React, { useEffect, useState } from "react";
import "./EditStaffInfo.css";
import toast from "react-hot-toast";
import { Autocomplete, TextField } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import languageList from "language-list";
import axios from "axios";
import countryList from "country-list";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { baseURL } from "../../../../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

function EditStaffInfo({
  fg,
  staffData,
  setShowEdit,
  getStaffSchool,
  setFl,

  setEditPhoto,
}) {
  const languages = languageList();

  const [allLanguages, setAllLanguages] = useState(null);
  const navigate = useNavigate();
  function handleClick() {
    navigate(`/StaffProfile/${params.staffId}?edit=false`);
  }
  useEffect(() => {
    const allLanguages = languages.getData().map((item) => {
      return item.language;
    });
    setAllLanguages(allLanguages);
  }, []);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [staffName, setStaffName] = useState("");
  const [staffInformation, setStaffInformation] = useState("");

  const [staffId, setStaffId] = useState("");
  const [stateId, setStateId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [govIssuedNumber, setGovIssuedNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [countryOfBirth, setCountryOfBirth] = useState("");
  const [nationality, setNationality] = useState("");
  const [nativeLanguage, setNativeLanguage] = useState("");
  const [languagesSpoken, setLanguagesSpoken] = useState("");
  const [handleFirstSwitch, setHandleFirstSwitch] = useState(false);
  const [handleSecondSwitch, setHandleSecondSwitch] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [loginEmailAddress, setLoginEmailAddress] = useState("");
  const [SSOLOGIN, setSSOLogin] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  console.log(staffData?.languagesSpoken, "Nice yaar")

  useEffect(() => {
    if (staffData) {
      setFirstName(staffData?.firstName);
      setMiddleName(staffData?.middleName);
      setLastName(staffData?.lastName);
      setStaffInformation(staffData?.staffInformation);
      setStaffId(staffData?.staffId);
      setStateId(staffData?.stateId);
      setDistrictId(staffData?.districtId);
      setGovIssuedNumber(staffData?.govIssuedNumber);
      setDateOfBirth(
        new Date(staffData?.dateOfBirth).toISOString().split("T")[0]
      );
      setGender(staffData?.gender);
      setCountryOfBirth(staffData?.countryOfBirth);
      setNationality(staffData?.nationality);
      setNativeLanguage(staffData?.nativeLanguage);
      setLanguagesSpoken(
        Array.isArray(staffData?.languagesSpoken)
          ? staffData?.languagesSpoken?.join("")
          : ""
      );
      setLoginEmailAddress(staffData?.loginEmailAddress);
    }
  }, [staffData]);

  const generateRandomPassword = () => {
    const randomPassword = Math.random().toString(36).slice(-8);
    setPassword(randomPassword);
  };
  const params = useParams();
  const [isCheck, setIsCheck] = useState(false);
  const [isCheck2, setIsCheck2] = useState(false);

  const { schoolId } = useSelector((state) => state.schoolId);

  const updateGeneralInfoHandler = async () => {
    try {
      const newForm = new FormData();
      fg && newForm.append("profilePicture", fg);
      newForm.append("firstName", firstName);
      newForm.append("middleName", middleName);
      newForm.append("lastName", lastName);
      newForm.append("staffName", ` ${firstName} ${middleName} ${lastName}`);
      newForm.append("stateId", stateId);
      newForm.append("districtId", districtId);
      newForm.append("govIssuedNumber", govIssuedNumber);
      newForm.append("dateOfBirth", dateOfBirth);
      newForm.append("gender", gender);
      newForm.append("schoolId", Number(schoolId));
      newForm.append("countryOfBirth", countryOfBirth);
      newForm.append("nationality", nationality);
      newForm.append("nativeLanguage", nativeLanguage);

      [languagesSpoken].forEach((element) => {
        newForm.append("languagesSpoken[]", element);
      });
      newForm.append("loginEmailAddress", loginEmailAddress);
      newForm.append("disabled", isCheck);
      password && newForm.append("password", password);
      newForm.append("staffId", staffId);
      // newForm.append("SSOLOGIN", SSOLOGIN);
      await axios.put(`/teachers/updateTeacher/${params?.staffId}`, newForm, {
        headers: {
          "Content-Type": "multipart/form-data",
          accessToken: `${localStorage.getItem("token")}`,
        },
      });

      getStaffSchool();
      setShowEdit(false);
      toast.success("Profile Updated Successfully");
      // setFl(fg);
      // setEditPhoto(false);
      // navigate(`/StaffProfile/${params?.staffId}?edit=false`);
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      }
    }
  };

  return (
    <div className="EditStaffInfo-main">
      <div className="ADDEditStudent">
        <h3>Identification Information</h3>

        <div className="EditStaffInfo-inputs">
          <div>
            <label>First/Given Name</label> <br />
            <input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="-"
            />
          </div>
          <div>
            <label>Middle Name</label> <br />
            <input
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
              placeholder="-"
            />
          </div>
          <div>
            <label>Last/Family Name</label> <br />
            <input
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="-"
            />
          </div>
        </div>
      </div>

      <div className="EditStaffInfo-inputschild-1">
        <div>
          <label>Staff ID</label> <br />
          <input
            value={staffId}
            onChange={(e) => setStaffId(e.target.value)}
            placeholder="-"
          />
        </div>
      </div>
      <div className="EditStaffInfo-inputschild-1">
        <div>
          <label>State ID</label> <br />
          <input
            value={stateId}
            onChange={(e) => setStateId(e.target.value)}
            placeholder="-"
          />
        </div>
        <div>
          <label>District ID</label> <br />
          <input
            value={districtId}
            onChange={(e) => setDistrictId(e.target.value)}
            placeholder="-"
          />
        </div>
      </div>
      <div className="EditStaffInfo-inputschild-1">
        <div>
          <label>Out Govt Issued Number</label> <br />
          <input
            value={govIssuedNumber}
            onChange={(e) => setGovIssuedNumber(e.target.value)}
            placeholder="-"
          />
        </div>
      </div>

      <div className="EditStaffInfo-child-2">
        <h3>Demographic Information</h3>
        <div className="EditStaffInfo-inp">
          <div>
            <label>Date of birth</label> <br />
            <input
              type="date"
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
              placeholder="-"
            />
          </div>
          <div>
            <label>Gender</label> <br />
            <select value={gender} onChange={(e) => setGender(e.target.value)}>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="Non-Binary">Non-Binary</option>
            </select>
          </div>
          <div>
            <label>Country of birth</label> <br />
            <Autocomplete
              disablePortal
              value={countryOfBirth}
              onChange={(event, newValue) => {
                setCountryOfBirth(newValue);
              }}
              id="combo-box-demo"
              options={countryList.getNames()}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        </div>
        <div className="EditStaffInfo-inp">
          <div>
            <label>Nationality</label> <br />
            <Autocomplete
              disablePortal
              value={nationality}
              onChange={(event, newValue) => {
                setNationality(newValue);
              }}
              id="combo-box-demo"
              options={countryList.getNames()}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
          <div>
            <label>Native Language</label> <br />
            {allLanguages && (
              <Autocomplete
                disablePortal
                value={nativeLanguage}
                onChange={(event, newValue) => {
                  setNativeLanguage(newValue);
                }}
                id="combo-box-demo"
                options={allLanguages}
                renderInput={(params) => <TextField {...params} />}
              />
            )}
          </div>
          <div>
            <label>Languages Spoken</label> <br />
            {allLanguages && (
              <Autocomplete
                disablePortal
                value={languagesSpoken}
                onChange={(event, newValue) => {
                  setLanguagesSpoken(newValue);
                }}
                id="combo-box-demo"
                options={allLanguages}
                renderInput={(params) => <TextField {...params} />}
              />
            )}
          </div>
        </div>
      </div>

      <div
        className="ADDEditSchoolInfo-Switch"
        style={{ display: "flex", gap: "20px", alignItems: "center" }}
      >
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id=""
            checked={handleFirstSwitch}
            onChange={() => {
              setIsCheck2(!isCheck2);
              setHandleFirstSwitch(!handleFirstSwitch);
            }}
          />
          <label className="form-check-label">Portal Access</label>
        </div>
      </div>
      {handleFirstSwitch ? (
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id=""
            checked={SSOLOGIN}
            style={{ marginLeft: "-14px" }}
            onChange={() => setSSOLogin(!SSOLOGIN)}
          />
          <label
            style={{ color: "black", fontSize: "14px" }}
            className="form-check-label"
          >
            Allow SSO Login
          </label>
        </div>
      ) : null}
      {handleFirstSwitch && (
        <div className="ADDSwitchShowInput">
          <div className="ADDSwitchShowInput-child-1">
            <label>Access Information</label> <br />
            <input
              onChange={(e) => setLoginEmailAddress(e.target.value)}
              value={loginEmailAddress}
              placeholder="Login Email Address*"
            />
          </div>
          <div className="ADDSwitchShowInput-child-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                checked={handleSecondSwitch}
                onChange={() => setHandleSecondSwitch(!handleSecondSwitch)}
                id="flexCheckDefault"
              />
              <label
                class="form-check-label"
                for="flexCheckDefault"
                style={{ color: "#000" }}
              >
                Set or Reset Password
              </label>
            </div>
          </div>
        </div>
      )}
      {handleSecondSwitch ? (
        <div className="ADDEditShowHide">
          <h4>Password</h4>
          <h5>Student email to set or reset password</h5>
          <div class="form-check">
            <input
              class="form-check-input "
              type="radio"
              name="exampleRadios"
              id="exampleRadios1"
              value="option1"
            />
            <label class="form-check-label labelss" for="exampleRadios1">
              Student will be able to create password on their own by clicking
              the link on their email
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios1"
              value="option1"
            />
            <label class="form-check-label" for="exampleRadios1">
              Create Password
            </label>
          </div>
          <div className="ADDShowCloseDiv-main">
            <div className="ADDShowCloseDiv">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
              />
              <span className="eye-icon" onClick={handleTogglePassword}>
                {showPassword ? "👁️" : "👁️‍🗨️"}
              </span>
            </div>
            <p onClick={generateRandomPassword}>
              <RefreshIcon />
              Auto Generate Password
            </p>
          </div>
        </div>
      ) : null}
      <div className="ADDEditFooter">
        {/* <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            checked={isCheck}
            onChange={() => setIsCheck(!isCheck)}
            id="flexCheckDisabled"
          />
          <label class="form-check-label" for="flexCheckDisabled">
            Disabled checkbox
          </label>
        </div> */}
        <button
          className="ADDEditFooter-btn1"
          onClick={() => {
            setShowEdit(false);
            navigate(`/StaffProfile/${params.staffId}?edit=false`);
          }}
        >
          Cancel
        </button>
        <button
          className="ADDEditFooter-btn2"
          onClick={updateGeneralInfoHandler}
        >
          Update
        </button>
      </div>
    </div>
  );
}

export default EditStaffInfo;
