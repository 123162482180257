import React, { useEffect, useState } from "react";
import ParentStuInfo from "../ParentStudentInfo/ParentStuInfo";
import CustomerDetailsFiltr from "../Customerdetailsfilters/Customerdetailsfilter";
import TopADDCards from "../ADDCards/TopADDCards";
import ParentAttendenceFilter from "../ParentAttendenceFltr/ParentAttendenceFilter";
import axios from "axios";
import { baseURL } from "../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

function AttendencePage() {
  const { studentId } = useSelector((state) => state.studentId);

  const [assignments, setAssignments] = useState(null);

  const getAssignments = async () => {
    try {
      const { data } = await axios.get(
        `/attendances/getStudentAttendanceForToday/${studentId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setAssignments(data?.attendanceToday);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAssignments();
  }, [studentId]);
  return (
    <div className="ParentAppsAccess ">
      <ParentStuInfo />
      <CustomerDetailsFiltr />
      <TopADDCards />
      <div style={{ padding: "20px" }}>
        {assignments && <ParentAttendenceFilter assignments={assignments} />}
      </div>
    </div>
  );
}

export default AttendencePage;
