import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { CgLoadbarDoc } from "react-icons/cg";
import "./Sidebar.css";
import GridView from "@mui/icons-material/GridView";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import Logo from "../images/Logo.png";
import { IoCaretForwardOutline, IoCaretDownOutline } from "react-icons/io5";
import { getUserProfile } from "../../../redux/actions/userAction";
import Dummy from "../../AdminComponents/images/dummy-user.png";
import { useDispatch, useSelector } from "react-redux";
import Cartoon from "../images/man.png";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import axios from "axios";
import { baseURL } from "../../../constants";
import { PiStarFour } from "react-icons/pi";
import cartoon2 from "../images/Compatible 2.png";
import rest from "../images/rest.png";
import work from "../images/work.png";
import setting from "../images/Setting.png";
import gradepic from "../images/25322 1.png";
import screen from "../images/screen.png";
import baby from "../images/3d-render-little-boy-reading-book-isolated-white-background 1.png";
import nana from "../images/nana.png";

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const { user } = useSelector((state) => state.getUserProfile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = (name) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const generateMenuItem = (
    path,
    name,
    icon,
    hasDropdown = false,
    submenuItems = []
  ) => {
    const item = {
      path: path,
      name: name,
      icon: icon,
    };

    if (hasDropdown) {
      item.arrow = dropdownOpen[name] ? (
        <IoCaretDownOutline />
      ) : (
        <IoCaretForwardOutline />
      );
      item.subMenu = submenuItems;
      item.onClick = () => toggleDropdown(name);
    }

    return item;
  };

  const menuItem = [
    generateMenuItem("/Home", "Home", <GridView />),
    generateMenuItem("/ClassRoomPage", "Classes", <MenuBookOutlinedIcon />),
    generateMenuItem("/ReportCard", "My Grades", <CgLoadbarDoc />, true, [
      { path: "/ReportCard", name: "Report Card" },
      { path: "/Grades", name: "Grades" },
      { path: "/GradesHistory", name: "Grades History" },
      { path: "/GraduationStatus", name: "Graduation Status" },
      { path: "/TranscriptStudent", name: "Transcripts" },
    ]),
    generateMenuItem("/Calender", "Calendar", <CalendarMonthIcon />),
    generateMenuItem("/MyAppAccess", "My App Access", <CgLoadbarDoc />),
    generateMenuItem("/CalculatorPage", "GPA Calculator", <CgLoadbarDoc />),
    generateMenuItem("/Myprofile", "Student Info", <CgLoadbarDoc />, true, [
      { path: "/Myprofile", name: "Student Info" },
      { path: "/Guidance", name: "Discipline" },
    ]),
    generateMenuItem("/Medicallog", "Medical", <CgLoadbarDoc />, true, [
      { path: "/Medicallog", name: "Medical log" },
      { path: "/MedicalHistory", name: "Medical History" },
    ]),
    generateMenuItem(
      "/ExtraActivities",
      "Extracurricular activities ",
      <PiStarFour />
    ),
    generateMenuItem(
      "/StudentNoticeBoard",
      "School Noticeboard ",
      <CgLoadbarDoc />
    ),
  ];

  const activeMenuItem = menuItem.find(
    (item) => window.location.pathname === item.path
  );
  const sidebarImage =
    activeMenuItem && activeMenuItem.imgSrc ? activeMenuItem.imgSrc : Cartoon;

  const [schoolId, setSchoolId] = useState(null);

  const getSchoolByStudentId = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/ParentBySchoolId/getschoolBystudentId/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setSchoolId(data.school.id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (user) {
      getSchoolByStudentId();
    }
  }, [user]);
  // eslint-disable-next-line
  const [subjectsList, setSubjectsList] = useState(null);

  const getSubjectsList = async () => {
    try {
      if (schoolId) {
        const { data } = await axios.get(`/subjects/getSubjects/${schoolId}`, {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        });
        const newArr = data?.subjects.map((arr) => {
          return {
            path: `/Attendence?subject_id=${arr?.id}`,
            name: arr?.subjectName,
          };
        });
        setSubjectsList(newArr);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (schoolId) {
      getSubjectsList();
    }
  }, [schoolId]);

  const [profile, setProfile] = useState({});

  const getStudenProfile = async () => {
    try {
      if (user) {
        let response = await axios.get(
          `/students/profile/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setProfile(response.data.student);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStudenProfile();
  }, [user]);

  const [key, setKey] = useState("");

  useEffect(() => {
    setKey(window.location.search?.split("=")[1]);
  }, [window.location.search]);

  const [loc, setLoc] = useState(window.location.pathname);
  useEffect(() => {
    setLoc(window.location.pathname);
  }, [window.location.pathname]);
  return (
    <>
      <div className="dashboardewrapper">
        <div className={`sidebar ${isOpen ? "active" : ""}`}>
          <div className="top_section">
            <div className="Logo">
              <img src={Logo} alt="#" className="Main-Logo-Dashboard" />
              <h4>MYCENTRALITY</h4>
            </div>
            <hr />
            <div className="IdImg">
              <div className="studentprofineoutdiv">
                <img
                  style={{ borderRadius: "100%", objectFit: "contain" }}
                  src={`${baseURL}/images/${profile?.profilePicture}`}
                  onError={({ currentTarget }) => {
                    currentTarget.src = Dummy;
                  }}
                  alt="#"
                />
              </div>
              <h4>
                {profile?.firstName} {profile?.middleName} {profile?.lastName}
              </h4>
            </div>
            {/* <button className="IdImgBtnStu">
              <img src={Wifi} alt="#" />
              My Points: {profile?.cumulativeGPA}
            </button> */}
          </div>
          <div className="BIOGraphy">
            <p>{profile?.biography}</p>
          </div>
          <div className="menubar">
            <div
              style={{ display: "flex", flexDirection: "column" }}
              className="Fullwidth"
            >
              {menuItem.map((item, index) => (
                <div key={index} className="Full-Width">
                  <NavLink
                    to={item.path}
                    className="Sidebar-link"
                    activeClassName="active"
                    onClick={item.onClick}
                  >
                    <div className="icon">{item.icon}</div>
                    <div className="link_text ms-2  d-sm-inline">
                      {item.name}
                      {item.arrow}
                    </div>
                  </NavLink>
                  {item.subMenu &&
                    dropdownOpen[item.name] &&
                    item.subMenu.map((subItem, subIndex) => (
                      <NavLink
                        to={subItem.path}
                        key={subIndex}
                        className="Sidebar-sublink"
                      >
                        <div className="link_text ms-3  d-sm-inline done">
                          <li>
                            <Link to={subItem.path}>{subItem.name}</Link>
                          </li>
                        </div>
                      </NavLink>
                    ))}
                </div>
              ))}
            </div>
          </div>

          {loc === "/Home" && (
            <img
              src={sidebarImage}
              alt="Sidebar Illustration"
              className="CartoonImg"
            />
          )}
          {loc === "/ClassRoomPage" && (
            <img
              src={cartoon2}
              alt="Sidebar Illustration"
              className="CartoonImg"
            />
          )}

          {loc === "/Calender" && (
            <img src={rest} alt="Sidebar Illustration" className="CartoonImg" />
          )}
          {loc === "/MyAppAccess" && (
            <img src={work} alt="Sidebar Illustration" className="CartoonImg" />
          )}
          {loc === "/Setting" && (
            <img
              src={setting}
              alt="Sidebar Illustration"
              className="CartoonImg"
            />
          )}

          {key === "summary" && (
            <img
              src={screen}
              alt="Sidebar Illustration"
              className="CartoonImg"
            />
          )}
          {key === "grade" && (
            <img
              src={gradepic}
              alt="Sidebar Illustration"
              className="CartoonImg"
            />
          )}
          {key === "noticeboard" && (
            <img src={baby} alt="Sidebar Illustration" className="CartoonImg" />
          )}
          {key === "classlecture" && (
            <img src={nana} alt="Sidebar Illustration" className="CartoonImg" />
          )}
        </div>

        <div
          className={`navbar-toggle ${isOpen ? "open" : ""}`}
          onClick={toggleNavbar}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <main>{children}</main>
      </div>
    </>
  );
};

export default Sidebar;
