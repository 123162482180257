import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../ADDnotification/Notification.css";
import NotificationData from "../ADDnotificationCard/NotificationData";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import axios from "axios";
import { baseURL } from "../../../constants";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;

function OffCanvasExample({ name, ...props }) {
  const [show, setShow] = useState(false);
  const [notificationData, setNotificationData] = useState(NotificationData);

  const [allNotifications, setAllNotifications] = useState(null);

  const dispatch = useDispatch();
  const { user, loading, error } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const getAllNotifications = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/notifications/getAllNotifications/${user.dataValues.id}/admin`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setAllNotifications(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllNotifications();
  }, [user]);

  const deleteNotification = async (id) => {
    try {
      const { data } = await axios.delete(
        `/notifications/removeNotification/${id}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      getAllNotifications();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const deleteAllNotifications = async (id) => {
    try {
      if (user) {
        const { data } = await axios.delete(
          `/notifications/removeAllNotification/admin/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        getAllNotifications();
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  function notcard(val) {
    return (
      <div className="notification-main">
        <div className="IMG">
          <img
            style={{ objectFit: "cover" }}
            src={`${baseURL}/images/${val.image}`}
            alt="#"
          />
        </div>

        <div className="notification-child">
          <div class="notification-new">
            <h6>{val.name}</h6>
            <p>{val.type}</p>
          </div>
          <div class="notifaction-para">
            <p>{val.time}</p>
            <div style={{ display: "flex" }} class="notification-dot">
              <p>{val.dotIcons}</p>
              <p onClick={() => deleteNotification(val.id)}>D</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const handleClearAll = () => {
    deleteAllNotifications();
  };

  const handleShowUnread = () => {
    const unreadNotifications = NotificationData.filter(
      (notification) => notification.type === "unread"
    );
    setNotificationData(unreadNotifications);
  };

  const handleShowAllNotifications = () => {
    setNotificationData(NotificationData);
  };

  return (
    <>
      <div className="row notiFication">
        <div onClick={handleShow}>
          <NotificationsNoneOutlinedIcon />
        </div>
        <Offcanvas show={show} onHide={handleClose} {...props}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Notification</Offcanvas.Title>
          </Offcanvas.Header>
          <hr className="Noti-hr" />
          <Offcanvas.Body>
            <div className="notifications-btn">
              <button
                className="all-notification-btn"
                onClick={handleShowAllNotifications}
              >
                All Notification
              </button>
              <button className="unread-btn" onClick={handleShowUnread}>
                Unread
              </button>
            </div>
            <h1 className="notification-heading">TODAY</h1>
            <div className="notification-map">
              {allNotifications && allNotifications.notifications.map(notcard)}
            </div>
            <div className="notification-yesterday">
              <h6>yesterday</h6>
            </div>
            <div className="notification-map2">
              {allNotifications && allNotifications.notifications.map(notcard)}
            </div>
            <div className="notification-clean-btn">
              <button onClick={handleClearAll}>Clear all</button>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
}

function Example() {
  return (
    <>
      {["end"].map((placement, idx) => (
        <OffCanvasExample key={idx} placement={placement} name={placement} />
      ))}
    </>
  );
}

export default Example;
