import React, { useState, useEffect } from "react";
import "./Classroom.css";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { cardsData } from "../data/MyscheduleData";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../../src/constants";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;

const Classroom = ({ showAll = true }) => {
  const [classRooms, setClassrooms] = useState(null);

  const dispatch = useDispatch();

  const { user, loading, error } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);


  const getClassrooms = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/classrooms/getAllClassroomsByStudent/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        setClassrooms(data?.classrooms);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getClassrooms();
  }, [user]);
  const navigate = useNavigate();

  const handleViewClass = (id) => {
    navigate(`/NestedTabs/${id}`);
  };
  return (
    <div className="classroom-main">
      <div className="Classroom-child-1">
        <div>
          My <span>Classroom</span>
        </div>
        <p>View All</p>
      </div>
      <div className="Classroom-child-2-map">
        {classRooms?.map((card, index) => {
          const newIndex = index % cardsData.length;

          return !showAll && index < 6 ? (
            <div className="Classroom-child-2" key={`${card.id}${index}`}>
              <div
                className="Classroom-child-2-main"
                style={{ background: cardsData[newIndex]?.backgroundColor }}
              >
                <span
                  className="three-dot-icon"
                  style={{ color: cardsData[newIndex]?.iconColor }}
                >
                  <HiOutlineDotsVertical />
                </span>
                <div
                  className="classroom-child-2-img"
                  style={{
                    backgroundColor: cardsData[newIndex]?.imgBackgroundColor,
                  }}
                >

                  <img
                    src={`${baseURL}/images/${card.classroomBanner}`}
                    alt=""
                  />
                </div>
                <p
                  className="Classroom-child-2-main-P"
                  style={{ color: cardsData[newIndex]?.iconColor }}
                >
                  {card?.classroomName}
                </p>
                <button
                  onClick={() => {
                    localStorage.removeItem("classroomId");
                    localStorage.setItem("classroomId", card.id);
                    handleViewClass(card.subjectId);
                  }}
                  style={{
                    backgroundColor: cardsData[newIndex]?.buttonColor,
                  }}
                >
                  view Class
                </button>
              </div>
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};

export default Classroom;
