import React, { useState } from "react";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../../constants";
import "../../CommentModal/CommentModal.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import toast from "react-hot-toast";
axios.defaults.baseURL = baseURL;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const TeacherClassRoom = ({
  data = [],
  showAll = true,
  getAllClassrooms = () => {},
}) => {

  const [deleteId, setDeleteId] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setDeleteId("");
  };

  const handleDeleteClass = async () => {
    try {
      await axios.delete(`/classrooms/removeClassroom/${deleteId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      toast.success("Classroom Deleted");

      getAllClassrooms();
      handleClose();
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else if (error?.response?.data?.error) {
        toast.error(
          error?.response?.data?.error[0]?.message ||
            error?.response?.data?.error[0]?.detail
        );
      } else {
        toast.error("Something went wrong!");
      }
    }
  };

  const navigate = useNavigate();

  return (
    <div className="classroom-mainaa">
      <div className="Classroom-child-1 Teacher-Classroom-child-1">
        <div>
          My <span>Classroom</span>
        </div>
        <p>View All</p>
      </div>
      <div className="CommentModal-Mmain">
        <Modal
          className="Allowmodalmain"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >

          <Fade in={open}>
            <Box sx={style}>
              <div className="CommentModal-Mmain-child1">
                <h4>Delete Classroom</h4>
                <p>
                  Are you sure you want to delete this Classroom? This will
                  remove the Classroom and can’t be undone.
                </p>
                <div className="CommentModal-Mmain-child2">
                  <button
                    className="CommentModal-Mmain-child2-Dont-allow"
                    onClick={handleClose}
                  >
                    NO, CANCEL
                  </button>
                  <button
                    onClick={handleDeleteClass}
                    className="CommentModal-Mmain-child2-allow"
                  >
                    YES, DELETE
                  </button>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>

      <div className="Classroom-child-2-mapa">
        {data?.map((classroom, index) =>
          index < 3 && !showAll ? (
            <div
              className="Classroom-child-2 teacher-Classroom-child-2"
              key={classroom?.id}
            >
              <div
                className="Classroom-child-2-main Teacher-Classroom-child-2-main"
              >
                <span
                  className="three-dot-icon"
                  style={{ color: index % 2 === 0 ? "#B1C1DF" : "#658BEB" }}
                >
                  <HiOutlineDotsVertical
                    onClick={() =>
                    {
                      handleOpen();
                      setDeleteId(classroom?.id);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </span>

                <div className="classroom-child-2-img teacher-Classroom-child-2-img">
                  <img
                    style={{
                      width: "100%",
                      height: "160px",
                      objectFit: "cover",
                    }}
                    src={`${baseURL}/images/${classroom?.classroomBanner}`}
                    alt="#"
                  />
                </div>
                <p>{classroom?.classroomName}</p>
                <div
                  className="Linkeded"
                  onClick={() => {
                    localStorage.removeItem("classroomId");
                    localStorage.setItem("classroomId", classroom?.id);
                    localStorage.removeItem("subjectId");
                    localStorage.setItem("subjectId", classroom?.subjectId);
                    navigate(`/TeacherNestedTabs/${classroom?.subjectId}`);
                  }}
                >
                  <button>View Class</button>
                </div>
              </div>
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export default TeacherClassRoom;

