import React, { useEffect, useState } from "react";
import "./MediHistory.css";
import print from "../Medical/flat-color-icons_print.png";
import { IoEyeOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../../constants";
import { FaImage, FaRegFilePdf } from "react-icons/fa";
axios.defaults.baseURL = baseURL;

function MediHistory() {
  const [medicalHistory, setMedicalHistory] = useState(null);
  const [documents, setDocuments] = useState(null);

  const { user } = useSelector((state) => state.getUserProfile);
  const getMedicalHistory = async () => {
    try {
      const { data } = await axios.get(
        `/medicalHistories/getMedicalHistoryBystudentId/${user?.dataValues?.id}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setMedicalHistory(data?.medicalHistory);

      const arr = [];

      data?.medicalHistory?.forEach((doc) => {
        if (doc?.file && doc?.filepdf) {
          arr.push(doc?.file, doc?.filepdf);
        } else if (doc?.file) {
          arr.push(doc?.file);
        } else if (doc?.filepdf) {
          arr.push(doc?.filepdf);
        }
      });
      setDocuments(arr);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMedicalHistory();
  }, [user]);

  const handleViewFile = (fl) => {
    const anchor = document.createElement("a");

    if (fl?.split(".")?.pop() === "pdf") {
      anchor.href = `${baseURL}/documents/${fl}`;
    } else {
      anchor.href = `${baseURL}/images/${fl}`;
    }

    document.body.appendChild(anchor);

    anchor.target = "_blank";

    anchor.click();

    document.body.removeChild(anchor);
  };

  function handlePrint() {
    window.print();
  }
  return (
    <div className="MediHistory-main">
      <div className="MediHistory-Second">
        <h4>Medical History</h4>
      </div>
      <div className="MefiHistoryTABLE">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Code</th>
              <th>Grade</th>
              <th>Age</th>
              <th>Start Date</th>
              <th>End Time</th>
            </tr>
          </thead>
          <tbody>
            {medicalHistory &&
              medicalHistory?.map((history) => {
                return (
                  <tr>
                    <td>{history?.date?.split("T")[0]}</td>
                    <td>{history?.code}</td>
                    <td>{history?.grade}</td>

                    <td>{history?.age}</td>
                    <td>{history?.starttime}</td>
                    <td>{history?.endtime}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="PrintImg">
        <button onClick={handlePrint}>
          <img src={print} alt="#" />
          Print Medical Profile
        </button>
      </div>
      <h2>MEDICAL DOCUMENT</h2>
      {documents &&
        documents?.map((fl) => {
          return (
            <div className="MHistory-Pdf">
              <div className="MHistory-pdfchild">
                {fl?.split(".")?.pop() === "pdf" ? (
                  <FaRegFilePdf />
                ) : (
                  <FaImage />
                )}

                <p>
                  {fl?.slice(0, 15)}... {fl?.split(".")?.pop()}
                </p>
              </div>
              <div className="MediHistory-btns">
                <button>Download</button>
                <button onClick={() => handleViewFile(fl)}>
                  <IoEyeOutline />
                  view
                </button>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default MediHistory;
