import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.css";
import { NavLink } from "react-router-dom";
import GridView from "@mui/icons-material/GridView";
import { BiSolidSchool } from "react-icons/bi";
import { LuBarChartBig } from "react-icons/lu";
import { LuBarChart4 } from "react-icons/lu";
import WebhookIcon from "@mui/icons-material/Webhook";
import { BsTools } from "react-icons/bs";
import Logo from "../images/Group.png";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShowChartOutlinedIcon from "@mui/icons-material/ShowChartOutlined";
import { GiHouseKeys } from "react-icons/gi";

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const menuItem = [
    {
      path: "/AdminstratorDashbord",
      name: "Dashboard",
      icon: <GridView />,
    },
    {
      path: "/SchoolList",
      name: "School",
      icon: <BiSolidSchool />,
    },
    {
      path: "/Addefficiency",
      name: "Efficiency Score",
      icon: <LuBarChartBig />,
    },
    {
      path: "/ADDdatausagepages",
      name: "Data Housing",
      icon: <WebhookIcon />,
    },
    // {
    //   path: "/Adiminstratorpicha",
    //   name: "Data Filtering",
    //   icon: <FilterListOutlinedIcon />,
    // },
    {
      path: "/FullCircleChart",
      name: "Centrality Score",
      icon: <LuBarChart4 />,
    },
    {
      path: "/VendorsOverview",
      name: "Vendor Comparison Tool",
      icon: <BsTools />,
    },
    {
      path: "/ADDFinancal",
      name: "Financial Oversight",
      icon: <AccountBalanceWalletOutlinedIcon />,
    },
    {
      path: "/Academicplan",
      name: "Academic Planning",
      icon: <ShowChartOutlinedIcon />,
    },
  ];

  const activeMenuItem = menuItem.find(
    (item) => location.pathname === item.path
  );

  return (
    <>
      <div className="dashboardewrapper">
        <div className={`sidebar ${isOpen ? "active" : ""}`}>
          <div className="Logos">
            <img src={Logo} alt="#" />
            <h4>MYCENTRALITY</h4>
          </div>
          <div className="menubar">
            <div
              style={{ display: "flex", flexDirection: "column" }}
              className="Fullwidth"
            >
              {menuItem.map((item, index) => (
                <div key={index} className="Full-Width">
                  <NavLink
                    to={item.path}
                    className="Sidebar-link"
                    activeClassName="active"
                    onClick={item.onClick}
                  >
                    <div className="icon">{item.icon}</div>
                    <div className="link_text ms-2  d-sm-inline">
                      {item.name}
                      {item.arrow}
                    </div>
                  </NavLink>
                  {item.subMenu &&
                    dropdownOpen &&
                    item.subMenu.map((subItem, subIndex) => (
                      <NavLink
                        to={subItem.path}
                        key={subIndex}
                        className="Sidebar-sublink"
                      >
                        <div className="link_text  d-sm-inline done">
                          <li>
                            <Link to={subItem.path}>{subItem.name}</Link>
                           </li>
                        </div>
                      </NavLink>
                    ))}
                </div>
              ))} 
              <div className="ADDSidebarButton">
                <button>
                  <GiHouseKeys /> Access to all Data
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`navbar-toggle ${isOpen ? "open" : ""}`}
          onClick={toggleNavbar}
        >

          <span></span>
          <span></span>
          <span></span>
        </div>
        <main>{children}</main>
      </div>
    </>
  );
};

export default Sidebar;
