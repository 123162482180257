import React from "react";
import "./ClassRoomPage.css";
import ClassRoomMain from "../classRooms/ClassRoomMain";

function ClassRoomPage() {
  return (
    <div className="ClassRoomPage-main">
      <ClassRoomMain />
    </div>
  );
}
export default ClassRoomPage;
