import React, { useState, useEffect } from "react";
import "./Recentgrades.css";
import Innercard from "./inner-card/Innercard";
import axios from "axios";
import { baseURL } from "../../../constants";
import { getUserProfile } from "../../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
axios.defaults.baseURL = baseURL;

const Recentgrades = () => {
  const dispatch = useDispatch();

  const { user, loading, error } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);
  const [grades, setGrades] = useState(null);
  const getRecentGrades = async () => {
    try {
      if (user) {
        let response = await axios.get(`/grades/recent/${user.dataValues.id}`, {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        });
        setGrades(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getRecentGrades();
  }, [user]);

  return (
    <div className="Recentgrades-main">
      <div className="Recentgrades-main-child-1">
        <h1>Recent Gradebook</h1>
        <Link to="/Grades" className="anker">See all</Link>
      </div>
      <div className="Recentgrades-main-child-2">
        {grades?.map((card, index) => (
          <Innercard
            key={index}
            title={card?.title}
            day={card?.date?.split("T")[0]}
            time={card?.time}
            progress={card?.scoredPercentage}
            // backgroundColor={"black"}
            progressColor={card?.result === "fail" ? "red" : "#16D03B"}
          />
        ))}
      </div>
    </div>
  );
};

export default Recentgrades;
