import React from "react";
import "./MediHistory.css";
import print from "../Medical/flat-color-icons_print.png";
import { LuFile } from "react-icons/lu";
import { IoEyeOutline } from "react-icons/io5";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getParentStudents } from "../../../redux/actions/userAction";
// import { getMedicalStudentId } from "../../../redux/actions/userAction";
import Dummy from "../../AdminComponents/images/dummy-user.png";
import { getMedicalHistoryStudentId } from "../../../redux/actions/userAction";
import axios from "axios";
import { baseURL } from "../../../constants";
axios.defaults.baseURL = baseURL;

function MediHistory() {
  function handlePrint() {
    window.print();
  }

  const { loading, students, error } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const { studentId: currentStudentId } = useSelector(
    (state) => state.medicalHistoryStudentId
  );

  const { user } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    if (user) {
      dispatch(getParentStudents(user?.dataValues?.id));
    }
  }, [user]);

  const [currentStudent, setCurrentStudent] = useState({
    name: "",
    image: "",
  });

  useEffect(() => {
    if (currentStudentId && students) {
      const student = students.find((st) => st.studentId == currentStudentId);

      setCurrentStudent({
        name: student?.Student?.firstName || "Name",
        image: `${baseURL}/images/${student?.Student?.profilePicture}`,
      });
    } else if (students && students?.length > 0) {
      setCurrentStudent({
        name: students?.Student?.[0]?.firstName || "Name",
        image: `${baseURL}/images/${students?.[0]?.Student?.profilePicture}`,
      });
      handleStoreId(students[0]?.studentId);
    }
  }, [students, currentStudentId]);

  const handleStoreId = (id) => {
    dispatch(getMedicalHistoryStudentId(id));
  };

  const [medicalHistories, setMedicalHistories] = useState(null);

  const [documents, setDocuments] = useState([]);

  const getMedicalHistory = async () => {
    try {
      if (currentStudentId) {
        const { data } = await axios.get(
          `/medicalHistories/getMedicalHistoryByStudentId/${currentStudentId}`,
          {
            headers: {
              accessToken: localStorage.getItem("token"),
            },
          }
        );
        setMedicalHistories(data?.medicalHistory);
        const documents = [];

        data?.medicalHistory?.forEach((history) => {
          if (history?.filePdf && history?.file) {
            documents.push(history?.filePdf, history?.file);
          } else if (history?.filePdf) {
            documents.push(history?.filePdf);
          } else if (history?.file) {
            documents.push(history?.file);
          }
        });
        setDocuments(documents);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMedicalHistory();
  }, [currentStudentId]);

  return (
    <div className="MediHistory-main">
      <div className="MediHistory-Second">
        <h4>Medical History</h4>
        <div className="dropdownn">
          <div className="dropdownn">
            {students && students?.length > 0 ? (
              <button
                className="btn dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <>
                  <img
                    src={currentStudent.image}
                    alt="userName"
                    onError={({ currentTarget }) => {
                      currentTarget.src = Dummy;
                    }}
                  />
                  <span className="userName">{currentStudent.name}</span>
                </>
              </button>
            ) : (
              <p>No Students</p>
            )}

            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {students &&
                students?.map((item) => {
                  return (
                    <li
                      onClick={() => {
                        handleStoreId(item?.studentId);
                      }}
                    >
                      <a className="dropdown-item" href="#">
                        <img
                          src={`${baseURL}/images/${item?.Student?.profilePicture}`}
                          alt="User"
                          onError={({ currentTarget }) => {
                            currentTarget.src = Dummy;
                          }}
                        />
                        {item?.Student?.firstName}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Code</th>
            <th>Grade</th>
            <th>Age</th>
            <th>Start Date</th>
            <th>End Time</th>
          </tr>
        </thead>
        <tbody>
          {medicalHistories?.map((medicalHistory) => {
            return (
              <tr>
                <td>{medicalHistory?.date?.split("T")[0]}</td>
                <td>{medicalHistory?.code}</td>
                <td>{medicalHistory?.grade}</td>
                <td>{medicalHistory?.age}</td>
                <td>{medicalHistory?.starttime}</td>
                <td>{medicalHistory?.endtime}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="PrintImg">
        <button onClick={handlePrint}>
          <img src={print} alt="#" />
          Print Medical Profile
        </button>
      </div>
      <h2>MEDICAL DOCUMENT</h2>
      {documents?.map(() => {
        return (
          <div className="MediHistory-main-last">
            <div className="MediHistory-main-text">
              <div className="MediHistory-icon">
                <LuFile />
              </div>
              <h2>MEDICAL DOCUMENT PDF</h2>
            </div>
            <div className="MediHistory-btns">
              <button>Download</button>
              <button>
                <IoEyeOutline />
                View
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default MediHistory;
