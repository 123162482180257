import React from "react";
import Ordercom from "./Ordercom";

const Order = ({ data = [] }) => {
  function tcard(val) {
    return (
      <Ordercom
        tablespan={val.tablespan}
        tableimg={val.tableimg}
        tablemade={val.tablemade}
        tabelproducts={val.tabelproducts}
        tabledate={val.tabledate}
        tablejohn={val.tablejohn}
        tabledollor={val.tabledollor}
        tablemaster={val.tablemaster}
        tableprocessing={val.tableprocessing}
        tabledelivered={val.tabledelivered}
        tablecancelled={val.tablecancelled}
        tableshiped={val.tableshiped}
      />
    );
  }
  return (
    <div>
      <Ordercom data={data} />
    </div>
  );
};

export default Order;
