import React, { useEffect, useState } from "react";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Dropdown from "react-bootstrap/Dropdown";
import { PiUploadFill } from "react-icons/pi";
import toast from "react-hot-toast";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import axios from "axios";
import { baseURL } from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../redux/actions/userAction";
import { IoAdd, IoSearch } from "react-icons/io5";
axios.defaults.baseURL = baseURL;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function NewClassroom() {
  const [editId, setEditId] = useState("");

  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(false);
  // eslint-disable-next-line
  const handleOpen = () => setOpen(true);
  const handleShow = () => setShow(true);
  const handleCloseShow = () => setShow(false);
  // eslint-disable-next-line
  const handleClosee = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setThumbnailImage(null);
    setThumbnailPreview(null);

    setOpen(false);
    setEditId("");
  };

  const [thumbnailImage, setThumbnailImage] = useState(
    "https://source.unsplash.com/640x640?computer"
  );
  // eslint-disable-next-line
  const navigate = useNavigate();
  const [thumbnailPreview, setThumbnailPreview] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const [classrooms, setClassrooms] = useState(null);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const { schoolId } = useSelector((state) => state.schoolId);

  const getSchoolByParentId = async () => {
    try {
      if (user) {
        // eslint-disable-next-line
        const { data } = await axios.get(
          `/ParentBySchoolId/getSchoolByTeacherId/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSchoolByParentId();
    // eslint-disable-next-line
  }, [user]);
  // eslint-disable-next-line
  const getAllClassrooms = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/classrooms/getClassroomsByTeacherId/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        setClassrooms(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [subjects, setSubjects] = useState(null);

  const getSubjects = async () => {
    try {
      if (schoolId) {
        const { data } = await axios.get(`/teachers/all/${schoolId}`, {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        });

        setSubjects(data?.teacherList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllClassrooms();
    getSubjects();
  }, [schoolId]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setThumbnailImage(file);

        setThumbnailPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [classroom, setClassroom] = useState("");
  // eslint-disable-next-line
  const [myName, setMyName] = useState("");

  const [subjectName, setSubjectName] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [subjectId, setSubjectId] = useState("");

  const remove = () => {
    setSubjectName();
    setGradeLevel();
    setClassroom();
    setSubjectId();
  };

  const addNewClassroom = async () => {
    try {
      const newForm = new FormData();

      if (schoolId && user) {
        thumbnailImage && newForm.append("classroomBanner", thumbnailImage);
        newForm.append("classroomName", classroom);
        newForm.append("subjectId", subjectId);
        newForm.append("schoolId", schoolId);
        newForm.append("gradeLevel", gradeLevel);
        newForm.append("teacherId", subjectName);

        if (!editId) {
          await axios.post("/classrooms/addClassroom", newForm, {
            headers: {
              "Content-Type": "multipart/form-data",
              accessToken: `${localStorage.getItem("token")}`,
            },
          });

          toast.success("Classroom Added");
        } else {
          await axios.put(`/classrooms/updateClassroom/${editId}`, newForm, {
            headers: {
              "Content-Type": "multipart/form-data",
              accessToken: `${localStorage.getItem("token")}`,
            },
          });

          toast.success("Classroom Updated");
        }
        setClassroom("");
        setGradeLevel("");
        setSubjectId("");
        setSubjectName("");
        // getAllClassrooms();
        getAllSchools();
        handleClose();
        handleCloseShow();
      }
    } catch (error) {
      console.log(error, "This is error");
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.message ||
            error?.response?.data?.details[0]?.detail
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const [allSchools, setAllSchools] = useState(null);

  const getAllSchools = async () => {
    try {
      if (schoolId) {
        const { data } = await axios.get(
          `/classrooms/getClassroomsBySchoolId/${schoolId}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setAllSchools(data?.classrooms);
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(allSchools, "these are all classrooms");

  useEffect(() => {
    getAllSchools();
  }, [schoolId]);

  // .....................................................

  const [grade, setGrade] = useState("");
  const getAllGrade = async () => {
    try {
      if (schoolId) {
        const { data } = await axios.get(
          `/students/getAllGradeLevels/${schoolId}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setGrade(data);
      }
    } catch (error) {
      console.log(error, "Ya lia");
    }
  };
  console.log(grade, "these are Grades");

  useEffect(() => {
    getAllGrade();
  }, [schoolId]);

  // ...............................................

  const handleDeleteClass = async () => {
    try {
      await axios.delete(`/classrooms/removeClassroom/${deleteId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      toast.success("Classroom Deleted");

      getAllSchools();
      handleClose();
      handleCloseShow();
    } catch (error) {
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else if (error?.response?.data?.error) {
        toast.error(
          error?.response?.data?.error[0]?.message ||
            error?.response?.data?.error[0]?.detail
        );
      } else {
        toast.error("Something went wrong!");
      }
    }
  };

  useEffect(() => {
    if (editId && classrooms) {
      setOpen(true);
      const getClassrooms = classrooms?.find((classroom) => {
        return editId === classroom?.id;
      });

      setClassroom(getClassrooms?.className);
      setMyName(getClassrooms?.myName);
      setSubjectName(getClassrooms?.subjectName);
    }
  }, [editId, classrooms]);

  const [allSubjects, setAllSubjects] = useState(null);

  const getAllSubjects = async () => {
    try {
      const { data } = await axios.get(`/subjects/getSubjects/${schoolId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setAllSubjects(data?.subjects);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(allSubjects, "these are all subjects");
  useEffect(() => {
    getAllSubjects();
  }, [schoolId]);

  return (
    <div className="TeacherClassRoomTopSearch">
      <div className="overview">
        <div className="view">
          <p>
            School
            <span>
              <IoIosArrowForward /> Manage Academics
            </span>
            <span>
              <IoIosArrowForward /> Room
            </span>
          </p>
        </div>
        <div className="myClassRoom">
          <div className="ClassFlex">
            <div className="ClassFlexSearch">
              <IoSearch />
              <input type="text" placeholder="Search ." />
            </div>
            <input className="ClassFlexxx" type="date" />
          </div>
          <React.Fragment>
            <Button variant="outlined" onClick={handleClickOpen}>
              <IoAdd />
              Add New Classroom
            </Button>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent className="CreateNewClass-main">
                <div className="CreateNewClass-main-child1">
                  <h5>Let’s Create your Classroom</h5>
                  <div className="CreateNewClass-main-child2">
                    <div className="CreateNewClass-main-child3">
                      {thumbnailPreview ? (
                        <img
                          src={thumbnailPreview}
                          alt="Thumbnail Preview"
                          className="thumbnail-preview"
                          style={{
                            padding: "5px",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <label htmlFor="thumbnailInput">
                          <PiUploadFill className="PiUploadFill-create" />
                          <p>Upload a Thumbnail Image</p>
                        </label>
                      )}
                      <input
                        type="file"
                        id="thumbnailInput"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                      />
                    </div>

                    <div className="CreateNewClass-main-child4">
                      <input
                        type="text"
                        id="text"
                        placeholder="Classroom Name"
                        value={classroom}
                        onChange={(e) => setClassroom(e.target.value)}
                      />
                      <select
                        value={subjectName}
                        onChange={(e) => setSubjectName(e.target.value)}
                        className="CreateNewClass-main-child4-My-Name"
                        name=""
                        id=""
                      >
                        <option value={""}>Assign Teacher</option>
                        {subjects &&
                          subjects?.map((subject) => {
                            return (
                              <option value={subject?.id}>
                                {subject?.firstName} {subject?.lastName}
                              </option>
                            );
                          })}
                      </select>
                      <select
                        value={subjectId}
                        onChange={(e) => setSubjectId(e.target.value)}
                        className="CreateNewClass-main-child4-My-Name"
                      >
                        <option value={""}>Select Subject</option>
                        {allSubjects &&
                          allSubjects?.map((subject) => {
                            return (
                              <option value={subject?.id}>
                                {subject?.subjectName}
                              </option>
                            );
                          })}
                      </select>
                      <select
                        value={gradeLevel}
                        onChange={(e) => setGradeLevel(e.target.value)}
                        className="CreateNewClass-main-child4-My-Name"
                      >
                        <option value={""}>Select Grade Level</option>
                        {grade &&
                          grade?.map((Level) => {
                            return <option value={Level?.id}>{Level}</option>;
                          })}
                      </select>
                      <div className="CreateNewClass-main-child5">
                        <button
                          onClick={() => {
                            handleClose();
                            remove();
                          }}
                        >
                          Cancel
                        </button>
                        <button onClick={addNewClassroom}>Create</button>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </React.Fragment>
        </div>
      </div>
      <div className="CommentModal-Mmain">
        <Modal
          className="Allowmodalmain"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={show}
          onClose={handleCloseShow}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={show}>
            <Box sx={style}>
              <div className="CommentModal-Mmain-child1">
                <h4>Delete Classroom</h4>
                <p>
                  Are you sure you want to delete this classroom? This will
                  remove the classroom and can’t be undone.
                </p>
                <div className="CommentModal-Mmain-child2">
                  <button
                    className="CommentModal-Mmain-child2-allow"
                    onClick={() => handleCloseShow()}
                  >
                    CANCEL
                  </button>
                  <button
                    onClick={handleDeleteClass}
                    className=" CommentModal-Mmain-child2-Dont-allow"
                  >
                    DELETE
                  </button>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>

      <div className="Classroom-child-2-mapa">
        {allSchools &&
          allSchools?.map((classroom, index) => (
            <div
              className="Classroom-child-2 teacher-Classroom-child-2"
              key={classroom?.id}
            >
              <div
                className="Classroom-child-2-main Teacher-Classroom-child-2-main"
                style={{ background: "#fff" }}
              >
                <div className="Mini_MAin Mini_Main_Teacher">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <HiOutlineDotsVertical />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className="Mini-Main-Div">
                        <Dropdown href="#/action-1">
                          <p
                            onClick={() => setEditId(classroom?.id)}
                            className="Mini-Main-Btns2"
                          >
                            Edit
                          </p>
                        </Dropdown>
                        <Dropdown href="#/action-2">
                          <Link
                            className="Mini-Main-Btns2"
                            // onClick={deleteSchoolHandler}
                            onClick={() => {
                              handleShow();
                              setDeleteId(classroom?.id);
                            }}
                          >
                            Delete
                          </Link>
                        </Dropdown>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div
                  className="classroom-child-2-img teacher-Classroom-child-2-img"
                  style={{
                    backgroundColor: index % 2 === 0 ? "#B1C1DF" : "#B798E0",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                    src={`${baseURL}/images/${classroom?.classroomBanner}`}
                    alt="#"
                  />
                </div>
                <p
                  style={{
                    color: index % 2 === 0 ? "#658BEB" : "#B1C1DF",
                  }}
                >
                  {classroom?.classroomName}
                </p>
                {/* <div
                  className="Linkeded"
                  onClick={() => {
                    localStorage.removeItem("classroomId");
                    localStorage.setItem("classroomId", classroom?.id);
                    localStorage.removeItem("subjectId", classroom?.subjectId);
                    localStorage.setItem("subjectId", classroom?.subjectId);
                    navigate(`/TeacherNestedTabs/${classroom?.subjectId}`);
                  }}
                >
                  <button className="ViewClassBtn">View Class</button>
                </div> */}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="custom-toggle"
  >
    {children}
  </button>
));

export default NewClassroom;
