import React from "react";
import "./Grades.css";
import print from "../Medical/flat-color-icons_print.png";

function Grades() {
  function handlePrint() {
    window.print();
  }

  return (
    <div className="Grades-Main">
      <div className="Grades-Top">
        <h3>Grades</h3>
        <button onClick={handlePrint}>
          <img src={print} alt="#" />
          Print Grade
        </button>
      </div>
      <div className="GRADESTAbLLEE">
        <table>
          <thead>
            <tr>
              <th>Per</th>
              <th>Crs-ID</th>
              <th>Course</th>
              <th>Teacher(s)</th>
              <th>1st Qtr</th>
              <th>1st Sem</th>
              <th>3rd Qtr</th>
              <th>2nd Sem</th>
              <th>CRD</th>
              <th>CIT</th>
              <th>WH</th>
              <th>ABS</th>
              <th>TDY</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>P1</td>
              <td>0317</td>
              <td>IB Eng HL2</td>
              <td>May, Ava</td>
              <td>A</td>
              <td>A</td>
              <td>A</td>
              <td className="GradesTD">dfsgfdsg</td>
              <td>5.00</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>29</td>
              <td>Barrera</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default Grades;
