import React, { useEffect, useRef, useState } from "react";
import "./StaffProfile.css";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { TiDocumentText } from "react-icons/ti";
import StaffTabs from "./StaffProfileTabs/StaffTabs";
import StaffInfoSchool from "./StaffSchoolinfo/StaffInfoSchool";
import StaffAddress from "./ADDStaffADDress/StaffAddress";
import CourseSchedule from "./ADDStaffADDress/EditStafAddress/CourseSchedule/CourseSchedule";
import Dummy from "../../images/dummy-user.png";
import { useParams } from "react-router-dom";
import Avatar from "../../PickAvator/Avator";
import fiupload from "../../images/fi_upload-cloud.png";
import { Link } from "react-router-dom";
import { IoCaretForwardOutline } from "react-icons/io5";
import axios from "axios";
import { baseURL } from "../../../../constants";
axios.defaults.baseURL = baseURL;

function StaffProfile() {
  const [editPhoto, setEditPhoto] = useState(false);
  const params = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [lgShow, setLgShow] = useState(null);

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (window.location.search.split("=")[1] === "true") {
      setEditPhoto(true);
    } else {
      setEditPhoto(false);
    }
  }, [window.location.search.split("=")[1]]);

  const handleHideImage = () => {
    if (window.location.search[0] === "?") {
      setEditPhoto(false);
    }
  };

  const [staffData, setStaffData] = useState(null);
  const getStaffSchool = async () => {
    try {
      const { data } = await axios.get(
        `/teachers/getTeacherSchoolInfoAndOfficialInfo/${params.staffId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );

      setStaffData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStaffSchool();
  }, []);

  return (
    <div className="StaffProfile-Main">
      <div className="myassingment-TOP2">
        <Link style={{ color: "#00afef" }} to="/StaffView">
          Staff List
        </Link>
        <IoCaretForwardOutline />
        <p
        // to={`/StaffProfile/${params.staffId}`}
        >
          Staff Information
        </p>
      </div>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <div className="staffcoldivmain">
            <div className="staffcoldivmain-child-1">
              <Col sm={12} className="StaffProfile-Col3-main">
                <div className="StaffProfile-COl3">
                  {!editPhoto && staffData ? (
                    <div className="StaffProfile-Child-1">
                      <img
                        src={`${baseURL}/images/${staffData?.teacherWithInfo?.profilePicture}`}
                        onError={({ currentTarget }) => {
                          currentTarget.src = Dummy;
                        }}
                        alt="Staff"
                      />
                      <h2>{staffData?.teacherWithInfo?.staffName}</h2>
                      <button className="TeacherBtn">Teacher</button>
                    </div>
                  ) : (
                    <div className="EditStaff-COl3">
                      <div className="EditStaff-Child-1">
                        <div className="fiupload-img">
                          <div
                            onClick={() => setLgShow(true)}
                            className="upLoaDing"
                          >
                            {selectedFile ? (
                              <img
                                src={selectedFile}
                                alt="Staff"
                                style={{
                                  width: "100%",
                                  height: "171px",
                                  cursor: "pointer",
                                  borderRadius: "50%",
                                }}
                                // onClick={handleImageClick}
                                onClick={() => setLgShow(true)}
                              />
                            ) : (
                              <>
                                <img
                                  src={fiupload}
                                  alt="Staff"
                                  style={{
                                    width: "20px",
                                    cursor: "pointer",
                                    height: "20px",
                                  }}
                                  // onClick={handleImageClick}
                                  onClick={() => setLgShow(true)}
                                />
                                <label
                                  htmlFor="upload"
                                  style={{
                                    cursor: "pointer",
                                    marginTop: "8px",
                                  }}
                                >
                                  Update Profile
                                </label>
                              </>
                            )}
                            <Avatar
                              setProfilePicture={setProfilePicture}
                              profilePicture={profilePicture}
                              lgShow={lgShow}
                              setLgShow={setLgShow}
                              setSelectedFile={setSelectedFile}
                            />
                          </div>
                        </div>
                        {staffData && (
                          <h2>{staffData.teacherWithInfo.staffName}</h2>
                        )}
                        <button>Teacher</button>
                      </div>
                    </div>
                  )}
                </div>
                <MDBAccordion initialActive={1}>
                  <MDBAccordionItem
                    collapseId={1}
                    headerTitle={
                      <>
                        <TiDocumentText /> Staff Details
                      </>
                    }
                  >
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="first">General Info</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link onClick={handleHideImage} eventKey="second">
                          School Info
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link onClick={handleHideImage} eventKey="third">
                          Address & Contact
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </MDBAccordionItem>
                </MDBAccordion>
                <Nav.Item>
                  <Nav.Link eventKey="fourth" className="StaffDifferentLinkss">
                    <TiDocumentText /> Course Schedule
                  </Nav.Link>
                </Nav.Item>
              </Col>
            </div>
            <div className="staffcoldivmain-child-2">
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <StaffTabs
                      setEditPhoto={setEditPhoto}
                      selectedFile={profilePicture}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <StaffInfoSchool staffData={staffData} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <StaffAddress />
                  </Tab.Pane>
                  <Tab.Pane eventKey="fourth">
                    <CourseSchedule />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </div>
          </div>
        </Row>
      </Tab.Container>
    </div>
  );
}

export default StaffProfile;
