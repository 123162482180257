import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { BsSliders } from "react-icons/bs";
import "./Filter.css";

function FilterModal() {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <div className="FilterAll">
      <div className="FilterAllSvg">
        <BsSliders />
      </div>
      <DropdownButton id="dropdown-basic-button" title="Filters">
        <Dropdown>
          <div className="Filter">
            <h2>Filter</h2>
            <hr />
            <div className="Filter-Status ">
              <label>Course</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>All</option>
                <option value="1">One</option>
              </select>
              <label>Teacher</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>All</option>
                <option value="1">One</option>
              </select>
              <div className="FilterModalBtn">
                <button>Filter</button>
              </div>
            </div>
          </div>
        </Dropdown>
      </DropdownButton>
    </div>
  );
}

export default FilterModal;
