import React, { useEffect, useState } from "react";
import "./Classroom.css";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { cardsData } from "../data/MyscheduleData";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../constants";
import { getUserProfile } from "../../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
axios.defaults.withCredentials = true;

const ClassRoomMain = () => {
  const [classRooms, setClassrooms] = useState(null);

  const dispatch = useDispatch();

  const { user, loading, error } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);
  const getClassrooms = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/classrooms/getAllClassroomsByStudent/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        setClassrooms(data?.classrooms);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getClassrooms();
  }, [user]);
  const navigate = useNavigate();

  const handleViewClass = (id) => {
    navigate(`/NestedTabs/${id}`);
  };
  console.log(user.dataValues.id, "uibsvvskljdfbvskdfj")
  return (
    <div className="classroom-main">
      <div className="Classroom-child-1 Classroom-child-1s">
        <div>
          <p className="para">
            My <span>Classroom</span>
          </p>
        </div>
      </div>
      <div className="Classroom-child-2-map Classroom-child-2-maps">
        {classRooms &&
          classRooms?.map((card, index) => {
            const newIndex = index % cardsData.length;

            return (
              <div
                className="Classroom-child-2 Classroom-child-2s"
                key={card?.id}
              >
                <div
                  className="Classroom-child-2-main Classroom-child-2-mains"
                  style={{ background: cardsData[newIndex]?.backgroundColor }}
                >
                  {/* <span
                    className="three-dot-icon"
                    style={{ color: cardsData[newIndex]?.iconColor }}
                  >
                    <HiOutlineDotsVertical />
                  </span> */}
                  <div
                    className="classroom-child-2-img classroom-child-2-imgs"
                    style={{
                      backgroundColor: cardsData[newIndex]?.imgBackgroundColor,
                    }}
                  >
                    <img
                      src={`${baseURL}/images/${card?.classroomBanner}`}
                      alt=""
                    />
                  </div>
                  <p
                    className="pra"
                    style={{ color: cardsData[newIndex]?.iconColor }}
                  >
                    {card?.classroomName}
                  </p>
                  <button
                    className="btton"
                    onClick={() => {
                      localStorage.removeItem("classroomId");
                      localStorage.setItem("classroomId", card.id);
                      navigate(`/NestedTabs/${card.subjectId}`);
                    }}
                    style={{
                      backgroundColor: cardsData[newIndex]?.buttonColor,
                    }}
                  >
                    View Class
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ClassRoomMain;
