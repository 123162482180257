import React, { useEffect, useState } from "react";
import AppImg from "../../images/1195025-education-apps 1.png";
import Appimg from "../../images/1195025-education-apps 2.png";
import appIMG from "../../images/1195025-education-apps 3.png";
import APPimg from "../../images/1195025-education-apps 4.png";
import ApPImg from "../../images/1195025-education-apps 5.png";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import "./RecentlyApp.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import FilterModal from "../../ByDate/Filter/FilterModal";
import ByDate from "../../ByDate/ByDate";
import { IoSearchOutline } from "react-icons/io5";
import axios from "axios";
import { baseURL } from "../../../../constants";
import { getUserProfile } from "../../../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;
function RecentlyApp() {
  const appData = [
    { Img: AppImg, label: "Camera", timeSpent: 5 * 60 * 60 },
    { Img: Appimg, label: "Calendar", timeSpent: 5 * 60 * 60 },
    { Img: appIMG, label: "Gallery", timeSpent: 10 * 60 * 60 },
    { Img: APPimg, label: "Message", timeSpent: 7 * 60 * 60 },
  ];

  const [apps, setApps] = useState([]);
  const dispatch = useDispatch();

  const { user, loading, error } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const getApps = async () => {
    try {
      if (user) {
        let { data } = await axios.get(
          `/apps/mostUsedApps/${user.dataValues.id}`,
          {

            
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setApps(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getApps();
  }, [user]);

  return (
    <div className="RecentlyApp-main">
      <div className="RecentlyApp-Filter">
        <h4>Recently Added</h4>
        <div className="RecentlyApp-Filter-All">
          <div className="RecentlyApp-Search">
            <IoSearchOutline />
            <input placeholder="Search App" />
          </div>
          <ByDate />
          <FilterModal />
        </div>
      </div>
      <div className="APPSIcon">
        <div className="AppFlex">
          <img src={AppImg} alt="" />
          <StarOutlineRoundedIcon />
        </div>
        <div className="AppFlex">
          <img src={Appimg} alt="" />
          <StarOutlineRoundedIcon />
        </div>
        <div className="AppFlex">
          <img src={appIMG} alt="" />
          <StarOutlineRoundedIcon />
        </div>
        <div className="AppFlex">
          <img src={APPimg} alt="" />
          <StarOutlineRoundedIcon />
        </div>
        <div className="AppFlex">
          <img src={ApPImg} alt="" />
          <StarOutlineRoundedIcon />
        </div>
        <div className="AppFlex">
          <img src={ApPImg} alt="" />
          <StarOutlineRoundedIcon />
        </div>
      </div>
      <h4>Most used App</h4>

      <div className="RecentlyApp-ProgressBars">
        {apps &&
          apps.map((app, index) => (
            <div className="ProgressFlex1">
              <div>
                <img
                  style={{ objectFit: "contain" }}
                  src={`${baseURL}/images/${app?.appLogo}`}
                  alt=""
                  key={index}
                />
                <label htmlFor={`file${index}`}>{app?.appName}</label>
              </div>
              <div className="ProgressBarWrapper">
                <ProgressBar
                  now={
                    ((parseInt(app?.totalUsageTime) * 60 * 60) /
                      (24 * 60 * 60)) *
                    100
                  }
                  className="ProgressBar"
                />
                <span className="ProgressBarTime">
                  {`${(
                    (parseInt(app?.totalUsageTime) * 60 * 60) /
                    3600
                  ).toFixed(2)}h`}
                </span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default RecentlyApp;
