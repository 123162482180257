import React, { useState, useEffect } from "react";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import "./Order.css";
import { baseURL } from "../../../constants";

const Ordercom = ({ data: newData = null }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (newData) {
      setData(newData);
    }
  }, [newData]);

  const getCurrentDay = new Date().getDay();

  const day = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const recordsPerPage = 4;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = data.slice(firstIndex, lastIndex);
  const npage = Math.ceil(data.length / recordsPerPage);
  const number = [...Array(npage).keys()].slice(1);

  function prePage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  return (
    <div className="main-order1">
      <div className="firstdiv">
        <table id="table1">
          <thead>
            <tr className="tablerow">
              <th className="order">S.No</th>
              <th className="product">Teacher</th>
              <th>Class Time</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((record, index) => (
              <tr key={index}>
                <td className="order">{index + 1}</td>
                <td className="product">
                  <div className="img">
                    <img
                      src={`${baseURL}/images/${record?.Teacher?.profilePicture}`}
                      alt="profileImage"
                    />
                    <div className="proinfo">
                      <h3>
                        {record?.Teacher?.firstName} {record?.Teacher?.lastName}
                      </h3>
                    </div>
                  </div>
                </td>
                <td>
                  {record?.Subject?.Schedules?.map((stday) => {
                    return stday?.day?.toLowerCase() ===
                      day[getCurrentDay]?.toLowerCase()
                      ? stday?.startTime
                      : null;
                  })}
                </td>
                <td>{record?.date?.split("T")[0]}</td>
                {record.status && (
                  <td className="processing">{record?.status}</td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <nav>
          <ul className="pagination">
            <li className="page-item">
              <div className="page-link arrorw" onClick={prePage}>
                <AiFillCaretLeft />
              </div>
            </li>
            {number.map((n, i) => (
              <li
                className={`page-item ${currentPage === n ? "active" : ""}`}
                key={i}
              >
                {n === currentPage ? (
                  <span className="page-link arrorw focus">{n}</span>
                ) : (
                  <div
                    className="page-link arrorw focus"
                    onClick={() => changeCPage(n)}
                  >
                    {n}
                  </div>
                )}
              </li>
            ))}
            <li className="page-item">
              <div className="page-link arrorw" onClick={nextPage}>
                <AiFillCaretRight />
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Ordercom;
