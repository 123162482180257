import "./NestedTabs.css";
import { Tab, Nav, Row, Col } from "react-bootstrap";
import { IoCaretForwardOutline } from "react-icons/io5";
import Summary from "./Summary/Summary";
import LineChart from "./Chart/LineChart";
import NoticeBoard from "./NoticeBoard/NoticeBoard";
import MyAssingmentSub from "./MyAssingmentsSub/MyAssingmentSub";
import ClassLectures from "./ClassLectures/ClassLectures";
import MyassigmentMain from "../myassigmentMain/MyassigmentMain";
import MyGrade from "../MyGrade/MyGrade";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./MyAssingmentsSub/MyAssingmentSub.css";
import Addimage from "../AddimageModals/Addimage/Addimage";
import "../AddimageModals/AddimageModal/AddimageModal.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ImRadioChecked } from "react-icons/im";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { baseURL } from "../../../constants";

import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../redux/actions/userAction";
axios.defaults.baseURL = baseURL;

const NestedTabs = () => {
  const [open, setOpen] = React.useState(false);
  const [assignmentId, setAssignmentId] = useState("");

  const handleOpen = (id) => {
    setAssignmentId(id);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const params = useParams();

  const [gradeData, setGradeData] = useState(null);

  const [pass, setPass] = useState(null);
  const [fail, setFail] = useState(null);
  const [miss, setMiss] = useState(null);

  const dispatch = useDispatch();

  const { user, loading, error } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const getAllGrades = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/grades/getAllStudentGradesByStudentAndSubject/${user.dataValues.id}/${params.subjectId}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        setGradeData(data);

        const passed = data.filter((element) => {
          return element?.result === "pass";
        });

        setPass(passed);

        const failed = data.filter((element) => {
          return element?.result === "fail";
        });

        setFail(failed);

        const missed = data.filter((element) => {
          return element?.result === "miss";
        });

        setMiss(missed);
      }
    } catch (error) {

      console.log(error);
    }
  };

  useEffect(() => {
    getAllGrades();
  }, []);


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const myStyles = [
    {
      bordercolor: "#F93333",
      backgroundcolor: "rgba(249,51,51,0.11)",
      buttonbackgroundcolor: "rgba(249,51,51,0.11)",
      descriptionColor: "#F93333",
      buttonTextColor: "#F93333",
      button: "Submit assignment",
      spandotColor: "#F93333",
      bctextColor: "#F93333",
    },
    {
      bordercolor: "#FF7E3E",
      backgroundcolor: "rgba(22,208,59,0.14)",
      buttonbackgroundcolor: "rgba(22,208,59,1)",
      descriptionColor: "#FF7E3E",
      buttonTextColor: "rgba(249, 51, 51, 1)",
      button: "Submit assingment",
      spandotColor: "#FF7E3E",
      bctextColor: "#4E5566",
    },
    {
      bordercolor: "#16D03B",
      backgroundcolor: "rgba(240,255,243,0.14)",
      buttonbackgroundcolor: "rgba(240,255,243,1)",
      descriptionColor: "#16D03B",
      buttonTextColor: "#16D03B",
      spandotColor: "#16D03B",
      button: "Already submitted",
      bctextColor: "#16D03B",
    },
    {
      bordercolor: "#00AFEF",
      backgroundcolor: "rgba(0,175,239,0.15)",
      buttonbackgroundcolor: "rgba(0,175,239,0.15)",
      descriptionColor: "#00AFEF",
      buttonBgColor: "#00AFEF",
      buttonTextColor: "#00AFEF",
      spandotColor: "#00AFEF",
      bctextColor: "#00AFEF",
    },
  ]

  useEffect(() => {
    const getAllAssignments = async () => {
      const generateMappedData = (inputData) => {
        const styles = {
          "OverDue Today": {
            bordercolor: "#F93333",
            backgroundcolor: "rgba(249,51,51,0.11)",
            buttonbackgroundcolor: "rgba(249,51,51,0.11)",
            descriptionColor: "#F93333",
            buttonTextColor: "#F93333",
            button: "Submit assignment",
            spandotColor: "#F93333",
            bctextColor: "#F93333",
          },
          "Due soon": {
            bordercolor: "#FF7E3E",
            backgroundcolor: "rgba(22,208,59,1)",
            buttonbackgroundcolor: "rgba(22,208,59,1)",
            descriptionColor: "#FF7E3E",
            buttonTextColor: "rgba(249, 51, 51, 1)",
            button: "Submit assingment",
            spandotColor: "#FF7E3E",
            bctextColor: "#4E5566",
          },
          submitted: {
            bordercolor: "#16D03B",
            backgroundcolor: "rgba(240,255,243,1)",
            buttonbackgroundcolor: "rgba(240,255,243,1)",
            descriptionColor: "#16D03B",
            buttonTextColor: "#16D03B",
            spandotColor: "#16D03B",
            button: "Already submitted",
            bctextColor: "#16D03B",
          },

          "new Assignment": {
            bordercolor: "#00AFEF",
            backgroundcolor: "rgba(0,175,239,0.15)",
            buttonbackgroundcolor: "rgba(0,175,239,0.15)",
            descriptionColor: "#00AFEF",
            buttonBgColor: "#00AFEF",
            buttonTextColor: "#00AFEF",
            spandotColor: "#00AFEF",
            bctextColor: "#00AFEF",
          },
        };

        return inputData.map((item) => {
          const style = styles[item.type] || {};
          return { ...item, ...style };
        });
      };
    };
  }, []);

  const [key, setKey] = useState("tab1");
  const [assignments, setAssignments] = useState(null);

  const getAssignments = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/assignments/getAllAssignments/${params.subjectId}/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setAssignments(data.assignments);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [graded, setGraded] = useState(null);

  const getGraded = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/grades/getAllStudentGradesByStudentAndSubject/${user.dataValues.id}/${params.subjectId}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setGraded(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const [submissionsTest, setSubmissionsTest] = useState(null);

  const getSubmissions = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/Tests/getTestsBySubjectId/${params.subjectId}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setSubmissionsTest(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(submissionsTest, "these are submission tests")
  console.log(submissionsTest, "This is test");
  const [submittedTests, setSubmittedTests] = useState(null);

  const getSubmittedTests = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/classTests/getSubmittedTests/${user.dataValues.id}/${params.subjectId}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        setSubmittedTests(data?.tests);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [upcomingTests, setUpcomingTests] = useState(null);

  const getUpcomingTests = async () => {
    try {
      const { data } = await axios.get(
        `/classTests/getUpcommingTests/${params.subjectId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setUpcomingTests(data?.upcomingTests);
    } catch (error) {
      console.log(error);
    }
  };

  const [retakingTests, setRetakingTests] = useState(null);

  const getRetakingTests = async () => {
    try {
      if (user) {
        const { data } = await axios.get(
          `/classTests/getRetakeTests/${params.subjectId}/${user.dataValues.id}`,
          {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );

        setRetakingTests(data?.tests);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAssignments();
    getGraded();
    getSubmissions();
    getSubmittedTests();
    getUpcomingTests();
    getRetakingTests();
  }, [user]);

  const reCall = () => {
    getAssignments();
    getGraded();
    getSubmissions();
    getSubmittedTests();
    getUpcomingTests();
    getRetakingTests();
  };

  const [subject, setSubject] = useState(null);
  const { subjectId } = useParams();
  const getSubject = async () => {
    try {
      const { data } = await axios.get(
        `/subjects/getSubjectById/${subjectId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setSubject(data?.subject);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getSubject();
  }, [user]);

  const navigate = useNavigate()
  useEffect(()=>{
    if(key === "tab1"){
      navigate("?key=summary")
    } else if(key === "tab2") {
      navigate("?key=grade")
    }else if(key === "tab5") {
      navigate("?key=noticeboard")
    }
    else if(key === "tab6") {
      navigate("?key=classlecture")
    }
    else{
      navigate("?")
    }
  }, [key])
  return (
    <div className="mainTabs">
      <Tab.Container
        id="nested-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Row>
          <Col sm={12} className="backGroundColor">
            <div className="Nested-Dflex">
              <div className="whereAreYou">
                <div className="homee">
                  <Link to="/Home" className="blueColor">
                    Home
                  </Link>
                  <span>
                    <IoCaretForwardOutline />
                  </span>
                  <Link to="/ClassRoomPage" className="blueColor">
                    Classrooms
                  </Link>
                  <span className="gray">
                    <IoCaretForwardOutline />
                  </span>
                  <p>{subject?.subjectName}</p>
                </div>
              </div>
              <Nav variant="tabs" className="nestedTabs">
                <Nav.Item>
                  <Nav.Link eventKey="tab1">Summary</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab2">My Gradebook</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab3">Assignments</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab4">Quizzes</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab5">Noticeboard</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab6">Class Lectures</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab7">Videos</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="tab1">
                <Summary />
                <div className="Chart">
                  <LineChart />
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="tab2">
                <Tab.Container
                  id="nested-subtabs-grades"
                  defaultActiveKey="all"
                >
                  <div className="main-Links">
                    <div className="mainLinks">
                      <Row>
                        <Col sm={12}>
                          <Nav variant="tabs" className="navLinksMain">
                            <h4>My Grades</h4>
                            <div className="mainLinksItems">
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="passed"
                                  className="nav-links"
                                >
                                  Passed
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="failed"
                                  className="nav-links"
                                >
                                  Failed
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="missed"
                                  className="nav-links"
                                >
                                  Missed
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="all" className="nav-links">
                                  All
                                </Nav.Link>
                              </Nav.Item>
                            </div>
                          </Nav>
                        </Col>
                      </Row>
                      <Row>
                        {gradeData && (
                          <Col sm={12}>
                            <Tab.Content>
                              <Tab.Pane eventKey="passed">
                                <MyGrade
                                  noRedirect={true}
                                  gradeData={pass}
                                  status={"pass"}
                                />
                              </Tab.Pane>
                              <Tab.Pane eventKey="failed">
                                <MyGrade
                                  noRedirect={true}
                                  gradeData={fail}
                                  status={"fail"}
                                />
                              </Tab.Pane>
                              <Tab.Pane eventKey="missed">
                                <MyGrade
                                  noRedirect={true}
                                  gradeData={miss}
                                  status={"miss"}
                                />
                              </Tab.Pane>
                              <Tab.Pane eventKey="all">
                                <MyGrade
                                  noRedirect={true}
                                  gradeData={gradeData}
                                  status={"all"}
                                />
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </div>
                </Tab.Container>
              </Tab.Pane>
              <Tab.Pane eventKey="tab3">
                <Tab.Container
                  id="nested-subtabs-assignments"
                  defaultActiveKey="all"
                >
                  <div className="main-Links">
                    <div className="mainLinks">
                      <Row>
                        <Col sm={12}>
                          <Nav variant="tabs" className="navLinksMain">
                            <h4>Assignment Content</h4>
                            <div className="mainLinksItems">
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="graded"
                                  className="nav-links"
                                >
                                  Graded
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="sbmitted"
                                  className="nav-links"
                                >
                                  2/10 Submitted
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="all" className="nav-links">
                                  All
                                </Nav.Link>
                              </Nav.Item>
                            </div>
                          </Nav>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <Tab.Content>
                            <Tab.Pane eventKey="graded">
                              {graded && <MyGrade gradeData={graded} />}
                            </Tab.Pane>
                            <Tab.Pane eventKey="sbmitted">
                              <MyAssingmentSub />
                            </Tab.Pane>
                            <Tab.Pane eventKey="all">
                              <div>
                                <div className="myassingment-child-1">
                                  <div className="myassingment-radio-btn">
                                    <span></span>
                                    <p>OverDue</p>
                                    <ImRadioChecked
                                      style={{
                                        color: "#f93333",
                                        width: "19px",
                                        height: "19px",
                                      }}
                                    />
                                  </div>
                                  <div className="myassingment-radio-btn">
                                    <span></span>
                                    <p>Due Soon</p>
                                    <ImRadioChecked
                                      style={{
                                        color: "#ff7e3e",
                                        width: "19px",
                                        height: "19px",
                                      }}
                                    />
                                  </div>
                                  <div className="myassingment-radio-btn">
                                    <span></span>
                                    <p>Submitted</p>
                                    <ImRadioChecked
                                      style={{
                                        color: "#16d03b",
                                        width: "19px",
                                        height: "19px",
                                      }}
                                    />
                                  </div>
                                  <div className="myassingment-radio-btn">
                                    <span></span>
                                    <p>New</p>
                                    <ImRadioChecked
                                      style={{
                                        color: "#00afef",
                                        width: "19px",
                                        height: "19px",
                                      }}
                                    />
                                  </div>
                                </div>
                                {assignments &&
                                  assignments.map((assignment, index) => {
                                    const newIndex = index % myStyles.length;
                                    return (
                                      <div
                                        className="myassingment-child-2"
                                        key={index}
                                        style={{ padding:'10px' }}
                                      >
                                        <div
                                          className="myassingment-card"
                                          style={{
                                            backgroundColor:
                                              myStyles[
                                                assignment?.status !==
                                                "submitted"
                                                  ? 0
                                                  : 2
                                              ]?.backgroundcolor,
                                            borderColor:
                                              myStyles[
                                                assignment?.status !==
                                                "submitted"
                                                  ? 0
                                                  : 2
                                              ]?.bordercolor,
                                          }}
                                        >
                                          <div className="myassingment-card-child-1">
                                            <div className="myassingment-child">
                                              <div className="myassingment-child-data">
                                                <div className="myassingment-child-data">
                                                  <p
                                                    className="date"
                                                    style={{
                                                      colors:
                                                        myStyles[
                                                          assignment?.status !==
                                                          "submitted"
                                                            ? 0
                                                            : 2
                                                        ]?.bcColor,
                                                      backgroundColor:
                                                        myStyles[
                                                          assignment?.status !==
                                                          "submitted"
                                                            ? 0
                                                            : 2
                                                        ]?.buttonTextColor,
                                                    }}
                                                  >
                                                    {assignment?.id}
                                                  </p>
                                                </div>
                                                <div className="mychild-myassingment">
                                                  <h6
                                                    style={{
                                                      color:
                                                        myStyles[
                                                          assignment?.status !==
                                                          "submitted"
                                                            ? 0
                                                            : 2
                                                        ]?.descriptionColor,
                                                    }}
                                                  >
                                                    {assignment?.title}
                                                  </h6>
                                                  <div className="myassingment-time">
                                                    <div className="myassingment-mother">
                                                      <p>
                                                        {
                                                          assignment?.dueOn?.split(
                                                            "T"
                                                          )[0]
                                                        }
                                                      </p>
                                                      <span
                                                        style={{
                                                          color:
                                                            myStyles[
                                                              assignment?.status !==
                                                              "submitted"
                                                                ? 0
                                                                : 2
                                                            ]?.spandotColor,
                                                        }}
                                                      >

                                                        ●
                                                      </span>
                                                      <p>
                                                        {
                                                          assignment?.createdAt?.split(
                                                            "T"
                                                          )[0]
                                                        }
                                                      </p>
                                                    </div>
                                                    {/* <p
                                                      style={{
                                                        fontSize: "8px",
                                                      }}
                                                    ></p> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="myassingment-card-child-2">
                                            <div className="description">
                                              <h5
                                                style={{
                                                  color:
                                                    myStyles[
                                                      assignment?.status !==
                                                      "submitted"
                                                        ? 0
                                                        : 2
                                                    ]?.buttonTextColor,
                                                }}
                                              >
                                                {assignment?.description}
                                              </h5>
                                              
                                            </div>
                                            <h5
                                                style={{
                                                  color:
                                                    myStyles[
                                                      assignment?.status !==
                                                      "submitted"
                                                        ? 0
                                                        : 2
                                                    ]?.buttonTextColor,
                                                }}
                                              >
                                                {assignment?.status || "Missed"}
                                              </h5>
                                            <div className="dov">
                                              <button
                                                style={{
                                                  background:
                                                    myStyles[
                                                      assignment?.status !==
                                                      "submitted"
                                                        ? 0
                                                        : 2
                                                    ]?.backgroundcolor,
                                                  borderColor:
                                                    myStyles[
                                                      assignment?.status !==
                                                      "submitted"
                                                        ? 0
                                                        : 2
                                                    ]?.buttonTextColor,
                                                  color:
                                                    myStyles[
                                                      assignment?.status !==
                                                      "submitted"
                                                        ? 0
                                                        : 2
                                                    ]?.buttonTextColor,
                                                }}
                                                onClick={() =>
                                                  assignment?.status !==
                                                    "submitted" &&
                                                  handleOpen(assignment?.id)
                                                }
                                              >
                                                {assignment?.status}
                                              </button>
                                              <div className="AssingMent-Submit">
                                                <Modal
                                                  open={open}
                                                  onClose={handleClose}
                                                  aria-labelledby="modal-modal-title"
                                                  aria-describedby="modal-modal-description"
                                                >
                                                  <Box
                                                    sx={style}
                                                    className="Neted_Modal"
                                                  >
                                                    <Typography
                                                      id="modal-modal-title"
                                                      variant="h6"
                                                      component="h2"
                                                    >
                                                      Submit your assignment
                                                    </Typography>
                                                    <Typography
                                                      id="modal-modal-description"
                                                      sx={{ mt: 2 }}
                                                    >
                                                      <Addimage
                                                        func={reCall}
                                                        assignmentId={
                                                          assignmentId
                                                        }
                                                        subjectId={
                                                          params?.subjectId
                                                        }
                                                        studentId={
                                                          user.dataValues.id
                                                        }
                                                      />
                                                    </Typography>
                                                  </Box>
                                                </Modal>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Tab.Container>
              </Tab.Pane>
              <Tab.Pane eventKey="tab4">
                <Tab.Container
                  id="nested-subtabs-tests"
                  defaultActiveKey="all"
                >
                  <div className="TestContent">
                    <Row>
                      <Col sm={12}>
                        <Nav variant="tabs">
                          <h4>Test</h4>
                          <div className="Test">
                            <Nav.Item>
                              <Nav.Link eventKey="retake" className="nav-links">
                                Retake test
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="upcoming"
                                className="nav-links"
                              >
                                Upcoming
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="submitted"
                                className="nav-links"
                              >
                                Submitted{" "}
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="all" className="nav-links">
                                All
                              </Nav.Link>
                            </Nav.Item>
                          </div>
                        </Nav>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="retake">
                            {retakingTests && (
                              <MyassigmentMain
                                data={retakingTests}
                                status={"Retake Test"}
                              />
                            )}
                            
                            
                          </Tab.Pane>
                          <Tab.Pane eventKey="upcoming">
                            {upcomingTests && (
                              <MyassigmentMain
                                data={upcomingTests}
                                status={"Upcoming"}
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey="submitted">
                            {submittedTests && (
                              <MyassigmentMain
                                data={submittedTests}
                                status={"Submitted"}
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey="all">
                            {submissionsTest && (
                              <MyassigmentMain
                                data={submissionsTest}
                                status={"All"}
                              />
                            )}
                             
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </div>
                </Tab.Container>
              </Tab.Pane>
              <Tab.Pane eventKey="tab5">
                <NoticeBoard />
              </Tab.Pane>
              <Tab.Pane eventKey="tab6">
                <ClassLectures />
              </Tab.Pane>
              <Tab.Pane eventKey="tab7">
                <h4>Upcoming</h4>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default NestedTabs;
