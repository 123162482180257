import React, { useState, useEffect } from "react";
import "./Addsignup.css";
import signuplogo from "./images/Group 1000004512.png";
import signupimg from "./images/image 9.png";
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";
import toast from "react-hot-toast";
import axios from "axios";
import { Country, State, City } from "country-state-city";
import { baseURL } from "../../../../constants";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { FaPhoneAlt } from "react-icons/fa";
import Loader from "../../../AdminComponents/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../redux/actions/userAction";

axios.defaults.baseURL = baseURL;

const Addsignup = () => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");

  const { user, isAuthenticated, loading, error } = useSelector(
    (state) => state.getUserProfile
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  useEffect(() => {
    if (!loading && isAuthenticated && user) {
      if (user.role === "parent") {
        navigate("/Parentportal");
      } else if (user.role === "admin") {
        navigate("/AdminstratorDashbord");
      } else if (user.role === "student") {
        navigate("/Home");
      } else if (user.role === "teacher") {
        navigate("/teacherhome");
      }
    }
  }, [isAuthenticated]);
  const countries = Country.getAllCountries();
  const cities = City.getCitiesOfState(selectedCountry, selectedRegion);
  const togglePasswordVisibility = (field) => {
    switch (field) {
      case "current":
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [country, setCountry] = useState("");
  const [district, setDistrict] = useState("");
  const regions = State.getStatesOfCountry(country);

  const navigate = useNavigate();
  const [contactNumber, setContactNumber] = useState("+1");
  useEffect(() => {
    if (!contactNumber?.startsWith("+1")) {
      setContactNumber("+1");
    } else {
      setContactNumber(contactNumber);
    }
  }, [contactNumber]);

  // const handleAddStudent = async () => {
  //   try {
  //     const regex = /^(\+1\s?)?(\ (\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
  //     if (!regex?.test(contactNumber))
  //       return toast.error("Phone number is invalid");
  //   }

  const handleSignup = async () => {
    if (confirmPassword !== password) {
      return toast.error("Password and confirm password is not matching");
    }
    try {
      const { data } = await axios.post(
        `/admins/signup`,
        {
          firstName,
          lastName,
          email,
          phoneNumber: contactNumber,
          password,
          schoolName,
          country,
          district,
          role: "admin",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      localStorage.removeItem("token");

      localStorage.setItem("token", data.accessToken);

      navigate(`/AdminstratorDashbord`);
      window.location.reload();
      toast.success(`Welcome ${firstName} ${lastName}`);
    } catch (error) {
      console.log(error, "this is signup");
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message
        );
      } else if (error?.response?.data?.error) {
        toast.error(
          error?.response?.data?.error[0]?.error ||
            error?.response?.data?.error[0]?.detail ||
            error?.response?.data?.error[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return loading && !user ? (
    <Loader />
  ) : (
    <div className="Addsignup-main">
      <div className="Addsignup-main-child-1">
        <div className="Addsignup-main-child-1-logo">
          <img src={signuplogo} alt="singinimg" />
          <h2>MyCentrality</h2>
        </div>
        <div className="Addsignup-main-child-1-img">
          <img src={signupimg} alt="img" />
        </div>
        <div className="Addsignup-main-child-1-taxt">
          <h2>MyCentrality Multipurpose Dashboard</h2>
          <p>Everything you need in an easily customizable dashboard.</p>
        </div>
        <div className="Addsignup-main-child-1-dits">
          <div className="dits-white"></div>
          <div className="dits-blue"></div>
          <div className="dits-blue"></div>
        </div>
      </div>
      <div className="Addsignup-main-child-2">
        <div className="Addsignup-main-child-2-title">
          <h2>Sign up for MyCentrality</h2>
          <p>
            Our District onboarding team will be in touch with next steps to
            create an account.
          </p>
        </div>
        <div className="Addsignup-main-child-2-form">
          <div className="Addsignup-main-child-2-input">
            <div className="inputs inputs-2">
              <div className="input">
                <label>First Name</label>
                <input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                  placeholder=""
                />
              </div>
              <div className="input">
                <label>Last Name</label>
                <input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  placeholder=""
                />
              </div>
            </div>
            <div className="inputs inputs-2">
              <div className="input">
                <label>Email</label>
                <input
                  type="text"
                  placeholder=""
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input">
                <label>Phone Number</label>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <FaPhoneAlt />
                  <PhoneInput
                    placeholder="Enter contact number"
                    value={contactNumber}
                    onChange={setContactNumber}
                  />
                </div>
              </div>
            </div>
            <div className="input-3">
              <div className="SignUpPAss">
                <label htmlFor="New Password">Create Password</label>
                <div className="New-Password-input">
                  <input
                    type={showNewPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Create Password"
                  />
                  {showNewPassword ? (
                    <FiEyeOff onClick={() => togglePasswordVisibility("new")} />
                  ) : (
                    <FiEye onClick={() => togglePasswordVisibility("new")} />
                  )}
                </div>
              </div>
              <div className="SihnUp">
                <label htmlFor="New Password">Confirm Password</label>
                <div className="New-Password-input">
                  <input
                    type={showNewPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm Password"
                  />
                  {showNewPassword ? (
                    <FiEyeOff onClick={() => togglePasswordVisibility("new")} />
                  ) : (
                    <FiEye onClick={() => togglePasswordVisibility("new")} />
                  )}
                </div>
              </div>
            </div>
            <div className="inputs inputs-2">
              <div className="input">
                <label>Name of School</label>
                <input
                  value={schoolName}
                  onChange={(e) => setSchoolName(e.target.value)}
                  type="text"
                />
              </div>
              <div className="input">
                <label>Country</label>
                <br />
                <select
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option key={country.isoCode} value={country.isoCode}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="input234">
              <div className="input2345">
                <label>City</label>
                <br />
                {country && (
                  <select
                    value={district}
                    onChange={(e) => setDistrict(e.target.value)}
                  >
                    <option value="">Select Region</option>
                    {regions.map((region) => (
                      <option
                        key={region.isoCode}
                        selected={
                          region.isoCode === selectedRegion ? true : false
                        }
                        value={region.isoCode}
                      >
                        {region.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <div></div>
            </div>
            <div className="Create-btn-my-centrality">
              <button onClick={handleSignup}>
                Create Your MyCentrality account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addsignup;
