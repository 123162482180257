import React from "react";
import "./StudentHeader.css";
import { CiSearch } from "react-icons/ci";
// import { RiEqualizerFill } from "react-icons/ri";
import { RxHamburgerMenu } from "react-icons/rx";
import { TfiLayoutGrid4 } from "react-icons/tfi";
import { FaPlus } from "react-icons/fa6";
import ExcelIcon from "../../images/vscode-icons_file-type-excel2.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import FilterModal from "../../Filter/FilterModal";
import ByDate from "../../ByDate/ByDate";
import { exportToCSV } from "../../../../utils/features";

const StudentHeader = ({
  showTable,
  setShowTable,
  searchValue,
  setSearchValue,
  allStaffs,

  filterFunction = () => {},
}) => {
  const params = useParams();

  return (
    <div className="student-header">
      <h2 className="student-list-title">Staff List</h2>
      <div className="student-content">
        <div className="left-content">
          <div id="searching">
            <CiSearch onClick={filterFunction} className="search-icon" />
            <input
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              placeholder="Search"
            />
          </div>
          <ByDate />

          <FilterModal />
        </div>
        {params?.role !== "parent" ? (
          <div className="switchh">
            <div className="switch-div" onClick={() => setShowTable(true)}>
              <RxHamburgerMenu
                className="hamburger-icon"
                style={{
                  backgroundColor: showTable ? "#00afef" : "#f5f4f6",
                }}
                size={30}
                color={showTable ? "white" : "gray"}
              />
            </div>
            <div
              onClick={() => setShowTable(false)}
              className="layout-grid-icon-div"
            >
              <TfiLayoutGrid4
                className="layout-grid-icon"
                style={{
                  backgroundColor: !showTable ? "#00afef" : "#f5f4f6",
                }}
                size={30}
                color={!showTable ? "white" : "gray"}
              />
            </div>
          </div>
        ) : null}
        <div className="exlimation-icon-div">
          <div className="export-data-div">
            <img
              src={ExcelIcon}
              style={{ width: "20px", height: "20px", objectFit: "contain" }}
            />
            <div
              onClick={() => exportToCSV(allStaffs)}
              style={{ textDecoration: "none" }}
            >
              <p style={{ color: "#148348" }}>EXPORT DATA</p>
            </div>
          </div>
          <Link to="/ImportData?role=teacher" className="import-data-div11">
            <FaPlus color="white" size={20} />
            <p style={{ color: "white", cursor: "pointer" }}>
              Import Staff Data
            </p>
          </Link>
          <Link to="/AddNewStaff" className="import-data-div22">
            <FaPlus color="white" size={20} />
            <p style={{ color: "white", cursor: "pointer" }}>Add new Staff</p>
          </Link>
          <BsThreeDotsVertical size={20} color="#00afef" />
        </div>
      </div>
    </div>
  );
};

export default StudentHeader;
