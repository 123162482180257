import React from "react";
import "./StuProfile.css";
import SixTabs from "./StuSixTabs/SixTabs";

function StuProfile() {
  return (
    <>
      <div className="SixTABSMAin">
        <SixTabs />
      </div>
    </>
  );
}
export default StuProfile;

