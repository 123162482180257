import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Dropdown, Accordion } from "react-bootstrap";
import { baseURL } from "../../../../constants";
import { useSelector } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";
axios.defaults.baseURL = baseURL;

const LineChart = ({ lineData = null }) => {
  const [teacherId, setTeacherId] = useState(null);

  const [years, setYears] = useState([]);

  const perSchool = {
    series: [
      {
        name: "Weekly",
        data: !lineData
          ? [15, 123, 235, 2340, 134]
          : lineData.weekly.map((d) => d),
      },
    ],
    xaxisCategories: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"],
  };
  const perDistrict = {
    series: [
      {
        name: "Weekly",
        data: !lineData
          ? [324, 34, 235, 325, 12]
          : lineData.weekly.map((d) => d),
      },
    ],
    xaxisCategories: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"],
  };
  const perGradeLevel = {
    series: [
      {
        name: "Weekly",
        data: !lineData
          ? [325, 235, 324, 342, 1234]
          : lineData.weekly.map((d) => d),
      },
    ],
    xaxisCategories: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"],
  };
  const perTeacher = {
    series: [
      {
        name: "Weekly",
        data: !lineData
          ? [234, 325, 235, 234, 234]
          : lineData.weekly.map((d) => d),
      },
    ],
    xaxisCategories: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"],
  };
  const perTestType = {
    series: [
      {
        name: "Weekly",
        data: !lineData
          ? [235, 235, 324, 235, 234]
          : lineData.weekly.map((d) => d),
      },
    ],
    xaxisCategories: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"],
  };

  const perSemester = {
    series: [
      {
        name: "Weekly",
        data: !lineData
          ? [35, 234, 235, 235, 235]
          : lineData.weekly.map((d) => d),
      },
    ],
    xaxisCategories: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"],
  };

  const perTrimester = {
    series: [
      {
        name: "Weekly",
        data: !lineData
          ? [13250, 234, 324, 53, 23523]
          : lineData.weekly.map((d) => d),
      },
    ],
    xaxisCategories: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"],
  };

  const perQuarter = {
    series: [
      {
        name: "Weekly",
        data: !lineData
          ? [13250, 235, 23, 23532, 4355]
          : lineData.weekly.map((d) => d),
      },
    ],
    xaxisCategories: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"],
  };

  const [currentTestType, setCurrentTestType] = useState("");
  const perYear = {
    series: [
      {
        name: "Weekly",
        data: !lineData
          ? [13250, 235, 2523, 235, 235325]
          : lineData.weekly.map((d) => d),
      },
    ],

    xaxisCategories: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"],
  };
  const monthlyData = {
    series: [
      {
        name: "Monthly",
        data: !lineData
          ? [325, 35, 235, 25, 23535, 235, 32535, 325, 353]
          : lineData.monthly.map((d) => d),
      },
    ],
    xaxisCategories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
    ],
  };

  const yearlyData = {
    series: [
      {
        name: "Yearly",
        data: !lineData
          ? [325, 23432, 35235, 23532]
          : lineData.yearly.map((d) => d),
      },
    ],
    xaxisCategories: ["2019", "2020", "2021", "2022"],
  };

  const [series, setSeries] = useState(perSchool.series);

  const [options, setOptions] = useState({
    chart: {
      height: 220,
      type: "line",
      zoom: { enabled: false },
    },
    dataLabels: { enabled: false },
    stroke: { curve: "straight" },
    title: { text: "Test Score", align: "left" },
    grid: { row: { colors: ["#f3f3f3", "transparent"], opacity: 0.5 } },
    xaxis: { categories: monthlyData.xaxisCategories },
  });

  const [currentGrade, setCurrentGradeLevel] = useState("");

  const { schoolId } = useSelector((state) => state.schoolId);

  const [testTypes, setTestTypes] = useState([]);

  const getTestTypes = async () => {
    try {
      const { data } = await axios.get(`/Tests/getalltesttype/${schoolId}`, {
        headers: {
          accessToken: localStorage.getItem("token"),
        },
      });
      setTestTypes(data.tests.map((test) => test.category));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTestTypes();
  }, []);

  const [teacher, setTeacher] = useState([]);

  const [selectOptions, setSelectOptions] = useState(null);

  const getTeachers = async () => {
    try {
      const { data } = await axios.get(`/teachers/all/${schoolId}`, {
        headers: {
          accessToken: localStorage.getItem("token"),
        },
      });
      setTeacher(data.teacherList);
      setSelectOptions(
        data.teacherList.map((teacher) => {
          return { label: teacher.staffName, id: teacher.id };
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTeachers();
  }, [schoolId]);

  const [grade, setGrade] = useState([]);

  const getGrade = async () => {
    try {
      const { data } = await axios.get(
        `/students/getAllGradeLevels/${schoolId}`,
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );
      setGrade(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGrade();
  }, [schoolId]);

  const [testType, setTestType] = useState([]);

  const getTestScores = async () => {
    try {
      const { data } = await axios.get(`/tests/getalltesttype${schoolId}`, {
        headers: {
          accessToken: localStorage.getItem("token"),
        },
      });

      setTestType(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTestScores();
  }, [schoolId]);

  const [teacherTestScore, setTeacherTestScore] = useState({});

  const [currentYear, setCurrentYear] = useState("");

  const handleCallTestScore = async (currentApi) => {
    try {
      console.log("eeyah");
      switch (currentApi) {
        case "gradeLevel":
          const { data: gradeData } = await axios.get(
            `/StudentTests/filterbygrade/${schoolId}?gradeLevel=${currentGrade}`,
            {
              headers: {
                accessToken: localStorage.getItem("token"),
              },
            }
          );
          setSeries([
            {
              name: "Test Score",
              data: gradeData?.map((gd) => gd.percentageScore),
            },
          ]);
          setOptions({
            ...options,
            xaxis: {
              ...options.xaxis,
              categories: gradeData?.map((gd) => gd.month),
            },
          });
          break;

        case "teacher":
          console.log("Caleed");
          const { data: teacherData } = await axios.get(
            `/studentTests/getDatabyfiterteacher/${schoolId}?teacherId=${teacherId}`,
            {
              headers: {
                accessToken: localStorage.getItem("token"),
              },
            }
          );

          console.log(teacherData, "Teacher's data");

          setSeries([
            {
              name: "Test Score",
              data: teacherData.datasets[0].data,
            },
          ]);
          setOptions({
            ...options,
            xaxis: { ...options.xaxis, categories: teacherData.labels },
          });

          break;
        case "testType":
          const { data: testTypeData } = await axios.get(
            `/StudentTests/filterbycategory/${7}?category=${currentTestType}`,
            {
              headers: {
                accessToken: localStorage.getItem("token"),
              },
            }
          );

          console.log(testTypeData, "Ya na");

          setSeries([
            {
              name: "Test Score",
              data: testTypeData.datasets[0].data,
            },
          ]);
          setOptions({
            ...options,
            xaxis: { ...options.xaxis, categories: testTypeData.labels },
          });

          break;
        case "year":
          const { data: yearData } = await axios.get(
            `/StudentTests/filterbyyear/${schoolId}?year=${currentYear}`,
            {
              headers: {
                accessToken: localStorage.getItem("token"),
              },
            }
          );

          setSeries([
            {
              name: "Test Score",
              data: yearData.datasets[0].data,
            },
          ]);
          setOptions({
            ...options,
            xaxis: { ...options.xaxis, categories: yearData.labels },
          });

        default:
          break;
      }

      // setOptions({
      //   ...options,
      //   xaxis: { ...options.xaxis, categories: perYear.xaxisCategories },
      // });
      // setOptions({
      //  ...options,
      //   xaxis: {
      //    ...options.xaxis,
      //     categories: data.map((d) => d.week),
      //   },
      // });
    } catch (error) {
      setSeries([
        {
          name: "No Data",
          data: [0],
        },
      ]);
      setOptions({
        ...options,
        xaxis: { ...options.xaxis, categories: ["No Data"] },
      });
    }
  };

  const [allYears, setAllYears] = useState([]);

  const getYears = async () => {
    try {
      const { data } = await axios.get(
        `/studentTests/getCurrentYearQuestionResults`,
        {
          headers: {
            accessToken: localStorage.getItem("token"),
          },
        }
      );

      setAllYears([...new Set(data.map((dt) => dt.createdAt.split("-")[0]))]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getYears();
  }, []);

  return (
    <div style={{ textAlign: "right" }}>
      <div className="NesLineChart">
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Filter
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown>
              <div className="DropDownBAckGround">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>District</Accordion.Header>
                    <Accordion.Body>
                      <div className="LineCheckBox">
                        <input type="checkbox" />
                        <h3>Text</h3>
                      </div>
                      <div className="LineButton">
                        <button>Done</button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Grade Level</Accordion.Header>
                    <Accordion.Body>
                      {grade.map((gra, index) => (
                        <div
                          onClick={() => setCurrentGradeLevel(gra)}
                          className="LineCheckBox"
                          key={index}
                        >
                          <input
                            checked={currentGrade === gra}
                            type="checkbox"
                          />
                          <h3>{gra}</h3>
                        </div>
                      ))}
                      <div className="LineButton">
                        <button
                          disabled={!currentGrade}
                          onClick={() => handleCallTestScore("gradeLevel")}
                        >
                          Done
                        </button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Teacher</Accordion.Header>
                    <Accordion.Body>
                      <div className="CheckTeacher">
                        {selectOptions && (
                          <Autocomplete
                            onChange={(event, newValue) => {
                              if (newValue) {
                                console.log(newValue, "Ye");
                                setTeacherId(newValue.id);
                              } else {
                                setTeacherId(null);
                              }
                            }}
                            disablePortal
                            id="combo-box-demo"
                            options={selectOptions}
                            getOptionLabel={(option) => option.label}
                            sx={{ width: 300 }}
                            value={selectOptions.find(
                              (t) => t.id === teacherId
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                }}
                              />
                            )}
                          />
                        )}

                        <div className="LineButton">
                          <button
                            disabled={!teacherId}
                            onClick={() => handleCallTestScore("teacher")}
                          >
                            Done
                          </button>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Test type</Accordion.Header>
                    <Accordion.Body>
                      {testTypes &&
                        testTypes.map((test, index) => (
                          <div
                            onClick={() => setCurrentTestType(test)}
                            className="LineCheckBox"
                            key={index}
                          >
                            <input
                              checked={currentTestType === test}
                              type="checkbox"
                            />
                            <h3>{test}</h3>
                          </div>
                        ))}
                      <div className="LineButton">
                        <button
                          disabled={!currentTestType}
                          onClick={() => handleCallTestScore("testType")}
                        >
                          Done
                        </button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Semester</Accordion.Header>
                    <Accordion.Body>
                      <div className="LineCheckBox">
                        <input type="checkbox" />
                        <h3>Text</h3>
                      </div>
                      <div className="LineButton">
                        <button onClick={handleCallTestScore}>Done</button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Trimester</Accordion.Header>
                    <Accordion.Body>
                      <div className="LineCheckBox">
                        <input type="checkbox" />
                        <h3>Text</h3>
                      </div>
                      <div className="LineButton">
                        <button onClick={handleCallTestScore}>Done</button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Quarter</Accordion.Header>
                    <Accordion.Body>
                      <div className="LineCheckBox">
                        <input type="checkbox" />
                        <h3>Text</h3>
                      </div>
                      <div className="LineButton">
                        <button onClick={handleCallTestScore}>Done</button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Year</Accordion.Header>
                    <Accordion.Body>
                      {allYears?.map((year) => {
                        return (
                          <div
                            onClick={() => setCurrentYear(year)}
                            className="LineCheckBox"
                          >
                            <input
                              checked={currentYear === year}
                              type="checkbox"
                            />
                            <h3>{year}</h3>
                          </div>
                        );
                      })}
                      <div className="LineButton">
                        <button
                          disabled={!currentYear}
                          onClick={() => handleCallTestScore("year")}
                        >
                          Done
                        </button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Dropdown>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={320}
        />
      </div>
    </div>
  );
};

export default LineChart;
