import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "./NoticeBoard.css";
import NewNotice from "../../../NEwNotice/NewNotice";
import axios from "axios";
import { baseURL } from "../../../../../../constants";
axios.defaults.baseURL = baseURL;

function NoticeBoard({ data = [], func = () => { } }) {
  return (
    <div className="notiss">
      <div className="NotisBoradBtn">
        <NewNotice func={func} />
      </div>
      {data &&
        data?.map((notice, index) => {
          return (
            <Accordion defaultActiveKey="0" key={index}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Announcement</Accordion.Header>
                <Accordion.Body>
                  <span>“ Notice:</span>
                  <p>
                    Sub: {notice?.title} <br />
                    {notice?.description}
                    <span>”</span>
                    <span className="span-2">
                      {notice?.date?.split("T")[0]}
                    </span>
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          );
        })}
    </div>
  );
}

export default NoticeBoard;
