export const MygradeData = [
  {
    id: 1,
    gradeTitle: "Articulate structure of C++ and Java in Semester 1",
    result: "failed",
    subject: "Gis Programming",
    date: "3-01-2023",
    time: "12:30 AM - 01:40 PM",
    gradetype: "Test",
    passingPercentage: 70,
    scoredPercentage: 65,
    buttonColor: "#F93333",
    gradebc: "blue",
  },
  {
    id: 2,
    gradeTitle: "Articulate structure of C++ and Java in Semester 1",
    result: "Missed",
    subject: "Gis Programming",
    date: "4-01-2023",
    time: "10:00 AM - 11:40 AM",
    gradetype: "Assignment",
    passingPercentage: 70,
    scoredPercentage: 20,
    buttonColor: "#9A9A9A",
    gradebc: "#E6E6E6",
  },
  {
    id: 3,
    result: "Passed",
    gradeTitle: "Articulate structure of C++ and Java in Semester 1",
    subject: "Gis Programming",
    date: "4-01-2023",
    time: "10:00 AM - 11:40 AM",
    gradetype: "Presentation",
    passingPercentage: 70,
    scoredPercentage: 30,
    buttonColor: "#11A529",
    gradebc: "#EFFFF5",
  },
  {
    id: 4,
    result: "Failed",
    gradeTitle: "Articulate structure of C++ and Java in Semester 1",
    subject: "Gis Programming",
    date: "3-01-2023",
    time: "12:30 AM - 01:40 PM",
    passingPercentage: 70,
    scoredPercentage: 65,
  },
  {
    id: 5,
    result: "Missed",
    gradeTitle: "Articulate structure of C++ and Java in Semester 1",
    subject: "Gis Programming",
    date: "4-01-2023",
    time: "10:00 AM - 11:40 AM",
    passingPercentage: 70,
    scoredPercentage: 80,
    buttonColor: "#9A9A9A",
    gradebc: "#E6E6E6",
  },
  {
    id: 6,
    result: "Passed",
    gradeTitle: "Articulate structure of C++ and Java in Semester 1",
    subject: "Gis Programming",
    date: "4-01-2023",
    time: "10:00 AM - 11:40 AM",
    passingPercentage: 70,
    scoredPercentage: 65,
    buttonColor: "#11A529",
    gradebc: "#EFFFF5",
  },
];
