import React, { useState, useEffect } from "react";
import axios from "axios";
import { GoPeople } from "react-icons/go";
import { PiChartBarHorizontalDuotone } from "react-icons/pi";
import { PiNotebookDuotone } from "react-icons/pi";
import "./TopADDCard.css";
import { Link } from "react-router-dom";
import { baseURL } from "../../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;
function TopADDCards() {
  const [cardData, setCardData] = useState(null);

  const { schoolId } = useSelector((state) => state.schoolId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (schoolId) {
          const { data } = await axios.get(`/schools/statistics/${schoolId}`, {
            headers: {
              accessToken: `${localStorage.getItem("token")}`,
            },
          });
          setCardData(data);
        }
      } catch (error) {
        console.error(error.response.data.message);
      }
    };

    fetchData();
  }, [schoolId]);

  return (
    cardData && (
      <>
        <div className="ADDtopCards1dmin">
          <Link to="/CardViewMain/student" className="ADDtopCards-1">
            <div className="ADDtopCards-1-svg">
              <GoPeople />
            </div>
            <div>
              <h2>Student</h2>
              <p>{cardData?.students}</p>
            </div>
          </Link>
          <Link to="/StaffView" className="ADDtopCards-2">
            <div className="ADDtopCards-2-svg">
              <PiChartBarHorizontalDuotone />
            </div>
            <div>
              <h2>Staff</h2>
              <p>{cardData?.staff}</p>
            </div>
          </Link>
          <Link to="/ParentTable" className="ADDtopCards-3">
            <div className="ADDtopCards-3-svg">
              <PiNotebookDuotone />
            </div>
            <div>
              <h2>Parents</h2>
              <p>{cardData?.parents}</p>
            </div>
          </Link>
        </div>
      </>
    )
  );
}

export default TopADDCards;
