import React, { useState, useEffect } from "react";
import "./ADDSchool.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { Country, State } from "country-state-city";
import Dummy from "../../images/images.png";
import { baseURL } from "../../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;
const AddSchoolinfo = () => {
  const [schoolInfo, setSchoolInfo] = useState(null);

  const { schoolId } = useSelector((state) => state.schoolId);

  useEffect(() => {
    const fetchSchoolInfo = async () => {
      try {
        if (schoolId) {
          const { data } = await axios.get(
            `/schools/getschoolById/${schoolId}`,
            {
              headers: {
                accessToken: `${localStorage.getItem("token")}`,
              },
            }
          );
          setSchoolInfo(data.school);
        }
      } catch (error) {
        console.error("Error fetching school information:", error);
      }
    };

    fetchSchoolInfo();
  }, [schoolId]);

  const handleInputChange = (field, value) => {
    setSchoolInfo((prevSchoolInfo) => ({
      ...prevSchoolInfo,
      [field]: value,
    }));
  };

  const handleUpdateSchoolInfo = async () => {
    try {
      const { data } = await axios.post(`/api/updateSchoolInfo`, schoolInfo, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
    } catch (error) {
      console.error("Error updating school information:", error);
    }
  };

  return (
    schoolInfo && (
      <div className="AddSchoolinfo-main">
        <div className="General-Information-main">
          <div className="General-Information-main-child-1">
            <img
              src={`${baseURL}/images/${schoolInfo?.logo}`}
              alt="schoollogo"
              onError={({ currentTarget }) => {
                currentTarget.src = Dummy;
              }}
            />
            <h2>{schoolInfo?.schoolLevel}</h2>
            <textarea readOnly>{schoolInfo?.description}</textarea>
          </div>
          <div className="General-Information-main-child-2">
            <div className="General-Information-main-child-2-title-editbutton">
              <h2>General Information</h2>
              <Link to="/AddnewSchool1">
                <button onClick={handleUpdateSchoolInfo}>
                  Edit Information
                </button>
              </Link>
            </div>
            <hr className="General-Information-main-child-2-title-editbutton-hr" />
            <div className="General-Information-main-child-2-1">
              <div className="General-Information-main-child-2-input-and-label">
                <div className="input-and-label">
                  <label>School Name</label> <br />
                  <input readOnly value={schoolInfo?.name} />
                </div>
                <div className="input-and-label">
                  <label>Alternative Name: </label> <br />
                  <input readOnly value={schoolInfo?.alternativeName} />
                </div>
                <div className="input-and-label">
                  <label>School ID: </label> <br />
                  <input readOnly value={schoolInfo?.schoolId} />
                </div>
                <div className="input-and-label">
                  <label>School Alternate ID: </label> <br />
                  <input readOnly value={schoolInfo?.alternativeId} />
                </div>
              </div>
              <div className="General-Information-main-child-2-input-and-label">
                <div className="input-and-label">
                  <label>State ID: </label> <br />
                  <input readOnly value={schoolInfo?.stateId} />
                </div>
                <div className="input-and-label">
                  <label>District ID: </label> <br />
                  <input readOnly value={schoolInfo?.districtId} />
                </div>
                <div className="input-and-label">
                  <label>School Classification: </label> <br />
                  <input readOnly value={schoolInfo?.schoolClassification} />
                </div>
              </div>
              <div className="General-Information-main-child-2-input-and-label">
                <div className="input-and-label">
                  <label>Lowest Grade Level: </label> <br />
                  <input readOnly value={schoolInfo?.lowestGradeLevel} />
                </div>
                <div className="input-and-label">
                  <label>Highest Grade Level: </label> <br />
                  <input readOnly value={schoolInfo?.highestGradeLevel} />
                </div>

                <div className="input-and-label">
                  <label>Date School Opened: </label> <br />
                  <input readOnly value={schoolInfo?.openedOn?.split("T")[0]} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-hr" />
        <div className="AddSchoolinfo-main-child-2">
          <div className="AddSchoolinfo-main-child-2-AddressInformation">
            <h2>Address Information</h2>
            <div className="General-Information-main-child-2-input-and-label">
              <div>
                <label>Street Address 1: </label> <br />
                <input readOnly value={schoolInfo?.address1} />
              </div>
              <div>
                <label>Street address 2: </label> <br />
                <input readOnly value={schoolInfo?.address2} />
              </div>
            </div>
            <div className="General-Information-main-child-2-input-and-label">
              <div>
                <label>State/Region/Province: </label> <br />
                <input
                  readOnly
                  value={
                    State?.getStateByCode(schoolInfo?.stateOrRegionOrProvince)
                      ?.name || ""
                  }
                />
              </div>
              <div>
                <label>District: </label> <br />
                <input readOnly value={schoolInfo?.district} />
              </div>
            </div>
            <div className="General-Information-main-child-2-input-and-label">
              <div>
                <label>Country: </label> <br />
                <input
                  readOnly
                  value={
                    Country?.getCountryByCode(schoolInfo?.country)?.name || ""
                  }
                />
              </div>
            </div>
          </div>
          <div className="AddSchoolinfo-main-child-2-Contact-Information">
            <h2>Contact Information</h2>
            <div className="General-Information-main-child-2-input-and-label">
              <div>
                <label>Principal: </label> <br />
                <input readOnly value={schoolInfo?.principal} />
              </div>
              <div>
                <label>Assistant Principal: </label> <br />
                <input readOnly value={schoolInfo?.assistantPrincipal} />
              </div>
              <div>
                <label>Telephone: </label> <br />
                <input readOnly value={schoolInfo?.telephone} />
              </div>
            </div>

            <div className="General-Information-main-child-2-input-and-label">
              <div>
                <label>Personal Number: </label> <br />
                <input readOnly value={schoolInfo?.personalNumber} />
              </div>
              <div>
                <label>email: </label> <br />
                <input readOnly value={schoolInfo?.email} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
export default AddSchoolinfo;
