import axios from "axios";
import { baseURL } from "../../constants";
axios.defaults.baseURL = baseURL;

export const getParentStudents = (id) => async (dispatch) => {
  try {
    dispatch({ type: "getParentStudentsRequest" });
    const { data } = await axios.get(
      `/relations/getRelationsByParentId/${id}`,
      {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      }
    );

    dispatch({ type: "getParentStudentsSuccess", payload: data?.relations });
  } catch (error) {
    dispatch({
      type: "getParentStudentsFailure",
      payload: error?.response?.data?.error,
    });
  }
};

export const getAllSchools = (user) => async (dispatch) => {
  try {
    dispatch({ type: "getAllSchoolsRequest" });
    if (user) {
      const { data } = await axios.get(
        `/schools/getAllSchoolsByDistrictId/${user?.dataValues?.districtId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      dispatch({ type: "getAllSchoolsSuccess", payload: data?.schools });
    }
  } catch (error) {
    console.log(error, "failed to load school");
    dispatch({ type: "getAllSchoolsFailure", payload: error });
  }
};
export const getStudentId = (studentId) => (dispatch) => {
  dispatch({ type: "studentId", payload: studentId });
  sessionStorage.setItem("studentId", studentId);
};

export const getMedicalStudentId = (studentId) => (dispatch) => {
  dispatch({ type: "medicalStudentId", payload: studentId });
  sessionStorage.setItem("medicalStudentId", studentId);
};

export const getMedicalHistoryStudentId = (studentId) => (dispatch) => {
  dispatch({ type: "medicalHistoryStudentId", payload: studentId });
  sessionStorage.setItem("medicalHistoryStudentId", studentId);
};

export const addSchoolId = (schoolId) => (dispatch) => {
  dispatch({ type: "addSchoolId", payload: schoolId });
  sessionStorage.setItem("myschool", schoolId);
};
export const getUserProfile = () => async (dispatch) => {
  try {
    dispatch({ type: "getUserProfileRequest" });
    const { data } = await axios.get("/loginprofile/getuser", {
      headers: {
        accessToken: `${localStorage.getItem("token")}`,
      },
    });

    dispatch({ type: "getUserProfileSuccess", payload: data });
  } catch (error) {
    dispatch({ type: "getUserProfileFailure", payload: error });
  }
};

export const addEvent = (formdata) => async (dispatch) => {
  try {
    dispatch({ type: "addEventRequest" });
    await axios.post("/events/addEvent", formdata, {
      headers: {
        accessToken: `${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    });

    dispatch({ type: "addEventSuccess", payload: "Event Added Successfully" });
  } catch (error) {
    console.log(error, "Goter");
    dispatch({
      type: "addEventFailure",
      payload:
        error?.response?.data?.details?.[0].detail ||
        error?.response?.data?.details?.[0].message ||
        error?.response?.data?.error ||
        "Failed to add event",
    });
  }
};

export const addStudentEvent = (formdata) => async (dispatch) => {
  try {
    dispatch({ type: "addEventRequest" });
    await axios.post("/events/addEventforstudent", formdata, {
      headers: {
        accessToken: `${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    });

    dispatch({ type: "addEventSuccess", payload: "Event Added Successfully" });
  } catch (error) {
    console.log(error, "Goter");
    dispatch({
      type: "addEventFailure",
      payload:
        error?.response?.data?.details?.[0].detail ||
        error?.response?.data?.details?.[0].message ||
        error?.response?.data?.error ||
        "Failed to add event",
    });
  }
};

export const addParentEvent = (formdata) => async (dispatch) => {
  try {
    dispatch({ type: "addEventRequest" });

    await axios.post("/events/addEventforParent", formdata, {
      headers: {
        accessToken: `${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    });

    dispatch({ type: "addEventSuccess", payload: "Event Added Successfully" });
  } catch (error) {
    console.log(error, "Goter");
    dispatch({
      type: "addEventFailure",
      payload:
        error?.response?.data?.details?.[0].detail ||
        error?.response?.data?.details?.[0].message ||
        error?.response?.data?.error ||
        "Failed to add event",
    });
  }
};

export const updateEvent = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: "updateEventRequest" });
    await axios.put(`/events/updateEvent/${id}`, formData, {
      headers: {
        accessToken: `${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    });

    dispatch({
      type: "updateEventSuccess",
      payload: "Event Updated Successfully",
    });
  } catch (error) {
    dispatch({
      type: "updateEventFailure",
      payload:
        error?.response?.data?.details?.[0].detail ||
        error?.response?.data?.details?.[0].message ||
        error?.response?.data?.error ||
        "Failed to add event",
    });
  }
};

export const getEvents = (schoolId) => async (dispatch) => {
  try {
    dispatch({ type: "getEventsRequest" });
    const { data } = await axios.get(`/events/getSchoolEvents/${schoolId}`, {
      headers: {
        accessToken: `${localStorage.getItem("token")}`,
      },
    });

    dispatch({
      type: "getEventsSuccess",
      payload:
        data?.events?.map((event) => {
          return {
            ...event,
            enddate: new Date(event?.enddate),
            startdate: new Date(event?.startdate),
          };
        }) || [],
    });
  } catch (error) {
    console.log(error, "dasfkadsjlgkjasd;lgkjas;ldkgjas;dlkj");
    dispatch({
      type: "getEventsFailure",
      payload: "Failed to get events",
    });
  }
};

export const deleteEvent = (eventId) => async (dispatch) => {
  try {
    dispatch({ type: "deleteEventRequest" });
    const { data } = await axios.delete(`/events/removeEvent/${eventId}`, {
      headers: {
        accessToken: `${localStorage.getItem("token")}`,
      },
    });

    dispatch({
      type: "deleteEventSuccess",
      payload: "Event Deleted Succcessfully",
    });
  } catch (error) {
    dispatch({
      type: "deleteEventFailure",
      payload: "Failed to get events",
    });
  }
};

export const getStudentEvents = (schoolId) => async (dispatch) => {
  try {
    dispatch({ type: "getEventsRequest" });
    const { data } = await axios.get(
      `/events/getVisiblestudentsEvents/${schoolId}`,
      {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      }
    );

    dispatch({
      type: "getEventsSuccess",
      payload:
        data?.events?.map((event) => {
          return {
            ...event,
            enddate: new Date(event?.enddate),
            startdate: new Date(event?.startdate),
          };
        }) || [],
    });
    console.log(data, "dasfkadsjlgkjasd;lgkjas;ldkgjas;dlkj");
  } catch (error) {
    console.log(error, "dasfkadsjlgkjasd;lgkjas;ldkgjas;dlkj");
    dispatch({
      type: "getEventsFailure",
      payload: "Failed to get events",
    });
  }
};

export const getParentEvents = (schoolId) => async (dispatch) => {
  try {
    dispatch({ type: "getEventsRequest" });
    const { data } = await axios.get(
      `/events/getVisibleparentsEvents/${schoolId}`,
      {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      }
    );

    dispatch({
      type: "getEventsSuccess",
      payload:
        data?.events?.map((event) => {
          return {
            ...event,
            enddate: new Date(event?.enddate),
            startdate: new Date(event?.startdate),
          };
        }) || [],
    });
    console.log(data, "dasfkadsjlgkjasd;lgkjas;ldkgjas;dlkj");
  } catch (error) {
    console.log(error, "dasfkadsjlgkjasd;lgkjas;ldkgjas;dlkj");
    dispatch({
      type: "getEventsFailure",
      payload: "Failed to get events",
    });
  }
};
