import "./ImportData.css";
import DownloadExcelImage from "../../../images/Group 1000006269.png";
import { IoIosPlay } from "react-icons/io";
import MapExcelIcon from "../../../images/map-excel.png";
import MapPreviewIcon from "../../../images/map-preview.png";
import CloudComputingIcon from "../../../images/cloud-computing.png";
import ImportToDatabaseIcon from "../../../images/import-to-database.png";
import UploadFileIcon from "../../../images/UpGroup.png";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { baseURL } from "../../../../../constants";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;

const ImportData = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [role, setRole] = useState("");
  const imageRef = useRef(null);

  const { schoolId } = useSelector((state) => state.schoolId);

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleImageSelection = () => {
    imageRef.current.click();
  };

  useEffect(() => {
    const roleFromUrl = window.location.search?.split("=")[1];
    if (roleFromUrl === "parent") {
      setRole("parent");
    } else if (roleFromUrl === "student") {
      setRole("student");
    } else if (roleFromUrl === "teacher") {
      setRole("teacher");
    } else {
      setRole("");
    }
  }, [window.location.search]);

  const [loading, setLoading] = useState(false);

  const uploadCsvHandler = async () => {
    try {
      setLoading(true);
      const newForm = new FormData();
      newForm.append("file", selectedFile);
      newForm.append("schoolId", schoolId);

      const headers = {
        "Content-Type": "multipart/form-data",
        accessToken: `${localStorage.getItem("token")}`,
        school_id: localStorage.getItem("schoolId"),
      };

      if (role === "teacher") {
        await axios.post(`/teachers/bulkAddTeachers`, newForm, { headers });
      } else if (role === "parent") {
        await axios.post(`/parents/bulkAddParents`, newForm, { headers });
      } else if (role === "student") {
        await axios.post(`/students/bulkAddStudents`, newForm, { headers });
      } else {
        toast.error("Select a role first");
      }

      toast.success("CSV File Uploaded");
      setLoading(false);
    } catch (error) {
      console.log(error, "Failed to upload file");
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div className="import-data-container">
      <div className="import-data-upper-div">
        <p>Student List</p>
        <IoIosPlay color="#c2c6ce" />
        <p>Import Data</p>
      </div>
      <div className="import-data-upper-section">
        <p className="import-data-step-1">Step 1</p>
        <div className="import-data-first-section-images-div">
          <img className="download-excel-image" src={DownloadExcelImage} />
        </div>
        <p>
          Click here to generate and download the data import template in Excel.
          Add your data to this Tamplete and upload the file.{" "}
          <span>Do not rename any column headers.</span>
        </p>
      </div>
      <div className="import-data-upload-cards">
        <div className="import-data-upload-card import-data-first-upload-card">
          <span className="import-data-upload-steps">Step 2</span>
          <img src={CloudComputingIcon} className="import-data-upload-icons" />
          <p className="upload-file-p">Upload your file</p>
          <p className="upload-file-second-p">Upload xls,xlsx or csv</p>
        </div>
        <div className="import-data-upload-card">
          <span style={{ bottom: 18 }} className="import-data-other-steps">
            Step 3
          </span>
          <img src={MapExcelIcon} className="import-data-upload-icons" />
          <p className="upload-file-p">Map Your Fields</p>
          <div>
            <p className="upload-file-second-p">Map your spreadsheet</p>
            <p className="upload-file-second-p">
              columns with database fields csv
            </p>
          </div>
        </div>
        <div className="import-data-upload-card">
          <span className="import-data-other-steps">Step 4</span>
          <img src={MapPreviewIcon} className="import-data-upload-icons" />
          <p className="upload-file-p">Upload your file</p>
          <p className="upload-file-second-p">
            Review your mapping before your proceed to import
          </p>
        </div>
        <div className="import-data-upload-card">
          <span className="import-data-other-steps">Step 5</span>
          <img
            src={ImportToDatabaseIcon}
            className="import-data-upload-icons"
          />
          <p className="upload-file-p">Upload your file</p>
          <p className="upload-file-second-p">
            Finally, import your data and see results
          </p>
        </div>
      </div>
      <div className="upload-file-section">
        <div
          className="upload-file-section-content"
          onClick={handleImageSelection}
        >
          <img src={UploadFileIcon} className="upload-file-image" />
          <div className="upload-file-section-texts">
            <p style={{ color: "black", fontWeight: "bold" }}>
              Upload file from your Local Drive
            </p>
            <p>
              {selectedFile
                ? selectedFile?.name
                : "Click here to select a file or drop the file here"}{" "}
            </p>
            <input
              type="file"
              style={{ display: "none" }}
              ref={imageRef}
              name=""
              accept=".csv"
              onChange={handleFileSelect}
              id=""
            />
          </div>
        </div>
        <div className="upload-file-button-container">
          <button
            disabled={loading}
            onClick={uploadCsvHandler}
            className="upload-file-button"
          >
            {loading ? "Uploading..." : "Upload File"}
          </button>
        </div>
      </div>
      <p style={{ textAlign: "center", padding: "2rem" }}>
        © 2023 MyCentrality. All Rights Reserved.
      </p>
    </div>
  );
};

export default ImportData;
