import React from "react";
import "./Attendence.css";
import { IoIosContacts } from "react-icons/io";
import { MdOutlineSlowMotionVideo } from "react-icons/md";
import { PiChartBarHorizontal } from "react-icons/pi";
import { PiNotebookDuotone } from "react-icons/pi";
import BarChartComponent from "../Chart/BarChartComponent";
import ByDate from "../ByDate/ByDate";
import FilterModal from "../ByDate/Filter/FilterModal";
import { CiSearch } from "react-icons/ci";
import Order from "../order/Order";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../../constants";
import { useParams } from "react-router-dom";
import { getUserProfile } from "../../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;
const Mycarddata = [
  {
    icon: <IoIosContacts className="icons" color="#FF5733" />,
    cardtitle: "Total",
    backgroundColor: "#FFF0F0",
  },
  {
    icon: <MdOutlineSlowMotionVideo className="icons" color="#00AFEF" />,
    cardtitle: "present",
    backgroundColor: "rgba(0, 175, 239,0.20)",
  },
  {
    icon: <PiChartBarHorizontal className="icons" color="#23BD33" />,
    cardtitle: "Absent",
    backgroundColor: "#E1F7E3",
  },
  {
    icon: <PiNotebookDuotone className="icons" color="#FD8E1F" />,
    cardtitle: "Excused",
    backgroundColor: "#FFF2E5",
  },
];
function Attendence() {
  const [data, setData] = useState(null);

  const params = useParams();
  const [attendancesList, setAttendancesList] = useState(null);

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.getUserProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {}, [window.location.search]);
  useEffect(() => {
    async function getAttendances() {
      try {
        if (user) {
          const { data } = await axios.get(
            `/attendances/getAttendanceAnalyticsByStudentAndSubject/${
              user.dataValues.id
            }/${window.location.search.split("=")[1]}`,
            {
              headers: {
                accessToken: `${localStorage.getItem("token")}`,
              },
            }
          );

          setData(data);
        }
      } catch (error) {
        setData(null);
        console.log(error);
      }
    }
    async function getAttendancesList() {
      try {
        if (user) {
          const { data } = await axios.get(
            `/attendances/getAttendanceByStudentAndSubject/${
              user.dataValues.id
            }/${window.location.search.split("=")[1]}`,
            {
              headers: {
                accessToken: `${localStorage.getItem("token")}`,
              },
            }
          );

          setAttendancesList(data);
        }
      } catch (error) {
        setAttendancesList(null);
        console.log(error);
      }
    }
    getAttendances();
    getAttendancesList();
  }, [window.location.search, user]);

  return (
    <>
      <div className="Attendence-card-main">
        <div className="Mycard-main">
          <div className="Mycard-main-child-1">
            <div
              className="my-icon"
              style={{
                backgroundColor: "#FFF0F0",
              }}
            >
              <IoIosContacts className="icons" color="#FF5733" />
            </div>
          </div>
          <div className="Mycard-main-child-2">
            <h2>Total</h2>
            <p>{data?.totalDays}</p>
          </div>
        </div>
        <div className="Mycard-main">
          <div className="Mycard-main-child-1">
            <div
              className="my-icon"
              style={{
                backgroundColor: "rgba(0, 175, 239,0.20)",
              }}
            >
              <MdOutlineSlowMotionVideo className="icons" color="#00AFEF" />
            </div>
          </div>
          <div className="Mycard-main-child-2">
            <h2>Present</h2>
            <p>{data?.totalPresentDays}</p>
          </div>
        </div>
        <div className="Mycard-main">
          <div className="Mycard-main-child-1">
            <div
              className="my-icon"
              style={{
                backgroundColor: "#E1F7E3",
              }}
            >
              <PiChartBarHorizontal className="icons" color="#23BD33" />
            </div>
          </div>
          <div className="Mycard-main-child-2">
            <h2>Excused</h2>
            <p>{data?.totalExcusedDays}</p>
          </div>
        </div>
        <div className="Mycard-main">
          <div className="Mycard-main-child-1">
            <div
              className="my-icon"
              style={{
                backgroundColor: "#FFF2E5",
              }}
            >
              <PiNotebookDuotone className="icons" color="#FD8E1F" />
            </div>
          </div>
          <div className="Mycard-main-child-2">
            <h2>Absent</h2>
            <p>{data?.totalAbsentDays}</p>
          </div>
        </div>
      </div>
      <div className="Attendence-card-main-chart">
        <BarChartComponent />
      </div>
      <div className="ByDate">
        <h3>Attendance Summery</h3>
        <div className="Main">
          <div className="input">
            <CiSearch />
            <input placeholder="Search" type="text" />
          </div>
          <div className="datePicker">
            {/* <div className="DaTe">
              <input type="date" />
            </div> */}
            <ByDate />
          </div>
          <div className="mAin">
            <FilterModal />
          </div>
        </div>
      </div>
      {attendancesList && <Order data={attendancesList} />}
    </>
  );
}

export default Attendence;
