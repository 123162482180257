import { useState } from "react";
import { Link } from "react-router-dom";
import EventCard from "./EventCard";
const Events = ({ events = [] }) => {
  const [isSeeMore, setIsSeeMore] = useState(false);
  return (
    <div
      style={{
        height: "calc(100vh - 150px)",
        overflowX: "hidden",
        overflowY: "auto",
      }}
      className="My-Events-Containers"
    >
      <Link to="/ParentAddEvent">
        <button className="event-add-button">
          <span> + </span>Add New Event
        </button>
      </Link>

      <h5 className="calendar-upcoming-events-text">Upcoming Events</h5>
      <div className="calendar-event-border-line" />

      <div className="calendar-event-cards">
        {!isSeeMore
          ? events?.slice(0, 3)?.map((event) => {
              return <EventCard event={event} />;
            })
          : events?.map((event) => {
              return <EventCard event={event} />;
            })}
      </div>
      <div className="event-card-footer">
        <button onClick={() => setIsSeeMore(!isSeeMore)}>
          {isSeeMore ? "Show Less" : "Show More"}
        </button>
      </div>
    </div>
  );
};

export default Events;
