import { useEffect, useState } from "react";
import StudentHeader from "../StudentHeader/StudentHeader";
import "./StudentsView.css";
import TableView from "../TableView/TableView";
import axios from "axios";
import { baseURL } from "../../../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;
const StudentsView = () => {
  const [tableData, setTableData] = useState(null);

  const { schoolId } = useSelector((state) => state.schoolId);
  const [allParents, setAllParents] = useState([])
  const getParentTableData = async () => {
    try {
      if (schoolId) {
        const { data } = await axios.get(`/parents/getParents/${schoolId}`, {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        });

        const newArr = data?.parents?.map((d) => {
          return {
            parentName: `${d?.firstName} ${d?.middleName} ${d?.lastName}`,
            relationship: d?.userProfile,
            personalEmail: d?.email,
            mobileNumber: d?.personalNumber,
            // associatedStudent: `${d?.Students[0]?.firstName} ${d?.Students[0]?.lastName}`,
            image: d?.profilePicture,
            id: d.id,
          };
        });
        setTableData(newArr);
        setAllParents(data?.parents);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getParentTableData();
  }, [schoolId]);

  const [searchValue, setSearchValue] = useState("");

  const [parentData, setParentData] = useState([]);

  useEffect(() => {
    if (tableData) {
      setParentData(tableData);
    }
  }, [tableData]);

  const handleSearch = () => {
    if (searchValue) {
      setParentData(
        tableData.filter((d) => {
          return d?.parentName
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase());
        })
      );
    } else {
      setParentData(tableData);
    }
  };

  useEffect(() => {
    if (tableData) {
      handleSearch();
    }
  }, [searchValue, tableData]);
  return (
    <div className="students-view">
      <StudentHeader
        filterFunction={handleSearch}
        setSearchValue={setSearchValue}
        value={searchValue}
        allParents={allParents}
      />
      {parentData && (
        <TableView
          getParentTableData={getParentTableData}
          tableData={parentData}
          link={"/Addparant"}
        />
      )}
    </div>
  );
};

export default StudentsView;
