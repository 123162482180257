import React, { useEffect, useState } from "react";
import "./AddUsersActive.css";
import AddUsersActiveCom from "./AddUsersActiveCom";
import AddUsersActiveData from "./AddUsersActiveData";
import axios from "axios";
import { baseURL } from "../../../../../constants";
axios.defaults.baseURL = baseURL;
const AddUsersActive = () => {
  const [activeUsers, setActiveUsers] = useState(null);
  const getDailyActiveUsers = async () => {
    try {
      const { data } = await axios.get("/dashboards/getDashboard", {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setActiveUsers(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDailyActiveUsers();
  }, []);
  return (
    <div className="userActives">
      {AddUsersActiveData.map((userData, index) => (
        <AddUsersActiveCom
          key={index}
          {...userData}
          amount={AddUsersActiveData[index].dailyActiveUsers}
          lastWeek={AddUsersActiveData[index].lastWeek}
          color={userData.color}
          backgroundColor={userData.backgroundColor}
        />
      ))}
    </div>
  );
};

export default AddUsersActive;
