import React from "react";
import "./ToggleFilterButton.css";
import { FaCaretRight } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { LiaSlidersHSolid } from "react-icons/lia";
import { TbGridDots } from "react-icons/tb";
import { VscThreeBars } from "react-icons/vsc";
import { Link } from "react-router-dom";

export const ToggleFilterButton = ({ mySwitch, setMySwitch }) => {

  return (
    <div className="ToggleFilterbtn-main">
      <div className="ToggleFilterbtn-main-child-1">
        <h3>List of Schools</h3>
        <FaCaretRight className="ToggleFilterbtn-main-right-icon" />
      </div>
      <div className="ToggleFilterbtn-main-child-2">
        <div className="ToggleFilterbtn-main-child-2-main-1">
          <div className="ToggleFilterbtn-main-child-2-search">
            <IoIosSearch className="ToggleFilterbtn-main-child-2-search-IoIosSearch" />

            <div>
              <input type="text" id="text" placeholder="Search ." />
            </div>
          </div>
          <Link className="ToggleFilterbtn-main-child-2-setting">
            <LiaSlidersHSolid />
            <div>
              <p>Filters</p>
            </div>
          </Link>
        </div>
        <div className="ToggleFilterbtn-main-child-4">
          <div className="toggle-card">
            <div
              style={{
                backgroundColor: mySwitch ? "#00afef" : "white",
                color: mySwitch ? "white" : "black",
              }}
              className="TbGridDots-icon"
            >

              <TbGridDots onClick={() => setMySwitch(true)} />
            </div>
            <div
              style={{
                backgroundColor: !mySwitch ? "#00afef" : "white",
                color: !mySwitch ? "white" : "black",
              }}
              className="VscThreeBars-icon TbGridDots-icon"
            >
              <VscThreeBars onClick={() => setMySwitch(false)} />
            </div>
          </div>
          <div className="ToggleFilterbtn-main-child-4-btnn">
            <Link to="/ADDNewSchool">Add new School</Link>
          </div>
        </div>
      </div>
    </div>
  );
};