import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./ManageAcModal.css";
import Form from "react-bootstrap/Form";
import { IoAdd } from "react-icons/io5";
import toast from "react-hot-toast";
import axios from "axios";
import { baseURL } from "../../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

function ManageAcModal({
  getPrograms = () => {},
  isEdit = false,
  setIsEdit = () => {},
}) {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const [programCode, setProgramCode] = useState("");
  const [programDescription, setProgramDescription] = useState("");
  const [programDuration, setProgramDuration] = useState("");
  const [programName, setProgramName] = useState("");
  const [action, setAction] = useState("");

  const handleClose = () => {
    setShow(false);
    setIsEdit("");
    setProgramCode("");
    setProgramDescription("");
    setProgramDuration("");
    setProgramName("");
    setAction("");
  };

  const [program, setProgram] = useState(null);


  const getProgram = async () => {
    try {
      const { data } = await axios.get(`/programs/getProgramById/${isEdit}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      setProgram(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (isEdit) {
      handleShow();
    }
  }, [isEdit]);

  useEffect(() => {
    if (program) {
      setProgramCode(program?.programCode);
      setProgramDescription(program?.programDescription);
      setProgramDuration(program?.programDuration);
      setProgramName(program?.programName);
      setAction(program?.isActive);
    }
  }, [program]);

  useEffect(() => {
    getProgram();
  }, [isEdit]);
  const { schoolId } = useSelector((state) => state.schoolId);

  const handleAddProgram = async () => {
    try {
      if (!isEdit) {
        await axios.post(
          `/programs/addProgram`,
          {
            schoolId: Number(schoolId),
            programCode,
            programDescription,
            programDuration,
            programName,
            isActive: action,
          },
          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Program Added Successfully");
      } else {
        await axios.put(
          `/programs/updateProgram/${isEdit}`,
          {
            schoolId: Number(schoolId),
            programCode,
            programDescription,
            programDuration,
            programName,
            isActive: action,
          },
          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Program Updated Successfully");
      }

      getPrograms();
      handleClose();
    } catch (error) {
      console.log(error, "Juu error")
      if (error?.response?.data?.error) {
        toast.error(
          error?.response?.data?.error[0].message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  return (
    <div className="CMmodal-main">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          cursor: "pointer",
        }}
      >
        <div
          variant="primary"
          onClick={handleShow}
          className="myassingment-SeC-button-btn"
        >
          <IoAdd />
          Add new Program
        </div>
      </div>

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        centered
        className="CMmodal-main0modal"
        style={{ marginLeft: "100px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Programs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="Accedmic-modal-main">
            <label htmlFor="ProgramName">Program Name</label>
            <div className="Accedmic-modal-main-child1">
              <Form.Select
                value={programName}
                onChange={(e) => setProgramName(e.target.value)}
                aria-label="Default select example"
              >
                <option value={""}>Program Name</option>
                <option value="Department Of Business">
                  Department Of Business
                </option>
                <option value="Anual">Anual</option>
                <option value="Siqualizer">Siqualizer</option>
                <option value="Equalizer">Equalizer</option>
              </Form.Select>
            </div>
          </div>

          <div className="Accedmic-modal-main1">
            <label htmlFor="ProgramName">Description</label>
            <div className="Accedmic-modal-main-child1">
              <input
                placeholder="Description"
                value={programDescription}
                onChange={(e) => setProgramDescription(e.target.value)}
              />
            </div>
          </div>
          <div className="Accedmic-modal-main1">
            <label htmlFor="ProgramName">Program Code</label>
            <div className="Accedmic-modal-main-child1">
              <Form.Select
                value={programCode}
                onChange={(e) => setProgramCode(e.target.value)}
                aria-label="Default select example"
              >
                <option value={""}>Program Code</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </Form.Select>
            </div>
          </div>
          <div className="Accedmic-modal-main1">
            <label htmlFor="ProgramName">Program Duration</label>
            <div className="Accedmic-modal-main-child1">
              <Form.Select
                value={programDuration}
                onChange={(e) => setProgramDuration(e.target.value)}
                aria-label="Default select example"
              >
                <option value={""}>Program Duration</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </Form.Select>
            </div>
          </div>
          <div className="Accedmic-modal-main1">
            <label htmlFor="ProgramName">Action</label>
            <div className="Accedmic-modal-main-child1">
              <Form.Select
                value={action}
                onChange={(e) => setAction(e.target.value)}
                aria-label="Default select example"
              >
                <option value={""}>Is Active?</option>
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </Form.Select>
            </div>
          </div>
          <hr style={{ width: "100%" }} />
          <div className="Accedmic-modal-main-child3">
            <button onClick={handleClose}>Close</button>
            <button onClick={handleAddProgram}>Save</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default ManageAcModal;
