import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { BsSliders } from "react-icons/bs";
import "./Filter.css";
import { CiFilter } from "react-icons/ci";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

function FilterModal() {
  // const [showModal, setShowModal] = useState(false);

  // const handleClose = () => setShowModal(false);
  // const handleShow = () => setShowModal(true);

  return (
    <div className="FilterAll1">
      <div className="FilterAllSvg">
        <BsSliders />
      </div>
      <DropdownButton id="dropdown-basic-button" title="Filters">
        <Dropdown>
          <div className="COLORFILTER">
            <div className="FilterTOOP">
              <h2>Filter</h2>
              <CiFilter />
            </div>
            <div className="FilterNAme">
              <h3>Student</h3>
              <IoIosArrowUp />
            </div>
            <div className="FilterINPUTS">
              <div className="FilterCheckBOX">
                <input type="checkbox" />
                <label>Name</label>
              </div>
              <button>32</button>
            </div>
            <div className="FilterINPUTS">
              <div className="FilterCheckBOX">
                <input type="checkbox" />
                <label>Student ID</label>
              </div>
              <button>14</button>
            </div>
            <div className="FilterINPUTS">
              <div className="FilterCheckBOX">
                <input type="checkbox" />
                <label>Grade Level</label>
              </div>
              <button>32</button>
            </div>
            <div className="FilterINPUTS">
              <div className="FilterCheckBOX">
                <input type="checkbox" />
                <label>SSID</label>
              </div>
              <button>32</button>
            </div>
            <div className="FilterINPUTS">
              <div className="FilterCheckBOX">
                <input type="checkbox" />
                <label>Admission</label>
              </div>
              <button>32</button>
            </div>
            <div className="FilterINPUTS">
              <div className="FilterCheckBOX">
                <input type="checkbox" />
                <label>Email</label>
              </div>
              <button>32</button>
            </div>

            {/* ......................... */}
            <div className="FilterNAme">
              <h3>School</h3>
              <IoIosArrowUp />
            </div>
            <div className="FilterINPUTS">
              <div className="FilterCheckBOX">
                <input type="checkbox" />
                <label>High School</label>
              </div>
              <button>32</button>
            </div>
            <div className="FilterINPUTS">
              <div className="FilterCheckBOX">
                <input type="checkbox" />
                <label>High School</label>
              </div>
              <button>32</button>
            </div>
            <div className="FilterINPUTS">
              <div className="FilterCheckBOX">
                <input type="checkbox" />
                <label>Grade Level</label>
              </div>
              <button>32</button>
            </div>
            {/* ......................... */}
            <div className="FilterNAme">
              <h3> Range</h3>
              <IoIosArrowDown />
            </div>
            {/* ......................... */}
            <div className="FilterNAme">
              <h3> Language</h3>
              <IoIosArrowDown />
            </div>
            {/* ......................... */}
            <div className="FilterLSTbtns">
              <button>Done</button>
              <button>Reset</button>
            </div>
          </div>
        </Dropdown>
      </DropdownButton>
    </div>
  );
}

export default FilterModal;
