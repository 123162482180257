import React, { useEffect, useState } from "react";
import ParentStuInfo from "../ParentStudentInfo/ParentStuInfo";
import MyAssingmentSub from "../ParentAssingmentSub/MyAssingmentSub";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../../constants";
axios.defaults.baseURL = baseURL;

function ParentAssigments() {
  const { studentId } = useSelector((state) => state.studentId);
  const [assignments, setAssignments] = useState(null);

  const getAssignments = async () => {
    try {
      const { data } = await axios.get(
        `/assignments/getAssignmentsByStudent/${studentId}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      setAssignments(data?.assignments);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getAssignments();
  }, [studentId]);
  return (
    <div className="ParentAppsAccess ">
      <ParentStuInfo />
      {assignments && <MyAssingmentSub assignments={assignments} />}
    </div>
  );
}

export default ParentAssigments;
