import React, { useEffect, useState } from "react";
import "./EditAC.css";
import toast from "react-hot-toast";
import "react-phone-number-input/style.css";
import axios from "axios";
import { baseURL } from "../../../../../../constants";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { isValidEmail } from "../../../../../../utils/regex.js";
import { Country, State, City } from "country-state-city";
import { FaPhoneAlt } from "react-icons/fa";
axios.defaults.baseURL = baseURL;

function EditAC({ setShowEdit, student, getAddressAndContact }) {
  const [isCheck, setIsCheck] = useState(false);

  const params = useParams();

  const [homeAddress, setHomeAddress] = useState({
    address1: student?.homeAddress?.address1,
    address2: student?.homeAddress?.address2,
    country: student?.homeAddress?.country,
    stateOrProvinceOrLocality: student?.homeAddress?.stateOrProvinceOrLocality,
    city: student?.homeAddress?.city,
    zipOrPostalCode: student?.zipOrPostalCode,
  });
  const [mailingAddress, setMailingAddress] = useState({
    address1: student?.mailingAddress?.address1,
    address2: student?.mailingAddress?.address2,
    country: student?.mailingAddress?.country,
    stateOrProvinceOrLocality:
      student?.mailingAddress?.stateOrProvinceOrLocality,
    city: student?.mailingAddress?.city,
    zipOrPostalCode: student?.mailingAddress?.zipOrPostalCode,
  });

  const [personalInfo, setPersonalInfo] = useState({
    homePhone: student?.personalInfo?.homePhone,
    mobilePhone: student?.personalInfo?.mobilePhone,
    personalEmail: student?.personalInfo?.personalEmail,
    emergencyPhoneNumber: student?.personalInfo?.emergencyPhoneNumber,
    fatherNumber: student?.personalInfo?.fatherNumber,
    brotherNumber: student?.personalInfo?.brotherNumber,
  });

  const handleHomeAddressChanges = (e) => {
    setHomeAddress((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const handleMailingAddressChanges = (e) => {
    setMailingAddress((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handlePersonalInfoChanges = (e) => {
    setPersonalInfo((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const countries = Country.getAllCountries();

  const [country1, setCountry1] = useState(student?.homeAddress?.country);
  const [country2, setCountry2] = useState(student?.mailingAddress?.country);

  const [stateOrProvinceOrLocality1, setStateOrProvinceOrLocality1] = useState(
    student?.homeAddress?.stateOrProvinceOrLocality
  );
  const [stateOrProvinceOrLocality2, setStateOrProvinceOrLocality2] = useState(
    student?.mailingAddress?.stateOrProvinceOrLocality
  );

  const [city1, setCity1] = useState(student?.homeAddress?.city);
  const [city2, setCity2] = useState(student?.mailingAddress?.city);

  const regions1 = State.getStatesOfCountry(country1);
  const regions2 = State.getStatesOfCountry(country2);

  const cities1 = City.getCitiesOfState(country1, stateOrProvinceOrLocality1);
  const cities2 = City.getCitiesOfState(country2, stateOrProvinceOrLocality2);

  useEffect(() => {
    if (isCheck) {
      setMailingAddress({
        address1: homeAddress.address1,
        address2: homeAddress.address2,
        zipOrPostalCode: homeAddress.zipOrPostalCode,
      });
      setCity2(city1);
      setCountry2(country1);
      setStateOrProvinceOrLocality2(stateOrProvinceOrLocality1);
    } else {
      setMailingAddress({
        address1: "",
        address2: "",
        zipOrPostalCode: "",
      });
      setCity2("");
      setCountry2("");
      setStateOrProvinceOrLocality2("");
    }
  }, [isCheck]);
  // toast.error(error?.response?.data?.error);
  const [homePhone, setHomePhone] = useState(student?.personalInfo?.homePhone);
  const [mobilePhone, setMobilePhone] = useState(
    student?.personalInfo?.mobilePhone || ""
  );
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState(
    student?.personalInfo?.emergencyPhoneNumber || ""
  );
  const [fatherNumber, setFatherNumber] = useState(
    student?.personalInfo?.fatherNumber || ""
  );
  const [brotherNumber, setBrotherNumber] = useState(
    student?.personalInfo?.brotherNumber || ""
  );

  useEffect(() => {
    if (!homePhone?.startsWith("+1")) {
      setHomePhone("+1");
    } else {
      setHomePhone(homePhone);
    }
  }, [homePhone]);

  useEffect(() => {
    if (!mobilePhone?.startsWith("+1")) {
      setMobilePhone("+1");
    } else {
      setMobilePhone(mobilePhone);
    }
  }, [mobilePhone]);

  useEffect(() => {
    if (!emergencyPhoneNumber?.startsWith("+1")) {
      setEmergencyPhoneNumber("+1");
    } else {
      setEmergencyPhoneNumber(emergencyPhoneNumber);
    }
  }, [emergencyPhoneNumber]);

  useEffect(() => {
    if (!fatherNumber?.startsWith("+1")) {
      setFatherNumber("+1");
    } else {
      setFatherNumber(fatherNumber);
    }
  }, [fatherNumber]);

  useEffect(() => {
    if (!brotherNumber?.startsWith("+1")) {
      setBrotherNumber("+1");
    } else {
      setBrotherNumber(brotherNumber);
    }
  }, [brotherNumber]);

  const editAddressAndContact = async () => {
    try {
      const regex = /^(\+1\s?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
      if (
        !regex?.test(homePhone) ||
        !regex?.test(mobilePhone) ||
        !regex?.test(emergencyPhoneNumber) ||
        !regex?.test(fatherNumber) ||
        !regex?.test(brotherNumber)
      ) {
        return toast.error("All phone numbers must be valid");
      }

      if (personalInfo.personalEmail) {
        if (!isValidEmail(personalInfo.personalEmail)) return;
      }
      const { data } = await axios.put(
        `/students/updateAddressAndContactInfo/${params.studentId}`,
        {
          homeAddress: {
            ...homeAddress,
            stateOrProvinceOrLocality: stateOrProvinceOrLocality1,
            city: city1,
            country: country1,
          },
          mailingAddress: {
            ...mailingAddress,
            stateOrProvinceOrLocality: stateOrProvinceOrLocality2,
            city: city2,
            country: country2,
          },
          personalInfo: {
            ...personalInfo,
            homePhone,
            mobilePhone,
            emergencyPhoneNumber,
            fatherNumber,
            brotherNumber,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );

      toast.success("Student Updated Successfully");
      setShowEdit(false);
      getAddressAndContact();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const [selectedCountry, setSelectedCountry] = useState("US");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const handleCountryChange = (val) => {
    setSelectedCountry(val);
    setSelectedRegion("");
    setSelectedCity("");
  };

  const handleRegionChange = (val) => {
    setSelectedRegion(val);
    setSelectedCity("");
  };

  const handleCityChange = (val) => {
    setSelectedCity(val);
  };

  return (
    <div className="EditAC-main">
      <h1>Address and Contact </h1>
      <h2>Student Home Address</h2>
      <div className="EditAC-1-Input-Row">
        <div>
          <label>Street Address 1</label> <br />
          <input
            name="address1"
            onChange={handleHomeAddressChanges}
            value={homeAddress.address1}
            placeholder="-"
          />
        </div>
        <div>
          <label>Street Address 2</label> <br />
          <input
            name="address2"
            onChange={handleHomeAddressChanges}
            value={homeAddress.address2}
            placeholder="-"
          />
        </div>
        <div>
          <label>Country</label>
          <br />
          <select
            value={country1}
            onChange={(e) => setCountry1(e.target.value)}
          >
            <option value="">Select Country</option>
            {countries.map((country) => (
              <option
                selected={country.isoCode === country1}
                key={country.isoCode}
                value={country.isoCode}
              >
                {country.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="EditAC-1-Input-Row">
        <div>
          <label>State/Provence/Locality</label>
          <br />
          {country1 && (
            <select
              value={stateOrProvinceOrLocality1}
              onChange={(e) => setStateOrProvinceOrLocality1(e.target.value)}
            >
              <option value="">Select Region</option>
              {regions1.map((region) => (
                <option
                  key={region.isoCode}
                  selected={region.isoCode === stateOrProvinceOrLocality1}
                  value={region.isoCode}
                >
                  {region.name}
                </option>
              ))}
            </select>
          )}
        </div>
        <div>
          <label>City</label>
          <br />
          {stateOrProvinceOrLocality1 && (
            <select value={city1} onChange={(e) => setCity1(e.target.value)}>
              <option value="">Select District</option>
              {cities1.map((city) => (
                <option
                  key={city.id}
                  selected={city.name === city1}
                  value={city.name}
                >
                  {city.name}
                </option>
              ))}
            </select>
          )}
        </div>
        <div>
          <label>Zip/Postal Code</label> <br />
          <input
            name="zipOrPostalCode"
            onChange={handleHomeAddressChanges}
            value={homeAddress.zipOrPostalCode}
            placeholder="-"
          />
        </div>
      </div>
      <div className="EditCheckBoxLabel">
        <input
          checked={isCheck}
          onChange={() => setIsCheck(!isCheck)}
          type="checkbox"
        />
        <label>Same as Home Address</label>
      </div>

      <div className="EditAC-2-Input-Row">
        <h1 className="EditH1">Student Mailing Address</h1>

        <div className="EditAC-1-Input-Row">
          <div>
            <label>Street Address 1</label> <br />
            <input
              name="address1"
              onChange={handleMailingAddressChanges}
              value={mailingAddress.address1}
              placeholder="-"
            />
          </div>
          <div>
            <label>Street Address 2</label> <br />
            <input
              name="address2"
              onChange={handleMailingAddressChanges}
              placeholder="-"
              value={mailingAddress.address2}
            />
          </div>
          <div>
            <label>Country</label>
            <br />
            <select
              value={country2}
              onChange={(e) => setCountry2(e.target.value)}
            >
              <option value="">Select Country</option>
              {countries.map((country) => (
                <option
                  key={country.isoCode}
                  selected={country.isoCode === country2}
                  value={country.isoCode}
                >
                  {country.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="EditAC-1-Input-Row">
          <div>
            <label>State/Provence/Locality</label>
            <br />
            {country2 && (
              <select
                value={stateOrProvinceOrLocality2}
                onChange={(e) => setStateOrProvinceOrLocality2(e.target.value)}
              >
                <option value="">Select Region</option>
                {regions2.map((region) => (
                  <option
                    key={region.isoCode}
                    selected={region.isoCode === selectedRegion ? true : false}
                    value={region.isoCode}
                  >
                    {region.name}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div>
            <label>City</label>
            <br />
            {stateOrProvinceOrLocality2 && (
              <select value={city2} onChange={(e) => setCity2(e.target.value)}>
                <option value="">Select District</option>
                {cities2.map((city) => (
                  <option
                    key={city.id}
                    selected={city.name === selectedCity ? true : false}
                    value={city.name}
                  >
                    {city.name}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div>
            <label>Zip/Postal Code</label> <br />
            <input
              value={mailingAddress.zipOrPostalCode}
              name="zipOrPostalCode"
              id=""
              onChange={handleMailingAddressChanges}
              placeholder="-"
            />
          </div>
        </div>
      </div>

      <div className="EditAC-3-Input-Row">
        <h1>Personal Contact Information</h1>
        <div className="EditAC-1-Input-Row">
          <div>
            <label>Home Phone</label> <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "7px",
              }}
            >
              <FaPhoneAlt />
              <PhoneInput
                placeholder="Enter contact number"
                value={homePhone}
                onChange={setHomePhone}
              />
            </div>
          </div>
          <div>
            <label>Mobile Phone</label> <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "7px",
              }}
            >
              <FaPhoneAlt />
              <PhoneInput
                placeholder="Enter contact number"
                value={mobilePhone}
                onChange={setMobilePhone}
              />
            </div>
          </div>
          <div>
            <label>Personal Email</label> <br />
            <input
              name="personalEmail"
              id=""
              onChange={handlePersonalInfoChanges}
              placeholder="-"
              value={personalInfo.personalEmail}
            />
          </div>
        </div>
        <div className="EditAC-1-Input-Row">
          <div>
            <label>Emergency Contact Number</label> <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "7px",
              }}
            >
              <FaPhoneAlt />
              <PhoneInput
                placeholder="Enter contact number"
                value={emergencyPhoneNumber}
                onChange={setEmergencyPhoneNumber}
              />
            </div>
          </div>
          <div>
            <label>Father Number</label> <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "7px",
              }}
            >
              <FaPhoneAlt />
              <PhoneInput
                placeholder="Enter contact number"
                value={fatherNumber}
                onChange={setFatherNumber}
              />
            </div>
          </div>
          <div>
            <label>Brother Number</label> <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "7px",
              }}
            >
              <FaPhoneAlt />
              <PhoneInput
                placeholder="Enter contact number"
                value={brotherNumber}
                onChange={setBrotherNumber}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="EditAC-main-btns">
        <button onClick={() => setShowEdit(false)} className="EditAC-main-btn1">
          Cancel
        </button>
        <button onClick={editAddressAndContact} className="EditAC-main-btn2">
          Update
        </button>
      </div>
    </div>
  );
}

export default EditAC;
