import React, { useEffect, useState } from "react";
import "./Classroom.css";
import { Link } from "react-router-dom";
import { LuSearch } from "react-icons/lu";
import { RiEqualizerFill } from "react-icons/ri";
import axios from "axios";
import { baseURL } from "../../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

const Classroom = () => {
  const [classRoomData, setClassRoomData] = useState(null);

  const { schoolId } = useSelector((state) => state.schoolId);
  const getClassRoomData = async () => {
    try {
      const { data } = await axios.get(`/courses/all/${schoolId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });

      setClassRoomData(data.courses);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getClassRoomData();
  }, [schoolId]);
  return (
    <div className="classroom-main">
      <div className="Classroom-child-1">
        <div className="ADDClassRoom-row1">
          <div className="SEarchInput">
            <LuSearch />
            <input placeholder="Search ." />
          </div>
          <div className="DateInput">
            <input type="date" />
          </div>
          <div className="EqualizerInput">
            <button>
              <RiEqualizerFill />
              Filters
            </button>
          </div>
        </div>
        <div className="ClassSelectInput">
          <select>
            <option value="Class 8th">Class 8th</option>
            <option value="Class 9th">Class 9th</option>
            <option value="Class 10th">Class 10th</option>
          </select>
          <button>Courses Manage </button>
        </div>
      </div>
      <div className="Classroom-child-2-map">
        {classRoomData &&
          classRoomData.map((card, index) => (
            <div className="Classroom-child-2" key={card.id}>
              <div
                className="Classroom-child-2-main"
                style={{ backgroundColor: "#fff" }}
              >
                <div
                  className="classroom-child-2-img"
                  style={{
                    backgroundColor: index % 2 === 0 ? "#5960B8" : "#658BEB",
                  }}
                >
                  <img src={`/${baseURL}/images/${card?.image}`} alt="" />
                </div>
                <p>{card?.courseName}</p>
                <Link className="Linkeded">
                  <button
                    style={{
                      backgroundColor: index % 2 === 0 ? "#B1C1DF" : "#B798E0",
                    }}
                  >
                    View Class
                  </button>
                </Link>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Classroom;
