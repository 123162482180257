import React from "react";
import { ActionKind, usePony } from "pony-props";
import ImgCredit from "../../images/Account type.png";
import "./CardsCaro.css";
import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";

function CardsCaro() {
  const items = [
    { id: 0, imageUrl: ImgCredit },
    { id: 1, imageUrl: ImgCredit },
    { id: 2, imageUrl: ImgCredit },
  ];

  const {
    getSectionProps,
    getHeadingProps,
    getCarouselWrapperProps,
    getCarouselProps,
    getCarouselItemProps,
    getButtonProps,
    getAnnouncerProps,
    state,
  } = usePony({ numItems: items.length });
  return (
    <div {...getSectionProps()} className="ADDCardCaro-Main">
      <h1 {...getHeadingProps()}></h1>
      <div {...getCarouselWrapperProps()}>
        <ul {...getCarouselProps()} className="ADDCardCaro-ul">
          {items.map((item, idx) => (
            <li key={item.id} {...getCarouselItemProps(idx)}>
              <img
                src={item.imageUrl}
                alt={item.name}
                style={{ width: "100%", height: "auto" }}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="CAROButtons">
        <button {...getButtonProps(ActionKind.Previous)}>
          <GrFormPrevious />
          Previous
        </button>
        <button {...getButtonProps(ActionKind.Next)}>
          Next
          <GrFormNext />
        </button>
      </div>
      <div {...getAnnouncerProps()}>
        <p>{`Item ${state.activeSlideIndex + 1} of ${items.length}`}</p>
      </div>
    </div>
  );
}

export default CardsCaro;
