import { createReducer } from "@reduxjs/toolkit";

export const userReducer = createReducer({}, (bundler) => {
  bundler.addCase("getParentStudentsRequest", (state) => {
    state.loading = true;
  });
  bundler.addCase("getParentStudentsSuccess", (state, action) => {
    state.loading = false;
    state.students = action.payload;
  });
  bundler.addCase("getParentStudentsFailure", (state, action) => {
    state.loading = false;
    state.error = action.payload;
  });
});
export const getAllSchoolsReducer = createReducer({}, (bundler) => {
  bundler.addCase("getAllSchoolsRequest", (state) => {
    state.loading = true;
  });
  bundler.addCase("getAllSchoolsSuccess", (state, action) => {
    state.loading = false;
    state.schools = action.payload;
  });
  bundler.addCase("getAllSchoolsFailure", (state, action) => {
    state.loading = false;
    state.error = action.payload;
  });
});

export const studentIdReducer = createReducer(
  {
    studentId: sessionStorage.getItem("studentId") || null,
  },
  (bundler) => {
    bundler.addCase("studentId", (state, action) => {
      state.studentId = action.payload;
    });
  }
);

export const medicalStudentIdReducer = createReducer(
  {
    studentId: sessionStorage.getItem("medicalStudentId") || null,
  },
  (bundler) => {
    bundler.addCase("medicalStudentId", (state, action) => {
      state.studentId = action.payload;
    });
  }
);
export const medicalHistoryStudentIdReducer = createReducer(
  {
    studentId: sessionStorage.getItem("medicalHistoryStudentId") || null,
  },
  (bundler) => {
    bundler.addCase("medicalHistoryStudentId", (state, action) => {
      state.studentId = action.payload;
    });
  }
);

export const schoolIdReducer = createReducer(
  {
    schoolId: sessionStorage.getItem("myschool") || null,
  },
  (bundler) => {
    bundler.addCase("addSchoolId", (state, action) => {
      state.schoolId = action.payload;
    });
  }
);

export const getUserProfileReducer = createReducer(
  { isAuthenticated: false },
  (bundler) => {
    bundler.addCase("getUserProfileRequest", (state) => {
      state.loading = true;
    });
    bundler.addCase("getUserProfileSuccess", (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.isAuthenticated = true;
    });
    bundler.addCase("getUserProfileFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = false;
    });
  }
);
