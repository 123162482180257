import React, { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoCaretForwardOutline } from "react-icons/io5";
import { IoAdd } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { IoIosSearch } from "react-icons/io";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modall from "@mui/material/Modal";
import "./AddCourse.css";
import toast from "react-hot-toast";
import axios from "axios";
import { baseURL } from "../../../../constants";
import { useSelector } from "react-redux";
axios.defaults.baseURL = baseURL;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function AddCourse() {
  const [deleteId, setDeleteId] = useState("");
  const [editId, setEditId] = useState("");

  const [assignmentsData, setAssignmentsData] = useState([
    {
      descriptionColor: "#F93333",
      bgColor: "#f4dfe1",
      buttonBgColor: "#f93333",
      courseCode: "1",
      date: "1",
      buttonTextColor: "#F93333",
      button: "Active",
      programCodeColor: "#F93333",
      programDurationColor: "#F93333",
      drageIconColor: "#F93333",
      EditIconClor: "#F93333",
      deleteIconColor: "#F93333",
    },

    {
      descriptionColor: "#16D03B",
      bgColor: "#f0fff3",
      courseCode: "3",
      buttonBgColor: "#16D03B",
      date: "3",
      buttonTextColor: "#16D03B",
      button: "Active",
      programCodeColor: "#16D03B",
      programDurationColor: "#16D03B",
      drageIconColor: "#16D03B",
      EditIconClor: "#16D03B",
      deleteIconColor: "#16D03B",
    },
  ]);
  const [courseName, setCourseName] = useState("");
  const [courseCode, setCourseCode] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [courseDuration, setCourseDuration] = useState("");
  const [isActive, setIsActive] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setDeleteId("");
    setEditId("");
  };
  const handleShow = () => {
    setShow(true);
    if (!editId) {
      setCourseName("");
      setCourseCode("");
      setCourseDescription("");
      setCourseDuration("");
      setIsActive("");
    }
  };
  const [open, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClosee = () => {
    setIsOpen(false);
    setDeleteId("");
    setEditId("");
  };

  const { schoolId } = useSelector((state) => state?.schoolId);

  const params = useParams();

  const [courses, setCourses] = useState(null);

  const getAllCourses = async () => {
    try {
      const { data } = await axios.get(
        `/courses/coursebyProgramId/${sessionStorage.getItem("programId")}`,
        {
          headers: {
            accessToken: `${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(data, "juu na baal");
      setCourses(data?.courses);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const addCourse = async () => {
    try {
      if (!editId) {
        await axios.post(
          `/courses/add`,
          {
            schoolId: Number(schoolId),
            programId: Number(sessionStorage.getItem("programId")),
            courseName: courseName,
            courseCode: courseCode,
            courseDescription: courseDescription,
            courseDuration: courseDuration,
            isActive: isActive,
          },

          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Course Added Successfully");
      } else {
        await axios.put(
          `/courses/updateCourse/${editId}`,
          {
            schoolId,
            programId: params?.programId,
            courseName: courseName,
            courseCode: courseCode,
            courseDescription: courseDescription,
            courseDuration: courseDuration,
            isActive: isActive,
          },

          {
            headers: {
              "Content-Type": "application/json",
              accessToken: `${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Course Updated Successfully");
      }

      handleClose();
      handleClosee();
      getAllCourses();
    } catch (error) {
      if (error?.response?.data?.error) {
        toast.error(error?.response?.data?.error[0].message);
      }
    }
  };

  useEffect(() => {
    getAllCourses();
  }, [schoolId]);

  const handleDeleteCourse = async () => {
    try {
      const { data } = await axios.delete(`/courses/remove/${deleteId}`, {
        headers: {
          accessToken: `${localStorage.getItem("token")}`,
        },
      });
      toast.success("Successfully deleted course!");
      handleClose();
      handleClosee();
      setDeleteId("");
      getAllCourses();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (editId && courses) {
      handleShow();
      const cour = courses?.find((crs) => {
        return crs?.id === editId;
      });
      setCourseCode(cour?.courseCode);
      setCourseName(cour?.courseName);
      setCourseDescription(cour?.courseDescription);
      setCourseDuration(cour?.courseDuration);
      setIsActive(cour?.isActive);
    }
  }, [editId, courses]);
  const navigate = useNavigate();

  return (
    <div className="CourseScreen-main">
      <div className="myassingment-TOP345">
        <h3>School </h3>
        <IoCaretForwardOutline />
        <Link to="/ManageAcademics" style={{ textDecoration: "none" }}>
          <h4 style={{ color: "#00afef" }}>Manage Academics</h4>
        </Link>
        <IoCaretForwardOutline />
        <Link to="/ManageAcademics" style={{ textDecoration: "none" }}>
          <h4 style={{ color: "#00afef" }}>Program</h4>
        </Link>
        <IoCaretForwardOutline />
        <h4>Courses</h4>
      </div>
      <div className="myassingment-SeC">
        <div className="myassingment-SeC-input">
          <IoIosSearch />
          <input
            placeholder="Search for Program,  Courses, Semester"
            type="text"
          />
        </div>
        <div className="myassingment-SeC-button">
          <div className="CMmodal-main">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
              }}
            >
              <div
                variant="primary"
                onClick={handleShow}
                className="myassingment-SeC-button-btn"
              >
                <IoAdd />
                Add new Course
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        centered
        className="CMmodal-main0modal"
        style={{ marginLeft: "100px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="Accedmic-modal-main">
            <label htmlFor="ProgramName">Course Name</label>
            <div className="Accedmic-modal-main-child1">
              <input
                value={courseName}
                onChange={(e) => setCourseName(e.target.value)}
                placeholder="Course Name"
              />
            </div>
          </div>

          <div className="Accedmic-modal-main1">
            <label htmlFor="ProgramName">Course Code</label>
            <div className="Accedmic-modal-main-child1">
              <input
                value={courseCode}
                onChange={(e) => setCourseCode(e.target.value)}
                placeholder="Course Code"
              />
            </div>
          </div>
          <div className="Accedmic-modal-main1">
            <label htmlFor="ProgramName">Course Description</label>
            <div className="Accedmic-modal-main-child1">
              <input
                value={courseDescription}
                onChange={(e) => setCourseDescription(e.target.value)}
                placeholder="Course Description"
              />
            </div>
          </div>
          <div className="Accedmic-modal-main1">
            <label htmlFor="ProgramName">Course Duration</label>
            <div className="Accedmic-modal-main-child1">
              <input
                value={courseDuration}
                onChange={(e) => setCourseDuration(e.target.value)}
                placeholder="3 year"
              />
            </div>
          </div>
          <div className="Accedmic-modal-main1">
            <label htmlFor="ProgramName">Status</label>
            <div className="Accedmic-modal-main-child1">
              <Form.Select
                value={isActive}
                onChange={(e) => setIsActive(e.target.value)}
                aria-label="Default select example"
              >
                <option value={""}>Is Active?</option>
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </Form.Select>
            </div>
          </div>

          <hr style={{ width: "100%" }} />
          <div className="Accedmic-modal-main-child3">
            <button onClick={handleClose}>Close</button>
            <button onClick={addCourse}>Save</button>
          </div>
        </Modal.Body>
      </Modal>
      <div className="myassingment-SeC-Table">
        <table>
          <thead>
            <tr>
              <th>
                <input type="checkbox" />
                Course Name
              </th>
              <th>Course Code</th>
              <th>Course Discription</th>
              <th>Course Duration</th>
              <th>Action</th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="CommentModal-Mmain">
        <Modall
          className="Allowmodalmain"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClosee}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div className="CommentModal-Mmain-child1">
                <h4>Delete Course</h4>
                <p>
                  Are you sure you want to delete this Course? This will remove
                  the Course and can’t be undone.
                </p>
                <div className="CommentModal-Mmain-child2">
                  <button
                    className="CommentModal-Mmain-child2-Dont-allow"
                    onClick={handleClosee}
                  >
                    CANCEL
                  </button>
                  <button
                    onClick={handleDeleteCourse}
                    className="CommentModal-Mmain-child2-allow"
                  >
                    DELETE
                  </button>
                </div>
              </div>
            </Box>
          </Fade>
        </Modall>
      </div>

      {/* map */}
      <div style={{ marginTop: "10px" }}>
        {courses?.map((assignment, index) => {
          return (
            <div
              className="CourseScreen-child-2m"
              key={index}
              style={{ marginTop: "10px" }}
              onClick={() => {
                sessionStorage.removeItem("courseId");
                sessionStorage.setItem("courseId", assignment?.id);
                navigate(`/AddSemester`);
              }}
            >
              <div
                className="CourseScreen-cardd21"
                style={{
                  backgroundColor:
                    assignmentsData[assignment?.isActive !== true ? 0 : 1]
                      ?.bgColor,
                  border: `1px solid ${
                    assignmentsData[assignment?.isActive !== true ? 0 : 1]
                      ?.programCodeColor
                  }`,
                }}
              >
                <div className="CourseScreen-card-childs-1m">
                  <div className="CourseScreens-childss12">
                    <div className="CourseScreen-childss-Data1">
                      <div
                        className="CourseScreen-childs-dateAb"
                        style={{
                          backgroundColor:
                            assignmentsData[
                              assignment?.isActive !== true ? 0 : 1
                            ]?.programCodeColor,
                        }}
                      >
                        <p>{assignment?.id}</p>
                      </div>
                      <div className="Mychildss-CourseScreens1">
                        <h6>{assignment?.courseName} </h6>
                      </div>
                    </div>
                    <div>
                      <h6
                        style={{
                          color:
                            assignmentsData[
                              assignment?.isActive !== true ? 0 : 1
                            ]?.programCodeColor,
                        }}
                      >
                        {assignment?.courseCode}
                      </h6>
                    </div>
                  </div>
                </div>
                {/* ///////////////////////////////// */}

                <div className="CourseScreen-card-childs-3m">
                  <div className="descriptions-s112">
                    <h5
                      style={{
                        color:
                          assignmentsData[assignment?.isActive !== true ? 0 : 1]
                            ?.descriptionColor,
                      }}
                    >
                      Description
                    </h5>
                    <p>{assignment?.description}</p>
                  </div>
                  <div>
                    <p
                      style={{
                        color:
                          assignmentsData[assignment?.isActive !== true ? 0 : 1]
                            ?.programDurationColor,
                      }}
                    >
                      {assignment?.courseDuration}
                    </p>
                  </div>
                  <div className="mychilds-CourseScreen-dovh">
                    <button
                      style={{
                        color:
                          assignmentsData[assignment?.isActive !== true ? 0 : 1]
                            ?.programCodeColor,
                        borderColor:
                          assignmentsData[assignment?.isActive !== true ? 0 : 1]
                            ?.buttonBgColor,
                      }}
                    >
                      {assignment?.isActive === true ? "Active" : "Not Active"}
                    </button>
                    <div className="mychilds-CourseScreen-icoNs">
                      <MdEdit
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditId(assignment?.id);
                        }}
                        style={{
                          color:
                            assignmentsData[
                              assignment?.isActive !== true ? 0 : 1
                            ]?.EditIconClor,
                        }}
                      />
                      <RiDeleteBin6Line
                        style={{
                          color:
                            assignmentsData[
                              assignment?.isActive !== true ? 0 : 1
                            ]?.deleteIconColor,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpen();
                          setDeleteId(assignment?.id);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AddCourse;
